import { IClientAccounts } from "us.collection.economy/interfaces/ClientAccounts";

export const initialState: IClientAccounts = {
  clientAccountsList: [],
  isLoadingClientAccounts: false,
  ledgerAccounts: [],
  remitSequenceDetails: [],
  ledgerSequenceDetails: [],
  isValidAccountNo: true,
  isValidJournalPrefix: true,
  accountSaveUpdate: {
    isLoading: false,
    data: {},
  },
};
