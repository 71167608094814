import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    sequenceName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.SEQUENCE_NAME_IS_REQUIRED"
    ),
    displayName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.DISPLAY_NAME_IS_REQUIRED"
    ),
  });
};
