import { FeeType } from "us.collection.economy/components/ItemTypes/Constants";
import { IDistributionFactor } from "us.collection.economy/interfaces/ItemTypes";

export const INITIAL_DISTRIBUTION_FACTORS: Array<IDistributionFactor> = [
  {
    id: 0,
    feeType: FeeType.AMOUNT,
    isVAT: false,
    bureauFraction: 0,
    creditorFraction: 0,
  },
  {
    id: 0,
    feeType: FeeType.INTEREST,
    isVAT: false,
    bureauFraction: 0,
    creditorFraction: 0,
  },
];

export const INITIAL_ITEM_TYPE_DETAIL = {
  itemTypeId: null,
  itemTypeName: "",
  transactionCode: "",
  description: "",
  createdDate: "",
  modifiedDate: "",
  modifiedUser: "",
  transactionGroupId: 0,
  transactionGroupName: "",
  defaultAmount: 0,
  voucherSequenceId: 0,
  bankAccountNumber: "",
  ledgerAccountNumber: "",
  isPayment: true,
  isInterestApplicable: false,
  isCancelationType: false,
  isVatLiable: false,
  isRemappingAllowed: false,
  subCaseMappingOnly: false,
  vatCode: -1,
  transactionType: "isPayment",
  distributionFactors: [INITIAL_DISTRIBUTION_FACTORS[0]],
};

export const INITIAL_VOUCHER_SEQUENCE_DETAIL = {
  sequenceId: "",
  subSequenceId: "",
  nextNo: 1,
  text: "",
  prefix: "",
};
