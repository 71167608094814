import React, { Fragment } from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { getRouteUrl } from "us.helper";
import { RemitValidationErrorsProps } from "./Interfaces";
import {
  RemitValidationTableColumn,
  REMIT_VALIDATION_TABLE_COLUMNS,
} from "./Constants";
import { RemitValidationError } from "us.collection.economy/components/Remit/Interfaces";
import { RemitValidationState } from "../../Constants";
import { ValidationMessage } from "../ValidationMessage";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "us.collection/interfaces";

const { $Button, $Table, $Skeleton, $AmountLabel } = Common.Components;

/**
 * @description - View remit proposal validation issues to fix and re-validate before remitting
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2858385457/Remit+Validation+Errors+UI+Design+and+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 08/03/2022
 */
const RemitValidationErrors: React.FC<
  RemitValidationErrorsProps & PropsFromRedux
> = (props) => {
  const { t } = useTranslation();
  const {
    remitValidation,
    validationState,
    onReValidate,
    onCancel,
    onViewRemit,
  } = props;
  const { data, isLoading } = remitValidation;

  // generate columns for the table
  const getColumns: any = () => {
    const columns: Array<any> = [];

    REMIT_VALIDATION_TABLE_COLUMNS.map(({ key, title, align }: any) => {
      let column: any = {
        key,
        dataIndex: key,
        title: title ? t(title) : "",
        align,
        sorter: (a: any, b: any) => {
          return a[key] - b[key];
        },
      };
      if (key === RemitValidationTableColumn.PAYMENT_ID) {
        column = {
          ...column,
          render: (text: any, record: RemitValidationError) => (
            <a onClick={() => getRouteUrl.mapPayment(record.paymentId)}>
              {text}
            </a>
          ),
        };
      } else if (
        [
          RemitValidationTableColumn.APPORTION_AMOUNT,
          RemitValidationTableColumn.TRANSACTION_AMOUNT,
          RemitValidationTableColumn.PAYMENT_AMOUNT,
        ].includes(key)
      ) {
        column = {
          ...column,
          render: (text: any, record: any) => (
            <$AmountLabel value={record[key]} />
          ),
        };
      }
      columns.push(column);
    });

    return columns;
  };

  return (
    <Fragment>
      <div>
        {validationState === RemitValidationState.VALIDATION_SUCCESS && (
          <ValidationMessage
            type={"success"}
            message={t(
              "US.COLLECTION.ECONOMY:MESSAGES.REMIT_VALIDATION_SUCCESS"
            )}
            buttonTitle={t("US.COLLECTION.COMMON:COMMON.VIEW_REMIT")}
            onPress={onViewRemit}
          />
        )}
        <$Skeleton loading={isLoading} active paragraph={{ rows: 2 }}>
          <div>
            <$Table
              rowKey={(record: RemitValidationError) => record.paymentId}
              columns={getColumns()}
              dataSource={data}
              className="mt-3"
              bordered
              pagination={{ defaultPageSize: 25 }}
            />
          </div>
        </$Skeleton>
      </div>

      <div className="drawer-footer-fixed align-content-center justify-content-end">
        <div>
          <$Button
            id="btnReValidate"
            className="ml-3 mr-2"
            type="primary"
            onClick={onReValidate}
            loading={isLoading}
          >
            {t("US.COLLECTION.ECONOMY:REMIT.RE-VALIDATE")}
          </$Button>
          <$Button onClick={onCancel} id="btnCancelDrawer">
            {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
          </$Button>
        </div>
      </div>
    </Fragment>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, remit } = state;

  const { remitValidation, validationState } = remit;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    validationState,
    remitValidation,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemitValidationErrors);
