export enum CoveringSequenceTableColumn {
  MENU = "menu",
  NAME = "sequenceName",
  DISPLAY_NAME = "displayName",
  SEQUENCE = "sequenceDetails",
  MODIFIED_DATE = 'modifiedDate',
  MODIFIED_USER = 'modifiedBy'
}

export const COVERING_SEQUENCE_TABLE_COLUMNS = [
  {
    id: 0,
    key: CoveringSequenceTableColumn.MENU,
    title: "",
  },
  {
    id: 1,
    key: CoveringSequenceTableColumn.NAME,
    title: "NAME",
  },
  {
    id: 2,
    key: CoveringSequenceTableColumn.DISPLAY_NAME,
    title: "DISPLAY_NAME",
  },
  {
    id: 3,
    key: CoveringSequenceTableColumn.SEQUENCE,
    title: "SEQUENCE",
  },
  {
    id: 4,
    key: CoveringSequenceTableColumn.MODIFIED_DATE,
    title: "MODIFIED_DATE",
  },
  {
    id: 5,
    key: CoveringSequenceTableColumn.MODIFIED_USER,
    title: "MODIFIED_USER",
  }
];
