/**
 * @description Group sequence data by sequence id
 * @param {any[]} sequenceData - sequence data by API
 * @param {string} type - sequence type
 * @returns {Array<any>} return grouped sequence data
 */
export const groupBySequenceId = (
  sequenceData: any[],
  type: string
): Array<any> => {
  try {
    return reFormatLedgerSequenceData(
      sequenceData?.filter((seq: any) => seq.sequenceId === type)
    );
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * @description reformat sequence data
 * @param {any[]} ledgerSequenceData - client account list in the table
 * @returns {Array<any>} return reformatted sequence data
 */
export const reFormatLedgerSequenceData = (
  ledgerSequenceData: any[]
): Array<any> => {
  try {
    return ledgerSequenceData?.map((seq: any) => {
      return {
        ...seq,
        text: seq.subSequenceId + " - " + seq.text,
      };
    });
  } catch (error) {
    console.error(error);
    return ledgerSequenceData;
  }
};
