import {
  Action,
  TableColumn,
  INITIAL_DETAIL_STATE,
} from "us.collection.economy/components/LedgerAccounts/Constants";

/**
 * @description - Get dynamic width property value for each column by key
 * @param {TableColumn} key - Column key
 * @returns {string} - Width (px) of a column
 */
export const getColumnWidth = (key: TableColumn): string => {
  switch (key) {
    case TableColumn.MENU:
      return "15px";
    case TableColumn.DESCRIPTION:
      return "120px";
    case TableColumn.ACCOUNT:
    case TableColumn.LAST_UPDATE:
      return "100px";
    default:
      return "160px";
  }
};

/**
 * @description - Get Drawer tile by action
 * @param {Action} type - action type
 * @returns {string} - return drawer title
 */
export const getDrawerTitle = (type: Action): string => {
  switch (type) {
    case Action.ADD:
      return "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.NEW_LEDGER_ACCOUNT";
    case Action.EDIT:
      return "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.EDIT_LEDGER_ACCOUNT";
    case Action.VIEW:
      return "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.VIEW_LEDGER_ACCOUNT";
    default:
      return "";
  }
};

/**
 * @description Get ledger accounts by accountId
 * @param {Array<LedgerDetails>} ledgerAccounts - List of ledger accounts to find by accountId
 * @param {number} accountId - The URL params id to find matching client accounts from the given list
 * @returns {LedgerDetails | undefined} - If find item will return it otherwise return undefined
 */
export const getLedgerAccountRecordDetailsById = (
  ledgerAccounts: Array<any>,
  accountId: number
): any | {} => {
  try {
    if (accountId != -1) {
      const matchedRecord = ledgerAccounts.find(
        (account: any) => account.accountId == accountId
      );
      return matchedRecord ?? INITIAL_DETAIL_STATE;
    }
    return INITIAL_DETAIL_STATE;
  } catch (error) {
    return INITIAL_DETAIL_STATE;
  }
};

/**
 * @description get button title by action
 * @param {boolean} action - action
 * @returns title of the the button
 */
export const getButtonTitle = (action: boolean) => {
  switch (action) {
    case true:
      return "US.COMMON:COMMON.UPDATE";
    case false:
      return "US.COMMON:COMMON.SAVE";
    default:
      return "";
  }
};
