import { DayValue } from "us.collection.economy/constants/Payments";

export const initialState = {
  payments: {
    data: {
      formatted: [],
      raw: [],
      ocrFileNameOptions: [],
    },
    dayVal: DayValue.TODAY,
    paymentDateRange: [],
    isFetching: false,
    hasMorePayments: true,
  },
  paymentsWithAcc: [],
  paymentsGrid: {
    data: {
      formatted: [],
      raw: [],
    },
    isFetching: false,
  },
  paymentAccounts: {
    data: [],
    isFetching: false,
  },
  paymentDistribution: {
    data: {},
    isFetching: false,
  },
  editPayment: {
    isSaving: false,
  },
  returnPayment: {
    isSaving: false,
    result: null,
  },
  cases: {
    data: [],
    isFetching: false,
  },
  matchPaymentResult: {
    data: null,
    isFetching: false,
  },
  matchPaymentData: {
    payment: null,
    isExceeded: true,
    casesData: [],
    caseTableData: [],
    remainingBalance: 0,
    returnFee: 0,
    debtorAccountNo: "",
    returnAmount: 0,
    exceededOrUnknownAmount: 0,
    mainAr: 0,
    activeRowKeys: [],
  },
  caseValidation: {
    data: {},
    isFetching: false,
  },
  paymentApportionments: {
    data: {
      caseTableData: [],
      remainingAmount: 0,
      exceededAmount: 0,
      roundOffApportionment: {},
      returnToDebtor: 0,
      returnFee: 0,
      accountNo: "",
      returnFeeVat: {
        vatAmount: 0,
        vatBasis: 0,
        vatDeducted: 0,
        vatPaidByDebtor: 0,
        amount: 0,
      },
    },
    initialData: {
      caseTableData: [],
      remainingAmount: 0,
      exceededAmount: 0,
      roundOffApportionment: {},
      returnToDebtor: 0,
      returnFee: 0,
      accountNo: "",
      returnFeeVat: {
        vatAmount: 0,
        vatBasis: 0,
        vatDeducted: 0,
        vatPaidByDebtor: 0,
        amount: 0,
      },
    },
    isFetching: false,
    isFetchingApportionments: false,
    isUpdatingApportionments: false,
    drawerInfo: {
      title: "",
      visible: false,
    },
    activeCollapseRawKeys: [],
  },
  revertPayment: {
    revertItemTypeId: 0,
    isPaymentRevert: false,
    revertPaymentPopConfirmStatus: false,
  },
};
