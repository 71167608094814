import React from "react";
import { useTranslation } from "react-i18next";
import { $Popconfirm } from "us.common/components";
import { IItemMenu } from "./interface";
import { EditOutlined, CopyOutlined, DeleteOutlined } from "us.icons";
import { CoveringSequenceAction } from "us.collection.economy/components/CoveringSequence/Constants";

/**
 * @description - Covering Sequence Table Item Menu
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3054534676/Covering+Sequence+List
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 18/07/2022
 */
const ItemMenu: React.FC<IItemMenu> = ({ onCallAction, isUsed }) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content" data-testid="popover-content">
      <div
        className="d-flex  flex-row more-item"
        data-testid="edit-btn"
        onClick={() => onCallAction(CoveringSequenceAction.EDIT)}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("COMMON.EDIT")}</div>
      </div>
      <div
        className="d-flex  flex-row more-item"
        data-testid="copy-btn"
        onClick={() => onCallAction(CoveringSequenceAction.COPY)}
      >
        <div className="p-1">
          <CopyOutlined />
        </div>
        <div className="p-1">{t("COMMON.COPY")}</div>
      </div>
      {!isUsed && (
        <$Popconfirm
          title={t("US.COLLECTION.COMMON:COMMON.SURE_TO_DELETE_?")}
          onConfirm={() => onCallAction(CoveringSequenceAction.DELETE)}
        >
          <div className="d-flex mb-2 flex-row more-item" data-testid="delete-btn">
            <div className="p-1">
              <DeleteOutlined />
            </div>
            <div className="p-1">{t("COMMON.DELETE")}</div>
          </div>
        </$Popconfirm>
      )}
    </div>
  );
};

export default ItemMenu;
