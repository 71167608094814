import { initialState } from "./State";
import { ClientOrders } from "us.collection.economy/constants/Actions";
import { sortData } from "us.collection.economy/functions";
const {
  SEARCH_CLIENT_ORDERS,
  SEARCH_CLIENT_ORDERS_SUCCESS,
  SEARCH_CLIENT_ORDERS_FAILED,

  GET_ORDER_TYPES,
  GET_ORDER_TYPES_SUCCESS,
  GET_ORDER_TYPES_FAILED,

  EXPORT_ORDERS,
  EXPORT_ORDERS_SUCCESS,
  EXPORT_ORDERS_FAILED,

  SORT_ORDERS,

  GENERATE_ORDERS,
  GENERATE_ORDERS_SUCCESS,
  GENERATE_ORDERS_FAILED,
  HANDLE_ORDER_GENERATE_DRAWER
} = ClientOrders;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case SEARCH_CLIENT_ORDERS:
      return Object.assign({}, state, {
        isLoadingOrders: true,
        totalOrders: 0,
        totalOrderAmount: 0,
        orders: [],
      });
    case SEARCH_CLIENT_ORDERS_SUCCESS:
      const { clientOrders, totalOrders, totalOrderAmount } = payload.data;
      return Object.assign({}, state, {
        orders: clientOrders,
        isLoadingOrders: false,
        totalOrders,
        totalOrderAmount,
      });
    case SEARCH_CLIENT_ORDERS_FAILED:
      return Object.assign({}, state, {
        isLoadingOrders: false,
      });
    case SORT_ORDERS:
      return Object.assign({}, state, {
        orders: [...sortData(state.orders, payload.data)],
      });
    case GET_ORDER_TYPES:
      return Object.assign({}, state, {
        isLoadingOrdersTypes: true,
        orderTypes: [],
      });
    case GET_ORDER_TYPES_SUCCESS:
      return Object.assign({}, state, {
        isLoadingOrdersTypes: false,
        orderTypes: payload.data,
      });
    case GET_ORDER_TYPES_FAILED:
      return Object.assign({}, state, {
        isLoadingOrdersTypes: false,
        orderTypes: [],
      });

    case EXPORT_ORDERS: {
      return Object.assign({}, state, {
        orderExport: {
          ...state.orderExport,
          isLoading: true,
          result: null,
        },
      });
    }
    case EXPORT_ORDERS_SUCCESS: {
      return Object.assign({}, state, {
        orderExport: {
          ...state.orderExport,
          isLoading: false,
          result: payload.data,
        },
      });
    }
    case EXPORT_ORDERS_FAILED: {
      return Object.assign({}, state, {
        orderExport: {
          ...state.orderExport,
          isLoading: false,
          result: payload.data,
        },
      });
    }
    case GENERATE_ORDERS:
      return Object.assign({}, state, {
        generateOrder: {
          ...state.generateOrder,
          result: null,
          isLoading: true,
        },
      });
    case GENERATE_ORDERS_SUCCESS:
      return Object.assign({}, state, {
        generateOrder: {
          isDrawerVisible: false,
          result: payload.data,
          isLoading: false,
        },
      });
    case GENERATE_ORDERS_FAILED:
      return Object.assign({}, state, {
        generateOrder: {
          isDrawerVisible: false,
          result: null,
          isLoading: false,
        },
      });
    case HANDLE_ORDER_GENERATE_DRAWER:
      return Object.assign({}, state, {
        generateOrder: {
          ...state.generateOrder,
          isDrawerVisible: !state.generateOrder.isDrawerVisible,
        },
      });

    default:
      return state;
  }
};
