import { httpCollection } from "us.helper/http/collection";

export default {
  regPayments: {
    getPaymentTypes: (params: string): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.getById(
            "paymentservice",
            "payments/types",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getVoucherNo: (params: string): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.getById(
            "paymentservice",
            "payments/voucherno",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getValidateCaseId: (params: number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.getById(
            "arservice",
            "ars/cases",
            `${params}/validationresult`
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    saveRegisterPayment: (params: any): Promise<any> => {
      return new Promise<{}>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "paymentservice",
            "payment/voucher",
            params
          );
          if (status === 200 || status === 201) {
            resolve(data);
          } else {
            reject(data);
          }
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
