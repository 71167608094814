import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  $Button,
  $InputAmount,
  $Form,
  $AmountLabel,
  $Popconfirm,
  $DatePicker,
  $Divider,
  $Alert,
} from "us.common/components";
import { connect } from "react-redux";
import { Formik } from "formik";
import { IRootState } from "us.collection/interfaces";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { managePayment } from "us.collection.economy/actions";
import { PaymentHelper } from "us.collection.economy/components/ManagePayments/Functions";
import { DebtorInfo, AsyncCaseNoInput } from "./Components";
import {
  GetPayments,
  SaveEditPayment,
} from "us.collection.economy/components/ManagePayments/Repository";
import {
  bureauOwnType,
  DATE_FORMAT_FOR_SERVER,
} from "us.collection.economy/constants/Payments";
import { editValidation } from "./Validations";
import { IEdit } from "./Interfaces";
import { IPayment } from "us.collection.economy/components/ManagePayments/interface";

const { clearValidateCaseData, editPayment } = managePayment;
const { getPayment, getExceededOrUnknownItem } = PaymentHelper;

const EditPayment: React.FC<IEdit> = (props) => {
  const { t } = useTranslation();
  const {
    mainFilters,
    payments,
    isSaving,
    currentLanguage,
    currentCurrency,
    currentDateFormat,
    isCaseNoValidating,
    validationCaseIdResults,
    clearValidateCaseData,
    editPayment,
  } = props;

  const { search } = useLocation();
  const params = queryString.parse(search);

  const [isKeyPressed, setKeyPressed] = useState<boolean>(false);

  const formRef = useRef<any>();
  const resetBtnRef = useRef<any>();
  const submitBtnRef = useRef<any>();
  const okBtnRef = useRef<any>();

  const paymentDetails: IPayment = getPayment(
    Number(params?.id),
    payments.data.raw
  )?.payment;
  const {
    itemType,
    voucherDate,
    amount,
    paymentId,
    kid,
    paymentTypeName,
    minimumExceedAmount,
  } = paymentDetails ?? {};

  const isCaseNoValid = Object.keys(validationCaseIdResults).length
    ? itemType == bureauOwnType
      ? validationCaseIdResults?.isCreditorInvoice &&
        validationCaseIdResults.arBalance > 0
      : validationCaseIdResults.arBalance > 0
    : false;

  useEffect(() => {
    !isSaving && clearValidateCaseData();
    !isSaving && formRef.current?.resetForm();
  }, [isSaving]);

  useEffect(() => {
    if (isKeyPressed && !isCaseNoValidating) {
      setTimeout(() => {
        submitBtnRef.current?.focus();
      }, 1);
      setKeyPressed(false);
    }
  }, [isCaseNoValidating]);

  // Handle edit submit
  const handleSubmit = (values: any, actions: any) => {
    if (actions.isValidating || isCaseNoValidating) return;
    actions.setSubmitting(true);
    const paymentDistribution = getExceededOrUnknownItem(paymentDetails);
    const data = {
      ...values,
      caseNo: Number(values.caseNo),
      paymentId,
      voucherDate: moment(values.voucherDate).format(DATE_FORMAT_FOR_SERVER),
      paymentStatus: paymentDistribution?.paymentStatus,
      paymentArItemNo: paymentDistribution?.arItemNo,
    };
    const searchFilters = GetPayments.call(mainFilters);
    const edit = SaveEditPayment.call(data);
    editPayment(edit, searchFilters);
  };

  const isRoundOff = (paymentAmount: number, caseBalance: number) => {
    const exceedAmount = paymentAmount - caseBalance;
    return minimumExceedAmount >= exceedAmount && exceedAmount > 0;
  };

  return (
    <Formik
      initialValues={{
        caseNo: "",
        voucherDate: voucherDate ? moment(voucherDate) : "",
        paymentAmount: amount ?? 0,
      }}
      enableReinitialize
      innerRef={formRef}
      validateOnMount
      validationSchema={editValidation}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }) => (
        <>
          <$Form className="w-100" onSubmit={handleSubmit}>
            <div className="map-action-wraper d-flex align-items-start flex-column">
              <div className="map-action-content mb-auto ">
                <h2>
                  {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EDIT_PAYMENT")}
                  <$Divider className="bui-devider" type="vertical" />
                  <span
                    className="font-weight-bold "
                    data-testid="label-payment-id"
                    style={{ fontSize: "1rem" }}
                  >
                    {" "}
                    {t(
                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_ID"
                    )}: {paymentId}
                  </span>
                </h2>
                <div className="px-3 py-2 mb-3 edit-pay-summary">
                  <div className="mb-2">
                    <div>{t("COMMON.KID")}</div>
                    <strong>{kid ?? " - "}</strong>
                  </div>
                  <div className="d-flex justify-content-between">
                    <div>
                      <div>
                        {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAID_AMOUNT")}
                      </div>
                      <strong data-testid="label-payment-amount">
                        <$AmountLabel value={amount} />
                      </strong>
                    </div>
                    <div className="px-2">
                      <div>
                        {t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_TYPE"
                        )}
                      </div>
                      <strong data-testid="label-payment-type">
                        {paymentTypeName ?? "-"}
                      </strong>
                    </div>
                  </div>
                </div>

                <$Divider />
                <div className="flex-fill">
                  <div className="d-flex mb-3">
                    <div className="d-flex flex-column mr-3">
                      <$InputAmount
                        label={t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.AMOUNT"
                        )}
                        min={0}
                        placeholder="0,00"
                        size="small"
                        required
                        name="paymentAmount"
                        dataTestid="input-payment-amount"
                        value={values.paymentAmount}
                        className="w-100"
                        currentLanguage={currentLanguage}
                        currentCurrency={currentCurrency}
                      />
                    </div>
                    <div className="d-flex flex-column mr-3">
                      <$DatePicker
                        name={`voucherDate`}
                        size="small"
                        label={t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VOUCHER_DATE"
                        )}
                        value={values.voucherDate}
                        required
                        allowClear={true}
                        style={{ width: "100%" }}
                        placeholder={currentDateFormat}
                        format={currentDateFormat}
                        onChange={(date: any) => {
                          !date && rest.setFieldTouched("voucherDate", true);
                          !date && rest.setFieldValue("voucherDate", "");
                          date &&
                            rest.setFieldValue(
                              "voucherDate",
                              moment(date, currentDateFormat)
                            );
                        }}
                        disabledDate={(current: any) => {
                          return current && current > moment();
                        }}
                      />
                    </div>
                    <AsyncCaseNoInput
                      label={t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NO")}
                      type="text"
                      size="small"
                      name="caseNo"
                      onKeyDown={(e: any) => {
                        if (
                          (e.keyCode == 13 || e.keyCode == 9) &&
                          values?.caseNo !== ""
                        ) {
                          setTimeout(() => {
                            resetBtnRef.current?.focus();
                          }, 1);
                          setKeyPressed(true);
                        }
                        if (
                          (e.keyCode == 13 || e.keyCode == 9) &&
                          values?.caseNo == ""
                        ) {
                          setTimeout(() => {
                            submitBtnRef.current?.focus();
                          }, 1);
                        }
                      }}
                      payment={paymentDetails}
                    />
                  </div>
                </div>

                {!isCaseNoValidating &&
                  isRoundOff(
                    values.paymentAmount,
                    validationCaseIdResults.arBalance
                  ) && (
                    <$Alert
                      className="my-2"
                      showIcon
                      message={t(
                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REMAINING_AMOUNT_WILL_BE_ROUNDED_OFF"
                      )}
                      type="info"
                    />
                  )}
                <DebtorInfo payment={paymentDetails} />
              </div>
              <div className="map-action-footer flex-column ">
                <div className="d-flex justify-content-between align-items-end">
                  <div className="flex-fill"></div>
                  <div>
                    <$Button
                      type="default"
                      ref={resetBtnRef}
                      data-testid="reset-button"
                      onClick={() => {
                        resetForm();
                        clearValidateCaseData();
                      }}
                      disabled={!rest.isValid || isSubmitting}
                      loading={
                        isSubmitting || isValidating || isCaseNoValidating
                      }
                    >
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RESET")}
                    </$Button>
                  </div>
                  <div className="ml-3">
                    <$Popconfirm
                      title={t("COMMON.SURE_TO_PROCEED_?")}
                      placement="topLeft"
                      data-testid="pop-confirm"
                      okButtonProps={{
                        ref: okBtnRef,
                        dataTestid: "pop-confirm-ok",
                      }}
                      onVisibleChange={(visible: boolean) =>
                        visible &&
                        setTimeout(() => {
                          document
                            ?.querySelector(
                              "[dataTestid=pop-confirm-ok]" as any
                            )
                            ?.focus({ preventScroll: true });
                        }, 1)
                      }
                      onConfirm={() => handleSubmit()}
                      okText={t("COMMON.YES")}
                      cancelText={t("COMMON.NO")}
                      disabled={
                        !rest.isValid ||
                        !rest.dirty ||
                        isSubmitting ||
                        isCaseNoValidating ||
                        (values.caseNo ? !isCaseNoValid : false)
                      }
                    >
                      <$Button
                        type="primary"
                        ref={submitBtnRef}
                        data-testid="submit-button"
                        disabled={
                          !rest.isValid ||
                          !rest.dirty ||
                          isSubmitting ||
                          isCaseNoValidating ||
                          (values.caseNo ? !isCaseNoValid : false)
                        }
                        loading={
                          isSubmitting || isValidating || isCaseNoValidating
                        }
                      >
                        {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CONFIRM")}
                      </$Button>
                    </$Popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </$Form>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { managePayment, common } = state;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  const { payments, editPayment, caseValidation } = managePayment;
  const { data, isFetching } = caseValidation;
  return {
    payments,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    isSaving: editPayment.isSaving,
    validationCaseIdResults: data,
    isCaseNoValidating: isFetching,
  };
};

const mapDispatchToProps = {
  clearValidateCaseData,
  editPayment,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditPayment);
