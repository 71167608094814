import { call, put, takeLatest } from "redux-saga/effects";
import * as API from "us.collection/services";
import { ObsoletedateAction } from "us.collection/actions";
import { ObsoleteDate } from 'us.collection/constants/Actions'
import { $Message } from "us.common/components";


const ObsoleteDateSaga = {
  save: function* (action: any) {
    const params = action.payload.data;
    try {
      const data = yield call(API.Obsoletedate.AddObsoleteDate.save, params);
      yield put(ObsoletedateAction.AddObsoletedate.success(data));
        if (!data?.isError) {
            $Message.success('Saved Successfully.')
        }
    } catch (error) {
      yield put(ObsoletedateAction.AddObsoletedate.fail(error));
    }
  },
}

export default [
  takeLatest(ObsoleteDate.ADD_OBSOLETE_DATE_STRAT , ObsoleteDateSaga.save),
]
