import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  arId: number;
  creditorName: string;
  debtorName: string;
}

const ParentRow: React.FC<Props> = ({ arId, creditorName, debtorName }) => {
  const { t } = useTranslation();
  return (
    <div style={{ fontSize: "0.75rem" }} key={arId} data-testid="parent-row">
      <span className="mr-4">
        {`${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.AR")}:`}
        <span className="font-weight-bold ml-2">{arId}</span>
      </span>
      <span className="mr-4">
        {`${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CREDITOR")}:`}
        <span className="font-weight-bold ml-2">{creditorName}</span>
      </span>
      <span className="mr-4">
        {`${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.DEBTOR")}:`}
        <span className="font-weight-bold ml-2">{debtorName}</span>
      </span>
    </div>
  );
};

export default ParentRow;
