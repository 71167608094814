import { initialState } from "./State";
import { RegisterJournal } from "us.collection.economy/constants/Actions";
import {
  modifyValidationStatus,
  restValidationStatus,
} from "us.collection.economy/functions";
import _ from "lodash";

const {
  SEARCH_LEDGER_ACCOUNTS,
  SEARCH_LEDGER_ACCOUNTS_SUCCESS,
  SEARCH_LEDGER_ACCOUNTS_FAILED,

  SEARCH_REMIT_HISTORY_DETAILS,
  SEARCH_REMIT_HISTORY_DETAILS_SUCCESS,
  SEARCH_REMIT_HISTORY_DETAILS_FAILED,
  RESET_REMIT_HISTORY_DETAILS,

  VALIDATE_JOURNAL_ID,
  VALIDATE_JOURNAL_ID_SUCCESS,
  VALIDATE_JOURNAL_ID_FAILED,

  VALIDATE_CASE_ID,
  VALIDATE_CASE_ID_SUCCESS,
  VALIDATE_CASE_ID_FAILED,

  RESET_JOURNAL_VALIDATION,

  REGISTER_JOURNAL_ENTRY,
  REGISTER_JOURNAL_ENTRY_SUCCESS,
  REGISTER_JOURNAL_ENTRY_FAILED,
} = RegisterJournal;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case SEARCH_LEDGER_ACCOUNTS:
      return Object.assign({}, state, {
        ledgerAccountList: {
          data: [],
          isLoading: true,
        },
      });
    case SEARCH_LEDGER_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        ledgerAccountList: {
          data: payload.data,
          isLoading: false,
        },
      });
    case SEARCH_LEDGER_ACCOUNTS_FAILED:
      return Object.assign({}, state, {
        ledgerAccountList: {
          data: [],
          isLoading: false,
        },
      });
    case SEARCH_REMIT_HISTORY_DETAILS:
      return Object.assign({}, state, {
        remitHistory: {
          data: [],
          isLoading: true,
        },
      });
    case SEARCH_REMIT_HISTORY_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        remitHistory: {
          data: payload.data,
          isLoading: false,
        },
      });
    case SEARCH_REMIT_HISTORY_DETAILS_FAILED:
      return Object.assign({}, state, {
        remitHistory: {
          data: [],
          isLoading: false,
        },
      });
    case RESET_REMIT_HISTORY_DETAILS:
      return Object.assign({}, state, {
        remitHistory: {
          data: [],
          isLoading: false,
        },
      });

    case VALIDATE_JOURNAL_ID:
      return Object.assign({}, state, {
        validation: modifyValidationStatus(state.validation, payload.data),
      });
    case VALIDATE_JOURNAL_ID_SUCCESS:
      return Object.assign({}, state, {
        validation: modifyValidationStatus(state.validation, payload.data),
      });
    case VALIDATE_JOURNAL_ID_FAILED:
      return Object.assign({}, state, {
        validation: state.validation,
      });

    case VALIDATE_CASE_ID:
      return Object.assign({}, state, {
        validation: modifyValidationStatus(state.validation, payload.data),
      });
    case VALIDATE_CASE_ID_SUCCESS:
      return Object.assign({}, state, {
        validation: modifyValidationStatus(state.validation, payload.data),
      });
    case VALIDATE_CASE_ID_FAILED:
      return Object.assign({}, state, {
        validation: state.validation,
      });

    case RESET_JOURNAL_VALIDATION:
      return Object.assign({}, state, {
        validation: restValidationStatus(state.validation, payload.data),
      });

    case REGISTER_JOURNAL_ENTRY:
      return Object.assign({}, state, {
        journalEntryTableInitial: payload.data.values,
        isJournalEntriesSaving: true
      });
    case REGISTER_JOURNAL_ENTRY_SUCCESS:
      return Object.assign({}, state, {
        journalEntryTableInitial: initialState.journalEntryTableInitial,
        isJournalEntriesSaving: false
      });
    case REGISTER_JOURNAL_ENTRY_FAILED:
      return Object.assign({}, state, {
        isJournalEntriesSaving: false
      });

    default:
      return state;
  }
};
