import { ICoveringSequenceActions } from "us.collection.economy/interfaces";
import { CoveringSequence } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

const {
  GET_COVERING_SEQUENCE_LIST,
  GET_COVERING_SEQUENCE_LIST_SUCCESS,
  GET_COVERING_SEQUENCE_LIST_FAIL,
  GET_ITEM_TYPES,
  GET_ITEM_TYPES_SUCCESS,
  GET_ITEM_TYPES_FAIL,
  SAVE_COVERING_SEQUENCE,
  SAVE_COVERING_SEQUENCE_SUCCESS,
  SAVE_COVERING_SEQUENCE_FAIL,
  UPDATE_COVERING_SEQUENCE,
  UPDATE_COVERING_SEQUENCE_SUCCESS,
  UPDATE_COVERING_SEQUENCE_FAIL,
  DELETE_COVERING_SEQUENCE,
  DELETE_COVERING_SEQUENCE_SUCCESS,
  DELETE_COVERING_SEQUENCE_FAIL,
  RESET_COVERING_SEQUENCE_DATA,
  VALIDATE_COVERING_SEQUENCE_NAME,
  VALIDATE_COVERING_SEQUENCE_NAME_SUCCESS,
  VALIDATE_COVERING_SEQUENCE_NAME_FAIL,
  RESET_COVERING_SEQUENCE_NAME_VALIDATION
} = CoveringSequence;

export const coveringSequence: Readonly<IAPIAction & ICoveringSequenceActions> = {
  coveringSequenceList: {
    get: (data) => ({
      type: GET_COVERING_SEQUENCE_LIST,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_COVERING_SEQUENCE_LIST_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_COVERING_SEQUENCE_LIST_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  itemTypes: {
    get: (data) => ({
      type: GET_ITEM_TYPES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_ITEM_TYPES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_ITEM_TYPES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  newCoveringSequence: {
    save: (data) => ({
      type: SAVE_COVERING_SEQUENCE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_COVERING_SEQUENCE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_COVERING_SEQUENCE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    resetDrawerData: () => ({
      type: RESET_COVERING_SEQUENCE_DATA,
    }),
  },
  existingCoveringSequence: {
    update: (data) => ({
      type: UPDATE_COVERING_SEQUENCE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UPDATE_COVERING_SEQUENCE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UPDATE_COVERING_SEQUENCE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  coveringSequenceData: {
    deleteById: (data) => ({
      type: DELETE_COVERING_SEQUENCE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_COVERING_SEQUENCE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_COVERING_SEQUENCE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  coveringSequenceName: {
    getByName: (data) => ({
      type: VALIDATE_COVERING_SEQUENCE_NAME,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: VALIDATE_COVERING_SEQUENCE_NAME_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: VALIDATE_COVERING_SEQUENCE_NAME_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_COVERING_SEQUENCE_NAME_VALIDATION,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
};
