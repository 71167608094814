import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import Common from "us.common";
import { ClientInvoices } from "us.collection.economy/constants/Actions";
import { $Message } from "us.common/components";
import { i18n } from "us.helper";

const { clientInvoices, creditorInvoiceDetails } = Actions;
const { $MessageBox } = Common.Components;
const { searchInvoices, exportInvoices, creditNoteAPI, downloadFile } =
  API.clientInvoices;

const {
  invoices,
  creditNoteDetails,
  creditNote,
  creditNoteExport,
  exportInvoice,
  navisionFile,
} = clientInvoices;

const{
  invoiceDetails
} = creditorInvoiceDetails;
const {
  SEARCH_CLIENT_INVOICES,
  GET_CREDITOR_INVOICE_DETAILS_START,
  SAVE_CREDIT_NOTE_START,
  EXPORT_INVOICES,
  EXPORT_CREDIT_INVOICE,
  DOWNLOAD_NAVISION_FILE,
  REFRESH_CLIENT_INVOICES,
} = ClientInvoices;

export const ClientInvoicesSagas = {
  clientInvoices: {
    search: function* (action: any): any {
      const searchParams = action?.payload?.data ?? {};
      try {
        const data = yield call(searchInvoices, searchParams);
        yield put(invoices.success(data));
      } catch (error) {
        yield put(invoices.fail(new Error()));
      }
    },
    export: function* (action: any): any {
      const { exportParams, searchParams } = action?.payload?.data ?? {};
      try {
        const { result, data } = yield call(
          exportInvoices,
          exportParams,
          searchParams
        );

        if (result.hasOwnProperty("isSuccess") && result.isSuccess) {
          yield put(exportInvoice.success(result));
          yield put(invoices.success(data));
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:MESSAGES.INVOICE_EXPORT_SUCCESSFUL",
            "",
            ""
          );
        } else if (
          result.hasOwnProperty("isSuccess") &&
          !result.isSuccess &&
          result.hasOwnProperty("statusCode") &&
          result.statusCode == 412
        ) {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.INVOICE_ALREADY_EXPORTED_OR_SOURCE_INVOICE_IS_NOT_EXPORTED",
            "",
            ""
          );
          yield put(exportInvoice.fail(new Error()));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.INVOICE_EXPORT_FAILED",
            "",
            ""
          );
          yield put(exportInvoice.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:MESSAGES.INVOICE_EXPORT_FAILED",
          "",
          ""
        );
        yield put(exportInvoice.fail(new Error()));
      }
    },
  },

  creditNote: {
    getDetails: function* (action: any): any {
      const params = action.payload.data;
      const isOpenDrawer = action.payload.isOpenDrawer ?? false;
      try {
        if (isOpenDrawer && creditNoteDetails.handleDrawer)
          yield put(creditNoteDetails.handleDrawer({ isOpenByList: true }));

        const data = yield call(creditNoteAPI.getDetails, params);
        if (
          (data.hasOwnProperty("statusCode") && data?.statusCode === 204) ||
          Object.keys(data).length === 0
        ) {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.INVOICE_NUMBER_DOES_NOT_EXIST",
            "",
            ""
          );
          yield put(creditNoteDetails.fail(new Error()));
        }
        if (data?.isCreditInvoice) {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.THIS_IS_A_CREDIT_INVOICE",
            "",
            ""
          );
          yield put(creditNoteDetails.fail(new Error()));
          return;
        }
        if (
          (data.hasOwnProperty("statusCode") && data?.statusCode === 500) ||
          data?.statusCode === 404
        ) {
          yield put(creditNoteDetails.fail(new Error()));
          return;
        } else {
          yield put(creditNoteDetails.success(data));
        }
      } catch (error) {
        yield put(creditNoteDetails.fail(new Error()));
      }
    },

    save: function* (action: any): any {
      const { creditNoteParams, searchParams } = action.payload.data ?? {};
      const { fail, success } = creditNote;
      try {
        const { result, data } = yield call(
          creditNoteAPI.save,
          creditNoteParams,
          searchParams
        );
        if (
          (result.hasOwnProperty("statusCode") && result?.statusCode === 500) ||
          result?.statusCode === 404
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:VALIDATIONS.SAVE_CREDIT_NOTE_FAILED`,
            "",
            ""
          );
          yield put(fail(new Error()));
        } else {
          yield put(success(result));
          if(Object.keys(searchParams).length != 0) {
          yield put(invoices.success(data));
        } else {
          yield put(invoiceDetails.success(data));
        }
          $MessageBox(
            "success",
            `US.COLLECTION.ECONOMY:MESSAGES.SAVE_CREDIT_NOTE_SUCCESSFULLY`,
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ECONOMY:VALIDATIONS.SAVE_CREDIT_NOTE_FAILED`,
          "",
          ""
        );
        yield put(creditNote.fail(new Error()));
      }
    },
    export: function* (action: any): any {
      const { exportParams, searchParams, exportType, prevInvoiceNo } =
        action.payload.data ?? {};
      const { fail, success } = creditNoteExport;
      try {
        const { result, data } = yield call(
          creditNoteAPI.export,
          exportParams,
          searchParams,
          exportType,
          prevInvoiceNo
        );
        if (
          (result.hasOwnProperty("statusCode") && result?.statusCode === 500) ||
          result?.statusCode === 404
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:VALIDATIONS.EXPORT_CREDIT_INVOICE_FAILED`,
            "",
            ""
          );
          yield put(fail(new Error()));
        } else if (
          result.hasOwnProperty("statusCode") &&
          result?.statusCode === 412
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:VALIDATIONS.PLEASE_EXPORT_THE_ORIGINAL_INVOICE_AND_RETRY`,
            "",
            ""
          );
          yield put(fail(new Error()));
        } else {
          yield put(success(result));
          if(Object.keys(searchParams).length != 0) {
          yield put(invoices.success(data));
        } else {
          yield put(invoiceDetails.success(data));
        }
          $MessageBox(
            "success",
            `US.COLLECTION.ECONOMY:MESSAGES.EXPORT_CREDIT_INVOICE_SUCCESSFULLY`,
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ECONOMY:VALIDATIONS.EXPORT_CREDIT_INVOICE_FAILED`,
          "",
          ""
        );
        yield put(creditNoteExport.fail(new Error()));
      }
    },
  },

  navisionFile: {
    download: function* (action: any): any {
      $Message.loading(i18n.t("COMMON.DOWNLOADING"), 0);
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(downloadFile, params);
        if (status === 200) {
          yield put(navisionFile.success(data));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.NAVISION_FILE_DOWNLOAD_FAILED",
            "",
            ""
          );
          yield put(navisionFile.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:MESSAGES.NAVISION_FILE_DOWNLOAD_FAILED",
          "",
          ""
        );
        yield put(navisionFile.fail(new Error()));
      } finally {
        $Message.destroy();
      }
    },
  },
};

export default [
  takeLatest(SEARCH_CLIENT_INVOICES, ClientInvoicesSagas.clientInvoices.search),
  takeLatest(
    GET_CREDITOR_INVOICE_DETAILS_START,
    ClientInvoicesSagas.creditNote.getDetails
  ),
  takeLatest(SAVE_CREDIT_NOTE_START, ClientInvoicesSagas.creditNote.save),
  takeLatest(EXPORT_INVOICES, ClientInvoicesSagas.clientInvoices.export),
  takeLatest(EXPORT_CREDIT_INVOICE, ClientInvoicesSagas.creditNote.export),
  takeLatest(DOWNLOAD_NAVISION_FILE, ClientInvoicesSagas.navisionFile.download),
  takeLatest(
    REFRESH_CLIENT_INVOICES,
    ClientInvoicesSagas.clientInvoices.search
  ),
];
