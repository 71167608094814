import * as Yup from "yup";

export default () => {
    return Yup.object().shape({
        articleNo: Yup.string()
            .required(
                "US.COLLECTION.VALIDATIONS:REQUIRED.ARTICLE_NO_IS_REQUIRED"
            )
            .test(
                "integer articleNo",
                "US.COLLECTION.VALIDATIONS:INVALID.INVALID_ARTICLE_NO",
                function (val: any) {
                  return /^\d+$/.test(val)
                }
              ),
        vatCodeName: Yup.string()
            .required(
                "US.COLLECTION.VALIDATIONS:REQUIRED.VAT_CODE_IS_REQUIRED"
            ),
        revenueAccountName: Yup.string()
            .required(
                "US.COLLECTION.VALIDATIONS:REQUIRED.REVENUE_ACCOUNT_IS_REQUIRED"
            ),
        nonVATAccountName: Yup.string()
            .required(
                "US.COLLECTION.VALIDATIONS:REQUIRED.NON_VAT_ACCOUNT_IS_REQUIRED"
            ),
        category: Yup.string()
            .required(
                "US.COLLECTION.VALIDATIONS:REQUIRED.CATEGORY_IS_REQUIRED"
            ),
        description: Yup.string().trim()
            .required(
                "US.COLLECTION.VALIDATIONS:REQUIRED.DESCRIPTION_IS_REQUIRED"
            )
    });
};
