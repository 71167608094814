import { OrderGenerationTypes } from "us.collection.economy/components/ClientOrders/Constants";

/**
 * @description get eligible categories for the selection
 * @param categories - order categories
 * @returns - filtered categories
 */
export const getCategories = (
  categories: Array<any>
): Array<any> => {
  try {
    return categories.filter(
      ({ categoryCode }: { categoryCode: string }) =>
        categoryCode !==
          OrderGenerationTypes.CREDITOR_ORDERS &&
        categoryCode !== OrderGenerationTypes.VAT_ORDERS &&
        categoryCode !== OrderGenerationTypes.DEFAULT
    )
  } catch (error) {
    return [];
  }
};
