import moment from "moment";
import {
  CIExportStatus,
  CISearchType,
  ClientInvoiceType,
  CISortType,
} from "us.collection.economy/components/ClientInvoices/Constants";
import { getMonthDateRange } from "us.collection.economy/functions";

export const INITIAL_FORM_VALUES = {
  startDate: getMonthDateRange().startDate,
  endDate: getMonthDateRange().endDate,
  searchType: CISearchType.REG_DATE,
  invoiceNo: undefined,
  invoiceType: ClientInvoiceType.CLIENT_INVOICES,
  creditInvoicesOnly: false,
  exportStatus: CIExportStatus.PENDING_EXPORT,
  customers: [-1],
  customerGroups: undefined,
  offset: 0,
  limit: 1000,
  dateRange: [moment().startOf("month"), moment().endOf("month")],
  duePeriod: [],
  sortType: CISortType.INVOICE_NO,
  filterInvoiceNo: undefined,
  customerNo: undefined,
  isAllInvoices: false,
  selectedInvoices: [],
};
