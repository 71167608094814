import { initialState } from 'us.collection/reducers/PaymentAgreement/State'
import { PaymentAgreement } from 'us.collection/constants';
import _ from 'lodash'
import moment from 'moment'
import { validateNumber } from 'us.common/functions';
// export default (state: any = initialState, action: any) => {
//     let newState = state;
//     switch (action.type) {
//         case PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_SUCCESS:
//             if (action.payload.data) {
//                 newState = {
//                     ...state,
//                     paymentAgreementAmounts: action.payload.data[0],
//                     isFetchingAmounts: action.payload?.isFetching
//                 };
//             }

//             break;
//         case PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_START:
//             if (action.payload.data)
//                 newState = {
//                     ...state,
//                     isFetchingAmounts: action.payload?.isFetching
//                 };
//             break;
//         case PaymentAgreement.GET_PAYMENT_AGREEMENT_DETAIL_SUCCESS:
//             if (action.payload.data) {
//                 let data = action.payload.data?.isError === true || _.isEmpty(action.payload.data) ? details : action.payload.data?.result
//                 newState = {
//                     ...state,
//                     interests:{},
//                     paymentAgreementDetails: data,
//                     isFetchingDetails: action.payload?.isFetching
//                 };
//                 console.log(newState.paymentAgreementDetails)
//             }
//             break;
//         case PaymentAgreement.GET_PAYMENT_AGREEMENT_DETAIL_START:
//             if (action.payload.data)
//                 newState = {
//                     ...state,
//                     isFetchingDetails: action.payload?.isFetching
//                 };
//             break;
//         case PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_START:
//             if (action.payload.data)
//                 newState = {
//                     ...state,
//                     values: action.payload?.data.values
//                 };

//             break;
//         case PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_SUCCESS:
//             if (action.payload.data)
//                 newState = {
//                     ...state,
//                     interests: { ...state?.values, ...action.payload?.data, }
//                 };
//             break;
//         default:
//             break;
//     }
//     return newState;
// }
const totalBalance = (amounts: any) => {
    let total = (
        amounts.collectionBalance +
        amounts.costBalance +
        amounts.courtBalance +
        amounts.mainBalance +
        amounts.otherBalance +
        amounts.interestCostBalance +
        amounts.interestMainBalance);

    return total;

}


const getNumber = (value: any, currentLanguage: string): number => {
    let returnVal = validateNumber(value, currentLanguage);
    return returnVal && returnVal > 0 ? returnVal : 0;
}
const totalAmount = (values: any) => {

    let total = (
        getNumber(values?.collectionFee, values?.currentLanguage) +
        getNumber(values?.costFee, values?.currentLanguage) +
        getNumber(values?.courtFee, values?.currentLanguage) +
        getNumber(values?.otherFee, values?.currentLanguage) +
        getNumber(values?.mainAmount, values?.currentLanguage) +
        values.interestMainBalance +
        values.interestCostBalance);
    return total;
}
const dataMapper = (amounts: any) => {
    return {
        collectionFee: amounts?.collectionBalance,
        costFee: amounts?.costBalance,
        courtFee: amounts?.courtBalance,
        id: 0,
        interestCostAmount: amounts?.interestCostBalance,
        interestDate: moment(),
        interestMainAmount: amounts?.interestMainBalance,
        isSendSMSNotification: false,
        mainAmount: amounts?.mainBalance,
        otherFee: amounts?.otherBalance,
        payDate: moment().add(1, 'days'),
        totalBalance: totalBalance(amounts)
    }
}

export default (state = initialState, { payload, type }: any) => {
    switch (type) {
        case PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_START:
            return Object.assign({}, state, {
                amounts: {
                    data: state.amounts.data,
                    isLoading: true
                },
                details: {
                    data: state.details.data,
                    isLoading: true
                }
            });
        case PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_SUCCESS:
            const { data } = payload;
            const { amounts, details } = data
            return Object.assign({}, state, {
                amounts: {
                    data: {
                        ...amounts[0],
                        totalAmount: totalBalance(amounts[0])
                    },
                    isLoading: false
                },
                details: {
                    data: _.isEmpty(details) ? dataMapper(amounts[0]) : { ...details?.result ,totalBalance:details?.result?.total},
                    isLoading: false
                },
                interests: {
                    data: {}
                }

            });
        case PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_FAIL:
            return Object.assign({}, state, {
                amounts: {
                    data: {},
                    isLoading: false
                },
                details: {
                    data: {},
                    isLoading: false
                },
                interests: {
                    data: {}
                }
            });

        case PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_START:
            return Object.assign({}, state, {
                values: payload?.data.values
            });
        case PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_SUCCESS:

            return Object.assign({}, state, {
                interests: {
                    data: {
                        ...state?.values,
                        interestCostAmount: payload?.data?.interestCostsBalance,
                        interestMainAmount: payload?.data?.interestMainBalance,
                        interestCostBalance: payload?.data?.intersetCostsBalance,
                        interestMainBalance: payload?.data?.interestMainBalance
                    }
                },
                amounts: {
                    data: {
                        ...state?.values,
                        totalAmount: totalBalance({
                            ...state?.values,
                            interestCostAmount: payload?.data?.interestCostsBalance,
                            interestMainAmount: payload?.data?.interestMainBalance,
                            interestCostBalance: payload?.data?.intersetCostsBalance,
                            interestMainBalance: payload?.data?.interestMainBalance
                        })
                    },
                    isLoading: false
                },
                details: {
                    data: {
                        ...state?.values,
                        totalBalance: totalAmount({
                            ...state?.values,
                            interestCostAmount: payload?.data?.interestCostsBalance,
                            interestMainAmount: payload?.data?.interestMainBalance,
                            interestCostBalance: payload?.data?.intersetCostsBalance,
                            interestMainBalance: payload?.data?.interestMainBalance
                        })
                    },
                    isLoading: false
                }
            });
        default:
            return state;
    }
}