import React from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { getRouteUrl } from "us.helper";
import { $Collapse, $Empty, InfiniteScrolling } from "us.common/components";
import { IRootState } from "us.collection/interfaces";
import { managePayment } from "us.collection.economy/actions";
import { PaymentStates } from "us.collection.economy/constants/Payments";
import { PaymentHelper } from "us.collection.economy/components/ManagePayments/Functions";
import {
  ActionTypes,
  IPayment,
  IPaymentLayout,
} from "us.collection.economy/components/ManagePayments/interface";
import {
  Header,
  SubHeader,
  MappedWithOther,
  PaymentBase,
  PaymentItems,
  BaseHeader,
  Footer,
  ReturnWithOther,
  Row,
} from "./components";

const { getPaymentAccounts, searchPayments } = managePayment;

/**
 * Component which manage payments
 */
const PaymentLayout: React.FC<IPaymentLayout> = ({
  activeId = 0,
  values,
  data,
  hasMorePayments,
  onSelectPayment,
  onItemCallAction,
  onViewLinkedPayment,
  onViewPaymentDetails,
  onLoadMore,
  onScrollToFocusedItem,
  onBackToTop,
}: IPaymentLayout) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = queryString.parse(search);

  return (
    <>
      <InfiniteScrolling
        pageLength={data.length}
        handleNextDataLoad={() => {
          onLoadMore();
        }}
        isMoreData={hasMorePayments}
        isExtraOptions
        enableBackToTop
        isDivScroll
        onFocusToItem={onScrollToFocusedItem}
        onBackToTop={onBackToTop}
      >
        {data && data.length > 0 && (
          <$Collapse
            ghost
            accordion
            defaultActiveKey={activeId}
            activeKey={params?.open === "true" ? Number(params?.id) : undefined}
            onChange={(value: string | string[]) =>
              onSelectPayment(value ? Number(value) : undefined)
            }
            collapsible={"header"}
            className="map-payment-collapsed"
          >
            {data.map((mainPaymentItem: IPayment) => {
              const {
                mainStatus,
                mainStatusAmount,
                tagClass,
                tagName,
                headerActions,
                subHeaderActions,
                mappedItems,
                returnedItems,
                isMappedWithOther,
                isReturnedWithOther,
                hasPaymentDistribution,
                isFullyReturn,
              } = new PaymentHelper(mainPaymentItem);
              const {
                paymentId,
                paymentDistribution,
                mappedSum,
                returnedToDebtorSum,
                linkedPaymentId,
              } = mainPaymentItem;
              return (
                <$Collapse.Panel
                  key={paymentId}
                  id={`${paymentId}`}
                  header={
                    <BaseHeader
                      data={mainPaymentItem}
                      priorityTag={PaymentHelper.getPriorityTag(
                        paymentDistribution,
                        values.paymentStates
                      )}
                      onViewLinkedPayment={() => {
                        PaymentHelper.setLinkedPaymentId(linkedPaymentId);
                        onViewLinkedPayment({
                          linkedPaymentId,
                        });
                      }}
                    />
                  }
                >
                  <PaymentBase
                    amount={mainStatusAmount}
                    tagName={tagName}
                    tagClass={tagClass}
                    isUnknownOld={
                      linkedPaymentId > 0 &&
                      mainStatus === PaymentStates.UNKNOWN
                    }
                  >
                    {/*render if has header with action*/}
                    {![
                      PaymentStates.UNKNOWN,
                      PaymentStates.EXCEEDED,
                      PaymentStates.RETURNED,
                    ].includes(mainStatus) && (
                      <Header
                        actions={
                          linkedPaymentId > 0 &&
                          mainStatus === PaymentStates.UNKNOWN
                            ? []
                            : headerActions
                        }
                        onAction={(action) =>
                          onItemCallAction({
                            action,
                            mainPaymentItem,
                          })
                        }
                      />
                    )}
                    {/*render if has sub header with some actions(Apportion/Revert/Edit etc)*/}
                    {[PaymentStates.UNKNOWN, PaymentStates.EXCEEDED].includes(
                      mainStatus
                    ) && (
                      <SubHeader
                        actions={
                          linkedPaymentId > 0 &&
                          mainStatus === PaymentStates.UNKNOWN
                            ? []
                            : subHeaderActions
                        }
                        label={`${
                          mainStatus === PaymentStates.UNKNOWN
                            ? t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN_AMOUNT"
                              )
                            : t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED_AMOUNT"
                              )
                        }`}
                        onAction={(action) =>
                          onItemCallAction({
                            action,
                            mainPaymentItem,
                          })
                        }
                        amount={mainStatusAmount}
                      />
                    )}
                    {/*render if has mapped items*/}
                    {mainStatus === PaymentStates.MAPPED && (
                      <PaymentItems payments={mappedItems} />
                    )}
                    {/*render if has returned items*/}
                    {mainStatus === PaymentStates.RETURNED && (
                      <div className="map-item-table pr-2">
                        {returnedItems?.map((payment: any) => {
                          const {
                            arItemNo,
                            amount,
                            transactionItemTypeId,
                            returnedDate,
                          } = payment;
                          return (
                            <Row
                              key={arItemNo}
                              amount={amount}
                              paymentId={arItemNo}
                              itemTypeId={transactionItemTypeId}
                              label={
                                transactionItemTypeId == 25
                                  ? t(
                                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_FEE"
                                    )
                                  : `${t(
                                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO"
                                    )} ${mainPaymentItem.accountNo ?? ""}`
                              }
                              returnedDate={returnedDate}
                              onRevert={() => {
                                onItemCallAction({
                                  action: ActionTypes.REVERT_TO_PAYMENT,
                                  payment,
                                  mainPaymentItem,
                                });
                              }}
                            />
                          );
                        })}
                      </div>
                    )}
                    {/*render if has returned items with other status*/}
                    {isReturnedWithOther && (
                      <ReturnWithOther
                        amount={returnedToDebtorSum}
                        accountNo={mainPaymentItem.accountNo}
                        items={returnedItems}
                        onRevertItem={(payment) => {
                          onItemCallAction({
                            action: ActionTypes.REVERT_TO_PAYMENT,
                            payment,
                            mainPaymentItem,
                          });
                        }}
                      />
                    )}
                    {/*render if has mapped items with other status*/}
                    {isMappedWithOther && (
                      <MappedWithOther
                        amount={mappedSum}
                        items={mappedItems}
                        onRevertAll={(payment) => {
                          //'Revert All'
                          onItemCallAction({
                            action: ActionTypes.REVERT_PAYMENT,
                            payment,
                            mainPaymentItem,
                          });
                        }}
                        onRevertItem={(payment) => {}}
                      />
                    )}
                    {/*render footer component - not includes in Exceeded and Unknown */}
                    {!(
                      [PaymentStates.EXCEEDED, PaymentStates.UNKNOWN].includes(
                        mainStatus
                      ) && !isMappedWithOther
                    ) &&
                      !isFullyReturn && (
                        <Footer
                          onViewDistributions={() =>
                            getRouteUrl.paymentDistribution(paymentId)
                          }
                          onViewPaymentDetails={() =>
                            onViewPaymentDetails(mainPaymentItem)
                          }
                          withViewDistribution={hasPaymentDistribution}
                        />
                      )}
                  </PaymentBase>
                </$Collapse.Panel>
              );
            })}
          </$Collapse>
        )}
      </InfiniteScrolling>

      {!data ||
        (data.length === 0 && <$Empty image={$Empty.PRESENTED_IMAGE_SIMPLE} />)}
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { managePayment, common } = state;

  const { payments, paymentsWithAcc, paymentAccounts, paymentDistribution } =
    managePayment;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  return {
    payments,
    paymentsWithAcc,
    paymentAccounts,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    paymentDistribution,
  };
};

const mapDispatchToProps = {
  getPaymentAccounts,
  searchPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentLayout);
