import React, { useMemo } from "react";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { INewLedgerAccount } from "../../Interfaces";
import { ValidationSchema } from "../../Validations";
import { SaveLedgerAccount } from "../../Repository";
import {
  getLedgerAccountRecordDetailsById,
  getButtonTitle,
} from "../../Functions";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "us.collection.economy/actions";
import { Action } from "../../Constants";
import { useLocation } from "react-router-dom";
import { isFormDirty } from "us.helper";

const { $TextArea, $Switch, $Button, $Form, $Row, $Col, $Input, $AsyncInput } =
  Common.Components;

/**
 * @description - Ledger Account Detail View
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3094577191/Ledger+List+-+UI+Implementation
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 08/31/2022
 */
const Details: React.FC<INewLedgerAccount & PropsFromRedux> = ({
  addNewLedgerAccount,
  validateAccountNo,
  editLedgerAccount,
  onCancel,
  accountsList,
  saveLedgerAccount,
  duplicateValidation,
}) => {
  const { t } = useTranslation();
  const parentValues = useFormikContext<any>();
  const { search } = useLocation();

  const urlParams = new URLSearchParams(search);
  const action = urlParams.get("action");
  const isEdit = action === Action.EDIT;
  const accountId = urlParams.get("accountId");
  const initialValues = useMemo(
    () =>
      getLedgerAccountRecordDetailsById(accountsList, Number(accountId)) ?? {},
    [accountsList, accountId]
  );

  /**
   * @description - handle submit button actions
   * @param {any} values
   */
  const handleSubmit = (values: any, actions: any) => {
    if (actions.isValidating || duplicateValidation.isValidating) return;

    const saveParams = SaveLedgerAccount.call({ ...values, accountId });
    if (isEdit) {
      editLedgerAccount &&
        editLedgerAccount({
          saveParams,
          searchText: parentValues.values?.searchText,
        });
    } else {
      addNewLedgerAccount &&
        addNewLedgerAccount({
          saveParams,
          searchText: parentValues.values?.searchText,
        });
    }
  };

  /**
   * @description - Check whether the form is valid
   * @param {any} restProps - The rest of form properties
   * @param {any} values - Form values
   */
  const isFormValid = (restProps: any, values: any) => {
    return (
      !restProps.isValid ||
      saveLedgerAccount.isLoading ||
      duplicateValidation.isValidating ||
      !duplicateValidation.status ||
      !isFormDirty(values, initialValues)
    );
  };

  return (
    <Formik
      initialValues={{
        ...initialValues,
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      validationSchema={ValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical">
            <div className="d-flex align-items-center mb-4">
              <$Switch
                name="isSystem"
                checked={values?.isSystem}
                disabled={initialValues?.isSystem}
                size="small"
              />
              <label className="ml-2">
                {t("US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.SYSTEM")}
              </label>
            </div>
            <$Row gutter={16} className="mb-2">
              <$Col span={24}>
                <$AsyncInput
                  label={t(
                    "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.ACCOUNT_NUMBER"
                  )}
                  className="w-100"
                  name="accountNo"
                  size="small"
                  required={true}
                  isNumber={true}
                  maxLength={4}
                  allowClear
                  onBlur={(e: any) => {
                    if (e.target.value !== "" && e.target.value != 0) {
                      const accountNum = e.target.value.replace(/ /g, "");
                      validateAccountNo && validateAccountNo(accountNum);
                    }
                  }}
                  isValid={duplicateValidation.status}
                  asyncError={t(
                    "US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_ACCOUNT_NUMBER"
                  )}
                  loading={duplicateValidation.isValidating}
                  disabled={
                    initialValues?.isSystem ||
                    isEdit ||
                    duplicateValidation.isValidating
                  }
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-2">
              <$Col span={24}>
                <$Input
                  required
                  size="small"
                  name="accountName"
                  disabled={initialValues?.isSystem}
                  label={t(
                    "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.ACCOUNT_NAME"
                  )}
                />
              </$Col>
            </$Row>
            <div>
              <$TextArea
                className="w-100"
                label={t("US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.DESCRIPTION")}
                name="description"
                required={true}
                disabled={initialValues?.isSystem}
                autoSize={{ minRows: 4, maxRows: 6 }}
                maxlength={200}
                data-testid="description"
              />
            </div>
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              {!initialValues?.isSystem && (
                <$Button
                  className="ml-3 mr-2"
                  type="primary"
                  disabled={isFormValid(restProps, values)}
                  loading={saveLedgerAccount.isLoading}
                  onClick={handleSubmit}
                  data-testid="save-btn"
                >
                  {t(getButtonTitle(isEdit))}
                </$Button>
              )}
              <$Button onClick={() => onCancel()}>
                {t("US.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { ledgerAccounts } = state;

  const { list, isListLoading, saveLedgerAccount, duplicateValidation } =
    ledgerAccounts;
  return {
    accountsList: list,
    isListLoading,
    saveLedgerAccount,
    duplicateValidation,
  };
};
const { newAccount, validateField, existingAccount } = Actions.ledgerAccounts;

const mapDispatchToProps = {
  addNewLedgerAccount: newAccount.save,
  editLedgerAccount: existingAccount.update,
  validateAccountNo: validateField.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Details);
