import { httpCollection } from "us.helper/http/collection";

export default {
  journal: {
    searchLedgerAccounts: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "economysettingservice",
            `ledgeraccounts?searchtext=All`,
            {}
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    saveJournalEntry: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "economysettingservice",
            `journalentry`,
            params
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  validate: {
    validateCaseId: ({value}: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.getById(
            "arservice",
            "ars/cases",
            `${value}/validationresult`
          );
          resolve({data, status});
        } catch (error) {
          reject(error);
        }
      });
    },
    validateJournalId: ({value}: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "economysettingservice",
            `journalentry/validate/${value}`,
            {}
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
