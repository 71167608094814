import { IOrderPayload } from "us.collection.economy/interfaces/ClientOrders";

export function ExportOrders(this: any): Array<IOrderPayload> {

  return this.selectedOrders?.map(
    ({
      orderNo,
      customerNo,
      orderType,
    }: {
      orderNo: number | string;
      customerNo: number;
      orderType: string;
    }) => {
      return {
        orderNo: orderNo,
        customerNo: customerNo,
        orderType: orderType == "VAT" ? "V" : "C",
      };
    }
  );
}
