import * as Yup from "yup";
import moment from "moment";

export default () => {
  return Yup.object().shape({
    paymentType: Yup.number()
      .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.DIRECT_PAYMENT_TYPE_IS_REQUIRED"
      )
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_PAYMENT_TYPE")
      .test(
        "paymentType",
        "US.COLLECTION.VALIDATIONS:REQUIRED.DIRECT_PAYMENT_TYPE_IS_REQUIRED",
        (val: any) => {
          return val != undefined;
        }
      ),
    // paymentMethod: Yup.string()
    // .test(
    //   'paymentMethod',
    //   'US.COLLECTION.VALIDATIONS:REQUIRED.PAYMENT_METHOD_IS_REQUIRED',
    //   function (val:any) {
    //     return this.parent.paymentType != 11;
    //   }
    // ),
    datePaid: Yup.date()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.PAID_DATE_IS_REQUIRED")
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_DATE")
      .test(
        "datePaid",
        "US.COLLECTION.VALIDATIONS:REQUIRED.CANNOT_SELECT_FUTURE_DATE",
        (val: any) => {
          return val < moment();
        }
      ),
    kid: Yup.number().typeError(
      "US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_CASE_NO"
    ),
    accountNo: Yup.number().typeError(
      "US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_ACCOUNT_NO"
    ),
    voucherAmount: Yup.number()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.VOUCHER_AMOUNT_IS_REQUIRED")
      .moreThan(
        0,
        "US.COLLECTION.VALIDATIONS:REQUIRED.VOUCHER_AMOUNT_MUST_BE_POSITIVE"
      )
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_VOUCHER_AMOUNT")
      .test(
        "voucherAmount",
        "US.COLLECTION.VALIDATIONS:REQUIRED.VOUCHER_AMOUNT_IS_REQUIRED",
        (val: any) => {
          return val != 0;
        }
      ),
    caseNo: Yup.number().when("paymentType", {
      is: (val) => val == 57,
      then: Yup.number().notRequired(),
      otherwise: Yup.number()
        .required("US.COLLECTION.VALIDATIONS:REQUIRED.CASE_NO_IS_REQUIRED")
        .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_CASE_NO"),
    }),
    amount: Yup.number().when("paymentType", {
      is: (val) => val == 57,
      then: Yup.number().notRequired(),
      otherwise: Yup.number()
        // .required("US.COLLECTION.VALIDATIONS:REQUIRED.AMOUNT_IS_REQUIRED")
        .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_AMOUNT")
        .test(
          "amount",
          "US.COLLECTION.VALIDATIONS:REQUIRED.AMOUNT_IS_REQUIRED",
          function (val: any) {
            return (!(val == 0 && this.parent.caseNo !== 0) && !(val == undefined && this.parent.caseNo !== 0) && !(val == '' && this.parent.caseNo !== 0))
          }
        )
        .test(
          "amount",
          "US.COLLECTION.VALIDATIONS:REQUIRED.AMOUNT_MUST_BE_POSITIVE",
          (val: any) => {
            return !(val < 0 && val !== undefined);
          }
        )
        .test(
          "amount",
          "US.COLLECTION.VALIDATIONS:REQUIRED.CANT_EXCEED_VOUCHER_AMOUNT",
          function (val: any) {
            return !(val > this.parent.voucherAmount && val !== undefined);
          }
        ),
    }),
  });
};
