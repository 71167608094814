import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentHelper } from "us.collection.economy/components/ManagePayments/Functions";
import {
  ActionTypes,
  IActionTypes,
} from "us.collection.economy/components/ManagePayments/interface";
import {
  $Button,
  $Tooltip,
  $Popover,
  $TextArea,
  $Popconfirm,
} from "us.common/components";
import { BoxIcons, IconTypes } from "us.icons";
import { useField } from "formik";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useDispatch, useSelector } from "react-redux";
import { managePayment } from "us.collection.economy/actions";

interface Props {
  actions: IActionTypes[];
  onAction: (actionType: IActionTypes) => void;
  paymentId?: number;
  uniqueRevertId?: number;
}

const { revertPaymentPopconfirmStatus } = managePayment;

const ActionsBar: React.FC<Props> = ({
  actions,
  onAction,
  paymentId,
  uniqueRevertId,
}) => {
  const [field, meta, helpers] = useField("comment");
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const [popoverStatus, setPopoverStatus] = useState(false);
  const dispatch = useDispatch();
  const revertPaymentInfo = useSelector(
    (state: any) => state.managePayment.revertPayment
  );

  useEffect(() => {
    if (params?.open === "false") {
      setPopoverStatus(false);
    }
  }, [params?.open]);

  useEffect(() => {
    setPopoverStatus(false);
  }, [paymentId]);

  return (
    <div>
      {actions.map((action: IActionTypes, index: number) => {
        const { actionName, iconName } = PaymentHelper.getPaymentAction(action);
        return (
          <$Tooltip key={index} title={t(actionName)}>
            {action == ActionTypes.REVERT_PAYMENT && params?.open === "true" && (
              <$Popconfirm
                title={
                  <div>
                    <p>{t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_REVERT_?")}</p>
                    <$TextArea
                      style={{ width: "250px" }}
                      required
                      placeholder={t("COMMON.COMMENT")}
                      name="comment"
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={150}
                    />
                  </div>
                }
                onVisibleChange={(visible: any) => {
                  if (!visible) {
                    setPopoverStatus(false);
                  }
                }}
                okButtonProps={{
                  disabled: meta.value == undefined || meta.value == "",
                }}
                destroyTooltipOnHide
                placement="topLeft"
                onConfirm={() => {
                  if (meta.value != undefined || meta.value != "") {
                    onAction(action);
                    setPopoverStatus(false);
                    dispatch(
                      revertPaymentPopconfirmStatus(false, uniqueRevertId)
                    );
                  } else {
                    helpers.setTouched(true);
                  }
                }}
                onCancel={() => {
                  helpers.setTouched(false);
                  helpers.setValue("");
                  setPopoverStatus(false);
                  dispatch(
                    revertPaymentPopconfirmStatus(false, uniqueRevertId)
                  );
                }}
                okText={t("COMMON.YES")}
                cancelText={t("COMMON.NO")}
                visible={popoverStatus}
              >
                <$Button
                  name="revertBtn"
                  onClick={() => {
                    helpers.setTouched(false);
                    helpers.setValue("");
                    setPopoverStatus(!popoverStatus);
                    dispatch(
                      revertPaymentPopconfirmStatus(
                        !popoverStatus,
                        uniqueRevertId
                      )
                    );
                  }}
                  size="small"
                  className="mr-1"
                  data-testid="revert-button"
                  icon={<BoxIcons type={IconTypes.BOX_ICON} name={iconName} />}
                  loading={
                    revertPaymentInfo?.isPaymentRevert &&
                    revertPaymentInfo?.revertItemTypeId == uniqueRevertId
                  }
                />
              </$Popconfirm>
            )}
            {action != ActionTypes.REVERT_PAYMENT && (
              <$Button
                onClick={() => onAction(action)}
                size="small"
                className="mr-1"
                icon={<BoxIcons type={IconTypes.BOX_ICON} name={iconName} />}
                data-testid={action}
              />
            )}
          </$Tooltip>
        );
      })}
    </div>
  );
};

export default ActionsBar;
