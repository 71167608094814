import * as Yup from "yup";
import { CISearchType } from "us.collection.economy/components/ClientInvoices/Constants";

export default () => {
  return Yup.object().shape({
    invoiceNo: Yup.number().when("searchType", {
      is: (searchType) => searchType === CISearchType.INVOICE_NO,
      then: Yup.number()
        .required("US.COLLECTION.ECONOMY:VALIDATIONS.INVOICE_NO_REQUIRED")
        .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_INVOICE_NO"),
      otherwise: Yup.number().notRequired(),
    }),

    filterInvoiceNo: Yup.number()
      .notRequired()
      .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_INVOICE_NO")
      .test(
        "is_a_number",
        "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_INVOICE_NO",
        (val: any) => /^\d+$/.test(val) || val === "" || val === undefined
      ),

    customerNo: Yup.number()
      .notRequired()
      .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_CUSTOMER_NO")
      .test(
        "is_a_number",
        "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_CUSTOMER_NO",
        (val: any) => /^\d+$/.test(val) || val === "" || val === undefined
      ),
  });
};
