import { initialState } from "./State";
import { ItemTypes } from "us.collection.economy/constants/Actions";
import {
  ValidateFieldNames,
  BreadcrumbType,
} from "us.collection.economy/components/ItemTypes/Constants";
import {
  formatItemTypeDetails,
  formatVatCodes,
  sortBankAccounts,
  getDataAfterAddNewSequence,
} from "us.collection.economy/functions";
import _ from "lodash";

const {
  INIT_ITEM_TYPES_HOME,
  INIT_ITEM_TYPES_HOME_SUCCESS,
  INIT_ITEM_TYPES_HOME_FAILED,

  SEARCH_ITEM_TYPES,
  SEARCH_ITEM_TYPES_SUCCESS,
  SEARCH_ITEM_TYPES_FAILED,

  DELETE_ITEM_TYPE,

  GET_TRANSACTION_GROUPS,
  GET_TRANSACTION_GROUPS_SUCCESS,
  GET_TRANSACTION_GROUPS_FAILED,

  ADD_NEW_VOUCHER_SEQUENCE,
  ADD_NEW_VOUCHER_SEQUENCE_SUCCESS,
  ADD_NEW_VOUCHER_SEQUENCE_FAILED,

  HANDLE_ITEM_TYPE_DRAWER,
  HANDLE_ITEM_TYPE_DRAWER_BREADCRUMB,
  RESET_ITEM_TYPE_DETAILS,

  GET_INIT_ITEM_TYPE_DATA,
  GET_INIT_ITEM_TYPE_DATA_SUCCESS,
  GET_INIT_ITEM_TYPE_DATA_FAILED,

  GET_ITEM_TYPE_DETAILS,
  GET_ITEM_TYPE_DETAILS_SUCCESS,
  GET_ITEM_TYPE_DETAILS_FAILED,

  DUPLICATE_VALIDATE_FIELDS,
  DUPLICATE_VALIDATE_FIELDS_SUCCESS,
  DUPLICATE_VALIDATE_FIELDS_FAILED,

  ADD_NEW_ITEM_TYPE,
  ADD_NEW_ITEM_TYPE_SUCCESS,
  ADD_NEW_ITEM_TYPE_FAILED,

  EDIT_ITEM_TYPE,
  EDIT_ITEM_TYPE_SUCCESS,
  EDIT_ITEM_TYPE_FAILED,
} = ItemTypes;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case INIT_ITEM_TYPES_HOME:
      return Object.assign({}, state, {
        itemTypes: {
          isLoading: true,
          data: [],
        },
        transactionGroups: {
          isLoading: true,
          data: [],
        },
      });
    case INIT_ITEM_TYPES_HOME_SUCCESS:
      const { itemTypes, transactionGroups } = payload.data;
      return Object.assign({}, state, {
        itemTypes: {
          isLoading: false,
          data: itemTypes,
        },
        transactionGroups: {
          isLoading: false,
          data: transactionGroups,
        },
      });
    case INIT_ITEM_TYPES_HOME_FAILED:
      return Object.assign({}, state, {
        itemTypes: {
          isLoading: false,
          data: state.itemTypes.data,
        },
        transactionGroups: {
          isLoading: false,
          data: state.transactionGroups.data,
        },
      });

    case SEARCH_ITEM_TYPES:
      return Object.assign({}, state, {
        itemTypes: {
          isLoading: true,
          data: initialState.itemTypes.data,
        },
      });
    case SEARCH_ITEM_TYPES_SUCCESS:
      return Object.assign({}, state, {
        itemTypes: {
          isLoading: false,
          data: payload.data,
        },
        isItemTypeDeleting: false,
      });
    case SEARCH_ITEM_TYPES_FAILED:
      return Object.assign({}, state, {
        itemTypes: {
          isLoading: false,
          data: state.itemTypes.data,
        },
        isItemTypeDeleting: false,
      });

    case ADD_NEW_VOUCHER_SEQUENCE:
      return Object.assign({}, state, {
        voucherSequenceDetails: {
          data: {
            ...initialState.voucherSequenceDetails.data,
            ...payload.data?.sequenceDetails,
          },
          isSaving: true,
        },
        itemTypeDetails: {
          ...state.itemTypeDetails,
          data: {
            ...state.itemTypeDetails.data,
            ...payload.data?.itemTypeDetails,
          },
        },
      });
    case ADD_NEW_VOUCHER_SEQUENCE_SUCCESS:
      return Object.assign({}, state, {
        voucherSequenceDetails: {
          ...initialState.voucherSequenceDetails,
        },
        itemTypeDetails: {
          ...state.itemTypeDetails,
          data: getDataAfterAddNewSequence(
            state.initDetailsData.voucherSequence,
            state.itemTypeDetails.data,
            payload.data
          ).itemTypeDetails,
        },
        initDetailsData: {
          ...state.initDetailsData,
          voucherSequence: getDataAfterAddNewSequence(
            state.initDetailsData.voucherSequence,
            state.itemTypeDetails.data,
            payload.data
          ).voucherSequence,
        },
        breadcrumbInfo: {
          ...state.breadcrumbInfo,
          visibleView: BreadcrumbType.VOUCHER_SEQUENCE_LIST,
        },
      });
    case ADD_NEW_VOUCHER_SEQUENCE_FAILED:
      return Object.assign({}, state, {
        voucherSequenceDetails: {
          ...state.voucherSequenceDetails,
          isSaving: false,
        },
      });

    case GET_TRANSACTION_GROUPS:
      return Object.assign({}, state, {
        transactionGroups: {
          ...initialState.transactionGroups,
          isLoading: true,
        },
      });
    case GET_TRANSACTION_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        transactionGroups: {
          ...initialState.transactionGroups,
          data: payload.data,
        },
      });
    case GET_TRANSACTION_GROUPS_FAILED:
      return Object.assign({}, state, {
        transactionGroups: {
          ...initialState.transactionGroups,
          data: state.transactionGroups.data,
        },
      });

    case DELETE_ITEM_TYPE:
      return Object.assign({}, state, {
        isItemTypeDeleting: true,
      });

    case HANDLE_ITEM_TYPE_DRAWER:
      return Object.assign({}, state, {
        drawerInfo: {
          ...state.drawerInfo,
          ...payload.data,
          visible: !state.drawerInfo.visible,
        },
      });

    case HANDLE_ITEM_TYPE_DRAWER_BREADCRUMB:
      return Object.assign({}, state, {
        breadcrumbInfo: {
          ...state.breadcrumbInfo,
          visibleView: payload.data,
        },
      });

    case GET_INIT_ITEM_TYPE_DATA:
      return Object.assign({}, state, {
        initDetailsData: {
          ...initialState.initDetailsData,
          isLoading: true,
        },
      });
    case GET_INIT_ITEM_TYPE_DATA_SUCCESS:
      const { ledgerAccounts, bankAccounts, voucherSequence, vatCodes } =
        payload.data;
      return Object.assign({}, state, {
        initDetailsData: {
          isLoading: false,
          ledgerAccounts,
          bankAccounts: sortBankAccounts(bankAccounts),
          voucherSequence: _.orderBy(voucherSequence, ["id"], ["desc"]),
          vatCodes: formatVatCodes(vatCodes, state.initDetailsData.vatCodes),
        },
      });
    case GET_INIT_ITEM_TYPE_DATA_FAILED:
      return Object.assign({}, state, {
        initDetailsData: {
          ...state.initDetailsData,
          isLoading: false,
        },
      });

    case GET_ITEM_TYPE_DETAILS:
      return Object.assign({}, state, {
        itemTypeDetails: {
          ...initialState.itemTypeDetails,
          isLoading: true,
        },
      });
    case GET_ITEM_TYPE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        itemTypeDetails: {
          data: formatItemTypeDetails(payload.data, state.itemTypeDetails.data),
          initData: formatItemTypeDetails(
            payload.data,
            state.itemTypeDetails.data
          ),
          isLoading: false,
        },
      });
    case GET_ITEM_TYPE_DETAILS_FAILED:
      return Object.assign({}, state, {
        itemTypeDetails: {
          ...state.itemTypeDetails,
          isLoading: false,
        },
      });

    case RESET_ITEM_TYPE_DETAILS:
      return Object.assign({}, state, {
        itemTypeDetails: { ...initialState.itemTypeDetails },
        initDetailsData: { ...initialState.initDetailsData },
        duplicateValidation: {
          ...initialState.duplicateValidation,
        },
        breadcrumbInfo: { ...initialState.breadcrumbInfo },
        voucherSequenceDetails: {
          ...initialState.voucherSequenceDetails,
        },
      });

    case DUPLICATE_VALIDATE_FIELDS:
      return Object.assign({}, state, {
        duplicateValidation: {
          ...state.duplicateValidation,
          ...(payload.data.field === ValidateFieldNames.ITEM_TYPE_ID
            ? {
                itemTypeId: {
                  status: true,
                  isValidating: true,
                },
              }
            : {
                transactionCode: {
                  status: true,
                  isValidating: true,
                },
              }),
        },
      });
    case DUPLICATE_VALIDATE_FIELDS_SUCCESS:
      return Object.assign({}, state, {
        duplicateValidation: {
          ...state.duplicateValidation,
          ...(payload.data.field === ValidateFieldNames.ITEM_TYPE_ID
            ? {
                itemTypeId: {
                  status: payload.data.status,
                  isValidating: false,
                },
              }
            : {
                transactionCode: {
                  status: payload.data.status,
                  isValidating: false,
                },
              }),
        },
      });
    case DUPLICATE_VALIDATE_FIELDS_FAILED:
      return Object.assign({}, state, {
        duplicateValidation: {
          ...initialState.duplicateValidation,
        },
      });

    case ADD_NEW_ITEM_TYPE:
      return Object.assign({}, state, {
        itemTypeDetails: {
          ...state.itemTypeDetails,
          data: { ...state.itemTypeDetails.data, ...payload.data?.detail },
          isSaving: true,
        },
      });
    case ADD_NEW_ITEM_TYPE_SUCCESS:
      return Object.assign({}, state, {
        itemTypeDetails: {
          ...state.itemTypeDetails,
          isSaving: false,
        },
      });
    case ADD_NEW_ITEM_TYPE_FAILED:
      return Object.assign({}, state, {
        itemTypeDetails: {
          ...state.itemTypeDetails,
          isSaving: false,
        },
      });
    case EDIT_ITEM_TYPE:
      return Object.assign({}, state, {
        itemTypeDetails: {
          ...state.itemTypeDetails,
          data: { ...state.itemTypeDetails.data, ...payload.data?.detail },
          isSaving: true,
        },
      });
    case EDIT_ITEM_TYPE_SUCCESS:
      return Object.assign({}, state, {
        itemTypeDetails: {
          ...state.itemTypeDetails,
          isSaving: false,
        },
      });
    case EDIT_ITEM_TYPE_FAILED:
      return Object.assign({}, state, {
        itemTypeDetails: {
          ...state.itemTypeDetails,
          isSaving: false,
        },
      });
    default:
      return state;
  }
};
