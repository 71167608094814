import { RegisterJournal } from "us.collection.economy/constants/Actions";
import { IRegisterJournalActions } from "us.collection.economy/interfaces";
import { IAPIAction } from "us.collection/interfaces";

const {
  SEARCH_LEDGER_ACCOUNTS,
  SEARCH_LEDGER_ACCOUNTS_SUCCESS,
  SEARCH_LEDGER_ACCOUNTS_FAILED,

  SEARCH_REMIT_HISTORY_DETAILS,
  SEARCH_REMIT_HISTORY_DETAILS_SUCCESS,
  SEARCH_REMIT_HISTORY_DETAILS_FAILED,
  RESET_REMIT_HISTORY_DETAILS,
  
  VALIDATE_JOURNAL_ID,
  VALIDATE_JOURNAL_ID_SUCCESS,
  VALIDATE_JOURNAL_ID_FAILED,

  VALIDATE_CASE_ID,
  VALIDATE_CASE_ID_SUCCESS,
  VALIDATE_CASE_ID_FAILED,

  RESET_JOURNAL_VALIDATION,

  REGISTER_JOURNAL_ENTRY,
  REGISTER_JOURNAL_ENTRY_SUCCESS,
  REGISTER_JOURNAL_ENTRY_FAILED
} = RegisterJournal;

export const JournalEntry: Readonly<IAPIAction & IRegisterJournalActions> = {
  ledgerAccounts: {
    search: (data) => ({
      type: SEARCH_LEDGER_ACCOUNTS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEARCH_LEDGER_ACCOUNTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEARCH_LEDGER_ACCOUNTS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remitHistory: {
    search: (data) => ({
      type: SEARCH_REMIT_HISTORY_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEARCH_REMIT_HISTORY_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEARCH_REMIT_HISTORY_DETAILS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_REMIT_HISTORY_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  journalIdValidation: {
    get: (data) => ({
      type: VALIDATE_JOURNAL_ID,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: VALIDATE_JOURNAL_ID_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: VALIDATE_JOURNAL_ID_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  caseIdValidation: {
    get: (data) => ({
      type: VALIDATE_CASE_ID,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: VALIDATE_CASE_ID_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: VALIDATE_CASE_ID_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  journalEntry: {
    save: (data) => ({
      type: REGISTER_JOURNAL_ENTRY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: REGISTER_JOURNAL_ENTRY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: REGISTER_JOURNAL_ENTRY_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
    resetValidation: (data) => ({
      type: RESET_JOURNAL_VALIDATION,
      payload: {
        data,
        isLoading: true,
      },
    }), 
  },
};
