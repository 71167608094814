import { httpCollection } from "us.helper/http/collection";

export default {
  articleList: {
    getArticles: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            "payments/articles",
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getVatCodes: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            "payments/masterdata/vatcodes",
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getCategories: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            "payments/masterdata/categories",
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getLedgerAccounts: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            "payments/masterdata/ledgerAccounts",
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteArticleData: (articleId: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.delete(
            "paymentservice",
            "payments/articles",
            articleId
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    saveArticleData: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.post(
            "paymentservice",
            "payments/article",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateArticleData: (params: any): Promise<any> => {
      console.log("pppppppp", params);

      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.put(
            "paymentservice",
            "payments/article",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    checkDuplicateArticleNumber: (articleNo: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            `payments/article/validate?articleNo=${articleNo}`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
