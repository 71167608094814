import { Articles } from "../../constants/Actions";
import { IArticleActions } from "../../interfaces/Articles";

export const articles: IArticleActions = {
  changeDrawerInfo: (data) => ({
    type: Articles.CHANGE_DRAWER_INFO,
    payload: {
      data,
    },
  }),
  getArticles: () => ({
    type: Articles.GET_ARTICLES,
    payload: {
      isLoading: true,
    },
  }),
  getArticlesSuccess: (data) => ({
    type: Articles.GET_ARTICLES_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getArticlesFail: (error) => ({
    type: Articles.GET_ARTICLES_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  getArticleProperties: () => ({
    type: Articles.GET_ARTICLE_PROPERTIES,
    payload: {
      isLoading: true,
    },
  }),
  getArticlePropertiesSuccess: (data) => ({
    type: Articles.GET_ARTICLE_PROPERTIES_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getArticlePropertiesFail: (error) => ({
    type: Articles.GET_ARTICLE_PROPERTIES_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  deleteArticle: (data) => ({
    type: Articles.DELETE_ARTICLE,
    payload: {
      data,
      isLoading: true,
    },
  }),
  deleteArticleSuccess: (data) => ({
    type: Articles.DELETE_ARTICLE_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  deleteArticleFail: (error) => ({
    type: Articles.DELETE_ARTICLE_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  saveArticle: (data) => ({
    type: Articles.SAVE_ARTICLE,
    payload: {
      data,
      isLoading: true,
    },
  }),
  saveArticleSuccess: (data) => ({
    type: Articles.SAVE_ARTICLE_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  saveArticleFail: (error) => ({
    type: Articles.SAVE_ARTICLE_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  checkDuplicateArticleNo: (data) => ({
    type: Articles.CHECK_DUPLICATE_ARTICLE_NO,
    payload: {
      data,
    },
  }),
  checkDuplicateArticleNoSuccess: (data) => ({
    type: Articles.CHECK_DUPLICATE_ARTICLE_NO_SUCCESS,
    payload: {
      data,
    },
  }),
  checkDuplicateArticleNoFail: (data) => ({
    type: Articles.CHECK_DUPLICATE_ARTICLE_NO_FAIL,
    payload: {
      data,
    },
  }),
  resetDuplicateArticleNoStatus: () => ({
    type: Articles.RESET_DUPLICATE_ARTICLE_NO_STATUS,
  }),
};
