import { groupBy } from "us.collection.economy/functions/ManagePayments/gridBase";
import { IMatchedCase, ICaseTag } from "../interface";
export default class MatchHelper {
  private static remainingBalance: number;

  public static getCaseTag(caseStatus: string): ICaseTag {
    switch (caseStatus) {
      case "Open":
        return {
          class: "tag-status-open",
          name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.OPEN",
        };
      case "SendToCourt":
        return {
          class: "tag-status-court",
          name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.COURT",
        };
      case "Closed":
        return {
          class: "tag-status-close",
          name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CLOSED",
        };
      case "NextDueDate":
        return {
          class: "tag-status-nextduedate",
          name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.DUE",
        };
      case "SentenceReceived":
        return {
          class: "tag-status-sentence",
          name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.SENTENCE_RECEIVED",
        };
      case "Freezed":
        return {
          class: "tag-status-freeze",
          name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.FREEZED",
        };
      default:
        return { class: "", name: "" };
    }
  }

  // get cases with sort by case no and closed case to the end
  private static sortCasesByCaseNoAndClosed(cases: Array<any>): Array<any> {
    const childCasesSortByCaseNo = cases.sort(
      (a: any, b: any) => Number(a.caseNo) - Number(b.caseNo)
    );
    const childCasesWithoutClosed = childCasesSortByCaseNo.filter(
      (caseItem: { caseStatus: string }) => caseItem.caseStatus !== "Closed"
    );
    const childCasesOnlyClosed = childCasesSortByCaseNo.filter(
      (caseItem: { caseStatus: string }) => caseItem.caseStatus === "Closed"
    );
    return [...childCasesWithoutClosed, ...childCasesOnlyClosed];
  }

  // auto mapping logic
  private static mapCases(
    arIndex: number,
    arId: string,
    arItem: { creditorName: string; debtorName: string },
    cases: Array<any>,
    remainingBalance: number
  ) {
    try {
      const { creditorName, debtorName } = arItem;
      const mappedItem = {
        creditorName,
        debtorName,
        arId: Number(arId),
        key: arId,
        isParent: true,
        children: cases.map(
          (
            {
              isObjected,
              balance,
              caseNo,
              deletable,
              caseStatus,
              ...rest
            }: {
              isObjected: boolean;
              balance: number;
              caseNo: number;
              deletable: boolean;
              caseStatus: string;
            },
            index: number
          ) => {
            const mBalance: number =
              !isObjected && !deletable
                ? remainingBalance > balance
                  ? balance
                  : remainingBalance
                : 0;
            remainingBalance -= mBalance;
            return {
              isObjected,
              balance,
              caseNo,
              deletable,
              caseStatus,
              ...rest,
              mappingBalance:
                caseStatus !== "Closed" && mBalance > 0
                  ? Number(mBalance.toFixed(2))
                  : undefined,
              key: caseNo,
              childIndex: index,
              arIndex,
            };
          }
        ),
      };
      return { remainingBalance, mappedItem };
    } catch (error) {
      return { remainingBalance, mappedItem: null };
    }
  }

  /**
   * @description This will return case table data with grouped by AR and sorted by case no, auto mapping for each AR's cases
   * @param {Array<any>} data
   * @param {number} amount
   * @param {number} mainArid
   */
  public static getCasesWithMapping(
    data: Array<any>,
    amount: number,
    mainArId: number
  ): Array<any> {
    const result: Array<any> = [];
    let remainingAmount = amount;
    try {
      if (data?.length > 0) {
        const groupedByAr = groupBy(data, "arId");
        const arIds = Object.keys(groupedByAr).sort(function (x, y) {
          return x === mainArId.toString()
            ? -1
            : y === mainArId.toString()
            ? 1
            : 0;
        });

        arIds.map((arId: string, index: number) => {
          const sortedChildren = this.sortCasesByCaseNoAndClosed(
            groupedByAr[arId]
          );
          const { remainingBalance, mappedItem } = this.mapCases(
            index,
            arId,
            groupedByAr[arId][0],
            sortedChildren,
            remainingAmount
          );
          result.push(mappedItem);
          remainingAmount = remainingBalance;
        });
      }
    } catch (error) {
      return [];
    }
    return result;
  }

  /**
   * @param {Array<any>} cases - Array of cases
   * @param {number} exceededOrUnknownAmount - Exceeded amount (`exceededSum`) or Unknown Amount (`unknownSum`)
   * @returns {number} exceededOrUnknownBalance
   */
  public static getExceededOrUnknownBalance(
    cases: Array<any>,
    exceededOrUnknownAmount: number
  ): number {
    const mappedCases: Array<any> = [];
    try {
      cases?.map((item: any) => {
        mappedCases.push(...item.children);
      });

      // calculate total mapping balance
      const totalMappingBalance: number = parseFloat(
        Number(
          mappedCases.reduce((a, b) => a + (b.mappingBalance || 0), 0)
        ).toFixed(2)
      );

      // calculate the remaining mapping balance
      const remainingBalance: number = parseFloat(
        Number(exceededOrUnknownAmount - totalMappingBalance).toFixed(2)
      );
      // update the state
      this.setRemainingBalance(remainingBalance);

      return exceededOrUnknownAmount > totalMappingBalance
        ? remainingBalance
        : 0;
    } catch (error) {
      this.setRemainingBalance(0);
      return 0;
    }
  }

  /**
   *
   */
  public static getChildCases(data: Array<any>): Array<any> {
    try {
      let childCases: any[] = [];
      data?.map((caseItem: any) => {
        childCases = [...childCases, ...caseItem.children];
      });
      return childCases;
    } catch (error) {
      return [];
    }
  }

  /**
   * Will return an array of `IMatchedCase` type Objects for API request
   * @param {Array} cases - Current case table data array objects
   * @returns {Array<IMatchedCase>}
   */
  public static getMatchedCases(cases: Array<any>): Array<IMatchedCase> {
    const matchedCases: any[] = [];
    try {
      const childCases = this.getChildCases(cases);

      childCases.map((item: any) => {
        const { mappingBalance, caseId, pid, caseType } = item;
        if (mappingBalance > 0) {
          const matchedCase: IMatchedCase = {
            caseId,
            amount: mappingBalance,
            pid,
            caseType,
          };
          matchedCases.push(matchedCase);
        }
      });
      return matchedCases;
    } catch (error) {
      return [];
    }
  }

  /**
   * @description set remaining exceeded or unknown balance for future use
   * @param {number} balance - Remaining exceeded or unknown balance
   */
  public static setRemainingBalance(balance: number) {
    this.remainingBalance = balance;
  }

  /**
   * @description get remaining exceeded or unknown balance
   * @returns {number} - Will return the remaining exceeded or unknown balance
   */
  public static getRemainingBalance(): number {
    return this.remainingBalance;
  }

  /**
   * @description remove specific case by caseNo from the `caseTableData`
   * @param {Arra<any>} caseTableData - Current case table data
   * @param {string} caseNo - `caseNo` to remove a case
   * @returns {Array<any>} - filtered data after removing case by `caseNo`
   */
  public static removeCaseFromCaseTableData(
    caseTableData: Array<any>,
    caseNo: string
  ): Array<any> {
    try {
      if (caseTableData && caseNo) {
        const filteredData = caseTableData.map(
          (arItemGroup: { children: Array<any> }) => {
            if (
              arItemGroup.children.some(
                (c: { caseNo: string }) => c.caseNo === caseNo
              )
            ) {
              arItemGroup.children = arItemGroup.children.filter(
                (c: { caseNo: string }) => c.caseNo !== caseNo
              );
            }
            return arItemGroup;
          }
        );
        return filteredData.filter(
          (arItemGroup: any) =>
            arItemGroup.children && arItemGroup.children.length > 0
        );
      }
      return [];
    } catch (error) {
      return [];
    }
  }

  /**
   * @description add a case to the `caseTableData`
   * @param {Array<any>} caseTableData - Current case table data
   * @param {Object} newCase - `newCase` to add to the table
   * @returns {Array<any>} - Array of data with the new case
   */
  public static addNewCaseToCaseTableData(
    caseTableData: Array<any>,
    newCase: any,
    remainingBalance: number
  ): Array<any> {
    try {
      if (newCase) {
        const { arId, arBalance, creditorName, debtorName, caseNo } = newCase;
        // generate new child case data object for a AR group
        const newCaseData = {
          ...newCase,
          balance: arBalance,
          deletable: true,
          key: caseNo,
        };
        if (caseTableData?.length > 0) {
          const hasSameAr = caseTableData.some(
            (arItem: { arId: number }) => arItem.arId === arId
          );
          if (hasSameAr) {
            // already include the same AR and add the new case to that
            return caseTableData.map(
              (
                arItem: { arId: number; children: Array<any> },
                index: number
              ) => {
                if (arItem.arId === arId) {
                  arItem.children = [
                    ...arItem.children,
                    {
                      ...newCaseData,
                      childIndex: arItem.children.length,
                      arIndex: index,
                    },
                  ];
                }
                return arItem;
              }
            );
          } else {
            // no AR include as same as the new case and add as a new AR group
            const newArItem = {
              creditorName,
              debtorName,
              arId,
              key: arId.toString(),
              isParent: true,
              children: [
                {
                  ...newCaseData,
                  childIndex: 0,
                  arIndex: caseTableData.length,
                },
              ],
            };
            return [...caseTableData, newArItem];
          }
        } else {
          const newArItem = {
            creditorName,
            debtorName,
            arId,
            key: arId.toString(),
            isParent: true,
            children: [
              {
                ...newCaseData,
                mappingBalance:
                  remainingBalance > arBalance ? arBalance : remainingBalance,
                childIndex: 0,
                arIndex: 0,
              },
            ],
          };
          return [newArItem];
        }
      }
      return caseTableData;
    } catch (error) {
      return caseTableData ?? [];
    }
  }

  public static getUpdatedRowData(
    revertMappedPayment: any,
    rowPayments: Array<any>
  ): Array<any> {
    try {
      if (Array.isArray(revertMappedPayment)) {
        return revertMappedPayment;
      } else {
        return rowPayments?.map((payment: { paymentId: number }) => {
          if (payment?.paymentId == revertMappedPayment?.paymentId) {
            return revertMappedPayment;
          }
          return payment;
        });
      }
    } catch (error) {
      return [];
    }
  }
}
