import {
  IItemTypeDetail,
  IVatCode,
  IBankAccount,
  IVoucherSequence,
} from "us.collection.economy/interfaces";
import { TransactionTypes } from "us.collection.economy/components/ItemTypes/Constants";
import { INITIAL_DISTRIBUTION_FACTORS } from "us.collection.economy/constants/ItemTypes";

/**
 * @description Add transactionType property to the endpoint payload.
 * @param {IItemTypeDetail} details - End point payload.
 * @param {IItemTypeDetail} previousState - Redux previous state.
 * @returns item type details with transactionType property.
 */
export const formatItemTypeDetails = (
  details: IItemTypeDetail,
  previousState: IItemTypeDetail
): IItemTypeDetail => {
  try {
    const {
      isPayment,
      isCancelationType,
      distributionFactors,
      isInterestApplicable,
    } = details;
    const paymentFractions =
      Array.isArray(distributionFactors) && distributionFactors?.length > 0
        ? distributionFactors
        : isInterestApplicable
        ? INITIAL_DISTRIBUTION_FACTORS
        : [INITIAL_DISTRIBUTION_FACTORS[0]];

    let transactionType = "";

    if (isPayment) {
      transactionType = TransactionTypes.PAYMENT;
    } else if (isCancelationType) {
      transactionType = TransactionTypes.CANCELATION_TYPE;
    } else {
      transactionType = TransactionTypes.FEES;
    }
    return {
      ...details,
      transactionType,
      distributionFactors: paymentFractions,
    };
  } catch (e) {
    return previousState;
  }
};

/**
 * @description Return formatted vatCodes.
 * @param {Array<IVatCode>} vatCodes - End point payload.
 * @param {Array<IVatCode>} previousState - Redux previous state.
 * @returns Formatted vatCodes.
 */
export const formatVatCodes = (
  vatCodes: IVatCode[],
  previousState: IVatCode[]
): IVatCode[] => {
  try {
    return vatCodes.map((vatCode: any) => ({
      ...vatCode,
      vatCode: Number(vatCode.vatcode),
    }));
  } catch (e) {
    return previousState;
  }
};

/**
 * @description Sort bank accounts.
 * @param {Array<IBankAccount>} bankAccounts - End point payload.
 * @returns Sorted bank accounts.
 */
export const sortBankAccounts = (
  bankAccounts: IBankAccount[]
): IBankAccount[] => {
  try {
    return bankAccounts.sort(
      (a: { accountName: string }, b: { accountName: string }) => {
        if (a.accountName < b.accountName) {
          return -1;
        }
        if (a.accountName > b.accountName) {
          return 1;
        }
        return 0;
      }
    );
  } catch (e) {
    return bankAccounts;
  }
};

/**
 * @description Add new sequence and update details.
 * @param {Array<IVoucherSequence>} voucherSequence - Previous sequences.
 * @param {IItemTypeDetail} itemTypeDetails - Previous item type detail.
 * @param {any} payload - Add sequence payload.
 * @returns Updated sequence list and details.
 */
export const getDataAfterAddNewSequence = (
  voucherSequence: Array<IVoucherSequence>,
  itemTypeDetails: IItemTypeDetail,
  payload: any
): {
  voucherSequence: Array<IVoucherSequence>;
  itemTypeDetails: IItemTypeDetail;
} => {
  try {
    let newVoucherSequence: Array<IVoucherSequence> = [];
    let newItemTypeDetails: IItemTypeDetail;

    if (payload?.hasOwnProperty("id")) {
      newVoucherSequence = [payload, ...voucherSequence];
    } else {
      newVoucherSequence = [...voucherSequence];
    }

    newItemTypeDetails = {
      ...itemTypeDetails,
      voucherSequenceId: Number(payload?.id),
    };
    return {
      voucherSequence: newVoucherSequence,
      itemTypeDetails: newItemTypeDetails,
    };
  } catch (e) {
    return {
      voucherSequence,
      itemTypeDetails,
    };
  }
};
