import { httpCollection } from "us.helper/http/collection";

export default {
  accounts: {
    getClientAccounts: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "economysettingservice",
            "clientaccounts",
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getInitDetails: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const ledgerAccounts = await httpCollection.get(
            "paymentservice",
            "payments/masterdata/ledgerAccounts",
            {}
          );
          const sequenceData = await httpCollection.post(
            "economysettingservice",
            "sequence/search",
            params
          );
          resolve({
            ledgerAccounts,
            sequenceData,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    saveClientAccount: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "economysettingservice",
            "clientaccount",
            params
          );
          const accountsList = await httpCollection.get(
            "economysettingservice",
            "clientaccounts",
            {}
          );
          resolve({
            newAccountData: data,
            status: status,
            allAccountsList: accountsList?.data,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    updateClientAccount: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.put(
            "economysettingservice",
            "clientaccount",
            params
          );
          const accountsList = await httpCollection.get(
            "economysettingservice",
            "clientaccounts",
            {}
          );
          resolve({
            editedAccountData: data,
            status: status,
            allAccountsList: accountsList?.data,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    validateAccountNo: (accountNumber: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.getById(
            "economysettingservice",
            `clientaccounts/validate/accountno`,
            accountNumber
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    validateRemitJournalPrefix: (journalPrefix: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.getById(
            "economysettingservice",
            `clientaccounts/validate/remitjournalprefix`,
            journalPrefix
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    deleteClientAccount: (accountId: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.delete(
            "economysettingservice",
            `clientaccount`,
            accountId
          );
          const accountsList = await httpCollection.get(
            "economysettingservice",
            "clientaccounts",
            {}
          );
          resolve({
            deletedData: data,
            status: status,
            allAccountsList: accountsList?.data,
          });
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
