import { httpCollection } from "us.helper/http/collection";

export default {
  getAccountsData: (searchText: string): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data = await httpCollection.get(
          "economysettingservice",
          `ledgeraccounts?searchtext=${searchText}`,
          {}
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  saveNewLedgerAccount: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.post(
          "economysettingservice",
          "ledgeraccount",
          params?.saveParams
        );
        const accounts = await httpCollection.get(
          "economysettingservice",
          `ledgeraccounts?searchtext=${params?.searchText}`,
          {}
        );
        resolve({ result: data, status: status, accountsList: accounts.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  updateLedgerAccount: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.put(
          "economysettingservice",
          "ledgeraccount",
          params?.saveParams
        );

        const accounts = await httpCollection.get(
          "economysettingservice",
          `ledgeraccounts?searchtext=${params?.searchText}`,
          {}
        );
        resolve({ result: data, status: status, accountsList: accounts.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteLedgerAccount: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.delete(
          "economysettingservice",
          "ledgeraccount",
          params?.accountId
        );
        const accounts = await httpCollection.get(
          "economysettingservice",
          `ledgeraccounts?searchtext=${params?.searchText}`,
          {}
        );
        resolve({ deleteData: data, status, accountsList: accounts.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  validateAccountNo: (accountNumber: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.get(
          "economysettingservice",
          `ledgeraccount/validate/${accountNumber}`,
          {}
        );

        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
};
