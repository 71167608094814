import moment from "moment";
import {
  RemitHistorySearchType,
  RemitFileStatus,
} from "us.collection.economy/components/RemitHistory/Interfaces";

export const REMIT_HISTORY_INITIAL_FORM_VALUES = {
  remitDateRange: [moment().subtract(30, "days"), moment()],
  remitFileStatus: RemitFileStatus.ACTIVE,
  accountNo: "",
  searchType: RemitHistorySearchType.FILE_DATE,
  journalIdText: "",
  offset: 0,
  limit: 1000,
};
