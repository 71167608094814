import {
  RemitRecord,
  RemitValidationError,
} from "us.collection.economy/components/Remit/Interfaces";

/**
 * @description - Check whether the creditor has any payment with minus remit creditor balance
 * @param {RemitRecord} remitRecord - Related remit record
 * @param {RemitValidationError[]} remitErrors - Error list to check
 * @returns {boolean}
 */
export const isErrorCreditor = (
  { creditorNo }: RemitRecord,
  remitErrors: RemitValidationError[]
): boolean => {
  try {
    return remitErrors.some(
      (errorRecord: RemitValidationError) =>
        creditorNo == errorRecord.creditorNo
    );
  } catch (error) {
    return false;
  }
};
