import {
  CaseStatus,
  IDebtorOtherCasesStatus,
} from "us.collection.economy/constants/Payments/ManagePayments";
import moment from "moment";
import {
  PaymentStates,
  DayValue,
  PeriodType,
  TagClasses,
} from "us.collection.economy/constants/Payments";
import { IGetCases } from "us.collection.economy/interfaces";

export interface IManagePaymentsCommon {
  currentDateFormat: any;
  currentCurrency: string;
  currentLanguage: string;
  payments: {
    data: {
      formatted: any[];
      raw: any[];
      ocrFileNameOptions: any[];
    };
    dayVal: DayValue.NON | DayValue.TODAY | DayValue.YESTERDAY;
    paymentDateRange: [moment.Moment, moment.Moment];
    isFetching: boolean;
    hasMorePayments: boolean;
  };
}

export interface IManagePayments extends IManagePaymentsCommon {
  paymentAccounts: {
    data: any[];
    isFetching: boolean;
  };
  paymentDistribution: {
    data: any;
    isFetching: boolean;
  };
  paymentApportionments: {
    drawerInfo: {
      title: string;
      visible: boolean;
    };
  };
}

export interface IManagePaymentsHome extends IManagePayments {
  onChangeView?: () => void;
  paymentsWithAcc: Array<any>;
  reverseReturnedPayment: (data: any, filters: any) => void;
  revertMappedPayment: (data: any, filters: any) => void;
  revertPaymentPopConfirmStatus: boolean;
}

export enum ActionTypes {
  EDIT = "EDIT",
  MATCH = "MATCH",
  TRANSFER = "TRANSFER",
  APPORTION = "APPORTION",
  REVERT_TO_PAYMENT = "REVERT_TO_PAYMENT",
  REVERT_TO_APPORTIONMENT = "REVERT_TO_APPORTIONMENT",
  NON = "NON",
  REVERT_PAYMENT = "REVERT_PAYMENT",
}
export interface IIsTransfer {
  visible: boolean;
  key: string;
}

export type IActionTypes =
  | ActionTypes.EDIT
  | ActionTypes.MATCH
  | ActionTypes.TRANSFER
  | ActionTypes.APPORTION
  | ActionTypes.REVERT_TO_PAYMENT
  | ActionTypes.REVERT_TO_APPORTIONMENT
  | ActionTypes.NON
  | ActionTypes.REVERT_PAYMENT;

export interface IVisibleView {
  defaultAction?: IActionTypes;
  action?: IActionTypes;
  paymentId: number;
  key: string;
}
export interface ITransferPayment {
  paymentAritemNo: string;
  paymentId: number;
  returnedAmount: number;
  returnFee: number;
  accountNo: string;
  paymentStatus: string;
}
export interface ITransfer {
  tansfer: ITransferPayment;
  perReturnFee: number;
  isBulk: boolean;
  bulk: BulkTransfer | undefined;
  dsbKey: "exceededSum" | "unknownSum";
}
export interface IEditPayment {
  paymentId: number;
  voucherDate: string;
  paymentAmount: number;
  caseNo: number;
  paymentStatus: IPaymentStates;
  paymentArItemNo: number;
}
export interface ISearchFilters {
  startDate: string;
  endDate: string;
  periodType: IPeriodType;
  paymentStates: Array<IPaymentStates>;
  accountNo: string | null;
  paymentId: number | string | undefined;
  offset: number;
  limit: number;
  dayVal?: DayValue.NON | DayValue.TODAY | DayValue.YESTERDAY;
  debtorOtherCasesStatus: IDebtorOtherCasesStatus;
}

export type IPaymentStates =
  | typeof PaymentStates.MAPPED
  | typeof PaymentStates.EXCEEDED
  | typeof PaymentStates.UNKNOWN
  | typeof PaymentStates.RETURNED;

export type IPeriodType =
  | typeof PeriodType.VOU
  | typeof PeriodType.REG
  | typeof PeriodType.ALL;

export type ITagClasses =
  | typeof TagClasses.EXCEEDED
  | typeof TagClasses.MAPPED
  | typeof TagClasses.UNKNOWN
  | typeof TagClasses.RETURNED;

export interface IPaymentCommon {
  paymentId: number;
  caseId: number;
  caseNo: string;
}
export interface IPaymentDistribution extends IPaymentCommon {
  amount: number;
  arItemNo: number;
  arNo: number;
  caseId: number;
  caseNo: string;
  creditorId: number;
  debtorId: number;
  paymentId: number;
  paymentStatus: string;
  returnedDate: string;
  returnfee: number;
  isRemitted: boolean;
}

export interface IPayment extends IPaymentCommon {
  accountNo: string | null;
  voucherNo: string;
  regDate: Date | null;
  voucherDate: Date | null;
  pid: string;
  creditorName: string;
  cid?: any;
  debtorName: string;
  kid: string;
  exceededSum: number;
  mappedSum: number;
  returnedToDebtorSum: number;
  unknownSum: number;
  paymentAmount: number;
  paymentTransactions?: any;
  paymentDistribution: IPaymentDistribution[];
  amount: number;
  arNo: number;
  itemType: string;
  paymentTypeName: string;
  fileName: string;
  itemTypeId: number;
  linkedPaymentId: number;
  minimumExceedAmount: number;
}

export interface BulkTransfer {
  paymentsWithAccounts: Array<IPayment>;
  noOfRecords: number;
  perReturnFee: number;
  totalExdOrUnkwnAmount: number;
  totalReturnAmount: number;
}

// Match related
export interface IFormValues {
  isInDepartments: boolean;
  debtorAccountNo: string | number;
  matchCaseNo: string | number;
  remainingBalance: number;
  returnAmount: number;
  returnFee: number;
  caseTableData: Array<any>;
  remainingAction: IMatchRemainingAction;
}

export interface IPaymentLayout extends IManagePaymentsCommon {
  activeId: number;
  values: any;
  paymentsWithAcc: Array<any>;
  data: Array<any>;
  hasMorePayments?: any;
  onItemCallAction: <D>(data: D) => void;
  onSelectPayment: (key: number | undefined) => void;
  onViewPaymentDetails: (payment: IPayment) => void;
  onViewLinkedPayment: (data: { linkedPaymentId: number }) => void;
  onLoadMore: () => void;
  onScrollToFocusedItem?: () => void;
  onBackToTop?: () => void;
}
export interface IMatch {
  isCaseValidating: boolean;
  isMatchingPayment: boolean;
  parentFormValues: any;
}

export interface IMatchedCase {
  caseId: number;
  amount: number;
  pid?: any;
  caseType: string;
}

export interface IGetPayments extends ISearchFilters {
  paymentDateRange: [moment.Moment, moment.Moment];
  dayVal: DayValue;
}

export interface IMatchPayment {
  paymentId: number;
  paymentArItemNo: number;
  itemType?: any;
  matchedCases: IMatchedCase[];
  remainingAmount: number;
  returnFee: number;
  remainingAction?: "Exceed" | "Return" | null;
  paymentStatus: IPaymentStates;
  accountNo: number;
  voucherDate: string;
}

export interface IManagePaymentsFormValues {
  periodType: IPeriodType;
  paymentDateRange: [moment.Moment, moment.Moment];
  paymentStates: IPaymentStates[];
  accountNo: string;
  offset: number;
  limit: number;
  kid: string;
  caseNo: string;
  voucherNo: string;
  paymentId: number | string | undefined;
  isExceeded: boolean;
  isUnknown: boolean;
  isMapped: boolean;
  isReturnToDebtor: boolean;
  dayVal: DayValue;
  balance: number;
  mappingBalance: number;
  debtorOtherCasesStatus: 1 | 2 | 3;
}

export interface IMatchPaymentData {
  payment: any;
  isExceeded: boolean;
  remainingBalance: number;
  returnFee: number;
  debtorAccountNo: any;
  returnAmount: number;
  casesData: Array<any>;
  caseTableData: Array<any>;
  exceededOrUnknownAmount: number;
  mainAr: number;
}

export interface IFilterValues {
  status: Array<IPaymentStates>;
  paymentId: string;
  kid: string;
  caseId: string;
  voucherNo: string;
}

export interface IFilter {
  disabled?: boolean;
  values: any;
  resetForm: any;
  paymentsCount: { exceededCount: number; unknownCount: number };
  fileNameOptions: Array<any>;
  onFilter: <D>(data?: D) => void;
  onFilterLocal: <D>(data?: D) => void;
  onChangePaymentId: (id: number | undefined) => void;
}
export interface ICaseTable {
  data: Array<any>;
  exceededBalance: number;
  isLoading?: boolean;
  currentLanguage: string;
  currentCurrency: string;
  onChangeMappingBalance: (data: {
    value: number;
    record: { arId: string | number; caseNo: string };
  }) => void;
  onDeleteCase: (data: { caseNo: string }) => void;
  activeRowKeys: string[];
  addCaseTableRowKey: (key: string) => void;
  deleteCaseTableRowKey: (key: string) => void;
}

export interface ICaseTag {
  class: string;
  name: string;
}

export type ICaseStates =
  | typeof CaseStatus.OPEN
  | typeof CaseStatus.CLOSED
  | typeof CaseStatus.FREEZED
  | typeof CaseStatus.NEXT_DUE_DATE
  | typeof CaseStatus.SEND_TO_COURT
  | typeof CaseStatus.SENTENCE_RECEIVED;

export enum MatchRemainingAction {
  LEAVE_EXCEEDED = "Exceed",
  RETURN_EXCEEDED = "Return",
  ROUND_OFF = "RoundOff",
}

export type IMatchRemainingAction =
  | MatchRemainingAction.LEAVE_EXCEEDED
  | MatchRemainingAction.RETURN_EXCEEDED
  | MatchRemainingAction.ROUND_OFF;
