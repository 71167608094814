import moment from "moment";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";
import { ISearchInvoices } from "us.collection.economy/interfaces/ClientInvoices/IClientInvoicesServices";
import {
  CIExportStatus,
  CISearchType,
} from "us.collection.economy/components/ClientInvoices/Constants";

export function SearchInvoices(this: any): ISearchInvoices {
  const [startDate, endDate] = this.dateRange ?? [
    moment().startOf("month"),
    moment().endOf("month"),
  ];
  return {
    startDate: moment(startDate).format(DATE_FORMAT_FOR_SERVER),
    endDate: moment(endDate).format(DATE_FORMAT_FOR_SERVER),
    searchType: this.searchType,
    invoiceNo:
      this.searchType === CISearchType.INVOICE_NO ? this.invoiceNo : null,
    invoiceType: this.invoiceType,
    creditInvoicesOnly: this.creditInvoicesOnly,
    exportStatus:
      this.searchType === CISearchType.EXPORTED_DATE
        ? CIExportStatus.EXPORTED_OR_NAVISION
        : this.exportStatus,
    customers: this.customers,
    offset: this.offset,
    limit: this.limit,
  };
}
