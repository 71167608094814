import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { ILedgerDashboard } from "./Interfaces";
import { Dashboard } from "./Components";
import "./LedgerDashboard.scss";

const { $Drawer } = Common.Components;

const LedgerDashboard: React.FC<ILedgerDashboard> = ({
  journalId,
  drawerProps,
  visible = false,
  title,
  width = "1000",
  onClose,
}) => {
  const { t } = useTranslation([
    "US.COLLECTION.ECONOMY",
    "US.COLLECTION.COMMON",
  ]);
  return (
    <$Drawer
      {...drawerProps}
      title={
        !title
          ? t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.JOURNAL_DASHBOARD")
          : title
      }
      width={width}
      visible={visible}
      onClose={onClose}
      destroyOnClose={true}
    >
      <Dashboard journalId={journalId} onClose={onClose} />
    </$Drawer>
  );
};
export default LedgerDashboard;
