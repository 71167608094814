import _ from "lodash";
import moment from "moment";
import { RemitDetails } from "us.collection.economy/interfaces";

/**
 * @description Modify remit records by adding new property `isReversible` to remit record which can be reverse
 * @param {RemitDetails[]} remits - Remit history records
 * @returns {RemitDetails[]}
 */
export const modifyRemitRecords = (remits: RemitDetails[]): RemitDetails[] => {
  try {
    // define new array to hold reversible remit record
    // reversible true if remit record is the first/latest remit of the day sequence
    const reversibleRemitRecords: RemitDetails[] = [];

    const remitsFormattedFileDate = remits.map((remit: RemitDetails) => {
      return {
        ...remit,
        fileDate: moment(remit.fileDate).format("DD.MM.YYYY"),
      };
    });
    const remitsGroupedByFileDate = _.groupBy(
      remitsFormattedFileDate,
      "fileDate"
    );
    for (const [_key, value] of Object.entries(remitsGroupedByFileDate)) {
      reversibleRemitRecords.push(value[0]);
    }
    return remits.map((remit: RemitDetails) => {
      const isItemReversible = reversibleRemitRecords.some(
        ({ journalId }: RemitDetails) => journalId == remit.journalId
      );
      if (isItemReversible) {
        return { ...remit, isReversible: true };
      }
      return remit;
    });
  } catch (error) {
    return remits;
  }
};
