import React, { useEffect, useMemo } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "us.collection.economy/actions";
import { useLocation } from "react-router-dom";
import { VatCodeAction } from "us.collection.economy/components/VATCode/Constants";
import {
  getVatCodeRecordDetailsById,
  isFormDirty,
  getAccountNameById,
} from "us.collection.economy/components/VATCode/Functions";
import { VatCodeValidationSchema } from "us.collection.economy/components/VATCode/Validations";
import { SaveVatCode } from "us.collection.economy/components/VATCode/Repository";
import { IDetails } from "us.collection.economy/components/VATCode/Interfaces";

const {
  $TextArea,
  $DatePicker,
  $Button,
  $Form,
  $Row,
  $Col,
  $Select,
  $Input,
  $AsyncInput,
} = Common.Components;

/**
 * @description - Form for Add Edit Vat Code
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2855108613/VAT+Code
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 28/07/2022
 */
const Details: React.FC<IDetails & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const {
    getLedgerAccounts,
    ledgerAccounts,
    validateVatCode,
    vatCodeSaveAndUpdate,
    vatCodeList,
    onCancel,
    validationStatus,
    resetVatCodeValidation,
    addNewVatCode,
    updateVatCode,
    currentDateFormat,
  } = props;

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const action = urlParams.get("action");
  const isEdit = action === VatCodeAction.EDIT;
  const vatId = urlParams.get("vatId");

  useEffect(() => {
    getLedgerAccounts && getLedgerAccounts({});
  }, []);

  useEffect(() => {
    if (
      !vatCodeSaveAndUpdate.isLoading &&
      vatCodeSaveAndUpdate.data?.hasOwnProperty("vatId")
    ) {
      onCancel();
    }
  }, [vatCodeSaveAndUpdate.isLoading]);

  const vatCodeRecordDetails = useMemo(() => {
    return getVatCodeRecordDetailsById(vatCodeList, Number(vatId));
  }, [vatId, vatCodeList]);

  /**
   * @description - Handle form submit
   */
  const onClearVatCodeInput = () => {
    resetVatCodeValidation && resetVatCodeValidation({});
  };

  /**
   * @description - Handle form submit
   * @param {any} values - Form values
   */
  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(true);
    const payload = SaveVatCode.call(values);
    if (isEdit) {
      updateVatCode && updateVatCode(payload);
    } else {
      addNewVatCode && addNewVatCode(payload);
    }
  };

  return (
    <Formik
      initialValues={{
        ...vatCodeRecordDetails,
        accountName: getAccountNameById(
          ledgerAccounts,
          vatCodeRecordDetails?.accountID
        ),
      }}
      enableReinitialize
      validationSchema={VatCodeValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical" autoComplete="off" onSubmit={handleSubmit}>
            <$Row gutter={16} className="mb-2">
              <$Col span={6}>
                <$AsyncInput
                  label={t("US.COLLECTION.ECONOMY:VAT_CODE.VATCODE")}
                  className="w-100"
                  name="vatCode"
                  placeholder={t("US.COLLECTION.ECONOMY:VAT_CODE.VATCODE")}
                  size="small"
                  required={true}
                  allowClear
                  showCount
                  maxLength={4}
                  onBlur={(e: any) => {
                    if (e.target.value !== "" && e.target.value != 0) {
                      validateVatCode && validateVatCode(e.target.value);
                    }
                  }}
                  isValid={validationStatus}
                  asyncError={t(
                    "US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_VAT_CODE"
                  )}
                  onClear={onClearVatCodeInput}
                  disabled={isEdit}
                />
              </$Col>
              <$Col span={18}>
                <$Input
                  required
                  size="small"
                  name="name"
                  label={t("US.COLLECTION.ECONOMY:VAT_CODE.NAME")}
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-2">
              <$Col span={6}>
                <$Input
                  {...restProps}
                  label={t("US.COLLECTION.ECONOMY:VAT_CODE.RATE")}
                  required
                  placeholder="0,00"
                  className="w-100"
                  size="small"
                  name="rate"
                />
              </$Col>

              <$Col span={18}>
                <$Select
                  data-testid="ledger-account"
                  name="accountName"
                  formitem={{
                    label: t("US.COLLECTION.ECONOMY:VAT_CODE.ACCOUNT"),
                  }}
                  size="small"
                  options={ledgerAccounts}
                  allOption={false}
                  optionValue="accountId"
                  optionText={"accountName"}
                  onSearchBy={["accountName", "accountId"]}
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-2">
              <$Col span={6}>
                <$DatePicker
                  required
                  label={t("US.COLLECTION.ECONOMY:VAT_CODE.START_DATE")}
                  size="small"
                  name="startDate"
                  className="w-100"
                  value={values.startDate}
                  disabled={isEdit}
                  placeholder={currentDateFormat}
                  format={currentDateFormat}
                />
              </$Col>

              <$Col span={18}>
                {isEdit && (
                  <$DatePicker
                    required
                    label={t("US.COLLECTION.ECONOMY:VAT_CODE.END_DATE")}
                    size="small"
                    name="endDate"
                    className="w-100"
                    value={values.endDate}
                    placeholder={currentDateFormat}
                    format={currentDateFormat}
                    disabledDate={(d: any) => d.isBefore(values.startDate)}
                  />
                )}
              </$Col>
            </$Row>
            <div>
              <$TextArea
                data-testid="description"
                className="w-100"
                label={t("US.COLLECTION.ECONOMY:VAT_CODE.DESCRIPTION")}
                name="description"
                autoSize={{ minRows: 4, maxRows: 6 }}
              />
            </div>
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="ml-3 mr-2"
                data-testid="save-btn"
                type="primary"
                onClick={handleSubmit}
                disabled={
                  !restProps.isValid ||
                  !isFormDirty(values.vatCodeList, vatCodeList)
                }
                loading={vatCodeSaveAndUpdate?.isLoading}
              >
                {isEdit
                  ? t("US.COLLECTION.COMMON:COMMON.UPDATE")
                  : t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </$Button>
              <$Button onClick={onCancel}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { vatCode, common } = state;
  const { currentDateFormat } = common;
  const { list, vatCodeSaveAndUpdate, validationStatus, ledgerAccounts } =
    vatCode;
  return {
    vatCodeList: list,
    vatCodeSaveAndUpdate,
    validationStatus,
    ledgerAccounts,
    currentDateFormat,
  };
};
const { ledgerAccounts, addedVatCode, newVatCode, existingVatCode } =
  Actions.vatCode;
const { get } = ledgerAccounts;
const { save } = newVatCode;
const { update } = existingVatCode;
const mapDispatchToProps = {
  getLedgerAccounts: get,
  validateVatCode: addedVatCode.get,
  resetVatCodeValidation: addedVatCode.reset,
  addNewVatCode: save,
  updateVatCode: update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Details);
