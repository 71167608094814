import { IVatCodes } from "us.collection.economy/interfaces";

export const INITIAL_STATE_VAT_CODE: IVatCodes = {
  list: [],
  isListLoading: false,
  ledgerAccounts: [],
  validationStatus: true,
  vatCodeSaveAndUpdate: {
    data: {},
    isLoading: false,
  },
};
