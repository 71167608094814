import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { getTableColumnTotal } from "us.collection.economy/components/Remit/Functions";
import { IRootState } from "us.collection/interfaces";
import Common from "us.common";
import { getRouteUrl } from "us.helper";
import {
  RemitDetailsTableColumn,
  REMIT_DETAILS_TABLE_COLUMNS,
} from "./constants";

const { $AmountLabel, $Empty, $TableTree } = Common.Components;

const RemitDetailsTable: React.FC<PropsFromRedux> = ({
  remitSummaryByCreditor,
}) => {
  const { t } = useTranslation();
  const { remitDetails } = remitSummaryByCreditor.data;

  /**
   * @description - Handle navigation from a link by id or number
   * @param id - id of the column
   * @param linkId - Clicked id to refer the link dashboard
   */
  const handleLinks = (id: number, linkId: number) => {
    switch (id) {
      case 0:
        getRouteUrl.ar(linkId);
        break;
      case 1:
        getRouteUrl.caseType(linkId);
        break;
      case 2:
        getRouteUrl.mapPayment(linkId);
        break;
      default:
        break;
    }
  };

  // generate table columns
  const getColumns: any = () => {
    const columns: Array<any> = [];

    REMIT_DETAILS_TABLE_COLUMNS.map(({ id, key, title }: any) => {
      let column: any = {
        key,
        dataIndex: key,
        title: title ? t(`US.COLLECTION.ECONOMY:REMIT.${title}`) : "",
        customSorter: (a: any, b: any) => a - b,
      };
      if (key === RemitDetailsTableColumn.AR_NO) {
        column = {
          ...column,
          customRenderChild: (text: any, record: any) => {
            return (
              <div>
                <a
                  data-testid={`link-${key}`}
                  onClick={() => handleLinks(id, record[key])}
                >
                  {text}
                </a>
              </div>
            );
          },
        };
      } else if (
        [
          RemitDetailsTableColumn.CASE_NO,
          RemitDetailsTableColumn.PAYMENT_ID,
        ].includes(key)
      ) {
        column = {
          ...column,
          customFilter: true,
          showFilters: true,
          title: title ? t(`US.COLLECTION.ECONOMY:REMIT.${title}`) : "",
          customRenderChild: (text: any, record: any) => {
            return (
              <div>
                <a
                  data-testid={`link-${key}`}
                  onClick={() => handleLinks(id, record[key])}
                >
                  {text}
                </a>
              </div>
            );
          },
        };
      } else if (key == RemitDetailsTableColumn.DEBTOR) {
        column = {
          ...column,
          customRenderChild: (text: any) => {
            return (
              <div>
                <span>{text}</span>
              </div>
            );
          },
        };
      } else {
        column = {
          ...column,
          align: "right",
          with: "100px",
          title: () => (
            <div className="mr-2">
              <div>
                {title ? t(`US.COLLECTION.ECONOMY:REMIT.${title}`) : ""}
              </div>
              <div className="header-custom-amount">
                <$AmountLabel value={getTableColumnTotal(key, remitDetails)} />
              </div>
            </div>
          ),
          customRenderChild: (text: any, record: any) => {
            return (
              <span data-testid={`amount-${key}`}>
                <$AmountLabel value={record[key]} />
              </span>
            );
          },
        };
      }
      columns.push(column);
    });

    return columns;
  };

  return (
    <$TableTree
      data-testid="table-remit-details"
      rowKey={(record: any) => record.creditorNo}
      data={remitDetails}
      size="small"
      className="mt-3 header-custom-tag"
      onSort={(sortData, dataSource) => {
        return sortData(dataSource);
      }}
      onFilter={(searchData, dataSource) => {
        return searchData(dataSource);
      }}
      filterOnType
      resetOnSourceChange
      bordered
      showResetAllFilters
      pagination={{
        defaultPageSize: 20,
      }}
      scroll={{
        x: 900,
        y: "calc(100vh - 265px)",
      }}
      columns={getColumns()}
      locale={{
        emptyText: (
          <$Empty
            image={$Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <div className="text-center">
                <div>{t("US.COLLECTION.COMMON:COMMON.NO_DATA")}</div>
                <div>
                  {t(
                    "US.COLLECTION.ECONOMY:REMIT.SELECT_CREDITORS_AND_THE_CORRECT_BANK_ACCOUNT_TO_VIEW_REMIT"
                  )}
                </div>
              </div>
            }
          />
        ),
      }}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { remit } = state;

  const { remitValidation, remitSummaryByCreditor } = remit;
  return {
    remitValidation,
    remitSummaryByCreditor,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemitDetailsTable);
