import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { CreditorInvoiceDetails } from "us.collection.economy/constants/Actions";

const { creditorInvoiceDetails } = Actions;
const { getCreditorInvoiceDetails } = API.creditorInvoiceDetails;
const { invoiceDetails } = creditorInvoiceDetails;
const { GET_INVOICE_DETAILS } = CreditorInvoiceDetails;

export const CreditorInvoiceDetailSagas = {
  creditorInvoiceDetails: {
    getInvoiceDetails: function* (action: any): any {
      const invoiceNo = action?.payload?.data ?? "";
      try {
        const { data, status } = yield call(
          getCreditorInvoiceDetails,
          invoiceNo
        );
        if (status == 200) {
          yield put(invoiceDetails.success(data));
        } else {
          yield put(invoiceDetails.fail(new Error()));
        }
      } catch (error) {
        yield put(invoiceDetails.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(
    GET_INVOICE_DETAILS,
    CreditorInvoiceDetailSagas.creditorInvoiceDetails.getInvoiceDetails
  ),
];
