import { versionedHttpCollection } from "us.helper/http/collection";
import ServiceConfig from "service.config.js";

const interests = {
  interestMainAmount: 51.0,
  interestCostAmount: 35.0,
};

export const getAgreementAmounts = async <D>(params: D): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.get(
        "paymentagreementservice",
        `PaymentAgreement/${params}`,
        {},
        ServiceConfig()[`paymentagreementservice`]["paymentAgreement.amount"]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getAgreementDetail = async <
  D extends { caseNo: number; entityType: string }
>(
  params: D
): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.get(
        "paymentagreementservice",
        `PaymentAgreement/${params.caseNo}/${params.entityType}`,
        {},
        ServiceConfig()[`paymentagreementservice`]["paymentAgreement.detail"]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const saveAgreement = async <D>(params: D): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.post(
        "paymentagreementservice",
        "PaymentAgreement",
        params,
        ServiceConfig()[`paymentagreementservice`]["paymentAgreement"]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const getInterests = async <D extends { caseNo: number; date: string }>(
  params: D
): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.get(
        "paymentagreementservice",
        "Interest",
        {
          caseId: params.caseNo,
          interestToDate: params.date,
        },
        ServiceConfig()[`paymentagreementservice`]["paymentAgreement.interest"]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};

export const deleteAgreement = async (params: number): Promise<any> => {
  return new Promise<{}>(async (resolve, reject) => {
    try {
      const { data } = await versionedHttpCollection.delete(
        "paymentagreementservice",
        "PaymentAgreement",
        params,
        ServiceConfig()[`paymentagreementservice`]["paymentAgreement"]
      );
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
};
