import React from "react";
import { useTranslation } from "react-i18next";
import { Row } from "../Row";

interface Props {
  payments: Array<any>;
}
const PaymentItems: React.FC<Props> = ({ payments }) => {
  const { t } = useTranslation();
  return (
    <div className="map-item-table pr-2">
      {payments?.map(
        ({
          arItemNo,
          amount,
          caseNo,
          caseId,
          caseType,
          transactionItemTypeId,
        }: {
          arItemNo: string;
          amount: number;
          caseNo: string;
          caseId: number;
          caseType: string;
          transactionItemTypeId: number;
        }) => (
          <Row
            key={arItemNo}
            amount={amount}
            label={`${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NO")} : `}
            caseId={caseId}
            caseNo={caseNo}
            caseType={caseType}
            itemTypeId={transactionItemTypeId}
          />
        )
      )}
    </div>
  );
};

export default PaymentItems;
