import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import "./Home.scss";
import { DeleteOutlined, ReloadOutlined } from "us.icons";
import { RegisterPaymentValidationSchema } from "./Validation";
import moment from "moment";
import * as Actions from "us.collection.economy/actions";
import { IRegPaymentHome, IRegPaymentForm } from "./interface";
import { connect } from "react-redux";
import { SaveRegisterPayments } from "./Repository/SaveRegisterPayments";
import { IRootState } from "us.collection/interfaces";
import { navigateStart } from "us.common/actions";
import { getRouteUrl } from "us.helper";
import { useHistory } from "react-router-dom";
import {
  PaymentTypes,
  APIParams,
  PaymentMethods,
} from "us.collection.economy/constants/Payments";
import { handleGoBack } from "us.helper";
import { filterOCRPaymentTypes } from "./Functions";

const {
  getPaymentTypes,
  getPaymentMethods,
  getVoucherNo,
  getValidateCaseId,
  saveRegisterPayment,
  clearValidateCaseIdData,
} = Actions.regPayments;

const {
  $Affix,
  $PageHeader,
  $Divider,
  $Button,
  $Input,
  $Select,
  $Form,
  $DatePicker,
  $Table,
  $Tooltip,
  $Empty,
  $InputAmount,
  $Skeleton,
  $Popconfirm,
  $AmountLabel,
  $Row,
  $Col,
  $Message,
  $DateLabel,
  $InputAccountNo,
} = Common.Components;

const RegPaymentHome: React.FC<IRegPaymentHome> = (props) => {
  const { t } = useTranslation();

  const [showCaseInfo, setShowCaseInfo] = useState<boolean>(false);
  const [caseDetails, setCaseDetails] = useState<Array<IRegPaymentForm>>([]);
  const [addedAmount, setAddedAmount] = useState<number>(0);

  const inputCaseNoRef = React.useRef<any>();
  const paymentSelectRef = React.useRef<any>();
  const inputCaseAmountRef = React.useRef<any>();
  const saveButtonRef = React.useRef<any>();

  const {
    currentLanguage,
    currentCurrency,
    currentDateFormat,
    getPaymentTypes,
    getPaymentMethods,
    getVoucherNo,
    getValidateCaseId,
    saveRegisterPayment,
    clearValidateCaseIdData,
    paymentTypes,
    paymentMethods,
    voucherNo,
    validationCaseIdResults,
    formInitial,
    isPaymentTypeFetching,
    recentPaymentId,
    isvalidating,
    isVoucherNoFetching,
    isAmountDisable,
    router,
    isPaymentSaving,
  } = props;

  const history = useHistory();

  useEffect(() => {
    getPaymentTypes(APIParams.PAYMENT_TYPES);
    clearValidateCaseIdData({ addedAmount });
  }, [router?.location?.key]);

  useEffect(() => {
    if (!isAmountDisable) {
      window.setTimeout(function () {
        typeof inputCaseAmountRef?.current !== "undefined" &&
          inputCaseAmountRef.current.focus();
      }, 1);
    }
  }, [isAmountDisable]);

  const deleteCase = (record: any, index: number) => {
    const cData = caseDetails.filter((caseDetail: any) => caseDetail != record);
    setCaseDetails(cData);
    setAddedAmount(addedAmount - record.amount);
  };

  const column: any = (restProps: any, values: any) => {
    const columns = [
      {
        title: "",
        key: "more",
        dataIndex: "more",
        width: "50px",
        render: (text: any, record: any, index: number) => {
          return (
            <$Button
              icon={<DeleteOutlined />}
              onClick={() => deleteCase(record, index)}
              size="small"
              type="primary"
              danger
            />
          );
        },
      },
      {
        title: () => (
          <>
            <div className="pr-2">
              <div>{t("US.COMMON:COMMON.AMOUNT")}</div>
              <div className="header-custom-amount">
                <$AmountLabel value={addedAmount} />
              </div>
            </div>
          </>
        ),
        dataIndex: "amount",
        key: "amount",
        width: "120px",
        align: "right",
        render: (text: any, record: any) => {
          let temp = addedAmount - record.amount;
          const remainingAmount =
            values.voucherAmount < addedAmount
              ? record.amount
              : values.voucherAmount > record.amount
              ? values.voucherAmount - temp
              : Number.MAX_SAFE_INTEGER;
          return (
            <div className="text-right">
              <$InputAmount
                {...restProps}
                currentLanguage={currentLanguage}
                currentCurrency={currentCurrency}
                min={0}
                placeholder="0,00"
                size="small"
                name="caseAmount"
                className="bui-number-input w-100"
                value={record.amount}
                onKeyPress={(e: any) => {
                  if (
                    e.charCode == 13 &&
                    values.paymentType != PaymentTypes.UNIDENTIFIED_PAYMENT
                  ) {
                    window.setTimeout(function () {
                      typeof inputCaseNoRef?.current !== "undefined" &&
                        inputCaseNoRef.current.focus();
                    }, 1);
                  }
                }}
                onChange={(val: any) => {
                  record.amount = remainingAmount < val ? remainingAmount : val;
                  let totalAmount = 0;

                  caseDetails.map((caseDetail: any) => {
                    totalAmount += caseDetail.amount;
                    return totalAmount;
                  });
                  const newAmount =
                    remainingAmount >= val &&
                    values.voucherAmount - totalAmount != 0
                      ? values.voucherAmount - totalAmount
                      : 0;
                  restProps.setFieldValue("amount", newAmount);
                  setAddedAmount(totalAmount);

                  if (remainingAmount < val) {
                    $Message.error(
                      t(
                        "US.COLLECTION.VALIDATIONS:REQUIRED.CANT_EXCEED_VOUCHER_AMOUNT"
                      )
                    );
                  }
                }}
              />
            </div>
          );
        },
      },
      {
        title: t("US.COLLECTION.ECONOMY:REGISTERPAYMENTS.CASE_NO"),
        dataIndex: "caseNo",
        width: "120px",
        key: "caseNo",
        render: (text: any, record: any) => {
          return (
            <a
              data-testid="case-id-link"
              onClick={() => getRouteUrl.caseType(record.caseNo)}
            >
              {text}
            </a>
          );
        },
      },
      {
        title: t("US.COLLECTION.COMMON:COMMON.DEBTOR"),
        dataIndex: "debtorName",
        key: "debtorName",
      },
      {
        title: t("US.COLLECTION.COMMON:COMMON.CREDITOR"),
        dataIndex: "creditorName",
        key: "creditorName",
      },
    ];

    return columns;
  };

  const handleEnterVoucherAmount = (
    { paymentType, paymentMethod, voucherAmount, datePaid }: any,
    errors: any
  ) => {
    if (
      paymentType == undefined ||
      paymentType == "" ||
      (paymentType == PaymentTypes.DIRECT_PAYMENT &&
        (paymentMethod == undefined || paymentMethod == "")) ||
      voucherAmount == undefined ||
      voucherAmount == "" ||
      datePaid == undefined
    ) {
      $Message.error(
        t("US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_REQUIRED_FEILDS")
      );
    } else if (errors.accountNo == "COMMON.INVALID_ACCOUNT_NO") {
      $Message.error(t("COMMON.INVALID_ACCOUNT_NO"));
    } else {
      setShowCaseInfo(true);
      getVoucherNo(paymentType);
    }
  };

  const getPaymentTypeDetails = (type: number) => {
    if (paymentTypes.length > 0) {
      const {
        ledgerAccountNo = 0,
        paymentTypeName = "",
        paymentTypeCode = "",
      } = paymentTypes?.filter(
        ({ paymentTypeId }: { paymentTypeId: number }) => paymentTypeId == type
      )[0];

      return {
        ledgerAccountNo,
        paymentTypeName,
        paymentTypeCode,
      };
    }

    return {
      ledgerAccountNo: "",
      paymentTypeName: "",
      paymentTypeCode: "",
    };
  };

  const handleSelectChange = (values: any, type: any, restProps: any) => {
    if (type == PaymentTypes.DIRECT_PAYMENT) {
      getPaymentMethods(APIParams.PAYMENT_METHODS);
    }
    setCaseDetails([]);
    setAddedAmount(0);
    clearValidateCaseIdData({
      values: { ...values, caseNo: "", amount: "", paymentType: type },
      addedAmount,
    });
    getVoucherNo(type);
  };

  const handleInputOnBlur = (values: any, errors: any) => {
    getValidateCaseId({
      addedAmount,
      values,
    });
  };

  const paymentTypeFocus = () => {
    typeof paymentSelectRef?.current !== "undefined" &&
      paymentSelectRef.current.focus();
  };

  const addCaseToTable = ({
    amount,
    voucherAmount,
    voucherReference,
    ...values
  }: {
    amount: number;
    voucherAmount: number;
    voucherReference: string;
  }) => {
    if (voucherAmount < addedAmount + amount) {
      $Message.error(
        t("US.COLLECTION.VALIDATIONS:REQUIRED.CANT_EXCEED_VOUCHER_AMOUNT")
      );
    } else {
      setAddedAmount(addedAmount + amount);

      const cDetails: Array<IRegPaymentForm> = [];

      const { debtorName, creditorName, caseNo, caseId, pid, caseType } =
        validationCaseIdResults;

      if (caseDetails.length == 0) {
        cDetails.push({
          id: 0,
          debtorName,
          creditorName,
          voucherReference,
          caseNo,
          caseId,
          amount,
          pid,
          caseType,
        });
      } else {
        const isExistingCase =
          caseDetails.filter((caseDetail: any) => caseDetail.caseNo == caseNo)
            .length > 0;

        if (isExistingCase) {
          caseDetails.map((caseDetail: any) => {
            if (caseDetail.caseNo == caseNo) {
              caseDetail.amount = caseDetail.amount + amount;
            }
          });
        } else {
          cDetails.push({
            id: caseDetails.length,
            debtorName,
            creditorName,
            voucherReference,
            caseNo,
            caseId,
            amount,
            pid,
            caseType,
          });
        }
      }
      clearValidateCaseIdData({
        values: {
          amount,
          voucherAmount,
          voucherReference,
          ...values,
          caseNo: 0,
        },
        addedAmount: addedAmount + amount,
      });
      setCaseDetails([...caseDetails, ...cDetails]);
    }
  };

  return (
    <Formik
      initialValues={formInitial}
      enableReinitialize
      validationSchema={RegisterPaymentValidationSchema}
      validateOnBlur
      validateOnMount
      validateOnChange
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(true);
        const { ledgerAccountNo, paymentTypeCode } = getPaymentTypeDetails(
          values.paymentType
        );
        const payload = SaveRegisterPayments.call({
          ...values,
          voucherNo,
          ledgerAccountNo,
          paymentTypeCode,
          caseDetails,
        });
        saveRegisterPayment(payload);
        actions.resetForm();
        setAddedAmount(0);
        setShowCaseInfo(false);
        setCaseDetails([]);
        clearValidateCaseIdData({ addedAmount });
        paymentTypeFocus();
      }}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header  header-border">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      onBack={() => handleGoBack(history)}
                      title={t(
                        "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.REGISTER_PAYMENT"
                      )}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <$Button
                      className="mr-2"
                      data-testid="saveBtn"
                      type="primary"
                      size="small"
                      ref={saveButtonRef}
                      loading={isPaymentSaving}
                      disabled={
                        values.voucherAmount == 0 ||
                        (values.paymentType !=
                          PaymentTypes.UNIDENTIFIED_PAYMENT &&
                          values.voucherAmount !== addedAmount) ||
                        Object.keys(restProps.errors).length !== 0
                      }
                      onClick={(e: any) => handleSubmit(e)}
                    >
                      {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                    </$Button>
                    <$Popconfirm
                      title={t(
                        "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.ARE_YOU_SURE_YOU_WANT_TO_RESET_THE_VIEW"
                      )}
                      placement="topLeft"
                      data-testid="reset-popUp"
                      disabled={values.voucherAmount == addedAmount}
                      onConfirm={() => {
                        resetForm();
                        paymentTypeFocus();
                        setShowCaseInfo(false);
                        setCaseDetails([]);
                        clearValidateCaseIdData({ addedAmount });
                        setAddedAmount(0);
                        getVoucherNo(
                          PaymentTypes.MANUAL_PAYMENT_CLIENT_ACCOUNT
                        );
                      }}
                      okText={t("COMMON.YES")}
                      cancelText={t("COMMON.NO")}
                    >
                      <$Button
                        type="default"
                        size="small"
                        disabled={values.voucherAmount == addedAmount}
                        data-testid="reset-button"
                      >
                        {t("US.COLLECTION.COMMON:COMMON.RESET")}
                      </$Button>
                    </$Popconfirm>
                  </div>
                  <div>
                    {recentPaymentId &&
                      recentPaymentId &&
                      recentPaymentId[recentPaymentId.length - 1] &&
                      recentPaymentId[recentPaymentId.length - 1].paymentId !==
                        undefined && (
                        <a
                          data-testid="last-payment-id-link"
                          onClick={() =>
                            getRouteUrl.mapPayment(
                              recentPaymentId[recentPaymentId.length - 1]
                                .paymentId
                            )
                          }
                        >
                          {t(
                            "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.VIEW_LAST_PAYMENT"
                          )}
                          : (
                          {t(
                            "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.PAYMENT_ID"
                          )}
                          :
                          {
                            recentPaymentId[recentPaymentId.length - 1]
                              .paymentId
                          }
                          )
                        </a>
                      )}
                    {recentPaymentId &&
                      recentPaymentId &&
                      recentPaymentId[recentPaymentId.length - 1] &&
                      recentPaymentId[recentPaymentId.length - 1].paymentId ==
                        undefined && (
                        <a>
                          {t(
                            "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.VIEW_LAST_PAYMENT"
                          )}
                          : (
                          {t(
                            "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.PAYMENT_ID"
                          )}
                          : 0 )
                        </a>
                      )}
                  </div>
                </div>
              </div>
            </$Affix>
          </div>
          <div className="reg-payment-layout">
            <$Affix offsetTop={95}>
              <aside className="reg-payment-side">
                <$Form layout="vertical" autocomplete="off">
                  <$Skeleton
                    loading={isPaymentTypeFetching}
                    paragraph={{ rows: 2 }}
                  >
                    {paymentTypes.length > 0 && (
                      <div>
                        <div className="d-flex flex-column flex-fill">
                          <$Select
                            name="paymentType"
                            size="small"
                            formitem={{
                              label: t(
                                "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.PAYMENT_TYPE"
                              ),
                            }}
                            data-testid="select-payment-types"
                            options={filterOCRPaymentTypes(paymentTypes)}
                            optionValue="paymentTypeId"
                            optionText="paymentTypeName"
                            allOption={false}
                            selectRef={paymentSelectRef}
                            onChange={(val: any) => {
                              handleSelectChange(
                                {
                                  ...values,
                                  paymentMethod:
                                    val == PaymentTypes.DIRECT_PAYMENT
                                      ? PaymentMethods.CARD_PAYMENT
                                      : 0,
                                },
                                val,
                                restProps
                              );
                            }}
                            autoFocus={true}
                            value={values.paymentType}
                            tabIndex={1}
                            required={true}
                            onSearchBy={["paymentTypeName", "paymentTypeId"]}
                          />
                          {values.paymentType ==
                            PaymentTypes.DIRECT_PAYMENT && (
                            <$Select
                              name="paymentMethod"
                              size="small"
                              formitem={{
                                label: t(
                                  "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.PAYMENT_METHOD"
                                ),
                              }}
                              required
                              data-testid="select-payment-method"
                              options={paymentMethods}
                              optionValue="paymentTypeId"
                              optionText="paymentTypeName"
                              allOption={false}
                              tabIndex={
                                values.paymentType ==
                                PaymentTypes.DIRECT_PAYMENT
                                  ? 2
                                  : -1
                              }
                              onSearchBy={["paymentTypeName", "paymentTypeId"]}
                            />
                          )}
                        </div>

                        <$Row gutter={16}>
                          <$Col span={12}>
                            <$DatePicker
                              name={`datePaid`}
                              size="small"
                              label={t(
                                "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.DATE_PAID"
                              )}
                              required
                              value={values.datePaid}
                              allowClear={true}
                              style={{ width: "100%" }}
                              placeholder={currentDateFormat}
                              format={currentDateFormat}
                              disabledDate={(current: any) => {
                                return current && current > moment();
                              }}
                              data-testid="select-date-paid"
                              tabIndex={
                                values.paymentType ==
                                PaymentTypes.DIRECT_PAYMENT
                                  ? 3
                                  : 2
                              }
                            />
                          </$Col>
                          <$Col span={12}>
                            <$InputAccountNo
                              name="accountNo"
                              label={t(
                                "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.PAYER_ACCOUNT_NO"
                              )}
                              size="small"
                              type="text"
                              dataTestid="payer-account-no"
                              tabIndex={
                                values.paymentType ==
                                PaymentTypes.DIRECT_PAYMENT
                                  ? 4
                                  : 3
                              }
                              onKeyDown={(e: any) => {
                                if (e.key == "Enter") {
                                  if (
                                    values.paymentType !=
                                    PaymentTypes.UNIDENTIFIED_PAYMENT
                                  ) {
                                    window.setTimeout(function () {
                                      typeof inputCaseNoRef?.current !==
                                        "undefined" &&
                                        inputCaseNoRef.current.focus();
                                    }, 1);
                                  }
                                  handleEnterVoucherAmount(
                                    values,
                                    restProps.errors
                                  );
                                }
                              }}
                            />
                          </$Col>
                        </$Row>

                        <$Row gutter={16}>
                          <$Col span={12}>
                            <$Input
                              name="kid"
                              label={t(
                                "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.KID"
                              )}
                              size="small"
                              type="text"
                              onPressEnter={() => {
                                if (
                                  values.paymentType !=
                                  PaymentTypes.UNIDENTIFIED_PAYMENT
                                ) {
                                  window.setTimeout(function () {
                                    typeof inputCaseNoRef?.current !==
                                      "undefined" &&
                                      inputCaseNoRef.current.focus();
                                  }, 1);
                                }
                                handleEnterVoucherAmount(
                                  values,
                                  restProps.errors
                                );
                              }}
                              onChange={(e: any) => {
                                const reg = /^-?[0-9]*(\.[0-9]*)?$/;
                                if (
                                  !isNaN(e.target.value) &&
                                  reg.test(e.target.value)
                                ) {
                                  handleChange(e);
                                }
                              }}
                              tabIndex={
                                values.paymentType ==
                                PaymentTypes.DIRECT_PAYMENT
                                  ? 5
                                  : 4
                              }
                              dataTestid="kid-no"
                            />
                            <$InputAmount
                              {...restProps}
                              label={t(
                                "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.VOUCHER_AMOUNT"
                              )}
                              currentLanguage={currentLanguage}
                              currentCurrency={currentCurrency}
                              min={0}
                              placeholder="0,00"
                              size="small"
                              value={values.voucherAmount}
                              required
                              name="voucherAmount"
                              className="w-100"
                              formitem={{
                                extra: t(
                                  "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.PRESS_ENTER_TO_ADD"
                                ),
                              }}
                              dataTestid="input-voucher-amount"
                              onBlur={(e: any) => {
                                if (
                                  e.target.value != 0 &&
                                  values.voucherAmount > 0
                                ) {
                                  if (values.voucherAmount < addedAmount) {
                                    $Message.error(
                                      t(
                                        "US.COLLECTION.VALIDATIONS:REQUIRED.EXCEEDED_VOUCHER_AMOUNT"
                                      )
                                    );
                                  } else {
                                    handleEnterVoucherAmount(
                                      values,
                                      restProps.errors
                                    );
                                    if (
                                      values.paymentType !=
                                      PaymentTypes.UNIDENTIFIED_PAYMENT
                                    ) {
                                      window.setTimeout(function () {
                                        typeof inputCaseNoRef?.current !==
                                          "undefined" &&
                                          inputCaseNoRef.current.focus();
                                      }, 1);
                                    } else {
                                      window.setTimeout(function () {
                                        typeof saveButtonRef?.current !==
                                          "undefined" &&
                                          saveButtonRef?.current !== null &&
                                          saveButtonRef.current.focus();
                                      }, 1);
                                    }
                                  }
                                } else {
                                  $Message.error(
                                    t(
                                      "US.COLLECTION.VALIDATIONS:REQUIRED.VOUCHER_AMOUNT_MUST_BE_POSITIVE"
                                    )
                                  );
                                }
                              }}
                              onKeyPress={(e: any) => {
                                if (e.charCode == 13) {
                                  if (
                                    e.target.value != 0 &&
                                    values.voucherAmount > 0
                                  ) {
                                    if (values.voucherAmount < addedAmount) {
                                      $Message.error(
                                        t(
                                          "US.COLLECTION.VALIDATIONS:REQUIRED.EXCEEDED_VOUCHER_AMOUNT"
                                        )
                                      );
                                    } else {
                                      handleEnterVoucherAmount(
                                        values,
                                        restProps.errors
                                      );
                                      if (
                                        values.paymentType !=
                                        PaymentTypes.UNIDENTIFIED_PAYMENT
                                      ) {
                                        window.setTimeout(function () {
                                          typeof inputCaseNoRef?.current !==
                                            "undefined" &&
                                            inputCaseNoRef.current.focus();
                                        }, 1);
                                      } else {
                                        window.setTimeout(function () {
                                          typeof saveButtonRef?.current !==
                                            "undefined" &&
                                            saveButtonRef?.current !== null &&
                                            saveButtonRef.current.focus();
                                        }, 1);
                                      }
                                    }
                                  } else {
                                    $Message.error(
                                      t(
                                        "US.COLLECTION.VALIDATIONS:REQUIRED.VOUCHER_AMOUNT_MUST_BE_POSITIVE"
                                      )
                                    );
                                  }
                                }
                              }}
                              tabIndex={
                                values.paymentType ==
                                PaymentTypes.DIRECT_PAYMENT
                                  ? 7
                                  : 6
                              }
                            />
                          </$Col>
                          <$Col span={12}>
                            <$Input
                              name="voucherReference"
                              label={t(
                                "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.VOUCHER_REFERENCE"
                              )}
                              size="small"
                              type="text"
                              dataTestid="input-voucher-reference"
                              onPressEnter={() => {
                                if (
                                  values.paymentType !=
                                  PaymentTypes.UNIDENTIFIED_PAYMENT
                                ) {
                                  window.setTimeout(function () {
                                    typeof inputCaseNoRef?.current !==
                                      "undefined" &&
                                      inputCaseNoRef.current.focus();
                                  }, 1);
                                }
                                handleEnterVoucherAmount(
                                  values,
                                  restProps.errors
                                );
                              }}
                              tabIndex={
                                values.paymentType ==
                                PaymentTypes.DIRECT_PAYMENT
                                  ? 6
                                  : 5
                              }
                            />
                          </$Col>
                        </$Row>
                      </div>
                    )}
                    {paymentTypes.length == 0 && (
                      <div>
                        <div className="d-flex flex-column align-items-center mt-4">
                          <div className="text-muted">
                            {t(
                              "US.COLLECTION.COMMON:COMMON.SOMETHING_WENT_WRONG_PLEASE_RETRY"
                            )}
                          </div>
                          <$Button
                            type="link"
                            icon={<ReloadOutlined />}
                            onClick={() =>
                              getPaymentTypes(APIParams.PAYMENT_TYPES)
                            }
                            data-testid="retry-btn"
                          >
                            {t("US.COLLECTION.COMMON:COMMON.TRY_AGAIN")}
                          </$Button>
                        </div>
                      </div>
                    )}
                  </$Skeleton>
                  {values.paymentType != PaymentTypes.UNIDENTIFIED_PAYMENT &&
                    showCaseInfo && (
                      <div className="mt-4">
                        <$Row gutter={16}>
                          <$Col span={12}>
                            <$Input
                              dataTestid="input-case-no"
                              label={t(
                                "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.CASE_NO"
                              )}
                              type="text"
                              size="small"
                              name="caseNo"
                              required
                              tabIndex={
                                values.paymentType ==
                                PaymentTypes.DIRECT_PAYMENT
                                  ? 8
                                  : 7
                              }
                              ref={inputCaseNoRef}
                              disabled={values.voucherAmount == addedAmount}
                              onBlur={(e: any) => {
                                window.setTimeout(function () {
                                  typeof inputCaseAmountRef?.current !==
                                    "undefined" &&
                                    inputCaseAmountRef?.current !== null &&
                                    inputCaseAmountRef.current.focus();
                                }, 1000);
                                if (
                                  e.target.value !== "" &&
                                  e.target.value != 0
                                ) {
                                  handleInputOnBlur(values, restProps.errors);
                                } else {
                                  clearValidateCaseIdData({
                                    values,
                                    addedAmount,
                                  });
                                }
                              }}
                            />
                          </$Col>
                          <$Col span={12}>
                            <$InputAmount
                              {...restProps}
                              label={t("US.COLLECTION.COMMON:COMMON.AMOUNT")}
                              currentLanguage={currentLanguage}
                              currentCurrency={currentCurrency}
                              min={0}
                              tabIndex={
                                values.paymentType ==
                                PaymentTypes.DIRECT_PAYMENT
                                  ? 9
                                  : 8
                              }
                              placeholder="0,00"
                              required
                              size="small"
                              ref={inputCaseAmountRef}
                              value={values.amount}
                              disabled={isAmountDisable}
                              name="amount"
                              className="w-100"
                              formitem={{
                                extra: t(
                                  "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.PRESS_ENTER_TO_ADD"
                                ),
                              }}
                              onKeyPress={(e: any) => {
                                if (
                                  e.charCode == 13 &&
                                  values.paymentType !=
                                    PaymentTypes.UNIDENTIFIED_PAYMENT
                                ) {
                                  if (
                                    values.voucherAmount ==
                                    addedAmount + values.amount
                                  ) {
                                    window.setTimeout(function () {
                                      typeof saveButtonRef?.current !==
                                        "undefined" &&
                                        saveButtonRef?.current !== null &&
                                        saveButtonRef.current.focus();
                                    }, 1);
                                  } else {
                                    window.setTimeout(function () {
                                      typeof inputCaseNoRef?.current !==
                                        "undefined" &&
                                        inputCaseNoRef?.current !== null &&
                                        inputCaseNoRef.current.focus();
                                    }, 1);
                                  }
                                  if (
                                    values.caseNo !== "" &&
                                    values.caseNo !== undefined &&
                                    values.caseNo != 0 &&
                                    e.target.value !== "" &&
                                    e.target.value !== 0 &&
                                    values.amount !== 0 &&
                                    !(values.amount > values.voucherAmount)
                                  ) {
                                    if (
                                      Object.keys(validationCaseIdResults)
                                        .length == 0
                                    ) {
                                      $Message.error(
                                        t(
                                          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_WAIT_UNTILL_LOAD_THE_CASE_DETAILS"
                                        )
                                      );
                                    } else {
                                      addCaseToTable(values);
                                    }
                                  } else {
                                    if (values.amount > values.voucherAmount) {
                                      $Message.error(
                                        t(
                                          "US.COLLECTION.VALIDATIONS:REQUIRED.CANT_EXCEED_VOUCHER_AMOUNT"
                                        )
                                      );
                                    } else if (values.caseNo == 0) {
                                      $Message.error(
                                        t(
                                          "US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_CASE_NO"
                                        )
                                      );
                                    } else {
                                      $Message.error(
                                        t(
                                          "US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_ENTER_REQUIRED_FEILDS"
                                        )
                                      );
                                    }
                                  }
                                }
                              }}
                              data-testid={"amount"}
                            />
                          </$Col>
                        </$Row>
                        <$Skeleton
                          loading={isvalidating}
                          paragraph={{ rows: 2 }}
                        >
                          {Object.keys(validationCaseIdResults).length != 0 &&
                            !isvalidating && (
                              <div
                                className="reg-payment-case-summery d-flex justify-content-between align-items-center"
                                data-testid={"case-details"}
                              >
                                <div className="flex-fill">
                                  <div className="font-weight-bold">
                                    {!(
                                      values.paymentType ==
                                        PaymentTypes.OCR_OWN_ACCOUNT &&
                                      !validationCaseIdResults.isCreditorInvoice
                                    ) &&
                                    validationCaseIdResults.debtorName !=
                                      undefined
                                      ? validationCaseIdResults.debtorName
                                      : "-"}
                                  </div>
                                  <div>
                                    {t("US.COLLECTION.COMMON:COMMON.DEBTOR")}
                                  </div>

                                  <div className="font-weight-bold mt-2">
                                    {!(
                                      values.paymentType ==
                                        PaymentTypes.OCR_OWN_ACCOUNT &&
                                      !validationCaseIdResults.isCreditorInvoice
                                    ) &&
                                    validationCaseIdResults.creditorName !=
                                      undefined
                                      ? validationCaseIdResults.creditorName
                                      : "-"}
                                  </div>
                                  <div>
                                    {t("US.COLLECTION.COMMON:COMMON.CREDITOR")}
                                  </div>
                                </div>
                                <$Divider
                                  className="mx-4"
                                  type="vertical"
                                  style={{
                                    height: "100px",
                                  }}
                                ></$Divider>
                                <div className="text-right">
                                  <div className="font-weight-bold font-lg">
                                    {!(
                                      values.paymentType ==
                                        PaymentTypes.OCR_OWN_ACCOUNT &&
                                      !validationCaseIdResults.isCreditorInvoice
                                    ) &&
                                    validationCaseIdResults.arBalance !=
                                      undefined ? (
                                      <$AmountLabel
                                        value={
                                          validationCaseIdResults.arBalance
                                        }
                                      />
                                    ) : (
                                      "-"
                                    )}
                                  </div>
                                  <div>
                                    {t(
                                      "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.CASE_BALANCE"
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                        </$Skeleton>
                      </div>
                    )}
                </$Form>
              </aside>
            </$Affix>
            <div className="flex-fill ">
              {showCaseInfo && (
                <div className="p-3 pt-1">
                  <div className="reg-payment-summery d-flex">
                    <div className="d-flex flex-column">
                      <span
                        className="font-weight-bold text-truncate"
                        style={{ minHeight: "21px" }}
                      >
                        {isVoucherNoFetching ? (
                          <$Skeleton.Input
                            style={{ width: 50 }}
                            active={true}
                            size={"small"}
                          />
                        ) : showCaseInfo && voucherNo !== "" ? (
                          voucherNo
                        ) : (
                          "-"
                        )}
                      </span>

                      <span>
                        {t("US.COLLECTION.ECONOMY:REGISTERPAYMENTS.VOUCHER_NO")}
                      </span>
                    </div>

                    <$Divider
                      className="mx-4"
                      type="vertical"
                      style={{
                        height: "100%",
                      }}
                    ></$Divider>
                    <div className="d-flex flex-column">
                      <span
                        className="font-weight-bold text-truncate"
                        style={{ minHeight: "21px" }}
                      >
                        {showCaseInfo && values.voucherReference !== ""
                          ? values.voucherReference
                          : "-"}
                      </span>
                      <span>
                        {t(
                          "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.VOUCHER_REFERENCE"
                        )}
                      </span>
                    </div>
                    <$Divider
                      className="mx-4"
                      type="vertical"
                      style={{
                        height: "100%",
                      }}
                    ></$Divider>
                    <div className="d-flex flex-column">
                      <span
                        className="font-weight-bold text-truncate"
                        style={{ minHeight: "21px" }}
                      >
                        <$Tooltip
                          title={
                            showCaseInfo
                              ? getPaymentTypeDetails(values.paymentType)
                                  .paymentTypeName
                              : ""
                          }
                        >
                          {showCaseInfo
                            ? getPaymentTypeDetails(values.paymentType)
                                .paymentTypeName
                            : ""}
                        </$Tooltip>
                      </span>
                      <span>
                        {t(
                          "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.PAYMENT_TYPE"
                        )}
                      </span>
                    </div>
                    <$Divider
                      className="mx-4"
                      type="vertical"
                      style={{
                        height: "100%",
                      }}
                    />
                    <div className="d-flex flex-column">
                      <span
                        className="font-weight-bold"
                        style={{ minHeight: "21px" }}
                      >
                        {showCaseInfo && <$DateLabel value={values.datePaid} />}
                      </span>
                      <span>
                        {t("US.COLLECTION.ECONOMY:REGISTERPAYMENTS.DATE_PAID")}
                      </span>
                    </div>
                    <$Divider
                      className="mx-4"
                      type="vertical"
                      style={{
                        height: "100%",
                      }}
                    />
                    <div className="d-flex flex-column">
                      <span
                        className="font-weight-bold "
                        style={{ minHeight: "21px" }}
                      >
                        {showCaseInfo &&
                        getPaymentTypeDetails(values.paymentType)
                          .ledgerAccountNo !== ""
                          ? getPaymentTypeDetails(values.paymentType)
                              .ledgerAccountNo
                          : "-"}
                      </span>
                      <span className="text-truncate">
                        {t(
                          "US.COLLECTION.ECONOMY:REGISTERPAYMENTS.LEDGER_ACCOUNT_NO"
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {caseDetails.length === 0 && (
                <$Empty
                  data-testid="empty-data"
                  image={$Empty.PRESENTED_IMAGE_SIMPLE}
                  description={t("US.COMMON:COMMON.NO_DATA")}
                />
              )}
              {caseDetails.length !== 0 &&
                values.paymentType != PaymentTypes.UNIDENTIFIED_PAYMENT && (
                  <div className="p-3">
                    <$Table
                      columns={column(restProps, values)}
                      rowKey={(record) => record.id}
                      dataSource={caseDetails}
                      className="table-striped header-custom-tag"
                      bordered
                      size="small"
                      data-testid="case-table"
                    />
                  </div>
                )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, registerPayment, router } = state;
  const { payments, formInitial } = registerPayment;
  const { currentLanguage, currentCurrency, currentDateFormat } = common;
  const {
    paymentTypes,
    paymentMethods,
    voucherNo,
    validationCaseIdResults,
    isFetching,
    isPaymentTypeFetching,
    paymentId,
    isvalidating,
    isVoucherNoFetching,
    isAmountDisable,
    isPaymentSaving,
  } = payments;

  return {
    currentLanguage,
    currentCurrency,
    currentDateFormat,
    paymentTypes,
    paymentMethods,
    voucherNo: voucherNo.voucherNo,
    validationCaseIdResults,
    isFetching,
    isvalidating,
    formInitial,
    isPaymentTypeFetching,
    recentPaymentId: paymentId,
    isVoucherNoFetching,
    isAmountDisable,
    router,
    isPaymentSaving,
  };
};

const mapDispatchToProps = {
  getPaymentTypes,
  getPaymentMethods,
  getVoucherNo,
  getValidateCaseId,
  saveRegisterPayment,
  clearValidateCaseIdData,
  navigateStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegPaymentHome);
