import { ISaveProvisionProfileParams } from "us.collection.economy/interfaces";

export function SaveProvisionProfile(this: any): ISaveProvisionProfileParams {
  const {
    profileId,
    profileName,
    maximumMainAmountProvision,
    maximumInterestProvision,
    isActive,
  } = this.values ?? {};
  const { profileDetailsPerson, profileDetailsCompany } = this.orderLines ?? {};
  return {
    profileId,
    profileName,
    maximumMainAmountProvision,
    maximumInterestProvision,
    isActive,
    profileDetails: [...profileDetailsPerson, ...profileDetailsCompany].map(
      (order: any) => ({
        ...order,
        startMainAmount: order.mainAmountRange[0],
        endMainAmount: order.mainAmountRange[1],
      })
    ),
  };
}
