import { Drawer } from "us.collection.economy/components/Remit/Interfaces";

export const DRAWER_DEFAULT_STATE: Drawer = {
  title: "",
  visible: false,
  width: "600px",
};

export enum CoveringSequenceAction {
  EDIT = "EDIT",
  COPY = "COPY",
  DELETE = "DELETE",
  ADD = "ADD",
}

export const ITEM_MENU_DEFAULT_STATE = {
  visible: false,
  id: 0,
};

export type CoveringSequenceRecord = {
  key: string;
  name: string;
  displayName: string;
  sequenceId: string;
};

export const ITEM_TYPE_SEARCH_PARAMS = {
  transactionGroupIds: [1, 4, 5, 8],
  itemTypes: ["ALL"],
  isPayment: false,
  isCancelation: false,
};

export const DEFAULT_SELECT_PROPS = {
  options: [],
  optionText: "",
  optionValue: "",
  onSearchBy: [],
};
