import { ClientOrders } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";
import { IManualOrdersAction } from "us.collection.economy/interfaces";

const {
  SEARCH_CLIENT_ORDERS,
  SEARCH_CLIENT_ORDERS_SUCCESS,
  SEARCH_CLIENT_ORDERS_FAILED,

  GET_ORDER_TYPES,
  GET_ORDER_TYPES_SUCCESS,
  GET_ORDER_TYPES_FAILED,

  EXPORT_ORDERS,
  EXPORT_ORDERS_SUCCESS,
  EXPORT_ORDERS_FAILED,

  DELETE_ORDERS,
  DELETE_ORDERS_SUCCESS,
  DELETE_ORDERS_FAILED,

  SORT_ORDERS,

  GENERATE_ORDERS,
  GENERATE_ORDERS_SUCCESS,
  GENERATE_ORDERS_FAILED,
  HANDLE_ORDER_GENERATE_DRAWER
} = ClientOrders;

export const clientOrders: Readonly<IAPIAction & IManualOrdersAction> = {
  orders: {
    search: (data) => ({
      type: SEARCH_CLIENT_ORDERS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    sort: (data) => ({
      type: SORT_ORDERS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEARCH_CLIENT_ORDERS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEARCH_CLIENT_ORDERS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  orderTypes: {
    get: (data) => ({
      type: GET_ORDER_TYPES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_ORDER_TYPES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_ORDER_TYPES_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  exportOrder: {
    save: (data) => ({
      type: EXPORT_ORDERS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: EXPORT_ORDERS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: EXPORT_ORDERS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  order: {
    delete: (data) => ({
      type: DELETE_ORDERS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_ORDERS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_ORDERS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  generateOrders: {
    save: (data) => ({
      type: GENERATE_ORDERS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GENERATE_ORDERS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GENERATE_ORDERS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
    handleDrawer: (data) => ({
      type: HANDLE_ORDER_GENERATE_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
};
