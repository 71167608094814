export enum CreditorInvoice {
  GET_CREDITOR_INVOICE_DETAILS_START = "GET_CREDITOR_INVOICE_DETAILS_START",
  GET_CREDITOR_INVOICE_DETAILS_SUCCESS = "GET_CREDITOR_INVOICE_DETAILS_SUCCESS",
  GET_CREDITOR_INVOICE_DETAILS_FAIL = "GET_CREDITOR_INVOICE_DETAILS_FAIL",
  RESET_CREDITOR_INVOICE_DETAILS = "RESET_CREDITOR_INVOICE_DETAILS",
  SET_DRAWER_VISIBLE_STATUS = "SET_DRAWER_VISIBLE_STATUS",

  SAVE_CREDIT_NOTE_START = "SAVE_CREDIT_NOTE_START",
  SAVE_CREDIT_NOTE_SUCCESS = "SAVE_CREDIT_NOTE_SUCCESS",
  SAVE_CREDIT_NOTE_FAIL = "SAVE_CREDIT_NOTE_FAIL",
}
