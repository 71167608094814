import { LedgerAccount } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";
import { ILedgerAccountActions } from "us.collection.economy/interfaces";

const {
  GET_LEDGER_ACCOUNTS_DATA,
  GET_LEDGER_ACCOUNTS_DATA_SUCCESS,
  GET_LEDGER_ACCOUNTS_DATA_FAIL,
  ADD_NEW_LEDGER_ACCOUNT,
  ADD_NEW_LEDGER_ACCOUNT_SUCCESS,
  ADD_NEW_LEDGER_ACCOUNT_FAIL,
  EDIT_LEDGER_ACCOUNT,
  EDIT_LEDGER_ACCOUNT_SUCCESS,
  EDIT_LEDGER_ACCOUNT_FAIL,
  DELETE_LEDGER_ACCOUNT,
  DELETE_LEDGER_ACCOUNT_SUCCESS,
  DELETE_LEDGER_ACCOUNT_FAIL,
  HANDLE_LEDGER_ACCOUNTS_DRAWER,
  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD,
  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_SUCCESS,
  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_FAIL
} = LedgerAccount;

export const ledgerAccounts: Readonly<IAPIAction & ILedgerAccountActions> = {
  accounts: {
    get: (data) => ({
      type: GET_LEDGER_ACCOUNTS_DATA,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_LEDGER_ACCOUNTS_DATA_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_LEDGER_ACCOUNTS_DATA_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  newAccount: {
    save: (data) => ({
      type: ADD_NEW_LEDGER_ACCOUNT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_NEW_LEDGER_ACCOUNT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_NEW_LEDGER_ACCOUNT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    manageDrawer: (data) => ({
      type: HANDLE_LEDGER_ACCOUNTS_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  existingAccount: {
    update: (data) => ({
      type: EDIT_LEDGER_ACCOUNT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: EDIT_LEDGER_ACCOUNT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: EDIT_LEDGER_ACCOUNT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  ledgerAccount: {
    deleteById: (data) => ({
      type: DELETE_LEDGER_ACCOUNT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_LEDGER_ACCOUNT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_LEDGER_ACCOUNT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  validateField: {
    get: (data) => ({
      type: DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
