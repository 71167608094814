import {
  ISearchItemTypesPayload,
  ISaveItemTypePayload,
} from "us.collection.economy/interfaces";
import { httpCollection } from "us.helper/http/collection";
import { ValidateFieldNames } from "us.collection.economy/components/ItemTypes/Constants";

export default {
  search: {
    init: (params: { searchParams: ISearchItemTypesPayload }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const search = await httpCollection.post(
            "economysettingservice",
            "itemtypes/search",
            params.searchParams
          );

          const groups = await httpCollection.get(
            "economysettingservice",
            "transactiongroups",
            {}
          );

          resolve({
            itemTypes: search.data,
            transactionGroups: groups.data,
            status: search.status,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    itemTypes: (params: {
      searchParams: ISearchItemTypesPayload;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "economysettingservice",
            "itemtypes/search",
            params.searchParams
          );

          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: ({
      itemTypeId,
      searchParams,
    }: {
      itemTypeId: number;
      searchParams: any;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const deleteData = await httpCollection.delete(
            "economysettingservice",
            `itemtype`,
            itemTypeId
          );
          const itemTypesData = await httpCollection.post(
            "economysettingservice",
            "itemtypes/search",
            searchParams
          );

          resolve({
            deleteData: deleteData.data,
            itemTypesData: itemTypesData.data,
            status: deleteData.status,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  detail: {
    add: ({
      searchParams,
      saveParams,
    }: {
      searchParams: ISearchItemTypesPayload;
      saveParams: ISaveItemTypePayload;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const save = await httpCollection.post(
            "economysettingservice",
            "itemtype",
            saveParams
          );
          const search = await httpCollection.post(
            "economysettingservice",
            "itemtypes/search",
            searchParams
          );

          resolve({
            saveData: save.data,
            itemTypes: search.data,
            saveStatus: save.status,
            itemTypesStatus: search.status,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    edit: ({
      searchParams,
      saveParams,
    }: {
      searchParams: ISearchItemTypesPayload;
      saveParams: ISaveItemTypePayload;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const save = await httpCollection.put(
            "economysettingservice",
            "itemtype",
            saveParams
          );

          const search = await httpCollection.post(
            "economysettingservice",
            "itemtypes/search",
            searchParams
          );

          resolve({
            saveData: save.data,
            itemTypes: search.data,
            saveStatus: save.status,
            itemTypesStatus: search.status,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    init: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const ledgerAccounts = await httpCollection.get(
            "paymentservice",
            "payments/masterdata/ledgerAccounts",
            {}
          );

          const bankAccounts = await httpCollection.get(
            "economysettingservice",
            "clientaccounts",
            {}
          );

          const vatCodes = await httpCollection.get(
            "paymentservice",
            "payments/masterdata/vatcodes",
            {}
          );

          const voucherSequence = await httpCollection.post(
            "economysettingservice",
            "sequence/search",
            {
              sequenceIds: ["ALL"],
            }
          );

          resolve({
            bankAccounts: bankAccounts.data,
            ledgerAccounts: ledgerAccounts.data,
            voucherSequence: voucherSequence.data,
            vatCodes: vatCodes.data,
            status: [
              bankAccounts.status,
              ledgerAccounts.status,
              voucherSequence.status,
              vatCodes.status,
            ],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    get: ({ itemTypeId }: { itemTypeId: any }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "economysettingservice",
            `itemtypes?itemTypeId=${itemTypeId}`,
            {}
          );

          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    validateFields: ({
      value,
      field,
    }: {
      value: string | number;
      field: string;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          let result;
          if (field == ValidateFieldNames.ITEM_TYPE_ID) {
            result = await httpCollection.get(
              "economysettingservice",
              `itemtype/validate/itemtypeid/${value}`,
              {}
            );
          } else {
            result = await httpCollection.get(
              "economysettingservice",
              `itemtype/validate/transactioncode/${value}`,
              {}
            );
          }

          resolve({ data: result.data, status: result.status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  mangeGroups: {
    get: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "economysettingservice",
            "transactiongroups",
            {}
          );

          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  voucherSequence: {
    add: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "economysettingservice",
            `sequence`,
            params
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    }
  },
};
