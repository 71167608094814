import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    
    orderTableData: Yup.array().of(Yup.object().shape({
          units: Yup.number().test(
            "units",
            "US.COLLECTION.ECONOMY:ORDERS.UNITS_CANNOT_BE_0",
            function (val: any) {
              return val != 0
            }
          ),
    })) 
  });
};
