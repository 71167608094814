export enum RemitHistory {
  GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS",
  GET_BANK_ACCOUNTS_SUCCESS = "GET_BANK_ACCOUNTS_SUCCESS",
  GET_BANK_ACCOUNTS_FAIL = "GET_BANK_ACCOUNTS_FAIL",

  INIT_REMIT_HISTORY = "INIT_REMIT_HISTORY",

  SEARCH_REMIT_HISTORY = "SEARCH_REMIT_HISTORY",
  SEARCH_REMIT_HISTORY_SUCCESS = "SEARCH_REMIT_HISTORY_SUCCESS",
  SEARCH_REMIT_HISTORY_FAIL = "SEARCH_REMIT_HISTORY_FAIL",

  DOWNLOAD_REMIT_FILE = "DOWNLOAD_REMIT_FILE",
  DOWNLOAD_REMIT_FILE_SUCCESS = "DOWNLOAD_REMIT_FILE_SUCCESS",
  DOWNLOAD_REMIT_FILE_FAIL = "DOWNLOAD_REMIT_FILE_FAIL",

  REVERSE_REMIT = "REVERSE_REMIT",
  REVERSE_REMIT_SUCCESS = "REVERSE_REMIT_SUCCESS",
  REVERSE_REMIT_FAIL = "REVERSE_REMIT_FAIL",

  GET_LEDGER_DETAILS = "GET_LEDGER_DETAILS",
  GET_LEDGER_DETAILS_SUCCESS = "GET_LEDGER_DETAILS_SUCCESS",
  GET_LEDGER_DETAILS_FAIL = "GET_LEDGER_DETAILS_FAIL",
}
