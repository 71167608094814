import React from "react";
import "us.collection.economy/components/ClientOrders/Home.scss";
import { IOrder } from "us.collection.economy/components/ClientOrders/Interfaces";
import { IOrderList } from "./interface";
import { OrderItem } from "./Components";

const OrderList: React.FC<IOrderList> = ({
  orders,
  selectedOrders,
  onSelectOrder,
  filterParams
}) => {
  return (
    <div className="order-item">
      {orders?.map((order: IOrder) => {
        return (
          <OrderItem
            order={order}
            selectedOrders={selectedOrders}
            onSelectOrder={onSelectOrder}
            searchParams={filterParams}
            key={order.orderNo}
          />
        );
      })}
    </div>
  );
};

export default OrderList;
