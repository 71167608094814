import { CoveringSequenceAction } from "us.collection.economy/components/CoveringSequence/Constants";
import { CoveringSequenceRecord } from "us.collection.economy/components/CoveringSequence/Interfaces";
import { DynamicComponent } from "us.common/constants";
import { TypeCode } from "us.common/components/DragNDrop/Constants";
import { sequenceDetailsParams } from "us.collection.economy/interfaces";
import _ from "lodash";

/**
 * @description Get Drawer Title
 * @param {CoveringSequenceAction} type - List of sequence to find by id
 * @returns {string} - return the Drawer title by type
 */
export const getDrawerTitle = (type: CoveringSequenceAction): string => {
  switch (type) {
    case CoveringSequenceAction.ADD:
      return "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.NEW_COVERING_SEQUENCE";
    case CoveringSequenceAction.EDIT:
      return "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.EDIT_COVERING_SEQUENCE";
    case CoveringSequenceAction.COPY:
      return "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.COPY_COVERING_SEQUENCE";
    default:
      return "";
  }
};

/**
 * @description Get system cobering sequence by id
 * @param {Array<CoveringSequenceRecord>} sequenceList - List of sequence to find by id
 * @param {string | null} id - The URL params id to find matching sequence from the given list
 * @returns {CoveringSequenceRecord | undefined} - If find item will return it otherwise return undefined
 */
export const getSequenceRecordById = (
  sequenceList: Array<CoveringSequenceRecord>,
  id: string | null,
  defaultSequenceList: Array<any>
): CoveringSequenceRecord | {} => {
  try {
    if (id) {
      // old record
      const matchedSequenceRecord = sequenceList.find(
        ({ sequenceId }: CoveringSequenceRecord) => sequenceId == Number(id)
      );
      const sequenceDetails = matchedSequenceRecord?.sequenceDetails.map(
        (item: any, index: number) => ({
          ...item,
          value:
            item.type == TypeCode.TRANSACTION_GROUP
              ? item.name?.trim()
              : item.preferredIds.split(",").map(Number),
        })
      );
      return { ...matchedSequenceRecord, sequenceDetails } ?? {};
    } else {
      // new record
      const sequenceDetails = defaultSequenceList.map(
        (item: any, index: number) => ({
          ...item,
          componentType:
            item.type == TypeCode.TRANSACTION_GROUP
              ? DynamicComponent.Label
              : DynamicComponent.Select,
          id: index,
          isNew: false,
          name: item.hasOwnProperty("itemTypeId")
            ? item.itemTypeId
            : item.description?.trim(),
          preferredIds: item.preferredIds,
          value:
            item.type == TypeCode.TRANSACTION_GROUP
              ? item.description?.trim()
              : item.preferredIds.split(",").map(Number),
        })
      );
      return {
        sequenceId: 0,
        sequenceName: "",
        displayName: "",
        createdDate: "",
        modifiedDate: null,
        createdBy: "",
        modifiedBy: "",
        isUsed: false,
        sequenceDetails: sequenceDetails,
      };
    }
  } catch (error) {
    return {};
  }
};

/**
 * @description Get Button Title
 * @param {CoveringSequenceAction} type - List of sequence to find by id
 * @returns {string} - return the Button title by type
 */
export const getButtonTitle = (type: CoveringSequenceAction): string => {
  switch (type) {
    case CoveringSequenceAction.EDIT:
      return "US.COLLECTION.COMMON:COMMON.UPDATE";
    default:
      return "US.COLLECTION.COMMON:COMMON.ADD";
  }
};

/**
 * @description Get updated list with correct index values
 * @param {Array<CoveringSequenceRecord>} sequenceList - List of sequence to update
 * @param {number} hoverIndex - hoverIndex after drop
 * @param {number} dragIndex - dragIndex after drag
 * @returns {CoveringSequenceRecord} - return updated list
 */
export const getUpdatedListWithCorrectIndexes = (
  sequenceList: Array<CoveringSequenceRecord>,
  hoverIndex: number,
  dragIndex: number
): Array<CoveringSequenceRecord> => {
  try {
    return sequenceList?.map((item: CoveringSequenceRecord) => ({
      ...item,
      id: getSwapSequenceId(item.id, hoverIndex, dragIndex),
    }));
  } catch (error) {
    return sequenceList;
  }
};

/**
 * @description Get swap sequence ids
 * @param {number} id - item id
 * @param {number} hoverIndex - hoverIndex after drop
 * @param {number} dragIndex - dragIndex after drag
 * @returns {number} - return swaped ids
 */
export const getSwapSequenceId = (
  id: number,
  hoverIndex: number,
  dragIndex: number
): number => {
  try {
    if (hoverIndex < dragIndex) {
      return id >= hoverIndex && id < dragIndex ? id + 1 : id;
    } else if (hoverIndex > dragIndex) {
      return id <= hoverIndex && id > dragIndex ? id - 1 : id;
    } else {
      return id;
    }
  } catch (error) {
    return id;
  }
};

/**
 * @description find sequence details form is updated or not
 * @param {Array<any>} sequenceRecord - initial apportionment details
 * @param {Array<any>} updatedSequenceRecord - current form values
 * @returns {boolean} - if initial sequence record values are changed return true otherwise false
 */
export const isDirtyForm = (
  sequenceRecord: any,
  updatedSequenceRecord: any
) => {
  try {
    const {
      createdBy,
      createdDate,
      displayName,
      isUsed,
      modifiedBy,
      modifiedDate,
      sequenceDetails,
      sequenceId,
      sequenceName,
    } = updatedSequenceRecord;
    const formattedSequenceRecord = {
      createdBy,
      createdDate,
      displayName,
      isUsed,
      modifiedBy,
      modifiedDate,
      sequenceDetails,
      sequenceId,
      sequenceName,
    };

    return !_.isEqual(
      _.cloneDeep(sequenceRecord),
      _.cloneDeep(formattedSequenceRecord)
    );
  } catch (error) {
    return false;
  }
};

/**
 * @description get formatted sequence details
 * @param {Array<sequenceDetailsParams>} sequenceDetails - initial apportionment details
 * @returns {Array<sequenceDetailsParams} - return formatted sequence details
 */
export const getFormattedSequenceDetails = (
  sequenceDetails: Array<sequenceDetailsParams>
): Array<sequenceDetailsParams> => {
  try {
    const sequenceDetailsPayload: Array<sequenceDetailsParams> = [];
    sequenceDetails
      .filter((val: any) => val.name)
      .map((sequence: any) => {
        sequenceDetailsPayload.push({
          sequenceDetailId: sequence.sequenceDetailId ?? 0,
          rank: sequence.id + 1,
          description: sequence.name.toString(),
          preferredIds: sequence.preferredIds,
          type: sequence.type,
        });
      });
    return sequenceDetailsPayload;
  } catch (error) {
    return sequenceDetails;
  }
};
