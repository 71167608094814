import { VatCodeTableColumn } from "../Constants";

/**
 * @description - Get dynamic width property value for each column by key
 * @param {VatCodeTableColumn} key - Column key
 * @returns {string} - Width (px) of a column
 */
export const getColumnWidth = (key: VatCodeTableColumn): string => {
  switch (key) {
    case VatCodeTableColumn.MENU:
      return "40px";
    default:
      return "160px";
  }
};

/**
 * @description Get column search input field current value by key
 * @param {VatCodeTableColumn} key - input field name
 * @returns return column filters
 */
export const getColumnFilterProps = (key: VatCodeTableColumn) => {
  switch (key) {
    case VatCodeTableColumn.MENU:
      return {};
    default:
      return { customFilter: true, showFilters: true };
  }
};
