import React, { useEffect } from "react";
import { connect } from 'react-redux';
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { IRootState } from "us.collection/interfaces";
import * as Actions from "us.collection.economy/actions";
import { IAddArticles } from "./Interface";
import { SaveArticle, UpdateArticle } from "./Repository";
import { ArticleValidations } from "./Validations";
import { AsyncInputClass } from './AsyncInput';
import { initialDrawerValues } from 'us.collection.economy/reducers/Articles/State'

const { $Select, $Button, $Form, $InputAmount, $TextArea, $Tooltip } = Common.Components;

const { saveArticle, changeDrawerInfo, getArticleProperties } = Actions.articles;

const AddArticles: React.FC<IAddArticles> = (props) => {
    const { t } = useTranslation();

    const { vatCodes, categories, ledgerAccounts, changeDrawerInfo, drawerInfo, saveArticle, isDuplicateArticleNo, currentLanguage, currentCurrency, isSavingArticleData, getArticleProperties } = props;

    const { revenueAccountNo, nonVATAccountNo, defaultPrice, isInUse } = drawerInfo?.selectedArticle ?? {}

    const inputArticleNoRef = React.useRef<any>();

    const saveButtonRef = React.useRef<any>();

    useEffect(() => {
        window.setTimeout(function () {
            typeof inputArticleNoRef?.current !==
                "undefined" &&
                inputArticleNoRef?.current !== null &&
                inputArticleNoRef.current.focus();
        }, 1);
        getArticleProperties()
    }, []);

    return (
        <Formik
            initialValues={{
                ...drawerInfo.selectedArticle,
                revenueAccountNo: revenueAccountNo != undefined && revenueAccountNo != 0 ? revenueAccountNo : '',
                nonVATAccountNo: nonVATAccountNo != undefined && nonVATAccountNo != 0 ? nonVATAccountNo : '',
                defaultPrice: defaultPrice != undefined && defaultPrice != 0 ? defaultPrice : '',
            }}
            enableReinitialize
            validationSchema={ArticleValidations}
            onSubmit={(values: any, actions: any) => {
                actions.setSubmitting(true);
                const revenueAccountId = ledgerAccounts?.filter((acc: any) => acc.accountNo == values.revenueAccountNo)[0].accountId;
                const nonVatAccountId = ledgerAccounts?.filter((acc: any) => acc.accountNo == values.nonVATAccountNo)[0].accountId;
                const vatCodeId = vatCodes?.filter((vat: any) => vat.vatcode == values.vatCode)[0].vatCodeId;

                if (drawerInfo.isEdit) {
                    const updatePayload = UpdateArticle.call({ ...values, revenueAccountId, nonVatAccountId, vatCodeId });
                    saveArticle({ updatePayload, isEdit: true })
                } else {
                    const savePayload = SaveArticle.call({ ...values, revenueAccountId, nonVatAccountId, vatCodeId });
                    saveArticle({ savePayload, isEdit: false })
                }
            }}
        >
            {({
                values,
                errors,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValidating,
                resetForm,
                dirty,
                ...restProps
            }: any) => (
                <>
                    <div>
                        <$Form layout="vertical" autoComplete="off">
                            <$Tooltip title= {isInUse ? t("US.COLLECTION.ECONOMY:ARTICLES.THIS_ARTICLE_NO_IS_IN_USE") : ''}>
                                <div>
                                <AsyncInputClass 
                                    name="articleNo"
                                    label={t("US.COLLECTION.ECONOMY:ARTICLES.ARTICLE_NUMBER")}
                                    size="small"
                                    type="number"
                                    tabIndex={1}
                                    currentValues={values}
                                    articleNo={drawerInfo?.selectedArticle?.articleNo}
                                    required
                                    ref={inputArticleNoRef}
                                    disabled = {isInUse}
                                />
                                </div>
                            </$Tooltip>
                            <$Select
                                name="vatCodeName"
                                size="small"
                                formitem={{ label: t("US.COLLECTION.ECONOMY:ARTICLES.VAT_CODE") }}
                                required
                                options={vatCodes}
                                allOption={false}
                                optionValue="vatCodeName"
                                optionText="vatCodeName"
                                data-testid='select-vatcode'
                                tabIndex={2}
                                onChange={(val: any) => {
                                    restProps.setFieldValue("vatCode", vatCodes.filter((vat: any) => vat.vatCodeName == val)[0].vatcode)
                                }}
                                onSearchBy={["vatCodeName", "vatcode"]}
                            />
                            <$Select
                                name="revenueAccountName"
                                size="small"
                                formitem={{ label: t("US.COLLECTION.ECONOMY:ARTICLES.REVENUE_ACCOUNT") }}
                                required
                                options={ledgerAccounts}
                                allOption={false}
                                optionValue="accountName"
                                optionText={"accountName"}
                                data-testid='select-revenue-accno'
                                onChange={(val: any) => {
                                    restProps.setFieldValue("revenueAccountNo", ledgerAccounts.filter((acc: any) => acc.accountName == val)[0].accountNo)
                                }}
                                tabIndex={3}
                                onSearchBy={["accountName", "accountNo"]}
                            />
                            <$Select
                                name="nonVATAccountName"
                                size="small"
                                formitem={{ label: t("US.COLLECTION.ECONOMY:ARTICLES.NON-VAT_ACCOUNT") }}
                                required
                                options={ledgerAccounts}
                                allOption={false}
                                optionValue="accountName"
                                optionText="accountName"
                                data-testid='select-nonvat-accno'
                                onChange={(val: any) => {
                                    restProps.setFieldValue("nonVATAccountNo", ledgerAccounts.filter((acc: any) => acc.accountName == val)[0].accountNo)
                                }}
                                tabIndex={4}
                                onSearchBy={["accountName", "accountNo"]}
                            />
                            <$Select
                                name="category"
                                size="small"
                                formitem={{ label: t("US.COLLECTION.ECONOMY:ARTICLES.CATEGORY") }}
                                required
                                options={categories}
                                allOption={false}
                                optionValue="categoryName"
                                optionText="categoryName"
                                data-testid='select-category'
                                onChange={(val: any) => {
                                    restProps.setFieldValue("categoryId", categories?.filter((cate: any) => cate.categoryName == val)[0].categoryId)
                                }}
                                tabIndex={5}
                                onSearchBy={["categoryName", "categoryCode"]}
                            />
                            <$TextArea
                                style={{ width: "100%" }}
                                required
                                label={t("US.COLLECTION.ECONOMY:ARTICLES.ARTICLE_TEXT")}
                                name="description"
                                autoSize={{ minRows: 4, maxRows: 6 }}
                                data-testid='description'
                                tabIndex={6}
                            />
                            <$InputAmount
                                {...restProps}
                                label={t("US.COLLECTION.ECONOMY:ARTICLES.DEFAULT_PRICE")}
                                min={0}
                                placeholder="0,00"
                                currentLanguage={currentLanguage}
                                currentCurrency={currentCurrency}
                                size="small"
                                name="defaultPrice"
                                className="bui-number-input w-50"
                                value={values?.defaultPrice != undefined ? values?.defaultPrice : 0}
                                tabIndex={7}
                                onBlur={(e: any) => {
                                    window.setTimeout(function () {
                                        typeof saveButtonRef?.current !==
                                            "undefined" &&
                                            saveButtonRef?.current !== null &&
                                            saveButtonRef.current.focus();
                                    }, 1);
                                }}
                            />

                        </$Form>
                        <div className="drawer-footer-fixed align-content-center justify-content-end">
                            <div>
                                <$Button className="ml-3 mr-2" type="primary" disabled={isDuplicateArticleNo || (!dirty || !restProps.isValid)} data-testid='save-btn'
                                    onClick={(e: any) => handleSubmit(e)}
                                    ref={saveButtonRef}
                                    loading={isSavingArticleData} >
                                    {drawerInfo.isEdit ? t('US.COLLECTION.COMMON:COMMON.UPDATE')
                                        : t('US.COLLECTION.COMMON:COMMON.SAVE')}
                                </$Button>
                                <$Button onClick={() => changeDrawerInfo(initialDrawerValues)} >{t('US.COLLECTION.COMMON:COMMON.CANCEL')}</$Button>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};

const mapStateToProps = (state: IRootState) => {
    const { common, articles } = state;
    const { currentLanguage, currentCurrency, currentDateFormat } = common;
    const { articleList, vatCodes, categories, ledgerAccounts, drawerInfo, isDuplicateArticleNo, isSavingArticleData } = articles;
    return {
        currentLanguage,
        currentCurrency,
        currentDateFormat,
        articleList,
        vatCodes,
        categories,
        ledgerAccounts,
        drawerInfo,
        isDuplicateArticleNo,
        isSavingArticleData
    };
};

const mapDispatchToProps = {
    changeDrawerInfo,
    saveArticle,
    getArticleProperties
};

export default connect(mapStateToProps, mapDispatchToProps)(AddArticles);