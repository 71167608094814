import React, { useEffect, useState, useRef } from 'react';
import { useHistory, matchPath } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import moment from 'moment';
import { IntlCurrency, getPlaceHolderNumber, validateNumber, IntlDate } from 'us.common/functions';
import { Formik } from 'formik';
import Common from 'us.common';
import { $FormItem } from 'us.common/components';
import { IBase } from 'us.collection/interfaces'
import * as Action from 'us.collection/actions';
import { ValidationSchema } from './Validation';
import "./ManageAgreements.scss";
import { CheckCircleOutlined } from '@ant-design/icons';  //'us.icons'
import { IRootState } from 'us.collection/interfaces';
import _, { values } from 'lodash';


const ManageAgreements: React.FC<IBase> = (props) => {

    const { t } = useTranslation(['US.COLLECTION.CASE'], { useSuspense: true });
    const history = useHistory();

    const {
        $Form,
        $Affix,
        $PageHeader,
        $Divider,
        $Button,
        $DatePicker,
        $Input,
        $Switch,
        $Skeleton,
        $Popconfirm,
        $Row,
        $Col,
        $Alert,
        $InputAmount
    } = Common.Components;

    const {
        currentCurrency,
        currentDateFormat,
        currentLanguage,
        details,
        amounts,
        save,
        interests,
        getInterest,
        deleteAgreement,
        dashboardMetaData,
        get,
        addWithPartPayment,
    } = props;
    interface IInterest {
        interestCost: number | string;
        interestMain: number | string;
    }

    //const [values.isEdit, setEdit] = useState<boolean>(false)
    const [total, setTotal] = useState<any>();
    const [balance, setBalance] = useState<any>();
    const [data, setData] = useState<any>();
    const inputEl = useRef<HTMLDivElement>();
    const [isUsePartPaymentView, setIsUsePartPaymentView] = useState(false);

    useEffect(() => {
        const { params }: any = matchPath(props.location.pathname, {
            path: "/:caseType/:caseId/:agreements",
            exact: false,
            strict: false
        });
        let caseNo = params['caseId'];

        // chaeck view is use part payment 
        if(params['agreements'].toString() === "part-payments"){
            setIsUsePartPaymentView(true);
        }

        if (dashboardMetaData && dashboardMetaData.debtorEntRoleID > 0 && dashboardMetaData.boxCaseNumber.toString() === caseNo.toString()) {
            // getAmounts(dashboardMetaData?.boxCaseNumber)

            // getDetails(
            //     {
            //         entityType: dashboardMetaData?.caseType,
            //         caseNo: dashboardMetaData?.boxCaseNumber
            //     }
            // );
            get(
                {
                    entityType: dashboardMetaData?.caseType,
                    caseNo: dashboardMetaData?.boxCaseNumber
                }
            );
        }
    }, [dashboardMetaData])

    // useEffect(() => {
    //     let tem = {};
    //     let totalBalance;
    //     if(amounts?.mainBalance>0){
    //         totalBalance   = (
    //             amounts.collectionBalance +
    //             amounts.costBalance +
    //             amounts.courtBalance +
    //             amounts.mainBalance +
    //             amounts.otherBalance +
    //             amounts.interestCostBalance +
    //             amounts.interestMainBalance)
    //     }
    //     if ((details?.hasOwnProperty('payDate'))) {
    //         if (details?.mainAmount !== 0) {
    //             setEdit(true)
    //             tem = {
    //                 id: details?.id,
    //                 payDate: IntlDate(moment(details?.payDate).toString(), currentLanguage, currentDateFormat),
    //                 mainAmount: IntlCurrency(details?.mainAmount, currentLanguage, currentCurrency),
    //                 collectionFee: IntlCurrency(details?.collectionFee, currentLanguage, currentCurrency),
    //                 costFee: IntlCurrency(details?.costFee, currentLanguage, currentCurrency),
    //                 courtFee: IntlCurrency(details?.courtFee, currentLanguage, currentCurrency),
    //                 otherFee: IntlCurrency(details?.otherFee, currentLanguage, currentCurrency),
    //                 interestMainAmount: IntlCurrency(details?.interestMainAmount, currentLanguage, currentCurrency),
    //                 interestCostAmount: IntlCurrency(details?.interestCostAmount, currentLanguage, currentCurrency),
    //                 interestDate: IntlDate(moment(details?.interestDate).toString(), currentLanguage, currentDateFormat),
    //                 isSendSMSNotification: details?.isSendSMSNotification
    //             }
    //             setTotal(details?.total)
    //         } else {
    //             if (interests?.mainAmount?.length !== 0 && interests?.hasOwnProperty('mainAmount')) {
    //                 let totalAmount = (
    //                     getNumber(interests.collectionFee) +
    //                     getNumber(interests.costFee) +
    //                     getNumber(interests.courtFee) +
    //                     getNumber(interests.mainAmount) +
    //                     getNumber(interests.otherFee) +
    //                     getNumber(interests.interestMainBalance) +
    //                     getNumber(interests.intersetCostsBalance))
    //                 totalBalance= (
    //                     getNumber(interests.collectionBalance) +
    //                     getNumber(interests.costBalance) +
    //                     getNumber(interests.courtBalance) +
    //                     getNumber(interests.mainBalance) +
    //                     getNumber(interests.otherBalance) +
    //                     getNumber(interests.interestMainBalance) +
    //                     getNumber(interests.intersetCostsBalance))
    //                 tem = {
    //                     payDate: IntlDate(moment(interests?.payDate).toString(), currentLanguage, currentDateFormat),
    //                     mainAmount: IntlCurrency(interests?.mainAmount, currentLanguage, currentCurrency),
    //                     collectionFee: IntlCurrency(interests?.collectionFee, currentLanguage, currentCurrency),
    //                     costFee: IntlCurrency(interests?.costFee, currentLanguage, currentCurrency),
    //                     courtFee: IntlCurrency(interests?.courtFee, currentLanguage, currentCurrency),
    //                     otherFee: IntlCurrency(interests?.otherFee, currentLanguage, currentCurrency),
    //                     interestMainAmount: IntlCurrency(interests?.interestMainBalance, currentLanguage, currentCurrency),
    //                     interestCostAmount: IntlCurrency(interests?.intersetCostsBalance, currentLanguage, currentCurrency),
    //                     interestDate: IntlDate(moment(interests?.interestDate).toString(), currentLanguage, currentDateFormat),
    //                     isSendSMSNotification: interests?.isSendSMSNotification,
    //                     interestCostBalance: IntlCurrency(interests?.intersetCostsBalance, currentLanguage, currentCurrency),
    //                     interestMainBalance: IntlCurrency(interests?.interestMainBalance, currentLanguage, currentCurrency),
    //                     collectionBalance:IntlCurrency(interests?.collectionBalance, currentLanguage, currentCurrency),
    //                     costBalance:IntlCurrency(interests?.costBalance, currentLanguage, currentCurrency),
    //                     courtBalance:IntlCurrency(interests?.courtBalance, currentLanguage, currentCurrency),
    //                     otherBalance:IntlCurrency(interests?.otherBalance, currentLanguage, currentCurrency),
    //                     mainBalance:IntlCurrency(interests?.mainBalance, currentLanguage, currentCurrency),
    //                     minCaseBalance:IntlCurrency(interests?.minCaseBalance, currentLanguage, currentCurrency),
    //                     totalAmount: IntlCurrency(totalAmount.toString(), currentLanguage, currentCurrency)
    //                 }
    //                 setTotal(totalAmount)
    //                 setBalance(totalBalance)
    //             } else {
    //                 let totalAmount = (
    //                     amounts.collectionBalance +
    //                     amounts.costBalance +
    //                     amounts.courtBalance +
    //                     amounts.mainBalance +
    //                     amounts.otherBalance +
    //                     amounts.interestCostBalance +
    //                     amounts.interestMainBalance)
    //                 tem = {
    //                     payDate: IntlDate(moment().add(1, 'd').toString(), currentLanguage, currentDateFormat),
    //                     mainAmount: IntlCurrency(amounts?.mainBalance, currentLanguage, currentCurrency),
    //                     collectionFee: IntlCurrency(amounts?.collectionBalance, currentLanguage, currentCurrency),
    //                     costFee: IntlCurrency(amounts?.costBalance, currentLanguage, currentCurrency),
    //                     courtFee: IntlCurrency(amounts?.courtBalance, currentLanguage, currentCurrency),
    //                     otherFee: IntlCurrency(amounts?.otherBalance, currentLanguage, currentCurrency),
    //                     interestMainAmount: IntlCurrency(amounts?.interestMainBalance, currentLanguage, currentCurrency),
    //                     interestCostAmount: IntlCurrency(amounts?.interestCostBalance, currentLanguage, currentCurrency),
    //                     interestDate: IntlDate(moment().toString(), currentLanguage, currentDateFormat),
    //                     collectionBalance:IntlCurrency(amounts.collectionBalance, currentLanguage, currentCurrency),
    //                     costBalance:IntlCurrency(amounts.costBalance, currentLanguage, currentCurrency),
    //                     courtBalance:IntlCurrency(amounts.courtBalance, currentLanguage, currentCurrency),
    //                     otherBalance:IntlCurrency(amounts.otherBalance, currentLanguage, currentCurrency),
    //                     mainBalance:IntlCurrency(amounts.mainBalance, currentLanguage, currentCurrency),
    //                     interestCostBalance:IntlCurrency(amounts.interestCostBalance, currentLanguage, currentCurrency),
    //                     interestMainBalance:IntlCurrency(amounts.interestMainBalance, currentLanguage, currentCurrency),
    //                     isSendSMSNotification: false,
    //                     minCaseBalance:IntlCurrency(amounts?.minCaseBalance, currentLanguage, currentCurrency),
    //                     totalAmount: IntlCurrency(totalAmount.toString(), currentLanguage, currentCurrency)

    //                 }
    //                 setEdit(false)
    //                 setTotal(totalAmount)
    //                // setBalance(totalAmount)
    //             }

    //         }
    //         setBalance(totalBalance)
    //         setData(tem);
    //     }

    //     // let totalBalance = (
    //     //     amounts.collectionBalance +
    //     //     amounts.costBalance +
    //     //     amounts.courtBalance +
    //     //     amounts.mainBalance +
    //     //     amounts.otherBalance +
    //     //     amounts.interestCostBalance +
    //     //     amounts.interestMainBalance)
    //     // setBalance(totalBalance)
    // }, [amounts, details, interests, dashboardMetaData, currentLanguage, currentCurrency])


    useEffect(() => {
        setBalance(amounts.data?.totalAmount)
        setTotal(details.data?.totalBalance)
    }, [amounts, details, interests])

    useEffect(() => {
        setBalance(amounts.data?.totalAmount)
        setTotal(details.data?.totalBalance)
    }, [interests])
    const addAmountFormatter = (data: any) => {
        return {
            ...data,
            interestCostBalance: IntlCurrency(data?.intersetCostBalance, currentLanguage, currentCurrency),
            interestMainBalance: IntlCurrency(data?.interestMainBalance, currentLanguage, currentCurrency),
            collectionBalance: IntlCurrency(data?.collectionBalance, currentLanguage, currentCurrency),
            costBalance: IntlCurrency(data?.costBalance, currentLanguage, currentCurrency),
            courtBalance: IntlCurrency(data?.courtBalance, currentLanguage, currentCurrency),
            otherBalance: IntlCurrency(data?.otherBalance, currentLanguage, currentCurrency),
            mainBalance: IntlCurrency(data?.mainBalance, currentLanguage, currentCurrency),
            minCaseBalance: IntlCurrency(data?.minCaseBalance, currentLanguage, currentCurrency),
            totalAmount: IntlCurrency(data?.totalAmount, currentLanguage, currentCurrency)
        }
    }
    const addInterestFormatter = (data: any) => {
        let temData = {}
        temData = _.isEmpty(data) ? {} :
            {
                ...data,
                payDate: moment(data?.payDate).toString(),
                mainAmount: IntlCurrency(data?.mainAmount, currentLanguage, currentCurrency),
                collectionFee: IntlCurrency(data?.collectionFee, currentLanguage, currentCurrency),
                costFee: IntlCurrency(data?.costFee, currentLanguage, currentCurrency),
                courtFee: IntlCurrency(data?.courtFee, currentLanguage, currentCurrency),
                otherFee: IntlCurrency(data?.otherFee, currentLanguage, currentCurrency),
                interestMainAmount: IntlCurrency(data?.interestMainBalance, currentLanguage, currentCurrency),
                interestCostAmount: IntlCurrency(data?.intersetCostBalance, currentLanguage, currentCurrency),
                interestDate: moment(data?.interestDate).toString(),
                isSendSMSNotification: data?.isSendSMSNotification,
                interestCostBalance: IntlCurrency(data?.intersetCostBalance, currentLanguage, currentCurrency),
                interestMainBalance: IntlCurrency(data?.interestMainBalance, currentLanguage, currentCurrency),
                collectionBalance: IntlCurrency(data?.collectionBalance, currentLanguage, currentCurrency),
                costBalance: IntlCurrency(data?.costBalance, currentLanguage, currentCurrency),
                courtBalance: IntlCurrency(data?.courtBalance, currentLanguage, currentCurrency),
                otherBalance: IntlCurrency(data?.otherBalance, currentLanguage, currentCurrency),
                mainBalance: IntlCurrency(data?.mainBalance, currentLanguage, currentCurrency),
                minCaseBalance: IntlCurrency(data?.minCaseBalance, currentLanguage, currentCurrency),
                totalAmount: IntlCurrency(data?.totalBalance, currentLanguage, currentCurrency)
            }
        return temData;
    }
    const addDetailFormatter = (data: any) => {
        return {
            ...data,
            collectionFee: IntlCurrency(data?.collectionFee, currentLanguage, currentCurrency),
            costFee: IntlCurrency(data?.costFee, currentLanguage, currentCurrency),
            courtFee: IntlCurrency(data?.courtFee, currentLanguage, currentCurrency),
            interestCostAmount: IntlCurrency(data?.interestCostAmount, currentLanguage, currentCurrency),
            interestMainAmount: IntlCurrency(data?.interestMainAmount, currentLanguage, currentCurrency),
            mainAmount: IntlCurrency(data?.mainAmount, currentLanguage, currentCurrency),
            otherFee: IntlCurrency(data?.otherFee, currentLanguage, currentCurrency),
            total: IntlCurrency(data?.totalBalance, currentLanguage, currentCurrency)
        }
    }
    const handleSubmit = (data: any, { setErrors, setStatus, resetForm, isSubmitting, isValidating, setSubmitting }: any) => {
        let reqBody = {
            payDate: moment(data?.payDate).isValid() ? moment(data?.payDate).endOf('day').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
            mainAmount: getNumber(data?.mainAmount),
            interestCostAmount: getNumber(data?.interestCostAmount),
            interestMainAmount: getNumber(data?.interestMainAmount),
            collectionFee: getNumber(data?.collectionFee),
            costFee: getNumber(data?.costFee),
            courtFee: getNumber(data?.courtFee),
            otherFee: getNumber(data?.otherFee),
            totalAmount: total,
            interestCostBalance: getNumber(data?.intersetCostsBalance),
            interestMainBalance: getNumber(data?.interestMainBalance),
            id: 1,
            caseNo: dashboardMetaData?.boxCaseNumber,
            agreementType: dashboardMetaData?.caseType,
            isSendSMSNotification: data?.isSendSMSNotification,
            InterestCollectionAmount: 0.00,
            InterestCourtAmount: 0.00,
            InterestOtherAmount: 0.00,
            caseType: dashboardMetaData?.caseType,
            interestDate: moment(data?.payDate).isValid() ? moment(data?.interestDate).endOf('day').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        }
        save(reqBody)
    }

    const handleInputOnBlur = (e: any, setFieldValue: any, values: any) => {
        setFieldValue(e.target.name, IntlCurrency(e.target.value, currentLanguage, currentCurrency));
        let totalAmount = getNumber(values.collectionFee) +
            getNumber(values.costFee) +
            getNumber(values.courtFee) +
            getNumber(values.mainAmount) +
            getNumber(values.otherFee) +
            getNumber(values.interestCostAmount) +
            getNumber(values.interestMainAmount)

        setFieldValue('totalAmount', IntlCurrency(totalAmount, currentLanguage, currentCurrency));
        setTotal(totalAmount)
    }

    /**----------------------------------------------------- */
    const getNumber = (value: any): number => {
        let returnVal = validateNumber(value, currentLanguage);
        return returnVal && returnVal > 0 ? returnVal : 0;
    }

    const handleInterest = (data: any, rest: any, values: any) => {
        data = data === null ? moment().format('YYYY-MM-DD') : data
        rest.setFieldValue('interestDate', data)
        let convertToNumber = {
            mainAmount: getNumber(values?.mainAmount),
            collectionFee: getNumber(values?.collectionFee),
            costFee: getNumber(values?.costFee),
            courtFee: getNumber(values?.courtFee),
            otherFee: getNumber(values?.otherFee),
            interestCostBalance: getNumber(values?.intersetCostsBalance),
            interestMainBalance: getNumber(values?.interestMainBalance),
            collectionBalance: getNumber(values?.collectionBalance),
            costBalance: getNumber(values?.costBalance),
            courtBalance: getNumber(values?.courtBalance),
            otherBalance: getNumber(values?.otherBalance),
            mainBalance: getNumber(values?.mainBalance),
            minCaseBalance: getNumber(values?.minCaseBalance),
        }
        let payDate = moment(values.payDate, 'DD/MM/YYYY')
        // rest.setFieldValue('interestDate', data)
        getInterest({
            caseNo: dashboardMetaData?.boxCaseNumber,
            date: moment(data).format('YYYY-MM-DD'),
            values: { ...values, ...convertToNumber, currentLanguage, interestDate: data, payDate }
        })
    }
    const handleDelete = (values: any) => {
        deleteAgreement({
            entityType: dashboardMetaData?.caseType,
            caseNo: dashboardMetaData?.boxCaseNumber,
            id: values.id
        }) //values.id
    }
    /**
     * add payment agreement in add part payment view
    */ 
    const addToPartPaymentview = (data: any, action: string) =>{
        if(action == "add"){
            let reqBody = {
                payDate: moment(data?.payDate).isValid() ? moment(data?.payDate).endOf('day').format('YYYY-MM-DD') : moment().add(1, 'days').format('YYYY-MM-DD'),
                mainAmount: getNumber(data?.mainAmount),
                interestCostAmount: getNumber(data?.interestCostAmount),
                interestMainAmount: getNumber(data?.interestMainAmount),
                collectionFee: getNumber(data?.collectionFee),
                costFee: getNumber(data?.costFee),
                courtFee: getNumber(data?.courtFee),
                otherFee: getNumber(data?.otherFee),
                totalAmount: total,
                interestCostBalance: getNumber(data?.intersetCostsBalance),
                interestMainBalance: getNumber(data?.interestMainBalance),
                id: 1,
                caseNo: dashboardMetaData?.boxCaseNumber,
                agreementType: dashboardMetaData?.caseType,
                isSendSMSNotification: data?.isSendSMSNotification,
                InterestCollectionAmount: 0.00,
                InterestCourtAmount: 0.00,
                InterestOtherAmount: 0.00,
                caseType: dashboardMetaData?.caseType,
                interestDate: moment(data?.payDate).isValid() ? moment(data?.interestDate).endOf('day').format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
            }
            props.addWithPartPayment(reqBody)
        }
        else{
            props.addWithPartPayment(null)
        }
        
    }
    /**----------------------------------------------------- */

    return (
        <div className="agreement-wrap ">
            <Formik
                enableReinitialize
                initialValues={{
                    ...addAmountFormatter(amounts.data),
                    ...addDetailFormatter(details.data),
                    ...addInterestFormatter(interests.data),
                    isEdit: details.data?.id === 0 ? false : true,
                }}
                validationSchema={ValidationSchema}
                validateOnBlur
                validateOnChange
                onSubmit={handleSubmit}
            >
                {({
                    values,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    isValidating,
                    resetForm,
                    errors,
                    ...rest
                }) => {

                    return (
                        <$Form onSubmit={handleSubmit}>
                            <div className="space-content manage-agreements">
                                {!isUsePartPaymentView ? 
                                <$Affix offsetTop={80}>
                                    <div className="page-header header-border">
                                        <div className="d-flex flex-row align-items-center justify-content-between">
                                            <div className="d-flex align-items-center">
                                                <$PageHeader
                                                    className="px-0"
                                                    onBack={() => history.goBack()} title={t('US.COLLECTION.CASE:MANAGEAGREEMENTS.TITLE')} 
                                                />
                                                <$Divider className="bui-devider" type="vertical" />
                                                <$FormItem>
                                                    {values.isEdit ?
                                                        <$Skeleton loading={details.isLoading} active paragraph={{ rows: 0 }}>
                                                            <$Popconfirm title={t('US.COLLECTION.CASE:MANAGEAGREEMENTS.SURETODELETE')}
                                                                okText={t('US.COLLECTION.CASE:MANAGEAGREEMENTS.YES')}
                                                                cancelText={t('US.COLLECTION.CASE:MANAGEAGREEMENTS.NO')}
                                                                onConfirm={() => handleDelete(values)}
                                                                placement="right">
                                                                <$Button type="primary"
                                                                    danger
                                                                    //  onClick={() => handleDelete(values)}
                                                                    size='small' className="mr-2 px-4">
                                                                    {t('US.COLLECTION.CASE:MANAGEAGREEMENTS.DELETE')}
                                                                </$Button>
                                                            </$Popconfirm>
                                                        </$Skeleton>
                                                        : <$Skeleton loading={details.isLoading} active paragraph={{ rows: 0 }}>
                                                            <$Button type="primary"
                                                                onClick={(e: any) => handleSubmit(e)}
                                                                size='small' className="mr-2 px-4"
                                                                disabled={!rest.isValid} >
                                                                {t('US.COLLECTION.CASE:MANAGEAGREEMENTS.SAVE')}
                                                            </$Button>
                                                        </$Skeleton>
                                                    }
                                                </$FormItem>
                                            </div>
                                        </div>
                                    </div>
                                </$Affix>


                                : null}
                                <div className={isUsePartPaymentView ? '': 'mt-3 px-3'}>
                                    <$Skeleton loading={details.isLoading}>
                                        <$Row gutter={16}>
                                            <$Col xl={{ span: isUsePartPaymentView ? '24' : '13' }} xxl={{ span: isUsePartPaymentView ? '24' : '11' }}>
                                                <$Row gutter={16} className="mb-3">
                                                    <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                        <strong>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.CURRENTSTATUS')}</strong>
                                                    </$Col>
                                                    <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                    </$Col>
                                                    <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                        <strong>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.TITLE')}</strong>
                                                    </$Col>
                                                </$Row>

                                                <$Row gutter={16}>
                                                    <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                        <$FormItem >
                                                            <label >{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.FINALPAYDATE')}</label>
                                                        </$FormItem>
                                                    </$Col>
                                                    <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                    </$Col>
                                                    <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                        <$DatePicker
                                                            name='payDate'
                                                            size="small"
                                                            placeholder={currentDateFormat}
                                                            format={currentDateFormat}
                                                            style={{ width: '100%' }}
                                                            disabledDate={(d: any) => !d || d.isBefore(moment().startOf('day')) || d.isAfter(moment().add(values.maximumDurationForPA, 'days').startOf('day'))}
                                                            allowClear
                                                            disabled={values.isEdit}
                                                            value={
                                                                moment(values.payDate).isValid()
                                                                    ? moment(values.payDate)
                                                                    : moment().add(1, 'days')
                                                            } />
                                                        <div><p>5 tolerance day(s) will be added by the system</p></div>
                                                    </$Col>
                                                </$Row>

                                                <$Row gutter={16}>
                                                    <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                        <$FormItem >
                                                            <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.MAINAMOUNTBALANCE')}</label>
                                                        </$FormItem>
                                                    </$Col>
                                                    <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                        <div className="text-right pr-4">
                                                            <$FormItem >
                                                                <strong>{IntlCurrency(values.mainBalance, currentLanguage, currentCurrency)}</strong>
                                                                {/* <strong>{values.mainBalance}</strong> */}
                                                            </$FormItem>
                                                        </div>
                                                    </$Col>
                                                    <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                        <$Input
                                                            name="mainAmount"
                                                            disabled={values.isEdit}
                                                            size="small"
                                                            className="text-right fixed-form-width"
                                                            onBlur={(e: any) =>
                                                                handleInputOnBlur(e, rest.setFieldValue, values)
                                                            }
                                                            placeholder={getPlaceHolderNumber(currentLanguage)}
                                                        />
                                                    </$Col>
                                                </$Row>

                                                <$Row gutter={16}>
                                                    <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                        <$FormItem >
                                                            <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.COLLECTIONFEEBALANCE')}</label>
                                                        </$FormItem>
                                                    </$Col>
                                                    <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                        <div className="text-right pr-4">
                                                            <$FormItem >
                                                                <strong>{IntlCurrency(values.collectionBalance, currentLanguage, currentCurrency)}</strong>
                                                            </$FormItem>
                                                        </div>
                                                    </$Col>
                                                    <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                        <$Input
                                                            name="collectionFee"
                                                            disabled={values.isEdit}
                                                            size="small"
                                                            className="text-right fixed-form-width"
                                                            onBlur={(e: any) => handleInputOnBlur(e, rest.setFieldValue, values)}
                                                            placeholder={getPlaceHolderNumber(currentLanguage)}
                                                        />
                                                    </$Col>
                                                </$Row>

                                                <$Row gutter={16}>
                                                    <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                        <$FormItem >
                                                            <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.COSTFEEBALANCE')}</label>
                                                        </$FormItem>
                                                    </$Col>
                                                    <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                        <div className="text-right pr-4">
                                                            <$FormItem >
                                                                <strong>{IntlCurrency(values.costBalance, currentLanguage, currentCurrency)}</strong>
                                                            </$FormItem>
                                                        </div>
                                                    </$Col>
                                                    <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                        <$Input
                                                            name="costFee"
                                                            size="small"
                                                            disabled={values.isEdit}
                                                            className="text-right fixed-form-width"
                                                            onBlur={(e: any) => handleInputOnBlur(e, rest.setFieldValue, values)}
                                                            placeholder={getPlaceHolderNumber(currentLanguage)}
                                                        />
                                                    </$Col>
                                                </$Row>

                                                <$Row gutter={16}>
                                                    <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                        <$FormItem >
                                                            <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.COURTFEEBALANCE')}</label>
                                                        </$FormItem>
                                                    </$Col>
                                                    <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                        <div className="text-right pr-4">
                                                            <$FormItem >
                                                                <strong>{IntlCurrency(values.courtBalance, currentLanguage, currentCurrency)}</strong>
                                                            </$FormItem>
                                                        </div>
                                                    </$Col>
                                                    <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                        <$Input
                                                            name="courtFee"
                                                            disabled={values.isEdit}
                                                            size="small"
                                                            className="text-right fixed-form-width"
                                                            onBlur={(e: any) => handleInputOnBlur(e, rest.setFieldValue, values)}
                                                            placeholder={getPlaceHolderNumber(currentLanguage)}
                                                        />
                                                    </$Col>
                                                </$Row>

                                                <$Row gutter={16}>
                                                    <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                        <$FormItem >
                                                            <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.OTHERFEEBALANCE')}</label>
                                                        </$FormItem>
                                                    </$Col>
                                                    <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                        <div className="text-right pr-4">
                                                            <$FormItem >
                                                                <strong>{IntlCurrency(values.otherBalance, currentLanguage, currentCurrency)}</strong>
                                                            </$FormItem>
                                                        </div>
                                                    </$Col>
                                                    <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                        <$Input
                                                            name="otherFee"
                                                            size="small"
                                                            disabled={values.isEdit}
                                                            className="text-right fixed-form-width"
                                                            onBlur={(e: any) => handleInputOnBlur(e, rest.setFieldValue, values)}
                                                            placeholder={getPlaceHolderNumber(currentLanguage)}
                                                        />
                                                    </$Col>
                                                </$Row>

                                                <div className="gray-box mx-n3 mt-2 mb-3">
                                                    <$Row gutter={16}>
                                                        <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                            <$FormItem >
                                                                <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.CALCULATEINTERESTTILL')}</label>
                                                            </$FormItem>
                                                        </$Col>
                                                        <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                            <div className="text-right pr-4">

                                                            </div>
                                                        </$Col>
                                                        <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                            <$DatePicker
                                                                name='interestDate'
                                                                size="small"
                                                                allowClear={true}
                                                                placeholder={currentDateFormat}
                                                                format={currentDateFormat}
                                                                style={{ width: '100%' }}
                                                                disabledDate={(d: any) => !d || d.isBefore(moment().startOf('day'))}
                                                                onChange={(e: any) => handleInterest(e, rest, values)}
                                                                disabled={values.isEdit}
                                                                value={
                                                                    moment(values.interestDate).isValid()
                                                                        ? moment(values.interestDate)
                                                                        : moment()
                                                                }
                                                            />
                                                        </$Col>
                                                    </$Row>

                                                    <$Row gutter={16}>
                                                        <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                            <$FormItem >
                                                                <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.INTERESTMAINBALANCE')}</label>
                                                            </$FormItem>
                                                        </$Col>
                                                        <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                            <div className="text-right pr-4">
                                                                <$FormItem >
                                                                    <strong>{IntlCurrency(values.interestMainBalance, currentLanguage, currentCurrency)}</strong>
                                                                </$FormItem>
                                                            </div>
                                                        </$Col>
                                                        <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                            <$Input
                                                                name="interestMainAmount"
                                                                size="small"
                                                                ref={inputEl}
                                                                disabled={values.isEdit}
                                                                className="text-right fixed-form-width"
                                                                // onChange={(e:any) => {
                                                                //     rest.setFieldValue('interestMain',e.target.value);
                                                                // }}
                                                                onBlur={(e: any) => handleInputOnBlur(e, rest.setFieldValue, values)}
                                                                placeholder={getPlaceHolderNumber(currentLanguage)}
                                                            />
                                                        </$Col>
                                                    </$Row>

                                                    <$Row gutter={16}>
                                                        <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                            <$FormItem >
                                                                <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.INTERESTCOSTSBALANCE')}</label>
                                                            </$FormItem>
                                                        </$Col>
                                                        <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                            <div className="text-right pr-4">
                                                                <$FormItem >
                                                                    <strong>{IntlCurrency(values.interestCostBalance, currentLanguage, currentCurrency)}</strong>
                                                                </$FormItem>
                                                            </div>
                                                        </$Col>
                                                        <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                            <$Input
                                                                name="interestCostAmount"
                                                                size="small"
                                                                disabled={values.isEdit}
                                                                className="text-right fixed-form-width"
                                                                onBlur={(e: any) => handleInputOnBlur(e, rest.setFieldValue, values)}
                                                                placeholder={getPlaceHolderNumber(currentLanguage)}
                                                            />
                                                        </$Col>
                                                    </$Row>
                                                </div>

                                                <$Row gutter={16}>
                                                    <$Col xl={{ span: 6 }} xxl={{ span: 7 }}>
                                                        <$FormItem >
                                                            <h3><strong>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.TOTAL')}</strong></h3>
                                                        </$FormItem>
                                                    </$Col>
                                                    <$Col xl={{ span: 5 }} xxl={{ span: 6 }}>
                                                        <div className="text-right pr-4">
                                                            <$FormItem >
                                                                <h3><strong>{IntlCurrency(balance, currentLanguage, currentCurrency)}</strong></h3>
                                                            </$FormItem>
                                                        </div>
                                                    </$Col>
                                                    <$Col xl={{ span: 13 }} xxl={{ span: 11 }}>
                                                        <$FormItem name='totalAmount' >
                                                            {/* <h3 className="text-right"><strong>{total}</strong></h3> */}
                                                            <h3 className={errors.totalAmount ? 'text-right pr-2 text-error' : 'text-right pr-2'}><strong>{IntlCurrency(total, currentLanguage, currentCurrency)}</strong></h3>
                                                        </$FormItem>
                                                    </$Col>
                                                </$Row>

                                                {errors.totalAmount ? (
                                                    <$Row gutter={16} className="mb-3">
                                                        <$Col lg={{ span: 24 }} xl={{ span: 24 }}>
                                                            <$FormItem name='totalAmountaa' >

                                                                <$Alert message={errors.totalAmount} type="error" />
                                                                {/*<div style={{color: '#f50000'}}>{errors.totalAmount}</div>*/}

                                                            </$FormItem>
                                                        </$Col>
                                                    </$Row>
                                                ) : null}
                                                {!isUsePartPaymentView ? 
                                                    <$Row gutter={16}>
                                                        <$Col lg={{ span: 24 }} xl={{ span: 24 }}>
                                                            <$FormItem >
                                                                <label>{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.SMSNOTIFICATION')}</label>
                                                                <$Switch
                                                                    className="ml-2"
                                                                    // formitem='true'
                                                                    checked={values.isSendSMSNotification}
                                                                    size="small"
                                                                    disabled={values.isEdit}
                                                                    onChange={(val: any) => {
                                                                        rest.setFieldValue("isSendSMSNotification", val);
                                                                    }}
                                                                    name='isSendSMSNotification' />
                                                            </$FormItem>
                                                        </$Col>
                                                    </$Row>
                                                : null }

                                                {values.isEdit && <div className="mx-n3 mt-3 debtor-agreed">
                                                    <div className="d-flex align-items-center justify-content-between">
                                                        <div className="d-flex align-items-center">
                                                            <CheckCircleOutlined className="agreed-icon" />
                                                            <strong className="pl-2">{t('US.COLLECTION.CASE:MANAGEAGREEMENTS.DEBTORAGREED')}</strong>
                                                        </div>
                                                        <div className="text-right pr-2">
                                                            <div className="amount"><strong>{IntlCurrency(total, currentLanguage, currentCurrency)}</strong></div>
                                                            <small>on {IntlDate(values.payDate, currentLanguage, currentDateFormat)}</small>
                                                        </div>
                                                    </div>
                                                </div>}
                                            </$Col>
                                        </$Row>
                                    </$Skeleton>
                                </div>
                            </div>
                            {isUsePartPaymentView ?
                            <div className="drawer-footer-fixed align-content-center justify-content-end">
                                <div>
                                    <$Button type="primary"
                                        onClick={() => addToPartPaymentview(values, "add")}
                                        className="mr-2"
                                        // disabled={!rest.isValid} 
                                    >
                                        {t('US.COLLECTION.COMMON:COMMON.SAVE')}
                                    </$Button>
                                        <$Popconfirm
                                            title={t('US.COLLECTION.CASE:MANAGEAGREEMENTS.CANCEL_ERROR')}
                                            placement="topLeft"
                                            onConfirm={() => addToPartPaymentview(values, "cancel")}
                                            okText={t("US.COLLECTION.COMMON:COMMON.YES")}
                                            cancelText={t("US.COLLECTION.COMMON:COMMON.NO")}
                                        >
                                            <$Button>{t("US.COLLECTION.COMMON:COMMON.CANCEL")}</$Button>
                                        </$Popconfirm>
                                    
                                    {/* {!rest.dirty && (
                                        <$Button onClick={() => addToPartPaymentview(values, "cancel")}>{t("US.COLLECTION.COMMON:COMMON.CANCEL")}</$Button>
                                    )} */}
                                </div>
                            </div>
                            : null}
                        </$Form>

                    )
                }

                }

            </Formik>
        </div >
    )
}

const mapStateToProps = (state: IRootState) => {
    const { common, paymentAgreement, dashboard } = state
    const { currentLanguage, currentDateFormat, currentCurrency } = common
    const { devmetaData } = dashboard
    const { amounts, interests, details } = paymentAgreement
    return {
        currentLanguage,
        currentCurrency,
        currentDateFormat,
        interests,
        dashboardMetaData: devmetaData,
        amounts,
        details
    };
};

const mapDispatchToProps = {
    save: Action.paymentAgreement.save.save,
    deleteAgreement: Action.paymentAgreement.delete.delete,
    get: Action.paymentAgreement.amountsAndDetails.get,
    getInterest: Action.paymentAgreement.interests.get
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageAgreements)