import { ISaveOrderLines, IOrderLine, ISaveOrderLinePayload } from "../Interface";
import { getCalculatedAmounts } from "us.collection.economy/components/ManualOrders/Functions/HelperFunctions";

export function SaveOrderLine(this: ISaveOrderLines): ISaveOrderLinePayload {
    let orderLine: any = [];
    this.allTableData.map((data: IOrderLine) => {
        const {article, articleDescription, caseId, description, units, unitPrice, amount, vatAmount, totalAmount, orderlineId } = data
        orderLine.push({
            orderlineId: orderlineId,
            articleNo: article,
            articleText: articleDescription,
            caseId: caseId,
            description: description,
            numberOfItems: Number((units).toFixed(2)),
            unitPrice: Number((unitPrice).toFixed(2)),
            amount: Number((amount).toFixed(2)),
            vatAmount: Number((vatAmount).toFixed(2)),
            totalAmount: Number((totalAmount).toFixed(2)),
            sourceId: 2,
        });
    });
    return {
        orderType: this.orderType,
        orderNo: this.orderNo,
        customerNo: this.customerNo,
        netAmount: Number(getCalculatedAmounts(this.allTableData, 'amount').toFixed(2)),
        vatAmount: Number(getCalculatedAmounts(this.allTableData, 'vatAmount').toFixed(2)),
        totalAmount: Number(getCalculatedAmounts(this.allTableData, 'totalAmount').toFixed(2)),
        orderlines: orderLine,
    };
}
