import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import {
  COLUMNS,
  ProvisionProfileTableColumn,
} from "us.collection.economy/components/ProvisionProfiles/Constants";
import { MoreOutlined } from "us.icons";
import * as Actions from "us.collection.economy/actions";
import { ItemMenu } from "./Components";
import { IProvisionProfileTable, ProvisionProfileAction } from "./Interfaces";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "us.collection/interfaces";
import { ITableTreeColumns } from "us.common/components";
import { ProvisionProfile } from "us.collection.economy/interfaces";

const {
  $Button,
  $Popover,
  $AmountLabel,
  $DateLabel,
  $TableTree,
  $Skeleton,
  $Tag,
} = Components;

/**
 * @description - Provision profiles table component to show all provision profiles
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2985361423/Provision+Profiles+UI+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 23/05/2022
 */
const ProfilesTable: React.FC<PropsFromRedux & IProvisionProfileTable> = (
  props
) => {
  const { profiles, onCallAction, getProfiles } = props;
  const { t } = useTranslation();

  const [itemMenu, setItemMenu] = useState<{
    visible: boolean;
    profileId: number;
    visibleTooltip: boolean;
  }>({ visible: false, profileId: -1, visibleTooltip: false });

  // auto close invoice item menu after 5 seconds
  const setAutoClose = (profileId: number, isTooltip?: boolean) => {
    setTimeout(
      () => {
        setItemMenu({
          visible: false,
          profileId,
          visibleTooltip: false,
        });
      },
      isTooltip ? 8000 : 5000
    );
  };

  /**
   * @function
   * @description Get table columns list
   * @returns {ITableTreeColumns} - TableTree columns
   */
  const getColumns = (): ITableTreeColumns => {
    const columns: ITableTreeColumns = [];

    COLUMNS.map(({ key, title }: any) => {
      let column: any = {
        key: key,
        dataIndex: key,
        title: title && t(title),
      };
      if (key === ProvisionProfileTableColumn.MENU) {
        // configure menu column
        column = {
          ...column,
          align: "center",
          width: "40px",
          customRenderChild: (_text: any, record: any) => (
            <div className="align-items-center" key={0}>
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={
                  itemMenu.visible && itemMenu.profileId === record.profileId
                }
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    profileId: record.profileId,
                  });
                  // setAutoClose(0);
                }}
                content={
                  <ItemMenu
                    record={record}
                    onCallAction={(actionType: ProvisionProfileAction) => {
                      setItemMenu({
                        ...itemMenu,
                        visible: false,
                        profileId: record.profileId,
                      });
                      onCallAction(actionType, record);
                    }}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  data-testid="popover-btn"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            </div>
          ),
        };
      } else if (
        [
          ProvisionProfileTableColumn.MAX_MAIN_AMOUNT_PROVISION,
          ProvisionProfileTableColumn.MAXIMUM_INTEREST_PROVISION,
        ].includes(key)
      ) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          align: "right",
          customFilter: "amount",
          showFilters: true,
          width: "180px",
          customSorter: (a: any, b: any) => {
            return a - b;
          },
          customRenderChild: (_text: any, record: any) => (
            <$AmountLabel value={record[key]} />
          ),
        };
      } else if ([ProvisionProfileTableColumn.MODIFIED_DATE].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: "date",
          showFilters: true,
          width: "100px",
          customSorter: (a: any, b: any) => {
            return new Date(a).getTime() - new Date(b).getTime();
          },
          customRenderChild: (_text: any, record: any) => (
            <$DateLabel value={record[key]} />
          ),
        };
      } else if ([ProvisionProfileTableColumn.STATUS].includes(key)) {
        // configure other columns
        column = {
          ...column,
          width: "100px",
          customRenderChild: (_text: any, record: any) => (
            <>
              {record[key] ? (
                <$Tag className="tag-status-active">
                  <span>{t("COMMON.ACTIVE")}</span>
                </$Tag>
              ) : (
                <$Tag className="tag-status-deactive">
                  <span>{t("COMMON.INACTIVE")}</span>
                </$Tag>
              )}
            </>
          ),
        };
      } else {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          showFilters: true,
          width: "180px",
          customSorter: (a: any, b: any) => {
            return a.localeCompare(b);
          },
          customRenderChild: (text: any, record: any) => (
            <span>{record[key]}</span>
          ),
        };
      }
      // add configured column data object to the columns array
      columns.push(column);
    });

    return columns;
  };

  return (
    <$Skeleton
      loading={profiles.isLoading}
      active
      paragraph={{
        rows: 2,
      }}
    >
      <$TableTree
        rowKey={(record: ProvisionProfile) => record.profileId}
        data={profiles.data}
        size="small"
        filterOnType
        resetOnSourceChange
        bordered
        className="mt-3"
        showResetAllFilters
        onSort={(sortData, dataSource) => {
          return sortData(dataSource);
        }}
        onFilter={(searchData, dataSource) => {
          return searchData(dataSource);
        }}
        pagination={{
          defaultPageSize: 20,
        }}
        onRow={(record, _rowIndex) => {
          return {
            onDoubleClick: (_event) =>
              onCallAction(ProvisionProfileAction.EDIT, record),
          };
        }}
        scroll={{ x: 1300, y: "calc(100vh - 195px)" }}
        columns={getColumns()}
        data-testid="provision-profile-table"
      />
    </$Skeleton>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { provisionProfiles } = state;
  const { profiles } = provisionProfiles;

  return {
    profiles,
  };
};

const { provisionProfiles } = Actions;

const mapDispatchToProps = {
  getProfiles: provisionProfiles.profiles.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProfilesTable);
