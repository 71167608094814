import React, { useEffect } from "react";
import { useFormikContext, setNestedObjectValues } from "formik";

/**
 * @function ValidateForm initially validates formik form
 */
const ValidateForm = () => {
  const { values, dirty, validateForm, setTouched } = useFormikContext();

  useEffect(() => {
    const timer = setTimeout(()=>{
      validate();
    }, 1);
    return () => {
      clearTimeout(timer);
    };
  }, [values]);

  const validate = async () => {
    if (!dirty) {
      const errors = await validateForm();
      Object.keys(errors)?.length !== 0 &&
        setTouched(setNestedObjectValues<any>(errors, true));
    }
  };

  return null;
};

export default ValidateForm;