import { i18n } from "us.helper";

export enum CISearchType {
  REG_DATE = "REGDATE",
  DUE_DATE = "DUEDATE",
  INVOICE_NO = "INVOICE",
  EXPORTED_DATE = "EXPORTDATE",
}

export enum ClientInvoiceType {
  VAT_INVOICES = "VAT",
  CLIENT_INVOICES = "CLIENT",
}

export enum CIExportStatus {
  ALL = "ALL",
  PENDING_EXPORT = "PENDING",
  EXPORTED_OR_NAVISION = "EXPORTED",
}

export type ICISearchType =
  | CISearchType.DUE_DATE
  | CISearchType.REG_DATE
  | CISearchType.INVOICE_NO;

export type IClientInvoiceType =
  | ClientInvoiceType.CLIENT_INVOICES
  | ClientInvoiceType.VAT_INVOICES;

export type ICIExportStatus =
  | CIExportStatus.ALL
  | CIExportStatus.PENDING_EXPORT
  | CIExportStatus.EXPORTED_OR_NAVISION;

export enum InvoiceAction {
  CREDIT_NOTE = "CREDIT_NOTE",
  PRINT = "PRINT",
  DOWNLOAD = "DOWNLOAD",
  VIEW_INVOICE_DETAILS = "VIEW_INVOICE_DETAILS"
}

export enum CISortType {
  CASE_NO = "caseNo",
  INVOICE_NO = "invoiceNo",
  INVOICE_AMOUNT = "invoiceAmount",
  CUSTOMER_NO = "customerNo",
  REGISTERED_DATE = "regDate",
  DUE_DATE = "dueDate",
  EXPORTED_DATE = "exportedDate",
}

export type ICISortType =
  | CISortType.CASE_NO
  | CISortType.INVOICE_NO
  | CISortType.CUSTOMER_NO
  | CISortType.INVOICE_AMOUNT
  | CISortType.REGISTERED_DATE
  | CISortType.DUE_DATE
  | CISortType.EXPORTED_DATE;

export enum ExportedMethod {
  BOX_IMPORT = "VAT",
  EXTERNAL = "CLIENT",
}

export enum InvoiceExportStatus {
  NOT_EXPORTED = 0,
  PENDING = 1,
  EXPORTED = 2,
}

export const DEFAULT_INVOICE_SORTING_OPTIONS = [
  {
    label: "US.COLLECTION.ECONOMY:INVOICES.INVOICE_NO",
    sortType: CISortType.INVOICE_NO,
  },
  {
    label: "US.COLLECTION.ECONOMY:INVOICES.CUSTOMER_NO",
    sortType: CISortType.CUSTOMER_NO,
  },
  {
    label: "US.COLLECTION.ECONOMY:INVOICES.INVOICE_AMOUNT",
    sortType: CISortType.INVOICE_AMOUNT,
  },
];
