import { PaymentStates } from 'us.collection.economy/constants/Payments';
const { EXCEEDED, UNKNOWN, MAPPED, RETURNED, UNEXED } = PaymentStates;

export const paymentOptions = (t: any) => {
	return [
		{
			label: t('US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN_EXCEEDED'),
			value: UNEXED,
		},
		{
			label: t('US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED'),
			value: EXCEEDED,
		},
		{
			label: t('US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN'),
			value: UNKNOWN,
		},
		{
			label: t('US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED'),
			value: MAPPED,
		},
		{
			label: t('US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR'),
			value: RETURNED,
		},
		{
			label: t('US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ALL'),
			value: 'ALL',
		},
	];
};

export const periodTypeOptions = (t: any) => {
	return [
		{
			label: t('US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REG_DATE'),
			value: 'REG',
		},
		{
			label: t('US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VOUCHER_DATE_1'),
			value: 'VOU',
		},
	];
};

export const getCounts = (
  payments: Array<any>
): { mpedCount: number; exedCount: number; unkwCount: number } => {
  return {
    mpedCount: payments.filter((i: any) => i.paymentStatus == MAPPED)?.length,
    exedCount: payments.filter((i: any) => i.paymentStatus == EXCEEDED)?.length,
    unkwCount: payments.filter((i: any) => i.paymentStatus == UNKNOWN)?.length,
  };
};