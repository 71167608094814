
export enum ImportPayments {
  UPLOAD_PAYMENTS_START = "UPLOAD_PAYMENTS_START",
  UPLOAD_PAYMENTS_SUCCESS = "UPLOAD_PAYMENTS_SUCCESS",
  UPLOAD_PAYMENTS_FAIL = "UPLOAD_PAYMENTS_FAIL",

  IMPORT_PAYMENTS_START = "IMPORT_PAYMENTS_START",
  IMPORT_PAYMENTS_SUCCESS = "IMPORT_PAYMENTS_SUCCESS",
  IMPORT_PAYMENTS_FAIL = "IMPORT_PAYMENTS_FAIL",

  RESET_FILE_IMPORT = "RESET_FILE_IMPORT",

  UPDATE_FILE_LIST = "UPDATE_FILE_LIST"
}