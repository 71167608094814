import { ImportTypes } from "us.collection.economy/components/ImportPayments/Constants";
import { IUploadPayload } from "us.collection.economy/components/ImportPayments/interface";

export function PaymentImport(this: any): IUploadPayload {
  if (this.type == ImportTypes.upload) {
    return { fileType: this.fileType, file: this.fileList };
  } else {
    return { fileType: this.fileType, fileName: this.fileList[0].name };
  }
}
