import { httpCollection } from "us.helper/http/collection";

export default {
  getCreditorInvoiceDetails: (invoiceNo: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data = await httpCollection.get(
          "clientinvoiceservice",
          `clientinvoice/${invoiceNo}`,
          {}
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
