export const initialSelectedArticle = {
  articleId: 0,
  articleNo: "",
  category: "",
  categoryId: 0,
  defaultPrice: 0,
  description: "",
  nonVATAccountName: "",
  nonVATAccountNo: 0,
  revenueAccountName: "",
  revenueAccountNo: 0,
  vatCode: 0,
  vatCodeName: "",
  isInUse: false,
  isValidArticle: false,
};

export const initialDrawerValues = {
  visible: false,
  rowKey: -1,
  selectedArticle: initialSelectedArticle,
};

export const initialTableData = {
  key: 0,
  orderlineId: 0,
  article: "",
  articleDescription: "",
  vatCode: 0,
  caseNo: '',
  caseId: 0,
  description: "",
  units: 1,
  unitPrice: 0,
  amount: 0,
  vatAmount: 0,
  totalAmount: 0,
  user: "",
  isValidCaseNo: '',
  isValidArticle: "",
  isManualOrder: true,
  isVatCalculated: ''
};

export const initialSelectedCreditor = {
  address1: "",
  address2: "",
  address3: "",
  countryId: "",
  creditorId: 0,
  creditorName: "",
  groupId: 0,
  groupName: "",
  id: "",
  masterCreditorId: "",
  masterCreditorName: "",
  nin: "",
  creditorNo: "",
  zipCode: "",
  zipName: "",
  address: "",
  selectedCreditor: ''
};

export const initialState = {
  orderTableData: [initialTableData],
  InitOrderTableData: [initialTableData],
  articleList: [],
  filteredArticleList: [],
  isLoadingArticleData: false,
  articleDrawerInfo: initialDrawerValues,
  lastOrderLineInfo: {},
  isSavingOrder: false,
  validationCaseNoResult: {},
  isSaveSuccess: false,
  isExportSuccess: false,
  exportedResult: {},
  validateCreditorDetails: {
    selectedCreditor: initialSelectedCreditor,
  },
  nextOrderId: 0,
  existingOrderData: {
    isManualOrder: true,
    orderType: "CLIENT"
  },
  isOrderDetailsLoading: false,
  initialFormattedTableData: [],
  nextOrderIdLoading: false,
  validateCreditorDetailsLoading: false,
  validatingCaseNo: false,
  isVatCalculating: false
};
