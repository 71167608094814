import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import "./Details.scss";
import { SequenceDetailsProps } from "./Interfaces";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import update from "immutability-helper";
import { useLocation } from "react-router-dom";
import { CoveringSequenceAction } from "us.collection.economy/components/CoveringSequence/Constants";
import {
  getSequenceRecordById,
  getButtonTitle,
  getUpdatedListWithCorrectIndexes,
  isDirtyForm,
} from "us.collection.economy/components/CoveringSequence/Functions";
import * as Actions from "us.collection.economy/actions";
import { SequenceValidation } from "us.collection.economy/components/CoveringSequence/Validations";
import { AddCoveringSequence } from "us.collection.economy/components/CoveringSequence/Repository";

const {
  DragNDrop,
  $Form,
  $Button,
  $Input,
  $Col,
  $Row,
  $Skeleton,
  $AsyncInput,
  $Popconfirm,
} = Common.Components;

/**
 * @description - Add New Covering Sequence
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3038609419/Covering+Sequence
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 19/07/2022
 */
const CoveringSequence: React.FC<SequenceDetailsProps & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();
  const {
    coveringSequenceList,
    itemTypes,
    saveSequence,
    itemTypesLoading,
    defaultSequenceList,
    isValidCoveringSequenceName,
    onCancel,
    getItemTypes,
    addNewCoveringSequence,
    updateCoveringSequence,
    validateSequenceName,
    resetSequenceNameValidation,
  } = props;

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const sequenceId = urlParams.get("sequenceId");
  const action = urlParams.get("action") as CoveringSequenceAction;

  useEffect(() => {
    getItemTypes && getItemTypes({});
  }, []);

  useEffect(() => {
    if (
      !saveSequence?.isLoading &&
      saveSequence?.data?.hasOwnProperty("sequenceId") &&
      saveSequence?.data?.sequenceId > 0
    ) {
      onCancel();
    }
  }, [saveSequence]);

  const sequenceRecord: any = getSequenceRecordById(
    coveringSequenceList,
    sequenceId,
    defaultSequenceList
  );

  /**
   * @description useCallBack hook call for update list after drag and drop
   */
  const moveItem = useCallback(
    ({ dragIndex, hoverIndex, values, restProps }) => {
      const dragCard = values.sequenceDetails[dragIndex];
      const updatedList = update(
        getUpdatedListWithCorrectIndexes(
          values.sequenceDetails,
          hoverIndex,
          dragIndex
        ),
        {
          $splice: [
            [dragIndex, 1],
            [hoverIndex, 0, { ...dragCard, id: hoverIndex }],
          ],
        }
      );
      restProps.setFieldValue("sequenceDetails", updatedList);
    },
    []
  );

  const handleSubmit = (values: any, _actions: any) => {
    const payload = AddCoveringSequence.call(values);
    if (
      [CoveringSequenceAction.ADD, CoveringSequenceAction.COPY].includes(action)
    ) {
      addNewCoveringSequence && addNewCoveringSequence(payload);
    } else if (action === CoveringSequenceAction.EDIT) {
      updateCoveringSequence && updateCoveringSequence(payload);
    }
  };

  /**
   * @description - Handle form submit
   */
  const onClearSequenceName = () => {
    resetSequenceNameValidation && resetSequenceNameValidation({});
  };

  return (
    <Formik
      initialValues={{
        ...sequenceRecord,
        selectProps: {
          options: itemTypes,
          optionText: "description",
          optionValue: "itemTypeId",
          onSearchBy: ["description", "itemTypeId"],
        },
      }}
      enableReinitialize
      validationSchema={SequenceValidation}
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        isValid,
        dirty,
        ...restProps
      }: any) => (
        <>
          <div>
            <$Form layout="vertical" autoComplete="off">
              <$Row gutter={16} className="mb-3">
                <$Col span={12}>
                  <$AsyncInput
                    name="sequenceName"
                    label={t("US.COLLECTION.ECONOMY:COVERING_SEQUENCE.NAME")}
                    dataTestid="seq-name"
                    placeholder={t(
                      "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.NAME"
                    )}
                    size="small"
                    autoFocus={false}
                    onBlur={(e: any) => {
                      const val = e.target.value;
                      if (val !== "" && val != 0) {
                        validateSequenceName && validateSequenceName(val);
                      }
                    }}
                    isValid={isValidCoveringSequenceName}
                    onClear={onClearSequenceName}
                    asyncError={t(
                      "US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_SEQUENCE_NAME"
                    )}
                    required
                    maxLength={250}
                  />
                </$Col>
                <$Col span={12}>
                  <$Input
                    name="displayName"
                    label={t(
                      "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.DISPLAY_NAME"
                    )}
                    size="small"
                    type="text"
                    dataTestid="seq-display-name"
                    required
                  />
                </$Col>
              </$Row>
              <$Skeleton loading={itemTypesLoading} paragraph={{ rows: 2 }}>
                <DragNDrop
                  name={"sequenceDetails"}
                  isAddTypeVisible
                  title="Sequence"
                  selectPropsName={"selectProps"}
                  defaultSelectOptions={itemTypes}
                  onMove={(data: any) =>
                    moveItem({
                      ...data,
                      values,
                      restProps,
                    })
                  }
                  onResetData={() => {
                    restProps.setFieldValue(
                      "sequenceDetails",
                      sequenceRecord?.sequenceDetails
                    );
                  }}
                  mode="multiple"
                />
              </$Skeleton>
            </$Form>
            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                <$Button
                  className="ml-3 mr-2"
                  type="primary"
                  data-testid="save-btn"
                  onClick={handleSubmit}
                  loading={saveSequence.isLoading}
                  disabled={
                    !isDirtyForm(sequenceRecord, values) ||
                    !isValidCoveringSequenceName ||
                    !isValid
                  }
                >
                  {t(getButtonTitle(action))}
                </$Button>
                {isDirtyForm(sequenceRecord, values) && (
                  <$Popconfirm
                    title={t(
                      "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?"
                    )}
                    placement="topLeft"
                    onConfirm={onCancel}
                    okText={t("COMMON.YES")}
                    cancelText={t("COMMON.NO")}
                  >
                    <$Button>{t("US.COLLECTION.COMMON:COMMON.CANCEL")}</$Button>
                  </$Popconfirm>
                )}
                {!isDirtyForm(sequenceRecord, values) && (
                  <$Button onClick={onCancel}>
                    {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                  </$Button>
                )}
              </div>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { coveringSequence } = state;
  const {
    list,
    itemTypes,
    saveSequence,
    itemTypesLoading,
    defaultSequenceList,
    isValidCoveringSequenceName,
  } = coveringSequence;

  return {
    coveringSequenceList: list,
    itemTypes,
    saveSequence,
    itemTypesLoading,
    defaultSequenceList,
    isValidCoveringSequenceName,
  };
};

const {
  itemTypes,
  newCoveringSequence,
  existingCoveringSequence,
  coveringSequenceName,
} = Actions.coveringSequence;

const { get } = itemTypes;
const { save } = newCoveringSequence;
const { getByName, reset } = coveringSequenceName;

const mapDispatchToProps = {
  getItemTypes: get,
  addNewCoveringSequence: save,
  updateCoveringSequence: existingCoveringSequence.update,
  validateSequenceName: getByName,
  resetSequenceNameValidation: reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CoveringSequence);
