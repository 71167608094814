import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import {
  PlusOutlined,
  SearchOutlined,
  DeleteOutlined,
  MoreOutlined,
  CopyOutlined,
  ExclamationCircleFilled,
  LinkOutlined,
} from "us.icons";
import "./Home.scss";
import { useHistory, useLocation } from "react-router-dom";
import { IManualOrder } from "./Interface";
import { connect } from "react-redux";
import { IRootState } from "us.collection/interfaces";
import * as Azure from "us.helper/azure";
import * as Actions from "us.collection.economy/actions";
import { SelectArticle, ArticleDescription } from "./Components";
import { initialDrawerValues } from "us.collection.economy/reducers/ManualOrders/State";
import moment from "moment";
import {
  getCalculatedAmounts,
  isFormDirty,
  isAddNewOrderLineDisabled,
  filterOrderTableData,
  getCombinedData,
  isSaveButtonDisabled,
  isCreditorEligibleForVatCalculation,
  isCreditorEligibleForValidation,
  isManualOrder,
  filterOrderLineByText,
  isErrorInVatCalculation,
  isSaveAndInvoiceButtonDisabled,
  getFilterTotal,
} from "./Functions/HelperFunctions";
import { SaveOrderLine, ValidateCreditor, VatCalculation } from "./Repository";
import {
  initialOrderTableData,
  OrderTypes,
  OrderLineProperties,
} from "./Constants";
import queryString from "query-string";
import { FilterOutlined } from "us.icons";
import { ManualOrderValidationSchema } from "./Validation";
import { getRouteUrl, handleGoBack } from "us.helper";
import { useSignalRTrigger } from "us.common/SignalRProvider";

const {
  article,
  articleList,
  orderLines,
  caseNoValidation,
  invoicedOrderLines,
  orderTableData,
  creditorValidation,
  selectedCreditorData,
  nextOrderId,
  orderDetails,
  existingOrderLines,
  vatAmounts,
  tableData,
  orderLine,
  orderType,
} = Actions.manualOrders;

const {
  $Affix,
  $PageHeader,
  $InputNumber,
  $Divider,
  $Button,
  $AmountLabel,
  $DateLabel,
  $Popconfirm,
  $InputWithValue,
  $Table,
  $Form,
  $Popover,
  $InputAmount,
  $SelectCreditors,
  $Drawer,
  $Modal,
  $Select,
  $Empty,
  $Skeleton,
  $Tooltip,
  $MessageBox,
} = Common.Components;

const ManualOrder: React.FC<IManualOrder> = (props) => {
  const history = useHistory();
  const { push } = history;
  const { search } = useLocation();
  const params = queryString.parse(search);
  const { t } = useTranslation([
    "US.COLLECTION.ECONOMY",
    "US.COLLECTION.COMMON",
  ]);
  const {
    currentLanguage,
    currentCurrency,
    getArticle,
    saveOrderLines,
    changeArticleDrawerInfo,
    articleDrawerInfo,
    isSavingOrder,
    lastOrderLineInfo,
    validateCaseNumber,
    exportOrderLines,
    resetExportModalStatus,
    isExportSuccess,
    resetOrderLineData,
    orderTableData,
    updateOrderTable,
    validateCreditor,
    updateSelectedCreditor,
    selectedCreditor,
    getNextOrderId,
    nextOrderId,
    getOrderDetails,
    isOrderDetailsLoading,
    updateOrderLines,
    initialFormattedTableData,
    existingOrderData,
    nextOrderIdLoading,
    validateCreditorDetailsLoading,
    getVatAmounts,
    isLoadingArticleData,
    InitOrderTableData,
    filterTableData,
    deleteOrderLine,
    customers,
    isVatCalculating,
    updateOrderType,
  } = props;
  const [filterText, setFilterText] = useState<Array<any>>([]);
  const [filterInfo, setFilterInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: "",
    order: "",
  });

  const [openCreditors, setOpenCreditors] = useState<boolean>(false);

  const creditorInputRef = React.useRef<any>();
  const articleNoRef = React.useRef<any>();
  const saveButtonRef = React.useRef<any>();
  const newOrderLineButtonRef = React.useRef<any>();
  const inputSearchRef = React.useRef<any>();
  const inputUnitPriceRef = React.useRef<any>();

  const isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;

  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }

  const currentUser: any = auth.currentUser();
  const { setTrigger } = useSignalRTrigger();

  const focusArticleInput = () => {
    window.setTimeout(function () {
      typeof articleNoRef?.current !== "undefined" &&
        articleNoRef?.current !== null &&
        articleNoRef.current.focus();
    }, 1);
  };

  useEffect(() => {
    setTrigger([
      {
        name: "onNavisionFileGeneration",
        callBack: (_response: any) => {
            window.location.replace("/orders");
        },
      },
    ]);
  }, []);

  useEffect(() => {
    if (params?.isEdit) {
      const orderId = params?.orderId;
      getOrderDetails && getOrderDetails({ orderId });
    } else {
      resetOrderLineData();
      getNextOrderId && getNextOrderId({});
    }
  }, [params?.isEdit]);

  useEffect(() => {
    window.setTimeout(function () {
      typeof creditorInputRef?.current !== "undefined" &&
        creditorInputRef?.current !== null &&
        creditorInputRef.current.focus();
    }, 1);
  }, []);

  useEffect(() => {
    if (selectedCreditor?.creditorNo !== "" && params?.isEdit !== "true") {
      focusArticleInput();
    }
  }, [selectedCreditor]);

  const content = (record: any, restProps: any, values: any) => (
    <div className="table-more-content" data-testid="popover-content">
      {!isAddNewOrderLineDisabled(
        orderTableData,
        selectedCreditor?.creditorVATStatus
      ) &&
        values.creditor != "" && (
          <div
            className="d-flex  flex-row more-item"
            data-testid="copy-btn"
            onClick={() =>
              updateOrderTable &&
              updateOrderTable([
                {
                  ...record,
                  key:
                    InitOrderTableData[InitOrderTableData.length - 1]?.key + 1,
                  user: currentUser?.upn,
                  orderlineId: 0,
                },
              ])
            }
          >
            <div className="p-1">
              <CopyOutlined />
            </div>
            <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.COPY")}</div>
          </div>
        )}
      <$Popconfirm
        title={t("COMMON.SURE_TO_DELETE_?")}
        onConfirm={() => deleteOrderLine && deleteOrderLine(record.key)}
      >
        <div
          className="d-flex mb-2 flex-row more-item text-error"
          data-testid="delete-btn"
        >
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );

  const clearFilters = () => {
    setFilterInfo({});
    setSortedInfo({});
    setFilterText([]);
    filterTableData && filterTableData(InitOrderTableData);
  };

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilterInfo(filters);
    setSortedInfo(sorter);
  };

  const getColumnSearchProps = (
    dataIndex: string,
    title: string,
    type: string
  ) => {
    return {
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          window.setTimeout(function () {
            typeof inputSearchRef?.current !== "undefined" &&
              inputSearchRef.current.focus();
            document.querySelector("[name=columnSearchAmount]" as any)?.focus();
          }, 1);
        }
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          {type !== "amount" && (
            <$InputWithValue
              name="columnSearch"
              placeholder={`${t(
                "US.COLLECTION.COMMON:COMMON.SEARCH"
              )} ${title}`}
              value={
                filterText.length > 0
                  ? filterOrderLineByText(filterText, dataIndex)
                  : ""
              }
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                setFilterText([
                  ...filterText?.filter((n: any) => n.dataIndex !== dataIndex),
                  { dataIndex: dataIndex, text: e.target.value },
                ]);
                filterTableData &&
                  filterTableData(
                    filterOrderTableData(
                      InitOrderTableData,
                      filterText,
                      dataIndex,
                      e.target.value,
                      type
                    )
                  );
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              autoComplete="off"
              ref={inputSearchRef}
            />
          )}
          {type == "amount" && (
            <$InputAmount
              id="column-search-amount"
              min={0}
              className="w-100"
              placeholder="0,00"
              name="columnSearchAmount"
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              value={
                !filterOrderLineByText(filterText, dataIndex)
                  ? ""
                  : filterOrderLineByText(filterText, dataIndex)
              }
              onChange={(amount: any) => {
                amount = amount == 0 ? "" : amount;
                setSelectedKeys(amount ? [amount] : []);
                setFilterText([
                  ...filterText?.filter((n: any) => n.dataIndex !== dataIndex),
                  { dataIndex: dataIndex, text: amount },
                ]);
                filterTableData &&
                  filterTableData(
                    filterOrderTableData(
                      InitOrderTableData,
                      filterText,
                      dataIndex,
                      amount,
                      type
                    )
                  );
              }}
              autoComplete="off"
              dataTestid="column-search"
            />
          )}
          <$Button
            onClick={() => {
              clearFilters();
              setFilterText([
                ...filterText?.filter((n: any) => n.dataIndex !== dataIndex),
              ]);
              filterTableData &&
                filterTableData(
                  filterOrderTableData(
                    InitOrderTableData,
                    filterText,
                    dataIndex,
                    "",
                    type
                  )
                );
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.RESET")}
          </$Button>
        </div>
      ),
      filterIcon: (filtered: string) => (
        <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      render: (text: string) => <div>{text}</div>,
    };
  };

  const getHeaderTitle = (dataIndex: string, title: any, type: string) => {
    return (
      <div className="d-flex flex-column">
        <span style={{ fontWeight: "bold" }}>
          <span data-testid={dataIndex}>{title}</span>
          {filterText.length > 0 &&
            filterOrderLineByText(filterText, dataIndex) && (
              <>
                <p>
                  <span
                    className="font-sm mt-n4"
                    style={{ fontWeight: "bold" }}
                    data-testid="filter-by"
                  >
                    {t("COMMON.FILTERED_BY_")}:{" "}
                    {type !== "amount" &&
                      filterOrderLineByText(filterText, dataIndex)}
                    {type == "amount" && (
                      <$AmountLabel
                        value={filterOrderLineByText(filterText, dataIndex)}
                      />
                    )}
                  </span>
                </p>
                {type == "amount" && (
                  <p style={{ marginTop: "-10px"}}>
                    <span
                      className="font-sm"
                    >
                      {t("COMMON.TOTAL")}:{" "}
                      <$AmountLabel
                        value={getFilterTotal(orderTableData, dataIndex)}
                      />
                    </span>
                  </p>
                )}
              </>
            )}
        </span>
      </div>
    );
  };

  const calculateVatAmounts = (
    record: any,
    value: any,
    label: string,
    relevantProp: string
  ) => {
    if (
      isCreditorEligibleForVatCalculation(
        selectedCreditor,
        record,
        value,
        relevantProp
      )
    ) {
      getVatAmounts &&
        getVatAmounts(
          VatCalculation.call({
            record,
            selectedCreditor,
            relevantProp,
            value: Number(value),
            label,
          })
        );
    }
  };

  const validateCreditorDetails = (value: any, orderTableData: any) => {
    validateCreditor &&
      validateCreditor(
        ValidateCreditor.call({
          creditorId: value,
          orderTableData,
        })
      );
  };

  const getColumns: any = (restProps: any, values: any, errors: any) => {
    const columns = [
      {
        title: "",
        key: "more",
        dataIndex: "more",
        width: "50px",
        render: (text: any, record: any, index: number) => {
          return (
            <$Popover
              placement="rightTop"
              content={content(record, restProps, values)}
              destroyTooltipOnHide
            >
              {record?.isManualOrder && (
                <$Button
                  data-testid="popover-menu-btn"
                  className="mt-1"
                  icon={<MoreOutlined />}
                  size="small"
                />
              )}
            </$Popover>
          );
        },
      },
      {
        title: getHeaderTitle(
          "article",
          t("US.COLLECTION.ECONOMY:ORDERS.ARTICLE"),
          "number"
        ),
        key: "article",
        dataIndex: "article",
        sorter: (a: any, b: any) => a?.article - b?.article,
        sortOrder: sortedInfo.columnKey === "article" && sortedInfo.order,
        ...getColumnSearchProps(
          "article",
          t("US.COLLECTION.ECONOMY:ORDERS.ARTICLE"),
          "number"
        ),
        filteredValue: filterInfo.article || null,
        Width: "300px",
        ellipsis: {
          showTitle: false,
        },
        render: (text: any, record: any, index: number) => {
          return (
            <div className="d-flex flex-column">
              <div
                className="d-flex align-items-center"
                data-testid="article-no"
              >
                <$InputWithValue
                  name={`orderTableData[${index}].article`}
                  allowClear
                  style={{
                    width: 80,
                    borderColor:
                      orderTableData[index]?.isValidArticle === false
                        ? "red"
                        : "",
                  }}
                  size="small"
                  disabled={!record?.isManualOrder}
                  ref={articleNoRef}
                  value={record.article}
                  tabIndex={2 + index * 5}
                  onChange={(e: any) => {
                    updateOrderTable &&
                      updateOrderTable({
                        key: record.key,
                        value: e.target.value,
                        type: OrderLineProperties.ARTICLE,
                        isVatCalculated: false,
                        articleValidation: "",
                      });
                  }}
                  onBlur={(val: any) => {
                    if (
                      val.target.value != "" &&
                      record?.isVatCalculated === false
                    ) {
                      const { creditorNo, creditorVATStatus } =
                        selectedCreditor;
                      getArticle &&
                        getArticle({
                          articleNo: val.target.value,
                          rowKey: record.key,
                          creditorVATStatus,
                          creditorNo,
                          units: record.units,
                          user: currentUser?.upn,
                        });
                    } else {
                      updateOrderTable &&
                        updateOrderTable({
                          key: record.key,
                          value: record.article,
                          type: OrderLineProperties.ARTICLE,
                          isVatCalculated: true,
                          articleValidation: true,
                        });
                    }
                  }}
                />
                <div>
                  <$Button
                    onClick={(e: any) =>
                      changeArticleDrawerInfo &&
                      changeArticleDrawerInfo({
                        ...initialDrawerValues,
                        visible: true,
                        rowKey: record.key,
                        selectedArticle: {
                          ...articleDrawerInfo.selectedArticle,
                          articleNo: record.article,
                          description: record.articleDescription,
                        },
                      })
                    }
                    className="m-0 ml-1"
                    size="small"
                    icon={<SearchOutlined />}
                    disabled={!record?.isManualOrder}
                  />
                </div>
              </div>
              <$Skeleton
                loading={
                  isLoadingArticleData && articleDrawerInfo.rowKey == record.key
                }
                active
                paragraph={{ rows: 0 }}
              >
                {orderTableData[index]?.isValidArticle === true && (
                  <$Tooltip placement="top" title={record.articleDescription}>
                    <span
                      className="text-truncate"
                      data-testid="articleDescription"
                    >
                      {record.articleDescription}
                    </span>
                  </$Tooltip>
                )}
              </$Skeleton>
              {orderTableData[index]?.isValidArticle === false && (
                <div style={{ color: "red" }}>
                  {t("US.COLLECTION.ECONOMY:ORDERS.INVALID_ARTICLE_NO")}
                </div>
              )}
            </div>
          );
        },
      },
      {
        title: getHeaderTitle(
          "caseNo",
          t("US.COLLECTION.ECONOMY:ORDERS.CASE_NO"),
          "number"
        ),
        key: "caseNo",
        dataIndex: "caseNo",
        sorter: (a: any, b: any) => a.caseNo - b.caseNo,
        sortOrder: sortedInfo.columnKey === "caseNo" && sortedInfo.order,
        ...getColumnSearchProps(
          "caseNo",
          t("US.COLLECTION.ECONOMY:ORDERS.CASE_NO"),
          "number"
        ),
        filteredValue: filterInfo.caseNo || null,
        render: (text: any, record: any, index: number) => {
          return (
            <div data-testid="case-no">
              {record?.isManualOrder && (
                <div className="d-flex">
                  <$InputWithValue
                    name={`orderTableData[${index}].caseNo`}
                    size="small"
                    style={{
                      width: 100,
                      borderColor:
                        orderTableData[index]?.caseNo !== "" &&
                        orderTableData[index]?.isValidCaseNo === false
                          ? "red"
                          : "",
                    }}
                    value={record.caseNo}
                    disabled={
                      values.creditor == "" || record.isValidArticle === false
                    }
                    tabIndex={3 + index * 5}
                    onChange={(e: any) => {
                      updateOrderTable &&
                        updateOrderTable({
                          key: record.key,
                          value: e.target.value,
                          type: OrderLineProperties.CASE_NO,
                          user: currentUser?.upn,
                        });
                    }}
                    onKeyDown={(e: any) => {
                      if (e.keyCode == 13 || e.keyCode == 9) {
                        const val = e.target.value;
                        const creditorId = selectedCreditor.creditorNo;
                        if (val !== "" && val != 0) {
                          validateCaseNumber &&
                            validateCaseNumber({
                              val,
                              creditorId,
                              rowKey: record.key,
                            });
                        }
                      }
                    }}
                    onBlur={(e: any) => {
                      const val = e.target.value;
                      const creditorId = selectedCreditor.creditorNo;
                      if (val !== "" && val != 0) {
                        validateCaseNumber &&
                          validateCaseNumber({
                            val,
                            creditorId,
                            rowKey: record.key,
                          });
                      }
                    }}
                  />
                  {orderTableData[index]?.caseNo !== "" &&
                    orderTableData[index]?.isValidCaseNo === true && (
                      <$Button
                        type="link"
                        size="small"
                        className="ml-1 mt-1"
                        icon={<LinkOutlined />}
                        onClick={() =>
                          getRouteUrl.caseType(orderTableData[index]?.caseNo)
                        }
                      />
                    )}
                </div>
              )}
              {!record?.isManualOrder && (
                <a
                  onClick={() =>
                    getRouteUrl.caseType(orderTableData[index]?.caseNo)
                  }
                >
                  {orderTableData[index]?.caseNo}
                </a>
              )}
              {orderTableData[index]?.caseNo !== "" &&
                orderTableData[index]?.isValidCaseNo === false && (
                  <div style={{ color: "red" }}>
                    {t("US.COLLECTION.ECONOMY:ORDERS.INVALID_CASE_NO")}
                  </div>
                )}
            </div>
          );
        },
      },
      {
        title: getHeaderTitle(
          "description",
          t("US.COLLECTION.ECONOMY:ORDERS.DESCRIPTION"),
          "string"
        ),
        key: "description",
        dataIndex: "description",
        sorter: (a: any, b: any) => a.description.localeCompare(b.description),
        sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
        ...getColumnSearchProps(
          "description",
          t("US.COLLECTION.ECONOMY:ORDERS.DESCRIPTION"),
          "string"
        ),
        filteredValue: filterInfo.description || null,
        render: (text: any, record: any, index: number) => {
          return (
            <ArticleDescription
              name={`orderTableData[${index}].description`}
              tabIndex={4 + index * 5}
              orderline={record}
              currentUser={currentUser}
            />
          );
        },
      },
      {
        title: getHeaderTitle(
          "units",
          t("US.COLLECTION.ECONOMY:ORDERS.NO_OF_UNITS"),
          "number"
        ),
        key: "units",
        dataIndex: "units",
        sorter: (a: any, b: any) => a.units - b.units,
        sortOrder: sortedInfo.columnKey === "units" && sortedInfo.order,
        ...getColumnSearchProps(
          "units",
          t("US.COLLECTION.ECONOMY:ORDERS.NO_OF_UNITS"),
          "number"
        ),
        filteredValue: filterInfo.units || null,
        render: (text: any, record: any, index: number) => {
          return (
            <div>
              <$InputNumber
                name={`orderTableData[${index}].units`}
                size="small"
                style={{ width: 80 }}
                tabIndex={5 + index * 5}
                value={record.units}
                disabled={
                  (record.caseNo != "" && !record.isValidCaseNo) ||
                  !record?.isManualOrder ||
                  !record.isValidArticle === true
                }
                onChange={(units: any) => {
                  updateOrderTable &&
                    updateOrderTable({
                      key: record.key,
                      value: Number(units),
                      type: OrderLineProperties.UNITS,
                      isVatCalculated: !isCreditorEligibleForVatCalculation(
                        selectedCreditor,
                        record,
                        units,
                        OrderLineProperties.UNIT_PRICE
                      ),
                      user: currentUser?.upn,
                    });
                }}
                onBlur={(e: any) => {
                  if (record?.isVatCalculated === false) {
                    calculateVatAmounts(
                      record,
                      e.target.value,
                      OrderLineProperties.UNITS,
                      OrderLineProperties.UNIT_PRICE
                    );
                  }
                }}
                onKeyDown={(e: any) => {
                  if (
                    record?.isVatCalculated === false &&
                    (e.keyCode == 13 || e.keyCode == 9)
                  ) {
                    calculateVatAmounts(
                      record,
                      e.target.value,
                      OrderLineProperties.UNITS,
                      OrderLineProperties.UNIT_PRICE
                    );
                  }
                }}
              />
            </div>
          );
        },
      },
      {
        title: getHeaderTitle(
          "unitPrice",
          t("US.COLLECTION.ECONOMY:ORDERS.UNIT_PRICE"),
          "amount"
        ),
        key: "unitPrice",
        dataIndex: "unitPrice",
        sorter: (a: any, b: any) => a.unitPrice - b.unitPrice,
        sortOrder: sortedInfo.columnKey === "unitPrice" && sortedInfo.order,
        ...getColumnSearchProps(
          "unitPrice",
          t("US.COLLECTION.ECONOMY:ORDERS.UNIT_PRICE"),
          "amount"
        ),
        filteredValue: filterInfo.unitPrice || null,
        align: "right",
        render: (text: any, record: any, index: number) => {
          return (
            <$InputAmount
              name={`orderTableData[${index}].unitPrice`}
              size="small"
              ref={inputUnitPriceRef}
              placeholder="0,00"
              tabIndex={6 + index * 5}
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              disabled={
                (record.caseNo != "" && !record.isValidCaseNo) ||
                !record?.isManualOrder ||
                !record.isValidArticle === true ||
                record.unitPrice < 0
              }
              className="bui-number-input"
              min={record?.unitPriceMinValue}
              value={record.unitPrice}
              onChange={(price: any) => {
                updateOrderTable &&
                  updateOrderTable({
                    key: record.key,
                    value: price,
                    type: OrderLineProperties.UNIT_PRICE,
                    isVatCalculated: !isCreditorEligibleForVatCalculation(
                      selectedCreditor,
                      record,
                      price,
                      OrderLineProperties.UNITS
                    ),
                    user: currentUser?.upn,
                  });
              }}
              onBlur={(e: any) => {
                if (!record.isVatCalculated) {
                  calculateVatAmounts(
                    record,
                    record.unitPrice,
                    OrderLineProperties.UNIT_PRICE,
                    OrderLineProperties.UNITS
                  );
                }
                window.setTimeout(function () {
                  typeof newOrderLineButtonRef?.current !== "undefined" &&
                    newOrderLineButtonRef?.current !== null &&
                    newOrderLineButtonRef.current.focus();
                }, 1);
              }}
              onKeyPress={(e: any) => {
                if (
                  !record.isVatCalculated &&
                  (e.charCode == 13 || e.charCode == 9)
                ) {
                  calculateVatAmounts(
                    record,
                    record.unitPrice,
                    OrderLineProperties.UNIT_PRICE,
                    OrderLineProperties.UNITS
                  );
                  window.setTimeout(function () {
                    typeof newOrderLineButtonRef?.current !== "undefined" &&
                      newOrderLineButtonRef?.current !== null &&
                      newOrderLineButtonRef.current.focus();
                  }, 1);
                }
              }}
            />
          );
        },
      },
      {
        title: getHeaderTitle(
          "amount",
          t("US.COLLECTION.ECONOMY:ORDERS.AMOUNT"),
          "amount"
        ),
        key: "amount",
        dataIndex: "amount",
        sorter: (a: any, b: any) => a.amount - b.amount,
        sortOrder: sortedInfo.columnKey === "amount" && sortedInfo.order,
        ...getColumnSearchProps(
          "amount",
          t("US.COLLECTION.ECONOMY:ORDERS.AMOUNT"),
          "amount"
        ),
        filteredValue: filterInfo.amount || null,
        align: "right",
        render: (text: any, record: any, index: number) => {
          return <$AmountLabel value={record?.amount} />;
        },
      },
      {
        title: getHeaderTitle(
          "vatAmount",
          t("US.COLLECTION.ECONOMY:ORDERS.VAT_AMOUNT"),
          "amount"
        ),
        key: "vatAmount",
        dataIndex: "vatAmount",
        sorter: (a: any, b: any) => a.vatAmount - b.vatAmount,
        sortOrder: sortedInfo.columnKey === "vatAmount" && sortedInfo.order,
        ...getColumnSearchProps(
          "vatAmount",
          t("US.COLLECTION.ECONOMY:ORDERS.VAT_AMOUNT"),
          "amount"
        ),
        width: "150px",
        filteredValue: filterInfo.vatAmount || null,
        align: "right",
        render: (text: any, record: any, index: number) => {
          return (
            <div className="d-flex justify-content-end">
              <$Skeleton
                loading={isVatCalculating && record.isVatCalculated === false}
                active
                title={{ width: "100%" }}
                paragraph={{ rows: 0 }}
              >
                {isErrorInVatCalculation(
                  record,
                  selectedCreditor?.creditorVATStatus
                ) && (
                  <div className="mr-2">
                    <$Popover
                      content={
                        <$Button
                          size="small"
                          block
                          onClick={() =>
                            calculateVatAmounts(
                              record,
                              record.unitPrice,
                              OrderLineProperties.UNIT_PRICE,
                              OrderLineProperties.UNITS
                            )
                          }
                        >
                          {t("US.COLLECTION.ECONOMY:ORDERS.RE_CALCULATE")}
                        </$Button>
                      }
                      title={
                        <div className="text-error">
                          <ExclamationCircleFilled className="text-error mr-2" />{" "}
                          {t(
                            "US.COLLECTION.ECONOMY:ORDERS.VAT_CALCULATION_ERROR"
                          )}
                        </div>
                      }
                    >
                      <ExclamationCircleFilled className="text-error" />
                    </$Popover>
                  </div>
                )}
                <div>
                  <span
                    className={
                      isErrorInVatCalculation(
                        record,
                        selectedCreditor?.creditorVATStatus
                      )
                        ? "text-error"
                        : ""
                    }
                  >
                    <$AmountLabel value={record.vatAmount} />
                  </span>
                </div>
              </$Skeleton>
            </div>
          );
        },
      },
      {
        title: getHeaderTitle(
          "totalAmount",
          t("US.COLLECTION.ECONOMY:ORDERS.TOTAL_AMOUNT"),
          "amount"
        ),
        key: "totalAmount",
        dataIndex: "totalAmount",
        sorter: (a: any, b: any) => a.totalAmount - b.totalAmount,
        sortOrder: sortedInfo.columnKey === "totalAmount" && sortedInfo.order,
        ...getColumnSearchProps(
          "totalAmount",
          t("US.COLLECTION.ECONOMY:ORDERS.TOTAL_AMOUNT"),
          "amount"
        ),
        filteredValue: filterInfo.totalAmount || null,
        align: "right",
        render: (text: any, record: any, index: number) => {
          return <$AmountLabel value={record?.totalAmount} />;
        },
      },
      {
        title: getHeaderTitle(
          "user",
          t("US.COLLECTION.ECONOMY:ORDERS.USER"),
          "string"
        ),
        key: "user",
        dataIndex: "user",
        sorter: (a: any, b: any) => a.user.localeCompare(b.user),
        sortOrder: sortedInfo.columnKey === "user" && sortedInfo.order,
        ...getColumnSearchProps(
          "user",
          t("US.COLLECTION.ECONOMY:ORDERS.USER"),
          "string"
        ),
        filteredValue: filterInfo.user || null,
        ellipsis: {
          showTitle: false,
        },
        render: (text: any, record: any, index: number) => {
          return (
            <$Tooltip
              placement="top"
              title={record?.user != "" ? record.user : currentUser?.upn}
            >
              {record?.user != "" ? record.user : currentUser?.upn}
            </$Tooltip>
          );
        },
      },
    ];
    return columns;
  };

  const onSelectCreditors = (creditorValues: any) => {
    const { customers, customerGroups, customerData } = creditorValues;
    setOpenCreditors(false);
    const { pid } = creditorValues.customerData[0];

    updateSelectedCreditor &&
      updateSelectedCreditor({
        selectedCreditor: {
          ...creditorValues.customerData[0],
          address: getCombinedData(creditorValues.customerData[0], "address"),
          creditorNo: pid,
        },
        customers,
        customerGroups,
        customerData,
      });
    validateCreditorDetails(pid, orderTableData[0]);
  };

  const onClose = () => {
    changeArticleDrawerInfo &&
      changeArticleDrawerInfo({
        ...initialDrawerValues,
        selectedArticle: articleDrawerInfo.selectedArticle,
      });
  };

  const validateCreditorNo = (value: any, restProps: any) => {
    if (Number(value) < 2) {
      restProps.setFieldValue("creditorNo", "");
      resetOrderLineData();
      value !== "" &&
        $MessageBox(
          "error",
          "US.COLLECTION.VALIDATIONS:INVALID.INVALID_CREDITOR_NO",
          "",
          ""
        );
    }
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={ManualOrderValidationSchema}
      initialValues={{
        visibleDescriptionId: "",
        creditorNo: selectedCreditor?.creditorNo,
        customers: customers,
        orderTableData: [...orderTableData],
        orderType:
          existingOrderData.orderType == "CLIENT"
            ? OrderTypes.CLIENT_INVOICE_TYPE
            : OrderTypes.VAT_INVOICE_TYPE,
        saveAndInvoice: false,
      }}
      onSubmit={(values: any, actions: any) => {
        actions.setSubmitting(true);
        const payload = SaveOrderLine.call({
          allTableData: orderTableData,
          customerNo: selectedCreditor?.creditorNo,
          orderNo: params?.isEdit ? nextOrderId : 0,
          orderType: values?.orderType,
        });
        if (params?.isEdit) {
          updateOrderLines &&
            updateOrderLines({
              payload,
              saveAndInvoice: values.saveAndInvoice,
            });
        } else {
          saveOrderLines &&
            saveOrderLines({
              payload,
              saveAndInvoice: values.saveAndInvoice,
            });
        }
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        errors,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={
                      <div>
                        {nextOrderIdLoading && (
                          <$Skeleton.Input
                            style={{ width: 50 }}
                            active={true}
                            size={"small"}
                          />
                        )}
                        {!nextOrderIdLoading && (
                          <span>
                            {t("US.COLLECTION.ECONOMY:ORDERS.ORDER_")}-{" "}
                            {nextOrderId}
                          </span>
                        )}
                      </div>
                    }
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <$Button
                    className="mr-2"
                    type="primary"
                    size="small"
                    data-testid="saveBtn"
                    ref={saveButtonRef}
                    onClick={(e: any) => {
                      restProps.setFieldValue("saveAndInvoice", false);
                      handleSubmit(e);
                    }}
                    loading={!values.saveAndInvoice && isSavingOrder}
                    disabled={isSaveButtonDisabled(
                      orderTableData,
                      params?.isEdit
                        ? initialFormattedTableData
                        : initialOrderTableData,
                      values.creditor,
                      articleDrawerInfo?.selectedArticle?.isValidArticle,
                      selectedCreditor?.creditorVATStatus
                    )}
                  >
                    {t("US.COMMON:COMMON.SAVE")}
                  </$Button>
                  <$Button
                    type="default"
                    size="small"
                    data-testid="saveAndInvoiceBtn"
                    onClick={(e: any) => {
                      restProps.setFieldValue("saveAndInvoice", true);
                      handleSubmit(e);
                    }}
                    loading={values.saveAndInvoice && isSavingOrder}
                    disabled={isSaveAndInvoiceButtonDisabled(
                      orderTableData,
                      params?.isEdit
                        ? initialFormattedTableData
                        : initialOrderTableData,
                      values.creditor,
                      articleDrawerInfo?.selectedArticle?.isValidArticle,
                      params?.isEdit,
                      existingOrderData?.invoicingStatus,
                      selectedCreditor?.creditorVATStatus
                    )}
                  >
                    {t("US.COLLECTION.ECONOMY:ORDERS.SAVE_&_INVOICE")}
                  </$Button>

                  <$Popconfirm
                    title={t(
                      "US.COLLECTION.ECONOMY:ORDERS.ARE_YOU_SURE_YOU_WANT_TO_RESET_THE_ORDER_LINES_?"
                    )}
                    placement="topLeft"
                    disabled={
                      isFormDirty(
                        orderTableData,
                        params?.isEdit
                          ? initialFormattedTableData
                          : initialOrderTableData
                      ) ||
                      isAddNewOrderLineDisabled(
                        orderTableData,
                        selectedCreditor?.creditorVATStatus
                      )
                    }
                    data-testid="reset-popUp"
                    onConfirm={() => {
                      if (params?.isEdit) {
                        const orderId = params?.orderId;
                        getOrderDetails && getOrderDetails({ orderId });
                      } else {
                        resetOrderLineData();
                      }
                    }}
                    okText={t("COMMON.YES")}
                    cancelText={t("COMMON.NO")}
                  >
                    <$Button
                      className="ml-2"
                      type="default"
                      size="small"
                      data-testid="reset-button"
                      disabled={
                        isFormDirty(
                          orderTableData,
                          params?.isEdit
                            ? initialFormattedTableData
                            : initialOrderTableData
                        ) ||
                        isAddNewOrderLineDisabled(
                          orderTableData,
                          selectedCreditor?.creditorVATStatus
                        )
                      }
                    >
                      {t("US.COLLECTION.COMMON:COMMON.RESET")}
                    </$Button>
                  </$Popconfirm>
                  <div>
                    <$Button
                      size="small"
                      onClick={clearFilters}
                      className="ml-2"
                      disabled={
                        filterText.length == 0 &&
                        (sortedInfo.order == "" ||
                          sortedInfo.order == undefined)
                      }
                      data-testid="reset-all-btn"
                    >
                      {t("US.COLLECTION.COMMON:COMMON.RESET_ALL")}
                    </$Button>
                  </div>
                </div>
              </div>
            </$Affix>
            <$Form layout="vertical" autoComplete="off">
              <div className="d-flex justify-content-between align-items-center pt-3">
                <div className="d-flex align-items-center">
                  <div className="mr-2">
                    <$Select
                      name={`orderType`}
                      size="small"
                      defaultValue={OrderTypes.CLIENT_INVOICE_TYPE}
                      formitem={{
                        label: t("US.COLLECTION.ECONOMY:ORDERS.SELECT_TYPE"),
                      }}
                      disabled={
                        isManualOrder(orderTableData) ||
                        params?.isEdit === "true"
                      }
                      optionText="label"
                      allOption={false}
                      onChange={(val: string) => updateOrderType(val)}
                      options={[
                        {
                          label: t("US.COLLECTION.ECONOMY:ORDERS.C_-_TYPE"),
                          value: OrderTypes.CLIENT_INVOICE_TYPE,
                        },
                        {
                          label: t("US.COLLECTION.ECONOMY:ORDERS.V_-_TYPE"),
                          value: OrderTypes.VAT_INVOICE_TYPE,
                        },
                      ]}
                      placeholder={t(
                        "US.COLLECTION.ECONOMY:ORDERS.SELECT_TYPE"
                      )}
                    />
                  </div>
                  <div className="mr-2 d-flex flex-column">
                    <$Button
                      type="link"
                      className="m-0 p-0 text-left font-weight-bold"
                      size="small"
                      data-testid="creditor-selection"
                      onClick={() =>
                        setOpenCreditors(orderTableData.length == 1)
                      }
                      disabled={
                        orderTableData.length != 1 || params?.isEdit === "true"
                      }
                    >
                      {t("US.COLLECTION.ECONOMY:ORDERS.CREDITOR")}
                    </$Button>
                    <$SelectCreditors
                      name="customers"
                      groupName="customerGroups"
                      multiple={false}
                      defaultAllSelectionFilter={
                        selectedCreditor?.creditorNo === ""
                      }
                      disabledKeys={[1]}
                      visible={openCreditors}
                      onClose={() => setOpenCreditors(false)}
                      onConfirm={(
                        customers: Array<number>,
                        customerGroups: Array<number>,
                        customerData: Array<number>
                      ) => {
                        setOpenCreditors(false);
                        onSelectCreditors({
                          ...values,
                          customers,
                          customerGroups,
                          customerData,
                          restProps,
                        });
                      }}
                    />
                    <$InputWithValue
                      size="small"
                      name="creditorNo"
                      ref={creditorInputRef}
                      value={values.creditorNo}
                      tabIndex={1}
                      disabled={
                        orderTableData.length > 1 || params?.isEdit === "true"
                      }
                      style={{ width: 120 }}
                      onBlur={(e: any) => {
                        validateCreditorNo(e.target.value, restProps);
                        if (
                          isCreditorEligibleForValidation(
                            e.target.value,
                            selectedCreditor
                          )
                        ) {
                          validateCreditorDetails(
                            e.target.value,
                            orderTableData[0]
                          );
                        }
                      }}
                      onKeyDown={(e: any) => {
                        (e.keyCode == 13 || e.keyCode == 9) &&
                          validateCreditorNo(e.target.value, restProps);
                        if (
                          (e.keyCode == 13 || e.keyCode == 9) &&
                          isCreditorEligibleForValidation(
                            e.target.value,
                            selectedCreditor
                          )
                        ) {
                          validateCreditorDetails(
                            e.target.value,
                            orderTableData[0]
                          );

                          window.setTimeout(function () {
                            typeof articleNoRef?.current !== "undefined" &&
                              articleNoRef?.current !== null &&
                              articleNoRef.current.focus();
                          }, 1);
                        }
                      }}
                    />
                  </div>
                  {validateCreditorDetailsLoading && (
                    <$Skeleton.Input
                      style={{ width: 250 }}
                      active={true}
                      size={"small"}
                      className="pt-3"
                    />
                  )}
                  {!validateCreditorDetailsLoading && (
                    <div>
                      <div
                        className="font-weight-bold"
                        data-testid="creditor-name"
                      >
                        {selectedCreditor?.creditorName}
                      </div>
                      <div
                        className="text-muted"
                        data-testid="creditor-address"
                      >
                        {selectedCreditor?.address}
                      </div>
                    </div>
                  )}
                </div>

                <div className="order-stat d-flex p-2">
                  <div className="mr-3 order-seperate pr-3">
                    <div className="font-weight-bold" data-testid="date">
                      <$DateLabel
                        value={
                          existingOrderData?.orderDate
                            ? moment(existingOrderData?.orderDate)
                            : moment()
                        }
                      />
                    </div>
                    <div>{t("US.COLLECTION.ECONOMY:ORDERS.DATE")}</div>
                  </div>
                  <div className="mr-3 order-seperate pr-3">
                    <div className="font-weight-bold" data-testid="netAmount">
                      <$AmountLabel
                        value={getCalculatedAmounts(
                          InitOrderTableData,
                          "amount"
                        )}
                      />
                    </div>
                    <div>{t("US.COLLECTION.ECONOMY:ORDERS.NET_AMOUNT")}</div>
                  </div>
                  <div className="mr-3 order-seperate pr-3">
                    <div className="font-weight-bold" data-testid="vatAmount">
                      <$AmountLabel
                        value={getCalculatedAmounts(
                          InitOrderTableData,
                          "vatAmount"
                        )}
                      />
                    </div>
                    <div>{t("US.COLLECTION.ECONOMY:ORDERS.VAT_AMOUNT")}</div>
                  </div>
                  <div>
                    <div className="font-weight-bold" data-testid="totalAmount">
                      <$AmountLabel
                        value={getCalculatedAmounts(
                          InitOrderTableData,
                          "totalAmount"
                        )}
                      />
                    </div>
                    <div>{t("US.COLLECTION.ECONOMY:ORDERS.TOTAL")}</div>
                  </div>
                </div>
              </div>
            </$Form>
            <div className="order-table">
              <$Skeleton
                loading={isOrderDetailsLoading}
                active
                paragraph={{ rows: 2 }}
              >
                {values.creditor != "" && selectedCreditor.creditorNo !== "" && (
                  <$Table
                    rowKey={(record: any) => {
                      return record.key;
                    }}
                    columns={getColumns(restProps, values, errors)}
                    dataSource={orderTableData.sort(
                      (a: any, b: any) => a.key - b.key
                    )}
                    bordered
                    className="mt-3 order-table"
                    onChange={handleTableChange}
                    footer={() => (
                      <>
                        <$Button
                          type="link"
                          icon={<PlusOutlined />}
                          data-testid="add-new-order-line"
                          ref={newOrderLineButtonRef}
                          disabled={
                            isAddNewOrderLineDisabled(
                              orderTableData,
                              selectedCreditor?.creditorVATStatus
                            ) ||
                            values.creditor == "" ||
                            isVatCalculating
                          }
                          onClick={() => {
                            updateOrderTable &&
                              updateOrderTable([
                                {
                                  ...initialOrderTableData,
                                  key:
                                    InitOrderTableData.length > 0
                                      ? InitOrderTableData[
                                          InitOrderTableData.length - 1
                                        ]?.key + 1
                                      : 0,
                                  user: currentUser?.upn,
                                },
                              ]);
                            focusArticleInput();
                          }}
                        >
                          {t("US.COLLECTION.ECONOMY:ORDERS.NEW_ORDER_LINE")}
                        </$Button>
                      </>
                    )}
                  />
                )}
              </$Skeleton>
            </div>
            <$Modal
              visible={lastOrderLineInfo?.saveAndInvoice}
              title={t("US.COLLECTION.ECONOMY:ORDERS.CONFIRMATION")}
              closable={false}
              footer={[
                <$Button
                  type="primary"
                  loading={isExportSuccess}
                  onClick={() => {
                    exportOrderLines &&
                      exportOrderLines([
                        {
                          invoiceNo: lastOrderLineInfo?.invoiceNo,
                          invoiceId: lastOrderLineInfo?.invoiceId,
                          isCreditInvoice: true,
                          exportType: lastOrderLineInfo?.exportType
                        },
                      ]);
                  }}
                >
                  {t("US.COLLECTION.ECONOMY:ORDERS.EXPORT_INVOICE")}
                </$Button>,
                <$Button
                  type="primary"
                  onClick={() => {
                    resetExportModalStatus(false);
                    push({ pathname: `/orders` });
                  }}
                >
                  {t("US.COLLECTION.COMMON:COMMON.NO")}
                </$Button>,
              ]}
            >
              <div>
                <p>
                  {t(
                    "US.COLLECTION.ECONOMY:ORDERS.DO_YOU_WANT_TO_EXPORT_ORDER_?"
                  )}
                </p>
                <div>
                  {lastOrderLineInfo?.invoiceNo != undefined && (
                    <span>
                      {t("US.COLLECTION.ECONOMY:ORDERS.LAST_INVOICE_NO")} :{" "}
                      {lastOrderLineInfo?.invoiceNo}
                    </span>
                  )}
                </div>
              </div>
            </$Modal>
            {!isOrderDetailsLoading &&
              values.creditor == "" &&
              selectedCreditor.creditorNo === "" && (
                <div className="flex-fill pt-5" data-testid="empty-normal">
                  <$Empty
                    image={$Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t("US.COLLECTION.ECONOMY:ORDERS.NO_DATA")}
                  />
                </div>
              )}
            <$Drawer
              title={t("US.COLLECTION.ECONOMY:ORDERS.SELECT_ARTICLE")}
              width={"750"}
              visible={articleDrawerInfo.visible}
              onClose={onClose}
              destroyOnClose
            >
              <SelectArticle />
            </$Drawer>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, manualOrders } = state;
  const { currentLanguage, currentCurrency, currentDateFormat } = common;
  const {
    articleList,
    articleDrawerInfo,
    isSavingOrder,
    lastOrderLineInfo,
    isExportSuccess,
    orderTableData,
    validateCreditorDetails,
    nextOrderId,
    isOrderDetailsLoading,
    initialFormattedTableData,
    existingOrderData,
    nextOrderIdLoading,
    validateCreditorDetailsLoading,
    isLoadingArticleData,
    InitOrderTableData,
    validatingCaseNo,
    isVatCalculating,
  } = manualOrders;
  const { selectedCreditor, customers } = validateCreditorDetails;

  return {
    currentLanguage,
    currentCurrency,
    currentDateFormat,
    articleList,
    articleDrawerInfo,
    isSavingOrder,
    lastOrderLineInfo,
    isExportSuccess,
    orderTableData,
    selectedCreditor,
    nextOrderId,
    isOrderDetailsLoading,
    initialFormattedTableData,
    existingOrderData,
    nextOrderIdLoading,
    validateCreditorDetailsLoading,
    isLoadingArticleData,
    InitOrderTableData,
    validatingCaseNo,
    customers,
    isVatCalculating,
  };
};

const mapDispatchToProps = {
  getArticle: article.get,
  changeArticleDrawerInfo: articleList.openDrawer,
  saveOrderLines: orderLines.save,
  validateCaseNumber: caseNoValidation.get,
  exportOrderLines: invoicedOrderLines.save,
  resetExportModalStatus: orderLines.reset,
  resetOrderLineData: invoicedOrderLines.reset,
  updateOrderTable: orderTableData.update,
  validateCreditor: creditorValidation.get,
  updateSelectedCreditor: selectedCreditorData.update,
  getNextOrderId: nextOrderId.get,
  getOrderDetails: orderDetails.get,
  updateOrderLines: existingOrderLines.update,
  getVatAmounts: vatAmounts.get,
  filterTableData: tableData.update,
  deleteOrderLine: orderLine.delete,
  updateOrderType: orderType.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManualOrder);
