import { IPayment } from "../interface";

/**
 * @description - Get available payment id to focus
 * @param {IPayment[]} payments - payment list of data
 * @param {number | string} paymentId - paymentId of the payment that has to be focused
 * @return - Best suitable payment id to be focus
 */
export const getPaymentIdToFocus = (
  payments: IPayment[],
  paymentId: number | string
): number | undefined => {
  try {
    const paymentToFocus = payments.find(
      (payment: IPayment) => payment.paymentId == paymentId
    );
    if (paymentToFocus) {
      return paymentToFocus?.paymentId;
    } else {
      return payments[0].paymentId;
    }
  } catch (error) {
    return undefined;
  }
};

/**
 * @description - Get the latest offset value for the given payments count with the limit
 * @param {number} count - Current no of payments
 * @param {number} limit - Limit value
 * @return {number} - Next offset value
 */
export const calculateOffSet = (count: number, limit: number): number => {
  return Math.floor(count / limit) * limit;
};
