import { IProfileDetail } from "us.collection.economy/interfaces";
import {
  DebtorType,
  NewOrderLine,
  DEFAULT_MAIN_AMOUNT_RANGES,
  LAST_MAIN_AMOUNT_RANGE_VALUE,
} from "us.collection.economy/constants";

import { isArray } from "lodash";
import _ from "lodash";

/**
 * This function takes an array of ProfileDetail objects and a DebtorType and returns an array of
 * ProfileDetail objects.
 * @param orderLines - Array<ProfileDetail>
 * @param {DebtorType} type - DebtorType = 'CUSTOMER' | 'SUPPLIER'
 * @returns {Array<ProfileDetail>} An array of ProfileDetail objects.
 */
export const getOrderLinesByDebtorType = (
  type: DebtorType,
  orderLines?: Array<IProfileDetail>
): Array<IProfileDetail> => {
  try {
    if (isArray(orderLines)) {
      return orderLines.filter(
        (orderLine: IProfileDetail) => orderLine.debtorType === type
      );
    }
    return [];
  } catch (error) {
    return [];
  }
};

/**
 * It takes an array of objects, and returns an array of objects, with a new property added to each
 * object.
 * @param orderliness - Array<IProfileDetail>
 * @param {DebtorType} type - DebtorType = 'person' | 'company'
 * @returns An array of objects with mainAmountRange property.
 */
export const formatOrderLinesByType = (
  orderliness: Array<IProfileDetail>,
  type: DebtorType
): Array<IProfileDetail> => {
  try {
    if (isArray(orderliness)) {
      return getOrderLinesByDebtorType(type, orderliness)?.map(
        (details: IProfileDetail) => ({
          ...details,
          mainAmountRange: [details.startMainAmount, details.endMainAmount],
        })
      );
    }

    return [];
  } catch (error) {
    return [];
  }
};

/**
 * It takes an array of objects, and updates the objects in the array based on the index of the object
 * and the value of the object.
 * @param orderLines - Array<IProfileDetail> - The array of form object.
 * @param orderLinesState - Array<IProfileDetail> - initial state.
 * @param {number} dataIndex - number - Index of the specific row.
 * @param {number[]} [value] - value of the amount input.
 * @param {"delete" | undefined} [type] - "delete" | undefined
 * @param {number} [orderLineId] - The id of the order line that is being deleted.
 * @returns An array of updated profileDetail.
 */
export const arrangeAmounts = (
  orderLines: Array<IProfileDetail>,
  orderLinesState: Array<IProfileDetail>,
  dataIndex: number,
  value?: number[],
  type?: "delete" | undefined,
  orderLineId?: number
): Array<IProfileDetail> => {
  try {
    if (isArray(orderLines)) {
      let newOrderLines = orderLines;

      if (type !== "delete" && isArray(value)) {
        /* Update previous and next row amounts */

        if (dataIndex > 0 && value[0] > 0)
          newOrderLines[dataIndex - 1].mainAmountRange[1] = value[0] - 1;

        if (dataIndex < orderLines.length)
          newOrderLines[dataIndex + 1].mainAmountRange[0] = value[1] + 1;

        return newOrderLines;
      } else {
        /* Delete current row. Update previous and next row amounts */

        newOrderLines = [
          ...orderLines?.filter(
            ({ detailId }: { detailId: number }) => detailId !== orderLineId
          ),
        ];

        if (dataIndex == 0 && newOrderLines.length !== 0) {
          newOrderLines[dataIndex].mainAmountRange[0] = 1;
        }

        if (dataIndex > 0 && dataIndex < newOrderLines.length) {
          newOrderLines[dataIndex].mainAmountRange[0] =
            newOrderLines[dataIndex - 1].mainAmountRange[1] + 1;
        }

        if (dataIndex + 1 <= orderLinesState.length) {
          newOrderLines = newOrderLines.map((order: any, index: number) => ({
            ...order,
            detailId: orderLinesState[index].detailId,
          }));
        }

        return newOrderLines;
      }
    }
    return orderLines;
  } catch (error) {
    return orderLines;
  }
};

/**
 * If the array length is greater than or equal to 4, add a new row with the end amount set to 0. If
 * the array length is less than 4, add a new row with the start amount set to the previous row's end
 * amount + 1 and the end amount set to the default end amount for the new row.
 * @param orderLines - Array<IProfileDetail> - Form values
 * @param orderLinesState - Array<IProfileDetail> = Initial state
 * @param {DebtorType} debtorType - DebtorType
 * @returns An array of objects.
 */
export const addNewline = (
  orderLines: Array<IProfileDetail>,
  orderLinesState: Array<IProfileDetail>,
  debtorType: DebtorType
): Array<IProfileDetail> => {
  try {
    if (isArray(orderLines)) {
      if (orderLines.length >= DEFAULT_MAIN_AMOUNT_RANGES.length) {
        const newOrderLines = [...orderLines];
        /* Add new row while keeping the end amount */

        newOrderLines[orderLines.length - 1].mainAmountRange[1] = 0;

        newOrderLines.push({
          ...NewOrderLine,
          mainAmountRange: [...NewOrderLine.mainAmountRange],
          detailId: getUID(),
          debtorType,
        });

        return [...newOrderLines];
      } else {
        /* Add new row and update start and end amounts */

        const newOrderLines = [...orderLines];

        if (orderLines.length === 0) {
          newOrderLines.push({
            ...NewOrderLine,
            detailId: orderLinesState[0].detailId,
            mainAmountRange: [...DEFAULT_MAIN_AMOUNT_RANGES[0]],
            debtorType,
          });

          return [...newOrderLines];
        } else {
          newOrderLines[orderLines.length - 1].mainAmountRange[1] = 0;
          newOrderLines.push({
            ...NewOrderLine,
            detailId:
              newOrderLines.length > 4
                ? getUID()
                : orderLinesState[newOrderLines.length].detailId,
            debtorType,
            mainAmountRange: [0, LAST_MAIN_AMOUNT_RANGE_VALUE],
          });

          return [...newOrderLines];
        }
      }
    }
    return orderLines;
  } catch (error) {
    return orderLines;
  }
};

/**
 * It returns a random number between 100000 and 999999
 * @returns A function that returns a random number between 100000 and 999999.
 */
export const getUID = (): number => {
  return Math.floor(Math.random() * 899999 + 100000);
};

/**
 * It takes an object with two arrays of objects, and returns a new object with the same two arrays of
 * objects, but with a new property added to each object in the arrays.
 * @param {any} profileDetailsReset - {
 * @returns fresh object for reset.
 */
export const getInitialValues = (profileDetailsReset: any): any => {
  try {
    return {
      ...profileDetailsReset,
      profileDetailsPerson: [
        ...profileDetailsReset?.profileDetailsPerson.map(
          (details: IProfileDetail) => ({
            ...details,
            mainAmountRange: [...details.mainAmountRange],
          })
        ),
      ],
      profileDetailsCompany: [
        ...profileDetailsReset?.profileDetailsCompany.map(
          (details: IProfileDetail) => ({
            ...details,
            mainAmountRange: [...details.mainAmountRange],
          })
        ),
      ],
    };
  } catch (error) {
    return profileDetailsReset;
  }
};

/**
 * @description Compare whether the form values have been updated or not with comparing the initial form values.
 * @param {any} formValues - any,
 * @param {any} initialTableData - {
 * @returns A function that takes two arguments and returns a boolean.
 */
export const isFormDirty = (
  formValues: any,
  initialTableData: any
): boolean => {
  try {
    return !_.isEqual(_.cloneDeep(formValues), _.cloneDeep(initialTableData));
  } catch (error) {
    return false;
  }
};

/**
 * If the first element of the last element of the orderLines array is 0, return true, otherwise return
 * false.
 * @param orderLines - Array<IProfileDetail>
 */
export const isDisableNewLine = (
  orderLines: Array<IProfileDetail>
): boolean => {
  try {
    if (isArray(orderLines) && orderLines.length > 0) {
      return orderLines[orderLines.length - 1].mainAmountRange[0] === 0;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

/**
 * "If the maximumMainAmountProvision or maximumInterestProvision is 0, then return true, otherwise
 * return false."
 * @param {any} values - this is the form values
 * @returns {boolean}
 */
export const isMaximumAmountsDefined = (values: any): boolean => {
  try {
    const { maximumMainAmountProvision, maximumInterestProvision } =
      values ?? {};
    return maximumMainAmountProvision > 0 && maximumInterestProvision > 0;
  } catch (error) {
    return false;
  }
};
