import { PaymentsImportSummary } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

const {
  GET_PAYMENTS_IMPORT_SUMMARY,
  GET_PAYMENTS_IMPORT_SUMMARY_FAIL,
  GET_PAYMENTS_IMPORT_SUMMARY_SUCCESS,
} = PaymentsImportSummary;

export const paymentsImportSummary: Readonly<IAPIAction> = {
  summary: {
    search: (data) => ({
      type: GET_PAYMENTS_IMPORT_SUMMARY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_PAYMENTS_IMPORT_SUMMARY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_PAYMENTS_IMPORT_SUMMARY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
