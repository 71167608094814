import { initialState } from "us.collection.economy/reducers/ManagePayment/State";
import {
  ManagePayments,
  ManagePaymentsGrid,
  ManagePaymentsApportionment,
} from "us.collection.economy/constants/Actions";
import { formatPayments } from "us.collection.economy/functions";
import {
  MatchHelper,
  PaymentHelper,
} from "us.collection.economy/components/ManagePayments/Functions";
import {
  updateCaseTableData,
  updateVatAmounts,
  updateVatAmountsForRD,
  getExpandedActiveRowKeys,
} from "us.collection.economy/components/ManagePayments/Components/PaymentDetails";

const {
  INIT_PAYMENTS,
  INIT_PAYMENTS_FAILED,

  SEARCH_PAYMENTS,
  SEARCH_PAYMENTS_SUCCESS,
  SEARCH_PAYMENTS_NO_RESULT,
  SEARCH_PAYMENTS_FAIL,

  GET_PAYMENT_ACCOUNTS,
  GET_PAYMENT_ACCOUNTS_SUCCESS,
  GET_PAYMENT_ACCOUNTS_FAIL,

  RETURN_PAYMENT,
  RETURN_PAYMENT_SUCCESS,
  RETURN_PAYMENT_FAIL,

  EDIT_PAYMENT,
  EDIT_PAYMENT_SUCCESS,
  EDIT_PAYMENT_FAIL,

  SET_PAYMENTS_WITH_ACCOUNT,

  GET_CASES,
  GET_CASES_SUCCESS,
  GET_CASES_FAIL,

  MATCH_PAYMENT,
  MATCH_PAYMENT_SUCCESS,
  MATCH_PAYMENT_FAIL,
  RESET_MATCH_PAYMENT_RESULT,

  DELETE_CASE,

  GET_CASE_VALIDATION_DATA,
  GET_CASE_VALIDATION_DATA_SUCCESS,
  GET_CASE_VALIDATION_DATA_FAIL,

  GET_PAYMENTS_DISTRIBUTION,
  GET_PAYMENTS_DISTRIBUTION_SUCCESS,
  GET_PAYMENTS_DISTRIBUTION_FAIL,

  REVERSE_RETURNED_PAYMENT,
  REVERSE_RETURNED_PAYMENT_SUCCESS,
  REVERSE_RETURNED_PAYMENT_FAIL,

  CLEAR_VALIDATE_CASE_DATA,

  REVERT_MAPPED_PAYMENT,
  REVERT_MAPPED_PAYMENT_SUCCESS,
  REVERT_MAPPED_PAYMENT_FAIL,

  REVERT_PAYMENT_POPCONFIRM_STATUS,
  ADD_CASE_TABLE_ROW_KEY,
  DELETE_CASE_TABLE_ROW_KEY,

  RESET_MATCH,
} = ManagePayments;

const {
  INIT_PAYMENTS_GRID,
  INIT_PAYMENTS_GRID_FAILED,

  GET_PAYMENTS_GRID,
  GET_PAYMENTS_GRID_SUCCESS,
  GET_PAYMENTS_GRID_FAIL,
} = ManagePaymentsGrid;

const {
  GET_PAYMENTS_APPORTIONMENT,
  GET_PAYMENTS_APPORTIONMENT_SUCCESS,
  GET_PAYMENTS_APPORTIONMENT_FAIL,

  GET_VAT_AMOUNT,
  GET_VAT_AMOUNT_SUCCESS,
  GET_VAT_AMOUNT_FAIL,

  GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR,
  GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_SUCCESS,
  GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_FAIL,

  UPDATE_APPORTIONMENT,
  UPDATE_APPORTIONMENT_SUCCESS,
  UPDATE_APPORTIONMENT_FAIL,

  CHANGE_DRAWER_INFO,
  ADD_APPORTIONMENT_TABLE_ACTIVE_KEYS,
  DELETE_APPORTIONMENT_TABLE_ACTIVE_KEYS,
} = ManagePaymentsApportionment;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case INIT_PAYMENTS:
      return Object.assign({}, state, {
        payments: {
          data: initialState.payments.data,
          isFetching: true,
        },
        paymentAccounts: {
          data: [],
          isFetching: true,
        },
      });
    case INIT_PAYMENTS_FAILED:
      return Object.assign({}, state, {
        payments: {
          data: initialState.payments.data,
          isFetching: false,
        },
        paymentAccounts: {
          data: [],
          isFetching: false,
        },
      });

    case INIT_PAYMENTS_GRID:
      return Object.assign({}, state, {
        paymentsGrid: {
          data: initialState.paymentsGrid.data,
          isFetching: true,
        },
        paymentAccounts: {
          data: [],
          isFetching: true,
        },
      });
    case INIT_PAYMENTS_GRID_FAILED:
      return Object.assign({}, state, {
        paymentsGrid: {
          data: initialState.paymentsGrid.data,
          isFetching: false,
        },
        paymentAccounts: {
          data: [],
          isFetching: false,
        },
      });

    case GET_PAYMENTS_GRID:
      return Object.assign({}, state, {
        paymentsGrid: {
          data: initialState.paymentsGrid.data,
          isFetching: true,
        },
      });
    case GET_PAYMENTS_GRID_SUCCESS:
      return Object.assign({}, state, {
        paymentsGrid: {
          data: {
            formatted: formatPayments(payload.data),
            raw: Array.isArray(payload.data) ? payload.data : [],
          },
          isFetching: false,
        },
      });
    case GET_PAYMENTS_GRID_FAIL:
      return Object.assign({}, state, {
        paymentsGrid: {
          data: initialState.paymentsGrid.data,
          isFetching: false,
        },
      });

    case SEARCH_PAYMENTS:
      return Object.assign({}, state, {
        payments: {
          data:
            payload.data?.offset == 0
              ? initialState.payments.data
              : state.payments.data,
          isFetching: !state.payments.hasMorePayments,
        },
      });
    case SEARCH_PAYMENTS_SUCCESS:
      const { payments, dayVal, paymentDateRange, hasMorePayments } =
        payload.data;
      const payment = PaymentHelper.getPaymentForMatch(
        Number(payments[0]?.paymentId),
        payments
      );
      return Object.assign({}, state, {
        payments: {
          data: {
            formatted: formatPayments(payments),
            raw: Array.isArray(payments)
              ? payload.data?.offset == 0
                ? payments
                : [...state.payments.data.raw, ...payments]
              : [],
            ocrFileNameOptions: PaymentHelper.getOCRFileNamesOptions(payments),
          },
          dayVal: dayVal,
          paymentDateRange: paymentDateRange,
          isFetching: false,
          hasMorePayments,
        },
        matchPaymentData: {
          ...state.matchPaymentData,
          payment: payment,
          isExceeded: payment.exceededSum > 0,
        },
      });
    case SEARCH_PAYMENTS_NO_RESULT:
      return Object.assign({}, state, {
        payments: {
          data: state.payments.data,
          dayVal: payload.data?.dayVal,
          paymentDateRange: payload.data?.paymentDateRange,
          isFetching: false,
          hasMorePayments: false,
        },
      });
    case SEARCH_PAYMENTS_FAIL:
      return Object.assign({}, state, {
        payments: {
          data:
            payload.data.offset != 0
              ? state.payments.data
              : initialState.payments.data,
          isFetching: false,
        },
      });

    case GET_PAYMENT_ACCOUNTS:
      return Object.assign({}, state, {
        paymentAccounts: {
          data: [],
          isFetching: true,
        },
      });
    case GET_PAYMENT_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        paymentAccounts: {
          data: payload.data,
          isFetching: false,
        },
      });
    case GET_PAYMENT_ACCOUNTS_FAIL:
      return Object.assign({}, state, {
        paymentAccounts: {
          data: [],
          isFetching: false,
        },
      });
    case GET_PAYMENTS_DISTRIBUTION:
      return Object.assign({}, state, {
        paymentDistribution: {
          data: {},
          isFetching: true,
        },
      });
    case GET_PAYMENTS_DISTRIBUTION_SUCCESS:
      return Object.assign({}, state, {
        paymentDistribution: {
          data: payload.data,
          isFetching: false,
        },
      });
    case GET_PAYMENTS_DISTRIBUTION_FAIL:
      return Object.assign({}, state, {
        paymentDistribution: {
          data: {},
          isFetching: false,
        },
      });
    case GET_CASES:
      return Object.assign({}, state, {
        matchPaymentData: {
          ...state.matchPaymentData,
          casesData: [],
          caseTableData: [],
        },
        cases: {
          data: [],
          isFetching: true,
        },
      });
    case GET_CASES_SUCCESS:
      const { cases, paymentId } = payload.data;
      const data = Array.isArray(cases) ? cases : [];
      const paymentForMatch = PaymentHelper.getPaymentForMatch(
        paymentId,
        state.payments.data.raw
      );
      const { amount, arNo, exceededSum, unknownSum, returnfee, accountNo } =
        paymentForMatch;
      const updatedCaseTableData = MatchHelper.getCasesWithMapping(
        cases,
        amount,
        arNo
      );
      const exceededOrUnknownAmount =
        exceededSum > 0 ? exceededSum : unknownSum;
      const remainingBalance = MatchHelper.getExceededOrUnknownBalance(
        updatedCaseTableData,
        exceededOrUnknownAmount
      );
      return Object.assign({}, state, {
        matchPaymentData: {
          payment: paymentForMatch,
          mainAr: arNo,
          exceededOrUnknownAmount,
          isExceeded: exceededSum > 0,
          remainingBalance: remainingBalance,
          returnFee: returnfee,
          debtorAccountNo: accountNo ?? "",
          returnAmount: remainingBalance > 0 ? remainingBalance - returnfee : 0,
          casesData: data,
          caseTableData: updatedCaseTableData,
          activeRowKeys:
            updatedCaseTableData.length > 0
              ? [updatedCaseTableData[0].key]
              : [],
        },
        cases: {
          data,
          isFetching: false,
        },
      });
    case GET_CASES_FAIL:
      return Object.assign({}, state, {
        cases: {
          data: [],
          isFetching: false,
        },
        matchPaymentData: {
          ...state.matchPaymentData,
          casesData: [],
          caseTableData: [],
        },
      });
    case MATCH_PAYMENT:
      return Object.assign({}, state, {
        matchPaymentResult: {
          data: null,
          isFetching: true,
        },
      });
    case MATCH_PAYMENT_SUCCESS:
      const { matchResult, matchedPayments } = payload.data;
      const updatedPayments = Array.isArray(matchedPayments)
        ? matchedPayments
        : state.payments.data.raw?.map((payment: { paymentId: number }) => {
            if (payment?.paymentId == matchedPayments.paymentId) {
              return matchedPayments;
            }
            return payment;
          });
      return Object.assign({}, state, {
        matchPaymentResult: {
          data: matchResult,
          isFetching: false,
        },
        payments: {
          ...state.payments,
          data: {
            ...state.payments.data,
            raw: updatedPayments,
            ocrFileNameOptions:
              PaymentHelper.getOCRFileNamesOptions(updatedPayments),
          },
        },
        matchPaymentData: {
          ...state.matchPaymentData,
          casesData: [],
          caseTableData: [],
        },
      });
    case MATCH_PAYMENT_FAIL:
      return Object.assign({}, state, {
        matchPaymentResult: {
          data: null,
          isFetching: false,
        },
      });
    case RESET_MATCH_PAYMENT_RESULT:
      return Object.assign({}, state, {
        matchPaymentResult: {
          data: null,
          isFetching: false,
        },
      });
    case GET_CASE_VALIDATION_DATA:
      return Object.assign({}, state, {
        caseValidation: {
          data: {},
          isFetching: true,
        },
      });
    case GET_CASE_VALIDATION_DATA_SUCCESS:
      if (Object.keys(payload.data).length > 0) {
        const newCase = [
          {
            ...payload.data,
            balance: payload.data.arBalance,
            deletable: true,
          },
        ];
        const caseTableData = MatchHelper.addNewCaseToCaseTableData(
          state.matchPaymentData.caseTableData,
          payload.data,
          state.matchPaymentData.remainingBalance
        );

        return Object.assign({}, state, {
          caseValidation: {
            data: payload.data,
            isFetching: false,
          },
          matchPaymentData: {
            ...state.matchPaymentData,
            casesData: [...state.matchPaymentData.casesData, ...newCase],
            caseTableData,
            activeRowKeys: [
              ...state.matchPaymentData.activeRowKeys,
              payload.data.arId?.toString(),
            ],
          },
        });
      }
    case GET_CASE_VALIDATION_DATA_FAIL:
      return Object.assign({}, state, {
        caseValidation: {
          data: {},
          isFetching: false,
        },
      });
    case DELETE_CASE:
      const updatedCases = state.matchPaymentData.casesData.filter(
        (c: { caseNo: string }) => c.caseNo !== payload.caseNo
      );
      const filteredCaseTableData = MatchHelper.removeCaseFromCaseTableData(
        state.matchPaymentData.caseTableData,
        payload.caseNo
      );

      return Object.assign({}, state, {
        matchPaymentData: {
          ...state.matchPaymentData,
          casesData: updatedCases,
          caseTableData: filteredCaseTableData,
        },
      });
    case RETURN_PAYMENT:
      return Object.assign({}, state, {
        returnPayment: {
          isSaving: true,
          result: null,
        },
      });
    case RETURN_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        returnPayment: {
          isSaving: false,
          result: payload.data,
        },
      });
    case RETURN_PAYMENT_FAIL:
      return Object.assign({}, state, {
        returnPayment: {
          isSaving: false,
          result: payload.data,
        },
      });

    case EDIT_PAYMENT:
      return Object.assign({}, state, {
        editPayment: {
          ...state.editPayment,
          isSaving: true,
        },
      });
    case EDIT_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        editPayment: {
          ...initialState.editPayment,
          isSaving: false,
        },
      });
    case EDIT_PAYMENT_FAIL:
      return Object.assign({}, state, {
        editPayment: {
          ...initialState.editPayment,
          isSaving: false,
        },
      });

    case SET_PAYMENTS_WITH_ACCOUNT:
      return Object.assign({}, state, {
        paymentsWithAcc: payload.data,
      });

    case REVERSE_RETURNED_PAYMENT:
      return Object.assign({}, state, {
        payments: state.payments,
        revertPayment: {
          ...state.revertPayment,
          isPaymentRevert: true,
        },
      });
    case REVERSE_RETURNED_PAYMENT_SUCCESS:
      const { reversedReturnedPayment } = payload.data;

      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          data: {
            ...state.payments.data,
            raw: MatchHelper.getUpdatedRowData(
              reversedReturnedPayment,
              state.payments.data.raw
            ),
          },
        },
        revertPayment: {
          ...state.revertPayment,
          isPaymentRevert: false,
        },
      });
    case REVERSE_RETURNED_PAYMENT_FAIL:
      return Object.assign({}, state, {
        payments: state.payments,
        revertPayment: {
          ...state.revertPayment,
          isPaymentRevert: false,
        },
      });
    case CLEAR_VALIDATE_CASE_DATA:
      return Object.assign({}, state, {
        caseValidation: initialState.caseValidation,
      });
    case GET_PAYMENTS_APPORTIONMENT:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          data: {},
          isFetchingApportionments: true,
        },
      });
    case GET_PAYMENTS_APPORTIONMENT_SUCCESS:
      const tableData1 = updateCaseTableData(payload);
      const tableData2 = updateCaseTableData(payload);
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          data: tableData1,
          initialData: tableData2,
          isFetchingApportionments: false,
          activeCollapseRawKeys: getExpandedActiveRowKeys(
            tableData1?.caseTableData.filter((itm: any) => itm.caseNo != "")[0]
          ),
        },
      });
    case GET_PAYMENTS_APPORTIONMENT_FAIL:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          data: [],
          isFetchingApportionments: false,
        },
      });

    case GET_VAT_AMOUNT:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          isFetching: true,
        },
      });
    case GET_VAT_AMOUNT_SUCCESS:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          data: {
            ...payload.values,
            caseTableData: updateVatAmounts(payload),
            roundOffApportionment:
              payload.record.text == "Rounding Off"
                ? {
                    ...payload.values.roundOffApportionment,
                    vatAmount: payload.data.vatAmount,
                    vatBasis: payload.data.vatBasis,
                    vatDeducted: payload.data.vatDeducted,
                    vatPaidByDebtor: payload.data.vatPaidByDebtor,
                  }
                : payload.values.roundOffApportionment,
          },
          isFetching: false,
        },
      });
    case GET_VAT_AMOUNT_FAIL:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          isFetching: false,
        },
      });
    case GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          data: { ...state.paymentApportionments.data },
          isFetching: true,
        },
      });
    case GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_SUCCESS:
      const { updatedReturnFeeVat, newCaseTableDataWithVat } =
        updateVatAmountsForRD(payload);
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          data: {
            ...payload.values,
            returnFeeVat: updatedReturnFeeVat,
            caseTableData: newCaseTableDataWithVat,
          },
          isFetching: false,
        },
      });
    case GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_FAIL:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          data: { ...state.paymentApportionments.data },
          isFetching: false,
        },
      });

    case UPDATE_APPORTIONMENT:
      return Object.assign({}, state, {
        payments: state.payments,
        paymentApportionments: {
          ...state.paymentApportionments,
          isUpdatingApportionments: true,
          isUpdatingApportionmentsSuccess: false,
        },
      });
    case UPDATE_APPORTIONMENT_SUCCESS:
      const { apportionedPayment } = payload.data;

      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          data: {
            ...state.payments.data,
            raw: Array.isArray(apportionedPayment)
              ? apportionedPayment
              : state.payments.data.raw?.map(
                  (payment: { paymentId: number }) => {
                    if (payment?.paymentId == apportionedPayment?.paymentId) {
                      return apportionedPayment;
                    }
                    return payment;
                  }
                ),
          },
        },
        paymentApportionments: {
          ...state.paymentApportionments,
          isUpdatingApportionments: false,
          isUpdatingApportionmentsSuccess: true,
          drawerInfo: {
            title: "",
            visible: false,
          },
        },
      });
    case UPDATE_APPORTIONMENT_FAIL:
      return Object.assign({}, state, {
        payments: state.payments,
        paymentApportionments: {
          ...state.paymentApportionments,
          isUpdatingApportionments: false,
          isUpdatingApportionmentsSuccess: false,
          drawerInfo: {
            title: "",
            visible: false,
          },
        },
      });

    case CHANGE_DRAWER_INFO:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          drawerInfo: payload.data,
        },
      });

    case ADD_APPORTIONMENT_TABLE_ACTIVE_KEYS:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          activeCollapseRawKeys: Array.isArray(payload)
            ? payload
            : [...state.paymentApportionments.activeCollapseRawKeys, payload],
        },
      });

    case DELETE_APPORTIONMENT_TABLE_ACTIVE_KEYS:
      return Object.assign({}, state, {
        paymentApportionments: {
          ...state.paymentApportionments,
          activeCollapseRawKeys:
            state.paymentApportionments.activeCollapseRawKeys?.filter(
              (key: string) => key !== payload
            ),
        },
      });

    case REVERT_MAPPED_PAYMENT:
      return Object.assign({}, state, {
        payments: state.payments,
        revertPayment: {
          ...state.revertPayment,
          isPaymentRevert: true,
        },
      });
    case REVERT_MAPPED_PAYMENT_SUCCESS:
      const { revertMappedPayment } = payload.data;
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          data: {
            ...state.payments.data,
            raw: MatchHelper.getUpdatedRowData(
              revertMappedPayment,
              state.payments.data.raw
            ),
          },
        },
        revertPayment: {
          ...state.revertPayment,
          isPaymentRevert: false,
        },
      });
    case REVERT_MAPPED_PAYMENT_FAIL:
      return Object.assign({}, state, {
        payments: state.payments,
        revertPayment: {
          ...state.revertPayment,
          isPaymentRevert: false,
        },
      });
    case REVERT_PAYMENT_POPCONFIRM_STATUS:
      return Object.assign({}, state, {
        revertPayment: {
          ...state.revertPayment,
          revertPaymentPopConfirmStatus: payload?.status,
          revertItemTypeId: payload?.id,
        },
      });
    case ADD_CASE_TABLE_ROW_KEY:
      return Object.assign({}, state, {
        matchPaymentData: {
          ...state.matchPaymentData,
          activeRowKeys: [...state.matchPaymentData.activeRowKeys, payload],
        },
      });
    case DELETE_CASE_TABLE_ROW_KEY:
      return Object.assign({}, state, {
        matchPaymentData: {
          ...state.matchPaymentData,
          activeRowKeys: state.matchPaymentData.activeRowKeys?.filter(
            (key: string) => key !== payload
          ),
        },
      });
    case RESET_MATCH:
      return Object.assign({}, state, {
        matchPaymentData: {
          ...initialState.matchPaymentData,
          payment: payload,
          isExceeded: payload.exceededSum > 0,
        },
      });
    default:
      return state;
  }
};
