import { VATDeductionStatus } from "us.collection.economy/constants";
import { InvoiceRemitParams } from "us.collection.economy/interfaces";

export function InvoiceRemit(this: any): InvoiceRemitParams {
  return {
    creditorNo: this.creditorNo,
    remitGuid: this.remitId,
    status: this.isInvoiced
      ? VATDeductionStatus.ADD
      : VATDeductionStatus.REMOVE,
  };
}
