import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import "us.collection.economy/components/ClientInvoices/Home.scss";
import { InfoCircleOutlined } from "us.icons";

import {
  IInvoice,
  IInvoiceAction,
} from "us.collection.economy/components/ClientInvoices/Interfaces";
import { BoxIcons, IconTypes, MoreOutlined } from "us.icons";
import {
  CIExportStatus,
  InvoiceAction,
  InvoiceExportStatus,
} from "us.collection.economy/components/ClientInvoices/Constants";
import { ItemMenu } from "../ItemMenu";
import { useField } from "formik";
import { getRouteUrl } from "us.helper";
import { IInvoiceItem } from "./interface";
import { isExportEnabled } from "us.collection.economy/components/ClientInvoices/Functions";
import { $Tag } from "us.common/components";

const { $Checkbox, $AmountLabel, $Popover, $Button, $DateLabel, $Tooltip } =
  Common.Components;

const InvoiceItem: React.FC<IInvoiceItem> = ({
  invoice,
  selectedInvoices,
  actions,
  onSelectInvoice,
  onCallAction,
}) => {
  const { t } = useTranslation();
  const {
    invoiceId,
    invoiceNo,
    customerNo,
    customerName,
    orderNo,
    netAmount,
    vatAmount,
    invoiceAmount,
    isCreditInvoice,
    sourceInvoiceNo,
    exportStatus,
    caseNo,
    exportMethod,
    regDate,
    exportedDate,
    invoiceExportStatus,
  } = invoice;

  const isPending = exportStatus === CIExportStatus.PENDING_EXPORT;
  const exportEnabled = isExportEnabled(invoice);

  const [, meta] = useField("isAllInvoices");
  const [itemMenu, setItemMenu] = useState<{
    visible: boolean;
    invoiceId: number;
    visibleTooltip: boolean;
  }>({ visible: false, invoiceId: -1, visibleTooltip: false });

  // auto close invoice item menu after 5 seconds
  const setAutoClose = (invoiceId: number, isTooltip?: boolean) => {
    setTimeout(
      () => {
        setItemMenu({
          visible: false,
          invoiceId,
          visibleTooltip: false,
        });
      },
      isTooltip ? 8000 : 5000
    );
  };

  /**
   * @description check whether an invoice item is selected or not
   * @returns true or false
   */
  const isInvoiceSelected = (id: number): boolean => {
    try {
      if (meta.value) {
        return true;
      } else if (selectedInvoices) {
        return selectedInvoices.some(
          ({ invoiceId }: IInvoice) => invoiceId === id
        );
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  return (
    <div
      className="invoices-item-row"
      key={invoiceId}
      id={`${invoiceId}`}
      data-testid={`invoice-item-${invoiceId}`}
      onDoubleClick={() => onCallAction(InvoiceAction.VIEW_INVOICE_DETAILS, invoice)}
    >
      <div className="invoices-item-col">
        <div className="d-flex align-items-center">
          {onSelectInvoice && exportEnabled && isPending && (
            <div className="mr-2">
              <$Checkbox
                checked={isInvoiceSelected(invoiceId)}
                onChange={(e: any) =>
                  onSelectInvoice(e.target.checked, invoice)
                }
              />
            </div>
          )}
          {!exportEnabled && isPending && (
            <$Popover
              placement="bottom"
              visible={
                itemMenu.visibleTooltip && itemMenu.invoiceId === invoiceId
              }
              onVisibleChange={(visible: boolean) => {
                setItemMenu({
                  ...itemMenu,
                  visibleTooltip: visible,
                  invoiceId,
                });
                visible && setAutoClose(invoiceId, true);
              }}
              content={
                <div className="d-flex text-primary mr-4">
                  <div className="mr-1">
                    <InfoCircleOutlined />
                  </div>
                  <div className="mr-1 text-nowrap">
                    {invoiceExportStatus === InvoiceExportStatus.PENDING
                      ? t(
                          "US.COLLECTION.ECONOMY:INVOICES.ALREADY_REQUESTED_TO_EXPORT"
                        )
                      : t(
                          "US.COLLECTION.ECONOMY:INVOICES.CANNOT_EXPORT_WITHOUT_EXPORTING_THE_SOURCE_INVOICE"
                        )}
                  </div>
                </div>
              }
            >
              <div className="mr-2">
                <$Checkbox disabled />
              </div>
            </$Popover>
          )}
          {!(isCreditInvoice && isPending) && actions && (
            <div className="mr-2">
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={itemMenu.visible && itemMenu.invoiceId === invoiceId}
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    invoiceId,
                  });
                  setAutoClose(invoiceId);
                }}
                content={
                  <ItemMenu
                    actions={actions}
                    onCallAction={(actionType: IInvoiceAction) => {
                      setItemMenu({
                        ...itemMenu,
                        visible: false,
                        invoiceId,
                      });
                      onCallAction(actionType, invoice);
                    }}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button icon={<MoreOutlined />} size="small" />
              </$Popover>
            </div>
          )}
          <div>
            {isCreditInvoice && sourceInvoiceNo && (
              <$Popover
                title={t("US.COLLECTION.ECONOMY:INVOICES.CREDIT_INVOICE")}
                content={
                  <div className="d-flex">
                    <div>
                      {`${t(
                        "US.COLLECTION.ECONOMY:INVOICES.THIS_IS_CREDIT_INVOICE_MADE_TOWARDS"
                      )} : `}
                    </div>
                    <div className="ml-2 font-weight-bold">
                      {sourceInvoiceNo}
                    </div>
                  </div>
                }
              >
                <span className="text-error" data-testid="icon-credit-invoice">
                  <BoxIcons
                    className="font-lg"
                    type={IconTypes.BOX_ICON}
                    name="credit-invoice"
                  />
                </span>
              </$Popover>
            )}
            {isCreditInvoice && !sourceInvoiceNo && (
              <span className="text-error" data-testid="icon-credit-invoice">
                <BoxIcons
                  className="font-lg"
                  type={IconTypes.BOX_ICON}
                  name="credit-invoice"
                />
              </span>
            )}
          </div>
        </div>
      </div>
      <div className="invoices-item-col">
        <div className="d-flex flex-column">
          <div
            className="font-weight-bold d-flex align-items-center"
            data-testid="label-invoice-no"
          >
            <span>{`${t(
              "US.COLLECTION.ECONOMY:INVOICES.INVOICE_NO"
            )} : ${invoiceNo}`}</span>
            {invoiceExportStatus === InvoiceExportStatus.PENDING && isPending && (
              <$Tag className="font-sm ml-2" color="orange">
                {t("US.COLLECTION.ECONOMY:INVOICES.PENDING")}
              </$Tag>
            )}
          </div>
          <div className="text-muted">
            {`${t("US.COLLECTION.ECONOMY:INVOICES.INVOICE_DATE")} : `}
            <$DateLabel value={regDate} />
          </div>
        </div>
      </div>
      {!isPending && (
        <div className="invoices-item-col">
          <div className="d-flex flex-column">
            <div className="text-muted">
              {t("US.COLLECTION.ECONOMY:INVOICES.EXPORTED_DATE")}
            </div>
            <div className="font-weight-bold">
              <$DateLabel value={exportedDate} />
            </div>
          </div>
        </div>
      )}
      <div className="invoices-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted">
            {t("US.COLLECTION.ECONOMY:INVOICES.CUSTOMER")}
          </div>
          <a
            className="font-weight-bold text-truncate text-nowrap"
            data-testid="label-customer"
            onClick={() =>
              getRouteUrl.moduleRoute("collection", `/creditor/${customerNo}`)
            }
          >{`${customerNo} - ${customerName}`}</a>
        </div>
      </div>
      <div className="invoices-item-col">
        {caseNo && (
          <div className="d-flex flex-column">
            <div className="text-muted">
              {t("US.COLLECTION.ECONOMY:INVOICES.CASE_NO")}
            </div>
            <a onClick={() => getRouteUrl.caseType(caseNo)}>{caseNo}</a>
          </div>
        )}
      </div>
      <div className="invoices-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted">
            {t("US.COLLECTION.ECONOMY:INVOICES.ORDER_NO")}
          </div>
          <div className="">{orderNo}</div>
        </div>
      </div>
      <div className="invoices-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted text-right">
            {t("US.COLLECTION.ECONOMY:INVOICES.NET_AMOUNT")}
          </div>
          <div className="text-right">
            <$AmountLabel value={netAmount} />
          </div>
        </div>
      </div>
      <div className="invoices-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted text-right">
            {t("US.COLLECTION.ECONOMY:INVOICES.VAT_AMOUNT")}
          </div>
          <div className="text-right">
            <$AmountLabel value={vatAmount} />
          </div>
        </div>
      </div>
      <div className="invoices-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted text-right">
            {t("US.COLLECTION.ECONOMY:INVOICES.TOTAL")}
          </div>
          <div className="text-right font-weight-bold">
            <$AmountLabel value={invoiceAmount} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoiceItem;
