import { ProvisionProfile } from "us.collection.economy/interfaces";

export enum ProvisionProfileAction {
  EDIT = "EDIT",
  ACTIVATE_OR_DEACTIVATE = "ACTIVATE_OR_DEACTIVATE",
  COPY = "COPY",
}

export interface IProvisionProfileTable {
  onCallAction: (
    action: ProvisionProfileAction,
    record: ProvisionProfile
  ) => void;
}
