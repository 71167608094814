import React from "react";
import { useTranslation } from "react-i18next";
import { $AmountLabel, $DateLabel } from "us.common/components";
import { getRouteUrl } from "us.helper";
import { ActionsBar } from "../ActionsBar";
import { ActionTypes } from "us.collection.economy/components/ManagePayments/interface";

interface Props {
  amount?: number | undefined;
  label: string | JSX.Element;
  returnedDate?: string;
  onRevert?: <D>(payment: D) => void | undefined;
  caseId?: string | number;
  caseNo?: number | string;
  caseType?: string;
  paymentId?: number;
  itemTypeId?: number;
}

const Row: React.FC<Props> = ({
  amount,
  label,
  returnedDate,
  onRevert,
  caseId,
  caseNo,
  caseType,
  paymentId,
  itemTypeId,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className="map-item-table-row"
      data-testid={`item-${caseNo}`}
      key={itemTypeId}
    >
      {onRevert !== undefined && (
        <div className="map-item-table-col" style={{ width: 60 }}>
          <ActionsBar
            actions={[ActionTypes.REVERT_PAYMENT]}
            paymentId={paymentId}
            onAction={onRevert}
            uniqueRevertId={itemTypeId}
          />
        </div>
      )}
      <div className="map-item-table-col">
        <span>
          <span>{label}</span>
          {returnedDate && (
            <span className="text-muted ml-1">
              ({`${t("US.COLLECTION.COMMON:COMMON.DATE")} `}
              <$DateLabel value={returnedDate} />)
            </span>
          )}
        </span>
        {caseNo && (
          <a
            href={"javascript:void(0)"}
            onClick={() => getRouteUrl.caseType(caseNo)}
          >
            {caseNo}
          </a>
        )}
      </div>
      <div
        className="map-item-table-col text-right font-weight-bold"
        data-testid="amount-test"
      >
        {amount && <$AmountLabel value={amount} />}
      </div>
    </div>
  );
};

export default Row;
