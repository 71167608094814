export enum TableColumn {
  MENU = "menu",
  ACCOUNT = "accountName",
  DESCRIPTION = "description",
  LAST_UPDATE = "modifiedDate",
}

export enum Action {
  ADD = "ADD",
  EDIT = "EDIT",
  VIEW = "VIEW",
  DELETE = "DELETE",
}

export enum ColumnSearchType {
  AMOUNT = "amount",
  DATE = "date",
  ACCOUNT_NO = "accountNo",
  STRING = "string",
}

export enum SearchType {
  ALL="ALL"
}

export const COLUMNS = [
  {
    id: 0,
    key: TableColumn.MENU,
    dataIndex: TableColumn.MENU,
    title: "",
    width: "40px"
  },
  {
    id: 1,
    key: TableColumn.ACCOUNT,
    dataIndex: TableColumn.ACCOUNT,
    title: "ACCOUNT",
  },
  {
    id: 2,
    key: TableColumn.DESCRIPTION,
    dataIndex: TableColumn.DESCRIPTION,
    title: "DESCRIPTION",
  },
  {
    id: 3,
    key: TableColumn.LAST_UPDATE,
    dataIndex: TableColumn.LAST_UPDATE,
    title: "LAST_UPDATE",
    width: "140px"
  },
];

export const DEFAULT_TABLE_INFO = {
  filters: {},
  sorter: { columnKey: "", order: "" },
  data: [],
};

export const DEFAULT_DRAWER_STATE = {
  title: "",
  visible: false,
};

export const ITEM_MENU_DEFAULT_STATE = {
  visible: false,
  id: -1,
  visibleTooltip: false,
};

export const INITIAL_DETAIL_STATE = {
  accountNo: "",
  accountName: "",
  description: "",
  isSystem: false,
};
