import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { ImportPayments } from "us.collection.economy/constants/Actions";
import { $MessageBox } from "us.common/components";
const { importPayments } = Actions;

const ImportPaymentSagas = {
  upload: function* (action: any) {
    const params = action?.payload?.data ?? {};
    try {
      const data = yield call(API.importPayments.payments.upload, params);
      if (data.code != undefined) {
        if (data.code != 200) {
          $MessageBox("error", `US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.${data.subject}`, "", "");
          yield put(importPayments.upload.fail(new Error()));
        }
      } else {
        $MessageBox(
          "success",
          "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.SUCCESSFULLY_UPLOADED",
          "",
          ""
        );
        yield put(importPayments.upload.success(data));
      }
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.UPLOAD_FAILED",
        "",
        ""
      );
      yield put(importPayments.upload.fail(new Error()));
    }
  },
  import: function* (action: any) {
    const params = action?.payload?.data ?? {};
    try {
      const data = yield call(API.importPayments.payments.import, params);
      if (data.code != undefined) {
        if (data.code != 200) {
          $MessageBox("error", "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.IMPORT_FAILED", "", "");
          yield put(importPayments.importFiles.fail(new Error()));
        }
      } else {
        $MessageBox(
          "loading",
          "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.OCR_IMPORT_IS_IN_PROGRESS_PLEASE_AWAIT_THE_NOTIFICATION",
          "",
          ""
        );
        yield put(importPayments.importFiles.success(data));
      }
    } catch (error) {
      $MessageBox(
        "error",
        "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.IMPORT_FAILED",
        "",
        ""
      );
      yield put(importPayments.importFiles.fail(new Error()));
    }
  },
};

export default [
  takeLatest(ImportPayments.UPLOAD_PAYMENTS_START, ImportPaymentSagas.upload),
  takeLatest(ImportPayments.IMPORT_PAYMENTS_START, ImportPaymentSagas.import),
];
