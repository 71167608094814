import React from "react";
import Common from "us.common";
import { IAccountRecord } from "us.collection.economy/components/RemitHistory/Components/LedgerDashboard/Interfaces";
const { $AmountLabel, $Tooltip } = Common.Components;

const Item: React.FC<IAccountRecord> = (props) => {
  const { accountName, debitAmount, creditAmount } = props.record ?? {};
  return (
    <>
      <div className="cardledger-wrap-inner">
        <div className="cardledger">
          <div className="cardledger-item text-truncate text-nowrap">
            <$Tooltip title={accountName}>{accountName}</$Tooltip>
          </div>
          <div className="d-flex ">
            <div className="flex-fill cardledger-amt font-weight-bold">
              <$AmountLabel value={creditAmount} />
            </div>
            <div className="flex-fill cardledger-amt font-weight-bold pt-3">
              <$AmountLabel value={debitAmount} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Item;
