import { IRemitActions } from "us.collection.economy/interfaces";
import { Remit } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

const {
  GET_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_FAIL,
  GET_CREDITORS,
  GET_CREDITORS_SUCCESS,
  GET_CREDITORS_FAIL,
  RESET_CREDITORS,
  GET_CREDITOR_GROUPS,
  GET_CREDITOR_GROUPS_FAIL,
  GET_CREDITOR_GROUPS_SUCCESS,
  REMOVE_CREDITORS_BY_GROUP_ID,
  GET_REMIT_PROPOSAL,
  GET_REMIT_PROPOSAL_SUCCESS,
  GET_REMIT_PROPOSAL_FAIL,
  VALIDATE_REMIT,
  VALIDATE_REMIT_SUCCESS,
  VALIDATE_REMIT_FAIL,
  GET_REMIT_DETAILS_BY_CREDITOR,
  GET_REMIT_DETAILS_BY_CREDITOR_SUCCESS,
  GET_REMIT_DETAILS_BY_CREDITOR_FAIL,
  SELECT_DESELECT_CREDITORS,
  GENERATE_REMIT,
  GENERATE_REMIT_SUCCESS,
  GENERATE_REMIT_FAIL,
  INVOICE_REMIT,
  INVOICE_REMIT_SUCCESS,
  INVOICE_REMIT_FAIL,
} = Remit;

export const remit: Readonly<IAPIAction & IRemitActions> = {
  bankAccounts: {
    get: (data) => ({
      type: GET_BANK_ACCOUNTS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_BANK_ACCOUNTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_BANK_ACCOUNTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  creditors: {
    get: (data) => ({
      type: GET_CREDITORS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    remove: (data) => ({
      type: REMOVE_CREDITORS_BY_GROUP_ID,
      payload: {
        data,
        isLoading: true,
      },
    }),
    select: (data) => ({
      type: SELECT_DESELECT_CREDITORS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    reset: (data) => ({
      type: RESET_CREDITORS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_CREDITORS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_CREDITORS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  creditorGroups: {
    get: (data) => ({
      type: GET_CREDITOR_GROUPS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_CREDITOR_GROUPS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_CREDITOR_GROUPS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remitProposal: {
    search: (data) => ({
      type: GET_REMIT_PROPOSAL,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_REMIT_PROPOSAL_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_REMIT_PROPOSAL_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remitValidation: {
    get: (data) => ({
      type: VALIDATE_REMIT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: VALIDATE_REMIT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: VALIDATE_REMIT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remitDetails: {
    get: (data) => ({
      type: GET_REMIT_DETAILS_BY_CREDITOR,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_REMIT_DETAILS_BY_CREDITOR_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_REMIT_DETAILS_BY_CREDITOR_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remitGeneration: {
    save: (data) => ({
      type: GENERATE_REMIT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GENERATE_REMIT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GENERATE_REMIT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remitInvoice: {
    update: (data) => ({
      type: INVOICE_REMIT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: INVOICE_REMIT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: INVOICE_REMIT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
