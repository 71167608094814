import { isArray } from "lodash";
import { ISearchRemitHistory } from "us.collection.economy/components/RemitHistory/Interfaces";
import { IDownloadFile } from "us.collection.economy/interfaces";
import { ReverseRemitParams } from "us.collection.economy/interfaces/RemitHistory";
import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";

export default {
  remits: {
    init: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const bankAccounts = await httpCollection.get(
            "paymentservice",
            "payment/bankaccounts",
            {}
          );
          const { data, status } = await httpCollection.post(
            "paymentservice",
            "remit/history",
            {
              ...params,
              accountNo: bankAccounts.data[0]?.accountNo,
            }
          );
          resolve({ remits: data, accounts: bankAccounts.data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    search: (params: ISearchRemitHistory): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "paymentservice",
            "remit/history",
            params
          );
          resolve({ remits: data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    download: ({
      remitHistoryParams,
      downloadFileParams,
    }: {
      remitHistoryParams: ISearchRemitHistory;
      downloadFileParams: IDownloadFile;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.downloadFile(
            "commonservice",
            "common/downloadfiles",
            downloadFileParams,
            "v1"
          );
          if (status === 200) {
            const link = document.createElement("a");
            link.target = "_blank";

            if (isArray(downloadFileParams.fileName)) {
              if (downloadFileParams.fileName.length > 1) {
                link.download = downloadFileParams.fileName[0].replace(
                  /\.[^.]+$/,
                  ".zip"
                );
              } else {
                link.download = downloadFileParams.fileName[0];
              }
            }

            link.href = URL.createObjectURL(new Blob([data]));
            link.click();
            const remitHistoryResponse = await httpCollection.post(
              "paymentservice",
              "remit/history",
              remitHistoryParams
            );
            resolve({ data, status, remits: remitHistoryResponse.data });
          }
          reject(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    reverse: ({
      journalId,
      reverseRemitParams,
      remitHistoryParams,
    }: {
      journalId: string;
      reverseRemitParams: ReverseRemitParams;
      remitHistoryParams: ISearchRemitHistory;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.delete(
            "paymentservice",
            `remit/${journalId}/reverse`,
            "",
            reverseRemitParams,
            "v1"
          );
          if (status === 200) {
            const remitHistory = await httpCollection.post(
              "paymentservice",
              "remit/history",
              remitHistoryParams
            );
            if (remitHistory.status === 200 && remitHistory.data) {
              resolve({
                status,
                remitReverse: data,
                remits: remitHistory.data,
              });
            }
          }
          resolve({
            status,
            remitReverse: data,
            remits: [],
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  ledgerDetails: {
    get: (journalId: string | number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            `remit/${journalId}/ledger`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
