import { IVatCodeActions } from "us.collection.economy/interfaces";
import { VatCode } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

const {
GET_VAT_CODE_LIST,
GET_VAT_CODE_LIST_SUCCESS,
GET_VAT_CODE_LIST_FAIL,
GET_LEDGER_ACCOUNTS,
GET_LEDGER_ACCOUNTS_SUCCESS,
GET_LEDGER_ACCOUNTS_FAIL,
SAVE_VAT_CODE,
SAVE_VAT_CODE_SUCCESS,
SAVE_VAT_CODE_FAIL,
RESET_VAT_CODE_DETAILS,
VALIDATE_VAT_CODE,
VALIDATE_VAT_CODE_SUCCESS,
VALIDATE_VAT_CODE_FAIL,
UPDATE_VAT_CODE,
UPDATE_VAT_CODE_SUCCESS,
UPDATE_VAT_CODE_FAIL,
DELETE_VAT_CODE,
DELETE_VAT_CODE_SUCCESS,
DELETE_VAT_CODE_FAIL,
RESET_VAT_CODE_VALIDATION
} = VatCode;

export const vatCode: Readonly<IAPIAction & IVatCodeActions> = {
  vatCodeList: {
    get: (data) => ({
      type: GET_VAT_CODE_LIST,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_VAT_CODE_LIST_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_VAT_CODE_LIST_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  ledgerAccounts: {
    get: (data) => ({
      type: GET_LEDGER_ACCOUNTS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_LEDGER_ACCOUNTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_LEDGER_ACCOUNTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  newVatCode: {
    save: (data) => ({
      type: SAVE_VAT_CODE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_VAT_CODE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_VAT_CODE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_VAT_CODE_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  addedVatCode: {
    get: (data) => ({
      type: VALIDATE_VAT_CODE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: VALIDATE_VAT_CODE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: VALIDATE_VAT_CODE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_VAT_CODE_VALIDATION,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  existingVatCode: {
    update: (data) => ({
      type: UPDATE_VAT_CODE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UPDATE_VAT_CODE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UPDATE_VAT_CODE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  vatCodeDetails: {
    deleteById: (data) => ({
      type: DELETE_VAT_CODE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_VAT_CODE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_VAT_CODE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
