export enum RemitSummaryTableColumn {
  MENU = "menu",
  CREDITOR_NO = "creditorNo",
  CREDITOR_AMOUNT = "creditorAmount",
  BUREAU_AMOUNT = "bureauAmount",
  DEBTOR_AMOUNT = "debtorAmount",
  VAT_AMOUNT = "vatAmount",
  VAT_PAID_BY_DEBTOR = "vatPaidByDebtor",
  VAT_DEDUCTED = "vatDeducted",
  REMIT_CREDITOR = "remitCreditor",
  REMIT_BUREAU = "remitBureau",
  REMIT_DEBTOR = "remitDebtor",
  TOTAL_AMOUNT = "totalAmount",
}

export const REMIT_PROPOSAL_TABLE_COLUMNS = [
  {
    id: 0,
    key: RemitSummaryTableColumn.MENU,
    title: "",
  },
  {
    id: 1,
    key: RemitSummaryTableColumn.CREDITOR_NO,
    title: "CREDITOR_NO",
  },
  {
    id: 2,
    key: RemitSummaryTableColumn.CREDITOR_AMOUNT,
    title: "CREDITOR_AMOUNT",
    align: "right",
  },
  {
    id: 3,
    key: RemitSummaryTableColumn.BUREAU_AMOUNT,
    title: "BUREAU_AMOUNT",
    align: "right",
  },
  {
    id: 4,
    key: RemitSummaryTableColumn.DEBTOR_AMOUNT,
    title: "DEBTOR_AMOUNT",
    align: "right",
  },
  {
    id: 5,
    key: RemitSummaryTableColumn.VAT_AMOUNT,
    title: "VAT_AMOUNT",
    align: "right",
  },
  {
    id: 6,
    key: RemitSummaryTableColumn.VAT_PAID_BY_DEBTOR,
    title: "VAT_PAID_BY_DEBTOR",
    align: "right",
  },
  {
    id: 7,
    key: RemitSummaryTableColumn.VAT_DEDUCTED,
    title: "VAT_DEDUCTED",
    align: "right",
  },
  {
    id: 8,
    key: RemitSummaryTableColumn.REMIT_CREDITOR,
    title: "REMIT_CREDITOR",
    align: "right",
  },
  {
    id: 9,
    key: RemitSummaryTableColumn.REMIT_BUREAU,
    title: "REMIT_BUREAU",
    align: "right",
  },
  {
    id: 10,
    key: RemitSummaryTableColumn.REMIT_DEBTOR,
    title: "REMIT_DEBTOR",
    align: "right",
  },
  {
    id: 11,
    key: RemitSummaryTableColumn.TOTAL_AMOUNT,
    title: "TOTAL_AMOUNT",
    align: "right",
  },
];
