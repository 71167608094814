import React from "react";
import Common from "us.common";
import { ValidationMessageProps } from "./Interfaces";

const { $Alert, $Button } = Common.Components;

/**
 * @description - Show validation message when remit validation `success` or `error`.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2858385457/Remit+Validation+Errors+UI+Design+and+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 08/03/2022
 */
const ValidationMessage: React.FC<ValidationMessageProps> = (props) => {
  const { type, message, buttonTitle, onPress } = props;
  return (
    <$Alert
      message={
        <>
          <div className=" d-flex">
            <span className="mr-5">{message}</span>
            {buttonTitle && onPress && (
              <$Button
                data-testid="btn-view-errors"
                id="btnViewErrors"
                className="font-weight-bold"
                size="small"
                type="link"
                onClick={onPress}
              >
                {buttonTitle}
              </$Button>
            )}
          </div>
        </>
      }
      type={type}
      showIcon
      className="mt-3"
      data-testid="alert-validation"
    />
  );
};

export default ValidationMessage;
