import { ISaveVoucherSequencePayload } from "us.collection.economy/interfaces/ItemTypes";

export function SaveVoucherSequence(this: any): ISaveVoucherSequencePayload {
  return {
    sequenceId: this.sequenceId,
    subSequenceId: this.subSequenceId,
    nextNo: this.nextNo,
    text: this.text,
    prefix: this.prefix,
  };
}
