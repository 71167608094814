import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import "us.collection.economy/components/ClientOrders/Home.scss";
import { Filter, Header, OrderList, OrderGeneration } from "./Components";
import * as Actions from "us.collection.economy/actions";
import {
  INITIAL_FORM_VALUES,
  INITIAL_COMPONENT_VALUES,
} from "us.collection.economy/constants/ClientOrders";
import { COSortType } from "us.collection.economy/components/ClientOrders/Constants";
import { SearchOrders } from "us.collection.economy/components/ClientOrders/Repository";
import { IClientOrders } from "us.collection.economy/interfaces";
import {
  IOrder,
  IComponentValues,
} from "us.collection.economy/components/ClientOrders/Interfaces";
import { ExportOrders } from "./Repository/ExportOrders";

const {
  $Button,
  $Checkbox,
  $Skeleton,
  $Empty,
  $SelectCreditors,
  $Affix,
  $Select,
} = Common.Components;

const Home: React.FC<PropsFromRedux & IClientOrders> = (props) => {
  const { t } = useTranslation();
  const {
    totalOrders,
    totalOrderAmount,
    orders,
    orderTypes,
    isLoadingOrders,
    isLoadingOrdersTypes,
    isGenerateDrawerVisible,
    search,
    sort,
    searchOrderTypes,
    handleGenerateDrawer,
    exportOrder,
    orderExport,
  } = props;

  const [filterValues, setFilterValues] = useState<any>(INITIAL_FORM_VALUES);
  const [componentValues, setComponentValues] = useState<IComponentValues>(
    INITIAL_COMPONENT_VALUES
  );

  useEffect(() => {
    onSearch(INITIAL_FORM_VALUES);
    searchOrderTypes && searchOrderTypes({});
  }, []);

  useEffect(() => {
    if (orderExport.result) {
      setComponentValues({ ...INITIAL_COMPONENT_VALUES, selectedOrders: [] });
    }
  }, [orderExport]);

  /**
   * onSearch
   * search orders API will call with the filter values
   */
  const onSearch = (values: any) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setFilterValues({
      ...filterValues,
      ...values,
      sortType: COSortType.ORDER_NO,
    });
    const params = SearchOrders.call({ ...values, orderTypes: orderTypes });
    setComponentValues({ ...INITIAL_COMPONENT_VALUES, selectedOrders: [] });
    search && search(params);
  };

  // creditor selection and update the UI
  const onSelectCreditors = (values: any) => {
    setComponentValues({ ...componentValues, isCreditorsVisible: false });
    onSearch(values);
  };

  /**
   * @description Update the state when select an order
   */
  const onSelectOrderToExport = (
    isSelected: boolean,
    order: IOrder,
    values: any,
    restProps: any
  ) => {
    try {
      let orderList: Array<IOrder> = componentValues.selectedOrders;
      if (values?.isAllOrders) {
        orderList = orderList.filter(
          ({ orderNo }: IOrder) => orderNo !== order.orderNo
        );
        restProps.setFieldValue("isAllOrders", false);
      } else {
        if (isSelected) {
          // checkbox checked add to list
          orderList.push(order);
          restProps.setFieldValue(
            "isAllOrders",
            orders?.length === orderList.length
          );
        } else {
          // checkbox unchecked remove from the list
          orderList = orderList.filter(
            ({ orderNo }: IOrder) => orderNo !== order.orderNo
          );
        }
      }
      setComponentValues({ ...componentValues, selectedOrders: orderList });
    } catch (error) {
      console.log(error);
    }
  };

  // handle order export
  const onExportOrders = (values: any) => {
    const exportParams = ExportOrders.call({
      selectedOrders: componentValues.selectedOrders,
    });
    const searchParams = SearchOrders.call({
      ...values,
      orderTypes: orderTypes,
    });

    exportOrder && exportOrder({ exportParams, searchParams });
  };

  return (
    <Formik
      initialValues={filterValues}
      onSubmit={onSearch}
      enableReinitialize
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <Header
              restProps={restProps}
              currentDateRange={filterValues.dateRange}
              onHeaderAction={() => handleGenerateDrawer && handleGenerateDrawer({})}
              onSearch={() => {
                restProps.isValid && onSearch(values);
              }}
              onChange={(filters: any) => {
                let dateRange = filters.dateRange;
                if (!filters.dateRange) {
                  dateRange = [
                    moment().startOf("month"),
                    moment().endOf("month"),
                  ];
                }
                restProps.setFieldValue("dateRange", dateRange);
                onSearch({ ...values, ...filters, dateRange });
              }}
              disabled={!values.dateRange}
            />
          </div>
          <div className="orders-layout">
            <Filter
              restProps={restProps}
              totalOrders={totalOrders}
              totalOrderAmount={totalOrderAmount}
              isOrderTypesLoading={isLoadingOrdersTypes}
              values={values}
              onChange={(filters: any) => onSearch({ ...values, ...filters })}
              onSelectCreditors={() =>
                setComponentValues({
                  ...componentValues,
                  isCreditorsVisible: true,
                })
              }
              creditors={values.customers}
              orderTypes={orderTypes}
            />
            <div className="d-flex flex-fill justify-content-center">
              <div>
                <div className="content-order">
                  <$Affix offsetTop={95}>
                    <div className="d-flex pl-2 mb-2 align-items-center bg-white py-2">
                      {!isLoadingOrders && orders.length > 0 && (
                        <div className="d-flex flex-fill justify-content-between">
                          <div className="d-flex mr-2 mt-1">
                            <$Checkbox
                              name="isAllOrders"
                              checked={values.isAllOrders}
                              onChange={(e: any) => {
                                restProps.setFieldValue(
                                  "isAllOrders",
                                  e.target.checked
                                );
                                setComponentValues({
                                  ...componentValues,
                                  selectedOrders: e.target.checked
                                    ? orders
                                    : [],
                                });
                              }}
                            >
                              {t("US.COLLECTION.ECONOMY:ORDERS.ALL")}
                            </$Checkbox>
                            <$Button
                              className="ml-2"
                              size="small"
                              type="primary"
                              data-testid="generate-invoice"
                              disabled={
                                componentValues.selectedOrders.length === 0
                              }
                              onClick={() => onExportOrders(values)}
                              loading={orderExport.isLoading}
                            >
                              {t(
                                "US.COLLECTION.ECONOMY:ORDERS.GENERATE_INVOICE"
                              )}
                            </$Button>
                          </div>
                          <$Select
                            dataTestid="select-sort-type"
                            name="sortType"
                            formitem={{}}
                            size="small"
                            options={[
                              {
                                label: t(
                                  "US.COLLECTION.ECONOMY:ORDERS.ORDER_NO"
                                ),
                                sortType: COSortType.ORDER_NO,
                              },
                              {
                                label: t(
                                  "US.COLLECTION.ECONOMY:ORDERS.CUSTOMER_NO"
                                ),
                                sortType: COSortType.CUSTOMER_NO,
                              },
                              {
                                label: t(
                                  "US.COLLECTION.ECONOMY:ORDERS.ORDER_AMOUNT"
                                ),
                                sortType: COSortType.ORDER_AMOUNT,
                              },
                            ]}
                            optionValue="sortType"
                            optionText="label"
                            defaultValue={COSortType.ORDER_NO}
                            defaultLabel={t(
                              "US.COLLECTION.ECONOMY:ORDERS.ORDER_NO"
                            )}
                            allOption={false}
                            showSearch={false}
                            onChange={sort}
                            style={{ width: 130 }}
                          />
                        </div>
                      )}
                    </div>
                  </$Affix>
                  {isLoadingOrders && (
                    <$Skeleton
                      loading={true}
                      active
                      paragraph={{ rows: 1 }}
                      className="p-3"
                      data-testid="payments-skeleton"
                    />
                  )}
                  {!isLoadingOrders && orders?.length === 0 && (
                    <div className="flex-fill pt-5" data-testid="empty-normal">
                      <$Empty
                        image={$Empty.PRESENTED_IMAGE_SIMPLE}
                        description={t(
                          "US.COLLECTION.ECONOMY:ORDERS.NO_ORDERS_AVAILABLE"
                        )}
                      />
                    </div>
                  )}
                  {!isLoadingOrders && (
                    <OrderList
                      orders={orders}
                      selectedOrders={componentValues.selectedOrders}
                      onSelectOrder={(isSelected: boolean, order: IOrder) =>
                        onSelectOrderToExport(
                          isSelected,
                          order,
                          values,
                          restProps
                        )
                      }
                      filterParams={filterValues}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <$SelectCreditors
            name="customers"
            groupName="customerGroups"
            visible={componentValues.isCreditorsVisible}
            onClose={() =>
              setComponentValues({
                ...componentValues,
                isCreditorsVisible: false,
              })
            }
            onConfirm={(
              customers: Array<number>,
              customerGroups: Array<number>
            ) => onSelectCreditors({ ...values, customers, customerGroups })}
          />

          <OrderGeneration
            visible={isGenerateDrawerVisible}
            searchValues={values}
            onClose={() => handleGenerateDrawer && handleGenerateDrawer({})}
          />
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { common, clientOrders } = state;

  const {
    orders,
    orderTypes,
    totalOrders,
    totalOrderAmount,
    isLoadingOrders,
    isLoadingOrdersTypes,
    orderExport,
    generateOrder,
  } = clientOrders;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;

  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    totalOrders,
    totalOrderAmount,
    isLoadingOrders,
    isLoadingOrdersTypes,
    orders,
    orderTypes,
    orderExport,
    isGenerateDrawerVisible: generateOrder.isDrawerVisible,
  };
};
const { orders, orderTypes, exportOrder, generateOrders } =
  Actions.clientOrders;

const { search, sort } = orders;
const { get } = orderTypes;
const { save } = exportOrder;
const { handleDrawer } = generateOrders;

const mapDispatchToProps = {
  search,
  sort,
  exportOrder: save,
  searchOrderTypes: get,
  handleGenerateDrawer: handleDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
