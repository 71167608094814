import React from "react";
import { Transfer } from "./Transfer";
import { Match } from "./Match";
import { Edit } from "./Edit";
import {
  ActionTypes,
  IActionTypes,
  IVisibleView,
} from "us.collection.economy/components/ManagePayments/interface";
interface Props {
  data: Array<any>;
  mainFilters: any;
  enabledMatch?: boolean;
  enabledTransfer?: boolean;
  enabledEdit?: boolean;
  parentFormValues: any;
  itemCallActionState: IVisibleView;
  visibleAction: IActionTypes | undefined;
}

const ActionView: React.FC<Props> = ({
  data,
  mainFilters,
  visibleAction,
  enabledEdit = false,
  enabledTransfer = false,
  enabledMatch = true,
  itemCallActionState,
  parentFormValues,
}) => {
  return (
    <div className="map-action-wraper  d-flex align-items-start flex-column">
      {visibleAction === ActionTypes.MATCH && enabledMatch && (
        <Match parentFormValues={parentFormValues} />
      )}
      {visibleAction === ActionTypes.EDIT && enabledEdit && (
        <Edit mainFilters={mainFilters} />
      )}
      {visibleAction == ActionTypes.TRANSFER && enabledTransfer && (
        <Transfer
          paymentsData={data}
          mainFilters={mainFilters}
          itemCallActionState={itemCallActionState}
        />
      )}
    </div>
  );
};

export default ActionView;
