import { RemitValidationState } from "us.collection.economy/components/Remit/Constants";
import {
  IRemitProps,
  ICreditorSelectionProps,
} from "us.collection.economy/interfaces";

export const INITIAL_STATE_REMIT: IRemitProps & ICreditorSelectionProps = {
  remitAccount: "",
  validationState: RemitValidationState.VALIDATION,
  bankAccounts: {
    data: {},
    isLoading: false,
  },
  creditors: {
    data: [],
    selected: [],
    backupData: [],
    ineligible: [],
    isLoading: false,
  },
  creditorGroups: {
    data: [],
    isLoading: false,
  },
  remitSummaryByCreditor: {
    data: {
      vatStatus: "",
      remitAccountNo: "",
      remitDetails: [],
      creditor: "",
    },
    isLoading: false,
  },
  remitValidation: {
    data: [],
    isLoading: false,
  },
  remitProposal: {
    data: {
      remitId: "",
      remitRecords: [],
      remitVoucherNo: "",
      journalId: "",
    },
    isLoading: false,
  },
  remitGeneration: {
    data: [],
    isLoading: false,
  },
  remitInvoicing: {
    data: {},
    isLoading: false,
  },
};
