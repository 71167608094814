import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import Common from "us.common";
import { RegisterJournal } from "us.collection.economy/constants/Actions";

const { JournalEntry } = Actions;
const { $MessageBox } = Common.Components;
const { searchLedgerAccounts, saveJournalEntry } = API.registerJournal.journal;
const { validateJournalId, validateCaseId } = API.registerJournal.validate;
const { remits } = API.remitHistory;
const { search } = remits;

const {
  ledgerAccounts,
  remitHistory,
  journalIdValidation,
  caseIdValidation,
  journalEntry,
} = JournalEntry;
const {
  SEARCH_LEDGER_ACCOUNTS,
  SEARCH_REMIT_HISTORY_DETAILS,
  VALIDATE_JOURNAL_ID,
  VALIDATE_CASE_ID,
  REGISTER_JOURNAL_ENTRY,
} = RegisterJournal;

export const JournalEntrySagas = {
  ledgerAccounts: {
    search: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(searchLedgerAccounts, params);
        if (status === 200 && Array.isArray(data)) {
          yield put(ledgerAccounts.success(data));
        } else {
          yield put(ledgerAccounts.success([]));
        }
      } catch (error) {
        yield put(ledgerAccounts.fail(new Error()));
      }
    },
  },
  remitHistory: {
    search: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { remits, status } = yield call(search, params);
        if (status === 200 && Array.isArray(remits)) {
          yield put(remitHistory.success(remits));
        } else {
          yield put(remitHistory.success([]));
        }
      } catch (error) {
        yield put(remitHistory.fail(new Error()));
      }
    },
  },
  validateJournalId: {
    validate: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(validateJournalId, params);
        if (status === 200) {
          yield put(
            journalIdValidation.success({
              ...params,
              status: data.status,
              isLoading: false,
            })
          );
        } else {
          journalIdValidation.success({
            ...params,
            status: false,
            isLoading: false,
          });
        }
      } catch (error) {
        yield put(journalIdValidation.fail(new Error()));
      }
    },
  },
  validateCaseId: {
    validate: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(validateCaseId, params);
        if (status === 200) {
          yield put(
            caseIdValidation.success({
              ...params,
              status: Object.keys(data).length === 0 ? false : true,
              isLoading: false,
            })
          );
        } else {
          yield put(
            caseIdValidation.success({
              ...params,
              status: false,
              isLoading: false,
            })
          );
        }
      } catch (error) {
        yield put(caseIdValidation.fail(new Error()));
      }
    },
  },
  journalEntry: {
    save: function* (action: any) {
      const params = action?.payload?.data.requestObject ?? {};
      try {
        const { data, status } = yield call(saveJournalEntry, params);
        if (status === 201 && data?.status) {
          yield put(journalEntry.success(data));
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:JOURNAL.JOURNAL_ENTRY_REGISTRATION_SUCCESSFUL",
            "",
            ""
          );
        } else {
          yield put(journalEntry.fail(data));
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:JOURNAL.JOURNAL_ENTRY_REGISTRATION_FAIL",
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:JOURNAL.JOURNAL_ENTRY_REGISTRATION_FAIL",
          "",
          ""
        );
        yield put(journalEntry.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(SEARCH_LEDGER_ACCOUNTS, JournalEntrySagas.ledgerAccounts.search),
  takeLatest(
    SEARCH_REMIT_HISTORY_DETAILS,
    JournalEntrySagas.remitHistory.search
  ),
  takeLatest(VALIDATE_JOURNAL_ID, JournalEntrySagas.validateJournalId.validate),
  takeLatest(VALIDATE_CASE_ID, JournalEntrySagas.validateCaseId.validate),
  takeLatest(REGISTER_JOURNAL_ENTRY, JournalEntrySagas.journalEntry.save),
];
