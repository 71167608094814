export enum RemitValidationTableColumn {
  PAYMENT_ID = "paymentId",
  BANK_REFERENCE = "bankReference",
  VOUCHER_NO = "voucherNo",
  VOUCHER_DETAIL_ID = "voucherDetailId",
  PAYMENT_AMOUNT = "paymentAmount",
  APPORTION_AMOUNT = "apportionAmount",
  TRANSACTION_AMOUNT = "arItemAmount",
}

export const REMIT_VALIDATION_TABLE_COLUMNS = [
  {
    key: RemitValidationTableColumn.PAYMENT_ID,
    title: "US.COLLECTION.ECONOMY:REMIT.PAYMENT_ID",
  },
  {
    key: RemitValidationTableColumn.VOUCHER_NO,
    title: "US.COLLECTION.ECONOMY:REMIT.VOUCHER_NO",
  },
  {
    key: RemitValidationTableColumn.PAYMENT_AMOUNT,
    title: "US.COLLECTION.ECONOMY:REMIT.PAYMENT_AMOUNT",
    align: "right",
  },
  {
    key: RemitValidationTableColumn.TRANSACTION_AMOUNT,
    title: "US.COLLECTION.ECONOMY:REMIT.TRANSACTION_AMOUNT",
    align: "right",
  },
  {
    key: RemitValidationTableColumn.APPORTION_AMOUNT,
    title: "US.COLLECTION.ECONOMY:REMIT.APPORTION_AMOUNT",
    align: "right",
  },
  {
    key: RemitValidationTableColumn.VOUCHER_DETAIL_ID,
    title: "US.COLLECTION.ECONOMY:REMIT.VOUCHER_DETAIL_ID",
  },
  {
    key: RemitValidationTableColumn.BANK_REFERENCE,
    title: "US.COLLECTION.ECONOMY:REMIT.BANK_REFERENCE",
  },
];
