import {
  ClientAccountsTableColumn,
  CLIENT_ACCOUNTS_COLUMNS,
  ClientAccountsAction,
} from "us.collection.economy/components/ClientAccounts/Constants";
import { ColumnFilterTypes } from "us.common/components/antd/TableTree/Constants";
import { i18n } from "us.helper";
import { IClientAccountInfo } from "us.collection.economy/components/ClientAccounts/Interfaces";
import { IntlDate } from "us.common/functions";

/**
 * @description - Get dynamic width property value for each column by id
 * @param {string} key - Column key
 * @returns {string} - Width (px) of a column
 */
export const getColumnWidth = (key: string): string => {
  try {
    switch (key) {
      case ClientAccountsTableColumn.MENU:
        return "2%";
      case ClientAccountsTableColumn.ACCOUNT_NO:
      case ClientAccountsTableColumn.ACCOUNT_NAME:
      case ClientAccountsTableColumn.LEDGER_ACCOUNT:
      case ClientAccountsTableColumn.MODIFIED_DATE:
      case ClientAccountsTableColumn.MODIFIED_USER:
        return "15%";
      default:
        return "";
    }
  } catch (error) {
    return "100px";
  }
};

/**
 * @description Get column filter props by key
 * @param {ClientAccountsTableColumn} key - input field name
 * @returns column filters by Key
 */
export const getColumnFilterProps = (key: ClientAccountsTableColumn) => {
  switch (key) {
    case ClientAccountsTableColumn.MENU:
      return {};
    case ClientAccountsTableColumn.MODIFIED_DATE:
      return { customFilter: ColumnFilterTypes.DATE_TIME, showFilters: true };
    case ClientAccountsTableColumn.ACCOUNT_NO:
    case ClientAccountsTableColumn.ACCOUNT_NO:
    case ClientAccountsTableColumn.LEDGER_ACCOUNT:
    case ClientAccountsTableColumn.MODIFIED_USER:
    default:
      return { customFilter: true, showFilters: true };
  }
};

/**
 * @description Get client accounts by accountId
 * @param {Array<any>} clientAccounts - List of client accounts to find by accountId
 * @param {number} accountId - The URL params id to find matching client accounts from the given list
 * @returns {ClientAccounts | undefined} - If find item will return it otherwise return undefined
 */
export const getClientAccountsById = (
  clientAccounts: Array<any>,
  accountId: number
): any | {} => {
  try {
    const matchedRecord = clientAccounts.find(
      (account: any) => account.accountId == accountId
    );
    return matchedRecord ?? {};
  } catch (error) {
    return {};
  }
};

/**
 * @description check form is dirty or not
 * @param {any} clientAccount - client account details
 * @param {any} initialClientAccount - initial client account details
 * @returns {boolean} - if data is changed return true otherwise return false
 */
export const isFormDirty = (
  clientAccount: any,
  initialClientAccount: any
): boolean => {
  try {
    return (
      JSON.stringify(clientAccount) !== JSON.stringify(initialClientAccount)
    );
  } catch (error) {
    console.error(error);
    return true;
  }
};

/**
 * @description format account no before export as a file
 * @param {string} value - value of a account no
 * @returns formatted acoount no
 */
export const formatAccountNo = (value: string): string => {
  try {
    if (value) {
      const trimmedValue = value.trim();
      const size = trimmedValue.length;
      if (size <= 11) {
        if (size >= 7) {
          const firstPart = trimmedValue.substring(0, 4);
          const secondPart = trimmedValue.substring(4, 6);
          const ThirdPart = trimmedValue.substring(6, size);
          return `${firstPart} ${secondPart} ${ThirdPart}`;
        } else if (size < 7 && size > 4) {
          const firstPart = trimmedValue.substring(0, 4);
          const secondPart = trimmedValue.substring(4, size);
          return `${firstPart} ${secondPart}`;
        } else {
          const firstPart = trimmedValue.substring(0, size);
          return `${firstPart}`;
        }
      }
      return value;
    }
    return "";
  } catch (e) {
    return value;
  }
};

/**
 * @description get button title by action
 * @param {boolean} action - action
 * @returns title of the the button
 */
export const getButtonTitle = (action: boolean) => {
  switch (action) {
    case true:
      return "US.COLLECTION.COMMON:COMMON.UPDATE";
    case false:
      return "US.COLLECTION.COMMON:COMMON.SAVE";
    default:
      return "";
  }
};

/**
 * @description get grid data for ecxel export
 * @param {any} clientAccountsList - client account list in the table
 * @param {any} currentLanguage - current language from redux store
 * @param {any} currentDateFormat - current date format from redux store
 * @returns formatted table data for excel export
 */
export const getGridData = (
  clientAccountsList: any,
  currentLanguage: any,
  currentDateFormat: any
): any => {
  const excelHeaderData: any = [];
  const excelBodyData: any = [];

  CLIENT_ACCOUNTS_COLUMNS.map(({ id, key, title }, _index) => {
    if (key != ClientAccountsTableColumn.MENU) {
      excelHeaderData.push(
        i18n.t(`US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.${title}`)
      );
    }
  });

  clientAccountsList.map((rowData: IClientAccountInfo) => {
    const {
      accountNo,
      accountName,
      ledgerAccountName,
      modifiedDate,
      modifiedUser,
    } = rowData;
    excelBodyData.push([
      formatAccountNo(accountNo),
      accountName,
      ledgerAccountName,
      IntlDate(modifiedDate, currentLanguage, currentDateFormat),
      modifiedUser,
    ]);
  });

  return [excelHeaderData, ...excelBodyData];
};

/**
 * @description get drawer title by action
 * @param {ClientAccountsAction} action - action
 * @returns title of the drawer
 */
export const getDrawerTitle = (action: ClientAccountsAction) => {
  switch (action) {
    case ClientAccountsAction.ADD:
      return "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.NEW_BANK_ACCOUNT";
    default:
      return "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.EDIT_BANK_ACCOUNT";
  }
};
