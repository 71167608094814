import moment from "moment";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";

/**
 * @description Get `startDate` and `endDate` of the current month
 */
export const getOrdersMonthDateRange = () => {
  // get start date of current month
  var startDate = moment().startOf("month").format(DATE_FORMAT_FOR_SERVER);

  // get end date of current month
  var endDate = moment().endOf("month").format(DATE_FORMAT_FOR_SERVER);

  // make sure to call toDate() for plain JavaScript date type
  return { startDate, endDate };
};

/**
 * @remarks
 * This method is a part of the {Client order list | Economy Module}.
 * @description - Sort any array of data by object key
 * @param data - Array of data
 * @param key - Sort by key
 * @param type - Whether the sorting is ASCENDING or DESCENDING
 * @returns  An array of sorted data
 */
 export const sortData = (
  data: Array<any>,
  key: string,
  type: "ASC" | "DSC" = "DSC"
): Array<any> => {
  if (data) {
    try {
      return data.sort((a, b) => {
        return type === "DSC" ? b[key] - a[key] : a[key] - b[key];
      });
    } catch (error) {
      return data;
    }
  }
  return [];
};
