import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";
import Common from "us.common";
import { SearchOutlined, PlusOutlined } from "us.icons";
import "us.collection.economy/components/ClientOrders/Home.scss";
import { IHeader } from "./interface";
import { handleGoBack } from "us.helper/utility";

const { $Affix, $PageHeader, $Divider, $Button, $RangePicker } =
  Common.Components;

const Header: React.FC<IHeader> = ({
  disabled,
  restProps,
  currentDateRange,
  onHeaderAction,
  onSearch,
  onChange,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { push } = history;
  const [dates, setDates] = useState<Array<moment.Moment>>([]);
  const { currentDateFormat } = useSelector((state: any) => state.common);

  // set dates that should disabled for selecting more than 30 days
  const disabledDate = (current: any) => {
    // disable future dates from last day of this month
    if (current.isAfter(moment().endOf("month"))) return true;
    // don't disable if no dates selected
    if (!dates || dates.length === 0) {
      return false;
    }
  };

  return (
    <$Affix offsetTop={48}>
      <div className="page-header header-border">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <$PageHeader
              className="px-0"
              onBack={() => handleGoBack(history)}
              title={t("US.COLLECTION.ECONOMY:ORDERS.ORDERS")}
            />
            <$Divider className="bui-devider" type="vertical" />
            <$Button
              type="default"
              size="small"
              data-testid="new-order"
              icon={<PlusOutlined />}
              onClick={() => push("/manual-orders")}
            >
              {t("US.COLLECTION.ECONOMY:ORDERS.NEW_ORDER")}
            </$Button>
            <$Divider className="bui-devider" type="vertical" />
            <$Button
              type="default"
              size="small"
              data-testid="generate-order"
              onClick={() => onHeaderAction()}
            >
              {t("US.COLLECTION.ECONOMY:ORDERS.GENERATE_ORDER")}
            </$Button>
            <$Divider className="bui-devider" type="vertical" />
            <$RangePicker
              name="dateRange"
              size="small"
              picker="date"
              minuteStep={15}
              order={false}
              currentTimeFormat={currentDateFormat}
              onCalendarChange={(val: any) => {
                setDates(val);
              }}
              onOpenChange={(isOpen: boolean) => {
                if (isOpen) {
                  setDates([]);
                  restProps.setFieldValue("dateRange", undefined);
                } else {
                  restProps.setFieldValue("dateRange", currentDateRange);
                }
              }}
              disabledDate={disabledDate}
              onChange={(dateRange: any) => {
                if (dateRange) {
                  onChange({ dateRange });
                }
              }}
            />

            <$Button
              className="mb-1 ml-1"
              type="default"
              size="small"
              data-testid="search-btn"
              disabled={disabled}
              onClick={onSearch}
              icon={<SearchOutlined />}
            />
          </div>
          <div>
            <a
              href={`/manage-client-invoices`}
              target="_blank"
              data-testid="navigate-client-invoices"
            >
              {t("US.COLLECTION.ECONOMY:ORDERS.VIEW_CLIENT_INVOICES")}
            </a>
          </div>
        </div>
      </div>
    </$Affix>
  );
};

export default Header;
