import { IVatCalculation } from "../Interface";
import { DefaultVatStatus } from "../Constants"

export function VatCalculation(this: any): IVatCalculation {
    const { creditorNo } = this.selectedCreditor
    const { vatCode, key } = this.record
    return { 
        vatParams: {
            vatCode,
            vatStatus: DefaultVatStatus, // Status changed due to this issue UBX4-15100
            amount: (this.record[this.relevantProp] * this.value),
            creditorNo
        },
        rowKey: key,
        label: this.label,
        value: this.value
    };
}
