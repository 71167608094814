import {
  initialState,
  initialSelectedArticle,
  initialTableData,
} from "us.collection.economy/reducers/ManualOrders/State";
import { ManualOrders } from "us.collection.economy/constants/Actions";
import {
  updateCaseNoValidation,
  updateTableDataWithSelectedArticleData,
  updateSelectedArticle,
  formattedOrderTableData,
  updateOrderTableWithVat,
  updateTableData,
  getCombinedData
} from "us.collection.economy/components/ManualOrders/Functions/HelperFunctions";
import { isArray } from "lodash";

const {
  GET_ARTICLE_BY_NO,
  GET_ARTICLE_BY_NO_SUCCESS,
  GET_ARTICLE_BY_NO_FAIL,

  GET_ARTICLE_LIST,
  GET_ARTICLE_LIST_SUCCESS,
  GET_ARTICLE_LIST_FAIL,
  CHANGE_ARTICLE_DRAWER,

  SAVE_ORDER_LINE,
  SAVE_ORDER_LINE_SUCCESS,
  SAVE_ORDER_LINE_FAIL,

  VALIDATE_CASE_NO,
  VALIDATE_CASE_NO_SUCCESS,
  VALIDATE_CASE_NO_FAIL,

  RESET_SHOW_MODAL_STATUS,

  EXPORT_ORDER_LINE,
  EXPORT_ORDER_LINE_SUCCESS,
  EXPORT_ORDER_LINE_FAIL,

  RESET_ORDER_DATA,
  UPDATE_ORDER_TABLE_DATA,

  VALIDATE_CREDITOR_BY_ID,
  VALIDATE_CREDITOR_BY_ID_SUCCESS,
  VALIDATE_CREDITOR_BY_ID_FAIL,

  UPDATE_SELECTED_CREDITOR_DATA,

  FILTER_ARTICLE_LIST,

  GET_NEXT_ORDER_ID,
  GET_NEXT_ORDER_ID_SUCCESS,
  GET_NEXT_ORDER_ID_FAIL,

  GET_ORDER_DETAILS,
  GET_ORDER_DETAILS_SUCCESS,
  GET_ORDER_DETAILS_FAIL,

  UPDATE_ORDER_LINE,
  UPDATE_ORDER_LINE_SUCCESS,
  UPDATE_ORDER_LINE_FAIL,

  GET_VAT_AMOUNTS,
  GET_VAT_AMOUNTS_SUCCESS,
  GET_VAT_AMOUNTS_FAIL,

  FILTER_TABLE_DATA,
  DELETE_ORDER_LINE,

  UPDATE_ORDER_TYPE
} = ManualOrders;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_ARTICLE_BY_NO:
      return Object.assign({}, state, {
        articleList: state.articleList,
        isFetching: true,
        isLoadingArticleData: true,
        articleDrawerInfo: {
          ...state.articleDrawerInfo,
          rowKey: payload?.data?.rowKey,
        },
      });
    case GET_ARTICLE_BY_NO_SUCCESS:
      const { data, rowKey, isEligibleForVat } = payload?.data;
      return Object.assign({}, state, {
        articleDrawerInfo: {
          ...state.articleDrawerInfo,
          rowKey: rowKey,
          selectedArticle: { ...data[0], isValidArticle: true },
        },
        orderTableData: updateTableDataWithSelectedArticleData(
          [...state.orderTableData],
          rowKey,
          { ...data[0], isValidArticle: true, isVatCalculated: !isEligibleForVat, user: payload?.data?.user }
        ),
        InitOrderTableData: updateTableDataWithSelectedArticleData(
          [...state.InitOrderTableData],
          rowKey,
          { ...data[0], isValidArticle: true , isVatCalculated: !isEligibleForVat, user: payload?.data?.user }
        ),
        isFetching: false,
        isLoadingArticleData: false,
        isVatCalculating: isEligibleForVat
      });
    case GET_ARTICLE_BY_NO_FAIL:
      return Object.assign({}, state, {
        articleList: [],
        filteredArticleList: [],
        articleDrawerInfo: {
          ...initialState.articleDrawerInfo,
          rowKey: payload?.error?.rowKey,
        },
        orderTableData: updateTableDataWithSelectedArticleData(
          [...state.orderTableData],
          payload?.error?.rowKey,
          { ...initialState.articleDrawerInfo.selectedArticle }
        ),
        InitOrderTableData: updateTableDataWithSelectedArticleData(
          [...state.InitOrderTableData],
          payload?.error?.rowKey,
          { ...initialState.articleDrawerInfo.selectedArticle }
        ),
        isFetching: false,
        isLoadingArticleData: false,
      });
    case GET_ARTICLE_LIST:
      return Object.assign({}, state, {
        articleList: [...state.articleList],
        filteredArticleList: state.filteredArticleList,
        isFetching: true,
        isLoadingArticleData: true,
      });
    case GET_ARTICLE_LIST_SUCCESS:
      return Object.assign({}, state, {
        isFetching: false,
        isLoadingArticleData: false,
        articleList: [...payload?.data],
        filteredArticleList: payload?.data,
      });
    case GET_ARTICLE_LIST_FAIL:
      return Object.assign({}, state, {
        articleList: [],
        filteredArticleList: [],
        isFetching: false,
        isLoadingArticleData: false,
      });
    case CHANGE_ARTICLE_DRAWER:
      const { selectedArticle } = payload?.data;
      return Object.assign({}, state, {
        articleDrawerInfo: payload?.data,
        orderTableData:
          selectedArticle.articleId !== 0
            ? updateSelectedArticle(
                [...state.orderTableData],
                { ...selectedArticle },
                payload?.data?.rowKey
              )
            : [...state.orderTableData],
        InitOrderTableData:
          selectedArticle.articleId !== 0
            ? updateSelectedArticle(
                [...state.InitOrderTableData],
                { ...selectedArticle },
                payload?.data?.rowKey
              )
            : [...state.InitOrderTableData],
      });
    case SAVE_ORDER_LINE:
      return Object.assign({}, state, {
        lastOrderLineInfo: { saveAndInvoice: false },
        isSavingOrder: true,
      });
    case SAVE_ORDER_LINE_SUCCESS:
      return Object.assign({}, state, {
        lastOrderLineInfo: payload?.data,
        isSavingOrder: false,
        isSaveSuccess: true,
      });
    case SAVE_ORDER_LINE_FAIL:
      return Object.assign({}, state, {
        lastOrderLineInfo: { saveAndInvoice: false },
        isSavingOrder: false,
      });
    case VALIDATE_CASE_NO:
      return Object.assign({}, state, {
        validationCaseNoResult: {},
        validatingCaseNo: true
      });
    case VALIDATE_CASE_NO_SUCCESS:
      const { validationResults } = payload?.data;
      return Object.assign({}, state, {
        validationCaseNoResult: validationResults,
        orderTableData: updateCaseNoValidation(
          state.orderTableData,
          payload?.data
        ),
        InitOrderTableData: updateCaseNoValidation(
          state.InitOrderTableData,
          payload?.data
        ),
        validatingCaseNo: false
      });
    case VALIDATE_CASE_NO_FAIL:
      return Object.assign({}, state, {
        validationCaseNoResult: {},
        validatingCaseNo: false
      });
    case RESET_SHOW_MODAL_STATUS:
      return Object.assign({}, state, {
        lastOrderLineInfo: {
          ...state.lastOrderLineInfo,
          saveAndInvoice: payload?.data,
        },
      });
    case EXPORT_ORDER_LINE:
      return Object.assign({}, state, {
        exportedResult: {},
        isExportSuccess: true,
      });
    case EXPORT_ORDER_LINE_SUCCESS:
      return Object.assign({}, state, {
        exportedResult: payload?.data,
        isExportSuccess: false,
        lastOrderLineInfo: {
          ...state.lastOrderLineInfo,
          saveAndInvoice: false,
        },
      });
    case EXPORT_ORDER_LINE_FAIL:
      return Object.assign({}, state, {
        exportedResult: {},
        isExportSuccess: false,
      });
    case RESET_ORDER_DATA:
      return Object.assign({}, state, {
        ...initialState,
        orderTableData: [{ ...initialTableData }],
        InitOrderTableData: [{ ...initialTableData }],
        nextOrderId: state.nextOrderId
      });
    case UPDATE_ORDER_TABLE_DATA:
      const { key, value, type, isVatCalculated, articleValidation, user } = payload?.data;
      return Object.assign({}, state, {
        orderTableData: isArray(payload?.data)
          ? [...state.orderTableData, payload?.data[0]]
          : updateTableData([...state.orderTableData], key, value, type, isVatCalculated,articleValidation, user),
        InitOrderTableData: isArray(payload?.data)
          ? [...state.InitOrderTableData, payload?.data[0]]
          : updateTableData(state.InitOrderTableData, key, value, type, isVatCalculated, articleValidation, user),
      });
    case DELETE_ORDER_LINE:
      return Object.assign({}, state, {
        orderTableData: state.orderTableData.filter((order:any) => order.key != payload?.data),
        InitOrderTableData: state.InitOrderTableData.filter((order:any) => order.key != payload?.data),
      });
    case FILTER_TABLE_DATA:
      return Object.assign({}, state, {
        orderTableData: [...payload?.data],
      });
    case VALIDATE_CREDITOR_BY_ID:
      return Object.assign({}, state, {
        validateCreditorDetailsLoading: true,
      });
    case VALIDATE_CREDITOR_BY_ID_SUCCESS:
      const {
        entRoleId,
        creditorVATStatus,
      } = payload?.data;
      return Object.assign({}, state, {
        validateCreditorDetails: {
          selectedCreditor: {
            ...payload?.data,
            address: getCombinedData(payload?.data, "address"),
            creditorName: getCombinedData(payload?.data, "name"),
            creditorVATStatus,
          },
          customers: Number(entRoleId),
          customerGroups: ["-1"],
          customerData: [payload?.data],
        },
        validateCreditorDetailsLoading: false,
      });
    case VALIDATE_CREDITOR_BY_ID_FAIL:
      return Object.assign({}, state, {
        validateCreditorDetails: initialState.validateCreditorDetails,
        validateCreditorDetailsLoading: false,
      });
    case UPDATE_SELECTED_CREDITOR_DATA:
      return Object.assign({}, state, {
        validateCreditorDetails: payload?.data,
      });
    case FILTER_ARTICLE_LIST:
      return Object.assign({}, state, {
        filteredArticleList: payload?.data,
        isFetching: true,
      });
    case GET_NEXT_ORDER_ID:
      return Object.assign({}, state, {
        nextOrderId: 0,
        nextOrderIdLoading: true,
      });
    case GET_NEXT_ORDER_ID_SUCCESS:
      return Object.assign({}, state, {
        nextOrderId: payload?.data,
        nextOrderIdLoading: false,
      });
    case GET_NEXT_ORDER_ID_FAIL:
      return Object.assign({}, state, {
        nextOrderId: 0,
        nextOrderIdLoading: false,
      });
    case GET_ORDER_DETAILS:
      return Object.assign({}, state, {
        orderTableData: state.orderTableData,
        InitOrderTableData: state.InitOrderTableData,
        isOrderDetailsLoading: true,
        validateCreditorDetails: initialState.validateCreditorDetails
      });
    case GET_ORDER_DETAILS_SUCCESS:
      const {
        customerName,
        customerNo,
        countryId,
        zipCode,
        zipName,
        orderNo,
        customerId,
        orderlines,
      } = payload?.data;
      const selectedCreditor = {
        address1: payload?.data?.address1,
        address2: payload?.data?.address2,
        address3: payload?.data?.address3,
        countryId: countryId,
        creditorId: customerId,
        creditorName: customerName,
        groupId: 0,
        groupName: "",
        id: "",
        masterCreditorId: "",
        masterCreditorName: "",
        nin: "",
        pid: customerNo,
        zipCode: zipCode,
        zipName: zipName,
        address: getCombinedData(payload?.data, 'address')
      };
      return Object.assign({}, state, {
        validateCreditorDetails: {
          selectedCreditor: selectedCreditor,
          customers: customerId,
          customerGroups: ["-1"],
          customerData: [payload?.data],
        },
        orderTableData: formattedOrderTableData(orderlines),
        InitOrderTableData: formattedOrderTableData(orderlines),
        initialFormattedTableData: formattedOrderTableData(orderlines),
        nextOrderId: orderNo,
        isOrderDetailsLoading: false,
        existingOrderData: payload?.data,
        articleDrawerInfo: {
          ...state.articleDrawerInfo,
          rowKey: 0,
          selectedArticle: { ...state.articleDrawerInfo.selectedArticle, isValidArticle: true, articleNo: orderlines.length > 0 ? orderlines[0].articleNo : ''},
        },
      });
    case GET_ORDER_DETAILS_FAIL:
      return Object.assign({}, state, {
        orderTableData: state.orderTableData,
        InitOrderTableData: state.InitOrderTableData,
        isOrderDetailsLoading: false,
      });
    case UPDATE_ORDER_LINE:
      return Object.assign({}, state, {
        isSavingOrder: true,
      });
    case UPDATE_ORDER_LINE_SUCCESS:
      return Object.assign({}, state, {
        lastOrderLineInfo: payload?.data,
        isSavingOrder: false,
      });
    case UPDATE_ORDER_LINE_FAIL:
      return Object.assign({}, state, {
        isSavingOrder: false,
      });
    case GET_VAT_AMOUNTS:
      return Object.assign({}, state, {
        isFetching: true,
        isVatCalculating: true
      });
    case GET_VAT_AMOUNTS_SUCCESS:
      return Object.assign({}, state, {
        orderTableData: updateOrderTableWithVat(
          [...state.orderTableData],
          payload?.data
        ),
        InitOrderTableData: updateOrderTableWithVat(
          [...state.InitOrderTableData],
          payload?.data
        ),
        isFetching: false,
        isVatCalculating: false
      });
    case GET_VAT_AMOUNTS_FAIL:
      return Object.assign({}, state, {
        isFetching: false,
        isVatCalculating: false,
        orderTableData: state.orderTableData.map(({ isVatCalculated, ...rest }: any) => ({
          ...rest,
          isVatCalculated: true,
        })),
        InitOrderTableData: state.InitOrderTableData.map(({ isVatCalculated, ...rest }: any) => ({
          ...rest,
          isVatCalculated: true,
        }))
      });
    case UPDATE_ORDER_TYPE:
      return Object.assign({}, state, {
        existingOrderData: {...state.existingOrderData, orderType: payload?.data === 'V' ? 'VAT' : 'CLIENT'}
      });
    default:
      return state;
  }
};
