import { ClientAccounts } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";
import { IClientAccountsAction } from "us.collection.economy/interfaces";

const {
  GET_CLIENT_ACCOUNTS,
  GET_CLIENT_ACCOUNTS_SUCCESS,
  GET_CLIENT_ACCOUNTS_FAIL,
  ADD_CLIENT_NEW_ACCOUNT,
  ADD_CLIENT_NEW_ACCOUNT_SUCCESS,
  ADD_CLIENT_NEW_ACCOUNT_FAIL,
  UPDATE_CLIENT_ACCOUNT,
  UPDATE_CLIENT_ACCOUNT_SUCCESS,
  UPDATE_CLIENT_ACCOUNT_FAIL,
  GET_INIT_DETAILS,
  GET_INIT_DETAILS_SUCCESS,
  GET_INIT_DETAILS_FAIL,
  VALIDATE_ACCOUNT_NUMBER,
  VALIDATE_ACCOUNT_NUMBER_SUCCESS,
  VALIDATE_ACCOUNT_NUMBER_FAIL,
  RESET_ACCOUNT_VALIDATION,
  VALIDATE_JOURNAL_PREFIX,
  VALIDATE_JOURNAL_PREFIX_SUCCESS,
  VALIDATE_JOURNAL_PREFIX_FAIL,
  RESET_JOURNAL_PREFIX_VALIDATION,
  DELETE_CLIENT_ACCOUNT,
  DELETE_CLIENT_ACCOUNT_SUCCESS,
  DELETE_CLIENT_ACCOUNT_FAIL,
  RESET_SAVE_UPDATE_DATA
} = ClientAccounts;



export const clientAccounts: Readonly<IAPIAction & IClientAccountsAction> = {
  accounts: {
    get: (data) => ({
      type: GET_CLIENT_ACCOUNTS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_CLIENT_ACCOUNTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_CLIENT_ACCOUNTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  newClientAccount: {
    save: (data) => ({
      type: ADD_CLIENT_NEW_ACCOUNT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_CLIENT_NEW_ACCOUNT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_CLIENT_NEW_ACCOUNT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  existingClientAccount: {
    update: (data) => ({
      type: UPDATE_CLIENT_ACCOUNT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: UPDATE_CLIENT_ACCOUNT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: UPDATE_CLIENT_ACCOUNT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  initDetails: {
    get: (data) => ({
      type: GET_INIT_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_INIT_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_INIT_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_SAVE_UPDATE_DATA,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  accountNumber: {
    getById: (id) => ({
      type: VALIDATE_ACCOUNT_NUMBER,
      payload: {
        id,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: VALIDATE_ACCOUNT_NUMBER_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: VALIDATE_ACCOUNT_NUMBER_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_ACCOUNT_VALIDATION,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  journalPrefix: {
    getById: (id) => ({
      type: VALIDATE_JOURNAL_PREFIX,
      payload: {
        id,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: VALIDATE_JOURNAL_PREFIX_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: VALIDATE_JOURNAL_PREFIX_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_JOURNAL_PREFIX_VALIDATION,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  bankAccount: {
    deleteById: (data) => ({
      type: DELETE_CLIENT_ACCOUNT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DELETE_CLIENT_ACCOUNT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DELETE_CLIENT_ACCOUNT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
