import { IDownloadFile } from "us.collection.economy/interfaces";

export function DownloadRemitFile(this: any): IDownloadFile {
  return {
    fileType: "REMIT",
    fileSubType: "completed",
    fileName: this.fileName ? this.fileName?.split(',') : [],
    referenceId: [],
  };
}
