import moment from "moment";
import { ISearchRemitHistory, RemitHistorySearchType } from "../Interfaces";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";

export function SearchRemitHistory(this: any): ISearchRemitHistory {
  const [startDate, endDate] = this.remitDateRange;
  return {
    startDate: moment(startDate).startOf("day").format(DATE_FORMAT_FOR_SERVER),
    endDate: moment(endDate).endOf("day").format(DATE_FORMAT_FOR_SERVER),
    remitFileStatus: this.remitFileStatus,
    accountNo: this.accountNo,
    journalId: this.journalIdText,
    searchType: this.journalIdText
      ? RemitHistorySearchType.JOURNAL_ID
      : RemitHistorySearchType.FILE_DATE,
    offset: this.offset,
    limit: this.limit,
  };
}
