import { IClientAccountsSave } from "us.collection.economy/interfaces/ClientAccounts";

export function SaveClientAccounts(this: any): IClientAccountsSave {
  return {
    accountId: this.accountId,
    accountNo: this.accountNo.replace(/ /g, ""),
    accountName: this.accountName,
    remitJournalPrifix: this.remitJournalPrifix,
    remitJournalSequence: this.remitJournalSequence,
    remitVoucherSequence: this.remitVoucherSequence,
    remitTransactionSequence: this.remitTransactionSequence,
    remitDaySequence: this.remitDaySequence,
    ledgerAccountNumber: this.ledgerAccountNumber,
    ledgerVoucherSequence: this.ledgerVoucherSequence,
    isConsideredForOrderlines: this.isConsideredForOrderlines,
    enterpriseNumber: this.enterpriseNumber,
  };
}
