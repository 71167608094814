import React, { useEffect, useRef } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { IRootState } from "us.collection/interfaces";
import * as Actions from "us.collection.economy/actions";
import {
  SaveProvisionProfile,
  SearchProvisionProfiles,
} from "../../Repository";
import { RangesTable } from "./Components";
import { DebtorType } from "us.collection.economy/constants";
import { DrawerType } from "../../Constants";
import { ProvisionProfileValidationSchema } from "../../Validations";
import { InfoCircleOutlined, LoadingOutlined } from "us.icons";
import { IProfileDetails } from "../../Interfaces";
import { isFormDirty, isMaximumAmountsDefined } from "../../Functions";
import _ from "lodash";
import { IntlCurrency } from "us.common/functions";

const {
  $Button,
  $Tabs,
  $Form,
  $Switch,
  $InputAmount,
  $Skeleton,
  $AsyncInput,
  $Popconfirm,
} = Common.Components;

/**
 * @description - Provision profiles drawer component to show profile details and for update, add new
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2985361423/Provision+Profiles+UI+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 23/05/2022
 */
const ProfileDetails: React.FC<PropsFromRedux & IProfileDetails> = (props) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const profileId = urlParams.get("profile-id");
  const type = urlParams.get("type");

  const activeRef = useRef<any>();
  const inputAmountRef = useRef<any>();
  const inputInterestRef = useRef<any>();

  const {
    formValues,
    currentCurrency,
    currentLanguage,
    profileDetails,
    profileNameValidation,
    resetProfileDetails,
    getProfileDetails,
    saveProfile,
    addProfile,
    validateProfileName,
    onCancel,
  } = props;

  useEffect(() => {
    if (profileId) {
      getProfileDetails && getProfileDetails({ profileId });
    }
    return () => {
      resetProfileDetails && resetProfileDetails({});
    };
  }, [profileId]);

  useEffect(() => {
    if (
      !profileDetails.isLoading &&
      profileDetails.data.profileName &&
      !profileNameValidation.validated
    ) {
      if (type === DrawerType.COPY_PROFILE) {
        const profileName = profileDetails.data.profileName;
        validateProfileName && validateProfileName({ profileName });
      }
    }
  }, [profileDetails]);

  /**
   * If validateProfileName is truthy, then call validateProfileName with the profileName argument.
   * @param {string} profileName - string - This is the name of the profile that the user is trying to
   * create.
   */
  const onValidateProfileName = (profileName: string) => {
    validateProfileName && validateProfileName({ profileName });
  };

  /**
   * @description Handle submit button action
   * @param {any} values
   */
  const handleSubmit = (values: any, actions: any) => {
    if (actions.isValidating || profileNameValidation.isLoading) return;
    actions.setSubmitting(true);

    const saveParams = SaveProvisionProfile.call({
      values,
      orderLines: profileDetails.data,
    });

    const searchParams = SearchProvisionProfiles.call(formValues);

    if (type === DrawerType.NEW_PROFILE || type === DrawerType.COPY_PROFILE) {
      // if add new or copy and add new one then call add profile
      addProfile && addProfile({ saveParams, searchParams });
    } else {
      // update the existing profile details
      saveProfile && saveProfile({ saveParams, searchParams });
    }
  };

  return (
    <$Skeleton
      loading={profileDetails.isLoading}
      active
      paragraph={{
        rows: 2,
      }}
    >
      <Formik
        initialValues={{
          ...profileDetails.data,
        }}
        enableReinitialize
        validateOnChange
        validateOnBlur
        validationSchema={ProvisionProfileValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          resetForm,
          ...restProps
        }: any) => (
          <>
            <$Form layout="vertical">
              <div id="form-content" className="d-flex">
                <div className="mr-3">
                  <$AsyncInput
                    name="profileName"
                    label={t("US.COLLECTION.ECONOMY:PROVISION_PROFILES.NAME")}
                    dataTestid="async-input-profile-name"
                    placeholder={t(
                      "US.COLLECTION.ECONOMY:PROVISION_PROFILES.NAME"
                    )}
                    size="small"
                    required={true}
                    autoFocus={!values.profileName}
                    suffix={
                      profileNameValidation.isLoading ? (
                        <LoadingOutlined style={{ fontSize: 12 }} spin />
                      ) : undefined
                    }
                    disabled={profileNameValidation.isLoading}
                    onBlur={(e: any) => {
                      if (e.target.value !== "" && e.target.value != 0) {
                        if (type == DrawerType.COPY_PROFILE) {
                          onValidateProfileName(e.target.value);
                        } else {
                          profileDetails.initialData?.profileName?.toLowerCase() !==
                            values?.profileName.toLowerCase() &&
                            onValidateProfileName(e.target.value);
                        }
                      }
                    }}
                    isValid={profileNameValidation.data?.status}
                    asyncError={t(
                      "US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_NAME"
                    )}
                    onKeyDown={(e: any) => {
                      if (e.key == "Tab") {
                        window.setTimeout(function () {
                          typeof inputAmountRef?.current !== "undefined" &&
                            inputAmountRef.current.focus();
                        }, 100);
                      }
                    }}
                  />
                </div>
                <div className="mr-3">
                  <$InputAmount
                    {...restProps}
                    label={t(
                      "US.COLLECTION.ECONOMY:PROVISION_PROFILES.MAXIMUM_MAIN_AMOUNT_PROVISION"
                    )}
                    placeholder="0,00"
                    className="w-100"
                    size="small"
                    min={0}
                    value={values.maximumMainAmountProvision}
                    name="maximumMainAmountProvision"
                    currentLanguage={currentLanguage}
                    currentCurrency={currentCurrency}
                    ref={inputAmountRef}
                    onKeyDown={(e: any) => {
                      if (e.key == "Tab") {
                        window.setTimeout(function () {
                          typeof inputInterestRef?.current !== "undefined" &&
                            inputInterestRef.current.focus();
                        }, 100);
                      }
                    }}
                    tooltip={{
                      title: `${t(
                        "US.COLLECTION.ECONOMY:PROVISION_PROFILES.WHEN_THE_MAX_AMOUNTS_ARE_SET_TO"
                      )} ${IntlCurrency(
                        0,
                        currentLanguage,
                        currentCurrency
                      )} ${t(
                        "US.COLLECTION.ECONOMY:PROVISION_PROFILES.RESPECTIVE_PROVISION_AMOUNT_WILL_BE"
                      )} ${IntlCurrency(0, currentLanguage, currentCurrency)}`,
                      icon: <InfoCircleOutlined />,
                    }}
                  />
                </div>
                <div className="mr-3">
                  <$InputAmount
                    {...restProps}
                    label={t(
                      "US.COLLECTION.ECONOMY:PROVISION_PROFILES.MAXIMUM_INTEREST_PROVISION"
                    )}
                    placeholder="0,00"
                    className="w-100"
                    size="small"
                    min={0}
                    value={values.maximumInterestProvision}
                    name="maximumInterestProvision"
                    currentLanguage={currentLanguage}
                    currentCurrency={currentCurrency}
                    ref={inputInterestRef}
                    onKeyDown={(e: any) => {
                      if (e.key == "Tab") {
                        window.setTimeout(function () {
                          typeof activeRef?.current !== "undefined" &&
                            activeRef.current.focus();
                        }, 100);
                      }
                    }}
                  />
                </div>
                <div className="mt-4">
                  <div className="d-flex flex-1 align-items-top">
                    <div className="mr-3">
                      <$Switch
                        name="isActive"
                        checked={values.isActive}
                        disabled={values.isUsed}
                        ref={activeRef}
                      />
                    </div>
                    <div>{t("US.COLLECTION.COMMON:COMMON.ACTIVE")}</div>
                  </div>
                </div>
              </div>

              <div className="mt-4">
                <$Tabs>
                  <$Tabs.TabPane
                    tab={t("US.COLLECTION.ECONOMY:PROVISION_PROFILES.PERSON")}
                    key={0}
                  >
                    <RangesTable
                      debtorType={DebtorType.PERSON}
                      restProps={restProps}
                    />
                  </$Tabs.TabPane>

                  <$Tabs.TabPane
                    tab={t("US.COLLECTION.ECONOMY:PROVISION_PROFILES.COMPANY")}
                    key={1}
                  >
                    <RangesTable
                      debtorType={DebtorType.COMPANY}
                      restProps={restProps}
                    />
                  </$Tabs.TabPane>
                </$Tabs>
              </div>
            </$Form>

            <div className="drawer-footer-fixed align-content-center justify-content-end">
              <div>
                <$Popconfirm
                  title={
                    isMaximumAmountsDefined(values)
                      ? t("COMMON.SURE_TO_PROCEED_?")
                      : `${t(
                          "US.COLLECTION.ECONOMY:PROVISION_PROFILES.PROVISION_WILL_NOT_BE_APPLIED_WHEN_THE_MAXIMUM_AMOUNTS_ARE"
                        )}${IntlCurrency(0, currentLanguage, currentCurrency)}.`
                  }
                  placement="topLeft"
                  okText={t("COMMON.CONTINUE")}
                  cancelText={t("COMMON.NO")}
                  onConfirm={handleSubmit}
                >
                  <$Button
                    id="btnSave"
                    className="ml-3 mr-2"
                    type="primary"
                    loading={profileDetails.isSaving}
                    disabled={
                      !profileNameValidation.data?.status ||
                      !restProps.isValid ||
                      !isFormDirty(
                        {
                          ...values,
                          profileDetailsPerson:
                            profileDetails.data.profileDetailsPerson,
                          profileDetailsCompany:
                            profileDetails.data.profileDetailsCompany,
                        },
                        profileDetails.initialData
                      ) ||
                      isSubmitting
                    }
                  >
                    {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                  </$Button>
                </$Popconfirm>
                <$Button id="btnCancelDrawer" onClick={onCancel}>
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              </div>
            </div>
          </>
        )}
      </Formik>
    </$Skeleton>
  );
};
const mapStateToProps = (state: IRootState) => {
  const { provisionProfiles, common } = state;
  const { currentLanguage, currentCurrency } = common;
  const { profiles, profileDetails, newProfile, profileNameValidation } =
    provisionProfiles;

  return {
    currentLanguage,
    currentCurrency,
    profiles,
    profileDetails,
    newProfile,
    profileNameValidation,
  };
};

const { provisionProfiles } = Actions;

const mapDispatchToProps = {
  resetProfileDetails: provisionProfiles.profileDetails.reset,
  getProfileDetails: provisionProfiles.profileDetails.get,
  saveProfile: provisionProfiles.profile.save,
  addProfile: provisionProfiles.newProfile.save,
  validateProfileName: provisionProfiles.profileName.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProfileDetails);
