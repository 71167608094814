import { PaymentStates } from "../../constants/Payments";

const sumBy = (data: any[], key: string) => {
  return data.reduce((sum, currentValue) => {
    return sum + currentValue[key];
  }, 0);
};

export const groupBy = (data: any[], key: string) => {
  return data.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(
      currentValue
    );
    return result;
  }, {});
};

export const isArrayMatch = (arr1: string | any[], arr2: string | any[]) => {
  // Check if the arrays are the same length
  if (arr1.length !== arr2.length) return false;

  // Check if all items exist and are in the same order
  for (var i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) return false;
  }

  // Otherwise, return true
  return true;
};

export const formatPaymentDistribution = (data: Array<any>, payment: any) => {
  // return if only exceeded and unknown
  if (
    [PaymentStates.EXCEEDED, PaymentStates.UNKNOWN].includes(
      payment.paymentStatus
    ) &&
    !Array.isArray(data)
  ) {
    return payment;
  }

  // check combinations
  let groupedByStatus = groupBy(
    Array.isArray(data) ? data : [],
    "paymentStatus"
  );
  let groupedByStatusKeys = Object.keys(groupedByStatus);
  console.log("groupedByStatusKeys => ", groupedByStatusKeys);

  if (
    isArrayMatch(groupedByStatusKeys, [
      PaymentStates.EXCEEDED,
      PaymentStates.MAPPED,
      PaymentStates.RETURNED,
    ])
  ) {
    // mapped with exceeded and return to debtor
  } else if (
    isArrayMatch(groupedByStatusKeys, [
      PaymentStates.EXCEEDED,
      PaymentStates.MAPPED,
    ])
  ) {
    // mapped with exceeded
  } else if (
    isArrayMatch(groupedByStatusKeys, [
      PaymentStates.RETURNED,
      PaymentStates.MAPPED,
    ])
  ) {
    // mapped with return to debtor

    let groupedByCaseNo = groupBy(
      groupedByStatus[PaymentStates.MAPPED],
      "caseNo"
    );
    let cases: { caseNo: string; mainAmount: any; items: any }[] = [];
    let groupedByCaseNoKeys = Object.keys(groupedByCaseNo);

    groupedByCaseNoKeys.map((key: string) => {
      let obj = {
        caseNo: key,
        mainAmount: sumBy(groupedByCaseNo[key], "amount"),
        items: groupedByCaseNo[key],
      };
      cases.push(obj);
    });

    let mapped = {
      amount: sumBy(groupedByStatus[PaymentStates.MAPPED], "amount"),
      cases: cases,
    };
    return {
      ...payment,
      items: groupedByStatus[PaymentStates.RETURNED],
      mapped: mapped,
    };
  } else if (isArrayMatch(groupedByStatusKeys, [PaymentStates.MAPPED])) {
    // mapped
    return { ...payment, items: data };
  } else if (isArrayMatch(groupedByStatusKeys, [PaymentStates.RETURNED])) {
    // return to debtor
    return { ...payment, items: data };
  }

  // if (!groupedByStatus.hasOwnProperty(PaymentStates.EXCEEDED)) {
  // 	let exceeded = groupedByStatus[PaymentStates.EXCEEDED];
  // 	result = { ...result, exceeded: exceeded };
  // 	if (groupedByStatus.hasOwnProperty(PaymentStates.MAPPED)) {
  // 		let groupByStatus = groupedByStatus[PaymentStates.MAPPED];
  // 		let groupByCaseNo = groupBy(
  // 			Array.isArray(groupByStatus) ? groupByStatus : [],
  // 			'caseNo'
  // 		);
  // 		console.log('Mapped ==> groupByStatus :', groupByStatus);
  // 		console.log('Mapped ==> groupByCaseId :', groupByCaseNo);

  // 		let cases = Object.keys(groupByCaseNo);
  // 		cases.map((c: any) => {
  // 			let caseObj = {
  // 				caseNo: c,
  // 				mainAmount: sumBy(groupByCaseNo[c], 'amount'),
  // 				items: groupByCaseNo[c],
  // 			};
  // 			console.log('Group by Case No Item ==> ', caseObj);
  // 		});

  // 		let obj = {
  // 			amount: sumBy(groupByStatus, 'amount'),
  // 			cases: groupByStatus,
  // 		};
  // 		let obj2 = {};
  // 		result = { ...result, mapped: obj };
  // 	}
  // 	if (groupedByStatus.hasOwnProperty(PaymentStates.RETURN_TO_DEBTOR)) {
  // 		//
  // 		let returnToDebtor = groupedByStatus[PaymentStates.RETURN_TO_DEBTOR];
  // 		console.log('Return to Debtor ==> ', returnToDebtor);
  // 		result = { ...result, returnToDebtor: returnToDebtor };
  // 		if (groupedByStatus.hasOwnProperty(PaymentStates.MAPPED)) {
  // 			let mapped = groupedByStatus[PaymentStates.MAPPED];
  // 			console.log('Mapped ==> ', mapped);
  // 			let obj = {
  // 				amount: sumBy(mapped, 'amount'),
  // 				cases: mapped,
  // 			};
  // 			let obj2 = {};
  // 			result = { ...result, mapped: obj };
  // 		}
  // 	}
  // } else if (groupedByStatus.hasOwnProperty(PaymentStates.RETURN_TO_DEBTOR)) {
  // 	let returnToDebtor = groupedByStatus[PaymentStates.RETURN_TO_DEBTOR];
  // 	console.log('Return to Debtor ==> ', returnToDebtor);
  // 	result = { ...result, returnToDebtor: returnToDebtor };
  // 	if (groupedByStatus.hasOwnProperty(PaymentStates.MAPPED)) {
  // 		let mapped = groupedByStatus[PaymentStates.MAPPED];
  // 		console.log('Mapped ==> ', mapped);
  // 		let obj = {
  // 			amount: sumBy(mapped, 'amount'),
  // 			cases: mapped,
  // 		};
  // 		let obj2 = {};
  // 		result = { ...result, mapped: obj };
  // 	}
  // } else if (groupedByStatus.hasOwnProperty(PaymentStates.MAPPED)) {
  // 	let mapped = groupedByStatus[PaymentStates.MAPPED];
  // 	console.log('Mapped ==> ', mapped);
  // 	let obj = {
  // 		amount: sumBy(mapped, 'amount'),
  // 		cases: mapped,
  // 	};
  // 	let obj2 = {};
  // 	result = { ...result, mapped: obj };
  // } else if (groupedByStatus.hasOwnProperty(PaymentStates.UNKNOWN)) {
  // 	let unknown = groupedByStatus[PaymentStates.UNKNOWN];
  // 	console.log('Unknown ==> ', unknown);
  // 	result = { ...result, unknown: unknown };
  // }
  // return result;
};

export const getBaseHeaderAmount = (data: {
  exceededSum: number;
  returnedToDebtorSum: number;
  mappedSum: number;
  unknownSum: number;
}) => {
  if (data.exceededSum > 0) {
    return data.exceededSum;
  } else if (data.returnedToDebtorSum > 0) {
    return data.returnedToDebtorSum;
  } else if (data.mappedSum > 0) {
    return data.mappedSum;
  } else if (data.unknownSum > 0) {
    return data.unknownSum;
  } else {
    return 0;
  }
};

export const getBaseHeaderState = (data: {
  exceededSum: number;
  returnedToDebtorSum: number;
  mappedSum: number;
  unknownSum: number;
}) => {
  if (data.exceededSum > 0) {
    return PaymentStates.EXCEEDED;
  } else if (data.returnedToDebtorSum > 0) {
    return PaymentStates.RETURNED;
  } else if (data.mappedSum > 0) {
    return PaymentStates.MAPPED;
  } else if (data.unknownSum > 0) {
    return PaymentStates.UNKNOWN;
  } else {
    return "";
  }
};
