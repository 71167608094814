import _, { isArray } from "lodash";
import { PaymentStates } from "../../constants/Payments";

/**
 * @param payments: any data array, array of data that needs to convert it's hierarchy.
 * @returns: Converted payment array that can be used for paymnet management TableTree.
 *  @name: @MAPPED @EXCEEDED @UNKNOWN @RETURNED : Payment types
 */
const { EXCEEDED, MAPPED, UNKNOWN, RETURNED } = PaymentStates;

export const formatPayments = (payments: Array<any>) => {
  try {
    let newPayments = [];

    const getObj = (type: string) => {
      let obj = {};
      const types = [MAPPED, EXCEEDED, UNKNOWN, RETURNED];

      const totals = {
        mpedTotal: calcTot(payments, MAPPED, type),
        exedTotal: calcTot(payments, EXCEEDED, type),
        ukwnTotal: calcTot(payments, UNKNOWN, type),
        rntdTotal: calcTot(payments, RETURNED, type),
      };

      if (payments.some((s: any) => status(s.fileName, type))) {
        let children: any = [];
        types.map((t: any) => {
          if (
            payments.some(
              (s: any) => status(s.fileName, type) && s.paymentStatus == t
            )
          ) {
            children.push({
              ...totals,
              paymentType: t,
              key: Math.random().toString(36).substr(2, 20),
              children: [
                ...payments
                  .filter(
                    (i: any) => status(i.fileName, type) && i.paymentStatus == t
                  )
                  .map((i: any) => {
                    return {
                      ...i,
                      key: Math.random().toString(36).substr(2, 20),
                    };
                  }),
              ],
            });
          }
        });

        obj = {
          ...obj,
          ...totals,
          isParent: true,
          fileName: type,
          type,
          children,
        };
      }
      return obj;
    };

    if (payments.some((s: any) => s.fileName == "Manual")) {
      newPayments.push(getObj("Manual"));
    }
    if (payments.some((s: any) => s.fileName !== "Manual")) {
      _.uniqBy(
        payments?.filter((n: any) => n.fileName !== "Manual"),
        "fileName"
      )?.map((s: any) => newPayments.push(getObj(s?.fileName)));
    }

    return newPayments.map((payment: any) => ({
      ...payment,
      key: Math.random().toString(36).substr(2, 20),
    }));
  } catch (e) {
    return [];
  }
};

// Calculate total payment amounts
export const calcTot = (data: Array<any>, payType: string, type: string) => {
  return data
    ? data
        ?.filter(
          (i) => status(i?.fileName, type) && i?.paymentStatus == payType
        )
        .reduce((acc, { amount }) => {
          return acc + amount;
        }, 0)
    : 0;
};

export const status = (arg: any, type: string) => {
  if (type == "Manual") {
    return arg == "Manual";
  } else {
    return arg == type;
  }
};

/**
 * @description - Sort client payment accounts numbers based
 * @param {Array<any>} accounts - Payment accounts
 * @returns {Array<any>}
 */
export const sortPaymentAccounts = (accounts: Array<any>): Array<any> => {
  try {
    if (isArray(accounts)) {
      return accounts?.sort(
        (a: { accountName: number }, b: { accountName: number }) => {
          if (a.accountName < b.accountName) {
            return -1;
          }
          if (a.accountName > b.accountName) {
            return 1;
          }
          return 0;
        }
      );
    } else {
      return [];
    }
  } catch (error) {
    return accounts;
  }
};
