import { initialState } from "./State";
import { ClientInvoices } from "us.collection.economy/constants/Actions";
import {
  CIExportStatus,
  CISearchType,
} from "us.collection.economy/components/ClientInvoices/Constants";
import {
  filterInvoices,
  getActiveTabKey,
  getInvoiceSummery,
  sortData,
} from "us.collection.economy/components/ClientInvoices/Functions";
import { IInvoice } from "us.collection.economy/components/ClientInvoices/Interfaces";

const {
  RESET_INVOICE_LIST,

  SEARCH_CLIENT_INVOICES,
  SEARCH_CLIENT_INVOICES_SUCCESS,
  SEARCH_CLIENT_INVOICES_FAILED,

  FILTER_INVOICES,
  SORT_INVOICES,

  GET_CREDITOR_INVOICE_DETAILS_START,
  GET_CREDITOR_INVOICE_DETAILS_SUCCESS,
  GET_CREDITOR_INVOICE_DETAILS_FAIL,
  RESET_CREDITOR_INVOICE_DETAILS,
  SET_DRAWER_VISIBLE_STATUS,

  SAVE_CREDIT_NOTE_START,
  SAVE_CREDIT_NOTE_SUCCESS,
  SAVE_CREDIT_NOTE_FAIL,

  EXPORT_CREDIT_INVOICE,
  EXPORT_CREDIT_INVOICE_SUCCESS,
  EXPORT_CREDIT_INVOICE_FAIL,

  ON_TAB_CHANGED,

  EXPORT_INVOICES,
  EXPORT_INVOICES_SUCCESS,
  EXPORT_INVOICES_FAILED,
} = ClientInvoices;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case RESET_INVOICE_LIST:
      return Object.assign({}, state, {
        exported: {
          invoices: [],
          filtered: [],
        },
        pendingExport: {
          invoices: [],
          filtered: [],
        },
        totalInvoices: 0,
        totalInvoicesAmount: 0,
      });
    case ON_TAB_CHANGED:
      const amounts = getInvoiceSummery(
        payload.activeKey == "0"
          ? state.pendingExport.filtered
          : state.exported.filtered
      );
      return Object.assign({}, state, {
        activeTabKey: payload.activeKey,
        totalInvoices: amounts.totalInvoices,
        totalInvoicesAmount: amounts.totalInvoicesAmount,
      });
    case SEARCH_CLIENT_INVOICES:
      return Object.assign({}, state, {
        isLoadingInvoices: true,
        exported: {
          invoices: [],
          filtered: [],
        },
        pendingExport: {
          invoices: [],
          filtered: [],
        },
        totalInvoices: 0,
        totalInvoicesAmount: 0,
        activeTabKey:
          payload.searchType == CISearchType.EXPORTED_DATE
            ? 1
            : state.activeTabKey,
      });
    case SEARCH_CLIENT_INVOICES_SUCCESS:
      const invoices = payload.data;
      const { totalInvoices, totalInvoicesAmount } =
        getInvoiceSummery(invoices);
      return Object.assign({}, state, {
        isLoadingInvoices: false,
        exported: {
          invoices,
          filtered: invoices.filter(
            (invoice: IInvoice) =>
              invoice.exportStatus === CIExportStatus.EXPORTED_OR_NAVISION
          ),
        },
        pendingExport: {
          invoices,
          filtered: invoices.filter(
            (invoice: IInvoice) =>
              invoice.exportStatus === CIExportStatus.PENDING_EXPORT
          ),
        },
        totalInvoices,
        totalInvoicesAmount,
        activeTabKey: getActiveTabKey(invoices[0], state.activeTabKey),
      });
    case SEARCH_CLIENT_INVOICES_FAILED:
      return Object.assign({}, state, {
        isLoadingInvoices: false,
      });
    case FILTER_INVOICES:
      const { filterInvoiceNo, customerNo, duePeriod, isReset } = payload;
      return Object.assign({}, state, {
        exported: {
          ...state.exported,
          filtered: isReset
            ? state.exported.invoices
            : filterInvoices(
                state.exported.invoices,
                filterInvoiceNo,
                customerNo,
                duePeriod
              ),
        },
        pendingExport: {
          ...state.pendingExport,
          filtered: isReset
            ? state.pendingExport.invoices
            : filterInvoices(
                state.pendingExport.invoices,
                filterInvoiceNo,
                customerNo,
                duePeriod
              ),
        },
      });
    case SORT_INVOICES:
      return Object.assign({}, state, {
        exported: {
          ...state.exported,
          filtered: sortData(state.exported.invoices, payload),
        },
        pendingExport: {
          ...state.pendingExport,
          filtered: sortData(state.pendingExport.invoices, payload),
        },
      });

    case GET_CREDITOR_INVOICE_DETAILS_START:
      return Object.assign({}, state, {
        details: {
          data: initialState.details.data,
          isLoading: true,
        },
      });
    case GET_CREDITOR_INVOICE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        details: {
          data: {
            ...payload?.data,
            creditorOrderlines: payload?.data.creditorOrderlines?.map(
              (item: any) => {
                return {
                  ...item,
                  articleName: item?.articleText,
                  quantity: item?.numberOfItems,
                  total: Number((item.amount + item.vatAmount).toFixed(2)),
                  reducedQuantity: 0,
                  reducedPriced: 0,
                  creditedAmount: 0,
                };
              }
            ),
          },
          isLoading: false,
        },
        creditNote: {
          isSaving: false,
          result: null,
        },
      });
    case GET_CREDITOR_INVOICE_DETAILS_FAIL:
      return Object.assign({}, state, {
        details: {
          data: initialState.details.data,
          isLoading: false,
        },
      });
    case RESET_CREDITOR_INVOICE_DETAILS:
      return Object.assign({}, state, {
        details: {
          data: initialState.details.data,
          isLoading: false,
        },
        creditNote: {
          ...state.creditNote,
          result: null,
        },
      });
    case SET_DRAWER_VISIBLE_STATUS:
      const isOpenByList = payload.data.isOpenByList ? true : false;
      return Object.assign({}, state, {
        details: {
          data: initialState.details.data,
          isLoading: isOpenByList,
        },
        drawer: {
          isVisible: !state.drawer.isVisible,
          isOpenByList: isOpenByList,
        },
        creditNote: {
          isSaving: false,
          result: null,
        },
      });
    case SAVE_CREDIT_NOTE_START:
      return Object.assign({}, state, {
        creditNote: {
          isSaving: true,
          result: null,
        },
      });
    case SAVE_CREDIT_NOTE_SUCCESS:
      return Object.assign({}, state, {
        creditNote: {
          isSaving: false,
          result: payload.data,
        },
        drawer: !state.details.data?.isExported
          ? { ...initialState.drawer }
          : { ...state.drawer },
      });
    case SAVE_CREDIT_NOTE_FAIL:
      return Object.assign({}, state, {
        creditNote: {
          isSaving: false,
          result: null,
        },
      });
    case EXPORT_CREDIT_INVOICE: {
      return Object.assign({}, state, {
        creditNoteExport: {
          ...state.creditNoteExport,
          isLoading: true,
        },
      });
    }
    case EXPORT_CREDIT_INVOICE_SUCCESS: {
      return Object.assign({}, state, {
        creditNoteExport: {
          ...state.creditNoteExport,
          isLoading: false,
          result: payload.data,
        },
        drawer: {
          isVisible: false,
          isOpenByList: false,
        },
        creditNote: {
          isSaving: false,
          result: null,
        },
      });
    }
    case EXPORT_CREDIT_INVOICE_FAIL: {
      return Object.assign({}, state, {
        creditNoteExport: {
          ...state.creditNoteExport,
          isLoading: false,
          result: null,
        },
        drawer: {
          isVisible: false,
          isOpenByList: false,
        },
        creditNote: {
          isSaving: false,
          result: null,
        },
      });
    }
    case EXPORT_INVOICES: {
      return Object.assign({}, state, {
        invoiceExport: {
          ...state.invoiceExport,
          isLoading: true,
          result: null,
        },
      });
    }
    case EXPORT_INVOICES_SUCCESS: {
      return Object.assign({}, state, {
        invoiceExport: {
          ...state.invoiceExport,
          isLoading: false,
          result: payload.data,
        },
      });
    }
    case EXPORT_INVOICES_FAILED: {
      return Object.assign({}, state, {
        invoiceExport: {
          ...state.invoiceExport,
          isLoading: false,
          result: payload.data,
        },
      });
    }
    default:
      return state;
  }
};
