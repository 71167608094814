import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { $AmountLabel } from "us.common/components";
import { TableProps } from "./Interfaces";
import { TableColumn, TABLE_COLUMNS } from "./Constants";
import { getColumnFilterProps } from "./Functions";
import { Record } from "us.collection.economy/components/CreditorInvoiceDetails/Interfaces";
import { RootState } from "us.helper/types";
import { connect, ConnectedProps } from "react-redux";

const { $TableTree } = Common.Components;

/**
 * @description - Creditor Invoice Details table
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2728034451/Creditor+Invoicing+%3A+Invoice+Detail+View
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 25/08/2022
 */
const Table: React.FC<TableProps & PropsFromRedux> = ({
  creditorInvoiceDetails,
}) => {
  const { t } = useTranslation();

  // get table columns
  const getColumns: any = () => {
    const columns: Array<any> = [];
    TABLE_COLUMNS.map(({ key, title, align }) => {
      let column: any = {
        key,
        dataIndex: key,
        align,
        title: title
          ? t(`US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.${title}`)
          : "",
        ...getColumnFilterProps(key),
      };
      if (key === TableColumn.ARTICLE_NUMBER) {
        column = {
          ...column,
          customRenderChild: (text: any) => {
            return <>{text}</>;
          },
          customSorter: (a: any, b: any) => a - b,
        };
      } else if (key === TableColumn.NAME) {
        column = {
          ...column,
          customRenderChild: (text: any) => {
            return <>{text}</>;
          },
          customSorter: (a: any, b: any) => a.localeCompare(b),
        };
      } else if (key === TableColumn.ORDERLINE_TOTAL) {
        column = {
          ...column,
          customRenderChild: (text: any, { amount, vatAmount }: Record) => {
            return (
              <span>
                <$AmountLabel
                  value={Number(
                    (Number(amount) + Number(vatAmount)).toFixed(2)
                  )}
                />
              </span>
            );
          },
          customSorter: (a: any, b: any) => a - b,
        };
      } else {
        column = {
          ...column,
          customRenderChild: (text: any, record: any) => {
            return (
              <span data-testid={`${key}Amount`}>
                <$AmountLabel value={record[key]} />
              </span>
            );
          },
          customSorter: (a: any, b: any) => a - b,
        };
      }
      columns.push(column);
    });
    return columns;
  };

  return (
    <$TableTree
      rowKey={(record: Record) => record.orderlineId}
      data={creditorInvoiceDetails?.creditorOrderlines}
      data-testid="credit-invoice-details"
      size="small"
      className="mt-3 table-striped header-custom-tag"
      onSort={(sortData, dataSource) => {
        return sortData(dataSource);
      }}
      onFilter={(searchData, dataSource) => {
        return searchData(dataSource);
      }}
      filterOnType
      resetOnSourceChange
      bordered
      showResetAllFilters
      pagination={{
        defaultPageSize: 20,
      }}
      columns={getColumns()}
    />
  );
};

const mapStateToProps = (state: RootState) => {
  const { creditorInvoiceDetails } = state;
  const { list, isListLoading } = creditorInvoiceDetails;
  return {
    creditorInvoiceDetails: list,
    isListLoading,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Table);
