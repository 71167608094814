import { ICoveringSequenceProps } from "us.collection.economy/interfaces";

export const INITIAL_STATE_COVERING_SEQUENCE: ICoveringSequenceProps = {
  list: [],
  isListLoading: false,
  sequenceList: [],
  itemTypes: [],
  itemTypesLoading: false,
  defaultSequenceList: [],
  saveSequence: {
    data: {},
    isLoading: false,
  },
  isValidCoveringSequenceName: true
};
