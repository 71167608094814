export enum RemitHistoryTableColumn {
  MENU = "menu",
  VALIDATION_STATUS = "isValid",
  JOURNAL_ID = "journalId",
  FILE_DATE = "fileDate",
  BUREAU_AMOUNT = "bureauAmount",
  CREDITOR_AMOUNT = "creditorAmount",
  DEBTOR_AMOUNT = "debtorAmount",
  TOTAL_AMOUNT = "totalAmount",
  SOURCE_ACCOUNT = "sourceBankAccountNo",
  REMIT_PERIOD = "remitPeriod",
  REMIT_VOUCHER = "remitVoucherNo",
  LAST_DOWNLOADED_USER = "downloadedUser",
}

export enum RemitAction {
  RE_CREATE = "RE_CREATE",
  VIEW_LEDGER_DASHBOARD = "VIEW_LEDGER_DASHBOARD",
  REVERSE = "REVERSE",
  DOWNLOAD = "DOWNLOAD",
}

export enum ColumnSearchType {
  AMOUNT = "amount",
  DATE = "date",
  ACCOUNT_NO = "accountNo",
  STRING = "string",
}

export const COLUMNS = [
  {
    id: 0,
    key: RemitHistoryTableColumn.MENU,
    title: "",
  },
  {
    id: 1,
    key: RemitHistoryTableColumn.JOURNAL_ID,
    title: "JOURNAL_ID",
  },
  {
    id: 2,
    key: RemitHistoryTableColumn.VALIDATION_STATUS,
    title: "VALIDATION",
  },
  {
    id: 3,
    key: RemitHistoryTableColumn.FILE_DATE,
    title: "FILE_DATE",
  },
  {
    id: 4,
    key: RemitHistoryTableColumn.BUREAU_AMOUNT,
    title: "BUREAU_AMOUNT",
  },
  {
    id: 5,
    key: RemitHistoryTableColumn.CREDITOR_AMOUNT,
    title: "CREDITOR_AMOUNT",
  },
  {
    id: 6,
    key: RemitHistoryTableColumn.DEBTOR_AMOUNT,
    title: "DEBTOR_AMOUNT",
  },
  {
    id: 7,
    key: RemitHistoryTableColumn.TOTAL_AMOUNT,
    title: "TOTAL_AMOUNT",
  },
  {
    id: 8,
    key: RemitHistoryTableColumn.SOURCE_ACCOUNT,
    title: "SOURCE_BANK_ACCOUNT_NO",
  },
  {
    id: 9,
    key: RemitHistoryTableColumn.REMIT_PERIOD,
    title: "REMIT_PERIOD",
  },
  {
    id: 10,
    key: RemitHistoryTableColumn.REMIT_VOUCHER,
    title: "REMIT_VOUCHER",
  },
  {
    id: 11,
    key: RemitHistoryTableColumn.LAST_DOWNLOADED_USER,
    title: "LAST_DOWNLOAD",
  },
];

export const DEFAULT_COLUMN_FILTERS = {
  journalId: "",
  fileDate: "",
  totalAmount: undefined,
  bureauAmount: undefined,
  debtorAmount: undefined,
  creditorAmount: undefined,
  remitPeriod: "",
  sourceBankAccountNo: "",
  remitVoucherNo: "",
};

export const DEFAULT_TABLE_INFO = {
  filters: {},
  sorter: { columnKey: "", order: "" },
  data: [],
};
