export enum ManagePayments {
  INIT_PAYMENTS = "INIT_PAYMENTS",
  INIT_PAYMENTS_SUCCESS = "INIT_PAYMENTS_SUCCESS",
  INIT_PAYMENTS_FAILED = "INIT_PAYMENTS_FAILED",

  GET_PAYMENT_ACCOUNTS = "GET_PAYMENT_ACCOUNTS",
  GET_PAYMENT_ACCOUNTS_SUCCESS = "GET_PAYMENT_ACCOUNTS_SUCCESS",
  GET_PAYMENT_ACCOUNTS_FAIL = "GET_PAYMENT_ACCOUNTS_FAIL",

  SEARCH_PAYMENTS = "SEARCH_PAYMENTS",
  SEARCH_PAYMENTS_SUCCESS = "SEARCH_PAYMENTS_SUCCESS",
  SEARCH_PAYMENTS_FAIL = "SEARCH_PAYMENTS_FAIL",
  SEARCH_PAYMENTS_NO_RESULT = "SEARCH_PAYMENTS_NO_RESULT",

  GET_PAYMENTS_DISTRIBUTION = "GET_PAYMENTS_DISTRIBUTION",
  GET_PAYMENTS_DISTRIBUTION_SUCCESS = "GET_PAYMENTS_DISTRIBUTION_SUCCESS",
  GET_PAYMENTS_DISTRIBUTION_FAIL = "GET_PAYMENTS_DISTRIBUTION_FAIL",

  RETURN_PAYMENT = "RETURN_PAYMENT",
  RETURN_PAYMENT_SUCCESS = "RETURN_PAYMENT_SUCCESS",
  RETURN_PAYMENT_FAIL = "RETURN_PAYMENT_FAIL",

  EDIT_PAYMENT = "EDIT_PAYMENT",
  EDIT_PAYMENT_SUCCESS = "EDIT_PAYMENT_SUCCESS",
  EDIT_PAYMENT_FAIL = "EDIT_PAYMENT_FAIL",
  SET_SELECTED_EDIT_PAYMENT = "SET_SELECTED_EDIT_PAYMENT",

  SET_PAYMENTS_WITH_ACCOUNT = "SET_PAYMENTS_WITH_ACCOUNT",

  GET_CASES = "GET_CASES",
  GET_CASES_SUCCESS = "GET_CASES_SUCCESS",
  GET_CASES_FAIL = "GET_CASES_FAIL",

  MATCH_PAYMENT = "MATCH_PAYMENT",
  MATCH_PAYMENT_SUCCESS = "MATCH_PAYMENT_SUCCESS",
  MATCH_PAYMENT_FAIL = "MATCH_PAYMENT_FAIL",
  RESET_MATCH_PAYMENT_RESULT = "RESET_MATCH_PAYMENT_RESULT",

  DELETE_CASE = "DELETE_CASE",

  GET_CASE_VALIDATION_DATA = "GET_CASE_VALIDATION_DATA",
  GET_CASE_VALIDATION_DATA_SUCCESS = "GET_CASE_VALIDATION_DATA_SUCCESS",
  GET_CASE_VALIDATION_DATA_FAIL = "GET_CASE_VALIDATION_DATA_FAIL",
  CLEAR_VALIDATE_CASE_DATA = "CLEAR_VALIDATE_CASE_DATA",

  REVERSE_RETURNED_PAYMENT = "REVERSE_RETURNED_PAYMENT",
  REVERSE_RETURNED_PAYMENT_SUCCESS = "REVERSE_RETURNED_PAYMENT_SUCCESS",
  REVERSE_RETURNED_PAYMENT_FAIL = "REVERSE_RETURNED_PAYMENT_FAIL",

  REVERT_MAPPED_PAYMENT = "REVERT_MAPPED_PAYMENT",
  REVERT_MAPPED_PAYMENT_SUCCESS = "REVERT_MAPPED_PAYMENT_SUCCESS",
  REVERT_MAPPED_PAYMENT_FAIL = "REVERT_MAPPED_PAYMENT_FAIL",

  REVERT_PAYMENT_POPCONFIRM_STATUS = "REVERT_PAYMENT_POPCONFIRM_STATUS",

  ADD_CASE_TABLE_ROW_KEY = "ADD_CASE_TABLE_ROW_KEY",
  DELETE_CASE_TABLE_ROW_KEY = "DELETE_CASE_TABLE_ROW_KEY",

  RESET_MATCH = "RESET_MATCH",
}

export enum ManagePaymentsGrid {
  INIT_PAYMENTS_GRID = "INIT_PAYMENTS_GRID",
  INIT_PAYMENTS_GRID_SUCCESS = "INIT_PAYMENTS_GRID_SUCCESS",
  INIT_PAYMENTS_GRID_FAILED = "INIT_PAYMENTS_GRID_FAILED",

  GET_PAYMENTS_GRID = "GET_PAYMENTS_GRID",
  GET_PAYMENTS_GRID_SUCCESS = "GET_PAYMENTS_GRID_SUCCESS",
  GET_PAYMENTS_GRID_FAIL = "GET_PAYMENTS_GRID_FAIL",
}

export enum ManagePaymentsApportionment {
  GET_PAYMENTS_APPORTIONMENT = "GET_PAYMENTS_APPORTIONMENT",
  GET_PAYMENTS_APPORTIONMENT_SUCCESS = "GET_PAYMENTS_APPORTIONMENT_SUCCESS",
  GET_PAYMENTS_APPORTIONMENT_FAIL = "GET_PAYMENTS_APPORTIONMENT_FAIL",

  GET_VAT_AMOUNT = "GET_VAT_AMOUNT",
  GET_VAT_AMOUNT_SUCCESS = "GET_VAT_AMOUNT_SUCCESS",
  GET_VAT_AMOUNT_FAIL = "GET_VAT_AMOUNT_FAIL",

  GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR = "GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR",
  GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_SUCCESS = "GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_SUCCESS",
  GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_FAIL = "GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_FAIL",

  UPDATE_APPORTIONMENT = "UPDATE_APPORTIONMENT",
  UPDATE_APPORTIONMENT_SUCCESS = "UPDATE_APPORTIONMENT_SUCCESS",
  UPDATE_APPORTIONMENT_FAIL = "UPDATE_APPORTIONMENT_FAIL",

  CHANGE_DRAWER_INFO = "CHANGE_DRAWER_INFO",
  ADD_APPORTIONMENT_TABLE_ACTIVE_KEYS = "ADD_APPORTIONMENT_TABLE_ACTIVE_KEYS",
  DELETE_APPORTIONMENT_TABLE_ACTIVE_KEYS = "DELETE_APPORTIONMENT_TABLE_ACTIVE_KEYS",
}
