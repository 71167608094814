import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MoreOutlined, EllipsisOutlined } from "us.icons";
import Common from "us.common";
import { CoveringSequenceTableProps } from "./Interfaces";
import {
  CoveringSequenceTableColumn,
  COVERING_SEQUENCE_TABLE_COLUMNS,
} from "./Constants";
import { ItemMenu } from "./Components";
import {
  CoveringSequenceActionType,
  CoveringSequenceRecord,
} from "us.collection.economy/components/CoveringSequence/Interfaces";
import {
  CoveringSequenceAction,
  ITEM_MENU_DEFAULT_STATE,
} from "us.collection.economy/components/CoveringSequence/Constants";
import { getColumnFilterProps } from "./Functions";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";

const { $Button, $Popover, $TableTree, $Timeline, $DateLabel } =
  Common.Components;

/**
 * @description - Covering Sequence List Show in the table view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3054534676/Covering+Sequence+List
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 18/07/2022
 */
const Table: React.FC<CoveringSequenceTableProps & PropsFromRedux> = ({
  onCallAction,
  coveringSequenceList,
}) => {
  const { t } = useTranslation();
  const [itemMenu, setItemMenu] = useState<{
    visible: boolean;
    id: number;
  }>(ITEM_MENU_DEFAULT_STATE);

  // auto close invoice item menu after 5 seconds
  const setAutoClose = (id: number) => {
    setTimeout(() => {
      setItemMenu({
        visible: false,
        id,
      });
    }, 5000);
  };

  const contentList = (record: CoveringSequenceRecord) => (
    <$Timeline className="sequeance-line mt-3">
      {record.sequenceDetails.map((seq: any, index: number) => (
        <$Timeline.Item key={index} dot={index + 1}>
          {seq.names}
        </$Timeline.Item>
      ))}
    </$Timeline>
  );

  // get table columns
  const getColumns: any = () => {
    const columns: Array<any> = [];
    COVERING_SEQUENCE_TABLE_COLUMNS.map(({ key, title, align }: any) => {
      let column: any = {
        key,
        dataIndex: key,
        align,
        title: title && t(`US.COLLECTION.ECONOMY:COVERING_SEQUENCE.${title}`),
      };
      if (key === CoveringSequenceTableColumn.MENU) {
        column = {
          ...column,
          width: "50px",
          customRenderChild: (text: any, record: CoveringSequenceRecord) => {
            return (
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={itemMenu.visible && itemMenu.id === record.sequenceId}
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    id: record.sequenceId,
                  });
                  setAutoClose(record.sequenceId);
                }}
                content={
                  <ItemMenu
                    onCallAction={(actionType: CoveringSequenceActionType) => {
                      setItemMenu(ITEM_MENU_DEFAULT_STATE);
                      onCallAction(record, actionType);
                    }}
                    isUsed={record?.isUsed}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  data-testid="popover-btn"
                  id="btnItemMenu"
                  type="default"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            );
          },
        };
      } else if (key === CoveringSequenceTableColumn.SEQUENCE) {
        column = {
          ...column,
          width: "250px",
          customRenderChild: (text: any, record: CoveringSequenceRecord) => {
            return (
              <div className="sequence-list d-flex align-items-center">
                {record.sequenceDetails.map(
                  (seqData: any, index: number) =>
                    index <= 1 && (
                      <div key={index} className="sequence-list-item">
                        {seqData.names}
                      </div>
                    )
                )}
                <div>
                  <$Popover
                    placement="bottom"
                    content={contentList(record)}
                    destroyTooltipOnHide
                    trigger="click"
                  >
                    <$Button type="link" size="small">
                      <EllipsisOutlined />
                    </$Button>
                  </$Popover>
                </div>
              </div>
            );
          },
        };
      } else if (key === CoveringSequenceTableColumn.MODIFIED_DATE) {
        column = {
          ...column,
          width: "220px",
          customRenderChild: (text: any, record: CoveringSequenceRecord) => {
            return (
              <div className="d-flex align-items-center">
                <$DateLabel value={record.modifiedDate ?? record.createdDate} />
              </div>
            );
          },
          ...getColumnFilterProps(key),
          customSorter: (a: any, b: any) =>
            new Date(a).getTime() - new Date(b).getTime(),
        };
      } else if (key === CoveringSequenceTableColumn.MODIFIED_USER) {
        column = {
          ...column,
          width: "220px",
          customRenderChild: (text: any, record: CoveringSequenceRecord) => {
            const { modifiedBy, createdBy } = record;
            return <span>{modifiedBy != "" ? modifiedBy : createdBy}</span>;
          },
          ...getColumnFilterProps(key),
          customSorter: (a: any, b: any) => a.localeCompare(b),
        };
      } else {
        column = {
          ...column,
          customFilter: true,
          showFilters: true,
          width: "220px",
          customRenderChild: (text: any, record: any) => {
            return <span>{text}</span>;
          },
          ...getColumnFilterProps(key),
          customSorter: (a: any, b: any) => a.localeCompare(b),
        };
      }
      columns.push(column);
    });
    return columns;
  };

  return (
    <$TableTree
      data-testid="coveringSequence-table"
      rowKey={(record: CoveringSequenceRecord) => record.sequenceId}
      data={coveringSequenceList}
      size="small"
      className="mt-3 header-custom-tag"
      onSort={(sortData, dataSource) => {
        return sortData(dataSource);
      }}
      onFilter={(searchData, dataSource) => {
        return searchData(dataSource);
      }}
      filterOnType
      resetOnSourceChange
      bordered
      showResetAllFilters
      pagination={{
        defaultPageSize: 20,
      }}
      scroll={{
        x: 900,
        y: "calc(100vh - 265px)",
      }}
      columns={getColumns()}
      onRow={(record, _rowIndex) => {
        return {
          onDoubleClick: (_event) => {
            onCallAction(record, CoveringSequenceAction.EDIT);
          },
        };
      }}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { coveringSequence } = state;
  const { list } = coveringSequence;
  return { coveringSequenceList: list };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Table);
