import { IAPIAction } from "us.collection/interfaces";
import { ImportPayments } from "../../constants/Actions";
import { IImportPaymentAction } from "us.collection.economy/interfaces";

export const importPayments: Readonly<IAPIAction & IImportPaymentAction> = {
  upload: {
    save: (data) => ({
      type: ImportPayments.UPLOAD_PAYMENTS_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ImportPayments.UPLOAD_PAYMENTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ImportPayments.UPLOAD_PAYMENTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    updateFiles: (data) => ({
      type: ImportPayments.UPDATE_FILE_LIST,
      payload: {
        data,
        isLoading: true,
      },
    }),
    reset: (data) => ({
      type: ImportPayments.RESET_FILE_IMPORT,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  importFiles: {
    save: (data) => ({
      type: ImportPayments.IMPORT_PAYMENTS_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ImportPayments.IMPORT_PAYMENTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ImportPayments.IMPORT_PAYMENTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
