import React from "react";
import { useTranslation } from "react-i18next";
import { $Popconfirm } from "us.common/components";
import { EditOutlined, DeleteOutlined } from "us.icons";
import { IItemMenu } from "./interface";
import { ClientAccountsAction } from "us.collection.economy/components/ClientAccounts/Constants";

const ItemMenu: React.FC<IItemMenu> = ({ onCallAction, isUsed }) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content" data-testid="popover-content">
      <div
        className="d-flex  flex-row more-item"
        data-testid="edit-btn"
        onClick={() => onCallAction(ClientAccountsAction.EDIT)}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("COMMON.EDIT")}</div>
      </div>
      {!isUsed && (
        <$Popconfirm
          title={t(
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.DO_YOU_WANT_TO_DELETE_THE_CLIENT_ACCOUNT"
          )}
          onConfirm={() => {
            onCallAction(ClientAccountsAction.DELETE);
          }}
        >
          <div
            className="d-flex mb-2 flex-row more-item text-error"
            data-testid="delete-btn"
          >
            <div className="p-1">
              <DeleteOutlined />
            </div>
            <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
          </div>
        </$Popconfirm>
      )}
    </div>
  );
};

export default ItemMenu;
