import { INITIAL_STATE_VAT_CODE } from "us.collection.economy/reducers/VatCode/State";
import { VatCode } from "us.collection.economy/constants/Actions";

const {
  GET_VAT_CODE_LIST,
  GET_VAT_CODE_LIST_SUCCESS,
  GET_VAT_CODE_LIST_FAIL,
  GET_LEDGER_ACCOUNTS,
  GET_LEDGER_ACCOUNTS_SUCCESS,
  GET_LEDGER_ACCOUNTS_FAIL,
  SAVE_VAT_CODE,
  SAVE_VAT_CODE_SUCCESS,
  SAVE_VAT_CODE_FAIL,
  RESET_VAT_CODE_DETAILS,
  VALIDATE_VAT_CODE,
  VALIDATE_VAT_CODE_SUCCESS,
  VALIDATE_VAT_CODE_FAIL,
  UPDATE_VAT_CODE,
  UPDATE_VAT_CODE_SUCCESS,
  UPDATE_VAT_CODE_FAIL,
  DELETE_VAT_CODE,
  DELETE_VAT_CODE_SUCCESS,
  DELETE_VAT_CODE_FAIL,
  RESET_VAT_CODE_VALIDATION,
} = VatCode;

export default (state = INITIAL_STATE_VAT_CODE, { payload, type }: any) => {
  switch (type) {
    case GET_VAT_CODE_LIST:
      return Object.assign({}, state, {
        ...INITIAL_STATE_VAT_CODE,
        isListLoading: true,
      });
    case GET_VAT_CODE_LIST_SUCCESS:
      return Object.assign({}, state, {
        list: payload?.data,
        isListLoading: false,
      });
    case GET_VAT_CODE_LIST_FAIL:
      return Object.assign({}, state, {
        isListLoading: false,
      });
    case GET_LEDGER_ACCOUNTS:
      return Object.assign({}, state, {
        ledgerAccounts: [],
      });
    case GET_LEDGER_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        ledgerAccounts: payload?.data,
      });
    case GET_LEDGER_ACCOUNTS_FAIL:
      return Object.assign({}, state, {
        ledgerAccounts: [],
      });
    case VALIDATE_VAT_CODE:
      return Object.assign({}, state, {
        validationStatus: true,
      });
    case VALIDATE_VAT_CODE_SUCCESS:
      return Object.assign({}, state, {
        validationStatus: payload?.data,
      });
    case VALIDATE_VAT_CODE_FAIL:
      return Object.assign({}, state, {
        validationStatus: false,
      });
    case RESET_VAT_CODE_VALIDATION:
      return Object.assign({}, state, {
        validationStatus: true,
      });
    case SAVE_VAT_CODE:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: {},
          isLoading: true,
        },
      });
    case SAVE_VAT_CODE_SUCCESS:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: payload?.data?.addedData,
          isLoading: false,
        },
        list: payload?.data?.vatCodeList,
      });
    case SAVE_VAT_CODE_FAIL:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: {},
          isLoading: false,
        },
      });
    case RESET_VAT_CODE_DETAILS:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: {},
          isLoading: false,
        },
      });
    case UPDATE_VAT_CODE:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: {},
          isLoading: true,
        },
      });
    case UPDATE_VAT_CODE_SUCCESS:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: payload?.data?.updatedData,
          isLoading: false,
        },
        list: payload?.data?.vatCodeList,
      });
    case UPDATE_VAT_CODE_FAIL:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: {},
          isLoading: false,
        },
      });
    case DELETE_VAT_CODE:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: {},
          isLoading: true,
        },
      });
    case DELETE_VAT_CODE_SUCCESS:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: payload?.data?.deletedData,
          isLoading: false,
        },
        list: payload?.data?.vatCodeList,
      });
    case DELETE_VAT_CODE_FAIL:
      return Object.assign({}, state, {
        vatCodeSaveAndUpdate: {
          data: {},
          isLoading: false,
        },
      });
    default:
      return state;
  }
};
