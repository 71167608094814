export const initialState = {
  totalOrders: 0,
  totalOrderAmount: 0,
  orders: [],
  orderTypes: [],
  isLoadingOrdersTypes: false,
  isLoadingOrders: false,
  orderExport: {
    isLoading: false,
    result: null,
  },
  generateOrder: {
    isLoading: false,
    result: null,
    isDrawerVisible: false
  },
};
