import { ISaveApportionment, ISaveResponse } from "../Interfaces";

export function SaveApportionments(this: ISaveApportionment) {
    let savePayload: any = []
    let accountNo = this.accountNo
    this.caseTableData.map((apportionment: any) => {
        apportionment.items.map((item: any) => {
            item.children.map((child: any) => {
                const { apportionId,
                    arId,
                    paymentId,
                    paymentAmount,
                    paymentArItemNo,
                    transactionArItemNo,
                    caseId,
                    ref,
                    transactionGroupId,
                    creditorAmount,
                    bureauAmount,
                    debtorAmount,
                    exceedAmount,
                    otherAmount,
                    amount,
                    vatAmount,
                    vatBasis,
                    vatCode,
                    vatPaidByDebtor,
                    vatDeducted,
                    itemTypeId,
                    transactionText,
                    isInterest,
                    text,
                    groupText
                } = child
                savePayload.push({
                    apportionId,
                    arId,
                    accountNo,
                    paymentId: this.mainPaymentId,
                    paymentAmount,
                    paymentArItemNo,
                    transactionArItemNo,
                    caseId,
                    ref,
                    transactionGroupId,
                    creditorAmount,
                    bureauAmount,
                    debtorAmount,
                    exceedAmount,
                    otherAmount,
                    amount,
                    vatAmount,
                    vatBasis,
                    vatCode,
                    vatPaidByDebtor,
                    vatDeducted,
                    itemTypeId,
                    transactionText,
                    isInterest,
                    text,
                    groupText
                })
            })
        })
        return apportionment
    })

    return savePayload
}
