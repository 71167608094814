import { ISearchProvisionProfilesParams } from "us.collection.economy/interfaces";

export function SearchProvisionProfiles(
  this: any
): ISearchProvisionProfilesParams {
  return {
    status: this.status,
    profileName: this.profileName !== "" ? this.profileName : null,
  };
}
