import { IMatchPayment } from "./../../components/ManagePayments/interface";
import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";
import { ISearchFilters } from "us.collection.economy/components/ManagePaymentsOld/interface";
import { IGetCases } from "us.collection.economy/interfaces";
import moment from "moment";
import {
  DATE_FORMAT_FOR_SERVER,
  DayValue,
} from "us.collection.economy/constants/Payments";
import ServiceConfig from "service.config.js";
import { sortPaymentAccounts } from "us.collection.economy/functions";

export default {
  payments: {
    get: <
      S extends ISearchFilters & {
        dayVal: DayValue.NON | DayValue.TODAY | DayValue.YESTERDAY;
      }
    >(
      params: S
    ): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.post(
            "paymentservice",
            "payment/search",
            params
          );

          resolve({
            payments: data,
            paymentDateRange: [
              moment(params?.startDate),
              moment(params?.endDate),
            ],
            dayVal: params.dayVal,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    grid: (params: ISearchFilters): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.post(
            "paymentservice",
            "payment/search/gridview",
            params
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    accounts: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            "payment/bankaccounts",
            {}
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    initPayments: (params: ISearchFilters): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const bankAccounts = await httpCollection.get(
            "paymentservice",
            "payment/bankaccounts",
            {}
          );

          const sortedData = sortPaymentAccounts(bankAccounts.data);

          const { data, status } = await httpCollection.post(
            "paymentservice",
            "payment/search",
            {
              ...params,
              accountNo: sortedData[0]?.accountNo,
            }
          );
          if (status === 401) {
            ///unauthorized
            window.location.replace("/unauthorized");
          }
          if (data.length > 0 && typeof data !== "undefined") {
            resolve({
              accounts: sortedData,
              payments: data,
              dayVal: DayValue.TODAY,
              paymentDateRange: [moment(), moment()],
            });
          }

          if (
            typeof data !== "undefined" &&
            (data.length === 0 || status === 204)
          ) {
            const { data } = await httpCollection.post(
              "paymentservice",
              "payment/search",
              {
                ...params,
                accountNo: sortedData[0]?.accountNo,
                startDate: moment()
                  .subtract(1, "d")
                  .startOf("day")
                  .format(DATE_FORMAT_FOR_SERVER),
                endDate: moment()
                  .subtract(1, "d")
                  .endOf("day")
                  .format(DATE_FORMAT_FOR_SERVER),
              }
            );

            resolve({
              accounts: sortedData,
              payments: data,
              dayVal: DayValue.YESTERDAY,
              paymentDateRange: [
                moment().subtract(1, "d"),
                moment().subtract(1, "d"),
              ],
            });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    initGridPayments: (params: ISearchFilters): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const bankAccounts = await httpCollection.get(
            "paymentservice",
            "payment/bankaccounts",
            {}
          );

          const { data } = await httpCollection.post(
            "paymentservice",
            "payment/search/gridview",
            {
              ...params,
              accountNo: bankAccounts.data[0]?.accountNo,
            }
          );
          resolve({
            accounts: bankAccounts.data,
            payments: data,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    distribution: (paymentId: number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            `payment/${paymentId}/distribution`,
            {}
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    cases: ({
      creditorId,
      debtorId,
      isIncludedOrganization,
    }: IGetCases): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "arservice",
            `ars/entities/${debtorId}/cases?creditorid=${creditorId}&includecreditororganisations=${isIncludedOrganization}`,
            {}
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    match: (params: IMatchPayment): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.post(
            "paymentservice",
            "payment/matchedpayment",
            params
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    return: <P extends { params: any; filters: any }>({
      params,
      filters,
    }: P): Promise<any> => {
      return new Promise<{
        returned: Array<{}>;
        payments: any;
        paymentDateRange: any;
        dayVal: any;
      }>(async (resolve, reject) => {
        try {
          const returned = await httpCollection.post(
            "paymentservice",
            "payment/returnedpayment",
            params
          );
          const payments = await httpCollection.post(
            "paymentservice",
            "payment/search",
            filters
          );
          resolve({
            returned: returned.data,
            payments: payments.data,
            paymentDateRange: [
              moment(filters?.startDate),
              moment(filters?.endDate),
            ],
            dayVal: filters.dayVal,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    edit: <P extends { params: any; filters: any }>(param: P): Promise<any> => {
      return new Promise<{
        edit: any;
        payments: Array<{}>;
        paymentDateRange: any;
        dayVal: any;
      }>(async (resolve, reject) => {
        try {
          const edit = await httpCollection.put(
            "paymentservice",
            "payment",
            param.params
          );
          const payments = await httpCollection.post(
            "paymentservice",
            "payment/search",
            param.filters
          );
          resolve({
            edit: edit.data,
            payments: payments.data,
            paymentDateRange: [
              moment(param.filters?.startDate),
              moment(param.filters?.endDate),
            ],
            dayVal: param.filters.dayVal,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
    reverseReturenedPayment: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.patch(
            "paymentservice",
            "payment/reversedpayment",
            params
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    revertMappedPayment: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await versionedHttpCollection.delete(
            "paymentservice",
            `payment`,
            `${params.revertPaymentId}/apportionment`,
            params.commentPayload,
            ServiceConfig()[`paymentService`]["payment.apportionment"]
          );

          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    getApportionments: (paymentId: number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.getById(
            "paymentservice",
            "payments",
            `${paymentId}/apportionments?IsDetailedVersion=true`
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getVatAmounts: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "paymentservice",
            "payment/vat",
            params
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateApportionment: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.put(
            "paymentservice",
            "payments/apportionment",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
