import {
  DrawerType,
  DRAWER_DEFAULT_STATE,
  RemitProposalAction,
} from "../Constants";
import { Drawer, RemitProposalActionType, RemitRecord } from "../Interfaces";

/**
 * @function
 * @description - Get dynamic drawer settings by drawer type
 * @param {DrawerType} type - Drawer type `DrawerType`
 * @returns {Drawer} drawer setting `Drawer`
 */
export const getDrawerSettings = (type: DrawerType): Drawer => {
  if (type === DrawerType.REMIT_DETAILS) {
    return {
      ...DRAWER_DEFAULT_STATE,
      type,
      visible: true,
      title: "US.COLLECTION.ECONOMY:REMIT.REMIT_DETAILS",
      width: "95%",
    };
  } else if (type === DrawerType.REMIT_VALIDATION_ERRORS) {
    return {
      ...DRAWER_DEFAULT_STATE,
      type,
      visible: true,
      title: "US.COLLECTION.ECONOMY:REMIT.REMIT_VALIDATION_ERRORS",
      width: "90%",
    };
  } else if (type === DrawerType.SELECT_CREDITOR) {
    return {
      ...DRAWER_DEFAULT_STATE,
      type,
      visible: true,
      title: "US.COLLECTION.ECONOMY:REMIT.SELECT_CREDITOR",
    };
  } else {
    return DRAWER_DEFAULT_STATE;
  }
};

/**
 * @function
 * @description - Calculate sum of columns values by a given column key and table data
 * @param {string} key - Column key
 * @param {Array<any>} tableData - Table data array
 * @returns {number} total - Sum of column values
 */
export const getTableColumnTotal = (
  key: string,
  tableData: Array<any>
): number => {
  try {
    const total = tableData.reduce(function (sum, current) {
      return sum + current[key];
    }, 0);
    if (total && !isNaN(total)) {
      return Number(total.toFixed(2));
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

/**
 * @function
 * @description - Get total remit amount calculated by the given remit record
 * @param {RemitRecord} record - `RemitRecord` item
 * @returns {number} Total = remitCreditor + remitBureau + remitDebtor
 */
export const getTotalRemitAmount = ({
  remitCreditor,
  remitBureau,
  remitDebtor,
}: RemitRecord): number => {
  try {
    return remitCreditor + remitBureau + remitDebtor;
  } catch (error) {
    return 0;
  }
};

/**
 * @function
 * @description - Get available actions by the remit proposal record
 * @param {RemitRecord} remitRecord - Remit proposal table remit record item
 * @returns {Array<RemitProposalActionType>} array of actions
 */
export const getRemitProposalRecordActions = (
  remitRecord: RemitRecord
): Array<RemitProposalActionType> => {
  const actions = [RemitProposalAction.VIEW_REMIT_DETAILS];
  try {
    const { vatAmount, vatDeducted, isInvoiced, vatDeductedOld } = remitRecord;
    if ((vatDeducted > 0 || vatDeductedOld > 0) && vatAmount > 0) {
      if (isInvoiced) {
        actions.push(RemitProposalAction.RESET_INVOICE);
      } else {
        actions.push(RemitProposalAction.INVOICE);
      }
    }
    return actions;
  } catch (error) {
    return actions;
  }
};

/**
 * @description Check whether any remit proposal record invoiced or not
 * @param {RemitRecord[]} remitRecords - Remit proposal records
 * @returns {boolean}
 */
export const isInvoicedAnyRecord = (remitRecords: RemitRecord[]): boolean => {
  try {
    return remitRecords.some(
      (remitRecord: RemitRecord) => remitRecord.isInvoiced
    );
  } catch (error) {
    return false;
  }
};

/**
 * @description Check whether the given record has minus remit creditor value or not
 * @param {RemitRecord} remitRecord - Remit proposal record
 * @returns {boolean}
 */
export const isMinusRemitCreditorValue = (
  remitRecord: RemitRecord
): boolean => {
  try {
    return remitRecord.remitCreditor < 0;
  } catch (error) {
    return false;
  }
};

/**
 * @description Check whether any remit proposal record has minus remit creditor value
 * @param {RemitRecord[]} remitRecords - Remit proposal records
 * @returns {boolean}
 */
export const hasMinusRemitCreditors = (
  remitRecords: RemitRecord[]
): boolean => {
  try {
    return remitRecords.some((remitRecord: RemitRecord) =>
      isMinusRemitCreditorValue(remitRecord)
    );
  } catch (error) {
    return false;
  }
};
