export enum ClientAccountsTableColumn {
  MENU = "menu",
  ACCOUNT_NO = "accountNo",
  ACCOUNT_NAME = "accountName",
  LEDGER_ACCOUNT = "ledgerAccountNumber",
  MODIFIED_DATE = "modifiedDate",
  MODIFIED_USER = "modifiedUser",
}

export const CLIENT_ACCOUNTS_COLUMNS = [
  {
    id: 0,
    key: ClientAccountsTableColumn.MENU,
    title: "",
  },
  {
    id: 1,
    key: ClientAccountsTableColumn.ACCOUNT_NO,
    title: "ACCOUNT_NO",
  },
  {
    id: 2,
    key: ClientAccountsTableColumn.ACCOUNT_NAME,
    title: "ACCOUNT_NAME",
  },
  {
    id: 3,
    key: ClientAccountsTableColumn.LEDGER_ACCOUNT,
    title: "LEDGER_ACCOUNT",
  },
  {
    id: 4,
    key: ClientAccountsTableColumn.MODIFIED_DATE,
    title: "MODIFIED_DATE",
  },
  {
    id: 5,
    key: ClientAccountsTableColumn.MODIFIED_USER,
    title: "MODIFIED_USER",
  },
];

export enum ClientAccountsAction {
  EDIT = "EDIT",
  DELETE = "DELETE",
  ADD = "ADD",
}

export enum ColumnSearchType {
  AMOUNT = "amount",
  DATE = "date",
  ACCOUNT_NO = "accountNo",
  STRING = "string",
  NUMBER = "number",
}

export const ITEM_MENU_DEFAULT_STATE = {
  visible: false,
  id: -1,
  visibleTooltip: false,
};

export const INITIAL_DRAWER_DATA = {
  title: "",
  visible: false,
  record: {
    accountId: 0,
    accountNo: "",
    accountName: "",
    createdDate: "",
    modifiedDate: "",
    modifiedUser: "",
    remitJournalPrifix: "",
    remitJournalSequence: "",
    remitVoucherSequence: "",
    remitTransactionSequence: "",
    remitDaySequence: "",
    ledgerAccountName: "",
    isConsideredForOrderlines: false,
    enterpriseNumber: "",
  },
};

export enum ValidateInput {
  ACCOUNT_NO = "accountNo",
  JOURNAL_PREFIX = "remitJournalPrifix",
}
