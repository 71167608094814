export const ITEM_MENU_DEFAULT_STATE = {
  visible: false,
  id: -1,
};

export enum VatCodeAction {
  ADD = "ADD",
  EDIT = "EDIT",
  DELETE = "DELETE",
}

export const INITIAL_DRAWER_DATA = {
  title: "",
  visible: false,
};
