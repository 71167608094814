import React from "react";
import { useTranslation } from "react-i18next";
import { DeleteOutlined } from "us.icons";
import { $Popconfirm } from "us.common/components";

interface IItemMenu {
  onCallAction: () => void;
}

/**
 * @description - Journal entry action menu.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3121676306/Add+Journal+Entries+-+UI+Implementation+Design
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 19/01/2023
 */
const ItemMenu: React.FC<IItemMenu> = ({ onCallAction }) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content" data-testid="popover-content">
      <$Popconfirm
        title={t("US.COLLECTION.COMMON:COMMON.SURE_TO_DELETE_?")}
        data-testid="popUpConfirm-box"
        onConfirm={() => onCallAction()}
      >
        <div
          className="d-flex mb-2 flex-row more-item text-error"
          data-testid="delete-btn"
        >
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );
};

export default ItemMenu;
