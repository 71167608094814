import { httpCollection } from "us.helper/http/collection";

export default {
  getVatCodeList: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data = await httpCollection.get(
          "economysettingservice",
          "vatcodes",
          {}
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getLedgerAccounts: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data = await httpCollection.get(
          "paymentservice",
          "payments/masterdata/ledgerAccounts",
          {}
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  validateVatCode: (vatcode: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.get(
          "economysettingservice",
          `vatcode/${vatcode}/validationresult`,
          {}
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  addNewVatCode: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.post(
          "economysettingservice",
          `vatcode`,
          params
        );
        const vatCodeListData = await httpCollection.get(
          "economysettingservice",
          "vatcodes",
          {}
        );
        resolve({ data, status, vatCodeList: vatCodeListData.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  editVatCode: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.put(
          "economysettingservice",
          `vatcode`,
          params
        );
        const vatCodeListData = await httpCollection.get(
          "economysettingservice",
          "vatcodes",
          {}
        );
        resolve({ data, status, vatCodeList: vatCodeListData.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteVatCode: (vatCode: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.delete(
          "economysettingservice",
          `vatcode`,
          vatCode
        );
        const vatCodeListData = await httpCollection.get(
          "economysettingservice",
          "vatcodes",
          {}
        );
        resolve({ data, status, vatCodeList: vatCodeListData.data });
      } catch (error) {
        reject(error);
      }
    });
  },
};
