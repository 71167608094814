/**
 * @function
 * @description Modify journal id validation
 * @param validationList Journal id validation list
 * @param payload response payload
 * @returns validation journal id list
 */
export const modifyValidationStatus = (validationList: any[], payload: any) => {
  try {
    const { rowKey, field } = payload;
    const rowIndex = validationList.findIndex(
      (item: any) => item?.rowKey == rowKey && item.field == field
    );

    if (rowIndex >= 0) {
      validationList[rowIndex] = {
        ...validationList[rowIndex],
        ...payload,
      };
    } else {
      validationList.push(payload);
    }

    return [...validationList];
  } catch (error) {
    return validationList;
  }
};

/**
 * @function
 * @description Reset journal id validation
 * @param validationList Journal id validation list
 * @param payload response payload
 * @returns validation journal id list
 */
export const restValidationStatus = (validationList: any[], payload: any) => {
  try {
    const { rowKey, field, type } = payload;
    const rowIndex = validationList.findIndex(
      (item: any) => item?.rowKey == rowKey && item.field == field
    );

    if (rowIndex >= 0) {
      validationList[rowIndex] = {
        ...validationList[rowIndex],
        ...payload,
      };
    }
    if (type == "delete") {
      validationList = validationList.filter(
        (item: any) => item.rowKey !== rowKey
      );
    }

    return [...validationList];
  } catch (error) {
    return validationList;
  }
};
