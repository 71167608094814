import { getFilenameFromHeaders } from "us.collection.economy/functions";
import {
  ISearchInvoices,
  IExportInvoices,
  IDownloadFile,
} from "us.collection.economy/interfaces";
import {
  httpCollection,
  versionedHttpCollection,
} from "us.helper/http/collection";

export default {
  searchInvoices: (params: ISearchInvoices): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.post(
          "clientinvoiceservice",
          "clientinvoice/search",
          params
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  exportInvoices: (
    { exportType, invoices }: IExportInvoices,
    searchParams: ISearchInvoices
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.post(
          "clientinvoiceservice",
          `clientinvoice/export/${exportType}`,
          invoices
        );
        if (
          status === 200 &&
          data.hasOwnProperty("isSuccess") &&
          data.isSuccess
        ) {
          const searchResponse = await httpCollection.post(
            "clientinvoiceservice",
            "clientinvoice/search",
            searchParams
          );
          resolve({ result: data, data: searchResponse.data });
        } else {
          resolve({ result: data, data: undefined });
        }
      } catch (error) {
        reject(error);
      }
    });
  },
  downloadInvoices: (params: number): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.post(
          "clientinvoiceservice",
          "ars/cases",
          `${params}/validationresult`
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },

  creditNoteAPI: {
    getDetails: (invoiceNo: string): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "clientinvoiceservice",
            `clientinvoice/${invoiceNo}`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    save: (
      creditNoteParams: any,
      searchParams: ISearchInvoices
    ): Promise<any> => {
      return new Promise<{
        result: Object;
        data: Array<{}>;
      }>(async (resolve, reject) => {
        try {
          if (Object.keys(searchParams).length != 0) {
            const result = await httpCollection.post(
              "clientinvoiceservice",
              "clientinvoice/creditinvoice",
              creditNoteParams
            );
            const search = await httpCollection.post(
              "clientinvoiceservice",
              "clientinvoice/search",
              searchParams
            );
            resolve({ result: result.data, data: search.data });
          } else {
            const result = await httpCollection.post(
              "clientinvoiceservice",
              "clientinvoice/creditinvoice",
              creditNoteParams
            );
            const details = await httpCollection.get(
              "clientinvoiceservice",
              `clientinvoice/${creditNoteParams?.invoiceNo}`,
              {}
            );
            resolve({ result: result.data, data: details.data });
          }
        } catch (error) {
          reject(error);
        }
      });
    },
    export: (
      exportParams: Array<{
        invoiceNo: number;
        invoiceId: number;
        isCreditInvoice: boolean;
      }>,
      searchParams: ISearchInvoices,
      exportType: string,
      prevInvoiceNo: number
    ): Promise<any> => {
      return new Promise<{
        result: Object;
        data: Array<{}>;
      }>(async (resolve, reject) => {
        try {
          if (Object.keys(searchParams).length != 0) {
            const result = await httpCollection.post(
              "clientinvoiceservice",
              `clientinvoice/export/${exportType}`,
              exportParams
            );
            const search = await httpCollection.post(
              "clientinvoiceservice",
              "clientinvoice/search",
              searchParams
            );
            resolve({ result: result.data, data: search.data });
          } else {
            const result = await httpCollection.post(
              "clientinvoiceservice",
              `clientinvoice/export/${exportType}`,
              exportParams
            );
            const details = await httpCollection.get(
              "clientinvoiceservice",
              `clientinvoice/${prevInvoiceNo}`,
              {}
            );
            resolve({ result: result.data, data: details.data });
          }          
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  downloadFile: (downloadFileParams: IDownloadFile): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status, headers } =
          await versionedHttpCollection.downloadFile(
            "commonservice",
            "common/downloadfiles",
            downloadFileParams,
            "v1"
          );
        const fileName = getFilenameFromHeaders(headers);
        if (status === 200) {
          const link = document.createElement("a");
          link.target = "_blank";
          link.download = fileName;
          link.href = URL.createObjectURL(new Blob([data]));
          link.click();
          resolve({ data, status });
        }
        reject(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
