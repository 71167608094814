export enum VatCodeTableColumn {
  MENU = "menu",
  VAT_ID = "vatId",
  VAT_CODE = "vatCode",
  NAME = "name",
  RATE = "rate",
  VAT_ACCOUNT = "accountID",
  START_DATE = "startDate",
  END_DATE = "endDate",
  DESCRIPTION = "description",
  MODIFIED_USER = "user",
}

export const VAT_CODE_TABLE_COLUMNS = [
  {
    id: 0,
    key: VatCodeTableColumn.MENU,
    title: "",
    align: "right",
  },
  {
    id: 1,
    key: VatCodeTableColumn.VAT_CODE,
    title: "VATCODE",
  },
  {
    id: 2,
    key: VatCodeTableColumn.NAME,
    title: "NAME",
    align: "right",
  },
  {
    id: 3,
    key: VatCodeTableColumn.RATE,
    title: "RATE",
    align: "right",
  },
  {
    id: 5,
    key: VatCodeTableColumn.START_DATE,
    title: "START_DATE",
    align: "right",
  },
  {
    id: 6,
    key: VatCodeTableColumn.DESCRIPTION,
    title: "DESCRIPTION",
    align: "right",
  },
  {
    id: 6,
    key: VatCodeTableColumn.MODIFIED_USER,
    title: "MODIFIED_USER",
    align: "right",
  },
];
