import moment from "moment";
import {
  COSearchType,
  COSortType,
} from "us.collection.economy/components/ClientOrders/Constants";
import { getOrdersMonthDateRange } from "us.collection.economy/functions";

export const INITIAL_FORM_VALUES = {
  startDate: getOrdersMonthDateRange().startDate,
  endDate: getOrdersMonthDateRange().endDate,
  manualOrdersOnly: false,
  searchType: COSearchType.REG_DATE,
  orderType: ["ALL"],
  orderNo: null,
  customers: [-1],
  customerGroups: undefined,
  offset: 0,
  limit: 1000,
  dateRange: [moment().startOf("month"), moment().endOf("month")],
  customerNo: undefined,
  isAllInvoices: false,
  selectedInvoices: [],
  sortType: COSortType.ORDER_NO,
};

export const INITIAL_COMPONENT_VALUES = {
  isCreditorsVisible: false,
  selectedOrders: [],
};
