import React from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined, EyeOutlined } from "us.icons";
import { Action } from "us.collection.economy/components/LedgerAccounts/Constants";
import { $Popconfirm } from "us.common/components";
import { IItemMenu } from "./interface";

/**
 * @description - Item Menu view if the table
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3094577191/Ledger+List+-+UI+Implementation
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 08/31/2022
 */
const ItemMenu: React.FC<IItemMenu> = ({ isSystem, onCallAction }) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content">
      {isSystem && (
        <div
          className="d-flex  flex-row more-item"
          data-testid="edit-btn"
          onClick={() => onCallAction(Action.VIEW)}
        >
          <div className="p-1">
            <EyeOutlined />
          </div>
          <div className="p-1">{t("COMMON.VIEW")}</div>
        </div>
      )}

      {!isSystem && (
        <>
          <div
            className="d-flex  flex-row more-item"
            data-testid="edit-btn"
            onClick={() => onCallAction(Action.EDIT)}
          >
            <div className="p-1">
              <EditOutlined />
            </div>
            <div className="p-1">{t("COMMON.EDIT")}</div>
          </div>
          <$Popconfirm
            title={t("US.COMMON:MANAGE_USER.SURE_TO_DELETE_?")}
            onConfirm={() => onCallAction(Action.DELETE)}
          >
            <div
              className="d-flex mb-2 flex-row more-item text-error"
              data-testid="delete-btn"
            >
              <div className="p-1">
                <DeleteOutlined />
              </div>
              <div className="p-1">{t("COMMON.DELETE")}</div>
            </div>
          </$Popconfirm>
        </>
      )}
    </div>
  );
};

export default ItemMenu;
