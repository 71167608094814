import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    sequenceId: Yup.string()
      .nullable()
      .required("US.COLLECTION.ECONOMY:VALIDATIONS.SEQUENCE_ID_IS_REQUIRED"),
    subSequenceId: Yup.string()
      .nullable()
      .required(
        "US.COLLECTION.ECONOMY:VALIDATIONS.SUB_SEQUENCE_ID_IS_REQUIRED"
      ),
  });
};
