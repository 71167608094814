export const initialDrawerValues = {
    title: '',
    visible: false,
    isEdit: false,
    selectedArticle: {
        articleId: 0,
        articleNo: '',
        category: '',
        categoryId: 0,
        defaultPrice: 0,
        description: '',
        nonVATAccountName: '',
        nonVATAccountNo: 0,
        revenueAccountName: '',
        revenueAccountNo: 0,
        vatCode: '',
        vatCodeName: '',
        isInUse: false,
    }
}

export const initialState = {
    articleList: [],
    vatCodes: [],
    categories: [],
    ledgerAccounts: [],
    drawerInfo: initialDrawerValues,
    isDuplicateArticleNo: false,
    isLoadingArticleData: false,
    isSavingArticleData: false
};

export interface IArticleList {
    articleId: number;
    articleNo: string;
    category: string;
    categoryId: number;
    defaultPrice: number;
    description: string;
    nonVATAccountName: string,
    nonVATAccountNo: number,
    revenueAccountName: string,
    revenueAccountNo: number,
    vatCode: string;
    vatCodeName: string;
    isInUse: boolean;
}

export interface IVatCodes {
    vatCodeId: number;
    vatcode: string;
    vatCodeName: string;
}

export interface ICategories {
    categoryId: number;
    categoryCode: string;
    categoryName: string;
}

export interface ILedgerAccounts {
    accountId: number;
    accountNo: string;
    accountName: string;
}

export interface IDrawerInfo {
    title: string,
    visible: boolean,
    isEdit: boolean,
    selectedArticle: IArticleList
}