import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { PaymentsImportSummary } from "us.collection.economy/constants/Actions";
const { paymentsImportSummary } = Actions;

const PaymentsImportSummarySaga = {
  remitHistory: {
    search: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(
          API.paymentsImportSummary.summary.search,
          params
        );
        if (status === 200 && Array.isArray(data)) {
          yield put(paymentsImportSummary.summary.success(data));
        } else {
          yield put(paymentsImportSummary.summary.success([]));
        }
      } catch (error) {
        yield put(paymentsImportSummary.summary.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(
    PaymentsImportSummary.GET_PAYMENTS_IMPORT_SUMMARY,
    PaymentsImportSummarySaga.remitHistory.search
  ),
];
