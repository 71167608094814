const services = {
  development: {
    catalogservice: {
      tenants: "v1",
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      creditorbmd: "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
      "payment.apportionment": "v1",
    },
    searchservice: {
      entitysearch: "v1",
    },
    extendeddataservice: {
      "extendeddata.extendedfields": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
    },
    economysettingservice:{
      economysettings: 'v1'
    }
  },
  qa: {
    catalogservice: {
      tenants: "v1",
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      creditorbmd: "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
      "payment.apportionment": "v1",
    },
    searchservice: {
      entitysearch: "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    searchservice: {
      entitysearch: "v1",

      "search.invoice": "v1",

      "search.case": "v1",

      "search.creditor": "v1",

      "search.debtor": "v1",

      "search.history": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
    },
    economysettingservice:{
      economysettings: 'v1'
    }
  },
  uat: {
    catalogservice: {
      tenants: "v1",
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      creditorbmd: "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
      "payment.apportionment": "v1",
    },
    searchservice: {
      entitysearch: "v1",
    },
    extendeddataservice: {
      "extendeddata.extendedfields": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
    },
    economysettingservice:{
      economysettings: 'v1'
    }
  },
  pp: {
    catalogservice: {
      tenants: "v1",
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      creditorbmd: "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
      "payment.apportionment": "v1",
    },
    searchservice: {
      entitysearch: "v1",
    },
    extendeddataservice: {
      "extendeddata.extendedfields": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    userservice: {
      "users.authorizationprofile": "v1",
      "users.accessauthoritycomments": "v1",
    },
    economysettingservice:{
      economysettings: 'v1'
    }
  },
  production: {
    catalogservice: {
      tenants: "v1",
    },
    entityservice: {
      entity: "v1",
      creditorGroups: "v1",
      "creditorGroups.save": "v1",
      "creditorGroups.update": "v1",
      "creditorGroups.delete": "v1",
      "creditorGroups.withCreditors": "v1",
      "entity.creditors": "v1",
      "entities.otherParties": "v1",
      "entities.otherParty.:entityId": "v1",
      "entities.otherParty": "v1",
      "debtor.adressinfo": "v1",
      "debtor.history": "v1",
      "debtor.debtorInfo": "v1",
      entities: "v1",
      "entities.contactperson": "v1",
      "entities.linkedcreditors": "v1",
      "entities.otherpartyRoleType": "v1",
      "entities.otherparty": "v1",
    },
    arservice: {
      ars: "v1",
      "ars.otherparty": "v1",
      "otherparty.otherpartyRoleTypes": "v1",
      "otherparty.otherpartyRoleType": "v1",
      "ars.interestData": "v1",
      "ars.case.interest": "v1",
      "atg.search": "v1",
      "atg.download": "v1",
      "objectionsCOP.save": "v1",
      "objectionsDO.save": "v1",
      "objection.profile": "v1",
      "ars.cancelObjection": "v1",
      "interestoccurrences.get": "v1",
      "interestoccurrences.save": "v1",
      "ars.caseInformation": "v1",
      "caseinformationdate.save": "v1",
      "interestoccurrences.delete": "v1",
    },
    uscservice: {
      note: "v1",
      "domainMessage.note": "v1",
      "domainMessage.template": "v1",
      "domainMessage.templateText": "v1",
      "domainMessage.basicInfo": "v1",
      "domainMessage.send": "v1",
      "usc.domainmessage": "v1",
      "usc.domainmessageText": "v1",
      "usc.domainmessage.basicdata": "v1",
      document: "v1",
    },
    paymentagreementservice: {
      paymentAgreement: "v1",
      "paymentAgreement.amount": "v1",
      "paymentAgreement.detail": "v1",
      "paymentAgreement.interest": "v1",
    },
    bmdservice: {
      creditorbmd: "v1",
    },
    systemservice: {
      CreditorDetails: "v1",
      entityhistorydetails: "v1",
    },
    paymentService: {
      payments: "v1",
      "payments.apportionments": "v1",
      "payment.apportionment": "v1",
    },
    searchservice: {
      entitysearch: "v1",
    },
    extendeddataservice: {
      "extendeddata.extendedfields": "v1",
    },
    documentservice: {
      "printed.documents": "v1",
      document: "v1",
    },
    activityservice: {
      "activities.settings": "v1",
      activities: "v1",
      activityhistory: "v1",
    },
    transactionservice: {
      "creditNote.add": "v1",
      delete: "v1",
    },
    noteservice: {
      note: "v1",
      "noteservice.note": "v1",
    },
    searchservice: {
      entitysearch: "v1",
      "search.invoice": "v1",
      "search.case": "v1",
      "search.creditor": "v1",
      "search.debtor": "v1",
      "search.history": "v1",
    },
    economysettingservice:{
      economysettings: 'v1'
    }
  },
  userservice: {
    "users.authorizationprofile": "v1",
    "users.accessauthoritycomments": "v1",
  },
};
export default () => services[window._ENV.NODE_ENV];
