import moment from "moment";

export const initialState = {
  payments: {
    paymentTypes: [],
    paymentMethods: [],
    isFetching: false,
    voucherNo: "0",
    validationCaseIdResults: {},
    isPaymentTypeFetching:false,
    paymentId:[],
    isvalidating:false,
    isVoucherNoFetching: false,
    isAmountDisable: false,
    isPaymentSaving: false
  },
  formInitial:{
    paymentType: 47,
    paymentMethod: 0,
    datePaid: moment(),
    voucherReference: '',
    accountNo: '',
    voucherAmount: '',
    caseNo:'',
    amount:'',
    caseAmount:'',
    kid:'',
  }
};