import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";

import { useSignalRTrigger } from "us.common/SignalRProvider";
import Common from "us.common";
import { DownloadOutlined } from "us.icons";
import "us.collection.economy/components/ClientInvoices/Home.scss";
import { Filter, Header, CreditNote, InvoiceList } from "./Components";
import * as Actions from "us.collection.economy/actions";
import { INITIAL_FORM_VALUES } from "us.collection.economy/constants/ClientInvoices";
import {
  CIExportStatus,
  CISearchType,
  CISortType,
  InvoiceAction,
} from "us.collection.economy/components/ClientInvoices/Constants";
import { IRootState } from "us.collection/interfaces";
import { SearchInvoices } from "us.collection.economy/components/ClientInvoices/Repository";
import { IClientInvoices } from "us.collection.economy/interfaces";
import { ValidationSchema } from "us.collection.economy/components/ClientInvoices/Validations";
import {
  getAllSelectedInvoices,
  getDownloadableInvoices,
  getExportInvoiceData,
  getExportMethod,
  getFileNames,
  getInvoiceSortingOptions,
  hasInvoicesToExport,
} from "us.collection.economy/components/ClientInvoices/Functions";
import {
  IInvoice,
  IInvoiceAction,
} from "us.collection.economy/components/ClientInvoices/Interfaces";
import { ExportInvoices, DownloadNavisionFile } from "./Repository";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";
import { useHistory } from "react-router-dom";

const {
  $Tabs,
  $Select,
  $Button,
  $Checkbox,
  $Skeleton,
  $Empty,
  $SelectCreditors,
  $Message,
  $Affix,
} = Common.Components;

const Home: React.FC<PropsFromRedux & IClientInvoices> = (props) => {
  const { t } = useTranslation();
  const {
    totalInvoices,
    totalInvoicesAmount,
    exported,
    pendingExport,
    isLoadingInvoices,
    activeTabKey,
    invoiceExport,
    search,
    filter,
    sort,
    manageCreditNoteDrawer,
    getCreditorInvoice,
    onChangeTab,
    exportInvoice,
    resetInvoiceList,
    download,
    refreshInvoices,
  } = props;

  const formikRef = useRef<any>(null);
  const { replace, push } = useHistory();

  // invoices renamed
  const exportedInvoices = exported.filtered;
  const pendingExportInvoices = pendingExport.filtered;
  const [openCreditors, setOpenCreditors] = useState<boolean>(false);
  const [filterValues, setFilterValues] = useState<any>(INITIAL_FORM_VALUES);
  const [selectedInvoices, setSelectedInvoices] = useState<Array<IInvoice>>([]);
  const { setTrigger } = useSignalRTrigger();

  useEffect(() => {
    onChangeTab && onChangeTab({ activeKey: 0 });
    onSearch(INITIAL_FORM_VALUES);
  }, []);

  useEffect(() => {
    if (invoiceExport.result && invoiceExport.result.isSuccess) {
      setSelectedInvoices([]);
    }
  }, [invoiceExport]);

  useEffect(() => {
    setTrigger([
      {
        name: "onNavisionFileGeneration",
        callBack: (_response: any) => {
          const params = SearchInvoices.call(formikRef?.current?.values);
          refreshInvoices && refreshInvoices(params);
        },
      },
    ]);
  }, []);

  /**
   * reset advanced filter values
   * @param {any} restProps - any -&gt; this is the props that are passed to the component
   */
  const resetAdvanceFilters = (restProps: any) => {
    restProps.setFieldValue("filterInvoiceNo", "");
    restProps.setFieldValue("customerNo", "");
    restProps.setFieldValue("duePeriod", []);
    setFilterValues({
      ...filterValues,
      filterInvoiceNo: "",
      customerNo: "",
      duePeriod: [],
    });
  };

  /**
   * onSearch
   * search invoices API will call with the filter values
   */
  const onSearch = (values: any) => {
    // reset selected invoices list
    setSelectedInvoices([]);
    // replace the scrolling to the top
    window.scrollTo({ top: 0, behavior: "smooth" });
    // manage start and end date from date range or assign default dates
    const [startDate, endDate] = values.dateRange ?? [
      moment().startOf("month"),
      moment().endOf("month"),
    ];
    // update form values state
    setFilterValues({
      ...filterValues,
      ...values,
      isAllInvoices: false,
      filterInvoiceNo: "",
      customerNo: "",
      duePeriod: [],
      sortType: CISortType.INVOICE_NO,
      startDate: moment(startDate).format(DATE_FORMAT_FOR_SERVER),
      endDate: moment(endDate).format(DATE_FORMAT_FOR_SERVER),
    });

    const params = SearchInvoices.call(values);
    search && search(params);
  };

  /**
   * @description - Handle date range change
   * @param {any} values - Form values
   * @param {any} restProps - Formik restProps
   */
  const onChangeDateRange = (values: any, restProps: any) => {
    let dateRange = values.dateRange;
    if (!values.dateRange) {
      dateRange = [moment().startOf("month"), moment().endOf("month")];
    }
    if (values.searchType === CISearchType.EXPORTED_DATE) {
      dateRange = [moment().startOf("month"), moment()];
      onChangeTab && onChangeTab({ activeKey: 1 });
    }
    restProps.setFieldValue("dateRange", dateRange);
    if (values.searchType === CISearchType.INVOICE_NO) {
      resetInvoiceList && resetInvoiceList();
    } else {
      resetAdvanceFilters(restProps);
      onSearch({
        ...values,
        dateRange,
      });
    }
  };

  /**
   * @description OnSelectCreditors is a function that takes in a parameter called values
   * @param {any} values - any - this is the values that are passed from the modal
   */
  const onSelectCreditors = (values: any) => {
    setFilterValues({ ...filterValues, ...values });
    setOpenCreditors(false);
    onSearch(values);
  };

  /**
   * @description Handle invoice item action calls
   * @param {IInvoiceAction} actionType - Invoice action type
   * @param {IInvoice} data - Invoice action related invoice data
   */
  const onHandleInvoiceAction = (
    actionType: IInvoiceAction,
    invoice: IInvoice
  ) => {
    switch (actionType) {
      case InvoiceAction.CREDIT_NOTE:
        getCreditorInvoice && getCreditorInvoice(invoice.invoiceNo, true);
        break;
      case InvoiceAction.DOWNLOAD:
        onDownloadInvoices([invoice]);
        break;
      case InvoiceAction.PRINT:
        $Message.info(t("US.COMMON:COMMON.NOT_IMPLEMENTED"));
        break;
      case InvoiceAction.VIEW_INVOICE_DETAILS:
        push(`/creditor-invoice-details?invoiceNo=${invoice?.invoiceNo}`);
        break;
      default:
        break;
    }
  };

  /**
   * @description Update the state when select an invoice
   */
  const onSelectInvoiceToExport = (
    isSelected: boolean,
    invoice: IInvoice,
    values: any,
    restProps: any
  ) => {
    try {
      let invoiceList: Array<IInvoice> = selectedInvoices;
      if (values?.isAllInvoices) {
        invoiceList = invoiceList.filter(
          ({ invoiceId }: IInvoice) => invoiceId !== invoice.invoiceId
        );
        restProps.setFieldValue("isAllInvoices", false);
      } else {
        if (isSelected) {
          // checkbox checked add to list
          invoiceList.push(invoice);
          restProps.setFieldValue(
            "isAllInvoices",
            pendingExportInvoices?.length === invoiceList.length
          );
        } else {
          // checkbox unchecked remove from the list
          invoiceList = invoiceList.filter(
            ({ invoiceId }: IInvoice) => invoiceId !== invoice.invoiceId
          );
        }
      }
      setSelectedInvoices(invoiceList);
    } catch (error) {
      console.log(error);
    }
  };

  /**
   * @description handle invoice export
   * @param {any} values - form values
   */
  const onExportInvoices = (values: any) => {
    const invoices = getExportInvoiceData(selectedInvoices);
    const exportType = getExportMethod(selectedInvoices);
    const exportParams = ExportInvoices.call({ exportType, invoices });
    const searchParams = SearchInvoices.call(values);
    exportInvoice && exportInvoice({ exportParams, searchParams });
  };

  /**
   * @description handle download all exported invoices
   * @param {Array<IInvoice>} invoices - list of invoices to download files
   */
  const onDownloadInvoices = (invoices: Array<IInvoice>) => {
    const downloadableInvoice = getDownloadableInvoices(invoices);
    const fileNames = getFileNames(downloadableInvoice);
    const exportMethod = getExportMethod(downloadableInvoice);
    const params = DownloadNavisionFile.call({ fileNames, exportMethod });
    download && download(params);
  };

  /**
   * @description render sorting options
   * @param {any} values form values
   */
  const sortOptions = (values: any) => {
    const sortingOptions = getInvoiceSortingOptions(values);

    return (
      <$Select
        dataTestid="select-sort-type"
        name="sortType"
        formitem={{}}
        size="small"
        options={sortingOptions}
        optionValue="sortType"
        optionText="label"
        defaultValue={CISortType.INVOICE_NO}
        defaultLabel={t("US.COLLECTION.ECONOMY:INVOICES.INVOICE_NO")}
        allOption={false}
        showSearch={false}
        bordered={false}
        onChange={sort}
        style={{ width: 130 }}
      />
    );
  };

  return (
    <Formik
      initialValues={filterValues}
      onSubmit={onSearch}
      validationSchema={ValidationSchema}
      enableReinitialize
      validateOnChange
      validateOnBlur
      innerRef={formikRef}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <Header
              restProps={restProps}
              currentDateRange={filterValues.dateRange}
              onSearch={() => {
                restProps.setFieldValue("sortType", CISortType.INVOICE_NO);
                resetAdvanceFilters(restProps);
                restProps.isValid && onSearch(values);
              }}
              creditInvoice={() =>
                manageCreditNoteDrawer && manageCreditNoteDrawer({})
              }
              onChange={(filters: any) =>
                onChangeDateRange({ ...values, ...filters }, restProps)
              }
              disabled={
                (values.searchType === CISearchType.INVOICE_NO &&
                  !restProps.isValid) ||
                !values.dateRange
              }
            />
          </div>
          <div className="invoices-layout">
            <Filter
              restProps={restProps}
              totalInvoices={totalInvoices}
              totalInvoicesAmount={totalInvoicesAmount}
              values={{ ...values, activeTabKey }}
              onChange={(filters: any) => {
                resetAdvanceFilters(restProps);
                onSearch({ ...values, ...filters });
              }}
              onSelectCreditors={() => setOpenCreditors(true)}
              onReset={() => {
                resetAdvanceFilters(restProps);
                filter && filter({ ...values, isReset: true });
                window.scrollTo({ top: 0, behavior: "smooth" });
              }}
              creditors={values.customers}
              onFilter={(value: any) => {
                window.scrollTo({ top: 0, behavior: "smooth" });
                filter && filter({ ...values, ...value, isReset: false });
              }}
            />
            <div className="d-flex flex-fill justify-content-center">
              <div>
                <div className="content-invoices">
                  <$Tabs
                    tabBarExtraContent={
                      values.searchType !== CISearchType.INVOICE_NO && {
                        right: sortOptions(values),
                      }
                    }
                    onChange={(activeKey: React.Key | null | undefined) => {
                      activeKey && onChangeTab && onChangeTab({ activeKey });
                      resetAdvanceFilters(restProps);
                      const exportStatus =
                        activeKey === "0"
                          ? CIExportStatus.PENDING_EXPORT
                          : CIExportStatus.EXPORTED_OR_NAVISION;
                      restProps.setFieldValue(
                        "sortType",
                        CISortType.INVOICE_NO
                      );
                      restProps.setFieldValue("exportStatus", exportStatus);
                      values.searchType !== CISearchType.INVOICE_NO &&
                        onSearch({
                          ...values,
                          exportStatus,
                          sortType: CISortType.INVOICE_NO,
                        });
                    }}
                    activeKey={`${activeTabKey}`}
                  >
                    {/* Pending Export Tab */}
                    <$Tabs.TabPane
                      tab={t("US.COLLECTION.ECONOMY:INVOICES.PENDING_EXPORT")}
                      key={0}
                      id="tab-panel-pending-export"
                      disabled={
                        values.searchType === CISearchType.EXPORTED_DATE
                      }
                    >
                      <$Affix offsetTop={95}>
                        <div className="d-flex pl-2 mb-2 align-items-center bg-white py-2">
                          {!isLoadingInvoices &&
                            pendingExportInvoices.length > 0 && (
                              <div
                                className="d-flex mr-2"
                                data-testid="pending-export-tab-header"
                              >
                                <$Checkbox
                                  name="isAllInvoices"
                                  checked={values.isAllInvoices}
                                  disabled={
                                    !hasInvoicesToExport(pendingExportInvoices)
                                  }
                                  onChange={(e: any) => {
                                    restProps.setFieldValue(
                                      "isAllInvoices",
                                      e.target.checked
                                    );
                                    setSelectedInvoices(
                                      e.target.checked
                                        ? getAllSelectedInvoices(
                                            pendingExport.filtered
                                          )
                                        : []
                                    );
                                    setFilterValues({
                                      ...values,
                                      isAllInvoices: e.target.checked,
                                    });
                                  }}
                                >
                                  {t("US.COLLECTION.ECONOMY:INVOICES.ALL")}
                                </$Checkbox>
                                <$Button
                                  id="btn-export"
                                  className="ml-2"
                                  size="small"
                                  type="primary"
                                  disabled={selectedInvoices.length === 0}
                                  onClick={() => onExportInvoices(values)}
                                  loading={invoiceExport.isLoading}
                                >
                                  {t(
                                    "US.COLLECTION.ECONOMY:INVOICES.EXPORT_INVOICE"
                                  )}
                                </$Button>
                              </div>
                            )}
                        </div>
                      </$Affix>
                      {isLoadingInvoices && (
                        <$Skeleton
                          loading={true}
                          active
                          paragraph={{ rows: 1 }}
                          className="p-3"
                          data-testid="payments-skeleton"
                        />
                      )}
                      {!isLoadingInvoices &&
                        pendingExportInvoices.length === 0 && (
                          <div
                            className="flex-fill pt-5"
                            data-testid="empty-normal"
                          >
                            <$Empty
                              image={$Empty.PRESENTED_IMAGE_SIMPLE}
                              description={t(
                                "US.COLLECTION.ECONOMY:INVOICES.NO_PENDING_EXPORT_INVOICES_AVAILABLE"
                              )}
                            />
                          </div>
                        )}
                      {!isLoadingInvoices &&
                        pendingExportInvoices.length > 0 && (
                          <InvoiceList
                            pending
                            invoices={pendingExportInvoices}
                            selectedInvoices={selectedInvoices}
                            onCallAction={onHandleInvoiceAction}
                            onSelectInvoice={(
                              isSelected: boolean,
                              invoice: IInvoice
                            ) =>
                              onSelectInvoiceToExport(
                                isSelected,
                                invoice,
                                values,
                                restProps
                              )
                            }
                          />
                        )}
                    </$Tabs.TabPane>
                    {/* Exported Tab */}
                    <$Tabs.TabPane
                      tab={t("US.COLLECTION.ECONOMY:INVOICES.EXPORTED")}
                      key={1}
                      id="tab-panel-exported"
                    >
                      <$Affix offsetTop={95}>
                        <div>
                          <div className="d-flex pl-2 mb-2 align-items-center bg-white py-2">
                            {!isLoadingInvoices && exportedInvoices.length > 0 && (
                              <div className="d-flex mr-2">
                                <$Button
                                  id="btn-download-all"
                                  className="ml-2 font-weight-bold"
                                  size="small"
                                  type="link"
                                  icon={<DownloadOutlined />}
                                  onClick={() =>
                                    onDownloadInvoices(exportedInvoices)
                                  }
                                >
                                  {t(
                                    "US.COLLECTION.ECONOMY:INVOICES.DOWNLOAD_ALL"
                                  )}
                                </$Button>
                              </div>
                            )}
                          </div>
                        </div>
                      </$Affix>
                      {isLoadingInvoices && (
                        <$Skeleton
                          loading={true}
                          active
                          paragraph={{ rows: 1 }}
                          className="p-3"
                          data-testid="payments-skeleton"
                        />
                      )}
                      {!isLoadingInvoices && exportedInvoices.length === 0 && (
                        <div
                          className="flex-fill pt-5"
                          data-testid="empty-normal"
                        >
                          <$Empty
                            image={$Empty.PRESENTED_IMAGE_SIMPLE}
                            description={t(
                              "US.COLLECTION.ECONOMY:INVOICES.NO_EXPORTED_INVOICES_AVAILABLE"
                            )}
                          />
                        </div>
                      )}
                      {!isLoadingInvoices && exportedInvoices.length > 0 && (
                        <InvoiceList
                          invoices={exportedInvoices}
                          onCallAction={onHandleInvoiceAction}
                        />
                      )}
                    </$Tabs.TabPane>
                  </$Tabs>
                </div>
              </div>
            </div>
          </div>

          <$SelectCreditors
            name="customers"
            groupName="customerGroups"
            visible={openCreditors}
            onClose={() => setOpenCreditors(false)}
            onConfirm={(
              customers: Array<number>,
              customerGroups: Array<number>
            ) => {
              onSelectCreditors({
                ...values,
                customers,
                customerGroups,
              });
            }}
          />
          <CreditNote filterValues={filterValues} />
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, clientInvoices } = state;

  const {
    exported,
    pendingExport,
    totalInvoices,
    totalInvoicesAmount,
    isLoadingInvoices,
    activeTabKey,
    invoiceExport,
  } = clientInvoices;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    totalInvoices,
    totalInvoicesAmount,
    isLoadingInvoices,
    activeTabKey,
    exported,
    pendingExport,
    invoiceExport,
  };
};
const { invoices, creditNoteDetails, exportInvoice, navisionFile } =
  Actions.clientInvoices;
const { handleDrawer, getDetails } = creditNoteDetails;
const { search, sort, filter, onChangeTab, resetInvoiceList, refresh } =
  invoices;

const mapDispatchToProps = {
  resetInvoiceList,
  search,
  sort,
  filter,
  manageCreditNoteDrawer: handleDrawer,
  getCreditorInvoice: getDetails,
  onChangeTab,
  exportInvoice: exportInvoice.save,
  download: navisionFile.download,
  refreshInvoices: refresh,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
