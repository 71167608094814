import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import { COLUMNS, RemitHistoryTableColumn } from "./Constants";
import { SearchOutlined } from "us.icons";
import { Align } from "us.collection.economy/constants";
import { connect, ConnectedProps } from "react-redux";
import { getColumnWidth } from "us.collection.economy/components/RemitHistory/Functions";
import { RootState } from "us.helper/types";
import { RemitDetails } from "us.collection.economy/interfaces";
import { getColumnFilterProps } from "us.collection.economy/components/RemitHistory/Components/RemitHistoryTable/Functions";
import { $Input, $RangePicker } from "us.common/components";
import { SearchRemitHistory } from "../../Repository";
import * as Actions from "us.collection.economy/actions";
import { IJournalSelection } from "../../Interfaces";
import { Formik } from "formik";

const { $Button, $AmountLabel, $DateLabel, $TableTree } = Components;

/**
 * @description - Journal id selection
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3121676306/Add+Journal+Entries+-+UI+Implementation+Design
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 19/10/2022
 */
const JournalSelection: React.FC<IJournalSelection & PropsFromRedux> = (
  props
) => {
  const {
    searchRemitHistory,
    remitHistory,
    selectJournalId,
    currentDateFormat,
  } = props;
  const { t } = useTranslation();

  const [selectedRowKey, setSelectedRowKey] = useState(0);

  /**
   * @description - Render rows
   */
  const renderCell = (record: RemitDetails, dataIndex: string) => {
    const {
      journalId,
      fileDate,
      bureauAmount,
      creditorAmount,
      debtorAmount,
      totalAmount,
      remitVoucherNo,
    } = record;
    const row = {
      children: (
        <>
          {dataIndex === RemitHistoryTableColumn.JOURNAL_ID && (
            <div className="align-items-center" key={remitVoucherNo}>
              <span className="ml-2" data-testid="journalId">
                {journalId}
              </span>
            </div>
          )}
          {dataIndex === RemitHistoryTableColumn.FILE_DATE && (
            <$DateLabel value={fileDate} />
          )}
          {dataIndex === RemitHistoryTableColumn.BUREAU_AMOUNT && (
            <$AmountLabel value={bureauAmount} data-testid={"bureau-amount"} />
          )}
          {dataIndex === RemitHistoryTableColumn.CREDITOR_AMOUNT && (
            <$AmountLabel
              value={creditorAmount}
              data-testid={"creditor-amount"}
            />
          )}
          {dataIndex === RemitHistoryTableColumn.DEBTOR_AMOUNT && (
            <$AmountLabel value={debtorAmount} data-testid={"debtor-amount"} />
          )}
          {dataIndex === RemitHistoryTableColumn.TOTAL_AMOUNT && (
            <$AmountLabel value={totalAmount} data-testid={"total-amount"} />
          )}
        </>
      ),
    };
    return row;
  };

  /**
   * @description - Generate table columns with sorter and filters
   * @returns - An array of columns
   */
  const getColumns = (): any[] => {
    const columns: any[] = [];
    COLUMNS.map(({ key, title }, _index) => {
      const columnWidth = getColumnWidth(key);
      let column: any = {
        dataIndex: key,
        key,
        width: columnWidth,
        title: title && t(`US.COLLECTION.ECONOMY:REMIT_HISTORY.${title}`),
        ...getColumnFilterProps(key),
        customRenderChild: (_text: any, record: any) => renderCell(record, key),
        align: [
          RemitHistoryTableColumn.BUREAU_AMOUNT,
          RemitHistoryTableColumn.CREDITOR_AMOUNT,
          RemitHistoryTableColumn.DEBTOR_AMOUNT,
          RemitHistoryTableColumn.TOTAL_AMOUNT,
        ].includes(key)
          ? Align.RIGHT
          : Align.LEFT,
      };
      columns.push(column);
    });
    return columns;
  };

  /**
   * Search journal id list
   * @param remitDateRange Search date range
   * @param journalId Search journal id
   */
  const onSearch = (remitDateRange?: any, journalId?: any) => {
    if (remitDateRange || journalId) {
      searchRemitHistory &&
        searchRemitHistory(
          SearchRemitHistory.call({ remitDateRange, journalId })
        );
    }
  };

  /**
   * Row selection for journal details table
   */
  const rowSelection = {
    onSelect: (record: any) => {
      setSelectedRowKey(record.journalId);
      selectJournalId(record.journalId);
    },
    selectedRowKeys: [selectedRowKey],
  };

  return (
    <Formik
      initialValues={{
        remitDateRange: [],
        journalIdText: "",
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      validateOnMount
      onSubmit={() => {}}
    >
      {({ values }) => (
        <>
          <div className="d-flex">
            <$RangePicker
              name={`remitDateRange`}
              size="small"
              style={{ width: 220 }}
              picker="date"
              minuteStep={15}
              order={false}
              currentTimeFormat={currentDateFormat}
              onChange={(remitDateRange: any) => {
                onSearch(remitDateRange, undefined);
              }}
            />
            <$Input
              name="journalIdText"
              size="small"
              type="text"
              className="ml-2"
              placeholder={t("US.COLLECTION.ECONOMY:JOURNAL.JOURNAL_ID")}
              onPressEnter={(e: any) => onSearch(undefined, e.target.value)}
              suffix={
                <$Button
                  id={"btnSearch"}
                  onClick={() => onSearch(undefined, values?.journalIdText)}
                  type="link"
                  size="small"
                  icon={<SearchOutlined />}
                  loading={remitHistory.isLoading}
                />
              }
            />
          </div>

          <$TableTree
            rowKey={(record: any) => record.journalId}
            data={remitHistory.data}
            size="small"
            className="mt-3 table-striped header-custom-tag"
            onSort={(sortData, dataSource) => {
              return sortData(dataSource);
            }}
            onFilter={(searchData, dataSource) => {
              return searchData(dataSource);
            }}
            filterOnType
            resetOnSourceChange
            bordered
            pagination={{
              defaultPageSize: 20,
            }}
            scroll={{
              x: 900,
              y: "calc(100vh - 265px)",
            }}
            columns={getColumns()}
            rowSelection={{
              ...rowSelection,
              type: "radio",
            }}
            onRow={(record: any) => {
              return {
                onDoubleClick: () => selectJournalId(record.journalId),
              };
            }}
          />
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, journalEntry } = state;

  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  const { remitHistory } = journalEntry;

  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    remitHistory,
  };
};

const { remitHistory } = Actions.JournalEntry;

const mapDispatchToProps = {
  searchRemitHistory: remitHistory.search,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(JournalSelection);
