import { useTranslation } from "react-i18next";
import { IntlCurrency } from "us.common/functions";
import { IRecord } from "us.collection.economy/components/RemitHistory/Components/LedgerDashboard/Interfaces";
/**
 * @remarks
 * This method is a part of the {Ledger dashboard | Economy Module}.
 * @description - Format ledger accounts for csv export
 * @param accounts - Array of accounts
 * @returns An array of formatted data
 */
export const getExportData = (
  accounts: Array<IRecord>,
  currentLanguage: any,
  currentCurrency: any
): Array<any> => {
  const { t } = useTranslation();

  try {
    const excelHeaderData: any = [
      t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.ACCOUNT_NO"),
      t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.ACCOUNT_NAME"),
      t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.DEBIT_AMOUNT"),
      t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.CREDIT_AMOUNT"),
      t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.BALANCE"),
    ];

    const excelBodyData: any = [];
    accounts?.map((rw: IRecord) => {
      excelBodyData.push([
        rw.accountNo,
        rw.accountName,
        replaceSpaces(
          IntlCurrency(rw.debitAmount, currentLanguage, currentCurrency).trim()
        ),
        replaceSpaces(
          IntlCurrency(rw.creditAmount, currentLanguage, currentCurrency).trim()
        ),
        replaceSpaces(
          IntlCurrency(
            rw.debitAmount - rw.creditAmount,
            currentLanguage,
            currentCurrency
          )
        ).trim(),
      ]);
    });

    const totalBalanceSum: any = [
      [
        t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.TOTAL"),
        ,
        ,
        ,
        replaceSpaces(
          IntlCurrency(
            accounts.reduce(
              (
                total: number,
                {
                  debitAmount,
                  creditAmount,
                }: { debitAmount: number; creditAmount: number }
              ) => total + (debitAmount - creditAmount),
              0
            ),
            currentLanguage,
            currentCurrency
          )
        ).trim(),
      ],
    ];
    return [excelHeaderData, ...excelBodyData, ...totalBalanceSum];
  } catch (error) {
    return [];
  }
};

function replaceSpaces(str: any) {
  let formattedString = new RegExp(String.fromCharCode(160), "g");
  return str.replace(formattedString, " ");
}

/**
 * @remarks
 * This method is a part of the {Ledger dashboard | Economy Module}.
 * @description - Calculate total carry forward balance
 * @param accounts - Array of accounts
 * @returns An object of total balances
 */
export const getCarryingForwardBalances = (
  accounts: Array<IRecord>
): { debitBalance: number; creditBalance: number } => {
  try {
    let debitBalance: number = 0;
    let creditBalance: number = 0;

    accounts.map(
      ({
        creditAmount,
        debitAmount,
      }: {
        creditAmount: number;
        debitAmount: number;
      }) => {
        if (creditAmount !== debitAmount) {
          debitBalance = debitBalance + debitAmount;
          creditBalance = creditBalance + creditAmount;
        }
      }
    );

    return { debitBalance, creditBalance };
  } catch (error) {
    return { debitBalance: 0, creditBalance: 0 };
  }
};
