import moment from "moment";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";
import { RemitHistorySearchType } from "../Constants";

export function SearchRemitHistory(this: any) {
  const [startDate, endDate] = this.remitDateRange ?? [];
  return {
    startDate: moment(startDate).startOf("day").format(DATE_FORMAT_FOR_SERVER),
    endDate: moment(endDate).endOf("day").format(DATE_FORMAT_FOR_SERVER),
    remitFileStatus: "ACTIVE",
    accountNo: "",
    journalId: this.journalId ?? "" ,
    searchType: this.journalId
      ? RemitHistorySearchType.JOURNAL_ID
      : RemitHistorySearchType.FILE_DATE,
    offset: 0,
    limit: 1000,
  };
}
