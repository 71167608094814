import { modifyRemitRecords } from "./../../functions/RemitHistory/functions";
import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { RemitHistory } from "us.collection.economy/constants/Actions";
import { $Message, $MessageBox } from "us.common/components";
import { i18n } from "us.helper";
const { remitHistory } = Actions;
const { remits, ledgerDetails } = API.remitHistory;
const { init, search, reverse } = remits;

const RemitHistorySagas = {
  history: {
    init: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { remits, accounts, status } = yield call(init, params);
        yield put(remitHistory.bankAccounts.success(accounts));
        if (status === 200 && remits) {
          yield put(remitHistory.remits.success(modifyRemitRecords(remits)));
        } else {
          yield put(remitHistory.remits.fail(new Error()));
        }
      } catch (error) {
        yield put(remitHistory.remits.fail(new Error()));
      }
    },
    search: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { remits, status } = yield call(search, params);
        if (status === 200 && Array.isArray(remits)) {
          yield put(remitHistory.remits.success(modifyRemitRecords(remits)));
        } else {
          yield put(remitHistory.remits.success([]));
        }
      } catch (error) {
        yield put(remitHistory.remits.fail(new Error()));
      }
    },
  },
  remit: {
    download: function* (action: any) {
      $Message.loading(i18n.t("COMMON.DOWNLOADING"), 0);
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, remits } = yield call(
          API.remitHistory.remits.download,
          params
        );
        if (status === 200 && data) {
          yield put(remitHistory.remitFile.success(data));
          yield put(remitHistory.remits.success(modifyRemitRecords(remits)));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.REMIT_FILE_DOWNLOAD_FAILED",
            "",
            ""
          );
          yield put(remitHistory.remitFile.fail(data));
        }
      } catch (error) {
        yield put(remitHistory.remitFile.fail(new Error()));
      } finally {
        $Message.destroy();
      }
    },
    reverse: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        $Message.loading(i18n.t("COMMON.REVERSING"), 0);
        const { status, remitReverse, remits } = yield call(reverse, params);
        $Message.destroy();
        if (
          remitReverse.hasOwnProperty("statusCode") &&
          remitReverse.statusCode === 1
        ) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:REMIT_HISTORY.REVERSE_REMIT_SUCCESS",
            "",
            ""
          );
          yield put(
            remitHistory.remit.success({
              status,
              remitReverse,
              remitHistory: modifyRemitRecords(remits),
            })
          );
        } else if (
          status == 412 &&
          remitReverse.hasOwnProperty("message") &&
          remitReverse.message.statusMessage
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:REMIT_HISTORY.${remitReverse.statusMessage}`,
            "",
            ""
          );
          yield put(remitHistory.remit.fail(new Error()));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:REMIT_HISTORY.REVERSE_REMIT_FAILED",
            "",
            ""
          );
          yield put(remitHistory.remit.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:REMIT_HISTORY.REVERSE_REMIT_FAILED",
          "",
          ""
        );
        yield put(remitHistory.remit.fail(new Error()));
      }
    },
  },
  ledgerDetails: {
    get: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const details = yield call(ledgerDetails.get, params?.journalId);
        yield put(remitHistory.ledgerDetails.success(details));
      } catch (error) {
        yield put(remitHistory.ledgerDetails.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(RemitHistory.INIT_REMIT_HISTORY, RemitHistorySagas.history.init),
  takeLatest(
    RemitHistory.SEARCH_REMIT_HISTORY,
    RemitHistorySagas.history.search
  ),
  takeLatest(
    RemitHistory.DOWNLOAD_REMIT_FILE,
    RemitHistorySagas.remit.download
  ),
  takeLatest(RemitHistory.REVERSE_REMIT, RemitHistorySagas.remit.reverse),
  takeLatest(
    RemitHistory.GET_LEDGER_DETAILS,
    RemitHistorySagas.ledgerDetails.get
  ),
];
