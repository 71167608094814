import { i18n } from "us.helper";
import moment from "moment";
import { IInvoice } from "us.collection.economy/components/ClientInvoices/Interfaces";
import { IExportInvoiceData } from "us.collection.economy/interfaces";
import {
  CIExportStatus,
  CISearchType,
  CISortType,
  DEFAULT_INVOICE_SORTING_OPTIONS,
  InvoiceExportStatus,
} from "us.collection.economy/components/ClientInvoices/Constants";

/**
 * @remarks
 * This method is a part of the {Creditor invoice list | Economy Module}.
 * @description - Filter invoices by given filter options
 * @param {Array<IInvoice>} invoices - Array of invoices
 * @param {string} filterInvoiceNo - invoice no search text
 * @param {string} filterCustomerNo - customer no search text
 * @param {Array<any>} duePeriod - Due date period
 * @returns {Array<any>} An array of sorted data
 */
export const filterInvoices = (
  invoices: Array<IInvoice>,
  filterInvoiceNo: string,
  filterCustomerNo: string,
  duePeriod: Array<any>
): Array<IInvoice> => {
  try {
    const filteredByInvoiceNo = filterInvoiceNo
      ? invoices.filter(({ invoiceNo }: IInvoice) =>
          invoiceNo.toString().includes(filterInvoiceNo)
        )
      : invoices;

    const filteredByCustomerNo = filterCustomerNo
      ? filteredByInvoiceNo.filter(({ customerNo }: IInvoice) =>
          customerNo.toString().includes(filterCustomerNo)
        )
      : filteredByInvoiceNo;
    const filteredByDueDate =
      duePeriod && duePeriod.length > 0
        ? filteredByCustomerNo.filter(
            ({ dueDate }: IInvoice) =>
              moment(dueDate).isSameOrAfter(
                moment(duePeriod[0]).startOf("day")
              ) &&
              moment(dueDate).isSameOrBefore(moment(duePeriod[1]).endOf("day"))
          )
        : filteredByCustomerNo;

    return filteredByDueDate;
  } catch (error) {
    return invoices;
  }
};

/**
 * @remarks
 * This method is a part of the {Creditor invoice list | Economy Module}.
 * @description This will return total no of invoices and total invoices amount
 * @param {Array<IInvoice>} invoices - An array of data
 * @returns {{totalInvoices: number, totalInvoicesAmount: number}}
 */
export const getInvoiceSummery = (
  invoices: Array<IInvoice>
): {
  totalInvoices: number;
  totalInvoicesAmount: number;
} => {
  let totalInvoices = 0,
    totalInvoicesAmount = 0;
  try {
    if (invoices && invoices.length > 0) {
      totalInvoices = invoices[0].recordCount;
      totalInvoicesAmount = invoices[0].totalAmount;
    }
    return { totalInvoices, totalInvoicesAmount };
  } catch (error) {
    return { totalInvoices, totalInvoicesAmount };
  }
};

/**
 * @remarks
 * This method is a part of the {Creditor invoice list | Economy Module}.
 * @description - Sort any array of data by object key
 * @param {Array<any>} data - Array of data
 * @param {string} key - Sort by key
 * @param {"ASC" | "DSC"} type - Whether the sorting is ASCENDING or DESCENDING
 * @returns  {Array<any>} An array of sorted data
 */
export const sortData = (
  data: Array<any>,
  key: string,
  type: "ASC" | "DSC" = "DSC"
): Array<any> => {
  if (data) {
    try {
      if (
        key == CISortType.DUE_DATE ||
        key == CISortType.REGISTERED_DATE ||
        key == CISortType.EXPORTED_DATE
      ) {
        return data.sort((a, b) => {
          return type === "DSC"
            ? new Date(moment(b[key]).format()).getTime() -
                new Date(moment(a[key]).format()).getTime()
            : new Date(moment(a[key]).format()).getTime() -
                new Date(moment(b[key]).format()).getTime();
        });
      } else {
        return data.sort((a, b) => {
          return type === "DSC" ? b[key] - a[key] : a[key] - b[key];
        });
      }
    } catch (error) {
      return data;
    }
  }
  return [];
};

/**
 * @remarks
 * This method is a part of the {Creditor invoice list | Economy Module}.
 * @description Get all selected invoices
 * @param {Array<IInvoice>} invoices - An array of data
 * @returns {Array<IInvoice>} - An array of `IInvoice` which represent the selected invoices ids
 */
export const getAllSelectedInvoices = (
  invoices: Array<IInvoice>
): Array<IInvoice> => {
  try {
    const selectedInvoices: Array<IInvoice> = [];
    if (invoices) {
      invoices.map((invoice: IInvoice) => {
        selectedInvoices.push(invoice);
      });
    }
    return selectedInvoices;
  } catch (error) {
    return [];
  }
};

/**
 * @function
 * @description Calculate VAT amount for each orderlines
 * @param {Array<any>} orderline - object that containing required props
 * @returns {number} - VAT amount
 */
export const getVatAmounts = <
  T extends {
    vatAmount: number;
    unitPrice: number;
    quantity: number;
    creditedAmount: number;
  }
>(
  orderline: T
): number => {
  try {
    const { vatAmount, unitPrice, quantity, creditedAmount } = orderline;

    if (
      typeof vatAmount == "number" &&
      typeof unitPrice == "number" &&
      typeof quantity == "number" &&
      typeof creditedAmount == "number"
    ) {
      const amount = unitPrice * quantity;
      const vatRate = (vatAmount * 100) / amount;
      let VAT = (creditedAmount * vatRate) / (vatRate + 100);
      VAT = isNaN(VAT) ? 0 : VAT;

      return Number(VAT.toFixed(2));
    } else {
      console.error("All variables are not numbers");
      return 0;
    }
  } catch (e) {
    return 0;
  }
};

/**
 * @function
 * @description Calculate total VAT amount
 * @param {Array<any>} orderlines - An array of orderlines
 * @returns {number} - Total VAT amount
 */
export const getTotalVatAmount = (orderlines: any[]): number => {
  try {
    const totalVat = orderlines.reduce(
      (a: number, item: any) => a + getVatAmounts(item),
      0
    );
    return Number(totalVat.toFixed(2));
  } catch (e) {
    return 0;
  }
};

/**
 * @description
 * Returns the active tab key 0 or 1.
 * @remarks
 * This method is a part of the {Creditor invoice list | Economy Module}.
 * @param {IInvoice} invoice - The first input number
 * @returns {number} The active tab key -  `0` for Pending Export or `1` for Exported
 */
export const getActiveTabKey = (
  invoice: IInvoice,
  currentTabKey: number
): number => {
  if (invoice) {
    return invoice.exportStatus == CIExportStatus.EXPORTED_OR_NAVISION ? 1 : 0;
  }
  return currentTabKey;
};

/**
 * @description Get export data objects to export invoices
 * @param {Array<IInvoice>} invoices - List of all selected invoices
 * @returns {Array<IExportInvoiceData>} Array of `IExportInvoiceData`
 */
export const getExportInvoiceData = (
  invoices: Array<IInvoice>
): Array<IExportInvoiceData> => {
  const exportInvoices: Array<IExportInvoiceData> = [];

  try {
    invoices &&
      invoices.map(
        ({
          invoiceId,
          invoiceNo,
          isCreditInvoice,
          isSourceInvoiceExported,
          sourceInvoiceNo,
        }: IInvoice) => {
          if (
            !(isCreditInvoice && sourceInvoiceNo && !isSourceInvoiceExported)
          ) {
            const requestObject = {
              invoiceId,
              invoiceNo,
              isCreditInvoice,
            };
            exportInvoices.push(requestObject);
          }
        }
      );
    return exportInvoices;
  } catch (error) {
    return [];
  }
};

/**
 * @description Get export type to export pending invoices
 * @param {Array<IInvoice>} invoices - Array of invoices
 * @returns {string}
 * @default BOXImport
 */
export const getExportMethod = (invoices: Array<IInvoice>): string => {
  try {
    if (invoices && invoices.length > 0) {
      return invoices[0].exportMethod ?? "BOXImport";
    }
    return "BOXImport";
  } catch (error) {
    return "BOXImport";
  }
};

/**
 * @description Check whether a specific invoice can able to export or not
 * @param {IInvoice} invoice - Invoice item details
 * @returns {boolean} - `true` or `false`
 */
export const isExportEnabled = (invoice: IInvoice): boolean => {
  try {
    const {
      invoiceExportStatus,
      exportStatus,
      isSourceInvoiceExported,
      isCreditInvoice,
      sourceInvoiceNo,
    } = invoice;
    if (
      invoiceExportStatus === InvoiceExportStatus.NOT_EXPORTED &&
      exportStatus === CIExportStatus.PENDING_EXPORT
    ) {
      // invoice not exported or started yet
      if (isCreditInvoice && sourceInvoiceNo && !isSourceInvoiceExported) {
        // invoice is a credit invoice and parent still not exported
        return false;
      }
      // can export
      return true;
    }
    // not a pending export invoice
    return false;
  } catch (error) {
    return false;
  }
};

/**
 * @description Check whether the given invoices has invoices to export
 * @param {Array<IInvoice>} invoices - Invoice list
 * @returns {boolean} - `true` - has invoices to export
 * `false` - no invoices to export
 */
export const hasInvoicesToExport = (invoices: Array<IInvoice>): boolean => {
  try {
    const invoicesToExport = invoices.filter((invoice: IInvoice) =>
      isExportEnabled(invoice)
    );
    return invoicesToExport && invoicesToExport.length > 0;
  } catch (error) {
    return false;
  }
};

/**
 * @description Check whether the given invoice has file to download or not
 * @param {IInvoice} invoices - Invoice
 * @returns {boolean}
 */
export const hasFileToDownload = ({
  filePath,
  exportMethod,
}: IInvoice): boolean => {
  try {
    if (
      filePath &&
      typeof filePath === "string" &&
      filePath !== "Imported To BOX" &&
      exportMethod
    ) {
      return exportMethod !== "BOXImport";
    }
    return false;
  } catch (error) {
    return false;
  }
};

/**
 * @remarks
 * This method is a part of the {Creditor invoice list | Economy Module}.
 * @description Get all downloadable invoices
 * @param {Array<IInvoice>} invoices - An array of data
 * @returns {Array<string>} - An array of invoices
 */
export const getDownloadableInvoices = (
  invoices: Array<IInvoice>
): Array<IInvoice> => {
  try {
    const downloadableInvoices: Array<IInvoice> = [];
    if (invoices) {
      invoices.map((invoice: IInvoice) => {
        if (hasFileToDownload(invoice) && invoice.filePath) {
          downloadableInvoices.push(invoice);
        }
      });
    }
    return downloadableInvoices;
  } catch (error) {
    return [];
  }
};

/**
 * @remarks
 * This method is a part of the {Creditor invoice list | Economy Module}.
 * @description Get all downloadable invoices file names
 * @param {Array<IInvoice>} invoices - An array of data
 * @returns {Array<string>} - An array of invoice file names
 */
export const getFileNames = (invoices: Array<IInvoice>): Array<string> => {
  try {
    const fileNames: Array<string> = [];
    if (invoices) {
      invoices.map((invoice: IInvoice) => {
        if (hasFileToDownload(invoice) && invoice.filePath) {
          !fileNames.includes(invoice.filePath) &&
            fileNames.push(invoice.filePath);
        }
      });
    }
    return fileNames;
  } catch (error) {
    return [];
  }
};

/**
 * @description Generate sorting options for client invoices.
 * If the `exportStatus` is not `PENDING_EXPORT`, add `caseNo` to the sortingOptions array, if the
 * `searchType` is `REG_DATE`, add `regDate` to the sortingOptions array, if the `searchType` is
 * `DUE_DATE`, add `dueDate` to the sortingOptions array, if the `searchType` is `EXPORTED_DATE`, add
 * `exportedDate` to the sortingOptions array, and return the sortingOptions array.
 * @param {any} values - form values
 * @returns An array of objects with the following structure:
 * [
 *   {
 *     label: string,
 *     sortType: CISortType
 *   }
 * ]
 */
export const getInvoiceSortingOptions = (
  values: any
): Array<{ label: string; sortType: CISortType }> => {
  let sortingOptions = DEFAULT_INVOICE_SORTING_OPTIONS;

  try {
    const { searchType, exportStatus } = values ?? {};
    const isPendingExport = exportStatus === CIExportStatus.PENDING_EXPORT;

    if (!isPendingExport) {
      sortingOptions = [
        ...sortingOptions,
        {
          label: "US.COLLECTION.ECONOMY:INVOICES.CASE_NO",
          sortType: CISortType.CASE_NO,
        },
      ];
    }
    if (searchType === CISearchType.REG_DATE) {
      sortingOptions = [
        ...sortingOptions,
        {
          label: "US.COLLECTION.ECONOMY:INVOICES.INVOICED_DATE",
          sortType: CISortType.REGISTERED_DATE,
        },
      ];
    }
    if (searchType === CISearchType.DUE_DATE) {
      sortingOptions = [
        ...sortingOptions,
        {
          label: "US.COLLECTION.ECONOMY:INVOICES.DUE_DATE",
          sortType: CISortType.DUE_DATE,
        },
      ];
    }
    if (searchType === CISearchType.EXPORTED_DATE) {
      sortingOptions = [
        ...sortingOptions,
        {
          label: "US.COLLECTION.ECONOMY:INVOICES.EXPORTED_DATE",
          sortType: CISortType.EXPORTED_DATE,
        },
      ];
    }
    return sortingOptions.map(
      (option: { label: string; sortType: CISortType }) => ({
        ...option,
        label: i18n.t(option.label),
      })
    );
  } catch (error) {
    return sortingOptions.map(
      (option: { label: string; sortType: CISortType }) => ({
        ...option,
        label: i18n.t(option.label),
      })
    );
  }
};
