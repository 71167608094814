import { ProvisionProfiles } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";
import { IProvisionProfileActions } from "us.collection.economy/interfaces/ProvisionProfiles";
const {
  SEARCH_PROVISION_PROFILES,
  SEARCH_PROVISION_PROFILES_SUCCESS,
  SEARCH_PROVISION_PROFILES_FAIL,

  DELETE_PROVISION_PROFILE,
  UPDATE_PROVISION_PROFILE_DETAILS,
  MANAGE_PROVISION_PROFILE_DRAWER,

  GET_PROVISION_PROFILE_DETAILS,
  GET_PROVISION_PROFILE_DETAILS_SUCCESS,
  GET_PROVISION_PROFILE_DETAILS_FAIL,
  RESET_PROVISION_PROFILE_DETAILS,

  SAVE_PROVISION_PROFILE,
  SAVE_PROVISION_PROFILE_SUCCESS,
  SAVE_PROVISION_PROFILE_FAIL,

  ADD_PROVISION_PROFILE,
  ADD_PROVISION_PROFILE_SUCCESS,
  ADD_PROVISION_PROFILE_FAIL,

  VALIDATE_PROFILE_NAME,
  VALIDATE_PROFILE_NAME_SUCCESS,
  VALIDATE_PROFILE_NAME_FAIL,
} = ProvisionProfiles;

export const provisionProfiles: Readonly<
  IProvisionProfileActions & IAPIAction
> = {
  profiles: {
    search: (data) => ({
      type: SEARCH_PROVISION_PROFILES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    delete: (data) => ({
      type: DELETE_PROVISION_PROFILE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEARCH_PROVISION_PROFILES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEARCH_PROVISION_PROFILES_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  profileDetails: {
    get: (data) => ({
      type: GET_PROVISION_PROFILE_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    update: (data) => ({
      type: UPDATE_PROVISION_PROFILE_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    manageDrawer: (data) => ({
      type: MANAGE_PROVISION_PROFILE_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_PROVISION_PROFILE_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_PROVISION_PROFILE_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_PROVISION_PROFILE_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  profile: {
    save: (data) => ({
      type: SAVE_PROVISION_PROFILE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_PROVISION_PROFILE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_PROVISION_PROFILE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  newProfile: {
    save: (data) => ({
      type: ADD_PROVISION_PROFILE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_PROVISION_PROFILE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_PROVISION_PROFILE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  profileName: {
    get: (data) => ({
      type: VALIDATE_PROFILE_NAME,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: VALIDATE_PROFILE_NAME_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: VALIDATE_PROFILE_NAME_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
