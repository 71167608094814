import { ProvisionProfileSearchStatus } from "us.collection.economy/constants";

export enum ProvisionProfileTableColumn {
  MENU = "menu",
  PROVISION_PROFILE_NAME = "profileName",
  MAX_MAIN_AMOUNT_PROVISION = "maximumMainAmountProvision",
  MAXIMUM_INTEREST_PROVISION = "maximumInterestProvision",
  STATUS = "isActive",
  MODIFIED_DATE = "modifiedDate",
  MODIFIED_USER = "modifiedUser",
}

export const COLUMNS = [
  {
    key: ProvisionProfileTableColumn.MENU,
    title: "",
  },
  {
    key: ProvisionProfileTableColumn.PROVISION_PROFILE_NAME,
    title: "US.COLLECTION.ECONOMY:PROVISION_PROFILES.PROVISION_PROFILE_NAME",
  },
  {
    key: ProvisionProfileTableColumn.MAX_MAIN_AMOUNT_PROVISION,
    title:
      "US.COLLECTION.ECONOMY:PROVISION_PROFILES.MAXIMUM_MAIN_AMOUNT_PROVISION",
  },
  {
    key: ProvisionProfileTableColumn.MAXIMUM_INTEREST_PROVISION,
    title:
      "US.COLLECTION.ECONOMY:PROVISION_PROFILES.MAXIMUM_INTEREST_PROVISION",
  },
  {
    key: ProvisionProfileTableColumn.STATUS,
    title: "US.COLLECTION.ECONOMY:PROVISION_PROFILES.STATUS",
  },
  {
    key: ProvisionProfileTableColumn.MODIFIED_DATE,
    title: "US.COLLECTION.ECONOMY:PROVISION_PROFILES.MODIFIED_DATE",
  },
  {
    key: ProvisionProfileTableColumn.MODIFIED_USER,
    title: "US.COLLECTION.ECONOMY:PROVISION_PROFILES.MODIFIED_USER",
  },
];

export enum ColumnSearchType {
  STRING = "string",
  NUMBER = "number",
}

export enum Align {
  CENTER = "center",
  RIGHT = "right",
  LEFT = "left",
}

export const INITIAL_FORM_VALUES = {
  status: ProvisionProfileSearchStatus.ALL,
  profileName: "",
};

export const DEFAULT_TABLE_INFO = {
  filters: [],
  sorter: { columnKey: "", order: "" },
  data: [],
};

export const DEFAULT_COLUMN_FILTERS = {
  provisionName: "",
  maxMainAmountProvision: 0.0,
  maxInterestProvision: 0.0,
  status: true,
  modifiedDate: "",
  user: "",
};

export enum DrawerType {
  NEW_PROFILE = "new",
  EDIT_PROFILE = "edit",
  COPY_PROFILE = "copy",
}

export const InitialFormData = {
  provisionName: "",
  maxMainAmountProvision: 0.0,
  maxInterestProvision: 0.0,
  status: true,
  modifiedDate: "",
  user: "",
};

export const DEFAULT_DRAWER_STATE = {
  visible: false,
  title: "",
  type: DrawerType.COPY_PROFILE,
};
