import {
  ISearchOrders,
  IOrderGeneratePayload
} from "us.collection.economy/interfaces";
import { httpCollection } from "us.helper/http/collection";

export default {
  searchOrders: (params: ISearchOrders): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.post(
          "clientinvoiceservice",
          "clientorder/search",
          params
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  searchOrderTypes: (): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.get(
          "commonservice",
          `common/categories?typeCode=CI`,
          {}
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  orderGeneration: (
    generateOrders: IOrderGeneratePayload,
    searchParams: ISearchOrders
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const generateResponse = await httpCollection.post(
          "clientinvoiceservice",
          "clientorder/generate",
          generateOrders
        );
        const searchResponse = await httpCollection.post(
          "clientinvoiceservice",
          "clientorder/search",
          searchParams
        );
        resolve({ result: generateResponse.data, data: searchResponse.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  exportOrders: (
    orders: Array<any>,
    searchParams: ISearchOrders
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const exportResponse = await httpCollection.post(
          "clientinvoiceservice",
          "order/invoice",
          orders
        );
        const searchResponse = await httpCollection.post(
          "clientinvoiceservice",
          "clientorder/search",
          searchParams
        );
        resolve({ result: exportResponse.data, data: searchResponse.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteOrder: (
    orderNo: number,
    searchParams: ISearchOrders
  ): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const deleteResponse = await httpCollection.delete(
          "clientinvoiceservice",
          "clientorder",
          orderNo
        );
         const searchResponse = await httpCollection.post(
            "clientinvoiceservice",
            "clientorder/search",
            searchParams
          );
        resolve({ deleteResult: deleteResponse, searchResult: searchResponse });
      } catch (error) {
        reject(error);
      }
    });
  },
};
