import { IItemTypes } from "us.collection.economy/interfaces";
import {
  DEFAULT_DRAWER_STATE,
  DEFAULT_BREADCRUMB_STATE,
} from "us.collection.economy/components/ItemTypes/Constants";
import {
  INITIAL_ITEM_TYPE_DETAIL,
  INITIAL_VOUCHER_SEQUENCE_DETAIL,
} from "us.collection.economy/constants/ItemTypes";
import _ from "lodash";

export const initialState: IItemTypes = {
  itemTypes: {
    isLoading: false,
    data: [],
  },
  transactionGroups: {
    isLoading: false,
    isSaving: false,
    data: [],
  },
  duplicateValidation: {
    itemTypeId: {
      status: true,
      isValidating: false,
    },
    transactionCode: {
      status: true,
      isValidating: false,
    },
  },
  initDetailsData: {
    isLoading: false,
    ledgerAccounts: [],
    bankAccounts: [],
    voucherSequence: [],
    vatCodes: [],
  },
  itemTypeDetails: {
    data: INITIAL_ITEM_TYPE_DETAIL,
    initData: _.cloneDeep(INITIAL_ITEM_TYPE_DETAIL),
    isLoading: false,
    isSaving: false,
  },
  voucherSequenceDetails: {
    data: INITIAL_VOUCHER_SEQUENCE_DETAIL,
    isSaving: false,
  },

  isItemTypeDeleting: false,
  drawerInfo: DEFAULT_DRAWER_STATE,
  breadcrumbInfo: DEFAULT_BREADCRUMB_STATE,
};
