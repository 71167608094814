/**
 * @name EXCEEDED Exceed Payments
 * @name UNKNOWN Unknown Payments
 * @name MAPPED Mapped Payments
 * @name RETURNED Return to debtor Payments
 *
 * @name MPEDG Mapped Payments (used only in grid view API request)
 * @name UNEXED Unknown and exceed Payments (used locally for identification)
 */

import moment from "moment";
import { ActionTypes } from "us.collection.economy/components/ManagePayments/interface";

export const PaymentStates = {
  EXCEEDED: "Exceeded",
  UNKNOWN: "Unknown",
  MAPPED: "Mapped",
  RETURNED: "Returned",
  UNEXED: "UNEXED",
};

export const CaseStatus = {
  OPEN: "Open",
  CLOSED: "Closed",
  FREEZED: "Freezed",
  SEND_TO_COURT: "SendToCourt",
  NEXT_DUE_DATE: "NextDueDate",
  SENTENCE_RECEIVED: "SentenceReceived",
};

export const PeriodType = {
  VOU: "VOU",
  REG: "REG",
  ALL: "ALL",
};

export enum DayValue {
  TODAY = "TODAY",
  YESTERDAY = "YESTERDAY",
  NON = "",
}

export const TagClasses = {
  EXCEEDED: "tag-status-exceeded",
  UNKNOWN: "tag-status-unknown",
  MAPPED: "tag-status-mapped",
  RETURNED: "tag-status-returntodebtor",
};

export const DATE_FORMAT_FOR_SERVER = `YYYY-MM-DD HH:mm:ss`;
export const ONLY_DATE_FORMAT_FOR_SERVER = `YYYY-MM-DD`;

export enum ExceedType {
  RETURN_EXCEED = "RETURN_EXCEED",
  LEAVE_EXCEED = "LEAVE_EXCEED",
}

/**
 * @name Bureau own account type
 */
export const bureauOwnType = "OCROB";

export const RegexOnlyNumbers = /^\d+$/;

export enum DebtorOtherCasesStatus {
  ALL = "3",
  WITH_OTHER_CASES = "2",
  WITH_NO_OTHER_CASES = "1",
}

export type IDebtorOtherCasesStatus =
  | typeof DebtorOtherCasesStatus.ALL
  | typeof DebtorOtherCasesStatus.WITH_OTHER_CASES
  | typeof DebtorOtherCasesStatus.WITH_NO_OTHER_CASES;

export const DEFAULT_PAYMENTS_LIMIT = 100;

export const INITIAL_FILTERS = {
  periodType: PeriodType.REG,
  paymentDateRange: [moment(), moment()],
  paymentStates: [PaymentStates.EXCEEDED],
  accountNo: "",
  offset: 0,
  limit: DEFAULT_PAYMENTS_LIMIT,
  isExceeded: true,
  isUnknown: false,
  isMapped: false,
  isReturnToDebtor: false,
  dayVal: DayValue.TODAY,
  balance: 0,
  mappingBalance: 0,
  debtorOtherCasesStatus: DebtorOtherCasesStatus.ALL,
};

export const INITIAL_ADVANCED_FILTERS = {
  kid: "",
  caseNo: "",
  voucherNo: "",
  paymentId: undefined,
  isOCR: true,
  isManual: true,
  ocrFileName: "all",
  fileNameOptions: [],
};

export const INITIAL_VISIBLE_VIEW = {
  action: ActionTypes.MATCH,
  key: "",
  defaultAction: ActionTypes.MATCH,
  paymentId: 0,
};

export enum Align {
  RIGHT = "right",
  LEFT = "left",
  CENTER = "center",
}
