import { IRemitHistory } from "us.collection.economy/interfaces";

export const initialState: IRemitHistory = {
  bankAccounts: [],
  isLoadingBankAccounts: true,
  remits: [],
  isLoadingRemitHistory: false,
  downloadedFile: {
    content: null,
    isLoading: false,
  },
  remitReversing: {
    data: [],
    isLoading: false,
  },
  accountsDetails: {
    totalCreditAmount: 0,
    totalDebitAmount: 0,
    accounts: [],
  },
  isDetailsLoading: false,
};
