import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import Common from "us.common";
import { ItemTypes } from "us.collection.economy/constants/Actions";
import { $Message } from "us.common/components";
import { i18n } from "us.helper";
import _ from "lodash";

const { itemTypes } = Actions;
const { $MessageBox } = Common.Components;
const { search, detail, mangeGroups, voucherSequence } = API.itemTypes;
const {
  init,
  types,
  groups,
  addSequence,
  initDetails,
  itemTypeDetails,
  addItemType,
  editItemType,
  validateFields,
} = itemTypes;
const {
  INIT_ITEM_TYPES_HOME,
  SEARCH_ITEM_TYPES,
  GET_TRANSACTION_GROUPS,
  DELETE_ITEM_TYPE,
  GET_INIT_ITEM_TYPE_DATA,
  GET_ITEM_TYPE_DETAILS,
  DUPLICATE_VALIDATE_FIELDS,
  ADD_NEW_VOUCHER_SEQUENCE,
  ADD_NEW_ITEM_TYPE,
  EDIT_ITEM_TYPE,
} = ItemTypes;

export const ItemTypesSagas = {
  itemTypeSearch: {
    init: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { itemTypes, transactionGroups, status } = yield call(
          search.init,
          params
        );
        if (
          Array.isArray(itemTypes) &&
          Array.isArray(transactionGroups) &&
          status === 200
        ) {
          yield put(init.success({ itemTypes, transactionGroups }));
        } else {
          yield put(init.fail(new Error()));
        }
      } catch (error) {
        yield put(init.fail(new Error()));
      }
    },
    search: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(search.itemTypes, params);
        if (Array.isArray(data) && status === 200) {
          yield put(types.success(data));
        } else {
          yield put(types.fail(new Error()));
        }
      } catch (error) {
        yield put(types.fail(new Error()));
      }
    },
    delete: function* (action: any) {
      const params = action?.payload?.data ?? {};
      $Message.loading(i18n.t("COMMON.DELETING"), 0);
      try {
        const { deleteData, itemTypesData, status } = yield call(
          search.delete,
          params
        );
        if (status === 200) {
          if (deleteData?.code == 1 && deleteData?.status) {
            $MessageBox(
              "success",
              "US.COLLECTION.ECONOMY:ITEM_TYPE.DELETE_ITEM_TYPE_SUCCESS",
              "",
              ""
            );
            yield put(types.success(itemTypesData));
          } else if (deleteData?.code == 2 && !deleteData?.status) {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:ITEM_TYPE.ITEM_TYPE_IS_IN_USE",
              "",
              ""
            );
            yield put(types.fail(new Error()));
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:ITEM_TYPE.DELETE_ITEM_TYPE_FAILED",
              "",
              ""
            );
            yield put(types.fail(new Error()));
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ITEM_TYPE.DELETE_ITEM_TYPE_FAILED",
            "",
            ""
          );
          yield put(types.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:ITEM_TYPE.DELETE_ITEM_TYPE_FAILED",
          "",
          ""
        );
        yield put(types.fail(new Error()));
      }
    },
    getTransactionGroups: function* (): any {
      try {
        const { data, status } = yield call(mangeGroups.get);
        if (Array.isArray(data) && status === 200) {
          yield put(groups.success(data));
        } else {
          yield put(groups.fail(new Error()));
        }
      } catch (error) {
        yield put(groups.fail(new Error()));
      }
    },
  },
  itemTypeDetail: {
    add: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { saveData, itemTypes, saveStatus, itemTypesStatus } = yield call(
          detail.add,
          params
        );
        if (saveStatus === 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_ITEM_TYPE_SUCCESS",
            "",
            ""
          );
          yield put(addItemType.success(saveData));

          if (Array.isArray(itemTypes) && itemTypesStatus === 200) {
            yield put(types.success(itemTypes));
          } else {
            yield put(types.fail(new Error()));
          }

          if (itemTypeDetails.manageDrawer)
            yield put(itemTypeDetails.manageDrawer({}));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_ITEM_TYPE_FAILED",
            "",
            ""
          );
          yield put(addItemType.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_ITEM_TYPE_FAILED",
          "",
          ""
        );
        yield put(addItemType.fail(new Error()));
      }
    },
    edit: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { saveData, itemTypes, saveStatus, itemTypesStatus } = yield call(
          detail.edit,
          params
        );
        if (saveStatus === 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_ITEM_TYPE_SUCCESS",
            "",
            ""
          );
          yield put(editItemType.success(saveData));

          if (Array.isArray(itemTypes) && itemTypesStatus === 200) {
            yield put(types.success(itemTypes));
          } else {
            yield put(types.fail(new Error()));
          }

          if (itemTypeDetails.manageDrawer)
            yield put(itemTypeDetails.manageDrawer({}));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_ITEM_TYPE_FAILED",
            "",
            ""
          );
          yield put(editItemType.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_ITEM_TYPE_FAILED",
          "",
          ""
        );
        yield put(editItemType.fail(new Error()));
      }
    },
    init: function* (): any {
      try {
        const {
          ledgerAccounts,
          bankAccounts,
          voucherSequence,
          vatCodes,
          status,
        } = yield call(detail.init);
        if (
          Array.isArray(ledgerAccounts) &&
          Array.isArray(bankAccounts) &&
          Array.isArray(voucherSequence) &&
          Array.isArray(vatCodes) &&
          status.every((statusCode: any) => statusCode == 200)
        ) {
          yield put(
            initDetails.success({
              ledgerAccounts,
              bankAccounts,
              voucherSequence,
              vatCodes,
            })
          );
        } else {
          yield put(initDetails.fail(new Error()));
        }
      } catch (error) {
        yield put(initDetails.fail(new Error()));
      }
    },
    get: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(detail.get, params);
        if (Array.isArray(data) && data?.length !== 0 && status === 200) {
          yield put(itemTypeDetails.success(data[0]));
        } else {
          yield put(itemTypeDetails.fail(new Error()));
        }
      } catch (error) {
        yield put(itemTypeDetails.fail(new Error()));
      }
    },
    validateFields: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(detail.validateFields, params);
        if (!_.isEmpty(data) && status === 200) {
          yield put(
            validateFields.success({
              ...data,
              field: params.field,
            })
          );
        } else {
          yield put(validateFields.fail(new Error()));
        }
      } catch (error) {
        yield put(validateFields.fail(new Error()));
      }
    },
    addNewVoucherSequence: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      const { sequenceDetails } = params;
      try {
        const { data, status } = yield call(
          voucherSequence.add,
          sequenceDetails
        );

        if (!_.isEmpty(data) && data?.hasOwnProperty("id") && status === 200) {
          if (data?.id > 0) {
            $MessageBox(
              "success",
              "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_VOUCHER_SEQUENCE_SUCCESS",
              "",
              ""
            );
            yield put(addSequence.success(data));
          } else if (data?.id == 0) {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:ITEM_TYPE.VOUCHER_SEQUENCE_ALREADY_EXISTS",
              "",
              ""
            );
            yield put(addSequence.fail(new Error()));
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_VOUCHER_SEQUENCE_FAILED",
              "",
              ""
            );
            yield put(addSequence.fail(new Error()));
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_VOUCHER_SEQUENCE_FAILED",
            "",
            ""
          );
          yield put(addSequence.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:ITEM_TYPE.SAVE_VOUCHER_SEQUENCE_FAILED",
          "",
          ""
        );
        yield put(addSequence.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(INIT_ITEM_TYPES_HOME, ItemTypesSagas.itemTypeSearch.init),
  takeLatest(DELETE_ITEM_TYPE, ItemTypesSagas.itemTypeSearch.delete),
  takeLatest(SEARCH_ITEM_TYPES, ItemTypesSagas.itemTypeSearch.search),
  takeLatest(
    GET_TRANSACTION_GROUPS,
    ItemTypesSagas.itemTypeSearch.getTransactionGroups
  ),
  takeLatest(GET_INIT_ITEM_TYPE_DATA, ItemTypesSagas.itemTypeDetail.init),
  takeLatest(GET_ITEM_TYPE_DETAILS, ItemTypesSagas.itemTypeDetail.get),
  takeEvery(
    DUPLICATE_VALIDATE_FIELDS,
    ItemTypesSagas.itemTypeDetail.validateFields
  ),
  takeLatest(
    ADD_NEW_VOUCHER_SEQUENCE,
    ItemTypesSagas.itemTypeDetail.addNewVoucherSequence
  ),
  takeLatest(ADD_NEW_ITEM_TYPE, ItemTypesSagas.itemTypeDetail.add),
  takeLatest(EDIT_ITEM_TYPE, ItemTypesSagas.itemTypeDetail.edit),
];
