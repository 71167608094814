import React from "react";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import {
  renderCellParent,
  renderCellChild,
  handleSort,
  handleFilter,
  tableColumns,
} from "../../Functions";
import * as Components from "us.common/components";
import { IGridView } from "./interface";
import _ from "lodash";
import { IntlDate, IntlCurrency } from "us.common/functions";

const { $Skeleton, $TableTree } = Components;

const GridView: React.FC<IGridView> = (props) => {
  const {
    payments,
    paymentAccounts,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    paymentDateRange,
  } = props;
  const { t } = useTranslation();

  const excelHeaderData: any = [
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REG_DATE"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VOUCHER_DATE"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.APPORTIONED_AMOUNT"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.INVOICE_NUMBER"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NUMBER"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VOUCHER_NUMBER"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_ID"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CUSTOMER_ID"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.KID"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_PAYMENT"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MANUAL_PAYMENT"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_STATUS"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAID_ACCOUNT"),
    t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_ITEM_TYPE_ID")
  ];

  function replaceNbsps(str:any) {
    var re = new RegExp(String.fromCharCode(160), "g");
    return str.replace(re, " ");
  }

  const excelBodyData: any = [];
  payments.data.raw.map((rw: any) => {
    excelBodyData.push([
      IntlDate(rw.regDate, currentLanguage, currentDateFormat),
      IntlDate(rw.voucherDate, currentLanguage, currentDateFormat),
      replaceNbsps(IntlCurrency(rw.amount, currentLanguage, currentCurrency).trim()),
      rw.ref,
      rw.caseNo,
      rw.voucherNo,
      rw.paymentId,
      rw.cid,
      rw.kid != '' ? rw.kid+'\t' : '',
      replaceNbsps(IntlCurrency(rw.paymentAmount, currentLanguage, currentCurrency).trim()),
      rw.fileName,
      rw.paymentStatus,
      rw.accountNo,
      rw.itemTypeId,
    ]);
  });

  const Grid_data = [excelHeaderData, ...excelBodyData];
  const fileName = paymentDateRange?.length > 0 ? 
                    `Payment_${IntlDate(paymentDateRange[0] , currentLanguage, currentDateFormat)}_${IntlDate(paymentDateRange[1] , currentLanguage, currentDateFormat)}.csv`
                    : ''
  return (
    <>
     
       <div className="space-content" >
          <$Skeleton
            loading={payments.isFetching || paymentAccounts.isFetching}
            active
            paragraph={{ rows: 1 }}
          >
            <$TableTree
              rowKey={(record: any) => {
                return record.key;
              }}
              columns={tableColumns(t)}
              data={payments.data.formatted}
              bordered
              className="mt-3"
              filterOnType={true}
              scroll={{ y: 'calc(100vh - 295px)' }}
              onSort={handleSort}
              onFilter={handleFilter}
              pagination={{pageSize:5, hideOnSinglePage: true}}
              showResetAllFilters={true}
              defaultExpandAllRows={true}
              renderCellParent={renderCellParent}
              renderCellChild={renderCellChild}
              data-testid="table-grid-payment"
              renderExcelData={Grid_data}
              currentCurrency={currentCurrency}
              excelFileName={fileName}
            />
          </$Skeleton>
        
       </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { managePayment, common } = state;

  const { paymentsGrid, paymentAccounts } = managePayment;
  const { currentLanguage, currentDateFormat, currentCurrency } = common;

  return {
    payments: paymentsGrid,
    paymentAccounts,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(GridView);
