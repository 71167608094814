export enum RegisterPayments {
    GET_PAYMENT_TYPE = 'GET_PAYMENT_TYPE',
    GET_PAYMENT_TYPE_SUCCESS = 'GET_PAYMENT_TYPE_SUCCESS',
    GET_PAYMENT_TYPE_FAIL = 'GET_PAYMENT_TYPE_FAIL',

    GET_PAYMENT_METHOD = 'GET_PAYMENT_METHOD',
    GET_PAYMENT_METHOD_SUCCESS = 'GET_PAYMENT_METHOD_SUCCESS',
    GET_PAYMENT_METHOD_FAIL = 'GET_PAYMENT_METHOD_FAIL',

    GET_VOUCHER_NO = 'GET_VOUCHER_NO',
    GET_VOUCHER_NO_SUCCESS = 'GET_VOUCHER_NO_SUCCESS',
    GET_VOUCHER_NO_FAIL = 'GET_VOUCHER_NO_FAIL',

    GET_VALIDATE_CASE_ID = 'GET_VALIDATE_CASE_ID',
    GET_VALIDATE_CASE_ID_SUCCESS = 'GET_VALIDATE_CASE_ID_SUCCESS',
    GET_VALIDATE_CASE_ID_FAIL = 'GET_VALIDATE_CASE_ID_FAIL',

    SAVE_REGISTER_PAYMENT = 'SAVE_REGISTER_PAYMENT',
    SAVE_REGISTER_PAYMENT_SUCCESS = 'SAVE_REGISTER_PAYMENT_SUCCESS',
    SAVE_REGISTER_PAYMENT_FAIL = 'SAVE_REGISTER_PAYMENT_FAIL',

    CLEAR_VALIDATE_CASE_ID_DATA = 'CLEAR_VALIDATE_CASE_ID_DATA',
}