import React from "react";
import { useTranslation } from "react-i18next";
import { RemitProposalAction } from "us.collection.economy/components/Remit/Constants";
import { $Popconfirm } from "us.common/components";
import { BoxIcons, IconTypes, ProfileOutlined } from "us.icons";
import { IItemMenu } from "./interface";

const ItemMenu: React.FC<IItemMenu> = ({
  actions = [RemitProposalAction.VIEW_REMIT_DETAILS],
  onCallAction,
}) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content">
      {actions.includes(RemitProposalAction.VIEW_REMIT_DETAILS) && (
        <div
          data-testid="btn-remit-details"
          className="d-flex align-items-center  flex-row more-item"
          onClick={() => onCallAction(RemitProposalAction.VIEW_REMIT_DETAILS)}
        >
          <div className="p-1" style={{ fontSize: "1.2rem" }}>
            <ProfileOutlined />
          </div>
          <div className="p-1">
            {t("US.COLLECTION.ECONOMY:REMIT.REMIT_DETAILS")}
          </div>
        </div>
      )}
      {actions.includes(RemitProposalAction.INVOICE) && (
        <$Popconfirm
          title={t("COMMON.SURE_TO_PROCEED_?")}
          placement="topLeft"
          onConfirm={() => onCallAction(RemitProposalAction.INVOICE)}
          okText={t("COMMON.YES")}
          cancelText={t("COMMON.NO")}
          data-testid="tooltip-confirm"
        >
          <div data-testid="btn-invoice" className="d-flex  flex-row more-item">
            <div className="p-1">
              <BoxIcons type={IconTypes.BOX_ICON} name="invoice" />
            </div>
            <div className="p-1">
              {t("US.COLLECTION.ECONOMY:REMIT.INVOICE")}
            </div>
          </div>
        </$Popconfirm>
      )}
      {actions.includes(RemitProposalAction.RESET_INVOICE) && (
        <$Popconfirm
          title={t("COMMON.SURE_TO_PROCEED_?")}
          placement="topLeft"
          onConfirm={() => onCallAction(RemitProposalAction.RESET_INVOICE)}
          okText={t("COMMON.YES")}
          cancelText={t("COMMON.NO")}
          data-testid="tooltip-confirm"
        >
          <div
            data-testid="btn-invoice"
            className="d-flex  flex-row more-item text-error"
          >
            <div className="p-1">
              <BoxIcons type={IconTypes.BOX_ICON} name="invoice" />
            </div>
            <div className="p-1">
              {t("US.COLLECTION.ECONOMY:REMIT.RESET_INVOICE")}
            </div>
          </div>
        </$Popconfirm>
      )}
    </div>
  );
};

export default ItemMenu;
