import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import moment from "moment";
import { SearchOutlined, BoxIcons, IconTypes, CloseOutlined } from "us.icons";
import emptyStateIcon from "us.icons/svg/searchList.svg";
import "./Home.scss";
import {
  $Affix,
  $PageHeader,
  $Divider,
  $Button,
  $Radio,
  $RangePicker,
  $Select,
  $Tooltip,
  $Empty,
  $Skeleton,
  $Search,
  $Message,
  $Drawer,
  $AmountLabel,
  $Popconfirm,
} from "us.common/components";
import { IRootState } from "us.collection/interfaces";
import {
  managePayment,
  managePaymentApportionment,
} from "us.collection.economy/actions";
import {
  ActionTypes,
  IPayment,
  IVisibleView,
  IManagePaymentsHome,
} from "./interface";
import { GetPayments } from "./Repository";
import {
  DayValue,
  DebtorOtherCasesStatus,
  INITIAL_ADVANCED_FILTERS,
  INITIAL_FILTERS,
  DEFAULT_PAYMENTS_LIMIT,
  PaymentStates,
  PeriodType,
} from "us.collection.economy/constants/Payments";
import {
  ActionView,
  Filter,
  PaymentLayout,
  PaymentDetails,
} from "./Components";
import { PaymentHelper, calculateOffSet } from "./Functions";
import { ManagePaymentValidationSchema } from "./Validations";
import { getPaymentIdToFocus } from "./Functions";
import { handleGoBack } from "us.helper/utility";
import { isArray } from "lodash";

const {
  searchPayments,
  initPayments,
  reverseReturnedPayment,
  revertMappedPayment,
} = managePayment;

const { changeDrawerInfo } = managePaymentApportionment;

/**
 * Manage Payments new UI component
 * @param @method onChangeView - this function will trigger when toggle the UI between new and old view
 */
const ManagePayments: React.FC<PropsFromRedux & IManagePaymentsHome> = (
  props
) => {
  const { t } = useTranslation();
  const {
    currentDateFormat,
    payments,
    paymentAccounts,
    paymentsWithAcc,
    paymentApportionments,
    revertPaymentPopConfirmStatus,
    searchPayments,
    initPayments,
    onChangeView,
    reverseReturnedPayment,
    changeDrawerInfo,
    revertMappedPayment,
  } = props;

  const history = useHistory();
  const { replace } = history;

  // extract the URL prams
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const urlParamView = urlParams.get("view");
  const urlParamPaymentId = urlParams.get("id");
  const urlParamPeriodType = urlParams.get("periodType");

  // Check whether the map payment search by payment id or not
  const isPaymentIdSearch =
    urlParamPeriodType === PeriodType.ALL.toLocaleLowerCase();

  const [visibleView, setVisibleView] = useState<IVisibleView>({
    action: ActionTypes.MATCH,
    key: "",
    defaultAction: ActionTypes.MATCH,
    paymentId: 0,
  });
  const [dates, setDates] = useState<Array<moment.Moment>>([]);
  const [paymentsData, setPaymentsData] = useState<Array<any>>([]);
  const [mainFilters, setMainFilters] = useState<any>({
    ...INITIAL_FILTERS,
    ...INITIAL_ADVANCED_FILTERS,
    dayVal: payments.dayVal,
    periodType: isPaymentIdSearch ? PeriodType.ALL : PeriodType.REG,
    paymentId:
      isPaymentIdSearch && urlParamPaymentId
        ? Number(urlParamPaymentId)
        : undefined,
    paymentStates: isPaymentIdSearch ? [] : [PaymentStates.EXCEEDED],
  });
  const [isPaymentDetailsChange, setPaymentDetailsChange] =
    useState<boolean>(true);

  // load initial data with default values
  useEffect(() => {
    const initParams = GetPayments.call(mainFilters);
    initPayments(initParams);
  }, []);

  // get payment accounts if not available
  useEffect(() => {
    if (paymentAccounts) {
      setMainFilters({
        ...mainFilters,
        accountNo: paymentAccounts.data[0]?.accountNo,
      });
    }
  }, [paymentAccounts]);

  useEffect(() => {
    if (!payments.isFetching && payments.data) {
      if (payments.data.raw && payments.data.raw.length > 0) {
        if (PaymentHelper.getLinkedPaymentId()) {
          // focused to linked new payment when navigate from the old payment
          onFocusPayment(PaymentHelper.getLinkedPaymentId());
        } else {
          if (payments.data.raw.length <= DEFAULT_PAYMENTS_LIMIT) {
            const paymentToFocus = getPaymentIdToFocus(
              payments.data.raw,
              Number(urlParamPaymentId)
            );
            // focus to a specific payment that has been updated/filtered
            onFocusPayment(paymentToFocus);
          }

          // open the apportionment view if the url view equal to apportionment
          if (paymentApportionments.isUpdatingApportionmentsSuccess) {
            changeDrawerInfo({
              title: "",
              visible: false,
            });
          } else {
            urlParamView == "apportionment" &&
              changeDrawerInfo({
                title: "",
                visible: true,
              });
          }
        }
        setMainFilters({
          ...mainFilters,
          fileNameOptions: payments.data.ocrFileNameOptions,
        });
      } else {
        // no content scroll to top if in bottom
        window.scrollTo({ top: 0, behavior: "smooth" });
      }

      const { paymentDateRange, dayVal } = payments;
      const dateRange =
        isArray(paymentDateRange) && paymentDateRange.length === 2
          ? paymentDateRange
          : mainFilters.paymentDateRange;
      // when load new data filter if already added filters, or else display the all data
      onFilterLocalData({
        ...mainFilters,
        paymentDateRange: dateRange,
        dayVal,
      });
    }
  }, [payments.data.raw]);

  /**
   * When the drawer is closed, change the drawerInfo state to have an empty title and set the visible
   * property to false.
   */
  const onClose = () => {
    replaceBrowserUrl({
      paymentId: urlParamPaymentId,
      periodType: urlParamPeriodType,
    });
    changeDrawerInfo({
      title: "",
      visible: false,
    });
  };

  /**
   * @description - Update browser url according to the current UI state
   * @param paymentId - current browser params id or paymentId from input value
   * @param periodType - current browser params periodType or selection value
   */
  const replaceBrowserUrl = ({
    view,
    paymentId,
    periodType,
  }: {
    view?: "default" | "apportionment";
    paymentId?: any;
    periodType?: any;
  }) => {
    let searchUrlParams = `?view=${view ?? urlParamView ?? "default"}`;
    if (paymentId || periodType) {
      if (paymentId) {
        searchUrlParams += `&id=${paymentId}&open=true`;
      }
      if (periodType) {
        searchUrlParams += `&periodType=${periodType}`;
      }
    }
    replace({
      pathname: `/manage-payments`,
      search: searchUrlParams,
    });
  };

  // focus to a payment
  const onFocusPayment = (
    paymentId?: number | undefined,
    isFiltered?: boolean
  ) => {
    // update the browser url
    replaceBrowserUrl({
      view: paymentApportionments.isUpdatingApportionmentsSuccess
        ? "default"
        : (urlParamView as "default" | "apportionment"),
      paymentId: paymentId ?? urlParamPaymentId,
      periodType: urlParamPeriodType,
    });
    // update the default action for selected payment when url changed
    const defaultPaymentAction = PaymentHelper.getDefaultPaymentAction(
      paymentId ?? Number(urlParamPaymentId),
      payments.data.raw,
      mainFilters?.debtorOtherCasesStatus
    );
    const key = Math.random().toString(36).substring(2, 20);
    const newVisibleState = {
      paymentId: visibleView.paymentId ?? 0,
      defaultAction: defaultPaymentAction,
      action:
        Number(paymentId ?? urlParamPaymentId) === visibleView.paymentId
          ? visibleView.action
          : defaultPaymentAction,
      key,
    };
    setVisibleView(newVisibleState);
    if (!payments?.hasMorePayments || isFiltered) {
      scrollToPosition(paymentId);
    }
  };

  // scroll to a specific payment
  const scrollToPosition = (paymentId: number | undefined) => {
    setTimeout(() => {
      const linkedPaymentId = PaymentHelper.getLinkedPaymentId();
      if (linkedPaymentId || paymentId || urlParamPaymentId) {
        const element: HTMLElement | null = document.getElementById(
          `${
            linkedPaymentId
              ? linkedPaymentId
              : paymentId
              ? paymentId
              : urlParamPaymentId
          }`
        );
        if (element) {
          element.scrollIntoView({ behavior: "smooth", block: "center" });
        }
      }
    }, 1000);
  };

  // handle today / yesterday toggle events
  const onChangeDayValue = (dayVal: string) => {
    let dateRange = {
      dayVal: DayValue.TODAY,
      paymentDateRange: [moment().startOf("day"), moment().endOf("day")],
    };

    if (dayVal === DayValue.YESTERDAY) {
      dateRange = {
        paymentDateRange: [
          moment().subtract(1, "d").startOf("day"),
          moment().subtract(1, "d").endOf("day"),
        ],
        dayVal,
      };
    }

    fetchPayments({
      ...mainFilters,
      ...dateRange,
    });
  };

  // handle any action call on a payment item
  const paymentActionHandler = (data: any) => {
    const { action, mainPaymentItem, payment, comment, restProps } = data;
    const key = Math.random().toString(36).substring(2, 20);
    if (action === ActionTypes.APPORTION) {
      // do apportion stuff
    } else if (action === ActionTypes.EDIT) {
      // do edit
      setVisibleView({
        action: ActionTypes.EDIT,
        key,
        paymentId: mainPaymentItem.paymentId,
      });
    } else if (action === ActionTypes.MATCH) {
      //do match
      setVisibleView({
        action: ActionTypes.MATCH,
        key,
        paymentId: mainPaymentItem.paymentId,
      });
    } else if (action === ActionTypes.REVERT_TO_PAYMENT) {
      // do revert
      const filters = GetPayments.call(mainFilters);
      reverseReturnedPayment({ ...payment, comment }, filters);
      restProps.setFieldValue("comment", "");
    } else if (action === ActionTypes.REVERT_PAYMENT) {
      const filters = GetPayments.call(mainFilters);
      revertMappedPayment({ ...mainPaymentItem, comment }, filters);
      restProps.setFieldValue("comment", "");
    } else if (action === ActionTypes.TRANSFER) {
      // do transfer
      setVisibleView({
        action: ActionTypes.TRANSFER,
        key,
        paymentId: mainPaymentItem.paymentId,
      });
    }
  };

  // fetch payments from the server
  const fetchPayments = (values: any) => {
    const {
      debtorOtherCasesStatus,
      isMapped,
      isReturnToDebtor,
      periodType,
      paymentId,
      isExceeded,
    } = values;
    // no content scroll to top if in bottom
    window.scrollTo({ top: 0, behavior: "smooth" });
    PaymentHelper.setLinkedPaymentId(undefined);
    // update the browser url
    replaceBrowserUrl({});
    const shouldHasMapped =
      debtorOtherCasesStatus !== DebtorOtherCasesStatus.ALL && isExceeded
        ? false
        : isMapped;
    const shouldHasReturned =
      debtorOtherCasesStatus !== DebtorOtherCasesStatus.ALL && isExceeded
        ? false
        : isReturnToDebtor;
    const requestParams = {
      ...mainFilters,
      ...values,
      ...INITIAL_ADVANCED_FILTERS,
      paymentId: periodType === PeriodType.ALL ? paymentId : "",
      paymentStates:
        periodType === PeriodType.ALL
          ? []
          : PaymentHelper.getPaymentStatus({
              ...values,
              isMapped: shouldHasMapped,
              isReturnToDebtor: shouldHasReturned,
            }),
      isMapped: shouldHasMapped,
      isReturnToDebtor: shouldHasReturned,
      debtorOtherCasesStatus: !isExceeded
        ? DebtorOtherCasesStatus.ALL
        : debtorOtherCasesStatus,
    };
    setMainFilters(requestParams);
    const searchFilters = GetPayments.call(requestParams);
    searchPayments(searchFilters);
  };

  // filtering fetched local data by mode (OCR / Manual)
  const onFilterLocalData = (values: any) => {
    setMainFilters({ ...mainFilters, ...values });
    if (payments.data.raw && payments.data.raw.length) {
      const newFilteredPayments = PaymentHelper.filterPaymentsByFileName(
        values,
        payments.data.raw
      );
      setPaymentsData(newFilteredPayments);
      const paymentToFocus = getPaymentIdToFocus(
        newFilteredPayments,
        Number(urlParamPaymentId)
      );
      if (newFilteredPayments.length !== payments.data.raw.length) {
        onFocusPayment(paymentToFocus, true);
      }
    } else {
      setPaymentsData([]);
      replaceBrowserUrl({});
    }
  };

  // set dates that should disabled for selecting more than 14 days
  const disabledDate = (current: any) => {
    // disable future dates
    if (current.isAfter(moment())) return true;
    // don't disable if no dates selected
    if (!dates || dates.length === 0) {
      return false;
    }
    // if first date selected then
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 14;
    // if last date selected without first date then
    const tooEarly =
      !dates[0] && dates[1] && dates[1].diff(current, "days") >= 14;
    return tooLate || tooEarly;
  };

  return (
    <Formik
      id={"form"}
      initialValues={{ ...mainFilters, revertPaymentPopConfirmStatus }}
      enableReinitialize
      validationSchema={ManagePaymentValidationSchema}
      validateOnChange
      validateOnBlur
      onSubmit={() => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        validateForm,
        ...restProps
      }: any) => (
        <>
          <div>
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div
                  className="d-flex align-items-center flex-nowrap"
                  data-testid="default-view"
                >
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t(
                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MANAGE_PAYMENTS"
                    )}
                    extra={
                      <$Tooltip
                        title={t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CHANGE_VIEW"
                        )}
                      >
                        <$Button
                          onClick={onChangeView}
                          className="px-1 my-1"
                          data-testid="toggle-default-view"
                          size="small"
                          type="link"
                          icon={
                            <BoxIcons
                              type={IconTypes.BOX_ICON}
                              name="toggle-view"
                            />
                          }
                        />
                      </$Tooltip>
                    }
                  />
                  <$Divider className="bui-devider" type="vertical" />

                  <div className="d-flex align-items-center">
                    <$Tooltip
                      title={t(
                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.SEARCH_TYPE"
                      )}
                    >
                      <$Select
                        dataTestid={"select-period-type"}
                        className="ml-2 mr-2 mb-1"
                        name="periodType"
                        size="small"
                        style={{ width: 160 }}
                        options={[
                          {
                            label: t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REGISTERED_DATE"
                            ),
                            value: PeriodType.REG,
                          },
                          {
                            label: t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VOUCHER_DATE"
                            ),
                            value: PeriodType.VOU,
                          },
                          {
                            label: t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_ID"
                            ),
                            value: PeriodType.ALL,
                          },
                        ]}
                        optionText="label"
                        allOption={false}
                        defaultValue={
                          urlParamPeriodType ? PeriodType.ALL : PeriodType.REG
                        }
                        loading={paymentAccounts.isFetching}
                        disabled={paymentAccounts.isFetching}
                        showSearch={false}
                        onChange={(periodType: any) => {
                          fetchPayments({
                            ...mainFilters,
                            periodType,
                          });
                        }}
                      />
                    </$Tooltip>
                    {values.periodType === PeriodType.ALL && (
                      <$Search
                        name="paymentId"
                        size="small"
                        allowClear
                        required
                        value={values.paymentId}
                        className="mb-1 ml-2"
                        loading={payments.isFetching}
                        placeholder={t(
                          "US.COLLECTION.ECONOMY:PLACE_HOLDERS.SEARCH_BY_PAYMENT_ID"
                        )}
                        style={{ width: 300 }}
                        onSearch={async (text: string) => {
                          restProps.isValid
                            ? text &&
                              !isValidating &&
                              fetchPayments({
                                ...values,
                                paymentId: Number(text),
                              })
                            : $Message.error(
                                t(
                                  "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_PAYMENT_ID"
                                )
                              );
                        }}
                      />
                    )}
                    {values.periodType !== PeriodType.ALL && (
                      <>
                        <$Radio
                          buttonStyle="solid"
                          optionType="button"
                          className="d-flex mr-2"
                          formitem
                          radioButton={false}
                          optionContent
                          optionStyle="flex-fill text-center px-4"
                          name="dayVal"
                          optionText="label"
                          optionValue="value"
                          size="small"
                          options={[
                            {
                              value: DayValue.TODAY,
                              label: t("US.COLLECTION.COMMON:COMMON.TODAY"),
                            },
                            {
                              value: DayValue.YESTERDAY,
                              label: t("US.COLLECTION.COMMON:COMMON.YESTERDAY"),
                            },
                          ]}
                          onChange={(e: any) => {
                            onChangeDayValue(e.target.value);
                          }}
                        />

                        <$RangePicker
                          name={`paymentDateRange`}
                          currentTimeFormat={currentDateFormat}
                          style={{ width: 220 }}
                          size="small"
                          picker="date"
                          onChange={(paymentDateRange: any) => {
                            if (paymentDateRange) {
                              restProps.setFieldValue("dayVal", DayValue.NON);
                              fetchPayments({
                                ...mainFilters,
                                paymentDateRange,
                                dayVal: DayValue.NON,
                              });
                            }
                          }}
                          onCalendarChange={(val: any) => {
                            setDates(val);
                          }}
                          onOpenChange={(isOpen: boolean) => {
                            if (isOpen) {
                              setDates([]);
                              restProps.setFieldValue(
                                "paymentDateRange",
                                undefined
                              );
                            } else {
                              restProps.setFieldValue(
                                "paymentDateRange",
                                mainFilters.paymentDateRange
                              );
                            }
                          }}
                          disabledDate={disabledDate}
                        />
                        <$Tooltip
                          title={t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ACCOUNT"
                          )}
                        >
                          <$Select
                            dataTestid={"select-account"}
                            className="ml-2 mr-2 mb-1"
                            name="accountNo"
                            defaultValue={paymentAccounts.data[0]?.accountNo}
                            placeholder={t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ACCOUNT"
                            )}
                            size="small"
                            style={{ width: 280 }}
                            options={paymentAccounts.data}
                            showSearch={false}
                            optionValue="accountNo"
                            optionText="accountName"
                            allOption={false}
                            loading={paymentAccounts.isFetching}
                            disabled={paymentAccounts.isFetching}
                            optionExtra={{
                              extraNumber: "accountNo",
                            }}
                          />
                        </$Tooltip>
                        <$Button
                          id={"btnSearch"}
                          onClick={() => {
                            fetchPayments(values);
                            resetForm();
                          }}
                          className="mb-1 mr-2"
                          type="default"
                          size="small"
                          icon={<SearchOutlined />}
                          disabled={
                            paymentAccounts.isFetching ||
                            !values.paymentDateRange
                          }
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </$Affix>
          </div>

          <div className="map-payment-layout">
            {values.periodType !== PeriodType.ALL && (
              <$Affix offsetTop={95}>
                <aside className="map-payment-side d-flex align-items-start flex-column">
                  {/* render filter section - start*/}
                  <Filter
                    data-testid="filterView"
                    onFilter={(val) => {
                      fetchPayments({
                        ...mainFilters,
                        ...values,
                        ...val,
                      });
                      resetForm();
                    }}
                    onFilterLocal={(value: any) => {
                      const key = Object.keys(value) && Object.keys(value)[0];
                      restProps.setFieldValue(key, value[key]);
                      onFilterLocalData({
                        ...values,
                        ...value,
                      });
                    }}
                    disabled={paymentAccounts?.isFetching}
                    values={values}
                    fileNameOptions={payments.data.ocrFileNameOptions}
                    resetForm={() => {
                      resetForm();
                      setMainFilters({
                        ...mainFilters,
                        ...INITIAL_ADVANCED_FILTERS,
                      });
                      setPaymentsData(payments.data.raw);
                      onFocusPayment(payments.data.raw[0].paymentId);
                    }}
                    paymentsCount={PaymentHelper.getExceedAndUnknownCounts(
                      paymentsData
                    )}
                    onChangePaymentId={(value: any) => {
                      restProps.setFieldValue("paymentId", value);
                      onFilterLocalData({
                        ...values,
                        paymentId: value,
                      });
                    }}
                  />
                  {/* render filter section - end*/}
                </aside>
              </$Affix>
            )}
            {payments.isFetching && (
              <$Skeleton
                loading={true}
                active
                paragraph={{ rows: 1 }}
                className="p-3"
                data-testid="payments-skeleton"
              />
            )}
            {!payments.isFetching &&
              payments.data.raw.length > 0 &&
              paymentsData.length > 0 && (
                <div className="d-flex flex-fill " data-testid="test1">
                  {/* render payments section - start*/}
                  <div style={{ width: 550 }}>
                    <PaymentLayout
                      data-testid="paymentsView"
                      data={
                        paymentsWithAcc.length ? paymentsWithAcc : paymentsData
                      }
                      activeId={Number(urlParamPaymentId)}
                      onItemCallAction={(data: any) =>
                        paymentActionHandler({ ...data, ...values, restProps })
                      }
                      onSelectPayment={(key: number | undefined) => {
                        onFocusPayment(key);
                      }}
                      values={values}
                      hasMorePayments={
                        paymentsData.length < payments.data.raw.length
                          ? false
                          : Boolean(payments?.hasMorePayments)
                      }
                      onViewLinkedPayment={({
                        linkedPaymentId,
                      }: {
                        linkedPaymentId: number;
                      }) => {
                        restProps.setFieldValue("periodType", PeriodType.ALL);
                        restProps.setFieldValue("paymentId", linkedPaymentId);
                        fetchPayments({
                          ...mainFilters,
                          paymentId: linkedPaymentId,
                          periodType: PeriodType.ALL,
                        });
                      }}
                      onViewPaymentDetails={(payment: IPayment) => {
                        changeDrawerInfo({
                          title: `${t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_DETAILS"
                          )} | ${t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT"
                          )}
                           : ${payment.paymentId}`,
                          visible: true,
                        });
                        setPaymentDetailsChange(false);
                      }}
                      onLoadMore={() => {
                        const requestParams = {
                          ...mainFilters,
                          offset: calculateOffSet(
                            payments.data.raw.length,
                            DEFAULT_PAYMENTS_LIMIT
                          ),
                        };
                        const searchFilters = GetPayments.call(requestParams);
                        searchPayments(searchFilters);
                      }}
                      onScrollToFocusedItem={() => {
                        if (urlParamPaymentId) {
                          scrollToPosition(parseInt(urlParamPaymentId));
                        }
                      }}
                      onBackToTop={() => {
                        const paymentToFocus = paymentsWithAcc.length
                          ? paymentsWithAcc[0]
                          : paymentsData[0];
                        scrollToPosition(paymentToFocus.paymentId);
                      }}
                    />
                  </div>
                  {/* render payments section - end*/}
                  {/* render Match/Transfer section - start*/}
                  <$Affix offsetTop={95} className="flex-fill">
                    <ActionView
                      data-testid="actionView"
                      data={paymentsData}
                      mainFilters={mainFilters}
                      visibleAction={
                        visibleView.action ?? visibleView.defaultAction
                      }
                      itemCallActionState={visibleView}
                      enabledMatch={PaymentHelper.hasMatch(
                        Number(urlParamPaymentId),
                        paymentsData
                      )}
                      enabledTransfer={PaymentHelper.hasTransfer(
                        Number(urlParamPaymentId),
                        paymentsData
                      )}
                      enabledEdit={PaymentHelper.hasEdit(
                        Number(urlParamPaymentId),
                        paymentsData
                      )}
                      parentFormValues={values}
                    />
                  </$Affix>
                  {/* render Match/Transfer section - end*/}
                </div>
              )}
            {!payments.isFetching &&
              (payments.data.raw.length === 0 || paymentsData.length === 0) &&
              values.periodType !== PeriodType.ALL && (
                <div className="flex-fill pt-5" data-testid="empty-normal">
                  <$Empty
                    image={$Empty.PRESENTED_IMAGE_SIMPLE}
                    description={t("COMMON.NO_DATA")}
                  />
                </div>
              )}
            {!payments.isFetching &&
              payments?.data.raw?.length === 0 &&
              values.periodType === PeriodType.ALL && (
                <div
                  className="flex-fill pt-5"
                  data-testid="empty-by-payment-id"
                >
                  <$Empty
                    image={emptyStateIcon}
                    description={t(
                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.SEARCH_BY_PAYMENT_ID_TO_VIEW_PAYMENTS"
                    )}
                  />
                </div>
              )}
          </div>
          <$Drawer
            title={
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex">
                  <div className="mr-3">
                    {paymentApportionments.drawerInfo.title}
                  </div>

                  <div className="mr-1">
                    {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.AMOUNT")}:
                  </div>

                  <div>
                    <$AmountLabel
                      value={
                        payments.data.raw.filter(
                          (s: any) => s.paymentId == urlParamPaymentId
                        )[0]?.paymentAmount
                      }
                    />
                  </div>
                </div>

                <div className="ant-drawer-close">
                  {!isPaymentDetailsChange && (
                    <CloseOutlined onClick={onClose} />
                  )}

                  {isPaymentDetailsChange && (
                    <$Popconfirm
                      title={t(
                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?"
                      )}
                      placement="bottomRight"
                      onConfirm={onClose}
                      okText={t("COMMON.YES")}
                      cancelText={t("COMMON.NO")}
                    >
                      <CloseOutlined />
                    </$Popconfirm>
                  )}
                </div>
              </div>
            }
            width={"1200px"}
            visible={paymentApportionments.drawerInfo.visible}
            className="payment-detail-drawer"
            closable={false}
            onClose={onClose}
            destroyOnClose
          >
            <PaymentDetails checkChildChanged={setPaymentDetailsChange} />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { managePayment, common } = state;
  const {
    payments,
    paymentAccounts,
    paymentDistribution,
    paymentsWithAcc,
    revertPayment,
    paymentApportionments,
  } = managePayment;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  const { revertPaymentPopConfirmStatus } = revertPayment;

  return {
    payments,
    paymentAccounts,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    paymentDistribution,
    paymentsWithAcc,
    paymentApportionments,
    revertPaymentPopConfirmStatus,
  };
};

const mapDispatchToProps = {
  initPayments,
  searchPayments,
  reverseReturnedPayment,
  changeDrawerInfo,
  revertMappedPayment,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ManagePayments);
