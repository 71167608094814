import moment from "moment";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";

/**
 * @description Get `startDate` and `endDate` of the current month
 */
export const getMonthDateRange = () => {
  // get start date of current month
  var startDate = moment().startOf("month").format(DATE_FORMAT_FOR_SERVER);

  // get end date of current month
  var endDate = moment().endOf("month").format(DATE_FORMAT_FOR_SERVER);

  // make sure to call toDate() for plain JavaScript date type
  return { startDate, endDate };
};

/**
 * @description Read filename content from Axios response header Content Disposition
 * @param {any} headers - Axios response headers
 * @returns {string} filename
 */
export const getFilenameFromHeaders = (headers: any): string => {
  try {
    if (headers && headers.hasOwnProperty("content-disposition")) {
      return headers["content-disposition"].split("filename=")[1].split(";")[0];
    }
    return "";
  } catch (error) {
    return "";
  }
};
