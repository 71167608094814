import { ImportPayments } from "us.collection.economy/constants/Actions";
import { initialState } from "./State";
import _ from "lodash";

const {
  UPLOAD_PAYMENTS_START,
  UPLOAD_PAYMENTS_SUCCESS,
  UPLOAD_PAYMENTS_FAIL,
  RESET_FILE_IMPORT,

  IMPORT_PAYMENTS_START,
  IMPORT_PAYMENTS_SUCCESS,
  IMPORT_PAYMENTS_FAIL,

  UPDATE_FILE_LIST,
} = ImportPayments;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case UPLOAD_PAYMENTS_START:
      return Object.assign({}, state, {
        upload: {
          data: {},
          isLoading: true,
        },
        initialValues: {
          fileType: payload.data.fileType
        }
      });
    case UPLOAD_PAYMENTS_SUCCESS:
      return Object.assign({}, state, {
        upload: {
          data: payload.data,
          isLoading: false,
        },
      });
    case UPLOAD_PAYMENTS_FAIL:
      return Object.assign({}, state, {
        upload: {
          data: {},
          isLoading: false,
        },
      });
    case RESET_FILE_IMPORT:
      return Object.assign({}, state, {
        ...initialState
      });
    case IMPORT_PAYMENTS_START:
      return Object.assign({}, state, {
        importFiles: {
          isLoading: true,
        },
      });
    case IMPORT_PAYMENTS_SUCCESS:
      return Object.assign({}, state, {
        ...initialState
      });
    case IMPORT_PAYMENTS_FAIL:
      return Object.assign({}, state, {
        importFiles: {
          isLoading: false,
        },
      });
    case UPDATE_FILE_LIST:
      return Object.assign({}, state, {
        fileList: payload.data,
      });

    default:
      return state;
  }
};
