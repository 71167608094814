import React from "react";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "us.icons";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "us.collection.economy/actions";
import { IManageVoucherSequence } from "us.collection.economy/components/ItemTypes/Interfaces";
import { BreadcrumbType } from "us.collection.economy/components/ItemTypes/Constants";
import {
  $Skeleton,
  $Button,
  $TableTree,
  ITableTreeColumns,
} from "us.common/components";

/**
 * @description - Manage voucher sequence.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2992242726/Manage+Item+Types+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 23/06/2022
 */
const VoucherSequenceList: React.FC<IManageVoucherSequence & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();
  const { initDetailsData, manageBreadcrumb, onCancel } = props;

  const parentFormik = useFormikContext<any>();

  const columns: ITableTreeColumns = [
    {
      title: t("US.COLLECTION.ECONOMY:ITEM_TYPE.SEQUENCE_ID"),
      dataIndex: "sequenceId",
      key: "sequenceId",
      showFilters: true,
      customFilter: true,
      width: "150px",
      customSorter: (a: any, b: any) => a?.localeCompare(b),
    },
    {
      title: t("US.COLLECTION.ECONOMY:ITEM_TYPE.SEQUENCE_SUB_ID"),
      dataIndex: "subSequenceId",
      key: "subSequenceId",
      showFilters: true,
      customFilter: true,
      customSorter: (a: any, b: any) => a?.localeCompare(b),
    },
  ];

  /**
   * @description -  Handle row selection in table.
   * @param {any} restProps Formik props.
   * @param {any} values Formik values.
   * @returns properties of ant table
   */
  const rowSelection = (restProps: any, values: any) => {
    return {
      onChange: (selectedRowKeys: any[]) => {
        restProps.setFieldValue("selectedSequenceId", selectedRowKeys);
      },
      selectedRowKeys: values?.selectedSequenceId,
      preserveSelectedRowKeys: true,
    };
  };

  return (
    <Formik
      initialValues={{
        selectedSequenceId: parentFormik?.values?.voucherSequenceId
          ? [parentFormik?.values?.voucherSequenceId]
          : [],
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Skeleton
            loading={initDetailsData.isLoading}
            active
            paragraph={{
              rows: 2,
            }}
          >
            <$TableTree
              rowKey={(record: any) => record.id}
              columns={columns}
              data={initDetailsData.voucherSequence}
              className="mt-2"
              size="small"
              bordered
              filterOnType
              resetOnSourceChange
              firstColSkipFilterProps={-1}
              onSort={(sortData, dataSource) => {
                return sortData(dataSource);
              }}
              onFilter={(searchData, dataSource) => {
                return searchData(dataSource);
              }}
              rowSelection={{
                type: "radio",
                ...rowSelection(restProps, values),
              }}
              pagination={{
                defaultPageSize: 13,
              }}
              footer={() => (
                <$Button
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={() =>
                    manageBreadcrumb &&
                    manageBreadcrumb(BreadcrumbType.VOUCHER_SEQUENCE_ADD)
                  }
                >
                  {t("US.COLLECTION.COMMON:COMMON.ADD")}
                </$Button>
              )}
            />
          </$Skeleton>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="ml-3 mr-2"
                type="primary"
                disabled={!values?.selectedSequenceId?.length}
                onClick={() => {
                  onCancel();
                  parentFormik?.setFieldValue(
                    "voucherSequenceId",
                    values?.selectedSequenceId[0]
                  );
                }}
              >
                {t("US.COLLECTION.COMMON:COMMON.SELECT")}
              </$Button>
              <$Button onClick={() => onCancel()}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { itemType } = state;
  const { initDetailsData } = itemType;

  return {
    initDetailsData,
  };
};

const { itemTypeDetails } = Actions.itemTypes;

const mapDispatchToProps = {
  manageBreadcrumb: itemTypeDetails.manageBreadcrumb
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VoucherSequenceList);
