import { put, takeLatest, call, all } from 'redux-saga/effects'
import { PaymentAgreement } from 'us.collection/constants';
import * as API from 'us.collection/services';
import * as Actions from 'us.collection/actions';
import _ from 'lodash'
import { details } from './../../reducers/PaymentAgreement/State/Details';
import { message } from 'antd';
const { paymentAgreement } = Actions;

const agreement = {
    amounts: {
        get: function* (action: any) {
            const params = action.payload?.data;
            try {
                const data = yield call(API.default.PaymentAgreement.getAgreementAmounts, params);
                //yield put(paymentAgreement.getAgreementAmountsSuccess(data));
            } catch (error) {
                //yield put(paymentAgreement.getAgreementAmountsFail(error));
            }
        }
    },
    details: {
        get: function* (action: any) {
            const params = action.payload?.data;
            try {
                const data = yield call(API.default.PaymentAgreement.getAgreementDetail, params);
                //yield put(paymentAgreement.getAgreementDetailsSuccess(data));
            } catch (error) {
                //yield put(paymentAgreement.getAgreementDetailsFail(error));
            }
        }
    },

    interest: {
        get: function* (action: any) {
            const params = action.payload?.data;
            try {
                const data = yield call(API.default.PaymentAgreement.getInterests, params);
                yield put(paymentAgreement.interests.success(data));
            } catch (error) {
                // yield put(paymentAgreement.getInterestsFail(error));
            }
        }
    },
    agreement: {
        save: function* (action: any):any {
            const params = action.payload?.data;
            let temParams = {
                entityType: params?.caseType,
                caseNo: params?.caseNo
            }
            try {
                const data = yield call(API.default.PaymentAgreement.saveAgreement, params);
                if (data?.result?.agreementId > 0) {
                    message.success('Successfully added')
                   // const res = yield call(API.default.PaymentAgreement.getAgreementDetail, temParams);
                    const [amounts, details] = yield all([
                        call(API.default.PaymentAgreement.getAgreementAmounts, params?.caseNo),
                        call(API.default.PaymentAgreement.getAgreementDetail, temParams)
                    ])
                    yield put(paymentAgreement.amountsAndDetails.success({ amounts, details }))
                    //yield put(paymentAgreement.getAgreementDetailsSuccess(res));
                }
                //yield put(paymentAgreement.saveSuccess(data))
            } catch (error) {
                //yield put(paymentAgreement.saveFail(error))
            }
        },
        delete: function* (action: any):any {
            const params = action.payload?.data;
            try {
                const data = yield call(API.default.PaymentAgreement.deleteAgreement, params?.id);
                if (!data?.isError) {
                    //yield put(paymentAgreement.getAgreementDetailsSuccess({ result: details }));
                    const [amounts, details] = yield all([
                        call(API.default.PaymentAgreement.getAgreementAmounts, params?.caseNo),
                        call(API.default.PaymentAgreement.getAgreementDetail,params)
                    ])
                    yield put(paymentAgreement.amountsAndDetails.success({ amounts, details }))
                }
                //yield put(paymentAgreement.deleteSuccess(data));
            } catch (error) {
                //yield put(paymentAgreement.deleteFail(error));
            }
        },
        test: function* (action: any): any {
            const params = action.payload?.data;
            try {
                const [amounts, details] = yield all([
                    call(API.default.PaymentAgreement.getAgreementAmounts, params?.caseNo),
                    call(API.default.PaymentAgreement.getAgreementDetail, params)
                ])
                // yield put(paymentAgreement.getAgreementAmountsSuccess(amounts));
                // yield put(paymentAgreement.getAgreementDetailsSuccess(details));
                yield put(paymentAgreement.amountsAndDetails.success({ amounts, details }))
            } catch (error) {
                //yield put(paymentAgreement.deleteFail(error));
            }
        }
    }
}

export default [
    // takeLatest(PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_START, agreement.amounts.get),
    //takeLatest(PaymentAgreement.GET_PAYMENT_AGREEMENT_DETAIL_START, agreement.details.get),
    takeLatest(PaymentAgreement.SAVE_PAYMENT_AGREEMENT_START, agreement.agreement.save),
    takeLatest(PaymentAgreement.GET_PAYMENT_AGREEMENT_INTEREST_START, agreement.interest.get),
    takeLatest(PaymentAgreement.DELETE_PAYMENT_AGREEMENT_START, agreement.agreement.delete),
    takeLatest(PaymentAgreement.GET_PAYMENT_AGREEMENT_AMOUNTS_AND_DETAIL_START, agreement.agreement.test),
];