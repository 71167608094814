import { IAddNewApportionment } from "../Interfaces";
import { ItemTypeIds, TransactionTexts, TransTypes, GroupTextTypes } from "../Constant";
import moment from "moment";

export function AddNewApportionment(this: IAddNewApportionment) {
  return {
    key: this.typeId == ItemTypeIds.ROUNDING_OFF ? this.mainPayment.caseNo : '',
    caseNo: this.typeId == ItemTypeIds.ROUNDING_OFF ? this.mainPayment.caseNo : '',
    caseId: this.typeId == ItemTypeIds.ROUNDING_OFF ? this.mainPayment.caseId : 0,
    caseType: this.typeId == ItemTypeIds.ROUNDING_OFF ? this.mainPayment.caseType : '',
    total: this.amountValue,
    balance: 0,
    totalCreditorAmount: 0,
    totalDebtorAmount: this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? this.amountValue : 0,
    totalOtherAmount: 0,
    totalBureauAmount: this.typeId == ItemTypeIds.RETURN_FEE || this.typeId == ItemTypeIds.ROUNDING_OFF ? this.amountValue : 0,
    totalVatAmount: 0,
    totalVatBasis: 0,
    totalVatDeducted: 0,
    totalVatPaidByDebtor: 0,
    items: [{
        key: this.typeId == ItemTypeIds.ROUNDING_OFF ? "p0-"+this.mainPayment.caseNo : "p0",
        text: this.text,
        isParent: true,
        amount: this.amountValue,
        children: [{
          amount: this.amountValue,
          amountBureuVat: 0,
          amountCreditorVat: 0,
          apportionId: 0,
          arId: 0,
          accountNo: this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? this.itemsInfo.accountNo : '',
          bureauAmount: this.typeId == ItemTypeIds.RETURN_FEE || this.typeId == ItemTypeIds.ROUNDING_OFF  ? this.amountValue : 0,
          caseNo: this.typeId == ItemTypeIds.ROUNDING_OFF ? this.mainPayment.caseNo : '',
          caseId: this.typeId == ItemTypeIds.ROUNDING_OFF ? this.mainPayment.caseId : 0,
          caseType: this.typeId == ItemTypeIds.ROUNDING_OFF ? this.mainPayment.caseType : '',
          childIndex: 0,
          creditorAmount: 0,
          debtorAmount: this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? this.amountValue : 0,
          exceedAmount: this.typeId == ItemTypeIds.EXCEEDED_AMOUNT ? this.amountValue : 0,
          groupText: this.typeId == ItemTypeIds.EXCEEDED_AMOUNT ? GroupTextTypes.EXCEEDED_AMOUNT : this.typeId == ItemTypeIds.ROUNDING_OFF ? GroupTextTypes.ROUNDING_OFF : GroupTextTypes.RETURN_TO_DEBTOR,
          invoiceNo: "",
          isInterest: false,
          isRemitted: false,
          itemIndex: 0,
          itemTypeId: this.typeId,
          journalId: "",
          key: 'ch-1',
          modifiedDate: moment(),
          modifiedUser: this.mainPayment.currentUser.upn,
          otherAmount: 0,
          parentIndex: 1,
          paymentAmount: this.mainPayment.paymentAmount,
          paymentArItemNo: 0,
          paymentId: this.mainPayment.paymentId,
          transactionProfileNo: 0,
          ref: 0,
          remitVoucherNo: 0,
          subcaseId: 0,
          text: this.text,
          transType: this.typeId == ItemTypeIds.EXCEEDED_AMOUNT ? TransTypes.EXCEEDED_AMOUNT : this.typeId == ItemTypeIds.ROUNDING_OFF ? TransTypes.ROUNDING_OFF : TransTypes.RETURN_TO_DEBTOR,
          transactionArItemNo: 0,
          transactionBalance: 0,
          transactionGroupId: "0",
          transactionText: this.typeId == ItemTypeIds.EXCEEDED_AMOUNT ? TransactionTexts.EXCEEDED_AMOUNT : this.typeId == ItemTypeIds.RETURN_FEE ? TransactionTexts.RETURN_FEE : this.typeId == ItemTypeIds.ROUNDING_OFF ? TransactionTexts.ROUNDING_OFF : TransactionTexts.RETURN_TO_DEBTOR,
          vatAmount: 0,
          vatBasis: 0,
          vatCode: 0,
          vatDeducted: 0,
          vatLiable: false,
          vatPaidByDebtor: 0,
          vatRate: 0,
          isNew: true
        }],
        totalTransBalanceByText: 0,
        totalCreditorAmountByText: 0,
        totalDebtorAmountByText: this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? this.amountValue : 0,
        totalOtherAmountByText: 0,
        totalBureauAmountByText: this.typeId == ItemTypeIds.RETURN_FEE || this.typeId == ItemTypeIds.ROUNDING_OFF ? this.amountValue : 0,
      }]
  }
}
