import {
  IManagePaymentsActions,
  IManagePaymentsGridActions,
  IManagePaymentsApportionmentActions,
} from "us.collection.economy/interfaces/ManagePayments";

import {
  ManagePayments,
  ManagePaymentsGrid,
  ManagePaymentsApportionment,
} from "us.collection.economy/constants/Actions";

export const managePayment: IManagePaymentsActions = {
  initPayments: (data) => ({
    type: ManagePayments.INIT_PAYMENTS,
    payload: {
      data,
      isLoading: true,
    },
  }),
  initPaymentsSuccess: (data) => ({
    type: ManagePayments.INIT_PAYMENTS_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  initPaymentsFail: (error) => ({
    type: ManagePayments.INIT_PAYMENTS_FAILED,
    payload: {
      error,
      isLoading: false,
    },
  }),

  getPaymentAccounts: (data) => ({
    type: ManagePayments.GET_PAYMENT_ACCOUNTS,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getPaymentAccountsSuccess: (data) => ({
    type: ManagePayments.GET_PAYMENT_ACCOUNTS_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getPaymentAccountsFail: (error) => ({
    type: ManagePayments.GET_PAYMENT_ACCOUNTS_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),

  searchPayments: (data) => ({
    type: ManagePayments.SEARCH_PAYMENTS,
    payload: {
      data,
      isLoading: true,
    },
  }),
  searchPaymentsSuccess: (data) => ({
    type: ManagePayments.SEARCH_PAYMENTS_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  searchPaymentsNoResult: (data) => ({
    type: ManagePayments.SEARCH_PAYMENTS_NO_RESULT,
    payload: {
      data,
      isLoading: false,
    },
  }),
  searchPaymentsFail: (error) => ({
    type: ManagePayments.SEARCH_PAYMENTS_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),

  getPaymentDistribution: (data) => ({
    type: ManagePayments.GET_PAYMENTS_DISTRIBUTION,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getPaymentDistributionSuccess: (data) => ({
    type: ManagePayments.GET_PAYMENTS_DISTRIBUTION_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getPaymentDistributionFail: (error) => ({
    type: ManagePayments.GET_PAYMENTS_DISTRIBUTION_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),

  returnPayment: (data, filters) => ({
    type: ManagePayments.RETURN_PAYMENT,
    payload: {
      data,
      filters,
      isLoading: true,
    },
  }),
  returnPaymentSuccess: (data) => ({
    type: ManagePayments.RETURN_PAYMENT_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  returnPaymentFail: (error) => ({
    type: ManagePayments.RETURN_PAYMENT_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),

  editPayment: (data, filters) => ({
    type: ManagePayments.EDIT_PAYMENT,
    payload: {
      data,
      filters,
      isLoading: true,
    },
  }),

  editPaymentSuccess: (data) => ({
    type: ManagePayments.EDIT_PAYMENT_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  editPaymentFail: (error) => ({
    type: ManagePayments.EDIT_PAYMENT_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),

  setPaymentsWithAcc: (data) => ({
    type: ManagePayments.SET_PAYMENTS_WITH_ACCOUNT,
    payload: {
      data,
    },
  }),

  getCases: (data) => ({
    type: ManagePayments.GET_CASES,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getCasesSuccess: (data) => ({
    type: ManagePayments.GET_CASES_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getCasesFail: (error) => ({
    type: ManagePayments.GET_CASES_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  matchPayment: (data) => ({
    type: ManagePayments.MATCH_PAYMENT,
    payload: {
      data,
      isLoading: true,
    },
  }),
  matchPaymentSuccess: (data) => ({
    type: ManagePayments.MATCH_PAYMENT_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  matchPaymentFail: (error) => ({
    type: ManagePayments.MATCH_PAYMENT_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  resetMatchPaymentResult: (data) => ({
    type: ManagePayments.RESET_MATCH_PAYMENT_RESULT,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getCaseValidationData: (data) => ({
    type: ManagePayments.GET_CASE_VALIDATION_DATA,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getCaseValidationDataSuccess: (data) => ({
    type: ManagePayments.GET_CASE_VALIDATION_DATA_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getCaseValidationDataFail: (error) => ({
    type: ManagePayments.GET_CASE_VALIDATION_DATA_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  clearValidateCaseData: () => ({
    type: ManagePayments.CLEAR_VALIDATE_CASE_DATA,
  }),
  deleteCase: (data) => ({
    type: ManagePayments.DELETE_CASE,
    payload: data,
  }),

  reverseReturnedPayment: (data, mainFilters) => ({
    type: ManagePayments.REVERSE_RETURNED_PAYMENT,
    payload: {
      data,
      mainFilters,
      isLoading: true,
    },
  }),
  reverseReturnedPaymentSuccess: (data) => ({
    type: ManagePayments.REVERSE_RETURNED_PAYMENT_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  reverseReturnedPaymentFail: (error) => ({
    type: ManagePayments.REVERSE_RETURNED_PAYMENT_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  revertMappedPayment: (data, mainFilters) => ({
    type: ManagePayments.REVERT_MAPPED_PAYMENT,
    payload: {
      data,
      mainFilters,
      isLoading: true,
    },
  }),
  revertMappedPaymentSuccess: (data) => ({
    type: ManagePayments.REVERT_MAPPED_PAYMENT_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  revertMappedPaymentFail: (error) => ({
    type: ManagePayments.REVERT_MAPPED_PAYMENT_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  revertPaymentPopconfirmStatus: (status, id) => ({
    type: ManagePayments.REVERT_PAYMENT_POPCONFIRM_STATUS,
    payload: {
      status,
      id,
      isLoading: false,
    },
  }),
  addCaseTableRowKey: (data) => ({
    type: ManagePayments.ADD_CASE_TABLE_ROW_KEY,
    payload: data,
  }),
  deleteCaseTableRowKey: (data) => ({
    type: ManagePayments.DELETE_CASE_TABLE_ROW_KEY,
    payload: data,
  }),
  resetMatch: (data) => ({
    type: ManagePayments.RESET_MATCH,
    payload: data,
  }),
};

export const managePaymentGrid: IManagePaymentsGridActions = {
  initPayments: (data) => ({
    type: ManagePaymentsGrid.INIT_PAYMENTS_GRID,
    payload: {
      data,
      isLoading: true,
    },
  }),
  initPaymentsSuccess: (data) => ({
    type: ManagePaymentsGrid.INIT_PAYMENTS_GRID_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  initPaymentsFail: (error) => ({
    type: ManagePaymentsGrid.INIT_PAYMENTS_GRID_FAILED,
    payload: {
      error,
      isLoading: false,
    },
  }),

  payments: (data) => ({
    type: ManagePaymentsGrid.GET_PAYMENTS_GRID,
    payload: {
      data,
      isLoading: true,
    },
  }),
  paymentsSuccess: (data) => ({
    type: ManagePaymentsGrid.GET_PAYMENTS_GRID_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  paymentsFail: (error) => ({
    type: ManagePaymentsGrid.GET_PAYMENTS_GRID_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
};

export const managePaymentApportionment: IManagePaymentsApportionmentActions = {
  getPaymentsApportionments: (data) => ({
    type: ManagePaymentsApportionment.GET_PAYMENTS_APPORTIONMENT,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getPaymentsApportionmentsSuccess: (data, accNo, amount) => ({
    type: ManagePaymentsApportionment.GET_PAYMENTS_APPORTIONMENT_SUCCESS,
    payload: {
      data,
      accNo,
      amount,
      isLoading: false,
    },
  }),
  getPaymentsApportionmentsFail: (error) => ({
    type: ManagePaymentsApportionment.GET_PAYMENTS_APPORTIONMENT_SUCCESS,
    payload: {
      error,
      isLoading: false,
    },
  }),
  getVatAmounts: (data) => ({
    type: ManagePaymentsApportionment.GET_VAT_AMOUNT,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getVatAmountsSuccess: (data, values, record) => ({
    type: ManagePaymentsApportionment.GET_VAT_AMOUNT_SUCCESS,
    payload: {
      data,
      values,
      record,
      isLoading: false,
    },
  }),
  getVatAmountsFail: (error) => ({
    type: ManagePaymentsApportionment.GET_VAT_AMOUNT_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  getVatAmountsForReturnFee: (data) => ({
    type: ManagePaymentsApportionment.GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getVatAmountsForReturnFeeSuccess: (data, values) => ({
    type: ManagePaymentsApportionment.GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_SUCCESS,
    payload: {
      data,
      values,
      isLoading: false,
    },
  }),
  getVatAmountsForReturnFeeFail: (error) => ({
    type: ManagePaymentsApportionment.GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  updateApportionment: (data, mainFilters, payment) => ({
    type: ManagePaymentsApportionment.UPDATE_APPORTIONMENT,
    payload: {
      data,
      mainFilters,
      payment,
      isLoading: true,
    },
  }),

  updateApportionmentSuccess: (data) => ({
    type: ManagePaymentsApportionment.UPDATE_APPORTIONMENT_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  updateApportionmentFail: (error) => ({
    type: ManagePaymentsApportionment.UPDATE_APPORTIONMENT_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  changeDrawerInfo: (data) => ({
    type: ManagePaymentsApportionment.CHANGE_DRAWER_INFO,
    payload: {
      data,
    },
  }),
  addApportionmentTableRowKey: (data) => ({
    type: ManagePaymentsApportionment.ADD_APPORTIONMENT_TABLE_ACTIVE_KEYS,
    payload: data,
  }),
  deleteApportionmentTableRowKey: (data) => ({
    type: ManagePaymentsApportionment.DELETE_APPORTIONMENT_TABLE_ACTIVE_KEYS,
    payload: data,
  }),
};
