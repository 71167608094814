import { ISaveLedgerDetails } from "../Interfaces";

export function SaveLedgerAccount(this: any): ISaveLedgerDetails {
  return {
    accountId: this.accountId,
    accountNo: this.accountNo,
    accountName: this.accountName,
    description: this.description ? this.description : "",
    isSystem: this.isSystem,
  };
}
