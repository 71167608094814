import React from "react";
import { useTranslation } from "react-i18next";
import { CopyOutlined, EditOutlined } from "us.icons";
import { $Popconfirm, $Tag, $Divider } from "us.common/components";
import { ProvisionProfileAction } from "../../Interfaces";
import { ProvisionProfile } from "us.collection.economy/interfaces";

interface IItemMenu {
  record: ProvisionProfile;
  actions?: ProvisionProfileAction[];
  onCallAction: (actionType: ProvisionProfileAction) => void;
}

/**
 * @description - Remit History to view previous remit records, navigate from the main menu
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2815000877/Remit+History+-+UI+Design+and+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 06/01/2022
 */
const ItemMenu: React.FC<IItemMenu> = ({
  record,
  actions = [
    ProvisionProfileAction.COPY,
    ProvisionProfileAction.EDIT,
    ProvisionProfileAction.ACTIVATE_OR_DEACTIVATE,
  ],
  onCallAction,
}) => {
  const { t } = useTranslation();
  const { isActive, isUsed } = record;

  return (
    <div className="table-more-content" data-testid="popover-content">
      {actions.includes(ProvisionProfileAction.COPY) && (
        <div
          className="d-flex  flex-row more-item"
          data-testid="copy-btn"
          onClick={() => onCallAction(ProvisionProfileAction.COPY)}
        >
          <div className="p-1">
            <CopyOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.COPY")}</div>
        </div>
      )}
      {actions.includes(ProvisionProfileAction.EDIT) && (
        <div
          className="d-flex flex-row more-item"
          data-testid="edit-btn"
          onClick={() => onCallAction(ProvisionProfileAction.EDIT)}
        >
          <div className="p-1">
            <EditOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
        </div>
      )}
      <$Divider className="my-2" />
      {actions.includes(ProvisionProfileAction.ACTIVATE_OR_DEACTIVATE) &&
        !isUsed && (
          <$Popconfirm
            title={t("US.COMMON:COMMON.SURE_TO_PROCEED_?")}
            data-testid="popUpConfirm-box"
            onConfirm={() =>
              onCallAction(ProvisionProfileAction.ACTIVATE_OR_DEACTIVATE)
            }
          >
            <div
              data-testid="delete-btn"
              className="d-flex flex-row more-item w-100 p-1"
            >
              {isActive ? (
                <$Tag className="tag-status-deactive m-0">
                  <span>{t("COMMON.DEACTIVATE")}</span>
                </$Tag>
              ) : (
                <$Tag className="tag-status-active w-100 m-0">
                  <span>{t("COMMON.ACTIVATE")}</span>
                </$Tag>
              )}
            </div>
          </$Popconfirm>
        )}
    </div>
  );
};

export default ItemMenu;
