import { INITIAL_STATE } from "us.collection.economy/reducers/LedgerAccounts/State";
import { LedgerAccount } from "us.collection.economy/constants/Actions";

const {
  GET_LEDGER_ACCOUNTS_DATA,
  GET_LEDGER_ACCOUNTS_DATA_SUCCESS,
  GET_LEDGER_ACCOUNTS_DATA_FAIL,
  ADD_NEW_LEDGER_ACCOUNT,
  ADD_NEW_LEDGER_ACCOUNT_SUCCESS,
  ADD_NEW_LEDGER_ACCOUNT_FAIL,
  EDIT_LEDGER_ACCOUNT,
  EDIT_LEDGER_ACCOUNT_SUCCESS,
  EDIT_LEDGER_ACCOUNT_FAIL,
  HANDLE_LEDGER_ACCOUNTS_DRAWER,
  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD,
  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_SUCCESS,
  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_FAIL,
} = LedgerAccount;

export default (state = INITIAL_STATE, { payload, type }: any) => {
  switch (type) {
    case GET_LEDGER_ACCOUNTS_DATA:
      return Object.assign({}, state, {
        ...INITIAL_STATE,
        isListLoading: true,
      });
    case GET_LEDGER_ACCOUNTS_DATA_SUCCESS:
      return Object.assign({}, state, {
        list: payload?.data,
        isListLoading: false,
      });
    case GET_LEDGER_ACCOUNTS_DATA_FAIL:
      return Object.assign({}, state, {
        list: [],
        isListLoading: false,
      });
    case ADD_NEW_LEDGER_ACCOUNT:
      return Object.assign({}, state, {
        saveLedgerAccount: {
          data: {},
          isLoading: true,
        },
      });
    case ADD_NEW_LEDGER_ACCOUNT_SUCCESS:
      const { result } = payload?.data ?? {};
      return Object.assign({}, state, {
        saveLedgerAccount: {
          data: result,
          isLoading: false,
        },
        drawerInfo: {
          ...INITIAL_STATE.drawerInfo,
          visible: false,
        },
      });
    case ADD_NEW_LEDGER_ACCOUNT_FAIL:
      return Object.assign({}, state, {
        saveLedgerAccount: {
          data: {},
          isLoading: false,
        },
      });
    case EDIT_LEDGER_ACCOUNT:
      return Object.assign({}, state, {
        saveLedgerAccount: {
          data: {},
          isLoading: true,
        },
      });
    case EDIT_LEDGER_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        saveLedgerAccount: {
          data: payload?.data?.result,
          isLoading: false,
        },
        drawerInfo: {
          ...INITIAL_STATE.drawerInfo,
          visible: false,
        },
      });
    case EDIT_LEDGER_ACCOUNT_FAIL:
      return Object.assign({}, state, {
        saveLedgerAccount: {
          data: {},
          isLoading: false,
        },
      });
    case DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD:
      return Object.assign({}, state, {
        duplicateValidation: {
          ...state.duplicateValidation,
          status: true,
          isValidating: true,
        },
      });
    case DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_SUCCESS:
      return Object.assign({}, state, {
        duplicateValidation: {
          ...state.duplicateValidation,
          status: payload.data.status,
          isValidating: false,
        },
      });
    case DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_FAIL:
      return Object.assign({}, state, {
        duplicateValidation: {
          ...INITIAL_STATE.duplicateValidation,
        },
      });
    case HANDLE_LEDGER_ACCOUNTS_DRAWER:
      return Object.assign({}, state, {
        drawerInfo: {
          ...state.drawerInfo,
          ...payload.data,
          visible: !state.drawerInfo.visible,
        },
        duplicateValidation: {
          ...INITIAL_STATE.duplicateValidation,
        },
      });
    default:
      return state;
  }
};
