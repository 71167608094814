import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import {
  COLUMNS,
  RemitAction,
  RemitHistoryTableColumn,
} from "us.collection.economy/components/RemitHistory/Constants";
import { MoreOutlined } from "us.icons";
import { Align } from "us.collection.economy/constants";
import { ItemMenu } from "./Components";
import { RemitActionType } from "us.collection.economy/components/RemitHistory/Interfaces";
import { connect, ConnectedProps } from "react-redux";
import {
  getColumnWidth,
  getRemitActions,
} from "us.collection.economy/components/RemitHistory/Functions";
import { IRemitHistoryTable } from "./Interfaces";
import { IRootState } from "us.collection/interfaces";
import { RemitDetails } from "us.collection.economy/interfaces";
import { getColumnFilterProps } from "./Functions";

const {
  $Button,
  $AmountLabel,
  $DateLabel,
  $Popover,
  $Tag,
  $Tooltip,
  $DateTimeLabel,
  $AccountNoLabel,
  $TableTree,
  $Empty,
} = Components;

/**
 * @description - Remit History to view previous remit records, navigate from the main menu
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2815000877/Remit+History+-+UI+Design+and+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 06/01/2022
 */
const RemitHistoryTable: React.FC<IRemitHistoryTable & PropsFromRedux> = (
  props
) => {
  const { remits, onCallAction } = props;
  const { t } = useTranslation();
  const [itemMenu, setItemMenu] = useState<{
    visible: boolean;
    id: number;
    visibleTooltip: boolean;
    confirmation?: boolean;
  }>({ visible: false, id: -1, visibleTooltip: false, confirmation: false });

  /**
   * @description - render rows
   */
  const renderCell = (record: RemitDetails, dataIndex: string) => {
    const {
      journalId,
      fileDate,
      bureauAmount,
      creditorAmount,
      debtorAmount,
      totalAmount,
      sourceBankAccountNo,
      remitFrom,
      remitTo,
      remitVoucherNo,
      isValid,
      downloadedUser,
      downloadedDateTime,
      isOrderInvoiced,
      isReversible,
    } = record;
    const row = {
      children: (
        <>
          {dataIndex === RemitHistoryTableColumn.MENU && (
            <div className="align-items-center" key={remitVoucherNo}>
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={itemMenu.visible && itemMenu.id === remitVoucherNo}
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    id: remitVoucherNo,
                  });
                }}
                content={
                  <ItemMenu
                    reversible={isReversible}
                    isOrderInvoiced={isOrderInvoiced}
                    actions={getRemitActions(record)}
                    onCallAction={(actionType: RemitActionType) => {
                      setItemMenu({
                        ...itemMenu,
                        visible: false,
                        id: remitVoucherNo,
                        confirmation: actionType == RemitAction.REVERSE,
                      });
                      onCallAction(actionType, record);
                    }}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  id="btnItemMenu"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            </div>
          )}
          {dataIndex === RemitHistoryTableColumn.JOURNAL_ID && (
            <div className="align-items-center" key={remitVoucherNo}>
              <span className="ml-2" data-testid="journalId">
                {journalId}
              </span>
            </div>
          )}
          {dataIndex === RemitHistoryTableColumn.VALIDATION_STATUS && (
            <$Tag color={isValid ? "green" : "red"}>
              {isValid
                ? t("US.COLLECTION.COMMON:COMMON.VALID")
                : t("US.COLLECTION.COMMON:COMMON.INVALID")}
            </$Tag>
          )}
          {dataIndex === RemitHistoryTableColumn.FILE_DATE && (
            <$DateLabel value={fileDate} />
          )}
          {dataIndex === RemitHistoryTableColumn.BUREAU_AMOUNT && (
            <$AmountLabel value={bureauAmount} data-testid={"bureau-amount"} />
          )}
          {dataIndex === RemitHistoryTableColumn.CREDITOR_AMOUNT && (
            <$AmountLabel
              value={creditorAmount}
              data-testid={"creditor-amount"}
            />
          )}
          {dataIndex === RemitHistoryTableColumn.DEBTOR_AMOUNT && (
            <$AmountLabel value={debtorAmount} data-testid={"debtor-amount"} />
          )}
          {dataIndex === RemitHistoryTableColumn.TOTAL_AMOUNT && (
            <$AmountLabel value={totalAmount} data-testid={"total-amount"} />
          )}
          {dataIndex === RemitHistoryTableColumn.SOURCE_ACCOUNT && (
            <$AccountNoLabel value={sourceBankAccountNo} />
          )}
          {dataIndex === RemitHistoryTableColumn.REMIT_PERIOD && (
            <span>
              <$DateLabel value={remitFrom} />
              {" - "}
              <$DateLabel value={remitTo} />
            </span>
          )}
          {dataIndex === RemitHistoryTableColumn.REMIT_VOUCHER && (
            <span data-testid={`remit-voucher-no-${remitVoucherNo}`}>
              {remitVoucherNo}
            </span>
          )}
          {dataIndex === RemitHistoryTableColumn.LAST_DOWNLOADED_USER && (
            <$Tooltip
              placement="top"
              title={() => (
                <div className="d-flex flex-column">
                  <span data-testid={`remit-last-downloaded-user`}>
                    {downloadedUser}
                  </span>
                  <span>
                    <$DateTimeLabel value={downloadedDateTime} />
                  </span>
                </div>
              )}
            >
              <span data-testid={`remit-last-downloaded-user`}>
                {downloadedUser}
              </span>
            </$Tooltip>
          )}
        </>
      ),
    };
    return row;
  };

  /**
   * @description - Generate table columns with sorter and filters
   * @returns - An array of columns
   */
  const getColumns = (): any[] => {
    const columns: any[] = [];
    COLUMNS.map(({ key, title }, _index) => {
      const columnWidth = getColumnWidth(key);
      let column: any = {
        dataIndex: key,
        key,
        width: columnWidth,
        title: title && t(`US.COLLECTION.ECONOMY:REMIT_HISTORY.${title}`),
        ...getColumnFilterProps(key),
        customRenderChild: (_text: any, record: any) => renderCell(record, key),
        align: [
          RemitHistoryTableColumn.BUREAU_AMOUNT,
          RemitHistoryTableColumn.CREDITOR_AMOUNT,
          RemitHistoryTableColumn.DEBTOR_AMOUNT,
          RemitHistoryTableColumn.TOTAL_AMOUNT,
        ].includes(key)
          ? Align.RIGHT
          : Align.LEFT,
      };
      if (key !== RemitHistoryTableColumn.MENU) {
        if (key === RemitHistoryTableColumn.LAST_DOWNLOADED_USER) {
          column = {
            ...column,
            ellipsis: {
              showTitle: false,
            },
            customSorter: (a: any, b: any) => a.localeCompare(b),
          };
        } else {
          column = {
            ...column,
            customSorter: (a: any, b: any) => {
              if (key === RemitHistoryTableColumn.JOURNAL_ID) {
                return a.localeCompare(b);
              } else if (key === RemitHistoryTableColumn.FILE_DATE) {
                return new Date(a).getTime() - new Date(b).getTime();
              } else if (key === RemitHistoryTableColumn.REMIT_PERIOD) {
                return a - b;
              } else if (key === RemitHistoryTableColumn.VALIDATION_STATUS) {
                if (a) return 1;
                if (b) return -1;
              } else {
                return a - b;
              }
            },
          };
        }
      }
      columns.push(column);
    });
    return columns;
  };

  return (
    <$TableTree
      rowKey={(record: any) => record.journalId}
      data={remits}
      size="small"
      className="mt-3 table-striped header-custom-tag"
      onSort={(sortData, dataSource) => {
        return sortData(dataSource);
      }}
      onFilter={(searchData, dataSource) => {
        return searchData(dataSource);
      }}
      filterOnType
      resetOnSourceChange
      bordered
      showResetAllFilters
      pagination={{
        defaultPageSize: 20,
      }}
      scroll={{
        x: 900,
        y: "calc(100vh - 265px)",
      }}
      columns={getColumns()}
      locale={{
        emptyText: (
          <$Empty
            image={$Empty.PRESENTED_IMAGE_SIMPLE}
            description={
              <div className="text-center">
                <div>{t("US.COLLECTION.COMMON:COMMON.NO_DATA")}</div>
                <div>
                  {t(
                    "US.COLLECTION.ECONOMY:REMIT.SELECT_THE_CORRECT_BANK_ACCOUNT_AND_DATE_RANGE_TO_VIEW_REMIT_HISTORY"
                  )}
                </div>
              </div>
            }
          />
        ),
      }}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, remitHistory } = state;

  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  const { remits, bankAccounts, isLoadingBankAccounts, isLoadingRemitHistory } =
    remitHistory;

  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    remits,
    bankAccounts,
    isLoadingBankAccounts,
    isLoadingRemitHistory,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemitHistoryTable);
