import { IProvisionProfiles } from "us.collection.economy/interfaces";
import { DefaultProfile } from "us.collection.economy/constants";
import { DEFAULT_DRAWER_STATE } from "us.collection.economy/components/ProvisionProfiles/Constants";
import _ from "lodash";

export const initialState: IProvisionProfiles = {
  profiles: {
    data: [],
    isLoading: false,
  },
  profileDetails: {
    initialData: _.cloneDeep(DefaultProfile),
    data: _.cloneDeep(DefaultProfile),
    isLoading: false,
    result: null,
    isSaving: false,
  },
  drawerInfo: DEFAULT_DRAWER_STATE,
  newProfile: {
    data: null,
    isSaving: false,
  },
  profileNameValidation: {
    data: { status: true },
    isLoading: false,
    validated: false,
  },
  deleteProfile: {
    isLoading: false,
  },
};
