import { CoveringSequenceTableColumn } from "us.collection.economy/components/CoveringSequence/Components/Table/Constants";
/**
 * @description Get column search input field current value by key
 * @param {CoveringSequenceTableColumn} key - input field name
 * @returns return column filters
 */
export const getColumnFilterProps = (key: CoveringSequenceTableColumn) => {
  switch (key) {
    case CoveringSequenceTableColumn.MENU:
      return {};
    default:
      return { customFilter: true, showFilters: true };
  }
};
