import { ColumnSearchType, RemitAction } from "../Constants";

export enum RemitHistorySearchType {
  JOURNAL_ID = "JOURNALID",
  FILE_DATE = "FILEDATE",
}

export type IRemitHistorySearchType =
  | RemitHistorySearchType.JOURNAL_ID
  | RemitHistorySearchType.FILE_DATE;

export enum RemitFileStatus {
  ACTIVE = "ACTIVE",
  DELETED = "DELETED",
}

export type IRemitFileStatus = RemitFileStatus.ACTIVE | RemitFileStatus.DELETED;

export interface ISearchRemitHistory {
  startDate: string;
  endDate: string;
  remitFileStatus: IRemitFileStatus;
  accountNo: string;
  searchType: IRemitHistorySearchType;
  journalId: string;
  offset: number;
  limit: number;
}

export type RemitActionType =
  | RemitAction.RE_CREATE
  | RemitAction.VIEW_LEDGER_DASHBOARD
  | RemitAction.REVERSE
  | RemitAction.DOWNLOAD;

export type IColumnSearchType =
  | ColumnSearchType.ACCOUNT_NO
  | ColumnSearchType.AMOUNT
  | ColumnSearchType.DATE
  | ColumnSearchType.STRING;

export interface ITableInfo {
  filters: any;
  sorter: { columnKey: string; order: string };
  data: Array<any>;
}
