import React from 'react';
import { useTranslation } from 'react-i18next';
import { PaymentHelper } from 'us.collection.economy/components/ManagePayments/Functions';
import { IPaymentStates } from 'us.collection.economy/components/ManagePayments/interface';

interface IStatusCount {
	status: IPaymentStates;
	count: number;
}

const StatusCount: React.FC<IStatusCount> = React.memo(
	({ status, count = 0 }) => {
		const { t } = useTranslation();
		const { name, className } = PaymentHelper.getTag(status);
		return (
			<div
				className={`card-status-mp ${className} d-flex justify-content-between mb-1`}>
				<div>{t(name)}</div>
				<div>{count}</div>
			</div>
		);
	}
);

export default StatusCount;
