import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import "us.collection.economy/components/ClientOrders/Home.scss";
import { IOrder } from "us.collection.economy/components/ClientOrders/Interfaces";
import { useHistory } from "react-router-dom";
import { useField } from "formik";
import { IOrderItem } from "./interface";
import * as Actions from "us.collection.economy/actions";
import { MoreOutlined, EditOutlined, DeleteOutlined } from "us.icons";
import { connect, ConnectedProps } from "react-redux";
import { isManualOrder } from "us.collection.economy/components/ManualOrders/Functions/HelperFunctions";
import { getRouteUrl } from "us.helper";
const { order } = Actions.clientOrders;

const { $Checkbox, $AmountLabel, $DateLabel, $Popover, $Button, $Popconfirm } =
  Common.Components;

const OrderItem: React.FC<IOrderItem & PropsFromRedux> = ({
  order,
  selectedOrders,
  onSelectOrder,
  deleteOrder,
  searchParams,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const {
    customerName,
    customerNo,
    orderNo,
    netAmount,
    vatAmount,
    orderDate,
    totalAmount,
  } = order;

  const [, meta] = useField("isAllOrders");

  const [itemMenu, setItemMenu] = useState<{
    orderNo: number;
    visibleTooltip: boolean;
  }>({ orderNo: -1, visibleTooltip: false });

  /**
   * @description check whether an order item is selected or not
   * @returns true or false
   */
  const isOrderSelected = (id: number): boolean => {
    try {
      if (meta.value) {
        return true;
      } else if (selectedOrders) {
        return selectedOrders.some(({ orderNo }: IOrder) => orderNo === id);
      } else {
        return false;
      }
    } catch (error) {
      return false;
    }
  };

  const contentMoreItem = () => (
    <div className="table-more-content" data-testid="popover-content">
      <div
        data-testid="edit-btn"
        className="d-flex  flex-row more-item"
        onClick={navigate}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
      </div>
      {!isManualOrder([order]) && (
        <$Popconfirm
          title={
            <div>
              <p>{t("US.COLLECTION.COMMON:COMMON.SURE_TO_DELETE_?")}</p>
            </div>
          }
          onConfirm={() =>
            deleteOrder && deleteOrder({ orderNo, searchParams })
          }
        >
          <div
            className="d-flex mb-2 flex-row more-item text-error"
            data-testid="delete-btn"
          >
            <div className="p-1">
              <DeleteOutlined />
            </div>
            <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
          </div>
        </$Popconfirm>
      )}
    </div>
  );

  // auto close order item menu after 5 seconds
  const setAutoClose = (orderNo: number, isTooltip?: boolean) => {
    setTimeout(
      () => {
        setItemMenu({
          orderNo,
          visibleTooltip: false,
        });
      },
      isTooltip ? 8000 : 5000
    );
  };

  const navigate = () => push(`/manual-orders?orderId=${orderNo}&isEdit=true`);

  return (
    <div
      className="order-item-row"
      id={`${orderNo}`}
      data-testid={`order-item-${orderNo}`}
    >
      <div className="order-item-col" style={{ width: "20px" }}>
        <div className="d-flex align-items-center">
          {onSelectOrder && (
            <div>
              <$Checkbox
                checked={isOrderSelected(orderNo)}
                onChange={(e: any) => onSelectOrder(e.target.checked, order)}
              />
            </div>
          )}
        </div>
      </div>
      <div className="order-item-col" style={{ width: "40px" }}>
        <div>
          <$Popover
            placement="bottomLeft"
            trigger="click"
            visible={itemMenu.visibleTooltip && itemMenu.orderNo === orderNo}
            onVisibleChange={(visible: boolean) => {
              setItemMenu({
                visibleTooltip: visible,
                orderNo,
              });
              visible && setAutoClose(orderNo, true);
            }}
            content={contentMoreItem}
            destroyTooltipOnHide
          >
            <$Button
              data-testid="popover-menu-btn"
              icon={<MoreOutlined />}
              size="small"
            />
          </$Popover>
        </div>
      </div>
      <div className="order-item-col">
        <div
          className="d-flex flex-column"
          onClick={(e) => e.detail == 2 && navigate()}
        >
          <div className="font-weight-bold">
            {t("US.COLLECTION.ECONOMY:ORDERS.ORDER_NO")}: {orderNo}
          </div>
          <div className="text-muted d-flex">
            <span className="mr-1">
              {t("US.COLLECTION.ECONOMY:ORDERS.ORDER_DATE")}:
            </span>
            <$DateLabel value={orderDate} />
          </div>
        </div>
      </div>
      <div className="order-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted">
            {t("US.COLLECTION.ECONOMY:ORDERS.CUSTOMER")}
          </div>
          <a
            className="font-weight-bold"
            onClick={() =>
              getRouteUrl.moduleRoute("collection", `/creditor/${customerNo}`)
            }
          >
            {customerNo} - {customerName}
          </a>
        </div>
      </div>
      <div className="order-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted text-right">
            {t("US.COLLECTION.ECONOMY:ORDERS.NET_AMOUNT")}
          </div>
          <div className="text-right">
            <$AmountLabel value={netAmount} />
          </div>
        </div>
      </div>
      <div className="order-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted text-right">
            {t("US.COLLECTION.ECONOMY:ORDERS.VAT_AMOUNT")}
          </div>
          <div className="text-right">
            <$AmountLabel value={vatAmount} />
          </div>
        </div>
      </div>
      <div className="order-item-col">
        <div className="d-flex flex-column">
          <div className="text-muted text-right">
            {t("US.COLLECTION.ECONOMY:ORDERS.TOTAL")}
          </div>
          <div className="text-right font-weight-bold">
            <$AmountLabel value={totalAmount} />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = {
  deleteOrder: order.delete,
};

const connector = connect(null, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OrderItem);
