import React from "react";
import * as Components from "us.common/components";
import { useTranslation } from "react-i18next";
import { PaymentStates } from "us.collection.economy/constants/Payments";
import {
  IOnFilter,
  IOnSort,
  ITableTreeCell,
  ITableTreeColumns,
} from "us.common/components/antd/TableTree";
import { getRouteUrl } from "us.helper";
import moment from "moment";
const { $DateLabel, $AmountLabel } = Components;
const { EXCEEDED, MAPPED, UNKNOWN, RETURNED } = PaymentStates;

export const renderCellParent = (data: ITableTreeCell) => {
  const { t } = useTranslation();
  const d = data.row;
  const labels = [
    {
      label: "TOTAL_AMOUNT",
      value: d?.mpedTotal + d?.exedTotal + d?.ukwnTotal + d?.rntdTotal,
    },
    { label: "EXCEEDED_SUM", value: d?.exedTotal },
    { label: "UNKNOWN_SUM", value: d?.ukwnTotal },
    { label: "RETURN_TO_DEBTOR_SUM", value: d?.rntdTotal },
    { label: "MAPPED_SUM", value: d?.mpedTotal },
  ];

  if (d?.isParent) {
    return (
      <span className="font-weight-bold">
        {d?.type == "Manual"
          ? t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MANUAL")
          : d?.fileName}{" "}
        ({" "}
        {labels.map(({ label, value }, i) => (
          <span key={i}>
            {t(`US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.${label}`)} ={" "}
            {<$AmountLabel value={value} />} {labels.length !== i + 1 && "| "}
          </span>
        ))}{" "}
        )
      </span>
    );
  } else {
    return (
      <span className="font-weight-bold">
        {d?.paymentType == MAPPED && (
          <span>
            {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED")}(
            {
              d?.children?.filter((i: any) => i?.paymentStatus == MAPPED)
                ?.length
            }
            ) ( {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_AMOUNT")} ={" "}
            <$AmountLabel value={getDistributionTotal(d?.children, MAPPED)} /> )
          </span>
        )}
        {d?.paymentType == EXCEEDED && (
          <span>
            {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED")}(
            {
              d?.children?.filter((i: any) => i?.paymentStatus == EXCEEDED)
                ?.length
            }
            ) ( {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_AMOUNT")} ={" "}
            <$AmountLabel value={getDistributionTotal(d?.children, EXCEEDED)} />{" "}
            )
          </span>
        )}
        {d?.paymentType == UNKNOWN && (
          <span>
            {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN")}(
            {
              d?.children?.filter((i: any) => i?.paymentStatus == UNKNOWN)
                ?.length
            }
            ) ( {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_AMOUNT")} ={" "}
            <$AmountLabel value={getDistributionTotal(d?.children, UNKNOWN)} />{" "}
            )
          </span>
        )}
        {d?.paymentType == RETURNED && (
          <span>
            {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR")}(
            {
              d?.children?.filter((i: any) => i?.paymentStatus == RETURNED)
                ?.length
            }
            ) ( {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_AMOUNT")} ={" "}
            <$AmountLabel value={getDistributionTotal(d?.children, RETURNED)} />{" "}
            )
          </span>
        )}
      </span>
    );
  }
};

export const renderCellChild = (data: ITableTreeCell) => {
  const { dataIndex } = data.column;
  const { caseNo, paymentId, voucherNo } = data.row;
  if (dataIndex == "regDate" || dataIndex == "voucherDate") {
    return <$DateLabel value={data.text} />;
  } else if (dataIndex == "amount" || dataIndex == "paymentAmount") {
    return <$AmountLabel value={data.row[dataIndex]} />;
  } else if (dataIndex == "title") {
    // return <GridBtns type={data.row?.paymentStatus} />;
    return <div />;
  } else if (dataIndex == "caseNo") {
    return (
      <a onClick={() => getRouteUrl.caseType(caseNo)} target="_blank">
        {caseNo !== 0 && caseNo}
        {caseNo === 0 && ""}
      </a>
    );
  } else if (dataIndex == "paymentId") {
    return (
      <a
        onClick={() => getRouteUrl.paymentDistribution(paymentId)}
        target="_blank"
      >
        {data.text}
      </a>
    );
  } else if (dataIndex == "voucherNo") {
    return (
      <>
        {voucherNo !== "0" && voucherNo}
        {voucherNo === "0" && ""}
      </>
    );
  } else {
    return data.text;
  }
};

export const handleSort: IOnSort = (sortData, dataSource) => {
  return dataSource?.map((i: any) => {
    return {
      ...i,
      children: i.children?.map((a: any) => {
        return { ...a, children: sortData(a.children) };
      }),
    };
  });
};

export const handleFilter: IOnFilter = (searchData, dataSource) => {
  const childData = (data: any) => {
    return data.children?.flatMap((a: any) => {
      if (searchData(a?.children)?.length == 0) {
        return [];
      } else {
        return {
          ...a,
          children: searchData(a?.children),
        };
      }
    });
  };
  return dataSource?.flatMap((i: any) => {
    if (childData(i)?.length == 0) {
      return [];
    } else {
      return {
        ...i,
        children: childData(i),
      };
    }
  });
};

export const tableColumns = (t: any) => {
  const columns: ITableTreeColumns = [
    {
      title: "",
      dataIndex: "title",
      key: "title",
      render: (text: any) => <div>{text}</div>,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REG_DATE"),
      dataIndex: "regDate",
      width: "150px",
      key: "regDate",
      customSorter: (a: any, b: any) => moment(a).unix() - moment(b).unix(),
      customFilter: "date",
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VOUCHER_DATE_1"),
      dataIndex: "voucherDate",
      key: "voucherDate",
      width: "170px",
      customSorter: (a: any, b: any) => moment(a).unix() - moment(b).unix(),
      customFilter: "date",
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.AMOUNT"),
      dataIndex: "amount",
      key: "amount",
      align: "right",
      width: "150px",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.INVOICE_NO"),
      dataIndex: "ref",
      key: "ref",
      width: "160px",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NO"),
      dataIndex: "caseNo",
      key: "caseNo",
      width: "140px",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VOUCHER_NO"),
      dataIndex: "voucherNo",
      key: "voucherNo",
      width: "160px",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_ID"),
      dataIndex: "paymentId",
      key: "paymentId",
      width: "160px",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CUSTOMER_ID"),
      dataIndex: "cid",
      key: "cid",
      width: "180px",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.KID"),
      dataIndex: "kid",
      key: "kid",
      width: "180px",
      customSorter: (a: any, b: any) => a - b,
      customFilter: true,
      showFilters: true,
    },
    {
      title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_AMOUNT"),
      dataIndex: "paymentAmount",
      key: "paymentAmount",
      width: "200px",
      align: "right",
      customSorter: (a: any, b: any) => a - b,
      customFilter: "amount",
      showFilters: true,
    },
  ];
  return columns;
};

/**
 * @function getDistributionTotal
 * @param children Array of payment rows
 * @param type Payment type
 * @description Returns total amount of each row
 */
const getDistributionTotal = (children: any[] = [], type: string): number => {
  if (children) {
    return children?.reduce(
      (
        total: number,
        { amount, paymentStatus }: { amount: number; paymentStatus: string }
      ) => {
        if (paymentStatus == type) {
          total = total + amount;
        }
        return total;
      },
      0
    );
  } else {
    return 0;
  }
};
