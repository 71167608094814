export enum ClientAccounts {
  GET_CLIENT_ACCOUNTS = "GET_CLIENT_ACCOUNTS",
  GET_CLIENT_ACCOUNTS_SUCCESS = "GET_CLIENT_ACCOUNTS_SUCCESS",
  GET_CLIENT_ACCOUNTS_FAIL = "GET_CLIENT_ACCOUNTS_FAIL",

  ADD_CLIENT_NEW_ACCOUNT = "ADD_CLIENT_NEW_ACCOUNT",
  ADD_CLIENT_NEW_ACCOUNT_SUCCESS = "ADD_CLIENT_NEW_ACCOUNT_SUCCESS",
  ADD_CLIENT_NEW_ACCOUNT_FAIL = "ADD_CLIENT_NEW_ACCOUNT_FAIL",

  UPDATE_CLIENT_ACCOUNT = "UPDATE_CLIENT_ACCOUNT",
  UPDATE_CLIENT_ACCOUNT_SUCCESS = "UPDATE_CLIENT_ACCOUNT_SUCCESS",
  UPDATE_CLIENT_ACCOUNT_FAIL = "UPDATE_CLIENT_ACCOUNT_FAIL",

  GET_INIT_DETAILS = "GET_INIT_DETAILS",
  GET_INIT_DETAILS_SUCCESS = "GET_INIT_DETAILS_SUCCESS",
  GET_INIT_DETAILS_FAIL = "GET_INIT_DETAILS_FAIL",
  RESET_SAVE_UPDATE_DATA = "RESET_SAVE_UPDATE_DATA",

  VALIDATE_ACCOUNT_NUMBER = "VALIDATE_ACCOUNT_NUMBER",
  VALIDATE_ACCOUNT_NUMBER_SUCCESS = "VALIDATE_ACCOUNT_NUMBER_SUCCESS",
  VALIDATE_ACCOUNT_NUMBER_FAIL = "VALIDATE_ACCOUNT_NUMBER_FAIL",
  RESET_ACCOUNT_VALIDATION = "RESET_ACCOUNT_VALIDATION",

  VALIDATE_JOURNAL_PREFIX = "VALIDATE_JOURNAL_PREFIX",
  VALIDATE_JOURNAL_PREFIX_SUCCESS = "VALIDATE_JOURNAL_PREFIX_SUCCESS",
  VALIDATE_JOURNAL_PREFIX_FAIL = "VALIDATE_JOURNAL_PREFIX_FAIL",
  RESET_JOURNAL_PREFIX_VALIDATION = "RESET_JOURNAL_PREFIX_VALIDATION",

  DELETE_CLIENT_ACCOUNT = "DELETE_CLIENT_ACCOUNT",
  DELETE_CLIENT_ACCOUNT_SUCCESS = "DELETE_CLIENT_ACCOUNT_SUCCESS",
  DELETE_CLIENT_ACCOUNT_FAIL = "DELETE_CLIENT_ACCOUNT_FAIL",
}
