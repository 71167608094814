import React, { useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { IconTypes, BoxIcons, PlusOutlined } from "us.icons";
import { useHistory, useLocation } from "react-router-dom";
import { handleGoBack } from "us.helper/utility";
import * as Actions from "us.collection.economy/actions";
import { getRouteUrl } from "us.helper";
import { RootState } from "us.helper/types";
import { CreditNote } from "../ClientInvoices/Components";
import { ICreditorInvoiceDetails } from "./Interfaces";
import { Table, CreditInvoice } from "./Components";
import "./Home.scss";

const {
  $Affix,
  $PageHeader,
  $Divider,
  $Button,
  $AmountLabel,
  $DateLabel,
  $Popover,
  $Skeleton,
} = Common.Components;

/**
 * @description - Creditor Invoice Details View
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2728034451/Creditor+Invoicing+%3A+Invoice+Detail+View
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 25/08/2022
 */
const CreditorInvoiceDetails: React.FC<
  ICreditorInvoiceDetails & PropsFromRedux
> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const {
    getCreditorInvoiceDetails,
    isListLoading,
    creditorInvoiceDetailList,
    manageCreditNoteDrawer,
  } = props;
  const { push } = history;

  const {
    customerNo,
    customerName,
    balance,
    amount,
    dueDate,
    regdate,
    installmentKID,
    installmentNo,
    creditNotes,
    isCreditInvoice,
    sourceInvoiceNo,
  } = creditorInvoiceDetailList;

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const invoiceNo = urlParams.get("invoiceNo");

  useEffect(() => {
    getCreditorInvoiceDetails && getCreditorInvoiceDetails(invoiceNo);
  }, [invoiceNo]);

  return (
    <Formik initialValues={{}} enableReinitialize onSubmit={() => {}}>
      {() => (
        <>
          <$Affix offsetTop={48}>
            <div className="page-header header-border">
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={
                      <div>
                        {t(
                          "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.CREDITOR_INVOICE_DETAILS"
                        ) +
                          " : " +
                          invoiceNo ?? " - "}
                      </div>
                    }
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <$Button
                    type="default"
                    size="small"
                    icon={
                      <BoxIcons
                        className="mr-1"
                        type={IconTypes.BOX_ICON}
                        name="print"
                      />
                    }
                  >
                    {t("US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.PRINT")}
                  </$Button>

                  <$Divider className="bui-devider" type="vertical" />
                  {!isCreditInvoice && (
                    <div>
                      <$Button
                        type="default"
                        size="small"
                        icon={<PlusOutlined />}
                        onClick={() =>
                          manageCreditNoteDrawer &&
                          manageCreditNoteDrawer(
                            invoiceNo ? invoiceNo : "",
                            true
                          )
                        }
                      >
                        {t(
                          "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.NEW_CREDIT_INVOICE"
                        )}
                      </$Button>
                    </div>
                  )}
                  {!isCreditInvoice && (
                    <div>
                      <$Popover
                        placement="bottomRight"
                        content={<CreditInvoice />}
                        trigger="click"
                      >
                        <$Button
                          type="link"
                          size="small"
                          data-testid="credit-invoice-btn"
                          disabled={
                            creditNotes?.length == 0 || creditNotes == undefined
                          }
                        >
                          {t(
                            "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.CREDIT_INVOICE"
                          )}
                          : ({creditNotes?.length})
                        </$Button>
                      </$Popover>
                    </div>
                  )}
                  {isCreditInvoice && (
                    <a
                      onClick={() => {
                        push(
                          `/creditor-invoice-details?invoiceNo=${sourceInvoiceNo}`
                        );
                      }}
                    >
                      <span>
                        {t(
                          "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.SOURCE_INVOICE_NO"
                        )}
                        : {sourceInvoiceNo}
                      </span>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </$Affix>
          <div className="space-content">
            <div>
              <div className="d-flex justify-content-between align-items-center my-3">
                <div className="d-flex flex-column font-weight-bold">
                  <a
                    onClick={() => {
                      getRouteUrl.moduleRoute(
                        "collection",
                        `/creditor/${customerNo}`
                      );
                    }}
                    className="font-lg"
                  >
                    {customerNo} - {customerName}
                  </a>
                </div>
                <div className="inv-detail-su d-flex align-items-center">
                  <div>
                    <div className="font-weight-bold">
                      <$AmountLabel value={balance} />
                    </div>
                    <div className="text-muted">
                      {t(
                        "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.TOTAL_PAYABLE"
                      )}
                    </div>
                  </div>
                  <$Divider
                    style={{ height: "40px" }}
                    className="mx-3"
                    type="vertical"
                  />
                  <div>
                    <div className="font-weight-bold">
                      <$DateLabel value={dueDate} />
                    </div>
                    <div className="text-muted">
                      {t(
                        "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.DUE_DATE"
                      )}
                    </div>
                  </div>
                  <$Divider
                    style={{ height: "40px" }}
                    className="mx-3"
                    type="vertical"
                  />
                  <div>
                    <div className="font-weight-bold">
                      <$AmountLabel value={amount} />
                    </div>
                    <div className="text-muted">
                      {t(
                        "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.INVOICE_TOTAL"
                      )}
                    </div>
                  </div>
                  <$Divider
                    style={{ height: "40px" }}
                    className="mx-3"
                    type="vertical"
                  />
                  <div>
                    <div className="font-weight-bold">
                      <$DateLabel value={regdate} />
                    </div>
                    <div className="text-muted">
                      {t(
                        "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.GENERATED_DATE"
                      )}
                    </div>
                  </div>
                  <$Divider
                    style={{ height: "40px" }}
                    className="mx-3"
                    type="vertical"
                  />
                  <div>
                    <div className="font-weight-bold">
                      {installmentNo != "" ? installmentNo : "-"}
                    </div>
                    <div className="text-muted">
                      {t(
                        "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.INSTALLMEMNT_NO"
                      )}
                    </div>
                  </div>
                  <$Divider
                    style={{ height: "40px" }}
                    className="mx-3"
                    type="vertical"
                  />
                  <div>
                    <div className="font-weight-bold">
                      {installmentKID != "" ? installmentKID : "-"}
                    </div>
                    <div className="text-muted">
                      {t("US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.KID")}
                    </div>
                  </div>
                </div>
              </div>
              <$Skeleton loading={isListLoading} active paragraph={{ rows: 2 }}>
                <Table />
              </$Skeleton>
            </div>
          </div>
          <CreditNote filterValues={[]} invoiceNo={invoiceNo} />
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, creditorInvoiceDetails } = state;

  const { list, isListLoading } = creditorInvoiceDetails;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    creditorInvoiceDetailList: list,
    isListLoading,
  };
};

const { creditNoteDetails } = Actions.clientInvoices;
const { getDetails } = creditNoteDetails;
const { invoiceDetails } = Actions.creditorInvoiceDetails;

const mapDispatchToProps = {
  getCreditorInvoiceDetails: invoiceDetails.getById,
  manageCreditNoteDrawer: getDetails,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreditorInvoiceDetails);
