import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { GenerateContent } from "./Components";
import { IOrderGenerationBase } from "us.collection.economy/components/ClientOrders/Interfaces";
import "us.collection.economy/components/ClientOrders/Home.scss";

const { $Drawer } = Common.Components;

const OrderGeneration: React.FC<IOrderGenerationBase> = (props) => {
  const { t } = useTranslation();

  const {
    drawerProps,
    width = "1050",
    visible = false,
    searchValues,
    onClose,
  } = props;

  return (
    <>
      <$Drawer
        {...drawerProps}
        title={t("US.COLLECTION.ECONOMY:ORDERS.ORDER_GENERATION")}
        width={width}
        visible={visible}
        onClose={onClose}
        destroyOnClose={true}
        maskClosable={false}
      >
        <GenerateContent searchValues={searchValues} onClose={onClose} />
      </$Drawer>
    </>
  );
};

export default OrderGeneration;
