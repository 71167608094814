import { initialState } from "us.collection.economy/reducers/ClientAccounts/State";
import { ClientAccounts } from "us.collection.economy/constants/Actions";

const {
  GET_CLIENT_ACCOUNTS,
  GET_CLIENT_ACCOUNTS_SUCCESS,
  GET_CLIENT_ACCOUNTS_FAIL,
  ADD_CLIENT_NEW_ACCOUNT,
  ADD_CLIENT_NEW_ACCOUNT_SUCCESS,
  ADD_CLIENT_NEW_ACCOUNT_FAIL,
  UPDATE_CLIENT_ACCOUNT,
  UPDATE_CLIENT_ACCOUNT_SUCCESS,
  UPDATE_CLIENT_ACCOUNT_FAIL,
  GET_INIT_DETAILS,
  GET_INIT_DETAILS_SUCCESS,
  GET_INIT_DETAILS_FAIL,
  VALIDATE_ACCOUNT_NUMBER,
  VALIDATE_ACCOUNT_NUMBER_SUCCESS,
  VALIDATE_ACCOUNT_NUMBER_FAIL,
  RESET_ACCOUNT_VALIDATION,
  VALIDATE_JOURNAL_PREFIX,
  VALIDATE_JOURNAL_PREFIX_SUCCESS,
  VALIDATE_JOURNAL_PREFIX_FAIL,
  RESET_JOURNAL_PREFIX_VALIDATION,
  DELETE_CLIENT_ACCOUNT,
  DELETE_CLIENT_ACCOUNT_SUCCESS,
  DELETE_CLIENT_ACCOUNT_FAIL,
  RESET_SAVE_UPDATE_DATA,
} = ClientAccounts;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_CLIENT_ACCOUNTS:
      return Object.assign({}, state, {
        clientAccountsList: [],
        isLoadingClientAccounts: true,
      });
    case GET_CLIENT_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        clientAccountsList: payload?.data,
        isLoadingClientAccounts: false,
      });
    case GET_CLIENT_ACCOUNTS_FAIL:
      return Object.assign({}, state, {
        clientAccountsList: [],
        isLoadingClientAccounts: false,
      });
    case GET_INIT_DETAILS:
      return Object.assign({}, state, {
        ledgerAccounts: initialState.ledgerAccounts,
        remitSequenceDetails: initialState.remitSequenceDetails,
        ledgerSequenceDetails: initialState.ledgerSequenceDetails,
        isValidAccountNo: initialState.isValidAccountNo,
        isValidJournalPrefix: initialState.isValidJournalPrefix,
      });
    case GET_INIT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        ledgerAccounts: payload?.data?.ledgerAccounts,
        remitSequenceDetails: payload?.data?.remitSequenceData,
        ledgerSequenceDetails: payload?.data?.ledgerSequenceData,
      });
    case GET_INIT_DETAILS_FAIL:
      return Object.assign({}, state, {
        ledgerAccounts: initialState.ledgerAccounts,
        remitSequenceDetails: initialState.remitSequenceDetails,
        ledgerSequenceDetails: initialState.ledgerSequenceDetails,
      });
    case ADD_CLIENT_NEW_ACCOUNT:
      return Object.assign({}, state, {
        accountSaveUpdate: {
          isLoading: true,
          data: {},
        },
      });
    case ADD_CLIENT_NEW_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        accountSaveUpdate: {
          isLoading: false,
          data: payload?.data,
        },
      });
    case ADD_CLIENT_NEW_ACCOUNT_FAIL:
      return Object.assign({}, state, {
        accountSaveUpdate: {
          isLoading: false,
          data: {},
        },
      });
    case UPDATE_CLIENT_ACCOUNT:
      return Object.assign({}, state, {
        accountSaveUpdate: {
          isLoading: true,
          data: {},
        },
      });
    case UPDATE_CLIENT_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        accountSaveUpdate: {
          isLoading: false,
          data: payload?.data,
        },
      });
    case UPDATE_CLIENT_ACCOUNT_FAIL:
      return Object.assign({}, state, {
        accountSaveUpdate: {
          isLoading: false,
          data: {},
        },
      });
    case VALIDATE_ACCOUNT_NUMBER:
      return Object.assign({}, state, {
        isValidAccountNo: true,
      });
    case VALIDATE_ACCOUNT_NUMBER_SUCCESS:
      return Object.assign({}, state, {
        isValidAccountNo: payload?.data,
      });
    case VALIDATE_ACCOUNT_NUMBER_FAIL:
      return Object.assign({}, state, {
        isValidAccountNo: false,
      });
    case RESET_ACCOUNT_VALIDATION:
      return Object.assign({}, state, {
        isValidAccountNo: true,
      });
    case VALIDATE_JOURNAL_PREFIX:
      return Object.assign({}, state, {
        isValidJournalPrefix: true,
      });
    case VALIDATE_JOURNAL_PREFIX_SUCCESS:
      return Object.assign({}, state, {
        isValidJournalPrefix: payload?.data,
      });
    case VALIDATE_JOURNAL_PREFIX_FAIL:
      return Object.assign({}, state, {
        isValidJournalPrefix: false,
      });
    case RESET_JOURNAL_PREFIX_VALIDATION:
      return Object.assign({}, state, {
        isValidJournalPrefix: true,
      });
    case DELETE_CLIENT_ACCOUNT:
      return Object.assign({}, state, {
        isLoadingClientAccounts: true,
      });
    case DELETE_CLIENT_ACCOUNT_SUCCESS:
      return Object.assign({}, state, {
        isLoadingClientAccounts: false,
      });
    case DELETE_CLIENT_ACCOUNT_FAIL:
      return Object.assign({}, state, {
        isLoadingClientAccounts: false,
      });
    case RESET_SAVE_UPDATE_DATA:
      return Object.assign({}, state, {
        accountSaveUpdate: {
          isLoading: false,
          data: {},
        },
      });
    default:
      return state;
  }
};
