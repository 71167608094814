import { parseInt } from "lodash";
import { RemitStatus } from "us.collection.economy/components/Remit/Components/CreditorSelector/Constants";
import {
  ICreditor,
  ICreditorGroup,
} from "us.collection.economy/interfaces/Remit/IRemitProps";

/**
 * @method
 * @description Search creditors for remit creditor selection
 * @param creditor - Object of data to search
 * @param {string} value - Search value
 * @returns boolean
 */
export const searchContent = (creditor: ICreditor, value: string): boolean => {
  try {
    return Object.keys(creditor).some(() =>
      String(creditor["name"] + creditor["creditorNo"] + creditor["groupName"])
        ?.toLowerCase()
        ?.includes(value?.toLowerCase())
    );
  } catch (e) {
    return true;
  }
};

/**
 * @method
 * @description Returns Array of creditor numbers for creditor selection
 * @param creditors - Array of creditors
 * @returns Array of creditor numbers
 */
export const getCreditorNumbers = (creditors: ICreditor[]): Array<string> => {
  try {
    return creditors.flatMap(({ creditorNo, remitStatus }: ICreditor) =>
      remitStatus === RemitStatus.ELIGIBLE ? creditorNo : []
    );
  } catch (error) {
    return [];
  }
};

/**
 * @method
 * @description Returns Array of  eligible and ineligible creditors for redux state
 * @param creditors - Array of existing creditors
 * @param payload - API response
 * @returns Object that contains creditors and ineligible creditors
 */
export const getCreditors = (
  creditors: Array<ICreditor>,
  selectedCreditors: Array<string> = [],
  payload?: any
): {
  data: Array<ICreditor>;
  backupData: Array<ICreditor>;
  selected: Array<string>;
  ineligible: Array<ICreditor>;
} => {
  try {
    const { data, groupId, isRefresh } = payload ?? {};
    const newData: ICreditor[] = isRefresh
      ? creditors.filter((creditor: ICreditor) => creditor.groupId !== groupId)
      : creditors;

    // merge all creditors into a single array
    const mergedData: ICreditor[] = data ? [...newData, ...data] : newData;

    // sort creditors by creditor no
    const sortedCreditorsByCreditorNo: ICreditor[] = mergedData.sort(function (
      a: ICreditor,
      b: ICreditor
    ) {
      return parseInt(a.creditorNo) - parseInt(b.creditorNo);
    });

    // find ineligible Creditors
    const ineligibleCreditors = sortedCreditorsByCreditorNo.filter(
      ({ remitStatus }: { remitStatus: string }) =>
        remitStatus !== RemitStatus.ELIGIBLE
    );

    return {
      data: sortedCreditorsByCreditorNo,
      backupData: sortedCreditorsByCreditorNo,
      selected: isRefresh
        ? selectedCreditors
        : [...selectedCreditors, ...getCreditorNumbers(data)],
      ineligible: ineligibleCreditors,
    };
  } catch (e) {
    return { data: [], backupData: [], selected: [], ineligible: [] };
  }
};

/**
 * @method
 * @description Returns creditor related object after resetting the selection
 * @param creditors - Array of existing creditors
 * @param payload - Selected groups
 * @returns Object that contains creditor data
 */
export const resetSelection = (
  creditors: Array<ICreditor>,
  payload?: any
): {
  data: Array<ICreditor>;
  backupData: Array<ICreditor>;
  selected: Array<string>;
  ineligible: Array<ICreditor>;
} => {
  try {
    const { selectedGroups } = payload ?? {};

    return selectedGroups
      ? {
          ...getCreditors(
            creditors.filter(({ groupId }: { groupId: number }) =>
              selectedGroups.includes(groupId)
            )
          ),
          selected: payload?.creditors,
        }
      : { data: [], backupData: [], selected: [], ineligible: [] };
  } catch (e) {
    return { data: [], backupData: [], selected: [], ineligible: [] };
  }
};

/**
 * @method
 * @description Remove creditors from redux state
 * @param creditors - Array of existing creditors
 * @param selectedGroupId - Group id that needs to remove
 * @returns Object that contains creditors and ineligible creditors
 */
export const removeLocalCreditors = (
  creditors: Array<ICreditor>,
  selectedCreditors: Array<string>,
  selectedGroupId: number
): {
  data: Array<ICreditor>;
  selected: Array<string>;
  ineligible: Array<ICreditor>;
} => {
  try {
    const restCreditors = creditors?.filter(
      ({ groupId }: ICreditor) => groupId !== selectedGroupId
    );
    return {
      data: restCreditors,
      selected: selectedCreditors?.filter((creditorNo: string) =>
        getCreditorNumbers(restCreditors)?.includes(creditorNo)
      ),
      ineligible: restCreditors?.filter(
        ({ remitStatus }: ICreditor) => remitStatus !== RemitStatus.ELIGIBLE
      ),
    };
  } catch (e) {
    return { data: [], selected: [], ineligible: [] };
  }
};

/**
 * @method
 * @param isVisibleIneligible - Whether the ineligible data is visible or not
 * @param creditors - All creditors
 * @param filterTable - Filtered creditors
 * @param ineligibleCreditors - Ineligible creditors
 * @returns Array of creditors
 */
export const getTableData = (
  isVisibleIneligible: boolean,
  creditors: Array<ICreditor>,
  filterTable: Array<ICreditor> | null,
  ineligibleCreditors: Array<ICreditor>
): Array<ICreditor> => {
  try {
    return !isVisibleIneligible
      ? filterTable == null
        ? creditors
        : filterTable
      : filterTable == null
      ? ineligibleCreditors
      : filterTable;
  } catch (e) {
    return [];
  }
};

/**
 * @method
 * @description Returns validation message
 * @param errorStatus - Comma separated status
 * @returns Validation message
 */
export const getValidationMessage = (errorStatus: string): string => {
  try {
    const status = errorStatus?.replace(/\s/g, "")?.split(",");
    if (status?.includes("1")) {
      return "US.COLLECTION.ECONOMY:REMIT.ADDRESS_NOT_FOUND";
    } else if (status?.includes("2")) {
      return "US.COLLECTION.ECONOMY:REMIT.REMIT_ACCOUNT_NUMBER_NOT_FOUND";
    } else if (status?.includes("3")) {
      return "US.COLLECTION.ECONOMY:REMIT.MORE_THAN_ONE_ADDRESS_FOUND";
    } else if (status?.includes("1") && status?.includes("2")) {
      return "US.COLLECTION.ECONOMY:REMIT.ACCOUNT_NUMBER_AND_ADDRESS_NOT_FOUND";
    } else {
      return "US.COLLECTION.ECONOMY:REMIT.MISSING_INFORMATION";
    }
  } catch (error) {
    return "US.COLLECTION.ECONOMY:REMIT.MISSING_INFORMATION";
  }
};

/**
 * @method
 * @description Returns Array of creditor group names for creditor table column filter
 * @param creditorGroups - Array of creditors groups
 * @returns Formatted array of creditor group names
 */
export const getColumnCreditorGroups = (
  creditorGroups: ICreditorGroup[],
  selectedGroupIds: any[]
): Array<any> => {
  try {
    return creditorGroups.flatMap(
      ({ groupId, groupName }: { groupId: number; groupName: string }) => {
        if (selectedGroupIds.includes(groupId)) {
          return { text: groupName, value: groupName };
        } else {
          return [];
        }
      }
    );
  } catch (error) {
    return [];
  }
};
