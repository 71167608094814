import { TableColumn } from "us.collection.economy/components/LedgerAccounts/Constants";
import { ColumnFilterTypes } from "us.common/components/antd/TableTree/Constants";

/**
 * @description Get column search input field current value by key
 * @param key - input field name
 * @returns value - Current value of the input field
 */
export const getColumnFilterProps = (key: TableColumn) => {
  switch (key) {
    case TableColumn.MENU:
      return {};
    case TableColumn.LAST_UPDATE:
      return { customFilter: ColumnFilterTypes.DATE_TIME, showFilters: true };
    default:
      return { customFilter: true, showFilters: true };
  }
};
