export enum ManualOrders {
    GET_ARTICLE_BY_NO = 'GET_ARTICLE_BY_NO',
    GET_ARTICLE_BY_NO_SUCCESS = 'GET_ARTICLE_BY_NO_SUCCESS',
    GET_ARTICLE_BY_NO_FAIL = 'GET_ARTICLE_BY_NO_FAIL',

    GET_ARTICLE_LIST = 'GET_ARTICLE_LIST',
    GET_ARTICLE_LIST_SUCCESS = 'GET_ARTICLE_LIST_SUCCESS',
    GET_ARTICLE_LIST_FAIL = 'GET_ARTICLE_LIST_FAIL',
    FILTER_ARTICLE_LIST = 'FILTER_ARTICLE_LIST',

    CHANGE_ARTICLE_DRAWER = 'CHANGE_ARTICLE_DRAWER',

    SAVE_ORDER_LINE = 'SAVE_ORDER_LINE',
    SAVE_ORDER_LINE_SUCCESS = 'SAVE_ORDER_LINE_SUCCESS',
    SAVE_ORDER_LINE_FAIL = 'SAVE_ORDER_LINE_FAIL',

    VALIDATE_CASE_NO = 'VALIDATE_CASE_NO',
    VALIDATE_CASE_NO_SUCCESS = 'VALIDATE_CASE_NO_SUCCESS',
    VALIDATE_CASE_NO_FAIL = 'VALIDATE_CASE_NO_FAIL',

    EXPORT_ORDER_LINE = 'EXPORT_ORDER_LINE',
    EXPORT_ORDER_LINE_SUCCESS = 'EXPORT_ORDER_LINE_SUCCESS',
    EXPORT_ORDER_LINE_FAIL = 'EXPORT_ORDER_LINE_FAIL',

    RESET_SHOW_MODAL_STATUS = 'RESET_SHOW_MODAL_STATUS',
    RESET_ORDER_DATA = 'RESET_ORDER_DATA',

    UPDATE_ORDER_TABLE_DATA = 'UPDATE_ORDER_TABLE_DATA',
    UPDATE_ORDER_TABLE_DATA_SUCCESS = 'UPDATE_ORDER_TABLE_DATA_SUCCESS',
    UPDATE_ORDER_TABLE_DATA_FAIL = 'UPDATE_ORDER_TABLE_DATA_FAIL',

    FILTER_TABLE_DATA = 'FILTER_TABLE_DATA',
    FILTER_TABLE_DATA_SUCCESS = 'FILTER_TABLE_DATA_SUCCESS',
    FILTER_TABLE_DATA_FAIL = 'FILTER_TABLE_DATA_FAIL',

    VALIDATE_CREDITOR_BY_ID = 'VALIDATE_CREDITOR_BY_ID',
    VALIDATE_CREDITOR_BY_ID_SUCCESS = 'VALIDATE_CREDITOR_BY_ID_SUCCESS',
    VALIDATE_CREDITOR_BY_ID_FAIL = 'VALIDATE_CREDITOR_BY_ID_FAIL',

    UPDATE_SELECTED_CREDITOR_DATA = 'UPDATE_SELECTED_CREDITOR_DATA',
    UPDATE_SELECTED_CREDITOR_DATA_SUCCESS = 'UPDATE_SELECTED_CREDITOR_DATA_SUCCESS',
    UPDATE_SELECTED_CREDITOR_DATA_FAIL = 'UPDATE_SELECTED_CREDITOR_DATA_FAIL',

    GET_NEXT_ORDER_ID = 'GET_NEXT_ORDER_ID',
    GET_NEXT_ORDER_ID_SUCCESS = 'GET_NEXT_ORDER_ID_SUCCESS',
    GET_NEXT_ORDER_ID_FAIL = 'GET_NEXT_ORDER_ID_FAIL',

    GET_ORDER_DETAILS = 'GET_ORDER_DETAILS',
    GET_ORDER_DETAILS_SUCCESS = 'GET_ORDER_DETAILS_SUCCESS',
    GET_ORDER_DETAILS_FAIL = 'GET_ORDER_DETAILS_FAIL',

    UPDATE_ORDER_LINE = 'UPDATE_ORDER_LINE',
    UPDATE_ORDER_LINE_SUCCESS = 'UPDATE_ORDER_LINE_SUCCESS',
    UPDATE_ORDER_LINE_FAIL = 'UPDATE_ORDER_LINE_FAIL',

    GET_VAT_AMOUNTS = 'GET_VAT_AMOUNTS',
    GET_VAT_AMOUNTS_SUCCESS = 'GET_VAT_AMOUNTS_SUCCESS',
    GET_VAT_AMOUNTS_FAIL = 'GET_VAT_AMOUNTS_FAIL',

    DELETE_ORDER_LINE= 'DELETE_ORDER_LINE',
    DELETE_ORDER_LINE_SUCCESS = 'DELETE_ORDER_LINE_SUCCESS',
    DELETE_ORDER_LINE_FAILED = 'DELETE_ORDER_LINE_FAILED',

    UPDATE_ORDER_TYPE= 'UPDATE_ORDER_TYPE',
    UPDATE_ORDER_TYPE_SUCCESS = 'UPDATE_ORDER_TYPE_SUCCESS',
    UPDATE_ORDER_TYPE_FAILED = 'UPDATE_ORDER_TYPE_FAILED'

}