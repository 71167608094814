export enum RemitHistoryTableColumn {
  JOURNAL_ID = "journalId",
  FILE_DATE = "fileDate",
  BUREAU_AMOUNT = "bureauAmount",
  CREDITOR_AMOUNT = "creditorAmount",
  DEBTOR_AMOUNT = "debtorAmount",
  TOTAL_AMOUNT = "totalAmount",
}

export enum RemitAction {
  RE_CREATE = "RE_CREATE",
  VIEW_LEDGER_DASHBOARD = "VIEW_LEDGER_DASHBOARD",
  REVERSE = "REVERSE",
  DOWNLOAD = "DOWNLOAD",
}

export enum ColumnSearchType {
  AMOUNT = "amount",
  DATE = "date",
  ACCOUNT_NO = "accountNo",
  STRING = "string",
}

export const COLUMNS = [
  {
    id: 0,
    key: RemitHistoryTableColumn.JOURNAL_ID,
    title: "JOURNAL_ID",
  },
  {
    id: 1,
    key: RemitHistoryTableColumn.FILE_DATE,
    title: "FILE_DATE",
  },
  {
    id: 2,
    key: RemitHistoryTableColumn.BUREAU_AMOUNT,
    title: "BUREAU_AMOUNT",
  },
  {
    id: 3,
    key: RemitHistoryTableColumn.CREDITOR_AMOUNT,
    title: "CREDITOR_AMOUNT",
  },
  {
    id: 4,
    key: RemitHistoryTableColumn.DEBTOR_AMOUNT,
    title: "DEBTOR_AMOUNT",
  },
  {
    id: 5,
    key: RemitHistoryTableColumn.TOTAL_AMOUNT,
    title: "TOTAL_AMOUNT",
  },
];

export const DEFAULT_COLUMN_FILTERS = {
  journalId: "",
  fileDate: "",
  totalAmount: undefined,
  bureauAmount: undefined,
  debtorAmount: undefined,
  creditorAmount: undefined,
  remitPeriod: "",
  sourceBankAccountNo: "",
  remitVoucherNo: "",
};

export const DEFAULT_TABLE_INFO = {
  filters: {},
  sorter: { columnKey: "", order: "" },
  data: [],
};
