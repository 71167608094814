import { IRemitHistoryActions } from "us.collection.economy/interfaces";
import { RemitHistory } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

const {
  GET_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_FAIL,
  INIT_REMIT_HISTORY,

  SEARCH_REMIT_HISTORY,
  SEARCH_REMIT_HISTORY_SUCCESS,
  SEARCH_REMIT_HISTORY_FAIL,

  DOWNLOAD_REMIT_FILE,
  DOWNLOAD_REMIT_FILE_SUCCESS,
  DOWNLOAD_REMIT_FILE_FAIL,
  REVERSE_REMIT,
  REVERSE_REMIT_SUCCESS,
  REVERSE_REMIT_FAIL,

  GET_LEDGER_DETAILS = "GET_LEDGER_DETAILS",
  GET_LEDGER_DETAILS_SUCCESS = "GET_LEDGER_DETAILS_SUCCESS",
  GET_LEDGER_DETAILS_FAIL = "GET_LEDGER_DETAILS_FAIL",
} = RemitHistory;

export const remitHistory: Readonly<IAPIAction & IRemitHistoryActions> = {
  bankAccounts: {
    get: (data) => ({
      type: GET_BANK_ACCOUNTS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_BANK_ACCOUNTS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_BANK_ACCOUNTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remits: {
    init: (data) => ({
      type: INIT_REMIT_HISTORY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    search: (data) => ({
      type: SEARCH_REMIT_HISTORY,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEARCH_REMIT_HISTORY_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEARCH_REMIT_HISTORY_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remitFile: {
    download: (data) => ({
      type: DOWNLOAD_REMIT_FILE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DOWNLOAD_REMIT_FILE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DOWNLOAD_REMIT_FILE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  remit: {
    delete: (data) => ({
      type: REVERSE_REMIT,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: REVERSE_REMIT_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: REVERSE_REMIT_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  ledgerDetails: {
    get: (data) => ({
      type: GET_LEDGER_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_LEDGER_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_LEDGER_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
