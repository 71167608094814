import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";

import * as Actions from "us.collection.economy/actions";
import { IconTypes, BoxIcons } from "us.icons";
import { IDashboard, IRecord } from "../../Interfaces";
import { AccountItem } from "./Components";
import { getExportData, getCarryingForwardBalances } from "../../Functions";

import {
  $Button,
  $Skeleton,
  $AmountLabel,
  $Divider,
} from "us.common/components";

const Dashboard: React.FC<PropsFromRedux & IDashboard> = (props) => {
  const { t } = useTranslation();

  const {
    journalId,
    accountsDetails,
    isDetailsLoading,
    currentCurrency,
    currentLanguage,
    getLedgerDetails,
    onClose,
  } = props;

  const { accounts } = accountsDetails ?? {};
  const { creditBalance, debitBalance } = getCarryingForwardBalances(accounts);

  useEffect(() => {
    getLedgerDetails && getLedgerDetails({ journalId });
  }, []);

  return (
    <>
      <$Skeleton loading={isDetailsLoading} active paragraph={{ rows: 3 }}>
        <div>
          <div className="page-header header-border">
            <span className="text-muted font-sm mb-3">
              {t(
                "US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.CARRYING_FORWARD_BALANCES"
              )}
            </span>
            <div className="d-flex align-items-center justify-content-between pb-2">
              <div className="d-flex align-items-center">
                <div className="d-flex flex-column mr-4">
                  <div
                    className="font-weight-bold"
                    data-testid="total-credited-amount"
                  >
                    <$AmountLabel value={creditBalance} />
                  </div>
                  <div>
                    {t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.CREDIT_BALANCE")}
                  </div>
                </div>
                <$Divider className="bui-devider" type="vertical" />
                <div className="d-flex flex-column">
                  <div
                    className="font-weight-bold"
                    data-testid="total-debited-amount"
                  >
                    <$AmountLabel value={debitBalance} />
                  </div>
                  <div>
                    {t("US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.DEBIT_BALANCE")}
                  </div>
                </div>
              </div>
              <div>
                <div data-testid="csv-link">
                  <$Button
                    type="default"
                    size="small"
                    icon={
                      <BoxIcons
                        className="mr-1"
                        type={IconTypes.BOX_ICON}
                        name="excel"
                      />
                    }
                  >
                    <CSVLink
                      separator={currentCurrency == "NOK" ? ";" : ","}
                      filename={`${journalId}.csv`}
                      data={getExportData(
                        accounts,
                        currentLanguage,
                        currentCurrency
                      )}
                    >
                      {t(
                        "US.COLLECTION.ECONOMY:LEDGER_DASHBOARD.EXPORT_TO_EXCEL"
                      )}
                    </CSVLink>
                  </$Button>
                </div>
              </div>
            </div>
          </div>
          <div className="cardledger-wrap-outer">
            {accounts?.map((record: IRecord) => (
              <AccountItem record={record} key={record.accountId} />
            ))}
          </div>
        </div>
      </$Skeleton>
      <div className="drawer-footer-fixed align-items-end justify-content-end">
        <div>
          <$Button onClick={onClose} data-testid="cancel-dashboard">
            {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
          </$Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { common, remitHistory } = state;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  const { accountsDetails, isDetailsLoading } = remitHistory;

  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    accountsDetails,
    isDetailsLoading,
  };
};

const { ledgerDetails } = Actions.remitHistory;

const mapDispatchToProps = {
  getLedgerDetails: ledgerDetails.get,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Dashboard);
