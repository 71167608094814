export enum RegisterJournal {
  SEARCH_LEDGER_ACCOUNTS = "SEARCH_LEDGER_ACCOUNTS",
  SEARCH_LEDGER_ACCOUNTS_SUCCESS = "SEARCH_LEDGER_ACCOUNTS_SUCCESS",
  SEARCH_LEDGER_ACCOUNTS_FAILED = "SEARCH_LEDGER_ACCOUNTS_FAILED",

  SEARCH_REMIT_HISTORY_DETAILS = "SEARCH_REMIT_HISTORY_DETAILS",
  SEARCH_REMIT_HISTORY_DETAILS_SUCCESS = "SEARCH_REMIT_HISTORY_DETAILS_SUCCESS",
  SEARCH_REMIT_HISTORY_DETAILS_FAILED = "SEARCH_REMIT_HISTORY_DETAILS_FAILED",
  RESET_REMIT_HISTORY_DETAILS="RESET_REMIT_HISTORY_DETAILS",

  VALIDATE_JOURNAL_ID = "VALIDATE_JOURNAL_ID",
  VALIDATE_JOURNAL_ID_SUCCESS = "VALIDATE_JOURNAL_ID_SUCCESS",
  VALIDATE_JOURNAL_ID_FAILED = "VALIDATE_JOURNAL_ID_FAILED",

  VALIDATE_CASE_ID = "VALIDATE_CASE_ID",
  VALIDATE_CASE_ID_SUCCESS = "VALIDATE_CASE_ID_SUCCESS",
  VALIDATE_CASE_ID_FAILED = "VALIDATE_CASE_ID_FAILED",

  RESET_JOURNAL_VALIDATION = "RESET_JOURNAL_VALIDATION",
  REGISTER_JOURNAL_ENTRY = "REGISTER_JOURNAL_ENTRY",
  REGISTER_JOURNAL_ENTRY_SUCCESS = "REGISTER_JOURNAL_ENTRY_SUCCESS",
  REGISTER_JOURNAL_ENTRY_FAILED = "REGISTER_JOURNAL_ENTRY_FAILED",
}
