import React from "react";
import { ActionTypes } from "us.collection.economy/components/ManagePayments/interface";
import { ActionsBar } from "../ActionsBar";
import { LabelsBar } from "../LabelsBar";

interface Props {
  actions: ActionTypes[];
  onAction: (actionType: ActionTypes) => void;
  amount?: number | undefined;
  label: string | JSX.Element;
}

const SubHeader: React.FC<Props> = ({ actions, amount, label, onAction }) => {
  return (
    <div className="map-item-table pr-2">
      <div className="map-item-table-row">
        {actions.length > 0 && (
          <div className="map-item-table-col" style={{ width: 90 }}>
            <ActionsBar actions={actions} onAction={onAction} />
          </div>
        )}
        <div className="map-item-table-col">
          <LabelsBar label={label} />
        </div>
        <div className="map-item-table-col text-right font-weight-bold">
          <LabelsBar amount={amount} />
        </div>
      </div>
    </div>
  );
};

export default SubHeader;
