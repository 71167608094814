import { Drawer } from "us.collection.economy/components/Remit/Interfaces";

export enum DrawerType {
  REMIT_DETAILS = 1,
  REMIT_VALIDATION_ERRORS = 2,
  SELECT_CREDITOR = 3,
}
export const DRAWER_DEFAULT_STATE: Drawer = {
  title: "",
  visible: false,
  width: "80%",
  type: DrawerType.REMIT_DETAILS,
};

export const REMIT_DEFAULT_FORM_VALUES = {
  remitAccount: "",
  ignoreExceedPayments: false,
  creditors: [],
  creditorGroups: undefined,
};

export enum RemitProposalAction {
  VIEW_REMIT_DETAILS = "VIEW_REMIT_DETAILS",
  INVOICE = "INVOICE",
  RESET_INVOICE = "RESET_INVOICE",
}

export const ITEM_MENU_DEFAULT_STATE = {
  visible: false,
  id: "",
};

export enum RemitValidationState {
  VALIDATION = "VALIDATION",
  VALIDATION_FAIL = "VALIDATION_FAIL",
  VALIDATION_SUCCESS = "VALIDATION_SUCCESS",
}
