import * as Actions from "./actions"
import * as API  from "./services"
import * as Components from "./components"
import * as Layouts from "./layouts"
import * as Containers from "./containers"
import * as Constants from "./constants"
import * as Reducers from "./reducers"
import * as Routes from "./routes"
import * as Sagas from "./sagas"


export default {
    Actions,
    API,
    Components,
    Layouts,
    Constants,
    Containers,
    Reducers,
    Routes,
    Sagas
}