export enum ClientInvoices {
  RESET_INVOICE_LIST = "RESET_INVOICE_LIST",

  SEARCH_CLIENT_INVOICES = "SEARCH_CLIENT_INVOICES",
  SEARCH_CLIENT_INVOICES_SUCCESS = "SEARCH_CLIENT_INVOICES_SUCCESS",
  SEARCH_CLIENT_INVOICES_FAILED = "SEARCH_CLIENT_INVOICES_FAILED",

  DOWNLOAD_INVOICES = "DOWNLOAD_INVOICES",
  DOWNLOAD_INVOICES_SUCCESS = "DOWNLOAD_INVOICES_SUCCESS",
  DOWNLOAD_INVOICES_FAILED = "DOWNLOAD_INVOICES_FAILED",

  EXPORT_INVOICES = "EXPORT_INVOICES",
  EXPORT_INVOICES_SUCCESS = "EXPORT_INVOICES_SUCCESS",
  EXPORT_INVOICES_FAILED = "EXPORT_INVOICES_FAILED",

  SORT_INVOICES = "SORT_INVOICES",
  FILTER_INVOICES = "FILTER_INVOICES",
  ON_TAB_CHANGED = "ON_TAB_CHANGED",

  GET_CREDITOR_INVOICE_DETAILS_START = "GET_CREDITOR_INVOICE_DETAILS_START",
  GET_CREDITOR_INVOICE_DETAILS_SUCCESS = "GET_CREDITOR_INVOICE_DETAILS_SUCCESS",
  GET_CREDITOR_INVOICE_DETAILS_FAIL = "GET_CREDITOR_INVOICE_DETAILS_FAIL",
  RESET_CREDITOR_INVOICE_DETAILS = "RESET_CREDITOR_INVOICE_DETAILS",
  SET_DRAWER_VISIBLE_STATUS = "SET_DRAWER_VISIBLE_STATUS",

  SAVE_CREDIT_NOTE_START = "SAVE_CREDIT_NOTE_START",
  SAVE_CREDIT_NOTE_SUCCESS = "SAVE_CREDIT_NOTE_SUCCESS",
  SAVE_CREDIT_NOTE_FAIL = "SAVE_CREDIT_NOTE_FAIL",

  EXPORT_CREDIT_INVOICE = "EXPORT_CREDIT_INVOICE",
  EXPORT_CREDIT_INVOICE_SUCCESS = "EXPORT_CREDIT_INVOICE_SUCCESS",
  EXPORT_CREDIT_INVOICE_FAIL = "EXPORT_CREDIT_INVOICE_FAIL",

  DOWNLOAD_NAVISION_FILE = "DOWNLOAD_NAVISION_FILE",
  DOWNLOAD_NAVISION_FILE_SUCCESS = "DOWNLOAD_NAVISION_FILE_SUCCESS",
  DOWNLOAD_NAVISION_FILE_FAIL = "DOWNLOAD_NAVISION_FILE_FAIL",

  REFRESH_CLIENT_INVOICES = "REFRESH_CLIENT_INVOICES",
  REFRESH_CLIENT_INVOICES_SUCCESS = "REFRESH_CLIENT_INVOICES_SUCCESS",
  REFRESH_CLIENT_INVOICES_FAILED = "REFRESH_CLIENT_INVOICES_FAILED",
}
