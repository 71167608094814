import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { useHistory } from "react-router-dom";
import { RootState } from "us.helper/types";

const { $Divider, $AmountLabel, $DateLabel } = Common.Components;

/**
 * @description - Creditor Invoice Details View
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2728034451/Creditor+Invoicing+%3A+Invoice+Detail+View
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 25/08/2022
 */
const creditInvoice: React.FC<any & PropsFromRedux> = ({ creditNotes }) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  return (
    <div className="credi-inv-wrap" data-testid="invoice-data-view">
      <div className="d-flex flex-column">
        {creditNotes?.map((note: any) => (
          <div className="credi-nt-list-item mb-2 d-flex" key={note.invoiceId}>
            <div>
              <div className="font-weight-bold" data-testid="reg-date">
                <$DateLabel value={note?.regdate} />
              </div>
              <div
                className="text-muted font-sm"
                data-testid="invoice-details-no"
              >
                {t("US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.NO")}:{" "}
                <a
                  onClick={() => {
                    push(
                      `/creditor-invoice-details?invoiceNo=${note?.invoiceNo}`
                    );
                  }}
                >
                  {note?.invoiceNo}
                </a>
              </div>
            </div>
            <$Divider style={{ height: "40px" }} type="vertical" />
            <div className="flex-fill text-right">
              <div
                className="font-weight-bold"
                data-testid="invoice-note-amount"
              >
                <$AmountLabel value={note?.amount} />
              </div>
              <div
                className="text-muted font-sm"
                data-testid="invoice-note-amount-lable"
              >
                {t(
                  "US.COLLECTION.ECONOMY:CREDITOR_INVOICE_DETAILS.CREDIT_NOTE_AMOUNT"
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { creditorInvoiceDetails } = state;
  const { list } = creditorInvoiceDetails;
  const { creditNotes } = list;
  return {
    creditNotes,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(creditInvoice);
