import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { LedgerAccount } from "us.collection.economy/constants/Actions";
import { $Message, $MessageBox } from "us.common/components";
import _, { isArray } from "lodash";
import { i18n } from "us.helper";

const { ledgerAccounts } = Actions;
const { accounts, newAccount, existingAccount, ledgerAccount, validateField } =
  ledgerAccounts;

const {
  getAccountsData,
  saveNewLedgerAccount,
  updateLedgerAccount,
  deleteLedgerAccount,
  validateAccountNo,
} = API.ledgerAccounts;

const LedgerAccountSagas = {
  ledgerAccounts: {
    getLedgerAccountsData: function* (action: any) {
      const { searchText } = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(getAccountsData, searchText);
        if (status === 200 && Array.isArray(data)) {
          yield put(accounts.success(data));
        } else {
          yield put(accounts.fail(new Error()));
        }
      } catch (error) {
        yield put(accounts.fail(new Error()));
      }
    },
    addNewLedgerAccount: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { result, status, accountsList } = yield call(
          saveNewLedgerAccount,
          params
        );
        if (status === 200 || status === 201) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.SAVE_LEDGER_ACCOUNT_SUCCESS",
            "",
            ""
          );
          yield put(newAccount.success({ result }));

          if (Array.isArray(accountsList)) {
            yield put(accounts.success(accountsList));
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.SAVE_LEDGER_ACCOUNT_FAILED",
            "",
            ""
          );
          yield put(newAccount.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.SAVE_LEDGER_ACCOUNT_FAILED",
          "",
          ""
        );
        yield put(newAccount.fail(new Error()));
      }
    },
    editLedgerAccount: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { result, status, accountsList } = yield call(
          updateLedgerAccount,
          params
        );
        if (status === 200 || status === 201) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.SAVE_LEDGER_ACCOUNT_SUCCESS",
            "",
            ""
          );
          yield put(existingAccount.success({ result }));

          if (Array.isArray(accountsList)) {
            yield put(accounts.success(accountsList));
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.SAVE_LEDGER_ACCOUNT_FAILED",
            "",
            ""
          );
          yield put(existingAccount.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.SAVE_LEDGER_ACCOUNT_FAILED",
          "",
          ""
        );
        yield put(existingAccount.fail(new Error()));
      }
    },
    validateAccountNoField: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(validateAccountNo, params);

        if (!_.isEmpty(data) && status === 200) {
          yield put(validateField.success(data));
        } else {
          yield put(validateField.fail(new Error()));
        }
      } catch (error) {
        yield put(validateField.fail(new Error()));
      }
    },
    deleteLedgerAccount: function* (action: any) {
      const params = action?.payload?.data ?? {};
      $Message.loading(i18n.t("COMMON.DELETING"), 0);
      try {
        const { deleteData, accountsList, status } = yield call(
          deleteLedgerAccount,
          params
        );
        $Message.destroy();

        if (status === 200) {
          if (deleteData?.code == 1 && deleteData?.status) {
            $MessageBox(
              "success",
              "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.DELETE_LEDGER_ACCOUNT_SUCCESS",
              "",
              ""
            );
            yield put(ledgerAccount.success(deleteData));
            if (accountsList && isArray(accountsList)) {
              yield put(accounts.success(accountsList));
            }
          } else if (deleteData?.code == 2 && !deleteData?.status) {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.LEDGER_ACCOUNT_IS_IN_USE",
              "",
              ""
            );
            yield put(ledgerAccount.fail(new Error()));
          } else if (deleteData?.code == 3 && !deleteData?.status) {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.ACCOUNT_IS_SYSTEM_ACCOUNT",
              "",
              ""
            );
            yield put(ledgerAccount.fail(new Error()));
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.DELETE_LEDGER_ACCOUNT_FAILED",
              "",
              ""
            );
            yield put(ledgerAccount.fail(new Error()));
          }
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.DELETE_LEDGER_ACCOUNT_FAILED",
            "",
            ""
          );
          yield put(ledgerAccount.fail(new Error()));
        }
      } catch (error) {
        $Message.destroy();
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.DELETE_LEDGER_ACCOUNT_FAILED",
          "",
          ""
        );
        yield put(ledgerAccount.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(
    LedgerAccount.GET_LEDGER_ACCOUNTS_DATA,
    LedgerAccountSagas.ledgerAccounts.getLedgerAccountsData
  ),
  takeLatest(
    LedgerAccount.ADD_NEW_LEDGER_ACCOUNT,
    LedgerAccountSagas.ledgerAccounts.addNewLedgerAccount
  ),
  takeLatest(
    LedgerAccount.EDIT_LEDGER_ACCOUNT,
    LedgerAccountSagas.ledgerAccounts.editLedgerAccount
  ),
  takeLatest(
    LedgerAccount.DELETE_LEDGER_ACCOUNT,
    LedgerAccountSagas.ledgerAccounts.deleteLedgerAccount
  ),
  takeLatest(
    LedgerAccount.DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD,
    LedgerAccountSagas.ledgerAccounts.validateAccountNoField
  ),
];
