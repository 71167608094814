import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { $MessageBox } from "us.common/components";
import { VatCode } from "us.collection.economy/constants/Actions";

const { vatCode } = Actions;
const {
  getVatCodeList,
  getLedgerAccounts,
  validateVatCode,
  addNewVatCode,
  editVatCode,
  deleteVatCode,
} = API.vatCode;
const {
  vatCodeList,
  ledgerAccounts,
  newVatCode,
  addedVatCode,
  existingVatCode,
  vatCodeDetails,
} = vatCode;
const {
  GET_VAT_CODE_LIST,
  GET_LEDGER_ACCOUNTS,
  VALIDATE_VAT_CODE,
  SAVE_VAT_CODE,
  UPDATE_VAT_CODE,
  DELETE_VAT_CODE,
} = VatCode;

export const VatCodeSaga = {
  vatCode: {
    getVatCodeList: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(getVatCodeList, params);
        if (status == 200) {
          yield put(vatCodeList.success(data));
        } else {
          yield put(vatCodeList.fail(new Error()));
        }
      } catch (error) {
        yield put(vatCodeList.fail(new Error()));
      }
    },
    getLedgerAccountList: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(getLedgerAccounts, params);
        if (status == 200) {
          yield put(ledgerAccounts.success(data));
        } else {
          yield put(ledgerAccounts.fail(new Error()));
        }
      } catch (error) {
        yield put(ledgerAccounts.fail(new Error()));
      }
    },
    validateVatCode: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(validateVatCode, params);
        yield put(addedVatCode.success(data?.status));
      } catch (error) {
        yield put(addedVatCode.fail(new Error()));
      }
    },
    saveVatCode: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, vatCodeList } = yield call(addNewVatCode, params);
        if (status == 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_CREATED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(newVatCode.success({ addedData: data, vatCodeList }));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_CREATION_FAILED",
            "",
            ""
          );
          yield put(newVatCode.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_CREATION_FAILED",
          "",
          ""
        );
        yield put(newVatCode.fail(new Error()));
      }
    },
    updateVatCode: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, vatCodeList } = yield call(editVatCode, params);
        if (status == 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_UPDATED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(
            existingVatCode.success({ updatedData: data, vatCodeList })
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_UPDATE_FAILED",
            "",
            ""
          );
          yield put(existingVatCode.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_UPDATE_FAILED",
          "",
          ""
        );
        yield put(existingVatCode.fail(new Error()));
      }
    },
    deleteVatCode: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, vatCodeList } = yield call(deleteVatCode, params);
        if (status == 200 && data.hasOwnProperty("status") && data.status) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_DELETED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(vatCodeDetails.success({ deletedData: data, vatCodeList }));
        } else if (
          status == 200 &&
          data.hasOwnProperty("status") &&
          !data?.status &&
          data.hasOwnProperty("message")
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:VAT_CODE.${data?.message}`,
            "",
            ""
          );
          yield put(vatCodeDetails.fail(new Error()));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_DELETE_FAILED",
            "",
            ""
          );
          yield put(vatCodeDetails.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:VAT_CODE.VAT_CODE_DELETE_FAILED",
          "",
          ""
        );
        yield put(vatCodeDetails.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(GET_VAT_CODE_LIST, VatCodeSaga.vatCode.getVatCodeList),
  takeLatest(GET_LEDGER_ACCOUNTS, VatCodeSaga.vatCode.getLedgerAccountList),
  takeLatest(VALIDATE_VAT_CODE, VatCodeSaga.vatCode.validateVatCode),
  takeLatest(SAVE_VAT_CODE, VatCodeSaga.vatCode.saveVatCode),
  takeLatest(UPDATE_VAT_CODE, VatCodeSaga.vatCode.updateVatCode),
  takeLatest(DELETE_VAT_CODE, VatCodeSaga.vatCode.deleteVatCode),
];
