import React from "react";
import { useTranslation } from "react-i18next";
import { $Divider, $Input, $Select, $Button } from "us.common/components";
import { IFilter } from "us.collection.economy/components/ManagePayments/interface";
import { StatusCount, StatusSwitch } from "./Components";
import {
  DebtorOtherCasesStatus,
  PaymentStates,
} from "us.collection.economy/constants/Payments";
import { useField } from "formik";

const Filter: React.FC<IFilter> = ({
  disabled = false,
  values,
  resetForm,
  paymentsCount,
  fileNameOptions,
  onChangePaymentId,
  onFilter,
  onFilterLocal,
}) => {
  const { t } = useTranslation();
  const { debtorOtherCasesStatus, isExceeded, isOCR, paymentStates, isManual } =
    values;
  const [, , helpers] = useField("isManual");
  const isMappedReturnToDebtorDisable =
    disabled ||
    (debtorOtherCasesStatus !== DebtorOtherCasesStatus.ALL && isExceeded);

  return (
    <>
      <div className="mb-auto w-100" data-testid="manage-payments-filter">
        <StatusSwitch
          name={"isExceeded"}
          label={t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED")}
          disabled={disabled}
          onChange={onFilter}
        />
        {isExceeded && (
          <>
            <$Select
              dataTestid={"select-exceeded-cases"}
              name="debtorOtherCasesStatus"
              size="small"
              style={{ width: 166 }}
              options={[
                {
                  label: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ALL"),
                  debtorOtherCasesStatus: DebtorOtherCasesStatus.ALL,
                },
                {
                  label: t(
                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.WITH_NO_OTHER_CASES"
                  ),
                  debtorOtherCasesStatus:
                    DebtorOtherCasesStatus.WITH_NO_OTHER_CASES,
                },
                {
                  label: t(
                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.WITH_OTHER_CASES"
                  ),
                  debtorOtherCasesStatus:
                    DebtorOtherCasesStatus.WITH_OTHER_CASES,
                },
              ]}
              optionValue="debtorOtherCasesStatus"
              optionText="label"
              allOption={false}
              showSearch={false}
              onChange={(debtorOtherCasesStatus) =>
                onFilter({ debtorOtherCasesStatus })
              }
            />
            <$Divider />
          </>
        )}

        <StatusSwitch
          name={"isUnknown"}
          label={t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN")}
          disabled={disabled}
          onChange={onFilter}
        />
        <StatusSwitch
          name={"isMapped"}
          label={t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED")}
          disabled={isMappedReturnToDebtorDisable}
          onChange={onFilter}
        />
        <StatusSwitch
          name={"isReturnToDebtor"}
          label={t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR")}
          disabled={isMappedReturnToDebtorDisable}
          onChange={onFilter}
        />

        {paymentStates && paymentStates.length > 0 && (
          <>
            <$Divider orientation="center" className="mt-4">
              {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ADVANCED_FILTER")}
            </$Divider>
            <StatusSwitch
              name={"isManual"}
              label={t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MANUAL")}
              onChange={onFilterLocal}
            />
            <StatusSwitch
              name={"isOCR"}
              label={t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.OCR")}
              onChange={onFilterLocal}
            />

            {isOCR && fileNameOptions.length > 0 && (
              <$Select
                dataTestid={"select-ocr-file"}
                name="ocrFileName"
                size="small"
                style={{ width: 166 }}
                options={[
                  {
                    label: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ALL"),
                    ocrFileName: "all",
                  },
                  ...fileNameOptions,
                ]}
                optionValue="ocrFileName"
                optionText="label"
                allOption={false}
                showSearch
                onSearchBy={["ocrFileName"]}
                onChange={(ocrFileName) => {
                  onFilterLocal({
                    ocrFileName,
                    isManual: isManual && ocrFileName === "all",
                  });
                  helpers?.setValue(isManual && ocrFileName === "all");
                }}
              />
            )}

            <$Input
              name="paymentId"
              size="small"
              type="text"
              className="mt-4"
              placeholder={t("US.COLLECTION.ECONOMY:PLACE_HOLDERS.PAYMENT_ID")}
              onChange={(e: any) => onChangePaymentId(e.target.value)}
              disabled={disabled}
              dataTestid={"filter-input-payment-id"}
            />
            <$Input
              name="caseNo"
              size="small"
              type="text"
              placeholder={t("US.COLLECTION.ECONOMY:PLACE_HOLDERS.CASE_NO")}
              disabled={disabled}
              onChange={(e: any) => onFilterLocal({ caseNo: e.target.value })}
              dataTestid={"filter-input-case-no"}
            />
            <$Input
              name="kid"
              size="small"
              type="text"
              placeholder={t("US.COLLECTION.ECONOMY:PLACE_HOLDERS.KID")}
              disabled={disabled}
              onChange={(e: any) => onFilterLocal({ kid: e.target.value })}
              dataTestid={"filter-input-kid"}
            />
            <$Input
              name="voucherNo"
              size="small"
              type="text"
              placeholder={t("US.COLLECTION.ECONOMY:PLACE_HOLDERS.VOUCHER_NO")}
              disabled={disabled}
              onChange={(e: any) =>
                onFilterLocal({ voucherNo: e.target.value })
              }
              dataTestid={"filter-input-voucher-no"}
            />
            <$Button
              onClick={() => resetForm()}
              type="default"
              size="small"
              disabled={disabled}
              block
              data-testid={"button-reset-filter"}
            >
              {t("US.COLLECTION.COMMON:COMMON.RESET")}
            </$Button>
          </>
        )}
      </div>
      <div className="w-100 mt-4">
        <StatusCount
          status={PaymentStates.UNKNOWN}
          count={paymentsCount.unknownCount}
        />
        <StatusCount
          status={PaymentStates.EXCEEDED}
          count={paymentsCount.exceededCount}
        />
      </div>
    </>
  );
};

export default Filter;
