import React, { useEffect } from "react";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { IRootState } from "us.collection/interfaces";
import { IItemTypeDetails } from "us.collection.economy/components/ItemTypes/Interfaces";
import * as Actions from "us.collection.economy/actions";
import {
  INITIAL_ITEM_TYPE_DETAIL,
  INITIAL_DISTRIBUTION_FACTORS,
} from "us.collection.economy/constants/ItemTypes";
import {
  ValidateFieldNames,
  TransactionTypes,
  DrawerType,
  BreadcrumbType,
  FeeType,
} from "us.collection.economy/components/ItemTypes/Constants";
import { ItemTypeValidationSchema } from "us.collection.economy/components/ItemTypes/Validations";
import {
  SearchItemTypes,
  SaveItemType,
} from "us.collection.economy/components/ItemTypes/Repository";
import { LoadingOutlined } from "us.icons";
import { isFormDirty } from "us.helper";
import {
  VoucherSequenceAdd,
  VoucherSequenceList,
  DistributionFactors,
} from "./Components";

const {
  $Button,
  $InputAmount,
  $Skeleton,
  $AsyncInput,
  $Row,
  $Col,
  $Input,
  $Select,
  $TextArea,
  $Breadcrumb,
  $Radio,
  $Form,
  $Switch,
  $Popconfirm,
} = Common.Components;

/**
 * @description - Item Type drawer component to show item type details and for update, add new
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2992242726/Manage+Item+Types+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 23/05/2022
 */
const ItemTypeDetails: React.FC<IItemTypeDetails & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const parentFormik = useFormikContext<any>();
  const urlParams = new URLSearchParams(search);
  const itemTypeId = urlParams.get("item-type-id");
  const type = urlParams.get("type");

  const {
    drawerInfo,
    breadcrumbInfo,
    currentCurrency,
    currentLanguage,
    itemTypeDetails,
    transactionGroups,
    initDetailsData,
    duplicateValidation,
    manageBreadcrumb,
    validateFields,
    getInitData,
    getDetails,
    onCancel,
    addItemType,
    editItemType,
    resetDetails,
  } = props;

  useEffect(() => {
    getInitData && getInitData({});
    if (itemTypeId) {
      getDetails && getDetails({ itemTypeId });
    }

    return () => {
      resetDetails && resetDetails({});
    };
  }, []);

  /**
   * Transaction type options.
   */
  const TransactionTypeOptions = [
    {
      value: TransactionTypes.PAYMENT,
      label: t("US.COLLECTION.ECONOMY:ITEM_TYPE.PAYMENT"),
    },
    {
      value: TransactionTypes.CANCELATION_TYPE,
      label: t("US.COLLECTION.ECONOMY:ITEM_TYPE.CANCELLATION"),
    },
    {
      value: TransactionTypes.FEES,
      label: t("US.COLLECTION.ECONOMY:ITEM_TYPE.COST_ITEM"),
    },
  ];

  /**
   * @description Handle transaction type change.
   * @param {any} e - Change event.
   * @param {any} restProps - Formik props.
   */
  const onChangeTransactionType = (e: any, restProps: any) => {
    const transactionType: any = e.target.value;
    {
      if (transactionType == TransactionTypes.FEES) {
        restProps.setFieldTouched(
          `distributionFactors[0].creditorFraction`,
          true
        );
        restProps.setFieldTouched(
          `distributionFactors[0].bureauFraction`,
          false
        );
      }
      if (
        transactionType == TransactionTypes.CANCELATION_TYPE ||
        transactionType == TransactionTypes.FEES
      ) {
        restProps.setValues((preValues: any) => ({
          ...preValues,
          bankAccountNumber: INITIAL_ITEM_TYPE_DETAIL.bankAccountNumber,
          voucherSequenceId: INITIAL_ITEM_TYPE_DETAIL.voucherSequenceId,
          isInterestApplicable: INITIAL_ITEM_TYPE_DETAIL.isInterestApplicable,
          distributionFactors: INITIAL_ITEM_TYPE_DETAIL.distributionFactors,
          transactionType: e.target.value,
        }));
      } else {
        restProps.setValues((preValues: any) => ({
          ...preValues,
          isVatLiable: INITIAL_ITEM_TYPE_DETAIL.isVatLiable,
          vatCode: INITIAL_ITEM_TYPE_DETAIL.vatCode,
          isInterestApplicable: INITIAL_ITEM_TYPE_DETAIL.isInterestApplicable,
          distributionFactors: INITIAL_ITEM_TYPE_DETAIL.distributionFactors,
          transactionType: e.target.value,
        }));
      }
    }
  };

  /**
   * @description Handle interest applicable toggle change.
   * @param {boolean} isChecked - Toggle value.
   * @param {any} restProps - Formik props.
   */
  const onChangeInterestApplicable = (isChecked: boolean, restProps: any) => {
    if (isChecked) {
      restProps.setFieldTouched(
        `distributionFactors[1].creditorFraction`,
        true
      );
      restProps.setFieldTouched(`distributionFactors[1].bureauFraction`, false);
    }

    restProps.setValues((preValues: any) => ({
      ...preValues,
      isInterestApplicable: isChecked,
      distributionFactors: isChecked
        ? [...preValues?.distributionFactors, INITIAL_DISTRIBUTION_FACTORS[1]]
        : preValues?.distributionFactors?.filter(
            ({ feeType }: { feeType: FeeType }) => feeType == FeeType.AMOUNT
          ),
    }));
  };

  /**
   * @description Validate item type id and transaction code.
   * @param {string | number} value - the value of the field
   * @param {string} field - the name of the field
   */
  const onValidateFields = (value: string | number, field: string) => {
    validateFields && validateFields({ value, field });
  };

  /**
   * @description Get form validation status.
   * @param {any} restProps - form properties
   * @param {boolean} isSubmitting - is form submitting
   */
  const isFormValid = (values: any, restProps: any, isSubmitting: boolean) => {
    return (
      !duplicateValidation.itemTypeId.status ||
      !duplicateValidation.transactionCode.status ||
      duplicateValidation.itemTypeId.isValidating ||
      duplicateValidation.transactionCode.isValidating ||
      itemTypeDetails.isSaving ||
      !restProps.isValid ||
      !isFormDirty(values, itemTypeDetails.initData) ||
      isSubmitting
    );
  };

  /**
   * @description Handle breadcrumb UI
   * @param {BreadcrumbType} breadcrumbType selected UI
   */
  const handleBreadcrumbState = (breadcrumbType: BreadcrumbType) => {
    manageBreadcrumb && manageBreadcrumb(breadcrumbType);
  };

  /**
   * @description Handle submit button action
   * @param {any} values
   */
  const handleSubmit = (values: any, actions: any) => {
    const { itemTypeId, transactionCode } = duplicateValidation;
    if (
      actions.isValidating ||
      itemTypeId.isValidating ||
      transactionCode.isValidating
    )
      return;

    const saveParams = SaveItemType.call(values);
    const searchParams = SearchItemTypes.call(parentFormik.values);

    if (type === DrawerType.NEW_ITEM_TYPE) {
      // add new item type
      addItemType && addItemType({ searchParams, saveParams, detail: values });
    } else {
      // update the existing item type details
      editItemType &&
        editItemType({ searchParams, saveParams, detail: values });
    }
    actions.setSubmitting(false);
  };

  return (
    <$Skeleton
      loading={itemTypeDetails.isLoading}
      active
      paragraph={{
        rows: 2,
      }}
    >
      <Formik
        initialValues={{
          ...itemTypeDetails.data,
        }}
        enableReinitialize
        validateOnChange
        validateOnBlur
        validationSchema={ItemTypeValidationSchema}
        onSubmit={handleSubmit}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          resetForm,
          ...restProps
        }: any) => (
          <>
            {breadcrumbInfo.visibleView !== BreadcrumbType.ITEM_TYPE && (
              <>
                <$Breadcrumb className="mb-2">
                  <$Breadcrumb.Item>
                    <a
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleBreadcrumbState(BreadcrumbType.ITEM_TYPE);
                      }}
                    >
                      {drawerInfo.title}
                    </a>
                  </$Breadcrumb.Item>
                  <$Breadcrumb.Item>
                    <a
                      onClick={(e: any) => {
                        e.preventDefault();
                        handleBreadcrumbState(
                          BreadcrumbType.VOUCHER_SEQUENCE_LIST
                        );
                      }}
                    >
                      {t(
                        "US.COLLECTION.ECONOMY:ITEM_TYPE.SELECT_VOUCHER_SEQUENCE"
                      )}
                    </a>
                  </$Breadcrumb.Item>

                  {breadcrumbInfo.visibleView ==
                    BreadcrumbType.VOUCHER_SEQUENCE_ADD && (
                    <$Breadcrumb.Item>
                      {t(
                        "US.COLLECTION.ECONOMY:ITEM_TYPE.NEW_VOUCHER_SEQUENCE"
                      )}
                    </$Breadcrumb.Item>
                  )}
                </$Breadcrumb>

                {breadcrumbInfo.visibleView ==
                  BreadcrumbType.VOUCHER_SEQUENCE_LIST && (
                  <VoucherSequenceList
                    onCancel={() =>
                      handleBreadcrumbState(BreadcrumbType.ITEM_TYPE)
                    }
                  />
                )}
                {breadcrumbInfo.visibleView ==
                  BreadcrumbType.VOUCHER_SEQUENCE_ADD && (
                  <VoucherSequenceAdd
                    onCancel={() =>
                      handleBreadcrumbState(
                        BreadcrumbType.VOUCHER_SEQUENCE_LIST
                      )
                    }
                  />
                )}
              </>
            )}
            {breadcrumbInfo.visibleView == BreadcrumbType.ITEM_TYPE && (
              <>
                <$Form layout="vertical">
                  <$Radio
                    className="mb-4"
                    name="transactionType"
                    data-testid="transaction-type"
                    optionText="label"
                    optionValue="value"
                    options={TransactionTypeOptions}
                    onChange={(e: any) => onChangeTransactionType(e, restProps)}
                  />

                  <$Row gutter={16} className="mb-2">
                    <$Col span={6}>
                      <$AsyncInput
                        name="itemTypeId"
                        label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.ID")}
                        size="small"
                        className="w-100"
                        maxLength={5}
                        isNumber={true}
                        showCount={type == DrawerType.NEW_ITEM_TYPE}
                        required={
                          type == DrawerType.NEW_ITEM_TYPE ? true : undefined
                        }
                        isValid={duplicateValidation.itemTypeId.status}
                        asyncError={t(
                          "US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_ITEM_TYPE_ID"
                        )}
                        loading={duplicateValidation.itemTypeId.isValidating}
                        disabled={
                          type == DrawerType.EDIT_ITEM_TYPE ||
                          duplicateValidation.itemTypeId.isValidating
                        }
                        onBlur={(e: any) => {
                          if (e.target.value && e.target.value != 0) {
                            itemTypeDetails.data.itemTypeId !==
                              values?.itemTypeId &&
                              onValidateFields(
                                e.target.value,
                                ValidateFieldNames.ITEM_TYPE_ID
                              );
                          }
                        }}
                      />
                    </$Col>
                    <$Col span={18}>
                      <$Input
                        required
                        size="small"
                        name="itemTypeName"
                        label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.NAME")}
                      />
                    </$Col>
                  </$Row>
                  <$Row gutter={16} className="mb-2">
                    <$Col span={6}>
                      <$AsyncInput
                        name="transactionCode"
                        label={t(
                          "US.COLLECTION.ECONOMY:ITEM_TYPE.TRANSACTION_CODE"
                        )}
                        size="small"
                        className="w-100"
                        maxLength={5}
                        showCount
                        required={true}
                        suffix={
                          duplicateValidation.transactionCode.isValidating ? (
                            <LoadingOutlined style={{ fontSize: 12 }} spin />
                          ) : undefined
                        }
                        disabled={
                          duplicateValidation.transactionCode.isValidating
                        }
                        onBlur={(e: any) => {
                          if (e.target.value !== "") {
                            itemTypeDetails.data.transactionCode?.toLowerCase() !==
                              values?.transactionCode?.toLowerCase() &&
                              onValidateFields(
                                e.target.value,
                                ValidateFieldNames.TRANSACTION_CODE
                              );
                          }
                        }}
                        isValid={duplicateValidation.transactionCode.status}
                        asyncError={t(
                          "US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_TRANSACTION_CODE"
                        )}
                      />
                    </$Col>

                    <$Col span={18}>
                      <$Select
                        name="transactionGroupId"
                        formitem={{
                          label: t(
                            "US.COLLECTION.ECONOMY:ITEM_TYPE.TRANSACTION_GROUP"
                          ),
                        }}
                        size="small"
                        value={
                          values?.transactionGroupId <= 0
                            ? null
                            : values?.transactionGroupId
                        }
                        loading={transactionGroups.isLoading}
                        disabled={transactionGroups.isLoading}
                        options={transactionGroups.data}
                        optionValue="transactionGroupId"
                        optionText="transactionGroupName"
                        allOption={false}
                        required
                        onSearchBy={[
                          "transactionGroupCode",
                          "transactionGroupName",
                        ]}
                      />
                    </$Col>
                  </$Row>

                  <div className="mb-5">
                    <$TextArea
                      className="w-100"
                      label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.DESCRIPTION")}
                      name="description"
                      autoSize={{ minRows: 2, maxRows: 5 }}
                      maxLength={500}
                    />
                  </div>

                  <$Row gutter={16} className="mb-2">
                    <$Col span={12}>
                      <$InputAmount
                        currentLanguage={currentLanguage}
                        currentCurrency={currentCurrency}
                        label={t(
                          "US.COLLECTION.ECONOMY:ITEM_TYPE.DEFAULT_AMOUNT"
                        )}
                        placeholder="0,00"
                        className="w-100"
                        size="small"
                        name="defaultAmount"
                        min={
                          itemTypeDetails.data.defaultAmount >= 0
                            ? 0
                            : undefined
                        }
                        value={values?.defaultAmount}
                      />
                    </$Col>
                    <$Col span={12}>
                      <$Select
                        name="ledgerAccountNumber"
                        formitem={{
                          label: t(
                            "US.COLLECTION.ECONOMY:ITEM_TYPE.LEDGER_ACCOUNT"
                          ),
                        }}
                        size="small"
                        loading={initDetailsData.isLoading}
                        disabled={initDetailsData.isLoading}
                        options={initDetailsData.ledgerAccounts}
                        optionValue="accountNo"
                        optionText="accountName"
                        allOption={false}
                        required
                        optionExtra={{
                          extraNumber: "accountNo",
                        }}
                        onSearchBy={["accountName", "accountNo"]}
                      />
                    </$Col>
                  </$Row>
                  <$Row gutter={16}>
                    {values?.transactionType === TransactionTypes.PAYMENT && (
                      <$Col span={12}>
                        <$Select
                          name="bankAccountNumber"
                          loading={initDetailsData.isLoading}
                          disabled={initDetailsData.isLoading}
                          formitem={{
                            label: t(
                              "US.COLLECTION.ECONOMY:ITEM_TYPE.BANK_ACCOUNT"
                            ),
                          }}
                          size="small"
                          optionValue="accountNo"
                          optionText="accountName"
                          options={initDetailsData.bankAccounts}
                          allOption={false}
                          optionExtra={{
                            extraNumber: "accountNo",
                          }}
                          onSearchBy={["accountName", "accountNo"]}
                        />
                      </$Col>
                    )}
                    {values?.transactionType === TransactionTypes.PAYMENT && (
                      <$Col span={12}>
                        <$Select
                          name="voucherSequenceId"
                          loading={initDetailsData.isLoading}
                          disabled={initDetailsData.isLoading}
                          value={
                            values?.voucherSequenceId <= 0
                              ? null
                              : values?.voucherSequenceId
                          }
                          formitem={{
                            label: (
                              <a
                                onClick={(e: any) => {
                                  e.preventDefault();
                                  handleBreadcrumbState(
                                    BreadcrumbType.VOUCHER_SEQUENCE_LIST
                                  );
                                }}
                              >
                                {t(
                                  "US.COLLECTION.ECONOMY:ITEM_TYPE.VOUCHER_SEQUENCE"
                                )}
                              </a>
                            ),
                          }}
                          size="small"
                          optionValue="id"
                          optionText="subSequenceId"
                          options={initDetailsData.voucherSequence}
                          allOption={false}
                          required
                          optionExtra={{
                            extraNumber: "sequenceId",
                          }}
                          onSearchBy={["sequenceId", "subSequenceId"]}
                        />
                      </$Col>
                    )}
                  </$Row>
                  <$Row gutter={16} className="mb-4 mt-4">
                    <$Col>
                      <div className="d-flex align-items-center mr-3">
                        <$Switch
                          name="isRemappingAllowed"
                          checked={values?.isRemappingAllowed}
                          size="small"
                          className="mr-1"
                          onChange={(isChecked: boolean) => {
                            restProps.setFieldValue("isRemappingAllowed", isChecked)
                            !isChecked && restProps.setFieldValue("subCaseMappingOnly", false, false)
                          }}
                        />
                        <label>
                          {t("US.COLLECTION.ECONOMY:ITEM_TYPE.ALLOW_REMAPPING")}
                        </label>
                      </div>
                    </$Col>

                    <$Col >

                      {values?.isRemappingAllowed && (
                        <div className="d-flex align-items-center mr-3">
                          <$Switch
                            name="subCaseMappingOnly"
                            checked={values?.subCaseMappingOnly}
                            size="small"
                            className="mr-1"
                          />
                          <label>
                            {t(
                              "US.COLLECTION.ECONOMY:ITEM_TYPE.RE_MAP_TO_INVOICE_LEVEL_ONLY"
                            )}
                          </label>
                        </div>
                      )}
                    </$Col>
                    <$Col >

                      {values?.transactionType === TransactionTypes.FEES && (
                        <div className="d-flex align-items-center mr-3">
                          <$Switch
                            name="isInterestApplicable"
                            checked={values?.isInterestApplicable}
                            size="small"
                            className="mr-1"
                            onChange={(isChecked: boolean) =>
                              onChangeInterestApplicable(isChecked, restProps)
                            }
                          />
                          <label>
                            {t(
                              "US.COLLECTION.ECONOMY:ITEM_TYPE.INTEREST_APPLICABLE"
                            )}
                          </label>
                        </div>
                      )}

                    </$Col>

                  </$Row>
                  <$Row gutter={16} className="mb-4">
                  <$Col >
                  {values?.transactionType &&
                        values?.transactionType !==
                          TransactionTypes.PAYMENT && (
                          <div className="d-flex align-items-center">
                            <$Switch
                              name="isVatLiable"
                              checked={values?.isVatLiable}
                              size="small"
                              className="mr-1"
                              onChange={(isChecked: boolean) => {
                                restProps.setFieldValue(
                                  "isVatLiable",
                                  isChecked
                                );
                                !isChecked &&
                                  restProps.setFieldValue(
                                    "vatCode",
                                    INITIAL_ITEM_TYPE_DETAIL.vatCode,
                                    false
                                  );
                              }}
                            />
                            <label>
                              {t("US.COLLECTION.ECONOMY:ITEM_TYPE.VAT_LIABLE")}
                            </label>
                          </div>
                        )}
                    </$Col>
                  <$Col span={8}>
                      {values?.isVatLiable && (
                        <$Select
                          name="vatCode"
                          loading={initDetailsData.isLoading}
                          disabled={initDetailsData.isLoading}
                          value={
                            values?.vatCode == INITIAL_ITEM_TYPE_DETAIL.vatCode
                              ? null
                              : values?.vatCode
                          }
                          formitem={{
                            label: t(
                              "US.COLLECTION.ECONOMY:ITEM_TYPE.VAT_CODE"
                            ),
                          }}
                          required
                          size="small"
                          optionValue="vatCode"
                          optionText="vatCodeName"
                          options={initDetailsData.vatCodes}
                          allOption={false}
                          optionExtra={{
                            extraNumber: "vatCode",
                          }}
                          onSearchBy={["vatCodeName", "vatCode"]}
                        />
                      )}
                    </$Col>
                    </$Row>
                  <DistributionFactors />
                </$Form>
                <div className="drawer-footer-fixed align-content-center justify-content-end">
                  <div>
                    <$Popconfirm
                      title={t("COMMON.SURE_TO_PROCEED_?")}
                      placement="topLeft"
                      data-testid="pop-confirm"
                      okButtonProps={{
                        dataTestid: "pop-confirm-ok",
                      }}
                      onConfirm={() => handleSubmit()}
                      okText={t("COMMON.YES")}
                      cancelText={t("COMMON.NO")}
                      disabled={isFormValid(values, restProps, isSubmitting)}
                    >
                      <$Button
                        className="ml-3 mr-2"
                        type="primary"
                        data-testid="save-btn"
                        loading={itemTypeDetails.isSaving}
                        disabled={isFormValid(values, restProps, isSubmitting)}
                      >
                        {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                      </$Button>
                    </$Popconfirm>
                    <$Button
                      data-testid="cancel-item-type"
                      onClick={() => onCancel()}
                    >
                      {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                    </$Button>
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </Formik>
    </$Skeleton>
  );
};
const mapStateToProps = (state: IRootState) => {
  const { itemType, common } = state;
  const { currentLanguage, currentCurrency } = common;
  const {
    drawerInfo,
    breadcrumbInfo,
    itemTypeDetails,
    initDetailsData,
    transactionGroups,
    duplicateValidation,
  } = itemType;

  return {
    drawerInfo,
    breadcrumbInfo,
    itemTypeDetails,
    initDetailsData,
    transactionGroups,
    currentLanguage,
    currentCurrency,
    duplicateValidation,
  };
};

const {
  initDetails,
  itemTypeDetails,
  validateFields,
  addItemType,
  editItemType,
} = Actions.itemTypes;

const mapDispatchToProps = {
  getInitData: initDetails.get,
  getDetails: itemTypeDetails.get,
  validateFields: validateFields.get,
  addItemType: addItemType.save,
  editItemType: editItemType.save,
  resetDetails: itemTypeDetails.reset,
  manageBreadcrumb: itemTypeDetails.manageBreadcrumb,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypeDetails);
