import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    mainAmount: Yup.string()
      .test('matchMain',
        'Please add a valid value into the field',
        function (mainAmount) {
          mainAmount = typeof mainAmount == "undefined" ? "0.00" : mainAmount;
          let res: boolean = true;
          if (mainAmount) {
            res = parseFloat(mainAmount?.replaceAll(/\D/g, '')) <= parseFloat(this.parent.mainBalance.toString().replaceAll(/\D/g, ''));
          }
          return res;
        }),

    collectionFee: Yup.string()
      .test('matchCollection',
        'Please add a valid value into the field',
        function (collectionFee) {
          collectionFee = typeof collectionFee == "undefined" ? "0.00" : collectionFee;
          let res: boolean = true;
          if (collectionFee) {
            res = parseFloat(collectionFee?.replaceAll(/\D/g, '')) <= parseFloat(this.parent.collectionBalance.toString().replaceAll(/\D/g, ''));
          }
          return res;
        }),

    courtFee: Yup.string()
      .test('matchCourtFee',
        'Please add a valid value into the field',
        function (courtFee) {
          courtFee = typeof courtFee == "undefined" ? "0.00" : courtFee;
          let res: boolean = true;
          if (courtFee) {
            res = parseFloat(courtFee?.replaceAll(/\D/g, '')) <= parseFloat(this.parent.courtBalance.toString().replaceAll(/\D/g, ''));
          }
          return res;
        }),

    costFee: Yup.string()
      .test('matchcostFee',
        'Please add a valid value into the field',
        function (costFee) {
          costFee = typeof costFee == "undefined" ? "0.00" : costFee;
          let res: boolean = true;
          if (costFee) {
            res = parseFloat(costFee?.replaceAll(/\D/g, '')) <= parseFloat(this.parent.costBalance.toString().replaceAll(/\D/g, ''));
          }
          return res;
        }),

    otherFee: Yup.string()
      .test('matchotherFee',
        'Please add a valid value into the field',
        function (otherFee) {
          otherFee = typeof otherFee == "undefined" ? "0.00" : otherFee;
          let res: boolean = true;
          if (otherFee) {
            res = parseFloat(otherFee?.replaceAll(/\D/g, '')) <= parseFloat(this.parent.otherBalance.toString().replaceAll(/\D/g, ''));
          }
          return res;
        }),

    interestMainAmount: Yup.string()
      .test('matchInterestMainAmount',
        'Please add a valid value into the field',
        function (interestMainAmount) {
          interestMainAmount = typeof interestMainAmount == "undefined" ? "0.00" : interestMainAmount;

          let res: boolean = true;
          if (interestMainAmount) {
            res = parseFloat(interestMainAmount?.replaceAll(/\D/g, '')) <= parseFloat(this.parent.interestMainBalance?.toString().replaceAll(/\D/g, ''));
          }
          return res;
        }),

    interestCostAmount: Yup.string()
      .test('matchInterestCostAmount',
        'Please add a valid value into the field',
        function (interestCostAmount) {
          interestCostAmount = typeof interestCostAmount == "undefined" ? "0.00" : interestCostAmount;
          let res: boolean = true;
          if (interestCostAmount) {
            res = parseFloat(interestCostAmount?.replaceAll(/\D/g, '')) <= parseFloat(this.parent.interestCostBalance?.toString().replaceAll(/\D/g, ''));
          }
          return res;
        }),
    totalAmount: Yup.mixed()
      .test('totalValue',
        '',
        function (totalAmount) {
          const { path, parent, createError } = this;
          if (totalAmount) {
            if (parseFloat(totalAmount?.replaceAll(/\D/g, '')) < parseFloat(parent.minCaseBalance.toString().replaceAll(/\D/g, ''))) {
              let a =parseFloat(totalAmount?.replaceAll(/\D/g, ''))
              let b= parseFloat(parent.minCaseBalance.toString().replaceAll(/\D/g, ''))
              console.log(a,b)
              return createError({
                path,
                message: `Agreement balance should be greater than ${parseFloat(parent.minCaseBalance)}`
              });
            }
          }
          return true;
        })

  })
};
