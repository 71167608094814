/**
 * @description - remove white spaces for given account number
 * @param {accountNo} string - given accountNo
 * @return {number} - return accountNo without white spaces
 */
export const formatAccountNo = (accountNo: any): any => {
  try {
    if (accountNo) {
      return accountNo.replace(/\D+/g, "");
    } else {
      return "";
    }
  } catch (error) {
    return "";
  }
};
