import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import { i18n } from "us.helper";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { $Message, $MessageBox } from "us.common/components";
import { Remit } from "us.collection.economy/constants/Actions";
import { VATDeductionStatus } from "us.collection.economy/constants";

const { remit } = Actions;
const {
  getBankAccounts,
  getCreditors,
  getCreditorGroups,
  getProposal,
  validate,
  getDetailsByCreditor,
  generate,
  invoice,
} = API.remit;
const {
  bankAccounts,
  creditors,
  creditorGroups,
  remitProposal,
  remitValidation,
  remitDetails,
  remitGeneration,
  remitInvoice,
} = remit;
const {
  GET_BANK_ACCOUNTS,
  GET_CREDITORS,
  GET_CREDITOR_GROUPS,
  GET_REMIT_PROPOSAL,
  VALIDATE_REMIT,
  GET_REMIT_DETAILS_BY_CREDITOR,
  GENERATE_REMIT,
  INVOICE_REMIT,
} = Remit;

export const RemitSagas = {
  remit: {
    getBankAccounts: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(getBankAccounts, params);
        yield put(bankAccounts.success(data));
      } catch (error) {
        yield put(bankAccounts.fail(new Error()));
      }
    },
    getCreditorGroups: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(getCreditorGroups, params);
        if (status === 200) {
          yield put(creditorGroups.success(data));
        } else if (status === 204) {
          yield put(creditorGroups.success([]));
        } else {
          yield put(creditorGroups.fail(new Error()));
        }
      } catch (error) {
        yield put(creditorGroups.fail(new Error()));
      }
    },
    getCreditors: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      const { groupId, isRefresh } = params;
      try {
        const { data, status } = yield call(getCreditors, params);
        if (status === 200) {
          yield put(creditors.success({ data, groupId, isRefresh }));
        } else if (status === 204) {
          yield put(creditors.success({ data: [], groupId, isRefresh }));
        } else {
          yield put(creditors.fail(new Error()));
        }
      } catch (error) {
        yield put(creditors.fail(new Error()));
      }
    },
    getProposal: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(getProposal, params);
        yield put(remitProposal.success(data));
      } catch (error) {
        yield put(remitProposal.fail(new Error()));
      }
    },
    getValidation: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(validate, params);
        if (status === 204) {
          yield put(remitValidation.success([]));
        } else if (status === 200) {
          yield put(remitValidation.success(data));
        } else {
          yield put(remitValidation.fail(new Error()));
        }
      } catch (error) {
        yield put(remitValidation.fail(new Error()));
      }
    },
    getDetailsByCreditor: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(getDetailsByCreditor, params);
        if (status === 204) {
          $MessageBox(
            "warning",
            "US.COLLECTION.ECONOMY:REMIT.THESE_RECORDS_ALREADY_PICKED_FOR_REMITTING",
            "",
            ""
          );
          yield put(remitDetails.fail(new Error()));
        } else if (status === 200) {
          yield put(remitDetails.success(data));
        } else {
          yield put(remitDetails.fail(new Error()));
        }
      } catch (error) {
        yield put(remitDetails.fail(new Error()));
      }
    },
    generate: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(generate, params);
        if (data.hasOwnProperty("isSuccess") && data.isSuccess) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:REMIT.REMIT_FILE_GENERATION_IS_IN_PROGRESS",
            "",
            ""
          );
          yield put(remitGeneration.success(data));
        } else {
          yield put(remitGeneration.fail(data));
        }
      } catch (error) {
        yield put(remitGeneration.fail(new Error()));
      }
    },
    invoice: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      const isRemoveVATDeduction = params?.status === VATDeductionStatus.REMOVE;
      try {
        $Message.loading(i18n.t("COMMON.INVOICING"), 0);
        const data = yield call(invoice, params);
        $Message.destroy();
        if (
          data.hasOwnProperty("status") &&
          typeof data.status === "boolean" &&
          data.status
        ) {
          $MessageBox(
            "success",
            isRemoveVATDeduction
              ? "US.COLLECTION.ECONOMY:REMIT.REMIT_INVOICE_SUCCESS"
              : "US.COLLECTION.ECONOMY:REMIT.REMIT_RESET_INVOICE_SUCCESS",
            "",
            ""
          );
          yield put(remitInvoice.success({ ...data, ...params }));
        } else {
          $MessageBox(
            "error",
            isRemoveVATDeduction
              ? "US.COLLECTION.ECONOMY:REMIT.REMIT_INVOICE_FAILED"
              : "US.COLLECTION.ECONOMY:REMIT.REMIT_RESET_INVOICE_FAILED",
            "",
            ""
          );
          yield put(remitInvoice.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:REMIT.REMIT_INVOICE_FAILED",
          "",
          ""
        );
        yield put(remitInvoice.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(GET_BANK_ACCOUNTS, RemitSagas.remit.getBankAccounts),
  takeEvery(GET_CREDITORS, RemitSagas.remit.getCreditors),
  takeLatest(GET_CREDITOR_GROUPS, RemitSagas.remit.getCreditorGroups),
  takeLatest(GET_REMIT_PROPOSAL, RemitSagas.remit.getProposal),
  takeLatest(VALIDATE_REMIT, RemitSagas.remit.getValidation),
  takeLatest(
    GET_REMIT_DETAILS_BY_CREDITOR,
    RemitSagas.remit.getDetailsByCreditor
  ),
  takeLatest(GENERATE_REMIT, RemitSagas.remit.generate),
  takeLatest(INVOICE_REMIT, RemitSagas.remit.invoice),
];
