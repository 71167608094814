import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  COLUMNS,
  ItemTypeTableColumn,
} from "us.collection.economy/components/ItemTypes/Constants";
import { MoreOutlined } from "us.icons";
import { connect, ConnectedProps } from "react-redux";
import { IRootState } from "us.collection/interfaces";
import { IItemType } from "us.collection.economy/interfaces";
import { ItemMenu } from "./Components";
import { IItemTypeTable, ItemTypeAction } from "./Interfaces";
import {
  $Button,
  $Popover,
  $TableTree,
  $Skeleton,
  $DateLabel,
  $AccountNoLabel,
  ITableTreeColumns,
  $Tooltip,
} from "us.common/components";

/**
 * @description - Item types table component for show all item types.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2992242726/Manage+Item+Types+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 20/06/2022
 */
const ItemTypesTable: React.FC<IItemTypeTable & PropsFromRedux> = (props) => {
  const { onCallAction, itemTypes } = props;
  const { t } = useTranslation();

  const [itemMenu, setItemMenu] = useState<{
    visible: boolean;
    itemTypeId: number;
    visibleTooltip: boolean;
  }>({ visible: false, itemTypeId: -1, visibleTooltip: false });

  // auto close item menu after 5 seconds
  const setAutoClose = (itemTypeId: number, isTooltip?: boolean) => {
    setTimeout(
      () => {
        setItemMenu({
          visible: false,
          itemTypeId,
          visibleTooltip: false,
        });
      },
      isTooltip ? 8000 : 5000
    );
  };

  /**
   * @function
   * @description Get table columns list
   * @returns {ITableTreeColumns} - TableTree columns
   */
  const getColumns = (): ITableTreeColumns => {
    const columns: ITableTreeColumns = [];

    COLUMNS.map(({ key, title, width }: any) => {
      let column: any = {
        key: key,
        dataIndex: key,
        title: title && t(title),
        width,
      };
      if (key === ItemTypeTableColumn.MENU) {
        // configure menu column
        column = {
          ...column,
          align: "center",
          customRenderChild: (_text: any, record: IItemType) => (
            <div className="align-items-center" key={0}>
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={
                  itemMenu.visible && itemMenu.itemTypeId === record.itemTypeId
                }
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    itemTypeId: record.itemTypeId,
                  });
                  setAutoClose(0);
                }}
                content={
                  <ItemMenu
                    onCallAction={(actionType: ItemTypeAction) => {
                      setItemMenu({
                        ...itemMenu,
                        visible: false,
                        itemTypeId: record.itemTypeId,
                      });
                      onCallAction(actionType, record);
                    }}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  data-testid="popover-btn"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            </div>
          ),
        };
      } else if (
        [ItemTypeTableColumn.DESCRIPTION].includes(key) ||
        [ItemTypeTableColumn.MODIFIED_USER].includes(key)
      ) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          showFilters: true,
          customSorter: (a: any, b: any) => {
            return a?.localeCompare(b);
          },
          customRenderChild: (_text: any, record: any) => (
            <$Tooltip placement="topLeft" title={record[key]}>
              <span className="text-truncate">{record[key]}</span>
            </$Tooltip>
          ),
        };
      } else if ([ItemTypeTableColumn.BANK_ACCOUNT_NO].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          showFilters: true,
          customSorter: (a: any, b: any) => {
            return a?.localeCompare(b);
          },
          customRenderChild: (_text: any, record: any) => (
            <$AccountNoLabel value={record[key]} />
          ),
        };
      } else if ([ItemTypeTableColumn.MODIFIED_DATE].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: "date",
          showFilters: true,
          customSorter: (a: any, b: any) => {
            return new Date(a).getTime() - new Date(b).getTime();
          },
          customRenderChild: (_text: any, record: any) =>
            record[key] && <$DateLabel value={record[key]} />,
        };
      } else {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          showFilters: true,
          customSorter: (a: any, b: any) => {
            if (key == ItemTypeTableColumn.ITEM_TYPE_ID) {
              return a - b;
            } else {
              return a?.localeCompare(b);
            }
          },
          customRenderChild: (text: any, record: any) => (
            <span>{record[key]}</span>
          ),
        };
      }
      // add configured column data object to the columns array
      columns.push(column);
    });

    return columns;
  };

  return (
    <$Skeleton
      loading={itemTypes.isLoading}
      active
      paragraph={{
        rows: 2,
      }}
    >
      <$TableTree
        rowKey={(record: IItemType) => record.itemTypeId}
        data={itemTypes.data}
        size="small"
        filterOnType
        resetOnSourceChange
        bordered
        className="mt-3"
        showResetAllFilters
        onSort={(sortData, dataSource) => {
          return sortData(dataSource);
        }}
        onFilter={(searchData, dataSource) => {
          return searchData(dataSource);
        }}
        pagination={{
          defaultPageSize: 50,
        }}
        onRow={(record, _rowIndex) => {
          return {
            onDoubleClick: () => onCallAction(ItemTypeAction.EDIT, record),
          };
        }}
        scroll={{ x: 900, y: "calc(100vh - 280px)" }}
        columns={getColumns()}
        data-testid="item-type-table"
      />
    </$Skeleton>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { itemType } = state;
  const { itemTypes } = itemType;

  return {
    itemTypes,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypesTable);
