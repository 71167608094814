import React, { useEffect } from "react";
import { Formik, FieldArray, useFormikContext } from "formik";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import { connect, ConnectedProps } from "react-redux";
import queryString from "query-string";

import Common from "us.common";
import "./PaymentDetails.scss";
import { IRootState } from "us.collection/interfaces";
import * as Actions from "us.collection.economy/actions";
import {
  sumBy,
  getSubText,
  updateCaseTableWithFooterChanges,
  getExpandedActiveRowKeys,
  isDisableBureauAmount,
  isDirtyApportionment,
  isDisabled,
  isDisableCreditorAmount,
  isDisableOtherAmount,
} from "./Functions";
import { IPaymentDetails } from "./Interfaces";
import { getRouteUrl } from "us.helper";
import {
  ItemTypeIds,
  AmountTypes,
  TotalAmountTypes,
  TotalAmountByText,
  TransactionTexts,
  VATAmountInfo,
  PropertyKeys,
  VatStatus,
} from "./Constant";
import { PaymentHelper } from "us.collection.economy/components/ManagePayments/Functions";
import {
  AddApportionmentReturnToDebtor,
  SaveApportionments,
} from "./Repository";
import * as Azure from "us.helper/azure";
import { BoxIcons, IconTypes, InfoCircleOutlined } from "us.icons";

import { GetPayments } from "us.collection.economy/components/ManagePayments/Repository";

const {
  getPaymentsApportionments,
  getVatAmounts,
  getVatAmountsForReturnFee,
  updateApportionment,
  changeDrawerInfo,
  addApportionmentTableRowKey,
  deleteApportionmentTableRowKey,
} = Actions.managePaymentApportionment;

const {
  $Divider,
  $Button,
  $Row,
  $Table,
  $Col,
  $InputAccountNo,
  $Popover,
  $Form,
  $AmountLabel,
  $Skeleton,
  $InputAmount,
  $Popconfirm,
  $DateLabel,
  $Empty,
  $Tooltip,
  $Alert,
  $Tag,
} = Common.Components;

const PaymentDetails: React.FC<PropsFromRedux & IPaymentDetails> = (props) => {
  const { t } = useTranslation([
    "US.COLLECTION.ECONOMY",
    "US.COLLECTION.COMMON",
  ]);

  // extract the URL prams
  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const urlParamPaymentId = urlParams.get("id");
  const params = queryString.parse(search);
  const { values: parentFormValues } = useFormikContext();

  const {
    paymentApportionments,
    currentLanguage,
    currentCurrency,
    payments,
    getPaymentsApportionments,
    getVatAmounts,
    changeDrawerInfo,
    getVatAmountsForReturnFee,
    updateApportionment,
    checkChildChanged,
    addApportionmentTableRowKey,
    deleteApportionmentTableRowKey,
  } = props;

  const paymentForApportionment =
    urlParamPaymentId && payments.data.raw.length > 0
      ? PaymentHelper.getPaymentForMatch(
          Number(urlParamPaymentId),
          payments.data.raw
        )
      : undefined;

  const isAzureAD = window._ENV.REACT_APP_AZURE_AD_SETUP;
  let auth;

  if (isAzureAD) {
    auth = new Azure.ADAuth();
  } else {
    auth = new Azure.B2CAuth();
  }
  const currentUser: any = auth.currentUser();

  useEffect(() => {
    if (paymentForApportionment) {
      getPaymentsApportionments(paymentForApportionment);
    }
  }, [urlParamPaymentId]);

  const onExpandRow = (expanded: boolean, record: any) => {
    if (expanded) {
      addApportionmentTableRowKey(record.key);
    } else {
      deleteApportionmentTableRowKey(record.key);
    }
  };

  const updateCaseTable = (
    restProps: any,
    values: any,
    record: any,
    _amountKey: string,
    _amountTotalKey: string,
    _amountTotalByTextKey: string
  ) => {
    const {
      caseNo,
      paymentArItemNo,
      text,
      apportionId,
      vatCode,
      vatStatus,
      creditorAmount,
      bureauAmount,
      otherAmount,
      parentIndex,
      itemIndex,
      childIndex,
    } = record;
    const updatedTableData = values.caseTableData.map((item: any) => {
      if (item.caseNo == caseNo) {
        item.items.map((parent: any) => {
          if (parent.text == text) {
            {
              parent.children.map((child: any, cIndex: number) => {
                if (
                  child.paymentArItemNo == paymentArItemNo &&
                  child.apportionId == apportionId &&
                  child.childIndex == record.childIndex
                ) {
                  const _ChildAmount =
                    paymentApportionments.data.caseTableData[parentIndex]
                      ?.items[itemIndex]?.children[childIndex]?.amount;
                  const _ChildBalance =
                    paymentApportionments.data.caseTableData[parentIndex]
                      ?.items[itemIndex]?.children[childIndex]
                      ?.transactionBalance;
                  const amountTotal =
                    creditorAmount + bureauAmount + otherAmount;
                  child[_amountKey] = record[_amountKey]; // row input amount value
                  if (child.isProvision && cIndex > 0) {
                    parent.children[cIndex - 1].transactionBalance =
                      parent.children[cIndex - 1].transactionBalance -
                      (amountTotal - child.amount); // row balance
                  }
                  if (parent.children[cIndex + 1]?.isProvision) {
                    parent.children[cIndex + 1].transactionBalance =
                      _ChildAmount + _ChildBalance - amountTotal; // row balance
                  }
                  child.amount = amountTotal; // row mapped amount
                  child.transactionBalance =
                    _ChildAmount + _ChildBalance - child.amount; // row balance
                  parent.amount = sumBy(parent.children, PropertyKeys.AMOUNT); // parent mapped amount
                  item.total = sumBy(item.items, PropertyKeys.AMOUNT); // complete table total
                  parent.totalTransBalanceByText = sumBy(
                    parent.children,
                    PropertyKeys.TRANSACTION_BALANCE
                  );
                  parent[_amountTotalByTextKey] = sumBy(
                    parent.children,
                    _amountKey
                  );
                  item.balance = sumBy(
                    item.items,
                    TotalAmountByText.TRANSACTION_BALANCE_BY_TEXT
                  ); // balance column total
                  item[_amountTotalKey] = sumBy(
                    item.items,
                    _amountTotalByTextKey
                  ); // balance column total
                  (child.modifiedDate = moment()),
                    (child.modifiedUser = currentUser?.upn);

                  if (_amountKey == "bureauAmount" && bureauAmount == 0) {
                    parent.totalVatAmountByText -= record?.vatAmount;
                    parent.totalVatBasisByText -= record?.vatBasis;
                    parent.totalVatDeductedByText -= record?.vatDeducted;
                    parent.totalVatPaidByDebtorByText -=
                      record?.vatPaidByDebtor;
                    item.totalVatAmount -= record?.vatAmount;
                    item.totalVatBasis -= record?.vatBasis;
                    item.totalVatDeducted -= record?.vatDeducted;
                    item.totalVatPaidByDebtor -= record?.vatPaidByDebtor;
                    child.vatAmount = 0;
                    child.vatBasis = 0;
                    child.vatDeducted = 0;
                    child.vatPaidByDebtor = 0;
                  }
                }
              });
            }
          }
        });
      }
      return item;
    });
    const newRemainingValue =
      paymentForApportionment?.paymentAmount -
      sumBy(updatedTableData, PropertyKeys.TOTAL);
    restProps.setFieldValue("remainingAmount", newRemainingValue);
    restProps.setFieldValue("caseTableData", updatedTableData);
    checkChildChanged(isDirtyApportionment(values, paymentApportionments));
    if (
      _amountKey == AmountTypes.BUREAU_AMOUNT &&
      (((record?.itemTypeId == ItemTypeIds.MAIN_AMOUNT ||
        record?.itemTypeId == ItemTypeIds.MAIN_AMOUNT_INTEREST) &&
        record.isProvision) ||
        !(
          record?.itemTypeId == ItemTypeIds.MAIN_AMOUNT ||
          record?.itemTypeId == ItemTypeIds.MAIN_AMOUNT_INTEREST
        )) &&
      vatCode != null &&
      vatCode != 0 &&
      vatStatus != "" &&
      bureauAmount != null &&
      bureauAmount != 0
    ) {
      getVatAmounts({
        vatParams: {
          vatcode: vatCode,
          vatstatus: vatStatus,
          amount: bureauAmount,
          creditorNo: paymentForApportionment?.pid,
        },
        values: {
          ...values,
          caseTableData: updatedTableData,
          remainingAmount: newRemainingValue,
        },
        record: record,
      });
    }
  };

  const addProvisionRow = (
    restProps: any,
    values: any,
    record: any,
    _amountKey: string,
    _amountTotalKey: string,
    _amountTotalByTextKey: string
  ) => {
    const {
      caseNo,
      paymentArItemNo,
      text,
      vatCode,
      vatStatus,
      bureauAmount,
      childIndex,
    } = record;

    let newRecord = {};
    const updatedTableData = values.caseTableData.map((item: any) => {
      if (item.caseNo == caseNo) {
        item.items.map((parent: any) => {
          if (parent.text == text) {
            const hasProvisionRecord =
              parent.children.filter(
                (pro: any) =>
                  pro.isProvision && pro.invoiceNo == record.invoiceNo
              )?.length > 0;
            if (
              (record?.itemTypeId == ItemTypeIds.MAIN_AMOUNT ||
                record?.itemTypeId == ItemTypeIds.MAIN_AMOUNT_INTEREST) &&
              _amountKey == "bureauAmount" &&
              !hasProvisionRecord &&
              bureauAmount != 0
            ) {
              const amountValue = bureauAmount;
              const typeId = record?.itemTypeId;
              const noOfChildrens = parent.children.length;
              const mainPayment = { ...record, noOfChildrens, currentUser };
              newRecord = AddApportionmentReturnToDebtor.call({
                amountValue,
                typeId,
                mainPayment,
                text,
              });
              parent.children.push(newRecord);
              parent.children.filter(
                (child: any) =>
                  child.paymentArItemNo == paymentArItemNo &&
                  child.childIndex == childIndex
              )[0].bureauAmount = 0;
              parent.children.filter(
                (child: any) =>
                  child.paymentArItemNo == paymentArItemNo &&
                  child.childIndex == childIndex
              )[0].amount =
                parent.children.filter(
                  (child: any) =>
                    child.paymentArItemNo == paymentArItemNo &&
                    child.childIndex == childIndex
                )[0].amount - amountValue;
              parent.amount = sumBy(parent.children, PropertyKeys.AMOUNT); // parent mapped amount
              item.total = sumBy(item.items, PropertyKeys.AMOUNT); // complete table total
              parent.totalTransBalanceByText = sumBy(
                parent.children,
                PropertyKeys.TRANSACTION_BALANCE
              );
              parent[_amountTotalByTextKey] = sumBy(
                parent.children,
                _amountKey
              );
              item.balance = sumBy(
                item.items,
                TotalAmountByText.TRANSACTION_BALANCE_BY_TEXT
              ); // balance column total
              item[_amountTotalKey] = sumBy(item.items, _amountTotalByTextKey); // balance column total

              parent.children.map((child: any) => {
                if (
                  child.childIndex > record.childIndex &&
                  child.transactionText != TransactionTexts.PROVISION
                ) {
                  child.childIndex = child.childIndex + 1;
                }
                return child;
              });
              parent.children.sort(
                (a: any, b: any) => a.childIndex - b.childIndex
              );
            }
          }
        });
      }
      return item;
    });

    checkChildChanged(isDirtyApportionment(values, paymentApportionments));
    if (
      _amountKey == AmountTypes.BUREAU_AMOUNT &&
      vatCode != null &&
      vatCode != 0 &&
      vatStatus != "" &&
      bureauAmount != null &&
      bureauAmount != 0 &&
      (record?.itemTypeId == ItemTypeIds.MAIN_AMOUNT ||
        record?.itemTypeId == ItemTypeIds.MAIN_AMOUNT_INTEREST) &&
      _amountKey == "bureauAmount" &&
      !record.isProvision
    ) {
      getVatAmounts({
        vatParams: {
          vatcode: vatCode,
          vatstatus: vatStatus,
          amount: bureauAmount,
          creditorNo: paymentForApportionment?.pid,
        },
        values: {
          ...values,
          caseTableData: updatedTableData,
        },
        record: newRecord,
      });
    } else {
      restProps.setFieldValue("caseTableData", updatedTableData);
    }
  };

  const column: any = (restProps: any, item: any, values: any) => {
    const columns = [
      {
        title: "",
        dataIndex: "invoiceNo",
        key: "invoiceNo",
        render: (text: any, record: any) => {
          return record?.isParent ? (
            {
              children: (
                <div
                  key={record?.key}
                  data-testid={`group-text-${record?.key}`}
                >
                  <span className="mr-4 font-weight-bold">{record?.text}</span>
                </div>
              ),
              props: {
                colSpan: 6,
              },
              key: record.key,
            }
          ) : (
            <div style={{ fontSize: "0.7rem" }} className="d-flex flex-column">
              <div className="d-flex">
                <span className="mr-1">
                  {getSubText(record)} {record?.invoiceNo != "" && ":"}
                </span>
                {record?.invoiceNo != "" && (
                  <a
                    data-testid="invoice-no-link"
                    onClick={() => getRouteUrl.caseType(record?.caseNo)}
                  >
                    {record?.invoiceNo}
                  </a>
                )}
                {record?.siInvoiceAdded && (
                  <BoxIcons type={IconTypes.BOX_ICON} name="court-fee" />
                )}
              </div>
              <div className="d-flex">
                {record.amount == 0 && (
                  <$Tag className="font-sm cursor-pointer" color="geekblue">
                    {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.NOT_APPORTION")}
                  </$Tag>
                )}
                {record.isProvision && (
                  <$Tag className="font-sm cursor-pointer" color="geekblue">
                    {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PROVISION")}
                  </$Tag>
                )}
              </div>
            </div>
          );
        },
      },
      {
        title: () => (
          <>
            <div>
              <div>{t("US.COLLECTION.COMMON:COMMON.BALANCE")}</div>
              <div className="header-custom-amount">
                <$AmountLabel value={item.balance} />
              </div>
            </div>
          </>
        ),
        dataIndex: "transactionBalance",
        key: "transactionBalance",
        width: 160,
        align: "right",
        render: (text: any, record: any) => {
          return record?.isParent
            ? {
                props: {
                  colSpan: 0,
                },
              }
            : !(
                record.itemTypeId == ItemTypeIds.ROUNDING_OFF &&
                record?.transactionBalance == 0
              ) &&
                !record.isProvision && (
                  <div style={{ display: "grid" }} data-testid="balance-test">
                    <$AmountLabel value={record?.transactionBalance} />
                    {record?.transactionBalance < 0 && (
                      <span
                        data-testid="balance-validation"
                        style={{ color: "red", fontSize: 10 }}
                      >
                        {t(
                          "US.COLLECTION.ECONOMY:VALIDATIONS.CANNOT_EXCEED_THE_BALANCE"
                        )}
                      </span>
                    )}
                  </div>
                );
        },
      },
      {
        title: "",
        dataIndex: "vat",
        key: "vat",
        width: 60,
        render: (text: any, record: any) => {
          return record?.isParent ? (
            {
              props: {
                colSpan: 0,
              },
            }
          ) : (
            <>
              {record?.bureauAmount != 0 && record?.vatCode != 0 && (
                <$Popover
                  placement="bottomLeft"
                  trigger="hover"
                  content={
                    <div className="d-flex" data-testid="popover-content">
                      <div className="pym-tile gray-100 mr-2">
                        <div
                          className="font-weight-bold mb1"
                          data-testid="vat-amount-popOver"
                        >
                          <$AmountLabel value={record?.vatAmount} />
                        </div>
                        <div className="text-muted text-nowrap">
                          {t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_AMOUNT"
                          )}
                        </div>
                      </div>

                      <div className="pym-tile gray-100 mr-2">
                        <div
                          className="font-weight-bold mb1"
                          data-testid="vat-basis-popOver"
                        >
                          <$AmountLabel value={record?.vatBasis} />
                        </div>
                        <div className="text-muted text-nowrap">
                          {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_BASIS")}
                        </div>
                      </div>

                      <div className="pym-tile gray-100 mr-2">
                        <div
                          className="font-weight-bold mb1"
                          data-testid="vat-deducted-popOver"
                        >
                          <$AmountLabel value={record?.vatDeducted} />
                        </div>
                        <div className="text-muted text-nowrap">
                          {t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_DEDUCTED"
                          )}
                        </div>
                      </div>

                      <div className="pym-tile gray-100">
                        <div
                          className="font-weight-bold mb1"
                          data-testid="vat-paidByDebtor-popOver"
                        >
                          <$AmountLabel value={record?.vatPaidByDebtor} />
                        </div>
                        <div className="text-muted text-nowrap ">
                          {t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_PAID_BY_DEBTOR"
                          )}
                        </div>
                      </div>
                    </div>
                  }
                >
                  <span
                    className="font-lg font-weight-bold cursor-pointer"
                    style={{ color: "#3FAFA0" }}
                    data-testid="vat-popover-test"
                  >
                    {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT")}
                  </span>
                </$Popover>
              )}
            </>
          );
        },
      },
      {
        title: () => (
          <>
            <div>
              <div>{t("US.COLLECTION.COMMON:COMMON.CREDITOR")}</div>
              <div
                className="header-custom-amount"
                data-testid="creditor-amount-header"
              >
                <$AmountLabel value={item.totalCreditorAmount} />
              </div>
            </div>
          </>
        ),
        dataIndex: "creditorAmount",
        key: "creditorAmount",
        align: "right",
        width: 120,
        render: (text: any, record: any) => {
          return record?.isParent ? (
            {
              props: {
                colSpan: 0,
              },
            }
          ) : (
            <div
              className="text-right"
              data-testid={`creditor-amount-${record?.itemIndex}${record?.childIndex}`}
            >
              <$InputAmount
                currentLanguage={currentLanguage}
                currentCurrency={currentCurrency}
                min={0}
                placeholder="0,00"
                size="small"
                disabled={isDisableCreditorAmount(values, record)}
                name={`caseTableData[${record?.parentIndex}].items[${record?.itemIndex}].children[${record?.childIndex}].creditorAmount`}
                className="bui-number-input w-100"
                value={record?.creditorAmount}
                onChange={(amount: any) => {
                  updateCaseTable(
                    restProps,
                    values,
                    { ...record, creditorAmount: amount },
                    AmountTypes.CREDITOR_AMOUNT,
                    TotalAmountTypes.TOTAL_CREDITOR_AMOUNT,
                    TotalAmountByText.TOTAL_CREDITOR_AMOUNT_BY_TEXT
                  );
                }}
              />
            </div>
          );
        },
      },
      {
        title: () => (
          <>
            <div>
              <div>{t("US.COLLECTION.COMMON:COMMON.BUREAU")}</div>
              <div
                className="header-custom-amount"
                data-testid="bureau-amount-header"
              >
                <$AmountLabel value={item.totalBureauAmount} />
              </div>
            </div>
          </>
        ),
        dataIndex: "bureauAmount",
        key: "bureauAmount",
        align: "right",
        width: 120,
        render: (text: any, record: any) => {
          return record?.isParent ? (
            {
              props: {
                colSpan: 0,
              },
            }
          ) : (
            <div
              className="text-right"
              data-testid={`bureau-amount-${record?.itemIndex}${record?.childIndex}`}
            >
              <$InputAmount
                currentLanguage={currentLanguage}
                currentCurrency={currentCurrency}
                min={0}
                placeholder="0,00"
                size="small"
                disabled={isDisableBureauAmount(values, record, item)}
                name={`caseTableData[${record?.parentIndex}].items[${record?.itemIndex}].children[${record?.childIndex}].bureauAmount`}
                className="bui-number-input w-100"
                value={record?.bureauAmount}
                onChange={(amount: any) => {
                  updateCaseTable(
                    restProps,
                    values,
                    { ...record, bureauAmount: amount },
                    AmountTypes.BUREAU_AMOUNT,
                    TotalAmountTypes.TOTAL_BUREAU_AMOUNT,
                    TotalAmountByText.TOTAL_BUREAU_AMOUNT_BY_TEXT
                  );
                }}
                onBlur={() => {
                  !record.isProvision &&
                    addProvisionRow(
                      restProps,
                      values,
                      record,
                      AmountTypes.BUREAU_AMOUNT,
                      TotalAmountTypes.TOTAL_BUREAU_AMOUNT,
                      TotalAmountByText.TOTAL_BUREAU_AMOUNT_BY_TEXT
                    );
                }}
              />
            </div>
          );
        },
      },
      {
        title: () => (
          <>
            <div>
              <div>{t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.OTHER")}</div>
              <div
                className="header-custom-amount"
                data-testid="other-amount-header"
              >
                <$AmountLabel value={item.totalOtherAmount} />
              </div>
            </div>
          </>
        ),
        dataIndex: "otherAmount",
        key: "otherAmount",
        align: "right",
        width: 120,
        render: (text: any, record: any) => {
          return record?.isParent ? (
            {
              props: {
                colSpan: 0,
              },
            }
          ) : (
            <div
              className="text-right"
              data-testid={`other-amount-${record?.itemIndex}${record?.childIndex}`}
            >
              <$InputAmount
                currentLanguage={currentLanguage}
                currentCurrency={currentCurrency}
                min={0}
                placeholder="0,00"
                size="small"
                disabled={isDisableOtherAmount(values, record)}
                name={`caseTableData[${record?.parentIndex}].items[${record?.itemIndex}].children[${record?.childIndex}].otherAmount`}
                className="bui-number-input w-100"
                value={record?.otherAmount}
                onChange={(amount: any) => {
                  updateCaseTable(
                    restProps,
                    values,
                    { ...record, otherAmount: amount },
                    AmountTypes.OTHER_AMOUNT,
                    TotalAmountTypes.TOTAL_OTHER_AMOUNT,
                    TotalAmountByText.TOTAL_OTHER_AMOUNT_BY_TEXT
                  );
                }}
              />
            </div>
          );
        },
      },
      {
        title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED"),
        dataIndex: "amount",
        key: "amount",
        width: 120,
        align: "right",
        render: (text: any, record: any) => {
          return record?.isParent ? (
            {
              children: (
                <div key={record?.key}>
                  <div className="text-right font-weight-bold">
                    <$AmountLabel value={record?.amount} />
                  </div>
                </div>
              ),
              props: {
                colSpan: 1,
              },
            }
          ) : (
            <div className="text-right">
              <$AmountLabel value={record?.amount} />
            </div>
          );
        },
      },
      {
        title: t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UPDATE"),
        dataIndex: "update",
        width: 120,
        key: "update",
        render: (text: any, record: any) => {
          return record?.isParent ? (
            {
              props: {
                colSpan: 1,
              },
            }
          ) : (
            <div>
              {record?.modifiedDate != null ? (
                <$DateLabel value={record?.modifiedDate} />
              ) : (
                ""
              )}
              <$Tooltip
                placement="topLeft"
                title={
                  record?.modifiedUser != ""
                    ? record?.modifiedUser
                    : t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.SYSTEM")
                }
              >
                <div
                  style={{ maxWidth: "120px" }}
                  className="font-sm text-muted text-truncate"
                >
                  {record?.modifiedUser != ""
                    ? record?.modifiedUser
                    : t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.SYSTEM")}
                </div>
              </$Tooltip>
            </div>
          );
        },
      },
    ];
    return columns;
  };

  /**
   * Calls the updateApportionment function
   * and passes in the payload, filters, and paymentForApportionment variables as parameters.
   * @param {any} values - the form values
   * @param {any} actions - any =&gt; {setSubmitting: true}
   */
  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(true);
    const mainPaymentId = paymentForApportionment?.paymentId ?? 0;
    const payload = SaveApportionments.call({ ...values, mainPaymentId });
    const filters = GetPayments.call(parentFormValues as any);
    updateApportionment(payload, filters, paymentForApportionment);
  };

  return (
    <Formik
      id={"form"}
      initialValues={paymentApportionments.data}
      validateOnBlur
      validateOnChange
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        values,
        errors,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        resetForm,
        isValidating,
        dirty,
        ...restProps
      }: any) => (
        <>
          <div className="pym-de  d-flex align-items-start flex-column">
            <div
              style={{ overflowY: "auto", overflowX: "hidden" }}
              className="mb-auto w-100 p-3"
            >
              <$Row gutter={12} className="mb-2">
                <$Col span={6}>
                  <div className="pym-tile gray-200">
                    <div
                      className="font-weight-bold mb1"
                      data-testid="creditor-test"
                    >
                      <$AmountLabel
                        value={sumBy(
                          values.caseTableData,
                          TotalAmountTypes.TOTAL_CREDITOR_AMOUNT
                        )}
                      />
                    </div>
                    <div className="text-muted">
                      {t("US.COLLECTION.COMMON:COMMON.CREDITOR")}
                    </div>
                  </div>
                </$Col>
                <$Col span={6}>
                  <div className="pym-tile gray-200">
                    <div
                      className="font-weight-bold mb1"
                      data-testid="bureau-test"
                    >
                      <$AmountLabel
                        value={sumBy(
                          values.caseTableData,
                          TotalAmountTypes.TOTAL_BUREAU_AMOUNT
                        )}
                      />
                    </div>
                    <div className="text-muted">
                      {t("US.COLLECTION.COMMON:COMMON.BUREAU")}
                    </div>
                  </div>
                </$Col>
                <$Col span={6}>
                  <div className="pym-tile gray-200">
                    <div
                      className="font-weight-bold mb1"
                      data-testid="other-test"
                    >
                      <$AmountLabel
                        value={sumBy(
                          values.caseTableData,
                          TotalAmountTypes.TOTAL_OTHER_AMOUNT
                        )}
                      />
                    </div>
                    <div className="text-muted">
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.OTHER")}
                    </div>
                  </div>
                </$Col>
                <$Col span={6}>
                  <div className="pym-tile gray-200">
                    <div
                      className="font-weight-bold mb1"
                      data-testid="debtor-test"
                    >
                      <$AmountLabel
                        value={sumBy(
                          values.caseTableData,
                          TotalAmountTypes.TOTAL_DEBTOR_AMOUNT
                        )}
                      />
                    </div>
                    <div className="text-muted">
                      {t("US.COLLECTION.COMMON:COMMON.DEBTOR")}
                    </div>
                  </div>
                </$Col>
              </$Row>
              <$Row gutter={12} className="mb-3">
                <$Col span={6}>
                  <div className="pym-tile gray-100">
                    <div
                      className="font-weight-bold mb1"
                      data-testid="total-vat-amount-test"
                    >
                      <$AmountLabel
                        value={sumBy(
                          values.caseTableData,
                          VATAmountInfo.TOTAL_VAT_AMOUNT
                        )}
                      />
                    </div>
                    <div className="text-muted">
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_AMOUNT")}
                    </div>
                  </div>
                </$Col>
                <$Col span={6}>
                  <div className="pym-tile gray-100">
                    <div
                      className="font-weight-bold mb1"
                      data-testid="total-vat-basis-test"
                    >
                      <$AmountLabel
                        value={sumBy(
                          values.caseTableData,
                          VATAmountInfo.TOTAL_VAT_BASIS
                        )}
                      />
                    </div>
                    <div className="text-muted">
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_BASIS")}
                    </div>
                  </div>
                </$Col>
                <$Col span={6}>
                  <div className="pym-tile gray-100">
                    <div
                      className="font-weight-bold mb1"
                      data-testid="total-vat-deducted-test"
                    >
                      <$AmountLabel
                        value={sumBy(
                          values.caseTableData,
                          VATAmountInfo.TOTAL_VAT_DEDUCTED
                        )}
                      />
                    </div>
                    <div className="text-muted">
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_DEDUCTED")}
                    </div>
                  </div>
                </$Col>
                <$Col span={6}>
                  <div className="pym-tile gray-100">
                    <div
                      className="font-weight-bold mb1"
                      data-testid="total-vat-paidByDebtor-test"
                    >
                      <$AmountLabel
                        value={sumBy(
                          values.caseTableData,
                          VATAmountInfo.TOTAL_VAT_PAID_BY_DEBTOR
                        )}
                      />
                    </div>
                    <div className="text-muted">
                      {t(
                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_PAID_BY_DEBTOR"
                      )}
                    </div>
                  </div>
                </$Col>
              </$Row>

              <$Skeleton
                loading={paymentApportionments.isFetchingApportionments}
                active
                paragraph={{ rows: 1 }}
                className="p-3"
              >
                <FieldArray
                  name="caseTableData"
                  render={() =>
                    values.caseTableData != undefined &&
                    values.caseTableData.map((item: any, index: number) => {
                      const { caseNo, total, items } = item;
                      return (
                        caseNo != "" && (
                          <div
                            key={index}
                            data-testid={`caseNo-set-test-${index}`}
                          >
                            <div className="d-flex justify-content-between">
                              <div
                                className="d-flex font-weight-bold font-lg"
                                data-testid="caseNo-test"
                              >
                                <span className="mr-2">
                                  {t("US.COLLECTION.COMMON:COMMON.CASE")}
                                </span>
                                <a
                                  data-testid="case-id-link"
                                  onClick={() => getRouteUrl.caseType(caseNo)}
                                >
                                  {caseNo}
                                </a>
                              </div>
                              <div
                                className="d-flex font-weight-bold font-lg"
                                data-testid="total-test"
                              >
                                <span className="mr-1">
                                  {t("US.COLLECTION.COMMON:COMMON.TOTAL")}:
                                </span>
                                <b>
                                  <$AmountLabel value={total} />
                                </b>
                              </div>
                            </div>
                            <$Table
                              data-testid={`apportionmentTable-${index}`}
                              columns={column(restProps, item, values)}
                              rowKey={(record) => record?.key}
                              dataSource={items.filter(
                                ({ text }: any) => typeof text !== undefined
                              )}
                              className="table-striped header-custom-tag"
                              bordered
                              pagination={{ hideOnSinglePage: true }}
                              size="small"
                              expandable={{
                                defaultExpandedRowKeys:
                                  paymentApportionments.activeCollapseRawKeys,
                                expandedRowKeys:
                                  paymentApportionments.activeCollapseRawKeys,
                                onExpand: onExpandRow,
                              }}
                            />
                          </div>
                        )
                      );
                    })
                  }
                />
                {values.caseTableData?.length == 0 && (
                  <$Empty image={$Empty.PRESENTED_IMAGE_SIMPLE} />
                )}
              </$Skeleton>
            </div>
            {values?.returnFeeVat?.amount != undefined &&
              values?.returnFeeVat?.amount != 0 &&
              values?.returnFeeVat?.amount !==
                values?.returnFeeVat?.advancedReturnFee && (
                <div className="d-flex w-100">
                  <div className="flex-fill p-1">
                    <$Alert
                      className="w-100"
                      message={
                        <span data-testid="alert-returnfee">
                          {t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.STANDARD_RETURN_FEE_IS"
                          )}{" "}
                          <$AmountLabel
                            value={values?.returnFeeVat?.advancedReturnFee}
                          />
                        </span>
                      }
                      type="info"
                      showIcon
                    />
                  </div>
                </div>
              )}
            <div className="pym-de-footer">
              <$Form layout="vertical">
                <div className="d-flex">
                  <div
                    style={{ backgroundColor: "#FBFFCC" }}
                    className="p-1 px-3"
                  >
                    <div className="text-muted">
                      {" "}
                      {t(
                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REMAINING_AMOUNT"
                      )}{" "}
                    </div>
                    <div
                      className="font-weight-bold font-lg"
                      style={{ display: "grid" }}
                      data-testid="remaining-amount-test"
                    >
                      <$AmountLabel
                        value={
                          values.remainingAmount != undefined
                            ? values.remainingAmount
                            : 0
                        }
                      />
                      {values.remainingAmount != undefined &&
                        values.remainingAmount < 0 && (
                          <span
                            data-testid="exceed-validation"
                            style={{ color: "red", fontSize: 10 }}
                          >
                            {t(
                              "US.COLLECTION.ECONOMY:VALIDATIONS.CANNOT_EXCEED_THE_BALANCE"
                            )}
                          </span>
                        )}
                    </div>
                  </div>
                  <div className="p-1 px-3" data-testid="exceeded-amount-test">
                    <$InputAmount
                      currentLanguage={currentLanguage}
                      currentCurrency={currentCurrency}
                      label={t(
                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED_AMOUNT"
                      )}
                      min={0}
                      placeholder="0,00"
                      size="small"
                      className="w-100"
                      disabled={values.isRemitted}
                      value={
                        values.exceededAmount != undefined
                          ? values.exceededAmount
                          : 0
                      }
                      onChange={(val: any) => {
                        if (values.exceededAmount != val) {
                          restProps.setFieldValue("exceededAmount", val);
                          const newCaseTableData =
                            updateCaseTableWithFooterChanges(
                              { ...paymentForApportionment, currentUser },
                              values,
                              val,
                              TransactionTexts.EXCEEDED_AMOUNT,
                              ItemTypeIds.EXCEEDED_AMOUNT
                            );
                          restProps.setFieldValue(
                            "caseTableData",
                            newCaseTableData
                          );
                          checkChildChanged(
                            paymentApportionments.initialData.exceededAmount !=
                              val
                          );
                        }
                        const newRemainingValue =
                          paymentForApportionment?.paymentAmount -
                          (sumBy(
                            values.caseTableData.filter(
                              (f: any) => f.caseNo != ""
                            ),
                            "total"
                          ) +
                            val +
                            values.returnToDebtor +
                            values.returnFeeVat?.amount);
                        restProps.setFieldValue(
                          "remainingAmount",
                          newRemainingValue
                        );
                      }}
                      name="exceededAmount"
                    />
                  </div>
                  <div className="mr-3 mt-1" data-testid="roundOf-amount-test">
                    <div className="d-flex justify-content-between align-items-center ant-form-item-label">
                      <div>
                        {t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ROUND_OF_AMOUNT"
                        )}
                      </div>
                      <div className="mx-2">
                        <$Popover
                          placement="topLeft"
                          data-testid="roundOf-vat"
                          trigger="hover"
                          content={
                            <div className="d-flex">
                              <div className="pym-tile gray-100 mr-2">
                                <div
                                  className="font-weight-bold mb1"
                                  data-testid="rof-vatAmount-test"
                                >
                                  <$AmountLabel
                                    value={
                                      values.roundOffApportionment?.vatAmount
                                    }
                                  />
                                </div>
                                <div className="text-muted text-nowrap">
                                  {t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_AMOUNT"
                                  )}
                                </div>
                              </div>

                              <div className="pym-tile gray-100 mr-2">
                                <div
                                  className="font-weight-bold mb1"
                                  data-testid="rof-vatBasis-test"
                                >
                                  <$AmountLabel
                                    value={
                                      values.roundOffApportionment?.vatBasis
                                    }
                                  />
                                </div>
                                <div className="text-muted text-nowrap">
                                  {t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_BASIS"
                                  )}
                                </div>
                              </div>

                              <div className="pym-tile gray-100 mr-2">
                                <div
                                  className="font-weight-bold mb1"
                                  data-testid="rof-vatDeducted-test"
                                >
                                  <$AmountLabel
                                    value={
                                      values.roundOffApportionment?.vatDeducted
                                    }
                                  />
                                </div>
                                <div className="text-muted text-nowrap">
                                  {t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_DEDUCTED"
                                  )}
                                </div>
                              </div>

                              <div className="pym-tile gray-100">
                                <div
                                  className="font-weight-bold mb1"
                                  data-testid="rof-vatPaidToDebtor-test"
                                >
                                  <$AmountLabel
                                    value={
                                      values.roundOffApportionment
                                        ?.vatPaidByDebtor
                                    }
                                  />
                                </div>
                                <div className="text-muted text-nowrap">
                                  {t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_PAID_BY_DEBTOR"
                                  )}
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <span
                            className="font-weight-bold cursor-pointer"
                            style={{ color: "#3FAFA0" }}
                            data-testid="rof-vat-test"
                          >
                            {" "}
                            {t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT"
                            )}{" "}
                          </span>
                        </$Popover>
                      </div>
                      <div data-testid={"info-icon"}>
                        {values?.roundOffApportionment?.minimumExceedAmount !=
                          undefined && (
                          <$Tooltip
                            placement="topLeft"
                            title={
                              t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAXIMUM_ROUNDING_OFF_AMOUNT_IS_ "
                              ) +
                              values?.roundOffApportionment?.minimumExceedAmount
                            }
                          >
                            <InfoCircleOutlined data-testid="icon-infor" />
                          </$Tooltip>
                        )}
                      </div>
                    </div>
                    <$InputAmount
                      currentLanguage={currentLanguage}
                      currentCurrency={currentCurrency}
                      min={0}
                      max={values?.roundOffApportionment?.minimumExceedAmount}
                      placeholder="0,00"
                      size="small"
                      className="w-100"
                      disabled={values.isRemitted}
                      value={
                        values.roundOffApportionment != undefined
                          ? values.roundOffApportionment.amount
                          : 0
                      }
                      name="roundOffAmount"
                      onChange={(val: any) => {
                        if (values.roundOffApportionment?.amount != val) {
                          restProps.setFieldValue("roundOffApportionment", {
                            ...values.roundOffApportionment,
                            amount: val,
                          });
                          const newCaseTableData =
                            updateCaseTableWithFooterChanges(
                              { ...paymentForApportionment, currentUser },
                              values,
                              val,
                              TransactionTexts.ROUNDING_OFF,
                              ItemTypeIds.ROUNDING_OFF
                            );
                          addApportionmentTableRowKey(
                            getExpandedActiveRowKeys(
                              newCaseTableData.filter(
                                (itm: any) => itm.caseNo != ""
                              )[0]
                            )
                          );
                          restProps.setFieldValue(
                            "caseTableData",
                            newCaseTableData
                          );
                          const newRemainingValue =
                            paymentForApportionment?.paymentAmount -
                            (sumBy(
                              newCaseTableData.filter(
                                (f: any) => f.caseNo != ""
                              ),
                              "total"
                            ) +
                              values.exceededAmount +
                              values.returnToDebtor +
                              values.returnFeeVat?.amount);
                          if (val != 0 && val != null) {
                            newCaseTableData.map((newData: any) => {
                              newData.items.map((nItem: any) => {
                                if (
                                  nItem.text == TransactionTexts.ROUNDING_OFF
                                ) {
                                  restProps.setFieldValue(
                                    "roundOffApportionment",
                                    {
                                      ...values.roundOffApportionment,
                                      ...nItem.children[0],
                                    }
                                  );
                                }
                              });
                            });
                          } else {
                            restProps.setFieldValue("roundOffApportionment", {
                              ...values.roundOffApportionment,
                              amount: val,
                              bureauAmount: val,
                              vatAmount: 0,
                              vatBasis: 0,
                              vatDeducted: 0,
                              vatPaidByDebtor: 0,
                            });
                          }
                          restProps.setFieldValue(
                            "remainingAmount",
                            newRemainingValue
                          );
                          checkChildChanged(
                            values.roundOffApportionment?.amount != val
                          );
                        }
                      }}
                      onBlur={() => {
                        if (values.roundOffApportionment != undefined) {
                          const {
                            roundingOffVATCode,
                            creditorVATStatus,
                            amount,
                          } = values.roundOffApportionment;
                          if (
                            roundingOffVATCode != null &&
                            creditorVATStatus != "" &&
                            amount != null &&
                            amount != 0
                          ) {
                            getVatAmounts({
                              vatParams: {
                                vatcode: roundingOffVATCode,
                                vatstatus: VatStatus.NON_REFUNDABLE,
                                amount: amount,
                                creditorNo: paymentForApportionment?.pid,
                              },
                              values: { ...values },
                              record: values.roundOffApportionment,
                            });
                          }
                        }
                      }}
                    />
                  </div>

                  <$Divider type="vertical" style={{ height: "70px" }} />
                  <div className="p-1 px-3 flex-fill d-flex justify-content-end">
                    <div
                      className="mr-4"
                      data-testid="returnToDebtor-amount-test"
                    >
                      <$InputAmount
                        currentLanguage={currentLanguage}
                        currentCurrency={currentCurrency}
                        label={t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR"
                        )}
                        min={0}
                        placeholder="0,00"
                        size="small"
                        disabled={values.isRemitted}
                        value={
                          values.returnToDebtor != undefined
                            ? values.returnToDebtor
                            : 0
                        }
                        name="returnToDebtor"
                        onChange={(val: any) => {
                          if (values.returnToDebtor != val) {
                            restProps.setFieldValue("returnToDebtor", val);
                            const newCaseTableData =
                              updateCaseTableWithFooterChanges(
                                { ...paymentForApportionment, currentUser },
                                values,
                                val,
                                TransactionTexts.RETURN_TO_DEBTOR,
                                ItemTypeIds.RETURN_TO_DEBTOR
                              );
                            restProps.setFieldValue(
                              "caseTableData",
                              newCaseTableData
                            );
                            checkChildChanged(
                              paymentApportionments.initialData
                                .returnToDebtor != val
                            );
                          }
                          const newRemainingValue =
                            paymentForApportionment?.paymentAmount -
                            (sumBy(
                              values.caseTableData.filter(
                                (f: any) => f.caseNo != ""
                              ),
                              PropertyKeys.TOTAL
                            ) +
                              val +
                              values.exceededAmount +
                              values.returnFeeVat?.amount);
                          restProps.setFieldValue(
                            "remainingAmount",
                            newRemainingValue
                          );
                        }}
                      />
                    </div>
                    <div className="mr-4" data-testid="accountNo-test">
                      <$InputAccountNo
                        name="accountNo"
                        label={t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ACCOUNT_NO"
                        )}
                        size="small"
                        type="text"
                        disabled={values.isRemitted}
                        onChange={(e) => {
                          restProps.setFieldValue("accountNo", e.target.value);
                          checkChildChanged(
                            paymentApportionments.initialData.accountNo !=
                              e.target.value
                          );
                        }}
                      />
                    </div>
                    <div
                      className="mr-4 "
                      style={{ width: 150 }}
                      data-testid="returnFee-amount-test"
                    >
                      <div className="d-flex justify-content-between ant-form-item-label">
                        <span>
                          {t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_FEE"
                          )}
                        </span>
                        <$Popover
                          placement="topLeft"
                          data-testid="vat-test-pop"
                          trigger="hover"
                          content={
                            <div className="d-flex">
                              <div className="pym-tile gray-100 mr-2">
                                <div
                                  className="font-weight-bold mb1"
                                  data-testid="rd-vatAmount-test"
                                >
                                  <$AmountLabel
                                    value={values.returnFeeVat?.vatAmount}
                                  />
                                </div>
                                <div className="text-muted text-nowrap">
                                  {t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_AMOUNT"
                                  )}
                                </div>
                              </div>

                              <div className="pym-tile gray-100 mr-2">
                                <div
                                  className="font-weight-bold mb1"
                                  data-testid="rd-vatBasis-test"
                                >
                                  <$AmountLabel
                                    value={values.returnFeeVat?.vatBasis}
                                  />
                                </div>
                                <div className="text-muted text-nowrap">
                                  {t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_BASIS"
                                  )}
                                </div>
                              </div>

                              <div className="pym-tile gray-100 mr-2">
                                <div
                                  className="font-weight-bold mb1"
                                  data-testid="rd-vatDeducted-test"
                                >
                                  <$AmountLabel
                                    value={values.returnFeeVat?.vatDeducted}
                                  />
                                </div>
                                <div className="text-muted text-nowrap">
                                  {t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_DEDUCTED"
                                  )}
                                </div>
                              </div>

                              <div className="pym-tile gray-100">
                                <div
                                  className="font-weight-bold mb1"
                                  data-testid="rd-vatPaidToDebtor-test"
                                >
                                  <$AmountLabel
                                    value={values.returnFeeVat?.vatPaidByDebtor}
                                  />
                                </div>
                                <div className="text-muted text-nowrap">
                                  {t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT_PAID_BY_DEBTOR"
                                  )}
                                </div>
                              </div>
                            </div>
                          }
                        >
                          <span
                            className="font-weight-bold cursor-pointer"
                            style={{ color: "#3FAFA0" }}
                            data-testid="vat-test"
                          >
                            {" "}
                            {t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VAT"
                            )}{" "}
                          </span>
                        </$Popover>
                      </div>
                      <$InputAmount
                        currentLanguage={currentLanguage}
                        currentCurrency={currentCurrency}
                        formItem
                        min={0}
                        placeholder="0,00"
                        size="small"
                        disabled={values.isRemitted}
                        value={
                          values.returnFeeVat != undefined
                            ? values.returnFeeVat.amount
                            : 0
                        }
                        name="returnFee"
                        className="w-100"
                        onChange={(val: any) => {
                          if (values.returnFeeVat?.amount != val) {
                            const newCaseTableData =
                              updateCaseTableWithFooterChanges(
                                { ...paymentForApportionment, currentUser },
                                values,
                                val,
                                TransactionTexts.RETURN_TO_DEBTOR,
                                ItemTypeIds.RETURN_FEE
                              );
                            restProps.setFieldValue(
                              "caseTableData",
                              newCaseTableData
                            );
                            checkChildChanged(
                              paymentApportionments.initialData.returnFeeVat
                                ?.amount != val
                            );
                          }
                          const newRemainingValue =
                            paymentForApportionment?.paymentAmount -
                            (sumBy(
                              values.caseTableData.filter(
                                (f: any) => f.caseNo != ""
                              ),
                              PropertyKeys.TOTAL
                            ) +
                              val +
                              values.returnToDebtor +
                              values.exceededAmount);
                          restProps.setFieldValue(
                            "remainingAmount",
                            newRemainingValue
                          );
                          if (val != 0) {
                            restProps.setFieldValue("returnFeeVat", {
                              ...values.returnFeeVat,
                              amount: val,
                            });
                          } else {
                            restProps.setFieldValue("returnFeeVat", {
                              ...values.returnFeeVat,
                              vatAmount: 0,
                              vatBasis: 0,
                              vatDeducted: 0,
                              vatPaidByDebtor: 0,
                              amount: 0,
                            });
                          }
                        }}
                        onBlur={() => {
                          if (
                            values.returnFeeVat != undefined &&
                            values.returnFeeVat !== ""
                          ) {
                            const { vatCode, creditorVATStatus, amount } =
                              values.returnFeeVat;
                            if (
                              vatCode != null &&
                              creditorVATStatus != "" &&
                              amount != null &&
                              amount != 0
                            ) {
                              getVatAmountsForReturnFee({
                                vatParams: {
                                  vatcode: vatCode,
                                  vatstatus: VatStatus.NON_REFUNDABLE,
                                  amount: values.returnFeeVat.amount,
                                  creditorNo: paymentForApportionment?.pid,
                                },
                                values,
                              });
                            }
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </$Form>
            </div>
          </div>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Popconfirm
                title={t("COMMON.SURE_TO_PROCEED_?")}
                placement="topLeft"
                data-testid="pop-confirm"
                onConfirm={() => handleSubmit()}
                okText={t("COMMON.YES")}
                okButtonProps={{
                  disabled: isDisabled(values, paymentApportionments),
                }}
                cancelText={t("COMMON.NO")}
                disabled={isDisabled(values, paymentApportionments)}
              >
                <$Button
                  className="mr-2"
                  type="primary"
                  data-testid="confirm-button"
                  disabled={isDisabled(values, paymentApportionments)}
                  loading={paymentApportionments.isUpdatingApportionments}
                >
                  {t("US.COLLECTION.COMMON:COMMON.CONFIRM")}
                </$Button>
              </$Popconfirm>
              <$Popconfirm
                title={t(
                  "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ARE_YOU_SURE_YOU_WANT_TO_RESET_THE_VIEW_?"
                )}
                placement="topLeft"
                disabled={!isDirtyApportionment(values, paymentApportionments)}
                onConfirm={() => {
                  getPaymentsApportionments(paymentForApportionment);
                }}
                okText={t("COMMON.YES")}
                cancelText={t("COMMON.NO")}
              >
                <$Button
                  className="mr-2"
                  type="default"
                  data-testid="reset-button"
                  disabled={
                    !isDirtyApportionment(values, paymentApportionments)
                  }
                >
                  {t("US.COLLECTION.COMMON:COMMON.RESET")}
                </$Button>
              </$Popconfirm>
              {!isDirtyApportionment(values, paymentApportionments) && (
                <$Button
                  data-testid="cancel-button"
                  onClick={() => {
                    getPaymentsApportionments(paymentForApportionment);
                    changeDrawerInfo({
                      title: "",
                      visible: false,
                    });
                  }}
                >
                  {" "}
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}{" "}
                </$Button>
              )}
              {isDirtyApportionment(values, paymentApportionments) && (
                <$Popconfirm
                  title={t(
                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?"
                  )}
                  placement="topLeft"
                  onConfirm={() => {
                    getPaymentsApportionments(paymentForApportionment);
                    changeDrawerInfo({
                      title: "",
                      visible: false,
                    });
                  }}
                  okText={t("COMMON.YES")}
                  cancelText={t("COMMON.NO")}
                >
                  <$Button data-testid="cancel-button">
                    {" "}
                    {t("US.COLLECTION.COMMON:COMMON.CANCEL")}{" "}
                  </$Button>
                </$Popconfirm>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { managePayment, common } = state;
  const { currentLanguage, currentCurrency } = common;
  const { paymentApportionments, payments } = managePayment;
  return {
    paymentApportionments,
    currentLanguage,
    currentCurrency,
    payments,
  };
};

const mapDispatchToProps = {
  getPaymentsApportionments,
  getVatAmounts,
  getVatAmountsForReturnFee,
  updateApportionment,
  changeDrawerInfo,
  addApportionmentTableRowKey,
  deleteApportionmentTableRowKey,
};
const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connect(mapStateToProps, mapDispatchToProps)(PaymentDetails);
