import moment from "moment";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";

export function SaveJournalEntry(this: any) {
  const requestObject = this.values?.values?.map((rowValue: any) => {
    const {
      accountId,
      journalId,
      creditorAmount,
      debitAmount,
      voucher,
      caseOrSubcase,
      text,
    } = rowValue ?? {};

    const ledgerAccount = this.ledgerAccountList.data.find((account: any) => {
      return account?.accountId === accountId;
    });

    return {
      journalId,
      ledgerAccountNo: ledgerAccount?.accountNo ? ledgerAccount?.accountNo : "",
      ledgerAccountID: accountId,
      amount:
        creditorAmount == 0
          ? Number(-debitAmount.toFixed(2))
          : Number(creditorAmount.toFixed(2)),
      datePaid: moment().format(DATE_FORMAT_FOR_SERVER),
      ledgerText: text,
      caseNo: Number(caseOrSubcase),
      voucherNo: Number(voucher),
    };
  });

  return requestObject;
}
