import {
  ITransferPayment,
  ITransfer,
  IPaymentDistribution,
} from "../interface";
import { PaymentStates } from "us.collection.economy/constants/Payments";

export function SaveTransfer(this: ITransfer) {
  let transfer: Array<ITransferPayment> | undefined;
  let isExd = this.dsbKey == "exceededSum";
  let payState = isExd ? "Exceed" : PaymentStates.UNKNOWN;

  if (!this.isBulk) {
    transfer = [
      {
        paymentAritemNo: this.tansfer.paymentAritemNo,
        paymentId: this.tansfer.paymentId,
        returnedAmount: this.tansfer.returnedAmount,
        returnFee: this.tansfer.returnFee,
        accountNo: this.tansfer.accountNo,
        paymentStatus: payState,
      },
    ];
  } else {
    transfer = this.bulk?.paymentsWithAccounts.map((i: any) => {
      const itemKey = i?.exceededSum > 0 ? "exceededSum" : "unknownSum";
      const itemStatus =
        i?.exceededSum > 0 ? PaymentStates.EXCEEDED : PaymentStates.UNKNOWN;
      const perReturnFee = i?.[itemKey] <= this.perReturnFee ? i?.[itemKey] : this.perReturnFee
      return {
        paymentAritemNo: i.paymentDistribution?.filter(
          (payment: IPaymentDistribution) =>
            payment.paymentStatus === itemStatus
        )[0].arItemNo,
        paymentId: i.paymentId,
        returnedAmount: Number((i?.[itemKey] - perReturnFee).toFixed(2)),
        returnFee: perReturnFee,
        accountNo: i.accountNo,
        paymentStatus:
          itemStatus == PaymentStates.EXCEEDED ? "Exceed" : itemStatus,
      };
    });
  }
  return transfer;
}
