import React from "react";
import { useTranslation } from "react-i18next";
import {
  $AmountLabel,
  $Collapse,
  $Divider,
  $Tag,
  $Tooltip,
  $Button,
} from "us.common/components";
import { getRouteUrl } from "us.helper";
import { BoxIcons, IconTypes } from "us.icons";
import { PaymentItems } from "../PaymentItems";
import { Row } from "../Row";
import { ActionsBar } from "../ActionsBar";
import { ActionTypes } from "us.collection.economy/components/ManagePayments/interface";

interface Props {
  items: Array<any>;
  amount: number;
  onRevertAll: <D>(item: D) => void;
  onRevertItem: <D>(item: D) => void;
  withBreakDown?: boolean;
}

const MappedWithOther: React.FC<Props> = ({
  items,
  amount,
  onRevertAll,
  onRevertItem,
  withBreakDown = false,
}) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="d-flex align-items-center mt-4 mb-2">
        <div className="mr-2">
          <ActionsBar
            actions={[ActionTypes.REVERT_PAYMENT]}
            onAction={onRevertAll}
            uniqueRevertId={-100}
          />
        </div>
        <div className="flex-fill ">
          <$Divider
            className="mt-0 mb-0 map-payment-divider"
            orientation="right"
          >
            <$Tag className="tag-status-mapped">
              <div className="d-flex justify-content-between">
                <span className="mr-5">
                  {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED")}
                </span>
                <span data-testid="mapped-amount">
                  <$AmountLabel value={amount} />
                </span>
              </div>
            </$Tag>
          </$Divider>
        </div>
      </div>
      {withBreakDown &&
        items?.map((caseItem: any, index: number) => {
          const { caseNo, amount, items, caseId, caseType } = caseItem;
          return (
            <div key={index} className="mt-2">
              <div className="d-flex justify-content-between map-item-font-sm mb-1 pr-2">
                <span className="text-muted">
                  {`${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NO")}: `}
                  <a
                    href={"javascript:void(0)"}
                    onClick={() => getRouteUrl.caseType(caseNo)}
                    className="mr-3"
                  >
                    {caseNo}
                  </a>
                </span>
                <span className="text-muted text-right">
                  {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED_AMOUNT")}
                </span>
              </div>
              <$Collapse className="collapsed-sm">
                <$Collapse.Panel
                  key={1}
                  header={
                    <div className="d-flex justify-content-between map-item-font-sm">
                      <div className="font-weight-bold">
                        {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAIN_AMOUNT")}
                      </div>
                      <div className="font-weight-bold">
                        <$AmountLabel value={amount} />
                      </div>
                    </div>
                  }
                >
                  <div className="map-item-table">
                    {items?.map((item: any) => {
                      const {
                        caseNo,
                        amount,
                        caseType,
                        caseId,
                        transactionItemTypeId,
                      } = item;
                      return (
                        <Row
                          key={caseNo}
                          amount={amount}
                          caseId={caseId}
                          caseType={caseType}
                          caseNo={caseNo}
                          itemTypeId={transactionItemTypeId}
                          label={t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NO"
                          )}
                          onRevert={() => onRevertItem(item)}
                        />
                      );
                    })}
                  </div>
                </$Collapse.Panel>
              </$Collapse>
            </div>
          );
        })}
      {!withBreakDown && <PaymentItems payments={items} />}
    </>
  );
};

export default MappedWithOther;
