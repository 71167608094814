import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { InitialGroup } from "us.collection.economy/components/ItemTypes/Constants";
import { connect, ConnectedProps } from "react-redux";
import { useFormikContext } from "formik";
import { IRootState } from "us.collection/interfaces";
import { IItemTypeFilter } from "../../Interfaces";

const { $Affix, $Select, $Form, $Switch, $Input, $Divider } = Common.Components;

/**
 * @description - Filter component for search all available item types.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2992242726/Manage+Item+Types+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 20/06/2022
 */
const ItemTypeFilter: React.FC<IItemTypeFilter & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext<any>();

  const { transactionGroups, onChange } = props;

  return (
    <$Affix offsetTop={95}>
      <aside className="item-type-side">
        <$Form layout="vertical" autocomplete="off">
          <$Input
            name="itemTypeName"
            className="mb-3"
            size="small"
            allowClear
            label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.ITEM_TYPE_NAME")}
            placeholder={t("US.COLLECTION.ECONOMY:PLACE_HOLDERS.EX_COURT_FEE")}
            onChange={(e: any) => {
              setFieldValue("itemTypeName", e.target.value);
              !e.target.value && onChange({ itemTypeName: e.target.value });
            }}
            onPressEnter={() => {
              values.itemTypeName &&
                onChange({ itemTypeName: values?.itemTypeName });
            }}
            formitem={{
              extra: values?.itemTypeName
                ? t("US.COMMON:COMMON.PRESS_ENTER_TO_SEARCH")
                : null,
            }}
          />
          <$Input
            name="transactionCode"
            size="small"
            allowClear
            label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.TRANSACTION_CODE")}
            placeholder={t("US.COLLECTION.ECONOMY:PLACE_HOLDERS.EX_CO")}
            onChange={(e: any) => {
              setFieldValue("transactionCode", e.target.value);
              !e.target.value && onChange({ transactionCode: e.target.value });
            }}
            onPressEnter={() => {
              values.transactionCode &&
                onChange({ transactionCode: values?.transactionCode });
            }}
            formitem={{
              extra: values?.transactionCode
                ? t("US.COMMON:COMMON.PRESS_ENTER_TO_SEARCH")
                : null,
            }}
          />

          <$Divider />
          <$Select
            name="transactionGroup"
            mode="multiple"
            className="mb-4"
            formitem={{
              label: t("US.COLLECTION.ECONOMY:ITEM_TYPE.TRANSACTION_GROUP"),
            }}
            size="small"
            options={transactionGroups.data}
            optionValue="transactionGroupId"
            disabled={transactionGroups.isLoading}
            loading={transactionGroups.isLoading}
            defaultValue={InitialGroup.ALL}
            optionText="transactionGroupName"
            onSelect={(selectedGroupId: number) => {
              if (selectedGroupId == InitialGroup.ALL) {
                setFieldValue("transactionGroup", [InitialGroup.ALL]);
                onChange({ transactionGroup: [InitialGroup.ALL] });
              } else {
                const groups = [
                  ...values?.transactionGroup,
                  selectedGroupId,
                ].filter((groupId: number) => groupId !== InitialGroup.ALL);
                setFieldValue("transactionGroup", groups);
                onChange({ transactionGroup: groups });
              }
            }}
            onDeselect={(selectedGroupId: number) => {
              onChange({
                transactionGroup: values?.transactionGroup.filter(
                  (groupId: number) => groupId !== selectedGroupId
                ),
              });
            }}
            onSearchBy={["transactionGroupName", "transactionGroupCode"]}
          />
          <div className="d-flex align-items-center justify-content-between mb-1">
            <label>{t("US.COLLECTION.ECONOMY:ITEM_TYPE.PAYMENT")}</label>
            <$Switch
              size="small"
              name="payment"
              dataTestid={"payment-toggle"}
              checked={values.payment}
              onChange={(isPayment: boolean) => {
                setFieldValue("payment", isPayment);
                onChange({ payment: isPayment });
              }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between mb-1">
            <label>{t("US.COLLECTION.ECONOMY:ITEM_TYPE.CANCELLATION")}</label>
            <$Switch
              size="small"
              name="cancellation"
              dataTestid={"cancellation-toggle"}
              checked={values.cancellation}
              onChange={(isCancellation: boolean) => {
                setFieldValue("cancellation", isCancellation);
                onChange({ cancellation: isCancellation });
              }}
            />
          </div>
          <div className="d-flex align-items-center justify-content-between">
            <label>{t("US.COLLECTION.ECONOMY:ITEM_TYPE.COST_ITEM")}</label>
            <$Switch
              size="small"
              name="other"
              dataTestid={"other-toggle"}
              checked={values.other}
              onChange={(isOther: boolean) => {
                setFieldValue("other", isOther);
                onChange({ other: isOther });
              }}
            />
          </div>
        </$Form>
      </aside>
    </$Affix>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { itemType } = state;
  const { transactionGroups } = itemType;

  return {
    transactionGroups,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypeFilter);
