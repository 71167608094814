import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    accountNo: Yup.number()
      .required("US.COLLECTION.ECONOMY:VALIDATIONS.ACCOUNT_NO_IS_REQUIRED")
      .moreThan(0, "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_ACCOUNT_NO"),
    accountName: Yup.string().required(
      "US.COLLECTION.ECONOMY:VALIDATIONS.ACCOUNT_NAME_IS_REQUIRED"
    ),
    description: Yup.string().required(
      "US.COLLECTION.ECONOMY:VALIDATIONS.DESCRIPTION_IS_REQUIRED"
    ),
  });
};
