export enum ProvisionProfiles {
  SEARCH_PROVISION_PROFILES = "SEARCH_PROVISION_PROFILES",
  SEARCH_PROVISION_PROFILES_SUCCESS = "SEARCH_PROVISION_PROFILES_SUCCESS",
  SEARCH_PROVISION_PROFILES_FAIL = "SEARCH_PROVISION_PROFILES_FAIL",

  GET_PROVISION_PROFILES = "GET_PROVISION_PROFILES",
  GET_PROVISION_PROFILES_SUCCESS = "GET_PROVISION_PROFILES_SUCCESS",
  GET_PROVISION_PROFILES_FAIL = "GET_PROVISION_PROFILES_FAIL",

  DELETE_PROVISION_PROFILE = "DELETE_PROVISION_PROFILE",
  UPDATE_PROVISION_PROFILE_DETAILS = "UPDATE_PROVISION_PROFILE_DETAILS",
  MANAGE_PROVISION_PROFILE_DRAWER = "MANAGE_PROVISION_PROFILE_DRAWER",

  RESET_PROVISION_PROFILE_DETAILS = "RESET_PROVISION_PROFILE_DETAILS",
  GET_PROVISION_PROFILE_DETAILS = "GET_PROVISION_PROFILE_DETAILS",
  GET_PROVISION_PROFILE_DETAILS_SUCCESS = "GET_PROVISION_PROFILE_DETAILS_SUCCESS",
  GET_PROVISION_PROFILE_DETAILS_FAIL = "GET_PROVISION_PROFILE_DETAILS_FAIL",

  SAVE_PROVISION_PROFILE = "SAVE_PROVISION_PROFILE",
  SAVE_PROVISION_PROFILE_SUCCESS = "SAVE_PROVISION_PROFILE_SUCCESS",
  SAVE_PROVISION_PROFILE_FAIL = "SAVE_PROVISION_PROFILE_FAIL",

  ADD_PROVISION_PROFILE = "ADD_PROVISION_PROFILE",
  ADD_PROVISION_PROFILE_SUCCESS = "ADD_PROVISION_PROFILE_SUCCESS",
  ADD_PROVISION_PROFILE_FAIL = "ADD_PROVISION_PROFILE_FAIL",

  VALIDATE_PROFILE_NAME = "VALIDATE_PROFILE_NAME",
  VALIDATE_PROFILE_NAME_SUCCESS = "VALIDATE_PROFILE_NAME_SUCCESS",
  VALIDATE_PROFILE_NAME_FAIL = "VALIDATE_PROFILE_NAME_FAIL",
}
