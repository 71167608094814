import { IDownloadFile } from "us.collection.economy/interfaces";

export function DownloadNavisionFile(this: any): IDownloadFile {
  return {
    fileType: "EXPORT",
    fileSubType: this.exportMethod,
    fileName: this.fileNames,
    referenceId: [],
  };
}
