import { ISearchItemTypesPayload } from "us.collection.economy/interfaces/ItemTypes";

export function SearchItemTypes(this: any): ISearchItemTypesPayload {
  return {
    transactionGroupIds: this.transactionGroup?.length
      ? this.transactionGroup
      : [-1],
    itemTypes:
      this.transactionCode == "" ? ["ALL"] : this.transactionCode?.split(" "),
    isPayment: this.payment,
    isCancelation: this.cancellation,
    other: this.other,
    description: this.itemTypeName,
  };
}
