import React from "react";
import { ToggleClass } from "us.helper";
import { MainSearch } from "us.common/components";
import { RouteComponentProps } from 'react-router-dom';

const Cnt_MainSearch: React.FC<RouteComponentProps> = (props) => {
  ToggleClass("change-body-color-dom");

  return <MainSearch {...props}/>;
};

export default Cnt_MainSearch;
