export enum RemitDetailsTableColumn {
  AR_NO = "arNo",
  CASE_NO = "caseNo",
  PAYMENT_ID = "paymentId",
  DEBTOR = "debtorNo",
  CREDITOR_AMOUNT = "creditorAmount",
  BUREAU_AMOUNT = "bureauAmount",
  DEBTOR_AMOUNT = "debtorAmount",
  VAT_AMOUNT = "vatAmount",
  VAT_PAID_BY_DEBTOR = "vatPaidByDebtor",
  VAT_DEDUCTED = "vatDeducted",
  REMIT_CREDITOR = "remitCreditor",
  REMIT_BUREAU = "remitBureau",
  REMIT_DEBTOR = "remitDebtor",
}

export const REMIT_DETAILS_TABLE_COLUMNS = [
  {
    id: 0,
    key: RemitDetailsTableColumn.AR_NO,
    title: "AR_NO",
  },
  {
    id: 1,
    key: RemitDetailsTableColumn.CASE_NO,
    title: "CASE_NO",
  },
  {
    id: 2,
    key: RemitDetailsTableColumn.PAYMENT_ID,
    title: "PAYMENT_ID",
  },
  {
    id: 3,
    key: RemitDetailsTableColumn.DEBTOR,
    title: "DEBTOR",
  },
  {
    id: 4,
    key: RemitDetailsTableColumn.CREDITOR_AMOUNT,
    title: "CREDITOR_AMOUNT",
    align: "right",
  },
  {
    id: 5,
    key: RemitDetailsTableColumn.BUREAU_AMOUNT,
    title: "BUREAU_AMOUNT",
    align: "right",
  },
  {
    id: 6,
    key: RemitDetailsTableColumn.DEBTOR_AMOUNT,
    title: "DEBTOR_AMOUNT",
    align: "right",
  },
  {
    id: 7,
    key: RemitDetailsTableColumn.VAT_AMOUNT,
    title: "VAT_AMOUNT",
    align: "right",
  },
  {
    id: 8,
    key: RemitDetailsTableColumn.VAT_PAID_BY_DEBTOR,
    title: "VAT_PAID_BY_DEBTOR",
    align: "right",
  },
  {
    id: 9,
    key: RemitDetailsTableColumn.VAT_DEDUCTED,
    title: "VAT_DEDUCTED",
    align: "right",
  },
  {
    id: 10,
    key: RemitDetailsTableColumn.REMIT_CREDITOR,
    title: "REMIT_CREDITOR",
    align: "right",
  },
  {
    id: 11,
    key: RemitDetailsTableColumn.REMIT_BUREAU,
    title: "REMIT_BUREAU",
    align: "right",
  },
  {
    id: 12,
    key: RemitDetailsTableColumn.REMIT_DEBTOR,
    title: "REMIT_DEBTOR",
    align: "right",
  },
];
