import { initialState } from "us.collection.economy/reducers/ProvisionProfiles/State/InitialState";
import { ProvisionProfiles } from "us.collection.economy/constants/Actions";
import { formatOrderLinesByType } from "us.collection.economy/components/ProvisionProfiles/Functions";
import { DebtorType } from "us.collection.economy/constants";
import _ from "lodash";

const {
  SEARCH_PROVISION_PROFILES,
  SEARCH_PROVISION_PROFILES_SUCCESS,
  SEARCH_PROVISION_PROFILES_FAIL,

  DELETE_PROVISION_PROFILE,
  UPDATE_PROVISION_PROFILE_DETAILS,
  MANAGE_PROVISION_PROFILE_DRAWER,

  GET_PROVISION_PROFILE_DETAILS,
  GET_PROVISION_PROFILE_DETAILS_SUCCESS,
  GET_PROVISION_PROFILE_DETAILS_FAIL,
  RESET_PROVISION_PROFILE_DETAILS,

  SAVE_PROVISION_PROFILE,
  SAVE_PROVISION_PROFILE_SUCCESS,
  SAVE_PROVISION_PROFILE_FAIL,

  ADD_PROVISION_PROFILE,
  ADD_PROVISION_PROFILE_SUCCESS,
  ADD_PROVISION_PROFILE_FAIL,

  VALIDATE_PROFILE_NAME,
  VALIDATE_PROFILE_NAME_SUCCESS,
  VALIDATE_PROFILE_NAME_FAIL,
} = ProvisionProfiles;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case SEARCH_PROVISION_PROFILES:
      return Object.assign({}, state, {
        profiles: {
          data: [],
          isLoading: true,
        },
      });
    case SEARCH_PROVISION_PROFILES_SUCCESS:
      return Object.assign({}, state, {
        profiles: { data: payload.data, isLoading: false },
        deleteProfile: { isLoading: false },
      });
    case SEARCH_PROVISION_PROFILES_FAIL:
      return Object.assign({}, state, {
        profiles: { ...state.profiles, isLoading: false },
        deleteProfile: { isLoading: false },
      });
    case DELETE_PROVISION_PROFILE:
      return Object.assign({}, state, {
        deleteProfile: { isLoading: true },
      });

    case GET_PROVISION_PROFILE_DETAILS:
      return Object.assign({}, state, {
        profileDetails: {
          ...initialState.profileDetails,
          isLoading: true,
        },
      });
    case GET_PROVISION_PROFILE_DETAILS_SUCCESS:
      const { profileData, profileDetailsPerson, profileDetailsCompany } =
        payload.data;
      return Object.assign({}, state, {
        profileDetails: {
          ...initialState.profileDetails,
          initialData: {
            ...profileData,
            profileDetailsPerson: _.cloneDeep(profileDetailsPerson),
            profileDetailsCompany: _.cloneDeep(profileDetailsCompany),
          },
          data: {
            ...profileData,
            profileDetailsPerson: _.cloneDeep(profileDetailsPerson),
            profileDetailsCompany: _.cloneDeep(profileDetailsCompany),
          },
          isLoading: false,
        },
      });

    case GET_PROVISION_PROFILE_DETAILS_FAIL:
      return Object.assign({}, state, {
        profileDetails: {
          ...initialState.profileDetails,
          isLoading: false,
        },
      });

    case MANAGE_PROVISION_PROFILE_DRAWER:
      return Object.assign({}, state, {
        drawerInfo: {
          ...state.drawerInfo,
          ...payload.data,
          visible: !state.drawerInfo.visible,
        },
      });

    case UPDATE_PROVISION_PROFILE_DETAILS:
      return Object.assign({}, state, {
        profileDetails: {
          ...state.profileDetails,
          data: {
            ...state.profileDetails.data,
            ...payload.data,
          },
        },
      });

    case RESET_PROVISION_PROFILE_DETAILS:
      return Object.assign({}, state, {
        profileDetails: {
          ...initialState.profileDetails,
          initialData: _.cloneDeep(initialState.profileDetails.initialData),
          data: _.cloneDeep(initialState.profileDetails.initialData),
        },
        profileNameValidation: {
          ...initialState.profileNameValidation,
        },
      });

    case SAVE_PROVISION_PROFILE:
      return Object.assign({}, state, {
        profileDetails: {
          ...state.profileDetails,
          result: payload.data,
          isSaving: true,
        },
      });
    case SAVE_PROVISION_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        profileDetails: {
          ...state.profileDetails,
          result: payload.data,
          isSaving: false,
        },
      });
    case SAVE_PROVISION_PROFILE_FAIL:
      return Object.assign({}, state, {
        profileDetails: {
          ...state.profileDetails,
          result: payload.data,
          isSaving: false,
        },
      });
    case ADD_PROVISION_PROFILE:
      return Object.assign({}, state, {
        newProfile: {
          data: payload.data,
          isSaving: true,
        },
      });
    case ADD_PROVISION_PROFILE_SUCCESS:
      return Object.assign({}, state, {
        newProfile: {
          data: payload.data,
          isSaving: false,
        },
      });
    case ADD_PROVISION_PROFILE_FAIL:
      return Object.assign({}, state, {
        newProfile: {
          data: payload.data,
          isSaving: false,
        },
      });
    case VALIDATE_PROFILE_NAME:
      return Object.assign({}, state, {
        profileNameValidation: {
          ...state.profileNameValidation,
          isLoading: true,
          validated: false,
        },
      });
    case VALIDATE_PROFILE_NAME_SUCCESS:
      return Object.assign({}, state, {
        profileNameValidation: {
          data: payload.data,
          isLoading: false,
          validated: true,
        },
      });
    case VALIDATE_PROFILE_NAME_FAIL:
      return Object.assign({}, state, {
        profileNameValidation: {
          data: payload.data,
          isLoading: false,
          validated: true,
        },
      });
    default:
      return state;
  }
};
