export enum FilterOptions {
  ALL = "all",
  SELECTED = "selected",
  NOT_SELECTED = "notSelected",
}

export enum ExtraColumnsOptions {
  ZIP_CODE = "zipCode",
  ZIP_NAME = "zipName",
}

export enum RemitStatus {
  ELIGIBLE = "Yes",
  INELIGIBLE = "No",
}
