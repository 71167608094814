import React, { useEffect, useState } from "react";
import { Formik, useField } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { ISelectArticle } from "us.collection.economy/components/ManualOrders/Interface";
import { IRootState } from "us.collection/interfaces";
import { connect } from 'react-redux';
import * as Actions from "us.collection.economy/actions";
import { initialDrawerValues, initialSelectedArticle } from 'us.collection.economy/reducers/ManualOrders/State'
import { FilterOutlined } from 'us.icons';
import { filterData, filterArticleListBySearch } from "../../Functions/HelperFunctions";
import { DefaultVatStatus } from "../../Constants";

const { articleList, vatAmounts } = Actions.manualOrders;

const { $Checkbox, $Table, $Button, $Search, $Skeleton, $AmountLabel, $InputAmount, $Input } = Common.Components;

const SelectArticle: React.FC<ISelectArticle> = (props) => {
    const { t } = useTranslation(['US.COLLECTION.ECONOMY', "US.COLLECTION.COMMON"],);
    const {
      getAllArticles,
      filteredArticleList,
      isLoadingArticleData,
      changeArticleDrawerInfo,
      articleDrawerInfo,
      filterArticleList,
      currentLanguage,
      currentCurrency,
      articleList,
      validateCreditorDetails,
      getVatAmounts,
      orderTableData
    } = props;
    const [field, meta, helpers] = useField(`orderTableData[${articleDrawerInfo.rowKey}].article`);
    const [selectedArticle, setSelectedArticle] = useState<any>(articleDrawerInfo.selectedArticle);
    const [filterText, setFilterText] = useState<Array<any>>([]);
    const [filterInfo, setFilterInfo] = useState<any>({})
    const [sortedInfo, setSortedInfo] = useState<any>({
        columnKey: '',
        order: '',
    });

    useEffect(() => {
        getAllArticles && getAllArticles()
    }, []);

    useEffect(() => {
        if (articleList?.length > 0) {
            setFilterText([])
            setFilterInfo({})
            setSortedInfo({})
        }
    }, [articleList]);

    const clearFilters = () => {
        setFilterInfo({})
        setSortedInfo({})
        setFilterText([])
        filterArticleList && filterArticleList(articleList)
    };
    
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setFilterInfo(filters)
        setSortedInfo(sorter)
    };

    const getColumnSearchProps = (dataIndex: string, title: string, type: string) => {
        const inputSearchRef = React.useRef<any>();
        return {
            onFilterDropdownVisibleChange: (visible: boolean) => {
                if (visible) {
                    window.setTimeout(function () {
                        typeof inputSearchRef?.current !== "undefined" &&
                            inputSearchRef.current.focus();
                        document.querySelector('[name=columnSearchAmount]' as any)?.focus()
                    }, 1);

                    
                }
            },
            filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: any) => (
                <div style={{ padding: 8 }}>
                    {type !== "amount" && (
                        <$Input
                            name="columnSearch"
                            placeholder={`${t("US.COLLECTION.COMMON:COMMON.SEARCH")} ${title}`}
                            value={filterText.length > 0 ? filterText.filter((i: any) => i.dataIndex == dataIndex)[0]?.text : ''}
                            onChange={(e: any) => {
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                                setFilterText([
                                    ...filterText?.filter((n: any) => n.dataIndex !== dataIndex),
                                    { dataIndex: dataIndex, text: e.target.value },
                                ])
                                filterArticleList && filterArticleList(filterData(articleList, filterText, dataIndex, e.target.value, type))
                            }}
                            style={{ width: 188, marginBottom: 8, display: "block" }}
                            autoComplete="off"
                            ref={inputSearchRef}
                        />)}
                    {type == "amount" && (
                        <$InputAmount
                            id='column-search-amount'
                            min={0}
                            className="w-100"
                            placeholder="0,00"
                            name="columnSearchAmount"
                            currentLanguage={currentLanguage}
                            currentCurrency={currentCurrency}
                            value={
                                !filterText.filter((i: any) => i.dataIndex == dataIndex)[0]?.text
                                    ? ''
                                    : filterText.filter((i: any) => i.dataIndex == dataIndex)[0]?.text
                            }
                            onChange={(amount: any) => {
                                amount = amount == 0 ? "" : amount;
                                setSelectedKeys(amount ? [amount] : [])
                                setFilterText([
                                    ...filterText?.filter((n: any) => n.dataIndex !== dataIndex),
                                    { dataIndex: dataIndex, text: amount },
                                ])
                                filterArticleList && filterArticleList(filterData(articleList, filterText, dataIndex, amount, type))
                            }}
                            autoComplete="off"
                            dataTestid='column-search'
                        />)}
                    <$Button onClick={() => {
                        clearFilters()
                        setFilterText([
                            ...filterText?.filter((n: any) => n.dataIndex !== dataIndex)
                        ])
                        filterArticleList && filterArticleList(filterData(articleList, filterText, dataIndex, '', type))
                        confirm()
                    }} size="small" style={{ width: 90 }}>
                        {t("US.COLLECTION.COMMON:COMMON.RESET")}
                    </$Button>
                </div>
            ),
            filterIcon: (filtered: string) => (
                <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
            ),
            render: (text: string) => <div>{text}</div>
        }
    };

    const getHeaderTitle = (dataIndex: string, title: any, type: string) => {
        return (
            <div className="d-flex flex-column">
                <span style={{ fontWeight: "bold" }}>
                    <span data-testid={dataIndex}>{title}</span>
                    {filterText.length > 0 && filterText.filter((i: any) => i.dataIndex == dataIndex)[0]?.text && <p>
                        <span
                            className="font-sm mt-n4"
                            style={{ fontWeight: "bold" }}
                            data-testid='filter-by'
                        >
                            {t("COMMON.FILTERED_BY_")}: {' '}
                            {type !== "amount" && filterText.filter((i: any) => i.dataIndex == dataIndex)[0]?.text}
                            {type == "amount" && (<$AmountLabel value={filterText.filter((i: any) => i.dataIndex == dataIndex)[0]?.text} />)}
                        </span>
                    </p>}
                </span>
            </div>
        )
    }

    const column: any = (restProps: any, values: any) => {
        const columns = [
            {
                title: "",
                key: "more",
                dataIndex: "more",
                width: "50px",
                render: (text: any, record: any, index: number) => {
                    return (
                        <$Checkbox
                            name="isArticleSelect"
                            checked={record.articleNo == selectedArticle.articleNo}
                            onChange={() => {
                                setSelectedArticle({...record, isValidArticle: true})
                            }}
                        />
                    );
                }
            },
            {
                title: getHeaderTitle("articleNo", t('US.COLLECTION.ECONOMY:ORDERS.ARTICLE_NUMBER'), 'number'),
                key: "articleNo",
                dataIndex: "articleNo",
                sorter: (a: any, b: any) => a.articleNo - b.articleNo,
                sortOrder: sortedInfo.columnKey === 'articleNo' && sortedInfo.order,
                ...getColumnSearchProps("articleNo", t("US.COLLECTION.ECONOMY:ORDERS.ARTICLE_NUMBER"), 'number'),
                filteredValue: filterInfo.articleNo || null
            },
            {
                title: getHeaderTitle("description", t('US.COLLECTION.ECONOMY:ORDERS.DESCRIPTION'), 'string'),
                dataIndex: "description",
                key: "description",
                sorter: (a: any, b: any) => a.description.localeCompare(b.description),
                sortOrder: sortedInfo.columnKey === 'description' && sortedInfo.order,
                ...getColumnSearchProps("description", t("US.COLLECTION.ECONOMY:ORDERS.DESCRIPTION"), 'string'),
                filteredValue: filterInfo.description || null
            },
            {
                title: getHeaderTitle("defaultPrice", t('US.COLLECTION.ECONOMY:ORDERS.DEFAULT_PRICE'), 'amount'),
                dataIndex: "defaultPrice",
                key: "defaultPrice",
                align: "right",
                className: "right-has-sort right-has-filter text-nowrap",
                sorter: (a: any, b: any) => a.defaultPrice - b.defaultPrice,
                sortOrder: sortedInfo.columnKey === 'defaultPrice' && sortedInfo.order,
                ...getColumnSearchProps("defaultPrice", t("US.COLLECTION.ECONOMY:ORDERS.DEFAULT_PRICE"), 'amount'),
                filteredValue: filterInfo.defaultPrice || null,
                render: (text: any, record: any, index: number) => {
                    return (
                        <$AmountLabel value={record.defaultPrice} />
                    );
                },
            },
        ];
        return columns;
    };

    return (
        <Formik
            initialValues={{filteredArticleList}}
            enableReinitialize
            onSubmit={() => { }}
        >
            {({
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                isValidating,
                resetForm,
                ...restProps
            }: any) => (
                <>
                    <div className="font-weight-bold mt-3">{t('US.COLLECTION.ECONOMY:ORDERS.ARTICLES')}</div>
                   
                       <div className="d-flex justify-content-between">
                            <div>
                                <$Search
                                    size="small"
                                    name="filterArticles"
                                    allowClear={true}
                                    placeholder={t('US.COLLECTION.ECONOMY:ORDERS.FILTER_BY_ARTICLE_NUMBER,DESCRIPTION')}
                                    style={{ width: 300 }}
                                    onChange={(e: any) => {
                                        filterArticleList && filterArticleList(filterArticleListBySearch(articleList, e.target.value))
                                    }}
                                    onSearch={(text:any) => {
                                        filterArticleList && filterArticleList(filterArticleListBySearch(articleList, text))
                                    }}
                                />
                            </div>
                            <div>
                                <$Button size="small" onClick={() => clearFilters()}
                                    disabled={filterText.length == 0 && (sortedInfo.order == '' || sortedInfo.order == undefined)}
                                    data-testid='reset-all-btn'>{t("COMMON.RESET_ALL")}</$Button>
                            </div>
                       </div>
                    <$Skeleton loading={isLoadingArticleData} active paragraph={{ rows: 3 }}>
                        <$Table
                            columns={column(restProps, values)}
                            dataSource={filteredArticleList}
                            bordered
                            className="mt-3"
                            onChange={handleTableChange}
                        />
                    </$Skeleton>
                    <div className="drawer-footer-fixed align-items-end justify-content-between">
                        <div></div>
                        <div >
                            <$Button className="ml-3 mr-2" type="primary" 
                                data-testid='article-select-btn'
                                disabled={isLoadingArticleData || JSON.stringify(selectedArticle) == JSON.stringify(initialSelectedArticle)}
                                onClick={() => {
                                    changeArticleDrawerInfo && changeArticleDrawerInfo({
                                        ...initialDrawerValues,
                                        rowKey: articleDrawerInfo.rowKey,
                                        selectedArticle
                                    })
                                    helpers.setValue(selectedArticle.articleNo)
                                    const {pid} = validateCreditorDetails.selectedCreditor
                                    const {units, unitPrice} = orderTableData.filter((order:any) => order.key == articleDrawerInfo.rowKey)[0]
                                    if (selectedArticle?.vatCode != 0 && unitPrice != 0 && pid != 0) {
                                        getVatAmounts && getVatAmounts({
                                            vatParams: {
                                                vatCode: selectedArticle?.vatCode,
                                                vatStatus: DefaultVatStatus,
                                                amount: (units * unitPrice),
                                                creditorNo: pid
                                            },
                                            rowKey: articleDrawerInfo.rowKey,
                                        })
                                    }
                                }}>{t('US.COLLECTION.COMMON:COMMON.SELECT')}</$Button>
                            <$Button disabled={isLoadingArticleData}
                                onClick={() => {
                                    changeArticleDrawerInfo && changeArticleDrawerInfo({...initialDrawerValues, selectedArticle: articleDrawerInfo.selectedArticle})
                                }}>{t('US.COLLECTION.COMMON:COMMON.CANCEL')}</$Button>
                        </div>
                    </div>
                </>
            )}
        </Formik>
    );
};

const mapStateToProps = (state: IRootState) => {
    const { common, manualOrders } = state;
    const { currentLanguage, currentCurrency, currentDateFormat } = common;
    const { filteredArticleList, articleDrawerInfo, isLoadingArticleData, articleList, validateCreditorDetails,orderTableData } = manualOrders;

    return {
        currentLanguage,
        currentCurrency,
        currentDateFormat,
        filteredArticleList,
        articleDrawerInfo,
        isLoadingArticleData,
        articleList,
        validateCreditorDetails,
        orderTableData
    };
};

const mapDispatchToProps = {
    getAllArticles: articleList.get,
    changeArticleDrawerInfo: articleList.openDrawer,
    filterArticleList: articleList.update,
    getVatAmounts: vatAmounts.get
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectArticle);