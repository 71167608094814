import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { $Message, $MessageBox } from "us.common/components";
import { CoveringSequence } from "us.collection.economy/constants/Actions";
import { getFormatedSequenceList, getSortedSequenceList } from "us.collection.economy/functions";
import { i18n } from "us.helper";

const { coveringSequence } = Actions;
const {
  getCoveringSequenceList,
  getItemTypes,
  addNewCoveringSequence,
  updateCoveringSequence,
  deleteCoveringSequence,
  validateCoveringSequenceName,
} = API.coveringSequence;
const {
  coveringSequenceList,
  itemTypes,
  newCoveringSequence,
  existingCoveringSequence,
  coveringSequenceData,
  coveringSequenceName,
} = coveringSequence;
const {
  GET_COVERING_SEQUENCE_LIST,
  GET_ITEM_TYPES,
  SAVE_COVERING_SEQUENCE,
  UPDATE_COVERING_SEQUENCE,
  DELETE_COVERING_SEQUENCE,
  VALIDATE_COVERING_SEQUENCE_NAME,
} = CoveringSequence;

export const CoveringSequenceSagas = {
  coveringSequence: {
    getCoveringSequences: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(getCoveringSequenceList, params);
        if (status == 200) {
          const payload = getSortedSequenceList(data.map((item: any) => ({
            ...item,
            sequenceDetails: getFormatedSequenceList(item.sequenceDetails),
          })));
          yield put(coveringSequenceList.success(payload));
        } else {
          yield put(coveringSequenceList.fail(new Error()));
        }
      } catch (error) {
        yield put(coveringSequenceList.fail(new Error()));
      }
    },
    getItemTypesByTransGroups: function* (action: any): any {
      try {
        const { itemTypesData, defaultTypes } = yield call(getItemTypes, {});
        const payload = {
          itemTypesList:
            itemTypesData?.status == 200
              ? itemTypesData?.data.map((item: any) => ({
                  ...item,
                  description: item.description.trim(),
                }))
              : [],
          defaultTypesList:
            defaultTypes?.status == 200 ? defaultTypes?.data : [],
        };
        yield put(itemTypes.success(payload));
      } catch (error) {
        yield put(itemTypes.fail(new Error()));
      }
    },
    saveCoveringSequence: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, sequenceList } = yield call(
          addNewCoveringSequence,
          params
        );
        if (status == 200 || status == 201) {
          $MessageBox(
            "success",
            `US.COLLECTION.ECONOMY:COVERING_SEQUENCE.COVERING_SEQUENCE_CREATED_SUCCESSFULLY`,
            "",
            ""
          );
          const payload = getSortedSequenceList(sequenceList.map((item: any) => ({
            ...item,
            sequenceDetails: getFormatedSequenceList(item.sequenceDetails),
          })));
          yield put(
            newCoveringSequence.success({
              savePayload: data,
              sequenceData: payload,
            })
          );
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:COVERING_SEQUENCE.COVERING_SEQUENCE_CREATION_FAILED`,
            "",
            ""
          );
          yield put(newCoveringSequence.fail(new Error()));
        }
      } catch (error) {
        yield put(newCoveringSequence.fail(new Error()));
      }
    },
    updateCoveringSequence: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, sequenceList } = yield call(
          updateCoveringSequence,
          params
        );
        if (status == 200 || status == 201) {
          $MessageBox(
            "success",
            `US.COLLECTION.ECONOMY:COVERING_SEQUENCE.COVERING_SEQUENCE_UPDATED_SUCCESSFULLY`,
            "",
            ""
          );
          const payload = getSortedSequenceList(sequenceList.map((item: any) => ({
            ...item,
            sequenceDetails: getFormatedSequenceList(item.sequenceDetails),
          })));
          yield put(
            existingCoveringSequence.success({
              updatePayload: data,
              updatedSeqList: payload,
            })
          );
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:COVERING_SEQUENCE.COVERING_SEQUENCE_UPDATE_FAILED`,
            "",
            ""
          );
          yield put(existingCoveringSequence.fail(new Error()));
        }
      } catch (error) {
        yield put(existingCoveringSequence.fail(new Error()));
      }
    },
    deleteCoveringSequence: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      $Message.loading(i18n.t("COMMON.LOADING"), 0);
      try {
        const { data, status, sequenceList } = yield call(
          deleteCoveringSequence,
          params
        );
        $Message.destroy();
        if (status == 200 || status == 201) {
          $MessageBox(
            "success",
            `US.COLLECTION.ECONOMY:COVERING_SEQUENCE.COVERING_SEQUENCE_DELETED_SUCCESSFULLY`,
            "",
            ""
          );
          const payload = getSortedSequenceList(sequenceList.map((item: any) => ({
            ...item,
            sequenceDetails: getFormatedSequenceList(item.sequenceDetails),
          })));
          yield put(
            coveringSequenceData.success({ updatedSequenceList: payload })
          );
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:COVERING_SEQUENCE.COVERING_SEQUENCE_DELETE_FAILED`,
            "",
            ""
          );
          yield put(coveringSequenceData.fail(new Error()));
        }
      } catch (error) {
        yield put(coveringSequenceData.fail(new Error()));
      }
    },
    validateSequenceName: function* (action: any): any {
      const sequenceName = action?.payload?.data ?? "";
      try {
        const { data, status } = yield call(
          validateCoveringSequenceName,
          sequenceName
        );
        yield put(coveringSequenceName.success(data?.status));
      } catch (error) {
        yield put(coveringSequenceName.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(
    GET_COVERING_SEQUENCE_LIST,
    CoveringSequenceSagas.coveringSequence.getCoveringSequences
  ),
  takeLatest(
    GET_ITEM_TYPES,
    CoveringSequenceSagas.coveringSequence.getItemTypesByTransGroups
  ),
  takeLatest(
    SAVE_COVERING_SEQUENCE,
    CoveringSequenceSagas.coveringSequence.saveCoveringSequence
  ),
  takeLatest(
    UPDATE_COVERING_SEQUENCE,
    CoveringSequenceSagas.coveringSequence.updateCoveringSequence
  ),
  takeLatest(
    DELETE_COVERING_SEQUENCE,
    CoveringSequenceSagas.coveringSequence.deleteCoveringSequence
  ),
  takeLatest(
    VALIDATE_COVERING_SEQUENCE_NAME,
    CoveringSequenceSagas.coveringSequence.validateSequenceName
  ),
];
