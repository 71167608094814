import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { PlusOutlined } from "us.icons";
import { Details } from "./Components";
import { $Drawer } from "us.common/components";
import { Table } from "us.collection.economy/components/VATCode/Components";
import {
  VatCodeAction,
  INITIAL_DRAWER_DATA,
} from "us.collection.economy/components/VATCode/Constants";
import {
  IVatCode,
  IDrawer,
} from "us.collection.economy/components/VATCode/Interfaces";
import { getDrawerTitle } from "us.collection.economy/components/VATCode/Functions";
import { VatCodeDetails } from "us.collection.economy/interfaces";
import { IRootState } from "us.collection/interfaces";
import * as Actions from "us.collection.economy/actions";
import { useHistory } from "react-router-dom";
import { handleGoBack } from "us.helper/utility";

const { $Affix, $PageHeader, $Divider, $Button, $Skeleton } = Common.Components;

/**
 * @description - Vat Code Main View
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2855108613/VAT+Code
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 28/07/2022
 */
const VatCode: React.FC<IVatCode & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { replace } = history;
  const {
    getVatCodeList,
    isListLoading,
    vatCodeSaveAndUpdate,
    deleteVatCode,
    resetVatCodeData,
  } = props;

  const [drawer, setDrawer] = useState<IDrawer>(INITIAL_DRAWER_DATA);

  useEffect(() => {
    getVatCodeList && getVatCodeList({});
  }, []);

  /**
   * @description - Update the browser tab url according to given id value
   * @param {VatCodeAction} action - action type
   * @param {number} vatId - vat id if available
   */
  const updateUrl = (action: VatCodeAction, vatId?: number) => {
    replace({
      pathname: "/vat-code",
      search: `?action=${action}&vatId=${vatId}`,
    });
  };

  /**
   * @description - Close the drawer
   */
  const onClose = () => {
    replace({
      pathname: "/vat-code",
      search: "",
    });
    resetVatCodeData && resetVatCodeData({});
    setDrawer(INITIAL_DRAWER_DATA);
  };

  /**
   * @description Handle drawer by Vat Code action
   * @param {VatCodeAction} type - Vat Code action type
   * @param {number} id - Vat Code id
   */
  const handleVatCodeAction = (type: VatCodeAction, id: number = -1) => {
    updateUrl(type, id);
    setDrawer({
      ...drawer,
      title: t(getDrawerTitle(type)),
      visible: true,
    });
  };

  /**
   * @description Handle Vat Code table menu item action
   * @param {VatCodeDetails} record - Vat Code table record data
   * @param {VatCodeAction} actionType - Vat Code action type
   */
  const handleVatCodeRecordAction = (
    record: VatCodeDetails,
    actionType: VatCodeAction
  ) => {
    const { vatId, vatCode } = record;
    switch (actionType) {
      case VatCodeAction.EDIT:
        handleVatCodeAction(actionType, vatId);
        break;
      case VatCodeAction.DELETE:
        deleteVatCode && deleteVatCode(vatCode);
        break;
      default:
        break;
    }
  };

  return (
    <Formik initialValues={{}} enableReinitialize onSubmit={() => {}}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t("US.COLLECTION.ECONOMY:VAT_CODE.VATCODE")}
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <$Button
                    data-testid="add-new-vat-code"
                    type="default"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => handleVatCodeAction(VatCodeAction.ADD)}
                  >
                    {t("US.COLLECTION.ECONOMY:VAT_CODE.NEW_VAT_CODE")}
                  </$Button>
                </div>
              </div>
            </$Affix>
            <$Skeleton
              loading={isListLoading || vatCodeSaveAndUpdate.isLoading}
              active
              paragraph={{ rows: 2 }}
            >
              <div>
                <Table onCallAction={handleVatCodeRecordAction} />
              </div>
            </$Skeleton>
          </div>

          <$Drawer
            title={drawer.title}
            width={"600"}
            visible={drawer.visible}
            onClose={() => onClose()}
            destroyOnClose
          >
            <Details onCancel={onClose} />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { vatCode } = state;
  const { isListLoading, vatCodeSaveAndUpdate } = vatCode;
  return {
    isListLoading,
    vatCodeSaveAndUpdate,
  };
};
const { vatCodeList, vatCodeDetails, newVatCode } = Actions.vatCode;
const { get } = vatCodeList;
const { deleteById } = vatCodeDetails;
const { reset } = newVatCode;

const mapDispatchToProps = {
  getVatCodeList: get,
  deleteVatCode: deleteById,
  resetVatCodeData: reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VatCode);
