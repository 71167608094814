import { PaymentHelper } from "us.collection.economy/components/ManagePayments/Functions";
import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import {
  ManagePayments,
  ManagePaymentsGrid,
  ManagePaymentsApportionment,
} from "us.collection.economy/constants/Actions";
import { $MessageBox } from "us.common/components";
import { GetCases } from "us.collection.economy/components/ManagePayments/Repository";
import { IGetCases } from "us.collection.economy/interfaces";
import { DEFAULT_PAYMENTS_LIMIT } from "us.collection.economy/constants";
import { sortPaymentAccounts } from "us.collection.economy/functions";
const { managePayment, managePaymentGrid, managePaymentApportionment } =
  Actions;

const ManagePaymentSagas = {
  managePayment: {
    init: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { accounts, payments, dayVal, paymentDateRange } = yield call(
          API.managePayments.payments.initPayments,
          params
        );
        yield put(managePayment.getPaymentAccountsSuccess(accounts));
        if (Array.isArray(payments)) {
          const hasMorePayments = payments.length % DEFAULT_PAYMENTS_LIMIT == 0;
          yield put(
            managePayment.searchPaymentsSuccess({
              payments,
              dayVal,
              paymentDateRange,
              offset: 0,
              hasMorePayments,
            })
          );
        } else {
          yield put(
            managePayment.searchPaymentsNoResult({
              payments: [],
              dayVal,
              paymentDateRange,
            })
          );
        }
      } catch (error) {
        yield put(managePayment.getPaymentAccountsFail(error));
        yield put(managePayment.initPaymentsFail(error));
      }
    },
    initGrid: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const accounts = yield call(
          API.managePayments.payments.accounts,
          params
        );
        yield put(managePayment.getPaymentAccountsSuccess(accounts));
        try {
          const payments = yield call(API.managePayments.payments.grid, {
            ...params,
            accountNo: accounts[0].accountNo,
          });

          yield put(managePaymentGrid.paymentsSuccess(payments));
        } catch (error) {
          yield put(managePaymentGrid.paymentsFail(error));
        }
      } catch (error) {
        yield put(managePayment.getPaymentAccountsFail(error));
        yield put(managePaymentGrid.initPaymentsFail(error));
      }
    },
    payments: function* (action: any) {
      const params = action?.payload?.data ?? {};
      const { limit, offset } = params;
      try {
        const { payments, dayVal, paymentDateRange } = yield call(
          API.managePayments.payments.get,
          params
        );
        if (Array.isArray(payments)) {
          const hasMorePayments = payments.length % limit == 0;
          yield put(
            managePayment.searchPaymentsSuccess({
              payments,
              dayVal,
              paymentDateRange,
              hasMorePayments,
              offset,
            })
          );
        } else {
          yield put(
            managePayment.searchPaymentsNoResult({
              payments: [],
              dayVal,
              paymentDateRange,
              hasMorePayments: false,
            })
          );
        }
      } catch (error) {
        yield put(managePayment.searchPaymentsFail({ offset }));
      }
    },
    paymentsGrid: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.managePayments.payments.grid, params);
        yield put(managePaymentGrid.paymentsSuccess(data));
      } catch (error) {
        yield put(managePaymentGrid.paymentsFail(error));
      }
    },
    paymentAccounts: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(API.managePayments.payments.accounts, params);
        const sortedData = sortPaymentAccounts(data);
        yield put(managePayment.getPaymentAccountsSuccess(sortedData));
      } catch (error) {
        yield put(managePayment.getPaymentAccountsFail(error));
      }
    },
    paymentDistribution: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(
          API.managePayments.payments.distribution,
          params
        );
        yield put(managePayment.getPaymentDistributionSuccess(data));
      } catch (error) {
        yield put(managePayment.getPaymentDistributionFail(error));
      }
    },
    cases: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const cases = yield call(API.managePayments.payments.cases, params);
        yield put(
          managePayment.getCasesSuccess({
            cases,
            paymentId: params.paymentId,
          })
        );
      } catch (error) {
        yield put(managePayment.getCasesFail(error));
      }
    },
    getValidateCaseId: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const validationResults = yield call(
          API.regPayments.regPayments.getValidateCaseId,
          params.caseNo
        );
        if (Object.keys(validationResults).length === 0) {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_CASE_NO",
            "",
            ""
          );
          yield put(managePayment.getCaseValidationDataFail({}));
          return;
        }
        if (params.itemTypeId == 67 && !validationResults.isCreditorInvoice) {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:VALIDATIONS.PLEASE_SELECT_A_CREDITOR_INVOICE",
            "",
            ""
          );
          yield put(managePayment.getCaseValidationDataFail({}));
          return;
        }
        yield put(
          managePayment.getCaseValidationDataSuccess(validationResults)
        );
      } catch (error) {
        yield put(managePayment.getCaseValidationDataFail(error));
      }
    },
    match: function* (action: any) {
      const { paramsMatch, searchParams } = action?.payload?.data ?? {};
      try {
        const matchResult = yield call(
          API.managePayments.payments.match,
          paramsMatch
        );
        if (typeof matchResult == "boolean" && matchResult) {
          let result = {};
          let casesParams: IGetCases | null = null;
          const { payments } = yield call(
            API.managePayments.payments.get,
            searchParams
          );
          if (payments && payments.length > 0) {
            const updatedPayment = payments.filter(
              (p: any) => p.paymentId == paramsMatch.paymentId
            )[0];
            if (updatedPayment) {
              const isExceeded = updatedPayment.exceededSum > 0;
              result = {
                matchResult,
                matchedPayments: updatedPayment,
              };
              casesParams = isExceeded
                ? GetCases.call(
                    PaymentHelper.getPaymentForMatch(
                      paramsMatch.paymentId,
                      payments
                    )
                  )
                : null;
            } else {
              result = {
                matchResult,
                matchedPayments: payments,
              };
              casesParams = GetCases.call(
                PaymentHelper.getPaymentForMatch(
                  payments[0].paymentId,
                  payments
                )
              );
            }
          } else {
            result = {
              matchResult,
              matchedPayments: [],
            };
          }
          yield put(managePayment.matchPaymentSuccess(result));
          if (casesParams) {
            const cases = yield call(
              API.managePayments.payments.cases,
              casesParams
            );
            yield put(
              managePayment.getCasesSuccess({
                cases,
                paymentId: casesParams?.paymentId,
              })
            );
          }
          $MessageBox(
            "success",
            `US.COLLECTION.ECONOMY:MESSAGES.THE_PAYMENT_SUCCESSFULLY_UPDATED`,
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:VALIDATIONS.THE_PAYMENT_UPDATE_FAILED`,
            "",
            ""
          );
          yield put(managePayment.matchPaymentFail(matchResult));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ECONOMY:VALIDATIONS.THE_PAYMENT_UPDATE_FAILED`,
          "",
          ""
        );
        yield put(managePayment.matchPaymentFail(error));
      }
    },
    reverseReturenedPayment: function* (action: any) {
      const isExceed = action?.payload?.data.exceededSum > 0;
      const returnedPayment = action?.payload?.data ?? {};
      let filters = action?.payload?.mainFilters ?? {};
      const reversePayload = {
        op: "remove",
        path: "/",
        returnedPaymentId: returnedPayment.arItemNo,
        comment: returnedPayment.comment,
      };
      try {
        const reverseResult = yield call(
          API.managePayments.payments.reverseReturenedPayment,
          reversePayload
        );
        if (typeof reverseResult == "boolean" && reverseResult) {
          let result = {};
          let casesParams: IGetCases | null = null;
          const { payments } = yield call(
            API.managePayments.payments.get,
            filters
          );
          if (payments && payments.length > 0) {
            const updatedPayment = payments.filter(
              (p: any) => p.paymentId == returnedPayment.paymentId
            )[0];
            if (updatedPayment && isExceed) {
              result = {
                reverseResult,
                reversedReturnedPayment: updatedPayment,
              };
              casesParams = GetCases.call(
                PaymentHelper.getPaymentForMatch(
                  returnedPayment.paymentId,
                  payments
                )
              );
            } else {
              result = {
                reverseResult,
                reversedReturnedPayment: payments,
              };
            }
          } else {
            result = {
              reverseResult,
              reversedReturnedPayment: [],
            };
          }
          yield put(managePayment.reverseReturnedPaymentSuccess(result));
          $MessageBox(
            "success",
            `US.COLLECTION.ECONOMY:MESSAGES.PAYMENT_REVERSED_SUCCESSFULLY`,
            "",
            ""
          );
          if (casesParams) {
            const cases = yield call(
              API.managePayments.payments.cases,
              casesParams
            );
            yield put(
              managePayment.getCasesSuccess({
                cases,
                paymentId: casesParams?.paymentId,
              })
            );
          }
        } else {
          yield put(managePayment.reverseReturnedPaymentFail(reverseResult));
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:MESSAGES.PAYMENT_REVERSED_FAILED`,
            "",
            ""
          );
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ECONOMY:MESSAGES.PAYMENT_REVERSED_FAILED`,
          "",
          ""
        );
        yield put(managePayment.reverseReturnedPaymentFail(error));
      }
    },
    getApportionments: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const payments = yield call(
          API.managePayments.payments.getApportionments,
          params.paymentId
        );
        yield put(
          managePaymentApportionment.getPaymentsApportionmentsSuccess(
            payments,
            params.accountNo,
            params.paymentAmount
          )
        );
      } catch (error) {
        yield put(
          managePaymentApportionment.getPaymentsApportionmentsFail(error)
        );
      }
    },
    getVatAmounts: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const vats = yield call(
          API.managePayments.payments.getVatAmounts,
          params.vatParams
        );
        if (vats?.code) {
          yield put(managePaymentApportionment.getVatAmountsFail(vats));
          if (vats.code == 400) {
            $MessageBox(
              "error",
              `US.COLLECTION.ECONOMY:MESSAGES.VAT_CALCULATION_FAILED`,
              "",
              ""
            );
          }
        } else {
          yield put(
            managePaymentApportionment.getVatAmountsSuccess(
              vats,
              params.values,
              params.record
            )
          );
        }
      } catch (error) {
        yield put(managePaymentApportionment.getVatAmountsFail(error));
      }
    },
    getVatAmountsForReturnFee: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const vats = yield call(
          API.managePayments.payments.getVatAmounts,
          params.vatParams
        );
        yield put(
          managePaymentApportionment.getVatAmountsForReturnFeeSuccess(
            vats,
            params.values
          )
        );
      } catch (error) {
        yield put(
          managePaymentApportionment.getVatAmountsForReturnFeeFail(error)
        );
      }
    },
    updateApportionment: function* (action: any) {
      const updatedApportionment = action?.payload?.data ?? {};
      const payment = action?.payload?.payment ?? {};
      let filters = action?.payload?.mainFilters ?? {};
      try {
        const updatedResult = yield call(
          API.managePayments.payments.updateApportionment,
          updatedApportionment
        );
        if (updatedResult) {
          if (typeof updatedResult == "boolean" && updatedResult) {
            let result = {};
            const { payments } = yield call(
              API.managePayments.payments.get,
              filters
            );
            if (payments && payments.length > 0) {
              const updatedPayment = payments.filter(
                (p: any) => p.paymentId == payment.paymentId
              )[0];
              if (updatedPayment) {
                result = {
                  updatedResult,
                  apportionedPayment: updatedPayment,
                };
              } else {
                result = {
                  updatedResult,
                  apportionedPayment: payments,
                };
              }
            } else {
              result = {
                updatedResult,
                apportionedPayment: [],
              };
            }
            yield put(
              managePaymentApportionment.updateApportionmentSuccess(result)
            );
            $MessageBox(
              "success",
              `US.COLLECTION.ECONOMY:MESSAGES.SUCCESSFULLY_UPDATE_THE_APPORTIONMENT`,
              "",
              ""
            );
          }
        } else {
          yield put(
            managePaymentApportionment.updateApportionmentFail(updatedResult)
          );
          if (updatedResult.code == 400) {
            $MessageBox(
              "error",
              `US.COLLECTION.ECONOMY:MESSAGES.APPORTIONMENT_UPDATE_ERROR`,
              "",
              ""
            );
          } else if (updatedResult.code == 412) {
            $MessageBox(
              "error",
              `US.COLLECTION.ECONOMY:MESSAGES.THE_PAYMENT_HAS_ALREADY_BEEN_REMITTED`,
              "",
              ""
            );
          } else if (updatedResult.code == 401) {
            $MessageBox(
              "error",
              `US.COLLECTION.ECONOMY:MESSAGES.INVALID_TOKEN`,
              "",
              ""
            );
          } else if (updatedResult.code == 500) {
            $MessageBox(
              "error",
              `US.COLLECTION.ECONOMY:MESSAGES.APPORTIONMENT_UPDATE_ERROR`,
              "",
              ""
            );
          } else if (updatedResult.code == 403) {
            $MessageBox(
              "error",
              `US.COLLECTION.ECONOMY:MESSAGES.YOU_DONT_HAVE_ACCESS_TO_THIS_FUNCTIONALITY`,
              "",
              ""
            );
          }
        }
      } catch (error) {
        yield put(managePaymentApportionment.updateApportionmentFail(error));
      }
    },
    returnMappedPayment: function* (action: any) {
      const isExceed = action?.payload?.data.exceededSum > 0;
      const revertPayment = action?.payload?.data ?? {};
      let filters = action?.payload?.mainFilters ?? {};
      const revertPayload = {
        commentPayload: { comment: revertPayment.comment },
        revertPaymentId: revertPayment.paymentId,
      };
      try {
        const { data, status } = yield call(
          API.managePayments.payments.revertMappedPayment,
          revertPayload
        );
        if (status === 200) {
          let result = {};
          let casesParams: IGetCases | null = null;
          const { payments } = yield call(
            API.managePayments.payments.get,
            filters
          );
          if (payments && payments.length > 0) {
            const updatedPayment = payments.filter(
              (p: any) => p.paymentId == revertPayment.paymentId
            )[0];
            if (updatedPayment && isExceed) {
              result = {
                revertResult: data,
                revertMappedPayment: updatedPayment,
              };
              casesParams = GetCases.call(
                PaymentHelper.getPaymentForMatch(
                  revertPayment.paymentId,
                  payments
                )
              );
            } else {
              result = {
                revertResult: data,
                revertMappedPayment: payments,
              };
            }
          } else {
            result = {
              revertResult: data,
              revertMappedPayment: [],
            };
          }
          yield put(managePayment.revertMappedPaymentSuccess(result));
          $MessageBox(
            "success",
            `US.COLLECTION.ECONOMY:MESSAGES.PAYMENT_REVERT_SUCCESSFULLY`,
            "",
            ""
          );
          if (casesParams) {
            const cases = yield call(
              API.managePayments.payments.cases,
              casesParams
            );
            yield put(
              managePayment.getCasesSuccess({
                cases,
                paymentId: casesParams?.paymentId,
              })
            );
          }
        } else if (
          status == 412 &&
          data.hasOwnProperty("message") &&
          data.message.statusMessage
        ) {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:MESSAGES.${data.message.statusMessage}`,
            "",
            ""
          );
          yield put(managePayment.revertMappedPaymentFail(data));
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.ECONOMY:MESSAGES.PAYMENT_REVERT_FAILED`,
            "",
            ""
          );
          yield put(managePayment.revertMappedPaymentFail(data));
        }
      } catch (error) {
        $MessageBox(
          "error",
          `US.COLLECTION.ECONOMY:MESSAGES.PAYMENT_REVERT_FAILED`,
          "",
          ""
        );
        yield put(managePayment.revertMappedPaymentFail(error));
      }
    },
  },
  payments: {
    edit: function* (action: any) {
      const params = action?.payload?.data ?? {};
      let filters = action?.payload?.filters ?? {};
      if (filters.paymentId !== 0) {
        filters.paymentStates = [];
      }
      try {
        const { edit, payments, paymentDateRange, dayVal } = yield call(
          API.managePayments.payments.edit,
          {
            params,
            filters: {
              ...filters,
              limit:
                filters.offset > DEFAULT_PAYMENTS_LIMIT
                  ? filters.offset
                  : DEFAULT_PAYMENTS_LIMIT,
              offset: 0,
            },
          }
        );
        if (Array.isArray(payments)) {
          const hasMorePayments = payments.length % filters.limit == 0;
          yield put(
            managePayment.searchPaymentsSuccess({
              payments,
              dayVal,
              paymentDateRange,
              offset: 0,
              hasMorePayments,
            })
          );
        } else {
          yield put(
            managePayment.searchPaymentsNoResult({
              payments: [],
              dayVal,
              paymentDateRange,
            })
          );
        }
        yield put(managePayment.editPaymentSuccess(edit));
        $MessageBox(
          "success",
          `US.COLLECTION.ECONOMY:MESSAGES.PAYMENT_EDITED_SUCCESSFULLY`,
          "",
          ""
        );
      } catch (error) {
        yield put(managePayment.editPaymentFail(error));
      }
    },
    transfer: function* (action: any) {
      const params = action?.payload?.data ?? {};
      const filters = action?.payload?.filters ?? {};
      if (filters.paymentId !== 0) {
        filters.paymentStates = [];
      }
      try {
        const { returned, payments, paymentDateRange, dayVal } = yield call(
          API.managePayments.payments.return,
          {
            params,
            filters: {
              ...filters,
              limit:
                filters.offset > DEFAULT_PAYMENTS_LIMIT
                  ? filters.offset
                  : DEFAULT_PAYMENTS_LIMIT,
              offset: 0,
            },
          }
        );
        yield put(managePayment.returnPaymentSuccess(returned));
        if (Array.isArray(payments)) {
          const hasMorePayments = payments.length % filters.limit == 0;
          yield put(
            managePayment.searchPaymentsSuccess({
              payments,
              dayVal,
              paymentDateRange,
              offset: 0,
              hasMorePayments,
            })
          );
        } else {
          yield put(
            managePayment.searchPaymentsNoResult({
              payments: [],
              dayVal,
              paymentDateRange,
            })
          );
        }
        $MessageBox(
          "success",
          `US.COLLECTION.ECONOMY:MESSAGES.PAYMENT_RETURNED_SUCCESSFULLY`,
          "",
          ""
        );
      } catch (error) {
        yield put(managePayment.returnPaymentFail(error));
      }
    },
  },
};

export default [
  takeLatest(
    ManagePayments.INIT_PAYMENTS,
    ManagePaymentSagas.managePayment.init
  ),
  takeLatest(
    ManagePaymentsGrid.INIT_PAYMENTS_GRID,
    ManagePaymentSagas.managePayment.initGrid
  ),
  takeLatest(
    ManagePayments.GET_PAYMENT_ACCOUNTS,
    ManagePaymentSagas.managePayment.paymentAccounts
  ),
  takeLatest(
    ManagePayments.SEARCH_PAYMENTS,
    ManagePaymentSagas.managePayment.payments
  ),
  takeLatest(
    ManagePaymentsGrid.GET_PAYMENTS_GRID,
    ManagePaymentSagas.managePayment.paymentsGrid
  ),
  takeLatest(
    ManagePayments.GET_PAYMENTS_DISTRIBUTION,
    ManagePaymentSagas.managePayment.paymentDistribution
  ),
  takeLatest(
    ManagePayments.RETURN_PAYMENT,
    ManagePaymentSagas.payments.transfer
  ),
  takeLatest(ManagePayments.EDIT_PAYMENT, ManagePaymentSagas.payments.edit),
  takeLatest(ManagePayments.GET_CASES, ManagePaymentSagas.managePayment.cases),
  takeLatest(
    ManagePayments.MATCH_PAYMENT,
    ManagePaymentSagas.managePayment.match
  ),
  takeLatest(
    ManagePayments.GET_CASE_VALIDATION_DATA,
    ManagePaymentSagas.managePayment.getValidateCaseId
  ),
  takeLatest(
    ManagePayments.REVERSE_RETURNED_PAYMENT,
    ManagePaymentSagas.managePayment.reverseReturenedPayment
  ),
  takeLatest(
    ManagePaymentsApportionment.GET_PAYMENTS_APPORTIONMENT,
    ManagePaymentSagas.managePayment.getApportionments
  ),
  takeLatest(
    ManagePaymentsApportionment.GET_VAT_AMOUNT,
    ManagePaymentSagas.managePayment.getVatAmounts
  ),
  takeLatest(
    ManagePaymentsApportionment.GET_VAT_AMOUNT_FOR_RETURN_TO_DEBTOR,
    ManagePaymentSagas.managePayment.getVatAmountsForReturnFee
  ),
  takeLatest(
    ManagePaymentsApportionment.UPDATE_APPORTIONMENT,
    ManagePaymentSagas.managePayment.updateApportionment
  ),
  takeLatest(
    ManagePayments.REVERT_MAPPED_PAYMENT,
    ManagePaymentSagas.managePayment.returnMappedPayment
  ),
];
