import { isNumber } from "lodash";
import moment from "moment";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";
import { IVatCodeSave } from "us.collection.economy/interfaces";

export function SaveVatCode(this: any): IVatCodeSave {
  return {
    vatId: this.vatId ?? -1,
    vatCode: this.vatCode,
    name: this.name,
    accountID: isNumber(this.accountName) ? this.accountName : this.accountID,
    description: this.description ?? "",
    startDate: moment(this.startDate).format(DATE_FORMAT_FOR_SERVER),
    endDate: this.endDate
      ? moment(this.endDate).format(DATE_FORMAT_FOR_SERVER)
      : moment(this.startDate).add(5, "months").format(DATE_FORMAT_FOR_SERVER),
    rate: this.rate,
  };
}
