export enum DebtorType {
  COMPANY = "N",
  PERSON = "P",
}

export enum ProvisionProfileSearchStatus {
  ALL = "ALL",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export const LAST_MAIN_AMOUNT_RANGE_VALUE = 999999999;

export const DEFAULT_MAIN_AMOUNT_RANGES = [
  [1, 1249],
  [1250, 4999],
  [5000, 9999],
  [10000, LAST_MAIN_AMOUNT_RANGE_VALUE],
];

export const NewOrderLine = {
  startMainAmount: 0,
  endMainAmount: 0,
  mainAmountProvisionRate: 0,
  interestProvisionRate: 0,
  mainAmountRange: [0, LAST_MAIN_AMOUNT_RANGE_VALUE],
  debtorType: DebtorType.PERSON,
};

export const DefaultProfile = {
  profileId: 0,
  profileName: null,
  createdDate: null,
  isActive: true,
  isUsed: false,
  maximumInterestProvision: 0,
  maximumMainAmountProvision: 0,
  modifiedDate: null,
  modifiedUser: null,
  profileDetailsPerson: [
    {
      detailId: 17,
      startMainAmount: 1,
      endMainAmount: 1249,
      mainAmountProvisionRate: 0,
      interestProvisionRate: 0,
      debtorType: DebtorType.PERSON,
      isUsed: false,
      mainAmountRange: [1, 1249],
    },
    {
      detailId: 18,
      startMainAmount: 1250,
      endMainAmount: 4999,
      mainAmountProvisionRate: 0,
      interestProvisionRate: 0,
      debtorType: DebtorType.PERSON,
      isUsed: false,
      mainAmountRange: [1250, 4999],
    },
    {
      detailId: 19,
      startMainAmount: 5000,
      endMainAmount: 9999,
      mainAmountProvisionRate: 0,
      interestProvisionRate: 0,
      debtorType: DebtorType.PERSON,
      isUsed: false,
      mainAmountRange: [5000, 9999],
    },
    {
      detailId: 20,
      startMainAmount: 10000,
      endMainAmount: LAST_MAIN_AMOUNT_RANGE_VALUE,
      mainAmountProvisionRate: 0,
      interestProvisionRate: 0,
      debtorType: DebtorType.PERSON,
      isUsed: false,
      mainAmountRange: [10000, LAST_MAIN_AMOUNT_RANGE_VALUE],
    },
  ],
  profileDetailsCompany: [
    {
      detailId: 21,
      startMainAmount: 1,
      endMainAmount: 1249,
      mainAmountProvisionRate: 0,
      interestProvisionRate: 0,
      debtorType: DebtorType.COMPANY,
      isUsed: false,
      mainAmountRange: [1, 1249],
    },
    {
      detailId: 22,
      startMainAmount: 1250,
      endMainAmount: 4999,
      mainAmountProvisionRate: 0,
      interestProvisionRate: 0,
      debtorType: DebtorType.COMPANY,
      isUsed: false,
      mainAmountRange: [1250, 4999],
    },
    {
      detailId: 23,
      startMainAmount: 5000,
      endMainAmount: 9999,
      mainAmountProvisionRate: 0,
      interestProvisionRate: 0,
      debtorType: DebtorType.COMPANY,
      isUsed: false,
      mainAmountRange: [5000, 9999],
    },
    {
      detailId: 24,
      startMainAmount: 10000,
      endMainAmount: LAST_MAIN_AMOUNT_RANGE_VALUE,
      mainAmountProvisionRate: 0,
      interestProvisionRate: 0,
      debtorType: DebtorType.COMPANY,
      isUsed: false,
      mainAmountRange: [10000, LAST_MAIN_AMOUNT_RANGE_VALUE],
    },
  ],
};
