import * as Yup from "yup";

interface ScheduleSchema extends Yup.MixedSchema {
  accountNo: string;
  returnFee: number;
  perReturnFee: number;
  exdOrUnkwSum: number;
  isBulkVisible: boolean;
}

export default () => {
  return Yup.object<ScheduleSchema>().shape({
    accountNo: Yup.number().when("isBulkVisible", {
      is: false,
      then: Yup.number()
        .required("US.COLLECTION.VALIDATIONS:REQUIRED.ACCOUNT_NO_IS_REQUIRED")
        .typeError("US.COLLECTION.VALIDATIONS:INVALID.INVALID_ACCOUNT_NO")
    }),
    returnFee: Yup.number()
      .test(
        "is returnFee positive",
        "US.COLLECTION.VALIDATIONS:INVALID.VALID_FEE_REQUIRED",
        (value: any) => value >= 0
      )
      .test(
        "max returnFee",
        "US.COLLECTION.VALIDATIONS:INVALID.VALID_FEE_REQUIRED",
        function (value: any) {
          return this?.parent.exdOrUnkwSum >= value;
        }
      )
      .typeError("US.COLLECTION.VALIDATIONS:INVALID.INVALID_RETURN_FEE"),
    perReturnFee: Yup.number()
      .test(
        "Is perReturnFee positive",
        "US.COLLECTION.VALIDATIONS:INVALID.VALID_FEE_REQUIRED",
        (value: any) => value >= 0
      )
      .test(
        "max perReturnFee",
        "US.COLLECTION.VALIDATIONS:INVALID.VALID_FEE_REQUIRED",
        function (value: any) {
          return this?.parent.totalExdAmount >= value * this?.parent?.noOfRecords;
        }
      )
      .typeError("US.COLLECTION.VALIDATIONS:INVALID.INVALID_RETURN_FEE"),
  });
}; 
