import React from "react";
import Economy from "us.collection.economy";
import { Route } from "react-router-dom";
import {
  MainSearch,
  UserRoleSettings,
  UserSettings,
  Home
} from "./asyncRoutes";

export default (caseNo: number) => {
  const MainViewModules = [Economy];

  return [
    <Route
      exact
      key="search"
      path={"/search"}
      render={(props) => <MainSearch {...props} />}
    />,
    <Route
      exact
      key="/"
      path={"/"}
      render={() => <Home />}
    />,
    ...MainViewModules.map(({ Routes }) => Routes.asyncRoutes),
  ];
};
