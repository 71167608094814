import { IUpdateArticle } from "../Interface";

export function UpdateArticle(this: IUpdateArticle) {
  return {
    articleId: this.articleId,
    articleNo: this.articleNo,
    description: this.description,
    vatCode: this.vatCodeId,
    revenueAccountId: this.revenueAccountId,
    nonVatAccountId: this.nonVatAccountId,
    categoryId: this.categoryId,
    defaultPrice: this.defaultPrice != '' ? this.defaultPrice : 0
  };
}
