import { IJournalItem } from "../Interfaces";

export const initialJournalData = {
  key: Math.random().toString(36).slice(2),
  accountId: null,
  journalId: "",
  creditorAmount: 0,
  debitAmount: 0,
  voucher: "",
  caseOrSubcase: "",
  text: "",
};

export const journalItem: IJournalItem = {
  values: [initialJournalData],
};

export enum journalAmountType {
  CREDIT = "Credit",
  DEBIT = "Debit",
}

export enum RemitHistorySearchType {
  JOURNAL_ID = "JOURNALID",
  FILE_DATE = "FILEDATE",
}

export enum JournalEntryTableColumn {
  MENU = "menu",
  ACCOUNT_ID = "accountId",
  JOURNAL_ID = "journalId",
  CREDITOR_AMOUNT = "creditorAmount",
  DEBIT_AMOUNT = "debitAmount",
  VOUCHER = "voucher",
  CASE_OR_SUB_CASE = "caseOrSubcase",
  TEXT = "text",
}

export const COLUMNS = [
  {
    key: JournalEntryTableColumn.MENU,
    title: "",
  },
  {
    key: JournalEntryTableColumn.ACCOUNT_ID,
    title: "US.COLLECTION.ECONOMY:JOURNAL.ACCOUNT",
  },
  {
    key: JournalEntryTableColumn.JOURNAL_ID,
    title: "US.COLLECTION.ECONOMY:JOURNAL.JOURNAL_ID",
  },
  {
    key: JournalEntryTableColumn.DEBIT_AMOUNT,
    title: "US.COLLECTION.ECONOMY:JOURNAL.DEBIT_AMOUNT",
  },
  {
    key: JournalEntryTableColumn.CREDITOR_AMOUNT,
    title: "US.COLLECTION.ECONOMY:JOURNAL.CREDIT_AMOUNT",
  },
  {
    key: JournalEntryTableColumn.VOUCHER,
    title: "US.COLLECTION.ECONOMY:JOURNAL.VOUCHER",
  },
  {
    key: JournalEntryTableColumn.CASE_OR_SUB_CASE,
    title: "US.COLLECTION.ECONOMY:JOURNAL.CASE/SUBCASE",
  },
  {
    key: JournalEntryTableColumn.TEXT,
    title: "US.COLLECTION.ECONOMY:JOURNAL.TEXT",
  },
];

export enum ColumnSearchType {
  STRING = "string",
  NUMBER = "number",
}

export enum Align {
  CENTER = "center",
  RIGHT = "right",
  LEFT = "left",
}

export const DEFAULT_TABLE_INFO = {
  filters: [],
  sorter: { columnKey: "", order: "" },
  data: [],
};

export enum JournalAction {
  DELETE = "DELETE",
  OPEN_DRAWER = "open_drawer",
}
