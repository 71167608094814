import * as Yup from "yup";
import { OrderGenerationTypes } from "us.collection.economy/components/ClientOrders/Constants";

export default () => {
  return Yup.object().shape({
    orderType: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.ORDER_TYPE_IS_REQUIRED"
    ),
    categories: Yup.string().when("orderType", {
      is: (orderType) => orderType === OrderGenerationTypes.CREDITOR_ORDERS,
      then: Yup.string().required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.CATEGORY_IS_REQUIRED"
      ),
    }),
    creditors: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.CATEGORY_IS_REQUIRED"
    ),
  });
};
