import { ManualOrders } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";
import { IManualOrdersAction } from "us.collection.economy/interfaces";

export const manualOrders: Readonly<IAPIAction & IManualOrdersAction> = {
  article: {
    get: (data) => ({
      type: ManualOrders.GET_ARTICLE_BY_NO,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.GET_ARTICLE_BY_NO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.GET_ARTICLE_BY_NO_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  articleList: {
    get: (data) => ({
      type: ManualOrders.GET_ARTICLE_LIST,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.GET_ARTICLE_LIST_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.GET_ARTICLE_LIST_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    openDrawer: (data) => ({
      type: ManualOrders.CHANGE_ARTICLE_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
    update: (data) => ({
      type: ManualOrders.FILTER_ARTICLE_LIST,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  orderLines: {
    save: (data) => ({
      type: ManualOrders.SAVE_ORDER_LINE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.SAVE_ORDER_LINE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.SAVE_ORDER_LINE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: ManualOrders.RESET_SHOW_MODAL_STATUS,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
  },
  caseNoValidation: {
    get: (data) => ({
      type: ManualOrders.VALIDATE_CASE_NO,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.VALIDATE_CASE_NO_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.VALIDATE_CASE_NO_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  invoicedOrderLines: {
    save: (data) => ({
      type: ManualOrders.EXPORT_ORDER_LINE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.EXPORT_ORDER_LINE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.EXPORT_ORDER_LINE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: ManualOrders.RESET_ORDER_DATA,
      payload: {
        data: data,
        isLoading: true,
      },
    }),
  },
  orderTableData: {
    update: (data) => ({
      type: ManualOrders.UPDATE_ORDER_TABLE_DATA,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.UPDATE_ORDER_TABLE_DATA_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.UPDATE_ORDER_TABLE_DATA_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  creditorValidation: {
    get: (data) => ({
      type: ManualOrders.VALIDATE_CREDITOR_BY_ID,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.VALIDATE_CREDITOR_BY_ID_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.VALIDATE_CREDITOR_BY_ID_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  selectedCreditorData: {
    update: (data) => ({
      type: ManualOrders.UPDATE_SELECTED_CREDITOR_DATA,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.UPDATE_SELECTED_CREDITOR_DATA_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.UPDATE_SELECTED_CREDITOR_DATA_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  nextOrderId: {
    get: (data) => ({
      type: ManualOrders.GET_NEXT_ORDER_ID,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.GET_NEXT_ORDER_ID_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.GET_NEXT_ORDER_ID_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  orderDetails: {
    get: (data) => ({
      type: ManualOrders.GET_ORDER_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.GET_ORDER_DETAILS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.GET_ORDER_DETAILS_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  existingOrderLines: {
    update: (data) => ({
      type: ManualOrders.UPDATE_ORDER_LINE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.UPDATE_ORDER_LINE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.UPDATE_ORDER_LINE_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  vatAmounts: {
    get: (data) => ({
      type: ManualOrders.GET_VAT_AMOUNTS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.GET_VAT_AMOUNTS_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.GET_VAT_AMOUNTS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  tableData: {
    update: (data) => ({
      type: ManualOrders.FILTER_TABLE_DATA,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.FILTER_TABLE_DATA_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.FILTER_TABLE_DATA_FAIL,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  },
  orderLine: {
    delete: (data) => ({
      type: ManualOrders.DELETE_ORDER_LINE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.DELETE_ORDER_LINE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.DELETE_ORDER_LINE_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  orderType: {
    update: (data) => ({
      type: ManualOrders.UPDATE_ORDER_TYPE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ManualOrders.UPDATE_ORDER_TYPE_SUCCESS,
      payload: {
        data: data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ManualOrders.UPDATE_ORDER_TYPE_FAILED,
      payload: {
        error: error,
        isLoading: false,
      },
    }),
  }
};
