import React, { useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import * as Actions from "us.collection.economy/actions";
import { connect, ConnectedProps } from "react-redux";
import { IImportPayments } from "./interface";
import { UploadOutlined } from "us.icons";
import { Uploader, Summary } from "./Component";
import { ImportTypes, FileTypes } from "./Constants";
import { PaymentImport } from "./Repository";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import "./Home.scss";
import { handleGoBack } from "us.helper/utility";

const { $Affix, $PageHeader, $Select, $Button, $Form, $Divider } =
  Common.Components;

const ImportPayments: React.FC<IImportPayments & PropsFromRedux> = (props) => {
  const {
    fileList,
    initialValues,
    uploadedResult,
    isUploading,
    isImporting,
    uploadPayment,
    importPayments,
    resetFileImport,
    updateFiles,
  } = props;

  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    resetFileImport && resetFileImport({});
  }, []);

  const handleSubmit = (values: any, type: ImportTypes) => {
    const params = PaymentImport.call({ ...values, type, fileList });
    if (type == ImportTypes.upload) {
      uploadPayment && uploadPayment(params);
    } else {
      importPayments && importPayments(params);
    }
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({ values }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t(
                      "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.IMPORT_PAYMENTS"
                    )}
                  />
                </div>
              </div>
            </$Affix>
            <div className="imp-pay_content mt-4">
              {_.isEmpty(uploadedResult) && (
                <$Form layout="vertical">
                  <$Select
                    name="fileType"
                    dataTestid={"select-file-type"}
                    formitem={{
                      label: t(
                        "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.SELECT_FILE_TYPE"
                      ),
                    }}
                    size="small"
                    options={[
                      {
                        label: t(
                          "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.PAYMENT_OCR_FILE"
                        ),
                        value: FileTypes.OCR,
                      },
                      {
                        label: t(
                          "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.PAYMENT_LINDOFF_FILE"
                        ),
                        value: FileTypes.LINDOFF,
                      },
                    ]}
                    optionText="label"
                    defaultValue={FileTypes.OCR}
                    allOption={false}
                    autoFocus={true}
                    tabIndex={1}
                    required
                  />
                  <Uploader
                    fileList={fileList}
                    onChangeFile={(files: Array<any>) =>
                      updateFiles && updateFiles(files)
                    }
                    uploadRules={{ formats: ".txt, .dat, .csv", maxCount: 1, maxSize: 5 }}
                  />
                </$Form>
              )}
              {!_.isEmpty(uploadedResult) && <Summary />}
              <$Divider />
              {!_.isEmpty(uploadedResult) && (
                <div>
                  <$Button
                    className="mb-2"
                    type="primary"
                    size="large"
                    block
                    data-testid="import-btn"
                    loading={isImporting}
                    disabled={isImporting}
                    onClick={() => handleSubmit(values, ImportTypes.import)}
                    icon={<UploadOutlined />}
                  >
                    {t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.IMPORT")}
                  </$Button>
                  <$Button
                    type="default"
                    size="large"
                    data-testid="cancel-import"
                    block
                    disabled={isImporting}
                    onClick={() => {
                      resetFileImport && resetFileImport({});
                    }}
                  >
                    {t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.CANCEL")}
                  </$Button>
                </div>
              )}

              {_.isEmpty(uploadedResult) && (
                <$Button
                  type="primary"
                  size="large"
                  data-testid="upload-btn"
                  block
                  loading={isUploading}
                  disabled={isUploading || fileList.length == 0}
                  icon={<UploadOutlined />}
                  onClick={() => handleSubmit(values, ImportTypes.upload)}
                >
                  {t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.UPLOAD")}
                </$Button>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
const mapStateToProps = (state: any) => {
  const { importPayments, common } = state;

  const { initialValues, upload, importFiles, fileList } = importPayments;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;

  return {
    initialValues,
    fileList,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    uploadedResult: upload.data,
    isUploading: upload.isLoading,
    isImporting: importFiles.isLoading,
  };
};

const { upload, importFiles } = Actions.importPayments;
const { save, reset, updateFiles } = upload;

const mapDispatchToProps = {
  updateFiles,
  uploadPayment: save,
  resetFileImport: reset,
  importPayments: importFiles.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ImportPayments);
