import moment from "moment";
import { isArrayMatch } from "us.collection.economy/functions/ManagePayments/gridBase";
import {
  PaymentStates,
  TagClasses,
  DATE_FORMAT_FOR_SERVER,
} from "us.collection.economy/constants/Payments/ManagePayments";
import {
  ActionTypes,
  BulkTransfer,
  IActionTypes,
  IPayment,
  IPaymentDistribution,
  IPaymentStates,
} from "us.collection.economy/components/ManagePayments/interface";
import { GetPayments } from "us.collection.economy/components/ManagePayments/Repository";
import { OCR_ITEM_TYPE_IDS } from "../Constants";
/**
 * @class
 */
export default class PaymentHelper {
  private payment: any;
  readonly mainStatus: IPaymentStates;
  readonly mainStatusAmount: number;
  readonly headerActions: ActionTypes[];
  readonly subHeaderActions: ActionTypes[];
  readonly tagName: string;
  readonly tagClass: string;
  readonly isMappedWithOther: boolean;
  readonly isReturnedWithOther: boolean;
  readonly hasPaymentDistribution: boolean;
  readonly isFullyReturn: boolean;
  readonly mappedItems: Array<IPaymentDistribution>;
  readonly returnedItems: Array<IPaymentDistribution>;
  private static linkedPaymentId: number | undefined;
  /**
   * Represent a `PaymentHandler`
   * - Create an instance of `PaymentHandler`
   * - Use this helper class to extract data from a given payment item
   * @constructor
   * @param {any} payment - the payment item of the `PaymentHelper`
   * @readonly `mainStatus` - main status of the payment
   * @readonly `isReturnedWithOther` - whether the given payment has a return with other exceeded or mapped
   */
  constructor(payment: IPayment) {
    this.payment = payment;
    this.mainStatus = PaymentHelper.getMainStatus(payment).tag;
    this.isReturnedWithOther = this.isReturnedWithOtherPayment();
    this.isMappedWithOther = this.isMappedWithOtherPayment();
    this.mainStatusAmount = PaymentHelper.getMainStatus(payment).amount;
    this.headerActions = this.getHeaderActions();
    this.subHeaderActions = this.getSubHeaderActions();
    this.tagName = PaymentHelper.getMainStatus(payment).name;
    this.tagClass = PaymentHelper.getMainStatus(payment).tagClass;
    this.mappedItems = this.getMappedItems();
    this.returnedItems = this.getReturnedItems();
    this.hasPaymentDistribution = this.setHasPaymentDistribution();
    this.isFullyReturn = this.setIsFullyReturn();
  }

  public static getMainStatus(payment: any): {
    tag: IPaymentStates;
    amount: number;
    name: string;
    tagClass: string;
  } {
    const { exceededSum, returnedToDebtorSum, mappedSum, unknownSum } = payment;
    if (exceededSum > 0) {
      return {
        tag: PaymentStates.EXCEEDED,
        amount: exceededSum,
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED",
        tagClass: TagClasses.EXCEEDED,
      };
    } else if (returnedToDebtorSum > 0) {
      return {
        tag: PaymentStates.RETURNED,
        amount: returnedToDebtorSum,
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR",
        tagClass: TagClasses.RETURNED,
      };
    } else if (mappedSum > 0) {
      return {
        tag: PaymentStates.MAPPED,
        amount: mappedSum,
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED",
        tagClass: TagClasses.MAPPED,
      };
    } else if (unknownSum > 0) {
      return {
        tag: PaymentStates.UNKNOWN,
        amount: unknownSum,
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN",
        tagClass: TagClasses.UNKNOWN,
      };
    } else {
      return { tag: "", amount: 0, name: "", tagClass: "" };
    }
  }

  public static getPriorityStatusData(
    payment: any,
    priorityTag?: IPaymentStates
  ): {
    amount: number;
    name: string;
    tagClass: string;
  } {
    const { exceededSum, returnedToDebtorSum, mappedSum, unknownSum } = payment;
    if (exceededSum > 0 && priorityTag === PaymentStates.EXCEEDED) {
      return {
        amount: exceededSum,
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED",
        tagClass: TagClasses.EXCEEDED,
      };
    } else if (
      returnedToDebtorSum > 0 &&
      priorityTag === PaymentStates.RETURNED
    ) {
      return {
        amount: returnedToDebtorSum,
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR",
        tagClass: TagClasses.RETURNED,
      };
    } else if (mappedSum > 0 && priorityTag === PaymentStates.MAPPED) {
      return {
        amount: mappedSum,
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED",
        tagClass: TagClasses.MAPPED,
      };
    } else if (unknownSum > 0 && priorityTag === PaymentStates.UNKNOWN) {
      return {
        amount: unknownSum,
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN",
        tagClass: TagClasses.UNKNOWN,
      };
    } else {
      return { amount: 0, name: "", tagClass: "" };
    }
  }

  private getHeaderActions(): ActionTypes[] {
    if (this.mainStatus === PaymentStates.EXCEEDED) {
      return [];
    } else if (this.mainStatus === PaymentStates.MAPPED) {
      return [ActionTypes.REVERT_PAYMENT];
    } else if (this.mainStatus === PaymentStates.RETURNED) {
      return [ActionTypes.APPORTION, ActionTypes.REVERT_TO_PAYMENT];
    } else {
      return [];
    }
  }

  private getSubHeaderActions(): ActionTypes[] {
    const { mainStatus, isMappedWithOther } = this;
    const transfer = PaymentHelper.getTransferVisibleStatus(this.payment)
      ? [ActionTypes.TRANSFER]
      : [];
    const edit = PaymentHelper.getEditVisibleStatus(this.payment)
      ? [ActionTypes.EDIT]
      : [];
    if (mainStatus === PaymentStates.EXCEEDED) {
      if (isMappedWithOther) {
        return [ActionTypes.MATCH, ...transfer];
      } else {
        return [ActionTypes.MATCH, ...transfer, ...edit];
      }
    } else if (
      mainStatus === PaymentStates.MAPPED ||
      mainStatus === PaymentStates.RETURNED
    ) {
      return [];
    } else {
      return [ActionTypes.MATCH, ...transfer, ...edit];
    }
  }

  public static getTag(mainStatus: IPaymentStates): {
    name: string;
    className: string;
  } {
    if (mainStatus === PaymentStates.EXCEEDED) {
      return {
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED",
        className: TagClasses.EXCEEDED,
      };
    } else if (mainStatus === PaymentStates.MAPPED) {
      return {
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED",
        className: TagClasses.MAPPED,
      };
    } else if (mainStatus === PaymentStates.RETURNED) {
      return {
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR",
        className: TagClasses.RETURNED,
      };
    } else {
      return {
        name: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN",
        className: TagClasses.UNKNOWN,
      };
    }
  }

  private isMappedWithOtherPayment(): boolean {
    const distributedStatus: IPaymentStates[] = [];
    this.payment.paymentDistribution?.map((paymentDistribution: any) => {
      distributedStatus.push(paymentDistribution.paymentStatus);
    });
    const mappedWithExceeded =
      distributedStatus.includes(PaymentStates.EXCEEDED) &&
      distributedStatus.includes(PaymentStates.MAPPED);

    const mappedWithReturn =
      distributedStatus.includes(PaymentStates.MAPPED) &&
      distributedStatus.includes(PaymentStates.RETURNED);

    const mappedWithExceededAndReturn =
      distributedStatus.includes(PaymentStates.EXCEEDED) &&
      distributedStatus.includes(PaymentStates.MAPPED) &&
      distributedStatus.includes(PaymentStates.RETURNED);

    return (
      mappedWithExceeded || mappedWithReturn || mappedWithExceededAndReturn
    );
  }

  private isReturnedWithOtherPayment(): boolean {
    const distributedStatus: IPaymentStates[] = [];
    this.payment.paymentDistribution?.map((paymentDistribution: any) => {
      distributedStatus.push(paymentDistribution.paymentStatus);
    });

    const returnWithExceeded =
      distributedStatus.includes(PaymentStates.EXCEEDED) &&
      distributedStatus.includes(PaymentStates.RETURNED);
    const returnWithMapped =
      distributedStatus.includes(PaymentStates.MAPPED) &&
      distributedStatus.includes(PaymentStates.RETURNED);
    const returnWithMappedExceeded =
      distributedStatus.includes(PaymentStates.EXCEEDED) &&
      distributedStatus.includes(PaymentStates.MAPPED) &&
      distributedStatus.includes(PaymentStates.RETURNED);

    return (
      this.mainStatus !== PaymentStates.RETURNED &&
      (returnWithExceeded || returnWithMapped || returnWithMappedExceeded)
    );
  }

  private getMappedItems(): Array<IPaymentDistribution> {
    return this.payment.paymentDistribution?.filter(
      ({ paymentStatus }: IPaymentDistribution) =>
        paymentStatus === PaymentStates.MAPPED
    );
  }

  private getReturnedItems(): Array<IPaymentDistribution> {
    return this.payment.paymentDistribution?.filter(
      ({ paymentStatus }: IPaymentDistribution) =>
        paymentStatus === PaymentStates.RETURNED
    );
  }

  private setHasPaymentDistribution(): boolean {
    const distributedStatus: IPaymentStates[] = [];
    this.payment.paymentDistribution?.map((item: IPaymentDistribution) => {
      distributedStatus.push(item.paymentStatus);
    });

    const returnWithExceeded =
      distributedStatus.includes(PaymentStates.EXCEEDED) &&
      distributedStatus.includes(PaymentStates.RETURNED) &&
      !distributedStatus.includes(PaymentStates.MAPPED);

    const returnOnly =
      distributedStatus.includes(PaymentStates.RETURNED) &&
      !distributedStatus.includes(PaymentStates.EXCEEDED) &&
      !distributedStatus.includes(PaymentStates.MAPPED);

    return !returnWithExceeded && !returnOnly;
  }

  private setIsFullyReturn(): boolean {
    const distributedStatus: IPaymentStates[] = [];
    this.payment.paymentDistribution?.map((item: IPaymentDistribution) => {
      distributedStatus.push(item.paymentStatus);
    });

    const fullyReturn =
      distributedStatus.includes(PaymentStates.RETURNED) &&
      !distributedStatus.includes(PaymentStates.EXCEEDED) &&
      !distributedStatus.includes(PaymentStates.MAPPED);

    return fullyReturn;
  }

  /**
   * @method
   * @param payment - a specific payment
   * @returns exceeded or unknown item
   */
  public static getExceededOrUnknownItem(
    payment: IPayment
  ): IPaymentDistribution | undefined {
    try {
      if (payment) {
        const { exceededSum, paymentDistribution, unknownSum } = payment;
        if (exceededSum > 0) {
          return paymentDistribution?.filter(
            ({ paymentStatus }: IPaymentDistribution) =>
              paymentStatus === PaymentStates.EXCEEDED
          )[0];
        } else if (unknownSum > 0) {
          return paymentDistribution?.filter(
            ({ paymentStatus }: IPaymentDistribution) =>
              paymentStatus === PaymentStates.UNKNOWN
          )[0];
        } else {
          return undefined;
        }
      }
      return undefined;
    } catch (error) {
      return undefined;
    }
  }

  /**
   * @method
   * @param {number} paymentId - a paymentId for a specific payment
   * @param {Array<IPayment>} - Array of Payments items
   * @returns the selected payment
   */
  public static getPayment(paymentId: number, payments: any[]): any {
    const payment: any = PaymentHelper.getPaymentById(paymentId, payments);
    if (payment) {
      const distribution: any = PaymentHelper.getExceededOrUnknownItem(payment);
      const _dsbKey = payment?.exceededSum > 0 ? "exceededSum" : "unknownSum";
      return {
        payment: {
          ...payment,
          amount: payment?.[_dsbKey],
          accountNo: payment?.accountNo ? payment?.accountNo : "",
        },
        distribution: {
          ...distribution,
          actualReturnFee: distribution.returnfee,
          returnfee:
            payment?.[_dsbKey] <= distribution.returnfee
              ? payment?.[_dsbKey]
              : distribution.returnfee,
        },
        bulk: {
          ...PaymentHelper.getBulkTransferData(payments, paymentId),
        },
      };
    }
  }

  /**
   * @method
   * @param {number} paymentId - a paymentId for a specific payment
   * @param {Array<IPayment>} - Array of Payments items to check with the given `paymentId`
   * @returns the matching payment of the given `paymentId`
   */
  public static getPaymentById(
    paymentId: number,
    payments: Array<IPayment>
  ): IPayment {
    return payments?.filter(
      (payment: IPayment) => payment.paymentId === paymentId
    )[0];
  }

  public static hasMatch(
    paymentId: number,
    payments: Array<IPayment>
  ): boolean {
    try {
      const payment = this.getPaymentById(paymentId, payments);
      if (payment) {
        const { exceededSum, unknownSum, linkedPaymentId } = payment;
        return exceededSum > 0 || (unknownSum > 0 && linkedPaymentId === 0);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public static hasTransfer(
    paymentId: number,
    payments: Array<IPayment>
  ): boolean {
    try {
      const payment = this.getPaymentById(paymentId, payments);
      if (payment) {
        const { exceededSum, unknownSum, linkedPaymentId } = payment;
        return (
          this.getTransferVisibleStatus(payment) &&
          (exceededSum > 0 || (unknownSum > 0 && linkedPaymentId === 0))
        );
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public static hasEdit(paymentId: number, payments: Array<IPayment>): boolean {
    try {
      const payment = this.getPaymentById(paymentId, payments);
      if (payment) {
        return this.getEditVisibleStatus(payment);
      }
      return false;
    } catch (error) {
      return false;
    }
  }

  public static getDefaultPaymentAction(
    paymentId: number,
    payments: Array<IPayment>,
    debtorOtherCasesStatus: 1 | 2
  ): IActionTypes {
    try {
      const payment = this.getPaymentById(paymentId, payments);
      if (payment) {
        const { exceededSum, unknownSum, linkedPaymentId } = payment;
        if (exceededSum > 0 || (unknownSum > 0 && linkedPaymentId === 0)) {
          return debtorOtherCasesStatus === 1
            ? ActionTypes.TRANSFER
            : ActionTypes.MATCH;
        }
      }
      return ActionTypes.NON;
    } catch (error) {
      return ActionTypes.NON;
    }
  }

  public static getEditVisibleStatus(payment: IPayment): boolean {
    try {
      const { returnedToDebtorSum, mappedSum, fileName, paymentDistribution } =
        payment;
      const isExedOrUnkwn = returnedToDebtorSum === 0 && mappedSum === 0;
      const isManualPayment = fileName === "Manual";
      const isRemitted = paymentDistribution?.filter(
        ({ paymentStatus }: IPaymentDistribution) =>
          paymentStatus === PaymentStates.EXCEEDED ||
          paymentStatus === PaymentStates.UNKNOWN
      )[0]?.isRemitted;
      return isExedOrUnkwn && isManualPayment && !isRemitted;
    } catch (error) {
      return false;
    }
  }

  public static getTransferVisibleStatus(payment: IPayment): boolean {
    const { itemType } = payment;
    return itemType !== "DB";
  }

  /**
   * @method
   * Filter the given `payments` by `paymentId`, `caseNo`, `kid` and `voucherNo`
   * @param {string} paymentId
   * @param {string} caseNo
   * @param {string} kid
   * @param {string} voucherNo
   * @param {Array<IPayment>} payments
   */
  public static filterPayments(
    paymentId: string,
    caseNo: string,
    kid: string,
    voucherNo: string,
    payments: Array<IPayment>
  ): Array<IPayment> {
    try {
      const filteredByPaymentId = payments.filter((payment: IPayment) =>
        payment.paymentId?.toString().includes(paymentId)
      );
      const filteredByCaseId =
        caseNo || filteredByPaymentId.length > 0
          ? filteredByPaymentId.length > 0
            ? filteredByPaymentId?.filter((payment: IPayment) =>
                payment.caseNo?.toString().includes(caseNo)
              )
            : payments?.filter((payment: IPayment) =>
                payment.caseNo?.toString().includes(caseNo)
              )
          : payments;

      const filteredByKid =
        kid || filteredByCaseId.length > 0
          ? filteredByCaseId.length > 0
            ? filteredByCaseId.filter((payment: IPayment) =>
                payment.kid.includes(kid)
              )
            : payments.filter((payment: IPayment) => payment.kid.includes(kid))
          : payments;
      return voucherNo || filteredByKid.length > 0
        ? filteredByKid.length > 0
          ? filteredByKid.filter((payment: IPayment) =>
              payment.voucherNo.includes(voucherNo)
            )
          : payments.filter((payment: IPayment) =>
              payment.voucherNo.includes(voucherNo)
            )
        : payments;
    } catch (error) {
      return payments;
    }
  }

  /**
   * @method
   * @param {Array<IPayment>} payments - an array of Payment
   * @returns {BulkTransfer} - an object which contains `noOfRecords`, `perReturnFee`, `totalExceededAmount`, `totalReturnAmount`
   */
  public static getBulkTransferData(
    payments: Array<IPayment>,
    paymentId: number
  ): BulkTransfer {
    // check whether the payment exceeded or unknown
    const isExdItem =
      payments?.filter((payment: any) => payment.paymentId == paymentId)[0]
        ?.exceededSum > 0;

    const payStat = isExdItem ? PaymentStates.EXCEEDED : PaymentStates.UNKNOWN;

    // exceeded and unknown payments with accountNo
    const withAccounts = payments?.filter(
      ({ exceededSum, unknownSum, accountNo }: IPayment) =>
        (exceededSum > 0 || unknownSum > 0) && accountNo
    );
    const hasReturnFee = withAccounts?.filter(
      ({ paymentDistribution }: IPayment) =>
        paymentDistribution
          ?.filter(
            ({ paymentStatus }: IPaymentDistribution) =>
              paymentStatus === PaymentStates.EXCEEDED ||
              paymentStatus === PaymentStates.UNKNOWN
          )
          ?.some(({ returnfee }: IPaymentDistribution) => returnfee > 0)
    );
    const totalExdSum = withAccounts.reduce(
      (a: any, b: any) => a + b?.exceededSum || 0,
      0
    );
    const totalUnwSum = withAccounts.reduce(
      (a: any, b: any) => a + b?.unknownSum || 0,
      0
    );

    const totalExdUnkwnSum = totalExdSum + totalUnwSum;
    const totalReturn = withAccounts.reduce(
      (a, b) => a + b.paymentAmount || 0,
      0
    );
    const returnFee = hasReturnFee[0]?.paymentDistribution?.filter(
      (i: any) => i?.paymentStatus === payStat
    )[0]?.returnfee;
    const perReturnFee = !returnFee
      ? 0
      : totalExdUnkwnSum > returnFee
      ? returnFee
      : 0;
    return {
      paymentsWithAccounts: withAccounts,
      noOfRecords: withAccounts.length,
      perReturnFee: perReturnFee,
      totalExdOrUnkwnAmount: totalExdUnkwnSum,
      totalReturnAmount: totalReturn,
    };
  }

  /**
   * @method
   * @param {Array<IPayment>} payments - an array of Payment
   * @returns {Number} - total return fee
   */
  public static getTotalReturnFee(
    payments: Array<IPayment>,
    perReturnFee: number
  ): number {
    try {
      // exceeded and unknown payments with accountNo
      const withAccounts = payments.filter(
        ({ exceededSum, unknownSum, accountNo }: IPayment) =>
          (exceededSum > 0 || unknownSum > 0) && accountNo
      );
      if (withAccounts.length) {
        return withAccounts.reduce(
          (a: number, { exceededSum, unknownSum }: IPayment) => {
            const exdOrunkwSum = exceededSum > 0 ? exceededSum : unknownSum;
            if (exdOrunkwSum <= perReturnFee) {
              return a + exdOrunkwSum;
            } else {
              return a + perReturnFee;
            }
          },
          0
        );
      } else {
        return 0;
      }
    } catch (error) {
      return 0;
    }
  }

  /**
   * @method
   * @param {Array<IPayment>} payments - an array of Payments
   * @returns {Array<IPayment>} - an array of Payments with returned amount less than or equal to perReturnFee
   */
  public static getBulkDataWithZeroAmount(
    payments: Array<IPayment>,
    perReturnFee: number
  ): Array<any> {
    try {
      return payments.filter(
        ({ exceededSum, unknownSum, accountNo }: IPayment) =>
          (exceededSum > 0 || unknownSum > 0) &&
          accountNo &&
          (exceededSum > 0 ? exceededSum : unknownSum) < perReturnFee
      );
    } catch (error) {
      return payments;
    }
  }

  /**
   * @method
   * @param {Array<IPaymentDistribution>} items - `paymentDistribution` item array for a specific `payment`
   * @param {IPaymentStates[]} filters - `filters` from the Filter as PaymentStatus Array
   * @returns {IPaymentStates} priorityTag - a status (`Exceeded` | `Mapped` | `Returned` | `Unknown`)
   */
  public static getPriorityTag(
    items: Array<IPaymentDistribution>,
    filters: IPaymentStates[]
  ): IPaymentStates {
    const tags: IPaymentStates[] = [];
    items?.map(({ paymentStatus }: IPaymentDistribution) => {
      if (!tags.some((tag: string) => tag === paymentStatus)) {
        tags.push(paymentStatus);
      }
    });

    if (filters.length === 1) {
      return filters[0];
    } else {
      if (
        isArrayMatch(filters, [
          PaymentStates.EXCEEDED,
          PaymentStates.MAPPED,
          PaymentStates.UNKNOWN,
        ]) ||
        isArrayMatch(filters, [PaymentStates.EXCEEDED, PaymentStates.MAPPED])
      ) {
        // exceeded mapped
        if (tags.length === 1) {
          return tags[0];
        } else if (tags.includes(PaymentStates.EXCEEDED)) {
          return PaymentStates.EXCEEDED;
        } else {
          return PaymentStates.RETURNED;
        }
      } else if (
        isArrayMatch(filters, [
          PaymentStates.EXCEEDED,
          PaymentStates.MAPPED,
          PaymentStates.RETURNED,
        ])
      ) {
        // exceeded returned
        if (tags.length === 1) {
          return tags[0];
        } else if (tags.includes(PaymentStates.EXCEEDED)) {
          return PaymentStates.EXCEEDED;
        } else {
          return PaymentStates.RETURNED;
        }
      } else if (
        isArrayMatch(filters, [PaymentStates.EXCEEDED, PaymentStates.RETURNED])
      ) {
        // exceeded returned
        if (tags.length === 1) {
          return tags[0];
        } else if (tags.includes(PaymentStates.EXCEEDED)) {
          return PaymentStates.EXCEEDED;
        } else {
          return PaymentStates.RETURNED;
        }
      } else if (
        isArrayMatch(filters, [PaymentStates.MAPPED, PaymentStates.RETURNED])
      ) {
        // mapped returned
        if (tags.length === 1) {
          return tags[0];
        } else if (tags.includes(PaymentStates.RETURNED)) {
          return PaymentStates.RETURNED;
        } else {
          return PaymentStates.MAPPED;
        }
      } else {
        // all or mapped, exceeded, returned
        if (tags.length === 1) {
          return tags[0];
        } else if (tags.includes(PaymentStates.EXCEEDED)) {
          return PaymentStates.EXCEEDED;
        } else {
          return PaymentStates.RETURNED;
        }
      }
    }
  }

  /**
   * @method
   * @param values - boolean values which represent the toggle filters
   * @returns {Array} - An array of payment filters
   * @example [`Exceeded`, `Mapped`]
   */
  public static getPaymentStatus = ({
    isExceeded,
    isMapped,
    isReturnToDebtor,
    isUnknown,
  }: {
    isExceeded: boolean;
    isMapped: boolean;
    isUnknown: boolean;
    isReturnToDebtor: boolean;
  }): Array<any> => {
    const status: Array<IPaymentStates> = [];

    if (isExceeded) {
      status.push(PaymentStates.EXCEEDED);
    }
    if (isMapped) {
      status.push(PaymentStates.MAPPED);
    }
    if (isUnknown) {
      status.push(PaymentStates.UNKNOWN);
    }
    if (isReturnToDebtor) {
      status.push(PaymentStates.RETURNED);
    }
    return status;
  };

  public static getPaymentAction(actionType: IActionTypes): {
    actionName: string;
    iconName: string;
  } {
    switch (actionType) {
      case ActionTypes.MATCH:
        return {
          actionName: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MATCH",
          iconName: "match",
        };
      case ActionTypes.TRANSFER:
        return {
          actionName: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TRANSFER",
          iconName: "transfer",
        };
      case ActionTypes.EDIT:
        return {
          actionName: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EDIT",
          iconName: "edit",
        };
      case ActionTypes.APPORTION:
        return {
          actionName: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.APPORTION",
          iconName: "apportion",
        };
      case ActionTypes.REVERT_TO_PAYMENT:
        return {
          actionName: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REVERT_TO_PAYMENT",
          iconName: "revert",
        };
      case ActionTypes.REVERT_TO_APPORTIONMENT:
        return {
          actionName:
            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REVERT_TO_APPORTIONMENT",
          iconName: "revert-to-apportionment",
        };
      case ActionTypes.REVERT_PAYMENT:
        return {
          actionName: "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REVERT_PAYMENT",
          iconName: "revert",
        };
      default:
        return {
          actionName: "",
          iconName: "",
        };
    }
  }

  /**
   * @method
   * @param {number} paymentId - a paymentId for a specific payment
   * @param {Array<IPayment>} - Array of Payments items to check with the given `paymentId`
   * @returns the matching payment of the given `paymentId`
   */
  public static getPaymentForMatch(
    paymentId: number,
    payments: Array<any>
  ): any {
    try {
      const payment = payments?.filter(
        (payment: IPayment) => payment.paymentId === paymentId
      )[0];
      if (payment) {
        if (payment.unknownSum > 0) {
          const unknownPayment = payment.paymentDistribution?.filter(
            ({ paymentStatus }: IPaymentDistribution) =>
              paymentStatus === PaymentStates.UNKNOWN
          )[0];
          return {
            ...payment,
            ...unknownPayment,
          };
        }
        if (payment.exceededSum > 0) {
          const exceedPayment = payment.paymentDistribution?.filter(
            ({ paymentStatus }: IPaymentDistribution) =>
              paymentStatus === PaymentStates.EXCEEDED
          )[0];
          return {
            ...payment,
            ...exceedPayment,
          };
        }
        return payment;
      }
    } catch (error) {
      return {};
    }
  }

  /**
   * Get `exceeded` and `unknown` payments count for the given payments
   * @param {Array<IPayment>} payments - Payments data
   * @returns {Object{exceededCount: number; unknownCount: number;}} - `exceededCount` - No of exceeded payments and `unknownCount` - No of unknown payments
   */
  public static getExceedAndUnknownCounts(payments: Array<any>): {
    exceededCount: number;
    unknownCount: number;
  } {
    let exceededCount: number = 0;
    let unknownCount: number = 0;
    try {
      payments.map(({ exceededSum, unknownSum }: IPayment) => {
        exceededSum > 0 && exceededCount++;
        unknownSum > 0 && unknownCount++;
      });
      return { exceededCount, unknownCount };
    } catch (error) {
      return { exceededCount, unknownCount };
    }
  }

  public static filterPaymentsByFileName(
    values: {
      isManual: boolean;
      isOCR: boolean;
      paymentId: string;
      caseNo: string;
      kid: string;
      voucherNo: string;
      ocrFileName: string;
    },
    data: Array<any>
  ): Array<any> {
    try {
      const {
        paymentId,
        kid,
        caseNo,
        voucherNo,
        isManual,
        isOCR,
        ocrFileName,
      } = values;
      if (isManual && isOCR && ocrFileName !== "all") {
        return this.filterPayments(
          paymentId,
          caseNo,
          kid,
          voucherNo,
          data.filter(
            ({ fileName }: IPayment) =>
              fileName === ocrFileName || fileName === "Manual"
          )
        );
      }

      if (!isManual && isOCR && ocrFileName === "all") {
        return this.filterPayments(
          paymentId,
          caseNo,
          kid,
          voucherNo,
          this.getOCRPayments(data)
        );
      }
      if (!isManual && isOCR && ocrFileName !== "all") {
        return this.filterPayments(
          paymentId,
          caseNo,
          kid,
          voucherNo,
          this.getOCRPayments(data).filter(
            ({ fileName }: IPayment) => fileName === ocrFileName
          )
        );
      }
      if (isManual && !isOCR) {
        return this.filterPayments(
          paymentId,
          caseNo,
          kid,
          voucherNo,
          data.filter(({ fileName }: IPayment) => fileName === "Manual")
        );
      }
      if (!isManual && !isOCR) {
        return [];
      }

      return this.filterPayments(paymentId, caseNo, kid, voucherNo, data);
    } catch (error) {
      return data;
    }
  }

  /**
   * @description This will return OCR payments only
   * @param {Array<any>} data - Array of payments
   */
  public static getOCRPayments(payments: Array<any>): Array<any> {
    try {
      return payments.filter(
        ({ fileName, itemTypeId }: IPayment) =>
          fileName !== "Manual" && OCR_ITEM_TYPE_IDS.includes(itemTypeId)
      );
    } catch (error) {
      return payments;
    }
  }

  /**
   * @description This will return a Set of file names those in OCR payments
   * @param {Array<any>} data - Array of payments
   * @returns {Set<string>} Set of file names
   */
  public static getOCRFileNamesOptions(
    payments: Array<any>
  ): Array<{ label: string; ocrFileName: string }> {
    const fileNames = new Set<string>();
    const fileNameOptions: Array<{ label: string; ocrFileName: string }> = [];
    try {
      const ocrPayments = this.getOCRPayments(payments);

      ocrPayments.map(({ fileName }: IPayment) => {
        fileNames.add(fileName);
      });

      fileNames.forEach((fileName) => {
        fileNameOptions.push({
          label: fileName,
          ocrFileName: fileName,
        });
      });
      return fileNameOptions;
    } catch (error) {
      return fileNameOptions;
    }
  }

  /**
   * @description set a payment id for dynamic focus purpose
   * @param {number | undefined} paymentId - the payment id to save
   */
  public static setLinkedPaymentId(paymentId: number | undefined) {
    this.linkedPaymentId = paymentId;
  }
  /**
   * @description get the payment id
   * @returns {number | undefined} paymentId - the payment id that set previously
   */
  public static getLinkedPaymentId(): number | undefined {
    return this.linkedPaymentId;
  }
}
