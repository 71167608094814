import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { ISummary } from "us.collection.economy/components/ImportPayments/interface";
import { connect } from "react-redux";

const { $Divider, $AmountLabel, $DateLabel } = Common.Components;

const Summary: React.FC<ISummary> = (props) => {
  const { t } = useTranslation();

  const { uploadResult, fileList } = props;

  const {
    totalPaymentAmount,
    paymentsRecordsCount,
    atgStatusCount,
    settlementDate,
  } = uploadResult ?? {};

  return (
    <>
      <div className="p-3 pt-1">
        <div data-testid="update-summary">
          <div className="d-flex flex-column">
            <h2>{t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.UPLOAD_SUMMARY")}</h2>
            <div>
              <div className="d-flex justify-content-between">
                <span>
                  {t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.FILE_NAME")}
                </span>
                <span
                  data-testid="upload-file-name"
                  className="font-weight-bold text-right"
                >
                  {fileList.length > 0 ? fileList[0].name : ""}
                </span>
              </div>
            </div>
            <$Divider/>
            <div>
              <div className="d-flex justify-content-between">
                <span>
                  {t(
                    "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.TOTAL_PAYMENT_AMOUNT"
                  )}
                </span>
                <span
                  data-testid="totalPaymentAmount"
                  className="font-weight-bold text-right"
                >
                  <$AmountLabel value={totalPaymentAmount} />
                </span>
              </div>
            </div>
            <$Divider/>
            <div className="d-flex justify-content-between">
              <span>
                {t(
                  "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.TOTAL_PAYMENT_RECORD_COUNT"
                )}
              </span>
              <span
                data-testid="paymentsRecordsCount"
                className="font-weight-bold text-right"
              >
                {paymentsRecordsCount}
              </span>
            </div>
            <$Divider/>
            <div className="d-flex justify-content-between">
              <span>
                {t(
                  "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.TOTAL_ATG_STATUS_COUNT"
                )}
              </span>
              <span
                data-testid="atgStatusCount"
                className="font-weight-bold text-right"
              >
                {atgStatusCount}
              </span>
            </div>
            <$Divider/>
            <div className="d-flex justify-content-between">
              <span>
                {t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.FILE_GENERATED_DATE")}
              </span>
              <span
                data-testid="settlementDate"
                className="font-weight-bold text-right"
              >
                <$DateLabel value={settlementDate} />
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { importPayments } = state;

  const { upload, fileList } = importPayments;

  return {
    fileList,
    uploadResult: upload.data
  };
};

const connector = connect(mapStateToProps, {});

export default connector(Summary);
