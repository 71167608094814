import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import {
  Action,
  COLUMNS,
  TableColumn,
  ITEM_MENU_DEFAULT_STATE,
} from "us.collection.economy/components/LedgerAccounts/Constants";
import { MoreOutlined } from "us.icons";
import { ItemMenu } from "./Components";
import { ActionType } from "us.collection.economy/components/LedgerAccounts/Interfaces";
import { connect, ConnectedProps } from "react-redux";
import { ILedgerAccountsTable, IItemMenu } from "./Interfaces";
import { IRootState } from "us.collection/interfaces";
import { LedgerDetails } from "us.collection.economy/components/LedgerAccounts/Interfaces";
import { getColumnFilterProps } from "./Functions";

const { $Button, $DateLabel, $Popover, $TableTree } = Components;

/**
 * @description - Ledger account list table view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3094577191/Ledger+List+-+UI+Implementation
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 08/31/2022
 */
const LedgerAccountsTable: React.FC<ILedgerAccountsTable & PropsFromRedux> = (
  props
) => {
  const { onCallAction, ledgerAccounts } = props;
  const { t } = useTranslation();
  const [itemMenu, setItemMenu] = useState<IItemMenu>(ITEM_MENU_DEFAULT_STATE);

  // auto close ledger account item menu after 5 seconds
  const setAutoClose = () => {
    setTimeout(() => {
      setItemMenu(ITEM_MENU_DEFAULT_STATE);
    }, 5000);
  };
  /**
   * @description - render rows
   */
  const renderCell = (record: LedgerDetails, dataIndex: string) => {
    const {
      accountId,
      accountNo,
      accountName,
      description,
      modifiedDate,
      isSystem,
    } = record;
    const row = {
      children: (
        <>
          {dataIndex === TableColumn.MENU && (
            <div className="align-items-center" key={accountId}>
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={itemMenu.visible && itemMenu.id === accountId}
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    id: accountId,
                  });
                  setAutoClose();
                }}
                content={
                  <ItemMenu
                    onCallAction={(actionType: ActionType) => {
                      setItemMenu(ITEM_MENU_DEFAULT_STATE);
                      onCallAction(actionType, record);
                    }}
                    isSystem={isSystem}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  id="btnItemMenu"
                  data-testid="popover-btn"
                  icon={<MoreOutlined />}
                  size="small"
                />
              </$Popover>
            </div>
          )}
          {dataIndex === TableColumn.ACCOUNT && (
            <>
              <div className="font-weight-bold">{accountName}</div>
              <div className="text-muted">
                {t("US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.ACCOUNT_NO")} :
                {accountNo}
              </div>
            </>
          )}
          {dataIndex === TableColumn.DESCRIPTION && <span>{description}</span>}
          {dataIndex === TableColumn.LAST_UPDATE && modifiedDate != null && (
            <$DateLabel value={modifiedDate} />
          )}
        </>
      ),
    };
    return row;
  };

  /**
   * @description - Generate table columns with sorter and filters
   * @returns - An array of columns
   */
  const getColumns = (): any[] => {
    const columns: any[] = [];
    COLUMNS.map((column: any, _index) => {
      const { title, key } = column ?? {};

      let newColumn: any = {
        ...column,
        title: title && t(`US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.${title}`),
        ...getColumnFilterProps(key),
        customRenderChild: (_text: any, record: any) => renderCell(record, key),
        align: "left",
        ellipsis: key == TableColumn.DESCRIPTION,
      };
      if (key == TableColumn.MENU) {
        newColumn = {
          ...newColumn,
          align: "center",
        };
      }
      if (key !== TableColumn.MENU) {
        newColumn = {
          ...newColumn,
          customSorter: (a: any, b: any) => {
            if (key === TableColumn.LAST_UPDATE) {
              return new Date(a).getTime() - new Date(b).getTime();
            } else {
              return a?.localeCompare(b);
            }
          },
        };
      }
      columns.push(newColumn);
    });
    return columns;
  };

  return (
    <$TableTree
      rowKey={(record: LedgerDetails) => record.accountId}
      data-testid="ledgerAccounts-table"
      data={ledgerAccounts}
      size="small"
      className="mt-3 table-striped header-custom-tag"
      onSort={(sortData, dataSource) => {
        return sortData(dataSource);
      }}
      onFilter={(searchData, dataSource) => {
        return searchData(dataSource);
      }}
      onRow={(record, _rowIndex) => {
        return {
          onDoubleClick: () =>
            onCallAction(record?.isSystem ? Action.VIEW : Action.EDIT, record),
        };
      }}
      filterOnType
      bordered
      showResetAllFilters
      resetOnSourceChange
      pagination={{
        defaultPageSize: 20,
      }}
      scroll={{
        x: 900,
        y: "calc(100vh - 265px)",
      }}
      columns={getColumns()}
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { ledgerAccounts } = state;

  const { list } = ledgerAccounts;
  return {
    ledgerAccounts: list,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LedgerAccountsTable);
