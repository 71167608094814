import { IOrderLine, ISelectedArticle } from "../Interface";

/**
 * @description calculate total amounts
 * @param {Array<IOrderLine>} orderTableData - order table data
 * @param {number} key - row key of selected row
 * @param {any} updatedValue - updatedValue of the edited field
 * @param {string} field - updated field name attribute
 * @returns {Array<IOrderLine>} - updated order table with updated field values
 */
export const getCalculatedAmounts = (
  tableData: Array<IOrderLine>,
  type: string
): number => {
  let totalAmount = 0;
  try {
    tableData.map((data: any) => (totalAmount = totalAmount + data[type]));
  } catch (error) {
    console.error(error);
  }
  return totalAmount;
};

/**
 * @description update order table data when table fields are edited
 * @param {Array<IOrderLine>} orderTableData - order table data
 * @param {number} key - row key of selected row
 * @param {any} updatedValue - updatedValue of the edited field
 * @param {string} field - updated field name attribute
 * @returns {Array<IOrderLine>} - updated order table with updated field values
 */
export const updateTableData = (
  tableData: Array<IOrderLine>,
  key: number,
  updatedValue: any,
  field: string,
  isVatCalculated?: boolean,
  articleValidation?: boolean,
  user?: string
): Array<IOrderLine> => {
  let updatedData: Array<IOrderLine> = tableData;
  try {
    updatedData = tableData.map((order: any) => {
      let { amount, vatAmount, totalAmount, isValidCaseNo } = order;
      if (order.key == key) {
        order.isValidCaseNo = field == "caseNo" ? "" : isValidCaseNo;
        order.isValidArticle =
          field == "article" ? articleValidation : order.isValidArticle;
        // order.articleDescription = (field == "article") ? "" : articleDescription;
        order[field] = updatedValue;
        order.amount =
          field == "unitPrice" || field == "units"
            ? Number((order.units * order.unitPrice).toFixed(2))
            : amount;
        order.vatAmount =
          field == "unitPrice" || field == "units" ? 0 : vatAmount;
        order.totalAmount =
          field == "unitPrice" || field == "units" ? order.amount : totalAmount;
        order.isVatCalculated =
          field == "unitPrice" || field == "units" || field == "article"
            ? isVatCalculated
            : order.isVatCalculated;
        order.user = user != undefined ? user : order.user;
      }
      return order;
    });
  } catch (error) {
    console.error(error);
  }
  return updatedData;
};

/**
 * @description update order table data with selected article data
 * @param {Array<IOrderLine>} orderTableData - order table data
 * @param {number} key - row key of selected row
 * @param {ISelectedArticle} selectedArticle - selected article data
 * @returns {Array<IOrderLine>} - updated order table with selected article data
 */
export const updateTableDataWithSelectedArticleData = (
  tableData: Array<IOrderLine>,
  key: number,
  selectedArticle: any
): Array<IOrderLine> => {
  let updatedData: Array<IOrderLine> = tableData;
  try {
    const {
      description,
      isValidArticle,
      defaultPrice,
      vatCode,
      articleNo,
      isVatCalculated,
      user,
    } = selectedArticle;
    updatedData = tableData.map((order: IOrderLine) => {
      if (order.key == key) {
        order.article = articleNo;
        order.articleDescription = description;
        order.isValidArticle = isValidArticle;
        order.unitPrice = defaultPrice;
        order.vatCode = vatCode;
        order.amount = Number((defaultPrice * order.units).toFixed(2));
        order.totalAmount = Number((defaultPrice * order.units).toFixed(2));
        order.vatAmount = 0;
        order.isVatCalculated = isVatCalculated;
        order.user = user != undefined ? user : order.user;
      }
      return order;
    });
  } catch (error) {
    console.error(error);
  }
  return updatedData;
};

/**
 * @description update order table data with copied table data row
 * @param {Array<IOrderLine>} orderTableData - order table data
 * @param {number} key - row key of selected row
 * @returns {Array<IOrderLine>} - updated order table with copied table data row
 */
export const copyOrderTableRow = (
  orderTableData: Array<IOrderLine>,
  key: number,
  user: string
): Array<IOrderLine> => {
  let updatedData: Array<IOrderLine> = orderTableData;
  try {
    const copiedRaw = orderTableData.filter(
      (data: IOrderLine) => data.key == key
    );
    const newRow = { ...copiedRaw[0], key: key + 1, user };
    const newTData = orderTableData.map((item: IOrderLine) => {
      if (item.key > key) {
        item.key = item.key + 1;
      }
      return item;
    });
    updatedData = [...newTData, newRow];
  } catch (error) {
    console.error(error);
  }
  return updatedData;
};

/**
 * @description update order table data with case no validation result
 * @param {Array<IOrderLine>} orderTableData - order table data
 * @param {IValidateCaseNoResult} validationCaseNoResult - case no validation result
 * @returns {Array<IOrderLine>} - updated order table with case no validation result
 */
export const updateCaseNoValidation = (
  orderTableData: Array<IOrderLine>,
  payload: any
): Array<IOrderLine> => {
  const { validationResults, rowKey } = payload;
  let updatedData: Array<IOrderLine> = orderTableData;
  try {
    const { caseNo, isValidCaseNo, caseId } = validationResults;
    updatedData = orderTableData.map((order: IOrderLine) => {
      if (order.key == rowKey) {
        order.caseNo = caseNo;
        order.isValidCaseNo = isValidCaseNo;
        order.caseId = caseId;
      }
      return order;
    });
  } catch (error) {
    console.error(error);
  }
  return updatedData;
};

/**
 * @description update selected article
 * @param {Array<IOrderLine>} orderTableData - order table data
 * @param {ISelectedArticle} selectedArticle - selected article details
 * @param {number} rowKey - row key of selected row
 * @returns {Array<IOrderLine>} - updated order table with selected article
 */
export const updateSelectedArticle = (
  orderTableData: Array<IOrderLine>,
  selectedArticle: ISelectedArticle,
  rowKey: number
): Array<IOrderLine> => {
  let updatedData: Array<IOrderLine> = orderTableData;
  try {
    const { articleNo, description, defaultPrice, vatCode } = selectedArticle;
    updatedData = orderTableData.map((order: IOrderLine) => {
      let { key } = order;
      if (key == rowKey) {
        order.article = articleNo;
        order.articleDescription = description;
        order.isValidArticle = true;
        order.unitPrice = defaultPrice;
        order.vatCode = vatCode;
        order.amount = Number((order.units * defaultPrice).toFixed(2));
        order.totalAmount = Number((order.units * defaultPrice).toFixed(2));
        order.vatAmount = 0;
      }
      return order;
    });
  } catch (error) {
    console.error(error);
  }
  return updatedData;
};

/**
 * @description find orderLine table is updated or not
 * @param {Array<IOrderLine>} orderTableData - current form values
 * @param {Object<IOrderLine>} initialTableData - initial apportionment details
 * @returns {boolean} - if initial orderLine values are changed return true otherwise false
 */
export const isFormDirty = (
  orderTableData: Array<IOrderLine>,
  initialTableData: IOrderLine
): boolean => {
  try {
    return (
      JSON.stringify(orderTableData) ===
      JSON.stringify(
        Array.isArray(initialTableData) ? initialTableData : [initialTableData]
      )
    );
  } catch (error) {
    console.error(error);
    return true;
  }
};

/**
 * @description filter Article List by search text
 * @param {Array<any>} articleList - all the article list
 * @param {string} searchText - search text
 * @returns {Array<any>} - filtered article list by search text
 */
export const filterArticleListBySearch = (
  articleList: any,
  searchText: any
): Array<IOrderLine> => {
  let filteredArticleTable: Array<any> = articleList;
  try {
    filteredArticleTable = articleList?.filter((article: any) => {
      return (
        article.articleNo
          ?.toString()
          .toUpperCase()
          ?.includes(searchText?.toString().toUpperCase()) ||
        article.description
          ?.toString()
          .toUpperCase()
          ?.includes(searchText?.toString().toUpperCase())
      );
    });
  } catch (error) {
    console.error(error);
  }
  return filteredArticleTable;
};

/**
 * @description check whether order line filled with required fields
 * @param {Array<any>} orderTableData - order table data
 * @returns {boolean} - each order live has field all the required fields
 */
export const isAddNewOrderLineDisabled = (
  orderTableData: any,
  vatStatus: string
): boolean => {
  let isDisabled: boolean = true;
  try {
    isDisabled =
      orderTableData.filter((o: any) => o.article == "" || o.units == 0)
        .length > 0 ||
      checkCaseNoValidation(orderTableData) ||
      orderTableData.filter(
        (order: any) =>
          order.vatCode !== 0 &&
          vatStatus !== "" &&
          order.unitPrice !== 0 &&
          order.units !== 0 &&
          order.vatAmount === 0 &&
          order.isVatCalculated
      ).length > 0;
  } catch (error) {
    console.error(error);
  }
  return isDisabled;
};

/**
 * @description Filter article list
 * @param {Array<any>} articleList - article list
 * @param {string} filterText - filter by text
 * @param {number} dataIndex - index of filtered column
 * @param {any} value - filter value
 * @param {any} type - filter column data type
 * @returns {Array<any>} - filtered article list
 **/
export const filterData = (
  articleList: any,
  filterText: any,
  dataIndex: string,
  value: any,
  type: string
) => {
  const searchData = articleList?.filter((o: any) =>
    [
      ...filterText.filter((n: any) => n.dataIndex !== dataIndex),
      { dataIndex: dataIndex, text: value },
    ].every((s: any) => {
      if (type == "amount") {
        return s.text?.toString()
          ? o[s.dataIndex]?.toString()?.includes(s.text?.toString())
          : true;
      } else {
        return o[s.dataIndex]
          ?.toString()
          ?.toLowerCase()
          ?.includes(s.text?.toString()?.toLowerCase());
      }
    })
  );
  return searchData;
};

/**
 * @description format order table data by existing order details in edit view
 * @param {Array<any>} orderDetails - existing order details
 * @returns {Array<any>} - formatted order table data
 **/
export const formattedOrderTableData = (orderDetails: any) => {
  let formattedOrderData: any = [];
  try {
    orderDetails.map((order: any, index: any) => {
      const {
        articleNo,
        articleText,
        caseNo,
        caseId,
        description,
        numberOfItems,
        unitPrice,
        amount,
        vatAmount,
        totalAmount,
        createdUser,
        orderlineId,
        vatCode,
        isManualOrderline,
      } = order;
      formattedOrderData.push({
        key: index,
        orderlineId,
        article: articleNo,
        articleDescription: articleText,
        caseNo,
        caseId,
        description,
        units: numberOfItems,
        unitPrice,
        amount,
        vatAmount,
        totalAmount,
        vatCode,
        user: createdUser,
        isValidCaseNo: true,
        isValidArticle: true,
        isManualOrder: isManualOrderline,
        unitPriceMinValue: (typeof unitPrice == "undefined" ||  unitPrice > 0) ? 0 : undefined
      });
    });
  } catch (error) {
    console.error(error);
  }
  return formattedOrderData;
};

/**
 * @description update order table data after vat calculation success
 * @param {Array<IOrderLine>} orderTableData - existing order table data
 * @param {any} vatPayload - vat amounts from API response
 * @returns {Array<IOrderLine>} - VAT updated order table data
 **/
export const updateOrderTableWithVat = (
  orderTableData: Array<IOrderLine>,
  vatPayload: any
) => {
  const { data, rowKey, label, value } = vatPayload;
  const { vatAmount, vatBasis } = data;
  let updatedOrderTable = orderTableData;
  try {
    orderTableData.map((order: any, index: any) => {
      if (order.key == rowKey) {
        order.amount = vatBasis;
        order.vatAmount = vatAmount;
        order.totalAmount = vatBasis + vatAmount;
        if (label != undefined) {
          order[label] = Number(value.toFixed(2));
        }
        order.isVatCalculated = true;
      }
    });
  } catch (error) {
    console.error(error);
  }
  return updatedOrderTable;
};

/**
 * @description Filter order lines
 * @param {Array<IOrderLine>} orderTableData - order table data
 * @param {string} filterText - filter by text
 * @param {number} dataIndex - index of filtered column
 * @param {any} value - filter value
 * @param {any} type - filter column data type
 * @returns {Array<IOrderLine>} - filtered order lines
 **/
export const filterOrderTableData = (
  orderTableData: any,
  filterText: any,
  dataIndex: string,
  value: any,
  type: string
) => {
  let searchData = orderTableData;
  try {
    searchData = orderTableData?.filter((o: any) =>
      [
        ...filterText.filter((n: any) => n.dataIndex !== dataIndex),
        { dataIndex: dataIndex, text: value },
      ].every((s: any) => {
        if (type == "amount") {
          return s.text?.toString()
            ? o[s.dataIndex]?.toString()?.includes(s.text?.toString())
            : true;
        } else {
          return o[s.dataIndex]
            ?.toString()
            ?.toLowerCase()
            ?.includes(s.text?.toString()?.toLowerCase());
        }
      })
    );
  } catch (error) {
    console.error(error);
  }
  return searchData;
};

/**
 * @description check whether order line has invalid case no
 * @param {Array<any>} orderTableData - order table data
 * @returns {boolean} - each order line has valid case no
 */
export const checkCaseNoValidation = (orderTableData: any): boolean => {
  let isValid: boolean = true;
  try {
    isValid =
      orderTableData.filter(
        (o: any) =>
          o.caseNo != "" &&
          (o.isValidCaseNo === false || o.isValidCaseNo === "")
      ).length > 0;
  } catch (error) {
    console.error(error);
  }
  return isValid;
};

/**
 * @description check whether order line has invalid case no
 * @param {Array<any>} orderTableData - order table data
 * @returns {boolean} - each order line has valid case no
 */
export const checkVatCalculationStatus = (orderTableData: any): boolean => {
  let isValid: boolean = true;
  try {
    isValid =
      orderTableData.filter((o: any) => o.isVatCalculated === false).length > 0;
  } catch (error) {
    console.error(error);
  }
  return isValid;
};

/**
 * @description create full address or full name
 * @param {Object} addressPayload - payload for create address or name
 * @returns {string} - return full address or full name
 */
export const getCombinedData = (addressPayload: any, type: string): string => {
  const { address1, address2, address3, firstName, lastName } = addressPayload;
  let combinedData: string = "";
  try {
    if (type === "address") {
      combinedData = [address1, address2, address3].join(" ");
    } else if (type === "name") {
      combinedData = [firstName, lastName].join(" ");
    }
  } catch (error) {
    console.error(error);
  }
  return combinedData;
};

/**
 * @description check whether order line filled with required fields
 * @param {Array<any>} orderTableData - order table data
 * @returns {boolean} - each order live has field all the required fields
 */
export const isSaveButtonDisabled = (
  orderTableData: any,
  tableData: any,
  creditor: string,
  isValidArticle: boolean,
  vatStatus: string
): boolean => {
  try {
    return (
      isFormDirty(orderTableData, tableData) ||
      isAddNewOrderLineDisabled(orderTableData, vatStatus) ||
      creditor == "" ||
      !isValidArticle ||
      checkCaseNoValidation(orderTableData) ||
      checkVatCalculationStatus(orderTableData) ||
      orderTableData.filter(
        (order: any) =>
          order.isValidArticle === false || order.isValidArticle === ""
      ).length > 0 ||
      orderTableData.filter(
        (order: any) =>
          order.vatCode !== 0 &&
          vatStatus !== "" &&
          order.unitPrice !== 0 &&
          order.units !== 0 &&
          order.vatAmount === 0 &&
          order.isVatCalculated
      ).length > 0
    );
  } catch (error) {
    console.error(error);
    return true;
  }
};

export const isSaveAndInvoiceButtonDisabled = (
  orderTableData: any,
  tableData: any,
  creditor: string,
  isValidArticle: boolean,
  isEdit: any,
  invoicingStatus: number,
  vatStatus: string
): boolean => {
  try {
    if (isFormDirty(orderTableData, tableData)) {
      return isEdit === "true" && invoicingStatus !== 0;
    } else {
      return (
        isFormDirty(orderTableData, tableData) ||
        isAddNewOrderLineDisabled(orderTableData, vatStatus) ||
        creditor == "" ||
        !isValidArticle ||
        checkCaseNoValidation(orderTableData) ||
        checkVatCalculationStatus(orderTableData) ||
        orderTableData.filter(
          (order: any) =>
            order.isValidArticle === false || order.isValidArticle === ""
        ).length > 0 ||
        orderTableData.filter(
          (order: any) =>
            order.vatCode !== 0 &&
            vatStatus !== "" &&
            order.unitPrice !== 0 &&
            order.units !== 0 &&
            order.vatAmount === 0 &&
            order.isVatCalculated
        ).length > 0
      );
    }
  } catch (error) {
    console.error(error);
    return true;
  }
};

/**
 * @description check eligibility for vat calculation
 * @param {any} selectedCreditor - selected creditor details
 * @param {any} record - order table updated record
 * @param {number} updatedProp - updated prop value
 * @param {string} label - updated prop name
 * @returns {boolean} - eligibility for vat calculation
 */
export const isCreditorEligibleForVatCalculation = (
  selectedCreditor: any,
  record: any,
  updatedProp: any,
  label: string
) => {
  const { creditorNo, creditorVATStatus } = selectedCreditor;
  
  
  try {
    return (
      record?.vatCode != 0 &&
      record[label] != 0 &&
      creditorNo != 0 &&
      creditorVATStatus != "" &&
      (record[label] * updatedProp) &&
    !isNaN((record[label] * updatedProp))
    );
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * @description check creditor eligible for validation
 * @param {number} value - updated creditor no
 * @param {any} selectedCreditor - selected creditor details
 * @returns {boolean} - eligibility for validation
 */
export const isCreditorEligibleForValidation = (
  value: any,
  selectedCreditor: any
) => {
  const { creditorNo } = selectedCreditor;
  try {
    return value != "" && Number(value) > 1 && creditorNo !== value;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * @description check order is manual or system generated
 * @param {Array<any>} orderTableData - order table data
 * @returns {boolean} - manual or system generated order
 */
export const isManualOrder = (orderTableData: any) => {
  try {
    return (
      orderTableData.filter((order: any) => order.isManualOrder === false)
        .length > 0
    );
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * @description filter filter text array
 * @param {Array<any>} filterText - filter text array
 * @param {string} dataIndex - dataIndex of table row
 * @returns {Array<any>} -filtered filter text array
 */
export const filterOrderLineByText = (
  filterText: Array<any>,
  dataIndex: string
) => {
  try {
    return filterText.filter((i: any) => i.dataIndex == dataIndex)[0]?.text;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * @description check is there any error in vat calculation
 * @param {object} record - orderline which need to be calculate the vat
 * @param {string} vatStatus - creditor vat status
 * @returns {boolean} - is there any error or not
 */
export const isErrorInVatCalculation = (record: any, vatStatus: string) => {
  const { vatCode, unitPrice, vatAmount, isVatCalculated, units } = record;
  try {
    return (
      vatCode !== 0 &&
      vatStatus !== "" &&
      unitPrice !== 0 &&
      units !== 0 &&
      vatAmount === 0 &&
      isVatCalculated
    );
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * @description Get total amount when filtering the table
 * @param {Array<any>} orderliness - array of orderliness
 * @param {string} dataIndex - column index
 * @returns {number} - total column amount
 */
 export const getFilterTotal = (orderliness: Array<any>, dataIndex: string):number => {
  try {
   return orderliness.reduce(
      (total: any, order: any) => total + order[dataIndex],
      0
    );
  } catch (error) {
    return 0;
  }
};