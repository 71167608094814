import { RemitRecord } from "us.collection.economy/components/Remit/Interfaces";
import { VATDeductionStatus } from "us.collection.economy/constants";

/**
 * @function
 * @description Get updated remit proposal records after invoicing
 * @param {RemitRecord[]} remitRecords -
 * @param {string} creditorNo
 * @param {VATDeductionStatus} vatDeductionStatus
 * @link Design https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2913435905/Remove+VAT+Deduction+Invoice
 */
export const getRemitRecordsAfterInvoice = (
  remitRecords: RemitRecord[],
  creditorNo: string,
  vatDeductionStatus: VATDeductionStatus
): any[] => {
  try {
    const updatedRecords = remitRecords.map((remitRecord: RemitRecord) => {
      const { remitBureau, vatDeducted, remitCreditor, vatDeductedOld } =
        remitRecord;
      if (creditorNo === remitRecord.creditorNo) {
        if (vatDeductionStatus === VATDeductionStatus.REMOVE) {
          /**
           * Remove vat deduction
           * Remit Bureau deduct the vat deducted
           * Remit Creditor plus the vat deducted
           * After doing this, Bureau Amount = Remit Bureau and Creditor Amount = Remit Creditor
           * vatDeducted will hold in a separate key as vatDeductedOld to use when reset the invoice
           */
          return {
            ...remitRecord,
            remitBureau: remitBureau - vatDeducted,
            remitCreditor: remitCreditor + vatDeducted,
            isInvoiced: true,
            vatDeductedOld: vatDeducted,
            vatDeducted: 0,
          };
        } else {
          /**
           * Add / Reset vat deduction
           * Remit Bureau plus the vat deducted
           * Remit Creditor deduct the vat deducted
           * After doing this, Remit Bureau and Remit Creditor value reset to default
           */
          return {
            ...remitRecord,
            remitBureau: remitBureau + vatDeductedOld,
            remitCreditor: remitCreditor - vatDeductedOld,
            isInvoiced: false,
            vatDeducted: vatDeductedOld,
          };
        }
      }
      return remitRecord;
    });

    return updatedRecords;
  } catch (error) {
    return remitRecords;
  }
};
