import { ISaveCreditNote } from "us.collection.economy/components/ClientInvoices/Interfaces";
import { getVatAmounts, getTotalVatAmount } from "../Functions";

export function SaveCreditNoteData(this: any): ISaveCreditNote {
  return {
    invoiceNo: this.invoiceNo,
    customerNo: this.customerNo,
    invoiceType: this.invoiceType,
    creditAmount: Number(this.creditAmount.toFixed(2)),
    vatAmount: getTotalVatAmount(this.creditNoteDetails),
    comment: this.comment,
    sourceInvoiceId: this.invoiceId,
    sourceOrderNo: this.orderNo,
    invoiceDetails: this.creditNoteDetails?.flatMap((item: any) => {
      if (item.creditedAmount !== 0) {
        return {
          orderlineId: item?.orderlineId,
          articleNo: item?.articleNo,
          articleText: item?.articleText,
          description: item?.description,
          amount: Number(item?.creditedAmount.toFixed(2)),
          vATAmount: getVatAmounts(item),
          numberOfItems:
            item?.reducedQuantity <= 0 ? item?.quantity : item?.reducedQuantity,
          caseId: item?.caseId,
          sourceOrderlineId: item?.orderlineId,
        };
      } else {
        return [];
      }
    }),
  };
}
