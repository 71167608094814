/**
 * Common routes of the application
 */

import React from "react";
import { Route } from "react-router-dom";
import { Unauthorized } from "us.common/components";
import { AuthCallback } from "us.common/containers";


export default [
  <Route
    exact
    key="/unauthorized"
    path={"/unauthorized"}
    render={() => <Unauthorized />}
  />,
  <Route
    exact
    key="/authcallback"
    path={"/authcallback"}
    render={() => <AuthCallback />}
  />
];
