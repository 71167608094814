/**
 * @name DIRECT_PAYMENT 11 - Direkte betaling payment type
 * @name OCR_PAYMENT_CLIENT_ACCOUNT 27 - OCR betaling klientkonto payment type
 * @name MOBILE_PAYMENT 38 - Mobile payment payment type
 * @name MANUAL_PAYMENT_CLIENT_ACCOUNT 47 - Manuell betaling klientkonto payment type
 * @name UNIDENTIFIED_PAYMENT 57 - Unidentified Payment payment type
 * @name OCR_OWN_ACCOUNT 67 - OCR own account payment type
 * 
 * @name PAYMENT_TYPES getPaymentTypes API parameter
 * @name PAYMENT_METHODS getPaymentMethods API parameter
 */

 export const PaymentTypes = {
   DIRECT_PAYMENT: 11,
   OCR_PAYMENT_CLIENT_ACCOUNT: 27,
   MOBILE_PAYMENT: 38,
   MANUAL_PAYMENT_CLIENT_ACCOUNT: 47,
   UNIDENTIFIED_PAYMENT: 57,
   OCR_OWN_ACCOUNT: 67,
 };
 
 export const APIParams = {
  PAYMENT_TYPES: 'ALL',
  PAYMENT_METHODS: 'SUB',
};

export const PaymentMethods = {
  CASH_PAYMENT: 601,
  BANK_PAYMENT: 602,
  PREPAID_PAYMENT: 603,
  SHOP_ACCOUNT_PAYMENT: 604,
  CARD_PAYMENT: 605,
};