import {} from "us.collection.economy/interfaces";
import { httpCollection } from "us.helper/http/collection";

export default {
  getCoveringSequenceList: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data = await httpCollection.get(
          "economysettingservice",
          "coveringsequences",
          {}
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getItemTypes: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const itemTypesData = await httpCollection.get(
          "economysettingservice",
          `itemtypes`,
          params
        );
        const defaultTypes = await httpCollection.get(
          "economysettingservice",
          `coveringsequences/default`,
          {}
        );
        resolve({itemTypesData,defaultTypes});
      } catch (error) {
        reject(error);
      }
    });
  },
  addNewCoveringSequence: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.post(
          "economysettingservice",
          `coveringsequence`,
          params
        );
        const sequenceData = await httpCollection.get(
          "economysettingservice",
          "coveringsequences",
          {}
        );
        resolve({ data, status, sequenceList: sequenceData.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  updateCoveringSequence: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.put(
          "economysettingservice",
          `coveringsequence`,
          params
        );
        const sequenceData = await httpCollection.get(
          "economysettingservice",
          "coveringsequences",
          {}
        );
        resolve({ data, status, sequenceList: sequenceData.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  deleteCoveringSequence: (id: string): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.delete(
          "economysettingservice",
          `coveringsequence`,
          id
        );
        const sequenceData = await httpCollection.get(
          "economysettingservice",
          "coveringsequences",
          {}
        );
        resolve({ data, status, sequenceList: sequenceData.data });
      } catch (error) {
        reject(error);
      }
    });
  },
  validateCoveringSequenceName: (sequenceName: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const data = await httpCollection.get(
          "economysettingservice",
          `coveringsequence/${sequenceName}/validationresult`,
          {}
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
