import { take, put, call, select, takeLatest, all } from "redux-saga/effects";
import * as Actions from "../../actions";
import * as API from "../../services";
const { regPayments } = Actions;
import { RegisterPayments } from "../../constants/Actions";
import Common from "us.common";

const { $MessageBox } = Common.Components;

export const RegisterPaymentSagas = {
  registerPayment: {
    getPaymentTypes: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const paymentTypes = yield call(
          API.regPayments.regPayments.getPaymentTypes,
          params
        );
        yield put(regPayments.getPaymentTypesSuccess(paymentTypes));

        let defaultPayment = paymentTypes.filter((p:any) => p.paymentTypeId == 47)

        if(defaultPayment.length > 0){
        const voucherNo = yield call(
          API.regPayments.regPayments.getVoucherNo,
          '47'
        );
        yield put(regPayments.getVoucherNoSuccess(voucherNo));
        }
      } catch (error) {
        yield put(regPayments.getPaymentTypesFail(error));
      }
    },
    getPaymentMethods: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const paymentMethods = yield call(
          API.regPayments.regPayments.getPaymentTypes,
          params
        );
        yield put(regPayments.getPaymentMethodsSuccess(paymentMethods));
      } catch (error) {
        yield put(regPayments.getPaymentMethodsFail(error));
      }
    },
  getVoucherNo: function* (action: any) {
    const params = action?.payload?.data ?? {};
    try {
      const voucherNo = yield call(
        API.regPayments.regPayments.getVoucherNo,
        params
      );
      yield put(regPayments.getVoucherNoSuccess(voucherNo));
    } catch (error) {
      yield put(regPayments.getVoucherNoFail(error));
    }
  },
  getValidateCaseId: function* (action: any) {
    const {values, addedAmount} = action?.payload?.data ?? {};
    try {
      const validationResults = yield call(
        API.regPayments.regPayments.getValidateCaseId,
        values.caseNo
      );      
      if(Object.keys(validationResults).length === 0){
        $MessageBox('error','US.COLLECTION.VALIDATIONS:INVALID.INVALID_CASE_NO','','')
      }
      if (values.paymentType == 67 && Object.keys(validationResults).length !== 0 && !validationResults.isCreditorInvoice) {
        $MessageBox('error','US.COLLECTION.VALIDATIONS:REQUIRED.PLEASE_SELECT_CREDITOR_INVOICE','','')
      }
      if((values.paymentType != 67) && Object.keys(validationResults).length !== 0 && validationResults.isCreditorInvoice){
        $MessageBox('error','US.COLLECTION.VALIDATIONS:REQUIRED.CANT_SELECT_CREDITOR_INVOICE','','')
      }
      yield put(regPayments.getValidateCaseIdSuccess(validationResults,values,addedAmount));
    } catch (error) {
      yield put(regPayments.getValidateCaseIdFail(error));
    }
  },
  saveRegisterPayment: function* (action: any) {
    const params = action?.payload?.data ?? {};
    try {
      const transactions = yield call(
        API.regPayments.regPayments.saveRegisterPayment,
        params
      );
      yield put(regPayments.saveRegisterPaymentsSuccess(transactions));
      if(transactions.length > 0){
        $MessageBox('success','US.COLLECTION.ECONOMY:REGISTERPAYMENTS.VOUCHER_DETAILS_SAVED_SUCCESSFULLY','','')
      }
    } catch (error) {
      yield put(regPayments.saveRegisterPaymentsFail(error));
    }
  },
}
};

export default [
  takeLatest(
    RegisterPayments.GET_PAYMENT_TYPE,
    RegisterPaymentSagas.registerPayment.getPaymentTypes
  ),
  takeLatest(
    RegisterPayments.GET_PAYMENT_METHOD,
    RegisterPaymentSagas.registerPayment.getPaymentMethods
  ),
  takeLatest(
    RegisterPayments.GET_VOUCHER_NO,
    RegisterPaymentSagas.registerPayment.getVoucherNo
  ),
  takeLatest(
    RegisterPayments.GET_VALIDATE_CASE_ID,
    RegisterPaymentSagas.registerPayment.getValidateCaseId
  ),
  takeLatest(
    RegisterPayments.SAVE_REGISTER_PAYMENT,
    RegisterPaymentSagas.registerPayment.saveRegisterPayment
  ),
];
