import React from 'react';
import { useTranslation } from 'react-i18next';
import * as Components from 'us.common/components/antd';
import { PaymentStates } from 'us.collection.economy/constants/Payments';
import { DeleteOutlined, BoxIcons, IconTypes } from 'us.icons';

const { $Button, $Tooltip } = Components;
const { EXCEEDED, UNKNOWN, MAPPED, RETURNED } = PaymentStates;

interface IPaymentBtns {
	type: string;
}

const GridBtns: React.FC<IPaymentBtns> = (props) => {
	const { t } = useTranslation();

	const renderButtons = (type: string) => {
		if (type == MAPPED) {
			return (
				<div className='d-flex justify-content-center mr-4'>
					<$Tooltip
						title={t(
							'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.APPORTION'
						)}>
						<$Button
							type='link'
							size='small'
							className='mr-1'
							icon={
								<BoxIcons
									type={IconTypes.BOX_ICON}
									name='apportion'
								/>
							}
						/>
					</$Tooltip>
					<$Tooltip
						title={t(
							'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MATCH'
						)}>
						<$Button
							size='small'
							className='mr-1'
							icon={
								<BoxIcons
									type={IconTypes.BOX_ICON}
									name='match'
								/>
							}></$Button>
					</$Tooltip>
					<$Tooltip
						title={t(
							'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REVERT'
						)}>
						<$Button
							size='small'
							icon={
								<BoxIcons
									type={IconTypes.BOX_ICON}
									name='revert'
								/>
							}
						/>
					</$Tooltip>
				</div>
			);
		} else if (type == EXCEEDED) {
			return (
				<div className='d-flex justify-content-center mr-4'>
					<$Tooltip
						title={t(
							'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MATCH'
						)}>
						<$Button
							size='small'
							className='mr-1'
							icon={
								<BoxIcons
									type={IconTypes.BOX_ICON}
									name='match'
								/>
							}></$Button>
					</$Tooltip>
					<$Tooltip
						title={t(
							'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TRANSFER'
						)}>
						<$Button
							size='small'
							className='mr-1'
							icon={
								<BoxIcons
									type={IconTypes.BOX_ICON}
									name='transfer'
								/>
							}></$Button>
					</$Tooltip>
					<$Tooltip title={t('US.COLLECTION.COMMON:COMMON.DELETE')}>
						<$Button
							size='small'
							type='primary'
							danger
							icon={<DeleteOutlined />}></$Button>
					</$Tooltip>
				</div>
			);
		} else if (type == UNKNOWN) {
			return (
				<div className='d-flex justify-content-center mr-4'>
					<$Tooltip
						title={t(
							'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MATCH'
						)}>
						<$Button
							size='small'
							className='mr-1'
							icon={
								<BoxIcons
									type={IconTypes.BOX_ICON}
									name='match'
								/>
							}></$Button>
					</$Tooltip>
					<$Tooltip title={t('US.COLLECTION.COMMON:COMMON.DELETE')}>
						<$Button
							size='small'
							type='primary'
							danger
							icon={<DeleteOutlined />}></$Button>
					</$Tooltip>
				</div>
			);
		} else if (type == RETURNED) {
			return (
				<div className='d-flex justify-content-center mr-4'>
					<$Tooltip
						title={t(
							'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN'
						)}>
						<$Button
							size='small'
							icon={
								<BoxIcons
									type={IconTypes.BOX_ICON}
									name='revert'
								/>
							}></$Button>
					</$Tooltip>
				</div>
			);
		} else {
			return <div />;
		}
	};

	return renderButtons(props.type);
};

export default GridBtns;
