export enum ItemTypes {
  INIT_ITEM_TYPES_HOME = "INIT_ITEM_TYPES_HOME",
  INIT_ITEM_TYPES_HOME_SUCCESS = "INIT_ITEM_TYPES_HOME_SUCCESS",
  INIT_ITEM_TYPES_HOME_FAILED = "INIT_ITEM_TYPES_HOME_FAILED",

  SEARCH_ITEM_TYPES = "SEARCH_ITEM_TYPES",
  SEARCH_ITEM_TYPES_SUCCESS = "SEARCH_ITEM_TYPES_SUCCESS",
  SEARCH_ITEM_TYPES_FAILED = "SEARCH_ITEM_TYPES_FAILED",

  GET_TRANSACTION_GROUPS = "GET_TRANSACTION_GROUPS",
  GET_TRANSACTION_GROUPS_SUCCESS = "GET_TRANSACTION_GROUPS_SUCCESS",
  GET_TRANSACTION_GROUPS_FAILED = "GET_TRANSACTION_GROUPS_FAILED",

  ADD_NEW_VOUCHER_SEQUENCE = "ADD_NEW_VOUCHER_SEQUENCE",
  ADD_NEW_VOUCHER_SEQUENCE_SUCCESS = "ADD_NEW_VOUCHER_SEQUENCE_SUCCESS",
  ADD_NEW_VOUCHER_SEQUENCE_FAILED = "ADD_NEW_VOUCHER_SEQUENCE_FAILED",

  HANDLE_ITEM_TYPE_DRAWER = "HANDLE_ITEM_TYPE_DRAWER",
  HANDLE_ITEM_TYPE_DRAWER_BREADCRUMB = "HANDLE_ITEM_TYPE_DRAWER_BREADCRUMB",
  DELETE_ITEM_TYPE = "DELETE_ITEM_TYPE",
  RESET_ITEM_TYPE_DETAILS = "RESET_ITEM_TYPE_DETAILS",

  GET_INIT_ITEM_TYPE_DATA = "GET_INIT_ITEM_TYPE_DATA",
  GET_INIT_ITEM_TYPE_DATA_SUCCESS = "GET_INIT_ITEM_TYPE_DATA_SUCCESS",
  GET_INIT_ITEM_TYPE_DATA_FAILED = "GET_INIT_ITEM_TYPE_DATA_FAILED",

  GET_ITEM_TYPE_DETAILS = "GET_ITEM_TYPE_DETAILS",
  GET_ITEM_TYPE_DETAILS_SUCCESS = "GET_ITEM_TYPE_DETAILS_SUCCESS",
  GET_ITEM_TYPE_DETAILS_FAILED = "GET_ITEM_TYPE_DETAILS_FAILED",

  DUPLICATE_VALIDATE_FIELDS = "DUPLICATE_VALIDATE_ITEM_TYPE_FIELDS",
  DUPLICATE_VALIDATE_FIELDS_SUCCESS = "DUPLICATE_VALIDATE_ITEM_TYPE_FIELDS_SUCCESS",
  DUPLICATE_VALIDATE_FIELDS_FAILED = "DUPLICATE_VALIDATE_ITEM_TYPE_FIELDS_FAILED",

  ADD_NEW_ITEM_TYPE = "ADD_NEW_ITEM_TYPE",
  ADD_NEW_ITEM_TYPE_SUCCESS = "ADD_NEW_ITEM_TYPE_SUCCESS",
  ADD_NEW_ITEM_TYPE_FAILED = "ADD_NEW_ITEM_TYPE_FAILED",

  EDIT_ITEM_TYPE = "EDIT_ITEM_TYPE",
  EDIT_ITEM_TYPE_SUCCESS = "EDIT_ITEM_TYPE_SUCCESS",
  EDIT_ITEM_TYPE_FAILED = "EDIT_ITEM_TYPE_FAILED",
}
