import {
  ISaveItemTypePayload,
  IDistributionFactor,
} from "us.collection.economy/interfaces/ItemTypes";
import { TransactionTypes } from "us.collection.economy/components/ItemTypes/Constants";
import _ from "lodash";

export function SaveItemType(this: any): ISaveItemTypePayload {
  return {
    itemTypeId: Number(this.itemTypeId),
    itemTypeName: this.itemTypeName,
    transactionCode: this.transactionCode,
    description: this.description,
    transactionGroupId: this.transactionGroupId,
    defaultAmount: Number(this.defaultAmount.toFixed(2)),
    voucherSequenceId: this.voucherSequenceId,
    bankAccountNumber: this.bankAccountNumber,
    ledgerAccountNumber: this.ledgerAccountNumber,
    isRemappingAllowed: this.isRemappingAllowed,
    subCaseMappingOnly: this.subCaseMappingOnly,
    isInterestApplicable: this.isInterestApplicable,
    isPayment: this.transactionType == TransactionTypes.PAYMENT ? true : false,
    isCancelationType:
      this.transactionType == TransactionTypes.CANCELATION_TYPE ? true : false,
    isVatLiable: this.isVatLiable,
    vatCode: this.vatCode,
    distributionFactors:
      this.transactionType == TransactionTypes.FEES
        ? this.distributionFactors?.map(
            (distributionFactor: IDistributionFactor) => ({
              ...distributionFactor,
              creditorFraction: Number(
                distributionFactor.creditorFraction?.toFixed(2)
              ),
              bureauFraction: Number(
                distributionFactor.bureauFraction?.toFixed(2)
              ),
            })
          )
        : null,
  };
}
