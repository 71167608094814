import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlusOutlined } from "us.icons";
import { Formik } from "formik";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { ConnectedProps, connect } from "react-redux";
import {
  $Button,
  $FormItem,
  $Input,
  $InputAmount,
  $Form,
  $Radio,
  $AmountLabel,
  $Select,
  $Popconfirm,
  $InputAccountNo,
  $MessageBox,
} from "us.common/components";
import { managePayment } from "us.collection.economy/actions";
import {
  IFormValues,
  IMatch,
  IMatchRemainingAction,
  MatchRemainingAction,
} from "us.collection.economy/components/ManagePayments/interface";
import {
  MatchHelper,
  PaymentHelper,
} from "us.collection.economy/components/ManagePayments/Functions";
import { CaseTable } from "./components";
import { RegexOnlyNumbers } from "us.collection.economy/constants/Payments";
import {
  MatchPayment,
  GetPayments,
  GetCases,
} from "us.collection.economy/components/ManagePayments/Repository";
import { ManagePaymentValidationSchema } from "us.collection.economy/components/ManagePayments/Validations";
import { RootState } from "us.helper/types";

const {
  getCases,
  matchPayment,
  deleteCase,
  getCaseValidationData,
  resetMatch,
} = managePayment;

const Match: React.FC<IMatch & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { search } = useLocation();
  const params = queryString.parse(search);

  const {
    payments,
    cases,
    currentCurrency,
    currentLanguage,
    isMatchingPayment,
    matchPaymentData,
    parentFormValues,
    isCaseValidating,
    getCaseValidationData,
    getCases,
    matchPayment,
    deleteCase,
    resetMatch,
  } = props;

  const {
    payment,
    isExceeded,
    caseTableData,
    casesData,
    remainingBalance,
    returnAmount,
    exceededOrUnknownAmount,
  } = matchPaymentData ?? {};
  const {
    accountNo = "",
    returnfee = 0,
    itemTypeId,
    amount = 0,
    minimumExceedAmount = 0,
  } = payment ?? {};

  const [formValues, setFormValues] = useState<IFormValues>({
    isInDepartments: false,
    debtorAccountNo: accountNo,
    matchCaseNo: "",
    remainingBalance,
    returnAmount,
    returnFee: returnfee,
    caseTableData,
    remainingAction: MatchRemainingAction.LEAVE_EXCEEDED,
  });

  // load cases for the selected
  useEffect(() => {
    if (
      params?.id &&
      !payments.isFetching &&
      !cases.isFetching &&
      payments.data.raw.length > 0
    ) {
      const paymentForMatch = PaymentHelper.getPaymentForMatch(
        Number(params.id),
        payments.data.raw
      );
      if (paymentForMatch) {
        const {
          accountNo,
          returnfee,
          minimumExceedAmount,
          exceededSum,
          unknownSum,
        } = paymentForMatch;
        const remainingAction =
          minimumExceedAmount >= remainingBalance
            ? MatchRemainingAction.ROUND_OFF
            : MatchRemainingAction.LEAVE_EXCEEDED;
        const updatedFormValues = {
          ...formValues,
          debtorAccountNo: accountNo ?? "",
          returnFee: returnfee,
          isInDepartments: false,
          remainingAction,
        };
        setFormValues(updatedFormValues);
        if (exceededSum > 0) {
          const values = GetCases.call({
            ...paymentForMatch,
            ...updatedFormValues,
          });
          getCases(values);
        } else if (unknownSum > 0) {
          resetMatch && resetMatch({ ...paymentForMatch });
        } else {
          resetMatch && resetMatch({});
        }
      }
    }
  }, [params?.id]);

  // update all amount and balance in the UI with new matchPaymentData
  useEffect(() => {
    if (
      matchPaymentData &&
      !payments.isFetching &&
      payments.data.raw &&
      payment &&
      caseTableData?.length > 0
    ) {
      updateBalances(caseTableData);
    }
  }, [matchPaymentData]);

  // handle mapping balance change for each case item
  const onMappingBalanceChangeHandler = (data: {
    value: number;
    record: { arId: string | number; caseNo: string };
  }) => {
    const { value, record } = data;
    const updatedTableData = caseTableData.map((rowItem: any) => {
      const { arId, children } = rowItem;
      if (arId == record.arId) {
        const caseItemToUpdate = children.find(
          (e: { caseNo: string }) => e.caseNo == record.caseNo
        );
        if (caseItemToUpdate !== undefined) {
          caseItemToUpdate.mappingBalance = value;
        }
      }
      return rowItem;
    });
    updateBalances(updatedTableData);
  };

  const onAddNewCase = (values: { matchCaseNo: string }) => {
    const { matchCaseNo } = values;
    const isAlreadyAdded = casesData.some(
      (caseItem: { caseNo: string }) => caseItem.caseNo == matchCaseNo
    );
    if (!isAlreadyAdded) {
      if (matchCaseNo && RegexOnlyNumbers.test(matchCaseNo)) {
        getCaseValidationData({
          caseNo: matchCaseNo,
          itemTypeId,
        });
      }
    } else {
      // case no already added
      $MessageBox(
        "warning",
        `US.COLLECTION.ECONOMY:VALIDATIONS.SELECTED_CASE_NO_IS_ALREADY_ADDED`,
        "",
        ""
      );
    }
  };

  const updateBalances = (formattedData?: Array<any>) => {
    const newExceededBalance = MatchHelper.getExceededOrUnknownBalance(
      formattedData ?? caseTableData,
      amount
    );
    setFormValues({
      ...formValues,
      remainingBalance: newExceededBalance,
      returnAmount:
        newExceededBalance > 0 ? newExceededBalance - formValues.returnFee : 0,
      caseTableData: formattedData ?? formValues.caseTableData,
      remainingAction:
        minimumExceedAmount >= newExceededBalance
          ? MatchRemainingAction.ROUND_OFF
          : MatchRemainingAction.LEAVE_EXCEEDED,
    });
  };

  // check whether Match submit is enable or not
  const isCanSubmit = (
    isValid: boolean,
    values: {
      returnAmount: number;
      remainingBalance: number;
      remainingAction: IMatchRemainingAction;
      debtorAccountNo: string;
    }
  ): boolean => {
    try {
      const {
        returnAmount,
        remainingBalance,
        remainingAction,
        debtorAccountNo,
      } = values;

      const isValidData = isValid && caseTableData?.length > 0;
      const isFullyMapped = remainingBalance === 0;

      const isPartiallyMapped = remainingBalance < exceededOrUnknownAmount;

      const isCanLeaveExceed =
        remainingAction === MatchRemainingAction.LEAVE_EXCEEDED &&
        amount > remainingBalance &&
        isPartiallyMapped &&
        remainingBalance !== 0;

      const isCanReturnExceed =
        (remainingAction === MatchRemainingAction.RETURN_EXCEEDED &&
          remainingBalance !== 0 &&
          debtorAccountNo.length === 11) ||
        returnAmount >= 0 ||
        isPartiallyMapped;

      const isCanRoundOff =
        remainingAction === MatchRemainingAction.ROUND_OFF &&
        minimumExceedAmount >= remainingBalance &&
        isPartiallyMapped &&
        remainingBalance !== 0;

      const isAbleToSubmit =
        isValidData &&
        (isFullyMapped ||
          isCanLeaveExceed ||
          isCanReturnExceed ||
          isCanRoundOff);

      return isAbleToSubmit;
    } catch (error) {
      return false;
    }
  };

  // handle submit
  const onSubmit = (values: any, _actions: any) => {
    const paramsMatch = MatchPayment.call({
      ...values,
      ...payment,
    });
    const searchParams = GetPayments.call(parentFormValues);
    matchPayment({ paramsMatch, searchParams, isExceeded });
  };

  return (
    <Formik
      id={"form"}
      initialValues={formValues}
      enableReinitialize
      validationSchema={ManagePaymentValidationSchema}
      validateOnChange
      validateOnBlur
      validateOnMount
      handleSubmit
      onSubmit={onSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        validateForm,
        resetForm,
        isValid,
        ...restProps
      }: any) => (
        <>
          <div className="map-action-content mb-auto ">
            <h2>{t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MATCH")}</h2>
            <div className="d-flex align-items-center justify-content-between">
              <div className="d-flex align-items-center">
                <div className="mr-2">
                  <$Input
                    name="matchCaseNo"
                    placeholder={t(
                      "US.COLLECTION.ECONOMY:PLACE_HOLDERS.CASE_NO"
                    )}
                    size="small"
                    type="text"
                    required
                    allowClear
                    disabled={isCaseValidating}
                    dataTestid={"match-input-case-no"}
                    onPressEnter={() => onAddNewCase(values)}
                  />
                </div>
                <$Button
                  onClick={() => onAddNewCase(values)}
                  type="default"
                  size="small"
                  disabled={!values.matchCaseNo}
                  loading={isCaseValidating}
                  icon={<PlusOutlined />}
                  className="mb-1"
                  data-testid="match-add-case-btn"
                >
                  {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ADD_CASE_NO")}
                </$Button>
              </div>
              {isExceeded && (
                <div>
                  <$Select
                    dataTestid={"select-cases-in-departments"}
                    name="isInDepartments"
                    size="small"
                    options={[
                      {
                        label: t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASES_IN_THE_AR"
                        ),
                        isInDepartments: false,
                      },
                      {
                        label: t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASES_IN_THE_ORGANIZATION"
                        ),
                        isInDepartments: true,
                      },
                    ]}
                    optionValue="isInDepartments"
                    optionText="label"
                    allOption={false}
                    autoFocus={true}
                    required
                    showSearch={false}
                    style={{ minWidth: "200px" }}
                    onChange={(value) => {
                      restProps.setFieldValue("isInDepartments", value);
                      setFormValues({
                        ...values,
                        isInDepartments: value,
                      });
                      const params = GetCases.call({
                        ...payment,
                        isInDepartments: value,
                      });
                      getCases(params);
                    }}
                  />
                </div>
              )}
            </div>
            <CaseTable
              data={caseTableData}
              exceededBalance={values.remainingBalance}
              isLoading={cases.isFetching}
              currentCurrency={currentCurrency}
              currentLanguage={currentLanguage}
              onChangeMappingBalance={onMappingBalanceChangeHandler}
              onDeleteCase={deleteCase}
            />
          </div>
          <div className="map-action-footer flex-column ">
            {values.remainingBalance > 0 && caseTableData?.length > 0 && (
              <div
                className="d-flex justify-content-between"
                data-testid="radio-button-content"
              >
                <$FormItem>
                  <$Radio
                    className="mb-5"
                    name="remainingAction"
                    optionText="label"
                    optionValue="remainingAction"
                    options={[
                      {
                        remainingAction: MatchRemainingAction.RETURN_EXCEEDED,
                        label: isExceeded
                          ? t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_EXCEED_BALANCE"
                            )
                          : t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_UNKNOWN_BALANCE"
                            ),
                        disabled:
                          true
                      },
                      {
                        remainingAction: MatchRemainingAction.LEAVE_EXCEEDED,
                        label: isExceeded
                          ? t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.LEAVE_EXCEED_BALANCE"
                            )
                          : t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.LEAVE_AS_EXCEEDED"
                            ),
                        disabled:
                          values.remainingBalance === exceededOrUnknownAmount ||
                          values.remainingBalance <= minimumExceedAmount,
                      },
                      {
                        remainingAction: MatchRemainingAction.ROUND_OFF,
                        label: t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ROUND_OFF"
                        ),
                        disabled:
                          minimumExceedAmount < values.remainingBalance ||
                          values.remainingBalance === 0,
                      },
                    ]}
                    onChange={(e: any) => {
                      setFormValues({
                        ...values,
                        remainingAction: e.target.value,
                      });
                    }}
                  />
                </$FormItem>
                <div className="d-flex flex-column justify-content-right text-right text-nowrap">
                  <span
                    className="font-weight-bold"
                    data-testid="match-payment-balance"
                  >
                    <$AmountLabel value={values.remainingBalance} />
                  </span>
                  <span data-testid="match-payment-balance-label">
                    {isExceeded
                      ? t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ESTIMATED_EXCEEDED_BALANCE"
                        )
                      : t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN_BALANCE"
                        )}
                  </span>
                </div>
              </div>
            )}
            {values.remainingBalance === 0 && caseTableData?.length > 0 && (
              <div className="d-flex justify-content-end mb-5">
                <div className="d-flex flex-column justify-content-right text-right text-nowrap">
                  <span
                    className="font-weight-bold"
                    data-testid="match-payment-balance"
                  >
                    <$AmountLabel value={values.remainingBalance} />
                  </span>
                  <span data-testid="match-payment-balance-label">
                    {isExceeded
                      ? t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ESTIMATED_EXCEEDED_BALANCE"
                        )
                      : t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN_BALANCE"
                        )}
                  </span>
                </div>
              </div>
            )}
            {caseTableData?.length > 0 && (
              <div className="d-flex justify-content-between align-items-end">
                <div className="flex-fill">
                  {values.remainingBalance > 0 &&
                    values.remainingAction ===
                      MatchRemainingAction.RETURN_EXCEEDED &&
                    values.remainingAction !==
                      MatchRemainingAction.ROUND_OFF && (
                      <$Form layout="vertical">
                        <div className="d-flex" data-testid="return-layout">
                          <div className="d-flex flex-column mr-2">
                            <span>
                              {t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_AMOUNT"
                              )}
                            </span>

                            <span
                              className="font-weight-bold mt-2"
                              data-testid="return-amount"
                            >
                              <$AmountLabel value={values.returnAmount} />
                            </span>
                          </div>
                          <div className="d-flex flex-column ml-2 mr-2">
                            <$InputAccountNo
                              name="debtorAccountNo"
                              value={values.debtorAccountNo}
                              label={t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ACCOUNT_NO"
                              )}
                              size="small"
                              type="text"
                              required={
                                values.remainingAction ===
                                  MatchRemainingAction.RETURN_EXCEEDED &&
                                values.remainingBalance > 0
                              }
                              onChange={(e) => {
                                restProps.setFieldValue(
                                  "debtorAccountNo",
                                  e.target.value.trim()
                                );
                                setFormValues({
                                  ...values,
                                  debtorAccountNo: e.target.value.trim(),
                                });
                              }}
                              dataTestid={"return-account-no"}
                            />
                          </div>
                          <div
                            className="d-flex flex-column"
                            data-testid="input-return-fee"
                          >
                            <$InputAmount
                              currentCurrency={currentCurrency}
                              currentLanguage={currentLanguage}
                              label={t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_FEE"
                              )}
                              min={0}
                              placeholder="0,00"
                              size="small"
                              name="returnFee"
                              value={values.returnFee}
                              onChange={(value) => {
                                restProps.setFieldValue(
                                  "returnAmount",
                                  values.remainingBalance - value
                                );
                                setFormValues({
                                  ...values,
                                  returnFee: value,
                                  returnAmount: values.remainingBalance - value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </$Form>
                    )}
                </div>
                <div className="ml-3 mb-2 ">
                  <$Popconfirm
                    title={t("COMMON.SURE_TO_PROCEED_?")}
                    placement="topLeft"
                    onConfirm={() => handleSubmit()}
                    okText={t("COMMON.YES")}
                    cancelText={t("COMMON.NO")}
                    disabled={!isCanSubmit(isValid, values)}
                    data-testid="tooltip-confirm"
                  >
                    <$Button
                      type="primary"
                      id={"btnMatchConfirm"}
                      htmlType="submit"
                      loading={isMatchingPayment}
                      disabled={!isCanSubmit(isValid, values)}
                    >
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CONFIRM")}
                    </$Button>
                  </$Popconfirm>
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { managePayment, common } = state;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;

  const {
    payments,
    paymentAccounts,
    paymentDistribution,
    cases,
    matchPaymentResult,
    matchPaymentData,
    caseValidation,
  } = managePayment;
  return {
    payments,
    paymentAccounts,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    paymentDistribution,
    cases,
    isMatchingPayment: matchPaymentResult.isFetching,
    matchPaymentData,
    isCaseValidating: caseValidation.isFetching,
  };
};

const mapDispatchToProps = {
  getCaseValidationData,
  getCases,
  matchPayment,
  deleteCase,
  resetMatch,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Match);
