import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import Common from "us.common";
import { connect, ConnectedProps } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { IRootState } from "us.collection/interfaces";
import {
  INITIAL_FORM_VALUES,
  PaymentsImportSummaryKey,
  PAYMENTS_IMPORT_SUMMARY_TABLE_COLUMNS,
} from "./Constants";
import { paymentsImportSummary } from "us.collection.economy/actions";
import { IPaymentsImportSummary } from "us.collection.economy/interfaces";
import { GetPaymentsImportSummary } from "./Repository";
import moment, { Moment } from "moment";
import { SearchOutlined } from "us.icons";
import { OCRSummary } from "./Interfaces";
import { getColumnWidth } from "./Functions";
import { handleGoBack } from "us.helper";

const {
  $Affix,
  $PageHeader,
  $RangePicker,
  $Divider,
  $Table,
  $AmountLabel,
  $Skeleton,
  $Button,
  $Input,
  $Tooltip,
  $DateLabel,
  $Switch,
} = Common.Components;

const PaymentsImportSummary: React.FC<PropsFromRedux & IPaymentsImportSummary> =
  (props) => {
    const { t } = useTranslation();
    const history = useHistory();
    const { currentDateFormat, summary, getSummary } = props;
    const [calendarInfo, setCalendarInfo] = useState<{
      dates: Array<moment.Moment>;
      dateRange?: [Moment, Moment];
    }>({ dates: [], dateRange: [moment().subtract(30, "days"), moment()] });
    const { search } = useLocation();
    const params = queryString.parse(search);

    // initial load data
    useEffect(() => {
      const requestParams = GetPaymentsImportSummary.call({
        ...INITIAL_FORM_VALUES,
        fileName: params?.name,
      });
      getSummary && getSummary(requestParams);
    }, []);

    // set dates that should disabled for selecting more than 31 days
    const disabledDate = (current: any) => {
      const { dates } = calendarInfo;
      const [startDate, endDate] = dates;
      // disable future dates
      if (current.isAfter(moment())) return true;
      // don't disable if no dates selected
      if (!dates || dates.length === 0) {
        return false;
      }
      // if first date selected then
      const tooLate = startDate && current.diff(startDate, "days") >= 31;
      // if last date selected without first date then
      const tooEarly =
        !startDate && endDate && endDate.diff(current, "days") >= 31;
      return tooLate || tooEarly;
    };

    // search summaries
    const onSearch = (values: any) => {
      const requestParams = GetPaymentsImportSummary.call(values);
      getSummary && getSummary(requestParams);
    };

    // generate columns for the table
    const getColumns: any = (isVisibleATGs: boolean) => {
      const tableColumns = isVisibleATGs
        ? PAYMENTS_IMPORT_SUMMARY_TABLE_COLUMNS
        : PAYMENTS_IMPORT_SUMMARY_TABLE_COLUMNS.filter(
            ({ title }: any) => title !== "ATG"
          );
      const columns: any[] = [];
      tableColumns.map(({ key, title, children }: any) => {
        const width = getColumnWidth(key);
        let column: any = {
          key,
          dataIndex: key,
          title: title
            ? t(`US.COLLECTION.ECONOMY:PAYMENTS_IMPORT_SUMMARY.${title}`)
            : "",
          width,
        };
        if ([PaymentsImportSummaryKey.FILE_NAME].includes(key)) {
          column = {
            ...column,
            sorter: (a: any, b: any) => a[key].localeCompare(b[key]),
          };
        } else if ([PaymentsImportSummaryKey.IMPORTED_USER].includes(key)) {
          column = {
            ...column,
            sorter: (a: any, b: any) => a[key].localeCompare(b[key]),
            ellipsis: {
              showTitle: false,
            },
            render: (text: any, record: any, index: number) => {
              return (
                <$Tooltip placement="top" title={record.importedUser ?? ""}>
                  {record.importedUser}
                </$Tooltip>
              );
            },
          };
        } else if (key === PaymentsImportSummaryKey.IMPORTED_DATE) {
          column = {
            ...column,
            sorter: (a: any, b: any) => a[key].localeCompare(b[key]),
            render: (text: any, record: any) => (
              <$DateLabel value={record[key]} />
            ),
          };
        } else {
          column = {
            ...column,
            children: children.map(({ key, title, align }: any) => {
              const width = getColumnWidth(key);
              if (
                [
                  PaymentsImportSummaryKey.TOTAL_PAYMENTS_AMOUNT,
                  PaymentsImportSummaryKey.MAPPED_PAYMENTS_AMOUNT,
                  PaymentsImportSummaryKey.EXCEEDED_PAYMENTS_AMOUNT,
                  PaymentsImportSummaryKey.UNKNOWN_PAYMENTS_AMOUNT,
                ].includes(key)
              ) {
                return {
                  key,
                  align,
                  width,
                  dataIndex: key,
                  title: title
                    ? t(
                        `US.COLLECTION.ECONOMY:PAYMENTS_IMPORT_SUMMARY.${title}`
                      )
                    : "",
                  sorter: (a: any, b: any) => a[key] - b[key],
                  render: (text: any, record: any) => (
                    <$AmountLabel value={record[key]} data-testid={key} />
                  ),
                };
              } else {
                return {
                  key,
                  width,
                  dataIndex: key,
                  title: title
                    ? t(
                        `US.COLLECTION.ECONOMY:PAYMENTS_IMPORT_SUMMARY.${title}`
                      )
                    : "",
                  sorter: (a: any, b: any) => a[key] - b[key],
                };
              }
            }),
          };
        }
        columns.push(column);
      });
      return columns;
    };

    return (
      <Formik
        initialValues={{
          ...INITIAL_FORM_VALUES,
          fileName: params?.name ?? "",
          dateRange: calendarInfo.dateRange,
        }}
        enableReinitialize
        onSubmit={onSearch}
      >
        {({
          values,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          isValidating,
          resetForm,
          ...restProps
        }: any) => (
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border d-flex flex-nowrap justify-content-between">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t(
                      "US.COLLECTION.ECONOMY:PAYMENTS_IMPORT_SUMMARY.PAYMENTS_IMPORT_SUMMARY"
                    )}
                  />
                  <$Divider className="bui-devider" type="vertical" />

                  <div className="d-flex flex-fill justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <div style={{ width: 260 }} className="mt-1">
                        <$RangePicker
                          name="dateRange"
                          size="small"
                          picker="date"
                          minuteStep={15}
                          order={false}
                          currentTimeFormat={currentDateFormat}
                          loading={summary.isLoading}
                          disabled={summary.isLoading}
                          onCalendarChange={(val: any) => {
                            setCalendarInfo({ ...calendarInfo, dates: val });
                          }}
                          onOpenChange={(isOpen: boolean) => {
                            if (isOpen) {
                              setCalendarInfo({ ...calendarInfo, dates: [] });
                              restProps.setFieldValue("dateRange", undefined);
                            } else {
                              restProps.setFieldValue(
                                "dateRange",
                                calendarInfo.dateRange
                              );
                            }
                          }}
                          onChange={(dateRange: any) => {
                            if (dateRange) {
                              setCalendarInfo({ ...calendarInfo, dateRange });
                              onSearch({ ...values, dateRange });
                            }
                          }}
                          disabledDate={disabledDate}
                        />
                      </div>
                      <div className="mx-2 mt-1">
                        <$Input
                          name="fileName"
                          size="small"
                          type="text"
                          placeholder={t(
                            "US.COLLECTION.ECONOMY:PLACE_HOLDERS.FILE_NAME"
                          )}
                          onChange={(e: any) => {
                            const fileName = e.target.value;
                            restProps.setFieldValue("fileName", fileName);
                            if (!fileName) {
                              onSearch({
                                ...values,
                                fileName,
                              });
                            }
                          }}
                          onPressEnter={(e: any) => {
                            const fileName = e.target.value;
                            if (fileName) {
                              onSearch({
                                ...values,
                                fileName,
                              });
                            }
                          }}
                          data-testid="search-file-name"
                          allowClear={true}
                        />
                      </div>
                      <$Button
                        id={"btnSearch"}
                        onClick={handleSubmit}
                        className="mr-2 mt-1"
                        type="default"
                        size="small"
                        icon={<SearchOutlined />}
                        disabled={summary.isLoading}
                      />
                    </div>
                    <div>
                      <a
                        href={`/import-payments`}
                        target="_blank"
                        data-testid="navigate-import-payments"
                      >
                        {t("US.COLLECTION.ECONOMY:PAYMENTS_IMPORT_SUMMARY.IMPORT_PAYMENTS")}
                      </a>
                    </div>
                  </div>
                </div>
                <div className="d-flex align-items-center py-1">
                  <$Switch
                    checked={values.isVisibleATGs}
                    size="small"
                    name={"isVisibleATGs"}
                    disabled={summary.isLoading}
                    onChange={(isVisibleATGs: boolean) => {
                      restProps.setFieldValue("isVisibleATGs", isVisibleATGs);
                    }}
                  />
                  <label className="ml-2">
                    {t(
                      "US.COLLECTION.ECONOMY:PAYMENTS_IMPORT_SUMMARY.SHOW_ATGS"
                    )}
                  </label>
                </div>
              </div>
            </$Affix>
            <$Skeleton
              loading={summary.isLoading}
              active
              paragraph={{ rows: 2 }}
            >
              <$Table
                rowKey={(record: OCRSummary) => record.fileName}
                columns={getColumns(values.isVisibleATGs)}
                dataSource={summary.data}
                className="mt-3"
                bordered
                scroll={{ x: 1500 }}
                pagination={{ defaultPageSize: 20 }}
              />
            </$Skeleton>
          </div>
        )}
      </Formik>
    );
  };

const mapStateToProps = (state: IRootState) => {
  const { common, paymentsImportSummary } = state;

  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  const { summary } = paymentsImportSummary;

  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    summary,
  };
};

const mapDispatchToProps = {
  getSummary: paymentsImportSummary.summary.search,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PaymentsImportSummary);
