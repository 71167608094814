import moment from "moment";
import { i18n } from "us.helper";
import {
  ItemTypeIds,
  VATAmountInfo,
  PropertyKeys,
  TransTypes,
  AmountTypes,
  CoveringOrder,
  TotalAmountByText,
  GroupTextTypes,
  TransactionGroupId,
} from "../Constant";
import {
  AddNewApportionment,
  AddApportionmentReturnToDebtor,
  AddAppotionmentItems,
} from "../Repository";

/**
 * @description calculate sum by property
 * @param {Array<any>} caseTableData - Current case table data
 * @param {string} key - property that wants to get sum
 * @returns {number} - sum of the required property
 */
export const sumBy = (data: any[], key: string) => {
  try {
    if (Array.isArray(data)) {
      let sum = 0.0;
      data.map((item: any) => {
        if (key == PropertyKeys.TRANSACTION_BALANCE) {
          sum = sum + (!item.isProvision ? item[key] : 0);
        } else {
          sum = sum + item[key];
        }
      });
      return Number(sum.toFixed(2));
    } else {
      return 0;
    }
  } catch (error) {
    console.log(error);
    return 0;
  }
};

/**
 * @description group an array by property
 * @param {Array<any>} caseTableData - Current case table data
 * @param {string} key - property that wants to group by
 * @returns {Array<any>} - grouped case table data
 */
export const groupBy = (data: any[], key: string) => {
  return data.reduce((result: any, obj: any) => {
    (result[obj[key]] = result[obj[key]] || []).push(obj);
    return result;
  }, {});
};

/**
 * @description get sub text for invoice text
 * @param {Object{any}} record - case table record
 * @returns {string} - sun text
 */
export const getSubText = (record: any): string => {
  switch (record?.groupText) {
    case GroupTextTypes.MAIN_AMOUNT:
      return i18n.t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.INVOICE_NO");
    case GroupTextTypes.COLLECTION_FEE:
      return record?.transactionText;
    case GroupTextTypes.COURT_FEE:
      return record?.transactionText;
    case GroupTextTypes.CASE_COST:
      return record?.transactionText;
    case GroupTextTypes.INTEREST:
      return record?.transactionText;
    case "":
      return i18n.t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.INVOICE_NO");
    default:
      return "";
  }
};

/**
 * @description calculate VAT amounts for bureau amounts
 * @param {Object{any}} payload - payload that contain vatData, values, record
 * @returns {Array<any>} - updated case table data
 */
export const updateVatAmounts = (payload: any) => {
  const { values, data, record } = payload;
  const { vatAmount, vatBasis, vatDeducted, vatPaidByDebtor } = data;
  const { caseNo, paymentArItemNo, text, childIndex } = record;
  try {
    const updatedTableData = values.caseTableData.map((item: any) => {
      if (item.caseNo == caseNo) {
        item.items.map((parent: any) => {
          if (parent.text == text) {
            item.totalVatAmount += vatAmount - record?.vatAmount;
            item.totalVatBasis += vatBasis - record?.vatBasis;
            item.totalVatDeducted += vatDeducted - record?.vatDeducted;
            item.totalVatPaidByDebtor +=
              vatPaidByDebtor - record?.vatPaidByDebtor;
            (parent.totalVatAmountByText += vatAmount - record?.vatAmount),
              (parent.totalVatBasisByText += vatBasis - record?.vatBasis),
              (parent.totalVatDeductedByText +=
                vatDeducted - record?.vatDeducted),
              (parent.totalVatPaidByDebtorByText +=
                vatPaidByDebtor - record?.vatPaidByDebtor);
            parent.children.map((child: any) => {
              if (
                child.paymentArItemNo == paymentArItemNo &&
                child.childIndex == childIndex
              ) {
                child.vatAmount = vatAmount;
                child.vatBasis = vatBasis;
                child.vatDeducted = vatDeducted;
                child.vatPaidByDebtor = vatPaidByDebtor;
              }
            });
          }
        });
      }
      return item;
    });
    return updatedTableData;
  } catch (error) {
    return values.caseTableData;
  }
};

/**
 * @description format caseTableData when apportionment loading
 * @param {Object{any}} payload - payload that contain apportionmentPayments, accountNo, paymentAmount
 * @returns {Array<any>} - updated case table data
 */
export const updateCaseTableData = (payload: any) => {
  const { data, accNo, amount } = payload;
  const exceededApportionment = data?.filter(
    (app: any) => app.transType === TransTypes.EXCEEDED_AMOUNT
  );
  const returnToDebtorApportionment = data?.filter(
    (app: any) => app.transType === TransTypes.RETURN_TO_DEBTOR
  );
  const filteredApportionment = data?.filter(
    (app: any) =>
      app.transType !== TransTypes.RETURN_TO_DEBTOR &&
      app.transType !== TransTypes.EXCEEDED_AMOUNT
  );
  const returnToDebtorAmount = returnToDebtorApportionment.filter(
    (rd: any) => rd.itemTypeId === 28
  );
  const returnToDebtorReturnFee = returnToDebtorApportionment.filter(
    (rd: any) => rd.itemTypeId === 25
  );
  const remittedApportionment = filteredApportionment.filter(
    (app: any) => app.isRemitted
  );
  const roundingOffApportionments = data?.filter(
    (ro: any) => ro.itemTypeId === 48
  );

  // group apportionment data according to caseNo
  const groupedByCaseNo = groupBy(
    Array.isArray(data) ? data : [],
    PropertyKeys.CASE_NO
  );

  // get grouped caseNo key array
  const groupedByCaseNoKeys = Object.keys(groupedByCaseNo);

  // cases format
  const cases: {
    caseNo: string;
    total: number;
    balance: number;
    totalCreditorAmount: number;
    totalDebtorAmount: number;
    totalOtherAmount: number;
    totalBureauAmount: number;
    items: any;
  }[] = [];

  // create caseTableData array with required fields
  groupedByCaseNoKeys.map((caseKey: any, parentIndex: number) => {
    const caseId = groupedByCaseNo[caseKey][0].caseId;
    const caseType = groupedByCaseNo[caseKey][0].caseType;
    let groupByText = groupBy(
      Array.isArray(groupedByCaseNo[caseKey]) ? groupedByCaseNo[caseKey] : [],
      PropertyKeys.TEXT
    );

    let groupedByTextKeys = Object.keys(groupByText);

    // filter data by covering order
    let OrderedText = CoveringOrder.filter((cov) =>
      groupedByTextKeys.includes(cov)
    );

    let itemArray: any = [];

    OrderedText.map((key: any, itemIndex: number) => {
      // create parent object
      let ob = {
        key: "p" + itemIndex + "-" + caseKey,
        text: key,
        isParent: true,
        amount: sumBy(groupByText[key], PropertyKeys.AMOUNT),
        children: groupByText[key].map((ch: any, i: number) => {
          // create child object
          let child = {
            ...ch,
            parentIndex: parentIndex,
            itemIndex: itemIndex,
            key: ch.paymentArItemNo,
            childIndex: i,
            paymentAmount: amount,
          };
          return child;
        }),
        totalTransBalanceByText: sumBy(
          groupByText[key],
          PropertyKeys.TRANSACTION_BALANCE
        ),
        totalCreditorAmountByText: sumBy(
          groupByText[key],
          AmountTypes.CREDITOR_AMOUNT
        ),
        totalDebtorAmountByText: sumBy(
          groupByText[key],
          AmountTypes.DEBTOR_AMOUNT
        ),
        totalOtherAmountByText: sumBy(
          groupByText[key],
          AmountTypes.OTHER_AMOUNT
        ),
        totalBureauAmountByText: sumBy(
          groupByText[key],
          AmountTypes.BUREAU_AMOUNT
        ),
        totalVatAmountByText: sumBy(groupByText[key], VATAmountInfo.VAT_AMOUNT),
        totalVatBasisByText: sumBy(groupByText[key], VATAmountInfo.VAT_BASIS),
        totalVatDeductedByText: sumBy(
          groupByText[key],
          VATAmountInfo.VAT_DEDUCTED
        ),
        totalVatPaidByDebtorByText: sumBy(
          groupByText[key],
          VATAmountInfo.VAT_PAID_BY_DEBTOR
        ),
      };
      itemArray.push(ob);
    });
    let obj = {
      key: caseKey,
      caseNo: caseKey,
      caseId: caseId,
      caseType: caseType,
      total: sumBy(groupedByCaseNo[caseKey], PropertyKeys.AMOUNT),
      balance: sumBy(
        groupedByCaseNo[caseKey].filter((fill: any) => !fill.isProvision),
        PropertyKeys.TRANSACTION_BALANCE
      ),
      totalCreditorAmount: sumBy(
        groupedByCaseNo[caseKey],
        AmountTypes.CREDITOR_AMOUNT
      ),
      totalDebtorAmount: sumBy(
        groupedByCaseNo[caseKey],
        AmountTypes.DEBTOR_AMOUNT
      ),
      totalOtherAmount: sumBy(
        groupedByCaseNo[caseKey],
        AmountTypes.OTHER_AMOUNT
      ),
      totalBureauAmount: sumBy(
        groupedByCaseNo[caseKey],
        AmountTypes.BUREAU_AMOUNT
      ),
      totalVatAmount: sumBy(groupedByCaseNo[caseKey], VATAmountInfo.VAT_AMOUNT),
      totalVatBasis: sumBy(groupedByCaseNo[caseKey], VATAmountInfo.VAT_BASIS),
      totalVatDeducted: sumBy(
        groupedByCaseNo[caseKey],
        VATAmountInfo.VAT_DEDUCTED
      ),
      totalVatPaidByDebtor: sumBy(
        groupedByCaseNo[caseKey],
        VATAmountInfo.VAT_PAID_BY_DEBTOR
      ),
      items: itemArray,
    };
    cases.push(obj);
  });

  const caseTableData = cases.length > 0 ? cases : [];

  // calculate exceededAmount using created data
  const exceededAmount =
    exceededApportionment.length > 0
      ? sumBy(exceededApportionment, PropertyKeys.AMOUNT)
      : 0;

  // create roundOffApportionment object with initial values
  const roundOffApportionment =
    roundingOffApportionments.length > 0
      ? roundingOffApportionments[0]
      : {
          roundingOffVATCode: data[0].roundingOffVATCode,
          roundingOffVATLiable: data[0].roundingOffVATLiable,
          creditorVATStatus: data[0].creditorVATStatus,
          minimumExceedAmount: data[0].minimumExceedAmount,
        };

  // calculate returnToDebtor amount
  const returnToDebtor =
    returnToDebtorAmount.length > 0
      ? sumBy(returnToDebtorAmount, PropertyKeys.AMOUNT)
      : 0;

  // create returnFeeVat object with initial values
  const returnFeeVat =
    cases.length > 0
      ? {
          vatCode: data[0].returnFeeVATCode,
          returnFeeVATLiable: data[0].returnFeeVATLiable,
          creditorVATStatus: data[0].creditorVATStatus,
          advancedReturnFee: data[0].returnFee,
          vatAmount:
            returnToDebtorReturnFee.length > 0
              ? returnToDebtorReturnFee[0].vatAmount
              : 0,
          vatBasis:
            returnToDebtorReturnFee.length > 0
              ? returnToDebtorReturnFee[0].vatBasis
              : 0,
          vatDeducted:
            returnToDebtorReturnFee.length > 0
              ? returnToDebtorReturnFee[0].vatDeducted
              : 0,
          vatPaidByDebtor:
            returnToDebtorReturnFee.length > 0
              ? returnToDebtorReturnFee[0].vatPaidByDebtor
              : 0,
          amount:
            returnToDebtorReturnFee.length > 0
              ? returnToDebtorReturnFee[0].amount
              : 0,
        }
      : {
          vatAmount: 0,
          vatBasis: 0,
          vatDeducted: 0,
          vatPaidByDebtor: 0,
          amount: 0,
        };

  return {
    caseTableData,
    remainingAmount: amount - sumBy(caseTableData, PropertyKeys.TOTAL),
    exceededAmount,
    returnToDebtor,
    accountNo: accNo,
    returnFeeVat,
    isRemitted: remittedApportionment.length > 0,
    roundOffApportionment,
  };
};

/**
 * @description calculate VAT amount for return fee in footer
 * @param {Object{any}} payload - payload that contain values, vatData
 * @returns {Array<any>} - updated case table data with VAT
 */
export const updateVatAmountsForRD = (payload: any) => {
  const { values, data } = payload;
  const { caseTableData } = values;
  const { vatAmount, vatBasis, vatDeducted, vatPaidByDebtor } = data;
  const updatedReturnFeeVat = {
    ...values.returnFeeVat,
    vatAmount,
    vatBasis,
    vatDeducted,
    vatPaidByDebtor,
  };
  try {
    let newCaseTableDataWithVat =
      caseTableData != undefined &&
      caseTableData.map((item: any, index: number) => {
        item.items.map((rdItems: any, i: number) => {
          rdItems.children.map((rd: any, r: number) => {
            if (rd.itemTypeId == ItemTypeIds.RETURN_FEE) {
              rd.vatAmount = vatAmount;
              rd.vatBasis = vatBasis;
              rd.vatDeducted = vatDeducted;
              rd.vatPaidByDebtor = vatPaidByDebtor;
              item.totalVatAmount = sumBy(
                rdItems.children,
                VATAmountInfo.VAT_AMOUNT
              );
              item.totalVatBasis = sumBy(
                rdItems.children,
                VATAmountInfo.VAT_BASIS
              );
              item.totalVatDeducted = sumBy(
                rdItems.children,
                VATAmountInfo.VAT_DEDUCTED
              );
              item.totalVatPaidByDebtor = sumBy(
                rdItems.children,
                VATAmountInfo.VAT_PAID_BY_DEBTOR
              );
            }
          });
        });
        return item;
      });
    return { updatedReturnFeeVat, newCaseTableDataWithVat };
  } catch (error) {
    console.error(error);
    return { updatedReturnFeeVat: {}, newCaseTableDataWithVat: [] };
  }
};

/**
 * @description check record is existing in the caseTableData or not
 * @param {Array{any}} caseTableData - current case table data
 * @param {string} text - updated table row text
 * @returns {boolean} - if there is a existing record true other wise false
 */
export const isExistingRecord = (caseTableData: any, text: string) => {
  let updatedDataRowNew = false;
  try {
    caseTableData?.map((item: any) => {
      item.items.map((filterItem: any) => {
        if (filterItem.text.toLowerCase() == text.toLocaleLowerCase()) {
          updatedDataRowNew = true;
        }
      });
    });
    return updatedDataRowNew;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * @description check empty caseNo records are in the caseTableData
 * @param {Array{any}} caseTableData - current case table data
 * @param {string} text - updated table row text
 * @returns {boolean} - if there is a empty caseNo record true other wise false
 */
export const isCaseNoEmpty = (caseTableData: any) => {
  try {
    return caseTableData.filter((item: any) => item.caseNo == "").length > 0;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * @description find existing record with same text
 * @param {Array{any}} item - current case table data item
 * @param {string} text - updated table row text
 * @returns {boolean} - if there is a existing record with the text true other wise false
 */
export const getExistingTextRecords = (item: any, text: string) => {
  try {
    const items = item.items.filter(
      (filterItem: any) => filterItem.text == text
    );
    return [items, items.length > 0];
  } catch (error) {
    console.error(error);
    return [[], false];
  }
};

/**
 * @description find index for remove newly added record with amount 0
 * @param {Array{any}} caseTableData - current case table data
 * @param {number} typeId - itemTypeId of a updated record
 * @returns {number} - item index that contain given itemTypeId with 0 amount
 */
export const getRemoveIndex = (caseTableData: any, typeId: number) => {
  try {
    let removeParentIndex = 0;
    let removeChildIndex = 0;
    let removeItemIndex = 0;
    caseTableData?.map((item: any) => {
      item.items.map((parent: any, pIndex: number) => {
        parent.children.map((child: any, chIndex: number) => {
          if (child.isNew && child.amount == 0 && child.itemTypeId == typeId) {
            if (item.items.length == 1 && parent.children.length == 1) {
              removeItemIndex = item.key;
            } else if (parent.children.length == 1) {
              removeParentIndex = parent.key;
            } else {
              removeChildIndex = child.key;
            }
          }
        });
      });
    });
    return removeItemIndex != 0
      ? caseTableData.filter((item: any) => item.key != removeItemIndex)
      : removeParentIndex != 0
      ? caseTableData.map((item: any) => {
          return {
            ...item,
            items: item.items.filter(
              (parent: any, index: number) => parent.key != removeParentIndex
            ),
          };
        })
      : (typeId == 25 || typeId == 28) && removeChildIndex != 0
      ? caseTableData.map((item: any) => {
          return {
            ...item,
            items: item.items.map((parent: any, index: number) => {
              return {
                ...parent,
                children: parent.children.filter(
                  (child: any, chIndex: number) => child.key != removeChildIndex
                ),
              };
            }),
          };
        })
      : caseTableData;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * @description update caseTableData array when footer elements change
 * @param {Array{any}} mainPayment - main payment of selected apportionment
 * @param {object{}} payload - current caseTableData, accountNo
 * @param {number} amountValue - typed amount value
 * @param {string} text - text of a updated record
 * @param {number} typeId - itemTypeId of a updated record
 * @returns {Array<any>} - updated case table data
 */
export const updateCaseTableWithFooterChanges = (
  mainPayment: any,
  payload: any,
  amountValue: any,
  text: string,
  typeId: number
) => {
  let { caseTableData, accountNo } = payload;
  let childrenItems = [];
  let newCaseTableData = caseTableData;
  try {
    if (
      !isExistingRecord(caseTableData, text) &&
      typeId == ItemTypeIds.ROUNDING_OFF
    ) {
      if (
        caseTableData.filter((item: any) => item.caseNo == mainPayment.caseNo)
          .length > 0
      ) {
        let existingCase = caseTableData?.filter(
          (item: any) => item.caseNo == mainPayment.caseNo
        );
        let itemsParentIndex =
          existingCase[0]?.items[0]?.children[0]?.parentIndex;
        let itemsLength = existingCase[0]?.items?.length;
        let itemsInfo = { itemsLength, itemsParentIndex };

        let newItems = AddAppotionmentItems.call({
          amountValue,
          typeId,
          mainPayment,
          text,
          itemsInfo,
        });
        const newCaseTable = caseTableData.map((tableData: any) => {
          if (tableData.caseNo == mainPayment.caseNo) {
            tableData.items.push(newItems);
            tableData.total = sumBy(existingCase[0].items, PropertyKeys.AMOUNT);
            tableData.totalBureauAmount = sumBy(
              existingCase[0].items,
              TotalAmountByText.TOTAL_BUREAU_AMOUNT_BY_TEXT
            );
          }
          return tableData;
        });
        return newCaseTable;
      } else {
        let obj = AddNewApportionment.call({
          amountValue,
          typeId,
          mainPayment,
          text,
        });
        newCaseTableData.push(obj);
        return newCaseTableData;
      }
    } else if (
      !isExistingRecord(caseTableData, text) &&
      !isCaseNoEmpty(caseTableData)
    ) {
      let itemsInfo = { accountNo };
      let obj = AddNewApportionment.call({
        amountValue,
        typeId,
        mainPayment,
        text,
        itemsInfo,
      });
      newCaseTableData.push(obj);
      return newCaseTableData;
    } else {
      let newCaseTableDataWithExistingData =
        caseTableData != undefined &&
        caseTableData.map((item: any, index: number) => {
          const [updatedDataRow, isExistingDataRecord] = getExistingTextRecords(
            item,
            text
          );
          if (isExistingDataRecord) {
            updatedDataRow?.map((exceededItem: any, i: number) => {
              childrenItems = exceededItem.children.filter(
                (ch: any) => ch.itemTypeId == typeId
              );
              exceededItem.children.map((ex: any, exIndex: number) => {
                if (childrenItems.length > 0 && ex.itemTypeId == typeId) {
                  ex.amount = amountValue;
                  ex.exceedAmount =
                    typeId == ItemTypeIds.EXCEEDED_AMOUNT
                      ? amountValue
                      : ex.exceedAmount;
                  ex.bureauAmount =
                    typeId == ItemTypeIds.RETURN_FEE ||
                    typeId == ItemTypeIds.ROUNDING_OFF
                      ? amountValue
                      : ex.bureauAmount;
                  ex.debtorAmount =
                    typeId == ItemTypeIds.RETURN_TO_DEBTOR
                      ? amountValue
                      : ex.debtorAmount;
                  exceededItem.amount = sumBy(
                    exceededItem.children,
                    PropertyKeys.AMOUNT
                  );
                  exceededItem.totalBureauAmountByText =
                    typeId == ItemTypeIds.RETURN_FEE ||
                    typeId == ItemTypeIds.ROUNDING_OFF
                      ? amountValue
                      : exceededItem.totalBureauAmountByText;
                  exceededItem.totalDebtorAmountByText =
                    typeId == ItemTypeIds.RETURN_TO_DEBTOR
                      ? amountValue
                      : exceededItem.totalDebtorAmountByText;
                  item.total = sumBy(item.items, PropertyKeys.AMOUNT);
                  item.totalBureauAmount = sumBy(
                    item.items,
                    TotalAmountByText.TOTAL_BUREAU_AMOUNT_BY_TEXT
                  );
                  item.totalDebtorAmount =
                    typeId == ItemTypeIds.RETURN_TO_DEBTOR
                      ? amountValue
                      : item.totalDebtorAmount;
                  ex.vatAmount = amountValue != 0 ? ex.vatAmount : 0;
                  ex.vatBasis = amountValue != 0 ? ex.vatBasis : 0;
                  ex.vatDeducted = amountValue != 0 ? ex.vatDeducted : 0;
                  ex.vatPaidByDebtor =
                    amountValue != 0 ? ex.vatPaidByDebtor : 0;
                  (ex.modifiedDate = moment()),
                    (ex.modifiedUser = mainPayment?.currentUser?.upn);
                } else if (childrenItems.length == 0) {
                  let itemsParentIndex = exceededItem.children[0]?.parentIndex;
                  let itemsLength = exceededItem.children?.length;
                  let itemsInfo = { itemsLength, itemsParentIndex, accountNo };
                  let newChild = AddApportionmentReturnToDebtor.call({
                    amountValue,
                    typeId,
                    mainPayment,
                    text,
                    itemsInfo,
                  });
                  exceededItem.children.push(newChild);
                }
              });
              (exceededItem.totalVatAmountByText = sumBy(
                exceededItem.children,
                VATAmountInfo.VAT_AMOUNT
              )),
                (exceededItem.totalVatBasisByText = sumBy(
                  exceededItem.children,
                  VATAmountInfo.VAT_BASIS
                )),
                (exceededItem.totalVatDeductedByText = sumBy(
                  exceededItem.children,
                  VATAmountInfo.VAT_DEDUCTED
                )),
                (exceededItem.totalVatPaidByDebtorByText = sumBy(
                  exceededItem.children,
                  VATAmountInfo.VAT_PAID_BY_DEBTOR
                ));
              item.totalVatAmount = sumBy(
                item.items,
                VATAmountInfo.TOTAL_VAT_AMOUNT_BY_TEXT
              );
              item.totalVatBasis = sumBy(
                item.items,
                VATAmountInfo.TOTAL_VAT_BASIS_BY_TEXT
              );
              item.totalVatDeducted = sumBy(
                item.items,
                VATAmountInfo.TOTAL_VAT_DEDUCTED_BY_TEXT
              );
              item.totalVatPaidByDebtor = sumBy(
                item.items,
                VATAmountInfo.TOTAL_VAT_PAID_BY_DEBTOR_BY_TEXT
              );
            });
          } else {
            if (item.caseNo == "" && typeId !== ItemTypeIds.ROUNDING_OFF) {
              let itemsParentIndex = item?.items[0]?.children[0]?.parentIndex;
              let itemsLength = item?.items?.length;
              let itemsInfo = { itemsLength, itemsParentIndex };
              let newItems = AddAppotionmentItems.call({
                amountValue,
                typeId,
                mainPayment,
                text,
                itemsInfo,
              });
              item.items.push(newItems);
            }
          }
          return item;
        });

      return amountValue != 0
        ? newCaseTableDataWithExistingData
        : getRemoveIndex(newCaseTableDataWithExistingData, typeId);
    }
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * @description find row keys that are expanded by default
 * @param {Array<any>} caseTableData - current case table data
 * @returns {Array<any>} - array of first case table row keys
 */
export const getExpandedActiveRowKeys = (data: any) => {
  let activeKeys: any = [];
  try {
    data?.items?.map((item: any) => {
      activeKeys.push(`${item.key}`);
    });
    return activeKeys;
  } catch (error) {
    console.error(error);
    return [];
  }
};

/**
 * @description validate transaction balance is exceed in rows
 * @param {Array<any>} caseTableData - current case table data
 * @returns {boolean} - if case table row amount exceeded transaction balance return true otherwise false
 */
export const isExceededRecordBalance = (data: any) => {
  let isExcceededError = false;
  try {
    data?.map((item: any) => {
      item.items.map((parent: any) => {
        parent.children.map((child: any) => {
          if (child.transactionBalance < 0) {
            isExcceededError = child.transactionBalance < 0;
          }
        });
      });
    });
    return isExcceededError;
  } catch (error) {
    console.error(error);
    return false;
  }
};

/**
 * @description find apportionment form is updated or not
 * @param {Object<any>} values - current form values
 * @param {Array<any>} apportionments - initial apportionment details
 * @returns {boolean} - if initial apportionment values are changed return true otherwise false
 */
export const isDirtyApportionment = (values: any, apportionments: any) => {
  try {
    return (
      JSON.stringify(values) !== JSON.stringify(apportionments.initialData)
    );
  } catch (error) {
    console.log(error);
    return true;
  }
};

/**
 * @description find when Confirm button wants to disabled
 * @param {Object<any>} values - current form values
 * @param {Array<any>} paymentApportionments - initial apportionment details
 * @returns {boolean} - when confirm button wants to disabled
 */
export const isDisabled = (values: any, paymentApportionments: any) => {
  const { remainingAmount, returnFeeVat, returnToDebtor, caseTableData } =
    values;
  try {
    return (
      remainingAmount !== 0 ||
      (returnFeeVat?.amount !== 0 && returnToDebtor == 0) ||
      isExceededRecordBalance(caseTableData)
    );
  } catch (error) {
    console.log(error);
    return true;
  }
};

/**
 * @description find when bureau amount field wants to disabled
 * @param {Object<any>} values - current form values
 * @param {Object<any>} record - updated case table record
 * @param {Array<any>} item - updated case table records parent item
 * @returns {boolean} - when bureau amount field wants to disabled
 */
export const isDisableBureauAmount = (
  values: any,
  record: any,
  item: any
): boolean => {
  const { isRemitted } = values;
  try {
    if (record?.isProvision) {
      const creditorAmount =
        values.caseTableData[record?.parentIndex].items[record?.itemIndex]
          .children[record?.childIndex].creditorAmount;
      return creditorAmount > 0;
    } else {
      return (
        isRemitted ||
        record?.itemTypeId == 48 ||
        item.items[record?.itemIndex].children
          .filter((child: any) => child.isProvision)
          .filter((i: any) => i.childIndex - 1 == record?.childIndex).length >
          0 ||
        (record.transactionGroupId === TransactionGroupId.COURT_FEE &&
          !record.siInvoiceAdded)
      );
    }
  } catch (error) {
    console.log(error);
    return true;
  }
};

/**
 * @description find when Creditor amount field wants to disabled
 * @param {Object<any>} values - current form values
 * @param {Object<any>} record - updated case table record
 * @returns {boolean} - when Creditor amount field wants to disabled
 */
export const isDisableCreditorAmount = (values: any, record: any): boolean => {
  try {
    const { isRemitted } = values;
    const { itemTypeId, transactionGroupId, siInvoiceAdded, isProvision } =
      record ?? {};
    if (isProvision) {
      const bureauAmount =
        values.caseTableData[record?.parentIndex].items[record?.itemIndex]
          .children[record?.childIndex].bureauAmount;
      return bureauAmount > 0;
    } else {
      return (
        isRemitted ||
        itemTypeId == 48 ||
        (transactionGroupId === TransactionGroupId.COURT_FEE && !siInvoiceAdded)
      );
    }
  } catch (error) {
    console.log(error);
    return true;
  }
};

/**
 * @description find when Other amount field wants to disabled
 * @param {Object<any>} values - current form values
 * @param {Object<any>} record - updated case table record
 * @returns {boolean} - when Other amount field wants to disabled
 */
export const isDisableOtherAmount = (values: any, record: any): boolean => {
  const { isRemitted } = values;
  const { itemTypeId, transactionGroupId, siInvoiceAdded } = record;
  try {
    return (
      isRemitted ||
      itemTypeId == 48 ||
      transactionGroupId !== TransactionGroupId.COURT_FEE ||
      siInvoiceAdded
    );
  } catch (error) {
    console.log(error);
    return true;
  }
};
