import { IRegPaymentsActions } from "../../interfaces/RegisterPayments";
import { RegisterPayments } from "../../constants/Actions";

export const regPayments: IRegPaymentsActions = {
  getPaymentTypes: (data) => ({
    type: RegisterPayments.GET_PAYMENT_TYPE,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getPaymentTypesSuccess: (data) => ({
    type: RegisterPayments.GET_PAYMENT_TYPE_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getPaymentTypesFail: (error) => ({
    type: RegisterPayments.GET_PAYMENT_TYPE_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  getPaymentMethods: (data) => ({
    type: RegisterPayments.GET_PAYMENT_METHOD,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getPaymentMethodsSuccess: (data) => ({
    type: RegisterPayments.GET_PAYMENT_METHOD_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getPaymentMethodsFail: (error) => ({
    type: RegisterPayments.GET_PAYMENT_METHOD_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  getVoucherNo: (data) => ({
    type: RegisterPayments.GET_VOUCHER_NO,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getVoucherNoSuccess: (data) => ({
    type: RegisterPayments.GET_VOUCHER_NO_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  getVoucherNoFail: (error) => ({
    type: RegisterPayments.GET_VOUCHER_NO_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  getValidateCaseId: (data) => ({
    type: RegisterPayments.GET_VALIDATE_CASE_ID,
    payload: {
      data,
      isLoading: true,
    },
  }),
  getValidateCaseIdSuccess: (data,values,addedAmount) => ({
    type: RegisterPayments.GET_VALIDATE_CASE_ID_SUCCESS,
    payload: {
      data,
      values,
      addedAmount,
      isLoading: false,
    },
  }),
  getValidateCaseIdFail: (error) => ({
    type: RegisterPayments.GET_VALIDATE_CASE_ID_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  saveRegisterPayment: (data) => ({
    type: RegisterPayments.SAVE_REGISTER_PAYMENT,
    payload: {
      data,
      isLoading: true,
    },
  }),
  saveRegisterPaymentsSuccess: (data) => ({
    type: RegisterPayments.SAVE_REGISTER_PAYMENT_SUCCESS,
    payload: {
      data,
      isLoading: false,
    },
  }),
  saveRegisterPaymentsFail: (error) => ({
    type: RegisterPayments.SAVE_REGISTER_PAYMENT_FAIL,
    payload: {
      error,
      isLoading: false,
    },
  }),
  clearValidateCaseIdData: (data: { values?: any; addedAmount: any }) => ({
    type: RegisterPayments.CLEAR_VALIDATE_CASE_ID_DATA,
    payload: data,
  }),
};
