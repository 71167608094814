import React from 'react';
import { useField, Field } from "formik";
import * as Utility from "us.common/components/antd/utility";
import { useDispatch, useSelector } from "react-redux";
import { articles } from 'us.collection.economy/actions';
import { useTranslation } from 'react-i18next';
import Common from "us.common";

const { $Input, $FormItem } = Common.Components;

export default React.forwardRef(({
  name, 
  label, 
  formItem, 
  required=false, 
  action, 
  articleNo, 
  currentValues, 
  tabIndex, 
  autoFocus, 
  disabled,
  ...rest 
}: any, ref: any) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const isDuplicateArticleNoInStore = useSelector((state: any) => state.articles.isDuplicateArticleNo);

  const [field, meta, helpers] = useField({ name });
  return (
    <$FormItem
      label={label}
      {...formItem}
      help={isDuplicateArticleNoInStore && t("US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_ARTICLE_NO")}
      validateStatus={Utility.validateStatus(t("US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_ARTICLE_NO"), isDuplicateArticleNoInStore)}
      required
    >
      <$Input
        {...field}
        tabIndex={tabIndex}
        onBlur={(e: any) => {
          let value = e.target.value
          if (articleNo?.toUpperCase() !== value.trim().toUpperCase()) {
            dispatch(articles.checkDuplicateArticleNo(value));
            helpers.setError(isDuplicateArticleNoInStore ? t("US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_ARTICLE_NO") : undefined)
          }
          field.onBlur(e);
        }}
        onChange={
          typeof rest?.onChange == "function"
            ? rest?.onChange
            : (v: any) => {
              dispatch(articles.resetDuplicateArticleNoStatus());
              helpers?.setValue(v.target.value);
            }
        }
        autoFocus={autoFocus}
        ref={ref}
        disabled={disabled}
      />
    </$FormItem>
  );
});
