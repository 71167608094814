import _ from "lodash";
import {
  IColumnSearchType,
  RemitActionType,
} from "us.collection.economy/components/RemitHistory/Interfaces";
import {
  ColumnSearchType,
  RemitAction,
  RemitHistoryTableColumn,
} from "us.collection.economy/components/RemitHistory/Constants";
import { RemitDetails } from "us.collection.economy/interfaces";

/**
 * @description - Get dynamic width property value for each column by key
 * @param - Column key
 * @returns - Width (px)
 */
export const getColumnWidth = (key: RemitHistoryTableColumn): string => {
  switch (key) {
    case RemitHistoryTableColumn.MENU:
      return "40px";
    case RemitHistoryTableColumn.VALIDATION_STATUS:
    case RemitHistoryTableColumn.FILE_DATE:
    case RemitHistoryTableColumn.REMIT_VOUCHER:
      return "120px";
    case RemitHistoryTableColumn.SOURCE_ACCOUNT:
      return "140px";
    case RemitHistoryTableColumn.REMIT_PERIOD:
    case RemitHistoryTableColumn.LAST_DOWNLOADED_USER:
      return "180px";
    default:
      return "160px";
  }
};

/**
 * @description - Get type of column (`IColumnSearchType`) by column id
 * @param {RemitHistoryTableColumn} key - Column key
 * @returns {IColumnSearchType} column type
 */
export const getColumnType = (
  key: RemitHistoryTableColumn
): IColumnSearchType => {
  switch (key) {
    case RemitHistoryTableColumn.FILE_DATE:
      return ColumnSearchType.DATE;
    case RemitHistoryTableColumn.BUREAU_AMOUNT:
    case RemitHistoryTableColumn.CREDITOR_AMOUNT:
    case RemitHistoryTableColumn.DEBTOR_AMOUNT:
    case RemitHistoryTableColumn.TOTAL_AMOUNT:
      return ColumnSearchType.AMOUNT;
    case RemitHistoryTableColumn.SOURCE_ACCOUNT:
      return ColumnSearchType.ACCOUNT_NO;
    default:
      return ColumnSearchType.STRING;
  }
};

/**
 * @description Check whether any column filters include or not
 * @param current filters Array
 * @returns boolean `true` if has any filters
 */
export const hasFilters = (filters: Array<any>): boolean => {
  const filterItems = [];
  filters.map(({ value }: { dataIndex: string; value: any }) => {
    if (value) {
      filterItems.push(value);
    }
  });
  return filterItems.length > 0;
};

/**
 * @description Get available actions for the remit history record
 * @param remitDetails - Remit record
 * @returns actions
 */
export const getRemitActions = (
  remitDetails: RemitDetails
): RemitActionType[] => {
  const actions: RemitActionType[] = [RemitAction.VIEW_LEDGER_DASHBOARD];
  try {
    if (remitDetails.fileName) {
      actions.push(RemitAction.DOWNLOAD);
    }
    if (!remitDetails.isDeleted) {
      actions.push(RemitAction.REVERSE);
    }
    return actions;
  } catch (error) {
    return actions;
  }
};
