import moment from "moment";
import {
  DATE_FORMAT_FOR_SERVER,
  PeriodType,
} from "us.collection.economy/constants/Payments";
import { IGetCases } from "us.collection.economy/interfaces";
import { MatchHelper } from "../Functions";
import {
  IMatchPayment,
  ISearchFilters,
  MatchRemainingAction,
} from "../interface";
import { formatAccountNo } from "us.collection.economy/functions";

export function GetPayments(this: any): ISearchFilters {
  const [startDate, endDate] = this.paymentDateRange;
  return {
    periodType: this.periodType,
    paymentStates:
      this.periodType === PeriodType.ALL
        ? this.paymentStates.length > 0
          ? this.paymentStates
          : []
        : this.paymentStates,
    accountNo: this.accountNo,
    paymentId:
      this.periodType === PeriodType.ALL
        ? this.paymentId
          ? Number(this.paymentId)
          : 0
        : 0,
    offset: this.offset,
    limit: this.limit,
    debtorOtherCasesStatus: this.debtorOtherCasesStatus,
    startDate: moment(startDate).startOf("day").format(DATE_FORMAT_FOR_SERVER),
    endDate: moment(endDate).endOf("day").format(DATE_FORMAT_FOR_SERVER),
    dayVal: this.dayVal,
  };
}

export function MatchPayment(this: any): IMatchPayment {
  const isReturnExceed =
    this.remainingAction === MatchRemainingAction.RETURN_EXCEEDED;
  return {
    paymentId: this.paymentId,
    paymentArItemNo: this.arItemNo,
    itemType: this.itemType,
    matchedCases: MatchHelper.getMatchedCases(this.caseTableData),
    paymentStatus: this.paymentStatus,
    remainingAmount: isReturnExceed ? this.returnAmount : this.remainingBalance,
    returnFee: isReturnExceed && this.remainingBalance > 0 ? this.returnFee : 0,
    remainingAction: this.remainingAction,
    accountNo: formatAccountNo(this.debtorAccountNo ?? this.accountNo),
    voucherDate: moment(this.voucherDate).format(DATE_FORMAT_FOR_SERVER),
  };
}

export function GetCases(this: any): IGetCases {
  return {
    creditorId: this.creditorId,
    debtorId: this.debtorId,
    isIncludedOrganization: this.isInDepartments ?? false,
    paymentId: this.paymentId,
  };
}
