import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { PlusOutlined } from "us.icons";
import { AddEditClientAccount } from "./Componets";
import { $Drawer } from "us.common/components";
import {
  IClientAccount,
  IClientAccountAction,
  IClientAccountInfo,
} from "us.collection.economy/components/ClientAccounts/Interfaces";
import {
  INITIAL_DRAWER_DATA,
  ClientAccountsAction,
} from "us.collection.economy/components/ClientAccounts/Constants";
import { ClientAccountsTable } from "us.collection.economy/components/ClientAccounts/Componets/ClientAccountsTable";
import * as Actions from "us.collection.economy/actions";
import { IRootState } from "us.collection/interfaces";
import { handleGoBack } from "us.helper/utility";
import { getDrawerTitle } from "./Functions";

const { $Affix, $PageHeader, $Divider, $Button, $Skeleton } = Common.Components;

/**
 * @description - Client Accounts
 * @link https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2835709953/Client+Accounts
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 20/06/2022
 */
const ClientAccount: React.FC<PropsFromRedux & IClientAccount> = (props) => {
  const { t } = useTranslation();
  const {
    getClientAccounts,
    isLoadingClientAccounts,
    deleteClientAccount,
    resetSaveUpdateData,
  } = props;
  const history = useHistory();
  const { replace } = history;
  const [drawer, setDrawer] = useState(INITIAL_DRAWER_DATA);

  useEffect(() => {
    getClientAccounts && getClientAccounts({});
  }, []);

  const onClose = () => {
    resetSaveUpdateData && resetSaveUpdateData({});
    setDrawer(INITIAL_DRAWER_DATA);
    replace({
      pathname: "/client-accounts",
      search: ``,
    });
  };

  /**
   * @description - Update the browser tab url according to given id value
   * @param {number} accountId - Setting id if available
   */
  const updateUrl = (action: IClientAccountAction, accountId?: number) => {
    // update the URL params
    replace({
      pathname: "/client-accounts",
      search: `?action=${action}&accountId=${accountId}`,
    });
  };

  /**
   * @description Handle drawer by Vat Code action
   * @param {ClientAccountsAction} type - Vat Code action type
   * @param {number} id - Vat Code id
   */
  const handleClientAccountAction = (
    type: ClientAccountsAction,
    id: number = -1
  ) => {
    updateUrl(type, id);
    setDrawer({
      ...drawer,
      title: t(getDrawerTitle(type)),
      visible: true,
    });
  };

  /**
   * @description handle client account item menu actions
   * @param action - action of clicked menu icon
   * @param record - selected court item for action
   */
  const onHandleClientAccountAction = (
    action: IClientAccountAction,
    record: IClientAccountInfo
  ) => {
    const { accountId } = record;
    switch (action) {
      case ClientAccountsAction.EDIT:
        handleClientAccountAction(action, accountId);
        break;
      case ClientAccountsAction.DELETE:
        deleteClientAccount && deleteClientAccount(accountId);
        break;
      default:
        break;
    }
  };

  return (
    <Formik initialValues={{}} enableReinitialize onSubmit={() => {}}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNTS"
                    )}
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <$Button
                    id="add-new-account"
                    type="default"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() =>
                      handleClientAccountAction(ClientAccountsAction.ADD)
                    }
                  >
                    {t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.NEW_BANK_ACCOUNT"
                    )}
                  </$Button>
                </div>
              </div>
            </$Affix>
            <$Skeleton
              loading={isLoadingClientAccounts}
              active
              paragraph={{ rows: 2 }}
            >
              <div>
                <ClientAccountsTable
                  onCallAction={onHandleClientAccountAction}
                />
              </div>
            </$Skeleton>
          </div>

          <$Drawer
            title={drawer.title}
            width={"750"}
            visible={drawer.visible}
            onClose={() => onClose()}
            destroyOnClose
          >
            <AddEditClientAccount onCancel={onClose} />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { clientAccounts } = state;

  const { isLoadingClientAccounts } = clientAccounts;

  return { isLoadingClientAccounts };
};

const { accounts, bankAccount, initDetails } = Actions.clientAccounts;
const { get } = accounts;
const { deleteById } = bankAccount;
const { reset } = initDetails;

const mapDispatchToProps = {
  getClientAccounts: get,
  deleteClientAccount: deleteById,
  resetSaveUpdateData: reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ClientAccount);
