import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    creditors: Yup.array()
      .required()
      .test("", "", function (this: any, val: any) {
        return Array.isArray(val) && val.length > 0;
      }),
    remitAccount: Yup.number()
      .required()
      .typeError("US.COLLECTION.VALIDATIONS:REQUIRED.INVALID_ACCOUNT_NO"),
  });
};
