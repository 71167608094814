// export const initialState = {
//     paymentAgreementAmounts: {},
//     paymentAgreementDetails:{},
//     isFetchingAmounts:false,
//     isFetchingDetails:false,
//     interests:{}
// }

export const initialState = {
    amounts: {
        data: {},
        isLoading: false
    },
    details: {
        data: {},
        isLoading: false
    },
    interests: {
        data: {},
        isLoading: false
    },
    values:{}
}