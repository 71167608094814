import React, { useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import Common from "us.common";
import { PlusOutlined, SearchOutlined } from "us.icons";
import {
  ProfileDetails,
  ProfilesTable,
  ProvisionProfileAction,
} from "./Components";
import { IRootState } from "us.collection/interfaces";
import * as Actions from "us.collection.economy/actions";
import {
  ProvisionProfile,
  IProvisionProfiles,
} from "us.collection.economy/interfaces";
import {
  DEFAULT_DRAWER_STATE,
  DrawerType,
  INITIAL_FORM_VALUES,
} from "./Constants";
import { ProvisionProfileSearchStatus } from "us.collection.economy/constants";
import { createSearchParams } from "us.common/functions";
import { SearchProvisionProfiles } from "./Repository";
import { handleGoBack } from "us.helper/utility";

const {
  $Affix,
  $Drawer,
  $PageHeader,
  $Divider,
  $Button,
  $Tooltip,
  $Select,
  $Input,
} = Common.Components;

/**
 * @description - Provision profiles table component to show all provision profiles
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2985361423/Provision+Profiles+UI+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 23/05/2022
 */
const ProvisionProfileHome: React.FC<IProvisionProfiles & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { replace } = history;

  const {
    drawerInfo,
    profiles,
    searchProfiles,
    deleteProvisionProfile,
    manageDrawer,
  } = props;

  useEffect(() => {
    updateUrl({});
    const params = SearchProvisionProfiles.call(INITIAL_FORM_VALUES);
    searchProfiles && searchProfiles(params);
  }, []);

  /**
   * @description - Close the drawer
   */
  const closeDrawer = () => {
    updateUrl({});
    manageDrawer && manageDrawer(DEFAULT_DRAWER_STATE);
  };

  /**
   * @description Open the drawer by type
   * @param {DrawerType} type - Type of the drawer
   */
  const openDrawer = (type: DrawerType, profileId?: number) => {
    // decide the suitable title for the drawer
    const title =
      type === DrawerType.EDIT_PROFILE
        ? t("US.COLLECTION.ECONOMY:PROVISION_PROFILES.EDIT_PROVISION_PROFILE")
        : t("US.COLLECTION.ECONOMY:PROVISION_PROFILES.NEW_PROVISION_PROFILE");
    // update the url that related to the selected provision profile or current action
    updateUrl({ profileId, type });
    // change drawer state
    manageDrawer &&
      manageDrawer({
        type,
        title,
      });
  };

  /**
   * @description - Update the browser tab url according to given id value
   * @param {number} profileId - Provision profile id if available
   */
  const updateUrl = ({
    profileId,
    profileName = "",
    type,
    status = ProvisionProfileSearchStatus.ALL,
  }: {
    profileId?: number;
    type?: ProvisionProfileAction | DrawerType;
    status?: ProvisionProfileSearchStatus;
    profileName?: string;
  }) => {
    const search = createSearchParams({ profileId, profileName, type, status });
    // update the URL params
    replace({
      pathname: "/provision-profiles",
      search,
    });
  };

  /**
   * @description handle court item menu actions
   * @param action - action of clicked menu icon
   * @param record - selected court item for action
   */
  const onHandleTableAction = (
    action: ProvisionProfileAction,
    record: ProvisionProfile,
    values: { status: ProvisionProfileSearchStatus; profileName: string }
  ) => {
    const { profileId, isActive } = record;
    switch (action) {
      case ProvisionProfileAction.EDIT:
        openDrawer(DrawerType.EDIT_PROFILE, profileId);
        break;
      case ProvisionProfileAction.ACTIVATE_OR_DEACTIVATE:
        const searchParams = SearchProvisionProfiles.call(values);
        deleteProvisionProfile &&
          deleteProvisionProfile({ profileId, searchParams, isActive });
        break;
      case ProvisionProfileAction.COPY:
        openDrawer(DrawerType.COPY_PROFILE, profileId);
        break;
      default:
        break;
    }
  };

  const onSearch = (values: any) => {
    updateUrl({ status: values.status, profileName: values.profileName });
    const searchParams = SearchProvisionProfiles.call(values);
    searchProfiles && searchProfiles(searchParams);
  };

  return (
    <Formik
      initialValues={INITIAL_FORM_VALUES}
      enableReinitialize
      onSubmit={onSearch}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div
                  className="d-flex align-items-center flex-nowrap justify-content-between"
                  data-testid="default-view"
                >
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      onBack={()=> handleGoBack(history)}
                      title={t(
                        "US.COLLECTION.ECONOMY:PROVISION_PROFILES.PROVISION_PROFILES"
                      )}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <$Button
                      id="btnAddNew"
                      type="default"
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => openDrawer(DrawerType.NEW_PROFILE)}
                    >
                      {t(
                        "US.COLLECTION.ECONOMY:PROVISION_PROFILES.NEW_PROVISION_PROFILE"
                      )}
                    </$Button>
                    <$Divider className="bui-devider" type="vertical" />
                    <div className="d-flex ant-form ant-form-inline">
                      <$Tooltip
                        title={t(
                          "US.COLLECTION.ECONOMY:PROVISION_PROFILES.PROFILE_STATUS"
                        )}
                        placement="top"
                      >
                        
                        <$Select
                          dataTestid={"select-status"}
                          name="status"
                          formitem={{}}
                          size="small"
                          style={{ width: 100 }}
                          options={[
                            {
                              label: t("COMMON.ALL"),
                              value: ProvisionProfileSearchStatus.ALL,
                            },
                            {
                              label: t("COMMON.ACTIVE"),
                              value: ProvisionProfileSearchStatus.ACTIVE,
                            },
                            {
                              label: t("COMMON.INACTIVE"),
                              value: ProvisionProfileSearchStatus.INACTIVE,
                            },
                          ]}
                          defaultActiveFirstOption
                          showSearch={false}
                          allOption={false}
                          loading={profiles.isLoading}
                          disabled={profiles.isLoading}
                          onChange={(status: string) =>
                            onSearch({ ...values, status })
                          }
                        />
                      </$Tooltip>

                      <$Input
                        name="profileName"
                        size="small"
                        
                        type="text"
                        disabled={profiles.isLoading}
                        placeholder={t(
                          "US.COLLECTION.ECONOMY:PROVISION_PROFILES.NAME"
                        )}
                        onPressEnter={(e: any) =>
                          onSearch({ ...values, profileName: e.target.value })
                        }
                        value={values.profileName}
                        dataTestid={"input-profile-name"}
                        allowClear={true}
                        onChange={(e: any) => {
                          const profileName = e.target.value;
                          restProps.setFieldValue("profileName", profileName);
                          if (!profileName) {
                            replace({
                              pathname: `/provision-profiles`,
                            });
                            onSearch({
                              ...values,
                              profileName: e.target.value,
                            });
                          }
                        }}
                      />
                     
                    </div>
                    <$Button
                      id={"btnSearch"}
                      onClick={handleSubmit}
                      
                      type="default"
                      size="small"
                      icon={<SearchOutlined />}
                      loading={profiles.isLoading}
                      disabled={profiles.isLoading}
                    />
                  </div>
                  <div>
                    
                  </div>
                </div>
              </div>
            </$Affix>
            <div>
              <ProfilesTable
                onCallAction={(
                  action: ProvisionProfileAction,
                  record: ProvisionProfile
                ) => onHandleTableAction(action, record, values)}
              />
            </div>
          </div>

          <$Drawer
            title={drawerInfo.title}
            width={"60%"}
            visible={drawerInfo.visible}
            onClose={closeDrawer}
            destroyOnClose={true}
            maskClosable={false}
          >
            {[
              DrawerType.NEW_PROFILE,
              DrawerType.COPY_PROFILE,
              DrawerType.EDIT_PROFILE,
            ].includes(drawerInfo.type) && (
              <ProfileDetails formValues={values} onCancel={closeDrawer} />
            )}
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { provisionProfiles } = state;
  const {
    profiles,
    profileDetails,
    newProfile,
    profileNameValidation,
    deleteProfile,
    drawerInfo,
  } = provisionProfiles;

  return {
    profiles,
    profileDetails,
    newProfile,
    profileNameValidation,
    deleteProfile,
    drawerInfo,
  };
};

const { provisionProfiles } = Actions;

const mapDispatchToProps = {
  searchProfiles: provisionProfiles.profiles.search,
  resetProfileDetails: provisionProfiles.profileDetails.reset,
  deleteProvisionProfile: provisionProfiles.profiles.delete,
  manageDrawer: provisionProfiles.profileDetails.manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ProvisionProfileHome);
