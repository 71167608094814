import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    vatCode: Yup.number().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.VAT_CODE_IS_REQUIRED"
    ),
    name: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.VAT_CODE_NAME_IS_REQUIRED"
    ),
    rate: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.RATE_IS_REQUIRED"
    ),
    startDate: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.START_DATE_IS_REQUIRED"
    ),
  });
};
