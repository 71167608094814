import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    values: Yup.array()
      .of(
        Yup.object().shape({
          accountId: Yup.number()
            .nullable()
            .required(
              "US.COLLECTION.ECONOMY:VALIDATIONS.LEDGER_ACCOUNT_IS_REQUIRED"
            ),
        })
      )
      .test(
        "total",
        "total validation error",
        function (this: any) {
          const { values } = this.parent ?? {};
          const totalCreditAmount = values.reduce(
            (total: number, entry: any) => total + (entry.creditorAmount || 0),
            0
          );
          const totalDebitAmount = values.reduce(
            (total: number, entry: any) => total + (entry.debitAmount || 0),
            0
          );
          return (
            totalCreditAmount != 0 &&
            totalDebitAmount != 0 &&
            totalCreditAmount == totalDebitAmount
          );
        }
      ),
  });
};
