import * as Yup from "yup";
import moment from "moment";
interface EditSchema extends Yup.MixedSchema {
  paymentAmount: number;
  voucherDate: string;
  caseNo: string;
}

export default () => {
  return Yup.object<EditSchema>().shape({
    paymentAmount: Yup.number()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.AMOUNT_IS_REQUIRED")
      .typeError("US.COLLECTION.VALIDATIONS:INVALID.INVALID_AMOUNT")
      .moreThan(
        0,
        "US.COLLECTION.VALIDATIONS:REQUIRED.AMOUNT_MUST_BE_POSITIVE"
      ),
    voucherDate: Yup.string()
      .required("US.COLLECTION.VALIDATIONS:REQUIRED.VOUCHER_DATE_IS_REQUIRED")
      .typeError("US.COLLECTION.VALIDATIONS:INVALID.INVALID_VOUCHER_DATE")
      .test(
        "voucherDate",
        "US.COLLECTION.VALIDATIONS:REQUIRED.CANNOT_SELECT_FUTURE_DATE",
        (val: any) => {
          return !moment(val).isSameOrAfter(moment());
        }
      ), 
    caseNo: Yup.number().typeError(
      "US.COLLECTION.VALIDATIONS:INVALID.INVALID_CASE_NO"
    ),
  });
};
