import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import queryString from "query-string";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";

import { REMIT_HISTORY_INITIAL_FORM_VALUES } from "us.collection.economy/constants";
import { IRootState } from "us.collection/interfaces";
import * as Components from "us.common/components";
import * as Actions from "us.collection.economy/actions";
import { SearchOutlined } from "us.icons";
import { IRemitHistory, RemitDetails } from "us.collection.economy/interfaces";
import { RemitAction } from "./Constants";
import {
  SearchRemitHistory,
  ReverseRemit,
  DownloadRemitFile,
} from "./Repository";
import { RemitHistoryTable, LedgerDashboard } from "./Components";
import { handleGoBack } from "us.helper";

const {
  $Affix,
  $Skeleton,
  $PageHeader,
  $Tooltip,
  $Button,
  $Divider,
  $Select,
  $RangePicker,
  $Input,
} = Components;

/**
 * @description - Remit History to view previous remit records, navigate from the main menu
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2815000877/Remit+History+-+UI+Design+and+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 06/01/2022
 */
const RemitHistory: React.FC<PropsFromRedux & IRemitHistory> = (props) => {
  const {
    isLoadingRemitHistory,
    bankAccounts,
    isLoadingBankAccounts,
    currentDateFormat,
    initRemitHistory,
    searchRemits,
    downloadRemitFile,
    reverseRemit,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { push, replace } = history;
  const { search } = useLocation();
  const params = queryString.parse(search);

  const [dates, setDates] = useState<Array<moment.Moment>>([]);
  const [formValues, setFormValues] = useState<any>(
    REMIT_HISTORY_INITIAL_FORM_VALUES
  );
  const [ledgerDashboard, setLedgerDashboard] = useState<any>({
    visible: false,
    journalId: "",
  });

  useEffect(() => {
    const requestParams = SearchRemitHistory.call({
      ...REMIT_HISTORY_INITIAL_FORM_VALUES,
      journalIdText: params?.journalId,
    });
    initRemitHistory && initRemitHistory(requestParams);
  }, []);

  /**
   * @description set dates that should disabled for selecting more than 31 days
   * @param {any} current - Current date of the calender
   * @returns {boolean}
   */
  const disabledDate = (current: any): boolean => {
    try {
      // disable future dates
      if (current.isAfter(moment())) return true;
      // don't disable if no dates selected
      if (!dates || dates.length === 0) {
        return false;
      }
      // if first date selected then
      const tooLate = dates[0] && current.diff(dates[0], "days") >= 31;
      // if last date selected without first date then
      const tooEarly =
        !dates[0] && dates[1] && dates[1].diff(current, "days") >= 31;
      return tooLate || tooEarly;
    } catch (error) {
      return false;
    }
  };

  /**
   * @description handle remit item menu actions
   * @param {RemitAction} action - Called action from the remit record item menu
   * @param {RemitDetails} remitDetails - The remit record to download related remit file
   * @param {any} values - Form values
   */
  const onHandleRemitAction = (
    action: RemitAction,
    remitDetails: RemitDetails,
    values: any
  ) => {
    const { journalId } = remitDetails;
    switch (action) {
      case RemitAction.RE_CREATE:
        // re create remit file action
        break;
      case RemitAction.VIEW_LEDGER_DASHBOARD:
        // view ledger dashboard navigation
        setLedgerDashboard({ visible: true, journalId });
        break;
      case RemitAction.REVERSE:
        // reverse remit action
        handleReverseRemit(values, remitDetails);
        break;
      case RemitAction.DOWNLOAD:
        // download remit file
        onDownloadRemitFile(values, remitDetails);
        break;
      default:
        break;
    }
  };

  /**
   * @description handle remit file download
   * @param {any} values - Form values
   * @param {RemitDetails} remitDetails - The remit record to download related remit file
   */
  const onDownloadRemitFile = (values: any, remitDetails: RemitDetails) => {
    const downloadFileParams = DownloadRemitFile.call(remitDetails);
    const remitHistoryParams = SearchRemitHistory.call(values);
    downloadRemitFile &&
      downloadRemitFile({ remitHistoryParams, downloadFileParams });
  };

  /**
   * @description handle reverse remit
   * @param {any} values - Form values
   * @param {RemitDetails} remitDetails - The remit record to reverse
   */
  const handleReverseRemit = (values: any, { journalId }: RemitDetails) => {
    const reverseRemitParams = ReverseRemit.call(values);
    const remitHistoryParams = SearchRemitHistory.call(values);
    reverseRemit &&
      reverseRemit({ reverseRemitParams, remitHistoryParams, journalId });
  };

  /**
   * @description call search remit API
   * @param {any} values - Form values
   */
  const onSearch = (values: any) => {
    setFormValues({ ...formValues, ...values });
    const params = SearchRemitHistory.call(values);
    searchRemits && searchRemits(params);
  };

  return (
    <Formik
      id={"remit-history-form"}
      initialValues={{
        ...formValues,
        accountNo: bankAccounts && bankAccounts[0]?.accountNo,
        journalIdText: params?.journalId ?? "",
      }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={onSearch}
    >
      {({ values, handleSubmit, resetForm, ...restProps }: any) => (
        <>
          <$Affix offsetTop={48}>
            <div className="page-header header-border">
              <div
                className="d-flex align-items-center flex-nowrap justify-content-between"
                data-testid="default-view"
              >
                <div className="d-flex align-items-center flex-nowrap">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t(
                      "US.COLLECTION.ECONOMY:REMIT_HISTORY.REMIT_HISTORY"
                    )}
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <div className="d-flex align-items-center">
                    <div className="mr-1">
                      <$Tooltip
                        title={t("US.COLLECTION.ECONOMY:REMIT_HISTORY.ACCOUNT")}
                      >
                        <$Select
                          dataTestid={"select-account"}
                          name="accountNo"
                          placeholder={t(
                            "US.COLLECTION.ECONOMY:REMIT_HISTORY.ACCOUNT"
                          )}
                          formitem={{}}
                          size="small"
                          style={{ width: 280 }}
                          options={bankAccounts}
                          showSearch={false}
                          optionValue="accountNo"
                          optionText="accountName"
                          allOption={false}
                          loading={isLoadingBankAccounts}
                          disabled={isLoadingBankAccounts}
                          optionExtra={{
                            extraNumber: "accountNo",
                          }}
                          onChange={(accountNo: string) =>
                            onSearch({ ...values, accountNo })
                          }
                        />
                      </$Tooltip>
                    </div>

                    <div className="mr-1">
                      <$RangePicker
                        name={`remitDateRange`}
                        size="small"
                        style={{ width: 220 }}
                        picker="date"
                        minuteStep={15}
                        order={false}
                        currentTimeFormat={currentDateFormat}
                        onCalendarChange={(val: any) => {
                          setDates(val);
                        }}
                        onOpenChange={(isOpen: boolean) => {
                          if (isOpen) {
                            setDates([]);
                            restProps.setFieldValue(
                              "remitDateRange",
                              undefined
                            );
                          } else {
                            restProps.setFieldValue(
                              "remitDateRange",
                              formValues.remitDateRange
                            );
                          }
                        }}
                        onChange={(remitDateRange: any) => {
                          if (remitDateRange) {
                            onSearch({ ...values, remitDateRange });
                          }
                        }}
                        disabledDate={disabledDate}
                      />
                    </div>
                    <div className="mr-1">
                      <$Input
                        name="journalIdText"
                        size="small"
                        type="text"
                        placeholder={t(
                          "US.COLLECTION.ECONOMY:PLACE_HOLDERS.JOURNAL_ID"
                        )}
                        onPressEnter={(e: any) =>
                          onSearch({ ...values, journalIdText: e.target.value })
                        }
                        value={values.journalIdText}
                        dataTestid={"input-remit-journal-id"}
                        allowClear={true}
                        onChange={(e: any) => {
                          const journalIdText = e.target.value;
                          restProps.setFieldValue(
                            "journalIdText",
                            journalIdText
                          );
                          if (!journalIdText) {
                            replace({
                              pathname: `/remit-history`,
                            });
                            onSearch({
                              ...values,
                              journalIdText: e.target.value,
                            });
                          }
                        }}
                      />
                    </div>

                    <$Button
                      id={"btnSearch"}
                      onClick={handleSubmit}
                      className="mr-2"
                      type="default"
                      size="small"
                      icon={<SearchOutlined />}
                      disabled={isLoadingBankAccounts}
                    />
                  </div>
                </div>
                <div>
                  <$Button
                    id="btn-generate-remit"
                    type="default"
                    size="small"
                    className="mr-2"
                    onClick={() => push({ pathname: "/remit" })}
                  >
                    {t("US.COLLECTION.ECONOMY:REMIT_HISTORY.GENERATE_REMIT")}
                  </$Button>
                </div>
              </div>
            </div>
          </$Affix>
          <div className="space-content mt-2">
            <$Skeleton
              loading={isLoadingRemitHistory}
              active
              paragraph={{ rows: 2 }}
            >
              <div>
                <RemitHistoryTable
                  onCallAction={(action: RemitAction, remitDetails: any) =>
                    onHandleRemitAction(action, remitDetails, values)
                  }
                />
              </div>
            </$Skeleton>
          </div>
          <LedgerDashboard
            visible={ledgerDashboard.visible}
            journalId={ledgerDashboard.journalId}
            onClose={() =>
              setLedgerDashboard({ journalId: "", visible: false })
            }
          />
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, remitHistory } = state;

  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  const {
    remits,
    bankAccounts,
    isLoadingBankAccounts,
    isLoadingRemitHistory,
    accountsDetails,
    isDetailsLoading,
  } = remitHistory;

  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    remits,
    bankAccounts,
    isLoadingBankAccounts,
    isLoadingRemitHistory,
    accountsDetails,
    isDetailsLoading,
  };
};

const { remits, remit, remitFile } = Actions.remitHistory;
const { init, search } = remits;

const mapDispatchToProps = {
  downloadRemitFile: remitFile.download,
  initRemitHistory: init,
  searchRemits: search,
  reverseRemit: remit.delete,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemitHistory);
