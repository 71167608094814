import moment from "moment";
import { ONLY_DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";
import { IGetPaymentsImportSummary } from "us.collection.economy/interfaces";

export function GetPaymentsImportSummary(this: any): IGetPaymentsImportSummary {
  const [startDate, endDate] = this.dateRange;
  return {
    startDate: moment(startDate)
      .startOf("day")
      .format(ONLY_DATE_FORMAT_FOR_SERVER),
    endDate: moment(endDate).endOf("day").format(ONLY_DATE_FORMAT_FOR_SERVER),
    fileName: this.fileName ?? "",
  };
}
