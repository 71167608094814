import { PeriodType } from "us.collection.economy/constants/Payments";
import { IntlCurrency } from "us.common/functions";
import { i18n } from "us.helper";
import * as Yup from "yup";
import { MatchHelper } from "../Functions";
import { MatchRemainingAction } from "../interface";

export default (currentCurrency: string, currentLanguage: string) => {
  return Yup.object().shape(
    {
      paymentId: Yup.number().when("periodType", {
        is: (periodType) => periodType === PeriodType.ALL,
        then: Yup.number()
          .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_PAYMENT_ID")
          .required(),
        otherwise: Yup.number()
          .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_PAYMENT_ID")
          .notRequired(),
      }),

      kid: Yup.number().typeError(
        "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_KID"
      ),

      matchCaseNo: Yup.number()
        .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_CASE_NO")
        .test(
          "is_a_number",
          "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_CASE_NO",
          (val: any) => /^\d+$/.test(val) || val === "" || val === undefined
        ),

      caseNo: Yup.number()
        .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_CASE_NO")
        .test(
          "is_a_number",
          "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_CASE_NO",
          (val: any) => /^\d+$/.test(val) || val === "" || val === undefined
        ),

      voucherNo: Yup.number().typeError(
        "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_VOUCHER_NO"
      ),
      remainingBalance: Yup.number(),
      caseTableData: Yup.array().of(
        Yup.object().shape({
          children: Yup.array().of(
            Yup.object().shape({
              balance: Yup.number(),
              mappingBalance: Yup.number().test(
                "mappingBalance_less_than_or_equal_to_remaining_balance",
                "",
                function (this: any, val: any) {
                  try {
                    const remainingBalance = MatchHelper.getRemainingBalance();
                    const maxAmount =
                      remainingBalance > this.parent.balance
                        ? this.parent.balance
                        : remainingBalance;
                    const newMax: number = parseFloat(
                      Number(
                        maxAmount + (this.parent.mappingBalance ?? 0)
                      ).toFixed(2)
                    );
                    const maxMappingValue =
                      newMax > this.parent.balance
                        ? this.parent.balance
                        : newMax;
                    if (maxMappingValue < val) {
                      return this.createError({
                        message: `${i18n.t(
                          "US.COLLECTION.ECONOMY:VALIDATIONS.MAXIMUM_AMOUNT_IS"
                        )} ${IntlCurrency(
                          maxMappingValue,
                          currentLanguage,
                          currentCurrency
                        )}`,
                      });
                    }
                    return true;
                  } catch (error) {
                    return this.createError({
                      message: `${i18n.t(
                        "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_MAPPING_BALANCE"
                      )}`,
                    });
                  }
                }
              ),
            })
          ),
        })
      ),

      debtorAccountNo: Yup.string().when(
        ["remainingBalance", "remainingAction"],
        {
          is: (remainingBalance, remainingAction) =>
            remainingBalance === 0 ||
            remainingAction !== MatchRemainingAction.RETURN_EXCEEDED,
          then: Yup.string().notRequired().nullable(),
          otherwise: Yup.string().required(
            "US.COLLECTION.ECONOMY:VALIDATIONS.DEBTOR_ACCOUNT_NO_REQUIRED"
          ),
        }
      ),

      returnFee: Yup.number().when(["remainingBalance", "remainingAction"], {
        is: (remainingBalance, remainingAction) =>
          remainingBalance == 0 ||
          remainingAction !== MatchRemainingAction.RETURN_EXCEEDED,
        then: Yup.number().notRequired(),
        otherwise: Yup.number()
          .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_RETURN_FEE")
          .test(
            "return_fee_should_be_less_than_to_the_return_amount",
            "US.COLLECTION.ECONOMY:VALIDATIONS.RETURN_FEE_EXCEEDED_THE_RETURN_AMOUNT",
            function (returnFee: any) {
              return this.parent.remainingBalance > returnFee;
            }
          ),
      }),
    },
    [["remainingBalance", "remainingAction"]]
  );
};
