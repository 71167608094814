import { INITIAL_STATE_COVERING_SEQUENCE } from "us.collection.economy/reducers/CoveringSequence/State";
import { CoveringSequence } from "us.collection.economy/constants/Actions";

const {
  GET_COVERING_SEQUENCE_LIST,
  GET_COVERING_SEQUENCE_LIST_SUCCESS,
  GET_COVERING_SEQUENCE_LIST_FAIL,
  GET_ITEM_TYPES,
  GET_ITEM_TYPES_SUCCESS,
  GET_ITEM_TYPES_FAIL,
  SAVE_COVERING_SEQUENCE,
  SAVE_COVERING_SEQUENCE_SUCCESS,
  SAVE_COVERING_SEQUENCE_FAIL,
  UPDATE_COVERING_SEQUENCE,
  UPDATE_COVERING_SEQUENCE_SUCCESS,
  UPDATE_COVERING_SEQUENCE_FAIL,
  DELETE_COVERING_SEQUENCE,
  DELETE_COVERING_SEQUENCE_SUCCESS,
  DELETE_COVERING_SEQUENCE_FAIL,
  RESET_COVERING_SEQUENCE_DATA,
  VALIDATE_COVERING_SEQUENCE_NAME,
  VALIDATE_COVERING_SEQUENCE_NAME_SUCCESS,
  VALIDATE_COVERING_SEQUENCE_NAME_FAIL,
  RESET_COVERING_SEQUENCE_NAME_VALIDATION,
} = CoveringSequence;

export default (
  state = INITIAL_STATE_COVERING_SEQUENCE,
  { payload, type }: any
) => {
  switch (type) {
    case GET_COVERING_SEQUENCE_LIST:
      return Object.assign({}, state, {
        list: INITIAL_STATE_COVERING_SEQUENCE.list,
        isListLoading: true,
      });
    case GET_COVERING_SEQUENCE_LIST_SUCCESS:
      return Object.assign({}, state, {
        list: payload.data,
        isListLoading: false,
      });
    case GET_COVERING_SEQUENCE_LIST_FAIL:
      return Object.assign({}, state, {
        list: INITIAL_STATE_COVERING_SEQUENCE.list,
        isListLoading: false,
      });
    case GET_ITEM_TYPES:
      return Object.assign({}, state, {
        itemTypes: INITIAL_STATE_COVERING_SEQUENCE.itemTypes,
        itemTypesLoading: true,
        isValidCoveringSequenceName: true,
      });
    case GET_ITEM_TYPES_SUCCESS:
      const { itemTypesList, defaultTypesList } = payload.data;
      return Object.assign({}, state, {
        itemTypes: itemTypesList.map((type: any, index: number) => ({
          ...type,
          key: `${type.itemTypeId}`,
          description:
            type.description != ""
              ? type?.transactionCode + " - " + type.description
              : type?.transactionCode + " - " + type.itemTypeName,
        })),
        itemTypesLoading: false,
        defaultSequenceList: defaultTypesList,
      });
    case GET_ITEM_TYPES_FAIL:
      return Object.assign({}, state, {
        itemTypes: INITIAL_STATE_COVERING_SEQUENCE.itemTypes,
        itemTypesLoading: false,
      });
    case SAVE_COVERING_SEQUENCE:
      return Object.assign({}, state, {
        saveSequence: {
          data: [],
          isLoading: true,
        },
      });
    case SAVE_COVERING_SEQUENCE_SUCCESS:
      const { savePayload, sequenceData } = payload.data;
      return Object.assign({}, state, {
        saveSequence: {
          data: savePayload,
          isLoading: false,
        },
        list: sequenceData,
      });
    case SAVE_COVERING_SEQUENCE_FAIL:
      return Object.assign({}, state, {
        saveSequence: {
          data: [],
          isLoading: false,
        },
      });
    case RESET_COVERING_SEQUENCE_DATA:
      return Object.assign({}, state, {
        saveSequence: {
          data: [],
          isLoading: false,
        },
      });
    case UPDATE_COVERING_SEQUENCE:
      return Object.assign({}, state, {
        saveSequence: {
          data: [],
          isLoading: true,
        },
      });
    case UPDATE_COVERING_SEQUENCE_SUCCESS:
      const { updatePayload, updatedSeqList } = payload.data;
      return Object.assign({}, state, {
        saveSequence: {
          data: updatePayload,
          isLoading: false,
        },
        list: updatedSeqList,
      });
    case UPDATE_COVERING_SEQUENCE_FAIL:
      return Object.assign({}, state, {
        saveSequence: {
          data: [],
          isLoading: false,
        },
      });
    case DELETE_COVERING_SEQUENCE:
      return Object.assign({}, state, {
        saveSequence: {
          data: [],
          isLoading: true,
        },
      });
    case DELETE_COVERING_SEQUENCE_SUCCESS:
      const { updatedSequenceList } = payload.data;
      return Object.assign({}, state, {
        list: updatedSequenceList,
        saveSequence: {
          data: [],
          isLoading: false,
        },
      });
    case DELETE_COVERING_SEQUENCE_FAIL:
      return Object.assign({}, state, {
        saveSequence: {
          data: [],
          isLoading: false,
        },
      });
    case VALIDATE_COVERING_SEQUENCE_NAME:
      return Object.assign({}, state, {
        isValidCoveringSequenceName: true,
      });
    case VALIDATE_COVERING_SEQUENCE_NAME_SUCCESS:
      return Object.assign({}, state, {
        isValidCoveringSequenceName: payload?.data,
      });
    case VALIDATE_COVERING_SEQUENCE_NAME_FAIL:
      return Object.assign({}, state, {
        isValidCoveringSequenceName: false,
      });
    case RESET_COVERING_SEQUENCE_NAME_VALIDATION:
      return Object.assign({}, state, {
        isValidCoveringSequenceName: true,
      });
    default:
      return state;
  }
};
