import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { ClientAccounts } from "us.collection.economy/constants/Actions";
import { $MessageBox } from "us.common/components";
import { groupBySequenceId } from "us.collection.economy/functions";

const { clientAccounts } = Actions;
const {
  accounts,
  initDetails,
  newClientAccount,
  existingClientAccount,
  accountNumber,
  journalPrefix,
  bankAccount,
} = clientAccounts;

const {
  getClientAccounts,
  getInitDetails,
  saveClientAccount,
  updateClientAccount,
  validateAccountNo,
  validateRemitJournalPrefix,
  deleteClientAccount,
} = API.clientAccounts.accounts;

const ClientAccountsSagas = {
  clientAccounts: {
    getAccountsList: function* (action: any) {
      try {
        const { data, status } = yield call(getClientAccounts);
        if (status === 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNTS_LIST_LOADED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(accounts.success(data));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_LIST_LOADING_FAILED",
            "",
            ""
          );
          yield put(accounts.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_LIST_LOADING_FAILED",
          "",
          ""
        );
        yield put(accounts.fail(new Error()));
      }
    },
    getInitData: function* (action: any): any {
      const params = {
        sequenceIds: ["Remit", "Ledger"],
      };
      try {
        const { ledgerAccounts, sequenceData } = yield call(
          getInitDetails,
          params
        );
        yield put(
          initDetails.success({
            ledgerAccounts: ledgerAccounts?.status == 200 ? ledgerAccounts?.data : [],
            remitSequenceData: sequenceData?.status == 200 ? groupBySequenceId(sequenceData?.data, "Remit") : [],
            ledgerSequenceData: sequenceData?.status == 200 ? groupBySequenceId(sequenceData?.data, "Ledger") : [],
          })
        );
      } catch (error) {
        yield put(initDetails.fail(new Error()));
      }
    },
    addNewClientAccount: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { newAccountData, status, allAccountsList } = yield call(
          saveClientAccount,
          params
        );
        yield put(accounts.success(allAccountsList));
        if ((status === 200 || status === 201) && newAccountData) {
          yield put(newClientAccount.success(newAccountData));
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_CREATED_SUCCESSFULLY",
            "",
            ""
          );
        } else {
          yield put(newClientAccount.fail(new Error()));
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_CREATE_FAILED",
            "",
            ""
          );
        }
      } catch (error) {
        yield put(newClientAccount.fail(new Error()));
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_CREATE_FAILED",
          "",
          ""
        );
      }
    },
    updateClientAccount: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { editedAccountData, status, allAccountsList } = yield call(
          updateClientAccount,
          params
        );
        yield put(accounts.success(allAccountsList));
        if (status === 200 && editedAccountData) {
          yield put(existingClientAccount.success(editedAccountData));
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_UPDATED_SUCCESSFULLY",
            "",
            ""
          );
        } else {
          yield put(existingClientAccount.fail(new Error()));
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_UPDATE_FAILED",
            "",
            ""
          );
        }
      } catch (error) {
        yield put(existingClientAccount.fail(new Error()));
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_UPDATE_FAILED",
          "",
          ""
        );
      }
    },
    validateAccountNo: function* (action: any) {
      const accountNo = action?.payload?.id ?? {};
      try {
        const { data, status } = yield call(validateAccountNo, accountNo);
        if (status === 200) {
          yield put(accountNumber.success(data?.status));
        } else {
          yield put(accountNumber.fail(new Error()));
        }
      } catch (error) {
        yield put(accountNumber.fail(new Error()));
      }
    },
    validateJournalPrefix: function* (action: any) {
      const journalPrefixNo = action?.payload?.id ?? {};
      try {
        const { data, status } = yield call(
          validateRemitJournalPrefix,
          journalPrefixNo
        );
        if (status === 200) {
          yield put(journalPrefix.success(data?.status));
        } else {
          yield put(journalPrefix.fail(new Error()));
        }
      } catch (error) {
        yield put(journalPrefix.fail(new Error()));
      }
    },
    deleteClientAccount: function* (action: any) {
      const accountId = action?.payload?.data ?? {};
      try {
        const { deletedData, status, allAccountsList } = yield call(
          deleteClientAccount,
          accountId
        );
        yield put(accounts.success(allAccountsList));
        if (status === 200) {
          yield put(bankAccount.success(deletedData));
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_DELETED_SUCCESSFULLY",
            "",
            ""
          );
        } else {
          yield put(bankAccount.fail(new Error()));
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_DELETED_FAILED",
            "",
            ""
          );
        }
      } catch (error) {
        yield put(bankAccount.fail(new Error()));
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CLIENT_ACCOUNT_DELETED_FAILED",
          "",
          ""
        );
      }
    },
  },
};

export default [
  takeLatest(
    ClientAccounts.GET_CLIENT_ACCOUNTS,
    ClientAccountsSagas.clientAccounts.getAccountsList
  ),
  takeLatest(
    ClientAccounts.GET_INIT_DETAILS,
    ClientAccountsSagas.clientAccounts.getInitData
  ),
  takeLatest(
    ClientAccounts.ADD_CLIENT_NEW_ACCOUNT,
    ClientAccountsSagas.clientAccounts.addNewClientAccount
  ),
  takeLatest(
    ClientAccounts.UPDATE_CLIENT_ACCOUNT,
    ClientAccountsSagas.clientAccounts.updateClientAccount
  ),
  takeLatest(
    ClientAccounts.VALIDATE_ACCOUNT_NUMBER,
    ClientAccountsSagas.clientAccounts.validateAccountNo
  ),
  takeLatest(
    ClientAccounts.VALIDATE_JOURNAL_PREFIX,
    ClientAccountsSagas.clientAccounts.validateJournalPrefix
  ),
  takeLatest(
    ClientAccounts.DELETE_CLIENT_ACCOUNT,
    ClientAccountsSagas.clientAccounts.deleteClientAccount
  ),
];
