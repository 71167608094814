import React from "react";
import { useTranslation } from "react-i18next";
import { EditOutlined, DeleteOutlined } from "us.icons";
import { VatCodeAction } from "us.collection.economy/components/VATCode/Constants";
import { $Popconfirm } from "us.common/components";
import { IItemMenu } from "./interface";

/**
 * @description - Item menu in table view to add, edit, delete actions
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2855108613/VAT+Code
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 28/07/2022
 */
const ItemMenu: React.FC<IItemMenu> = ({ onCallAction }) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content" data-testid="popover-content">
      <div
        className="d-flex  flex-row more-item"
        data-testid="edit-btn"
        onClick={() => onCallAction(VatCodeAction.EDIT)}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("COMMON.EDIT")}</div>
      </div>
      <$Popconfirm
        title={t("US.COLLECTION.COMMON:COMMON.SURE_TO_DELETE_?")}
        onConfirm={() => onCallAction(VatCodeAction.DELETE)}
      >
        <div
          className="d-flex mb-2 flex-row more-item"
          data-testid="delete-btn"
        >
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );
};

export default ItemMenu;
