import { languageUtils } from "us.helper/jest/API/__mocks__/helper";
import _ from "lodash";
import { IntlCurrency } from "us.common/functions";
import { i18n } from "us.helper";
import * as Yup from "yup";
import { LAST_MAIN_AMOUNT_RANGE_VALUE } from "us.collection.economy/constants";

export default () => {
  return Yup.object().shape({
    profileName: Yup.string()
      .nullable()
      .required("US.COLLECTION.ECONOMY:VALIDATIONS.PROFILE_NAME_IS_REQUIRED"),
    maximumMainAmountProvision: Yup.number()
      .min(
        0,
        "US.COLLECTION.ECONOMY:VALIDATIONS.MAXIMUM_MAIN_AMOUNT_PROVISION_SHOULD_BE_A_POSITIVE_AMOUNT"
      )
      .notRequired()
      .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_AMOUNT"),
    maximumInterestProvision: Yup.number()
      .min(
        0,
        "US.COLLECTION.ECONOMY:VALIDATIONS.MAXIMUM_INTEREST_PROVISION_SHOULD_BE_A_POSITIVE_AMOUNT"
      )
      .notRequired()
      .typeError("US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_AMOUNT"),
    profileDetailsPerson: Yup.array()
      .of(
        Yup.object().shape({
          mainAmountRange: Yup.array().test(
            "isLessThanZero_1",
            "",
            function (this: any, value: any) {
              if (value[0] > LAST_MAIN_AMOUNT_RANGE_VALUE || value[0] == 0) {
                return this.createError({
                  message: `${i18n.t(
                    "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_START_MAIN_AMOUNT"
                  )}`,
                  path: this.path,
                });
              } else if (
                value[1] > LAST_MAIN_AMOUNT_RANGE_VALUE ||
                value[1] == 0
              ) {
                return this.createError({
                  message: `${i18n.t(
                    "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_END_MAIN_AMOUNT"
                  )}`,
                  path: this.path,
                });
              } else if (value[0] > value[1]) {
                return this.createError({
                  message: `${i18n.t(
                    "US.COLLECTION.ECONOMY:VALIDATIONS.START_MAIN_AMOUNT_SHOULD_LESS_THAN_THE_END_MAIN_AMOUNT"
                  )}`,
                  path: this.path,
                });
              } else {
                return true;
              }
            }
          ),
        })
      )
      .test(
        "end_value_should_be_999999999_in_person",
        "",
        function (this: any, value: any) {
          const lastIndex = value.length - 1;
          if (
            value[lastIndex]?.mainAmountRange[1] !==
            LAST_MAIN_AMOUNT_RANGE_VALUE
          ) {
            return this.createError({
              message: `${i18n.t(
                "US.COLLECTION.ECONOMY:VALIDATIONS.THE_LAST_MAIN_AMOUNT_RANGE_SHOULD_BE_ENDS_WITH"
              )} ${IntlCurrency(
                LAST_MAIN_AMOUNT_RANGE_VALUE,
                languageUtils.language,
                languageUtils.currency
              )}`,
              path: `profileDetailsPerson[${value.length - 1}].mainAmountRange`,
            });
          } else {
            return true;
          }
        }
      ),
    profileDetailsCompany: Yup.array()
      .of(
        Yup.object().shape({
          mainAmountRange: Yup.array().test(
            "isLessThanZero_2",
            "",
            function (value: any) {
              if (value[0] > LAST_MAIN_AMOUNT_RANGE_VALUE || value[0] == 0) {
                return this.createError({
                  message: `${i18n.t(
                    "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_START_MAIN_AMOUNT"
                  )}`,
                  path: this.path,
                });
              } else if (
                value[1] > LAST_MAIN_AMOUNT_RANGE_VALUE ||
                value[1] == 0
              ) {
                return this.createError({
                  message: `${i18n.t(
                    "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_END_MAIN_AMOUNT"
                  )}`,
                  path: this.path,
                });
              } else if (value[0] > value[1]) {
                return this.createError({
                  message: `${i18n.t(
                    "US.COLLECTION.ECONOMY:VALIDATIONS.START_MAIN_AMOUNT_SHOULD_LESS_THAN_THE_END_MAIN_AMOUNT"
                  )}`,
                  path: this.path,
                });
              } else {
                return true;
              }
            }
          ),
        })
      )
      .test(
        "end_value_should_be_999999999_in_person",
        "",
        function (this: any, value: any) {
          const lastIndex = value.length - 1;
          if (
            value[lastIndex]?.mainAmountRange[1] !==
            LAST_MAIN_AMOUNT_RANGE_VALUE
          ) {
            return this.createError({
              message: `${i18n.t(
                "US.COLLECTION.ECONOMY:VALIDATIONS.THE_LAST_MAIN_AMOUNT_RANGE_SHOULD_BE_ENDS_WITH"
              )} ${IntlCurrency(
                LAST_MAIN_AMOUNT_RANGE_VALUE,
                languageUtils.language,
                languageUtils.currency
              )}`,
              path: `profileDetailsCompany[${
                value.length - 1
              }].mainAmountRange`,
            });
          } else {
            return true;
          }
        }
      ),
  });
};
