import { saveCoveringSequenceParams } from "us.collection.economy/interfaces";
import { getFormattedSequenceDetails } from "../Functions";

export function AddCoveringSequence(this: any): saveCoveringSequenceParams {
  return {
    sequenceId: this.sequenceId,
    sequenceName: this.sequenceName,
    displayName: this.displayName,
    sequenceDetails: getFormattedSequenceDetails(this.sequenceDetails),
  };
}
