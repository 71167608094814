import React from "react";
import { $Tooltip, ColumnsType } from "us.common/components";
import { useTranslation } from "react-i18next";
import { ExclamationCircleFilled } from "us.icons";
import {
  ICreditor,
  ICreditorGroup,
} from "us.collection.economy/interfaces/Remit/IRemitProps";
import { RemitStatus } from "us.collection.economy/components/Remit/Components/CreditorSelector/Constants";
import {
  getValidationMessage,
  getColumnCreditorGroups,
} from "us.collection.economy/components/Remit/Components/CreditorSelector/Functions";

const Columns = (
  values: any,
  creditorGroups: Array<ICreditorGroup>,
  onNavigate: (record: ICreditor) => void
) => {
  const { t } = useTranslation();

  const columns = [
    {
      title: t("US.COLLECTION.ECONOMY:REMIT.CREDITOR_NO"),
      key: "creditorNo",
      dataIndex: "creditorNo",
      render: (text: any, record: ICreditor) => {
        const { remitStatus, creditorNo, errorStatus } = record ?? {};
        return (
          <a
            onClick={() => onNavigate(record)}
            data-testid="tooltip-validation"
          >
            {remitStatus === RemitStatus.INELIGIBLE && (
              <$Tooltip
                title={t(getValidationMessage(errorStatus))}
                className="mr-1"
              >
                <ExclamationCircleFilled
                  className="text-error"
                  data-testid="tooltip-validation-msg"
                />
              </$Tooltip>
            )}
            <span>{creditorNo}</span>
          </a>
        );
      },
    },
    {
      title: t("US.COLLECTION.ECONOMY:REMIT.CREDITOR_NAME"),
      dataIndex: "name",
      key: "name",
    },
    {
      title: t("US.COLLECTION.ECONOMY:REMIT.ACCOUNT_NUMBER"),
      dataIndex: "remitAccountNo",
      key: "remitAccountNo",
      width: "200px",
    },
    {
      title: t("US.COLLECTION.ECONOMY:REMIT.ORG_NUMBER"),
      dataIndex: "companyID",
      key: "companyID",
      width: "200px",
    },
    {
      title: t("US.COLLECTION.ECONOMY:REMIT.ADDRESS"),
      dataIndex: "addr1",
      key: "addr1",
      render: (text: any, { addr1, addr2, addr3 }: any) => {
        return [addr1, addr2, addr3].filter((line: any) => line).join(",");
      },
    },
    {
      title: t("US.COLLECTION.ECONOMY:REMIT.CREDITOR_GROUP"),
      dataIndex: "groupName",
      key: "groupName",
      width: "260px",
      filters: getColumnCreditorGroups(creditorGroups, values.creditorGroup),
      onFilter: (value: any, record: ICreditor) =>
        record.groupName?.indexOf(value) === 0,
    },
  ];

  const extraColumns = [
    {
      title: t("US.COLLECTION.ECONOMY:REMIT.ZIP_CODE"),
      dataIndex: "zipCode",
      key: "zipCode",
    },
    {
      title: t("US.COLLECTION.ECONOMY:REMIT.ZIP_NAME"),
      dataIndex: "zipName",
      key: "zipName",
    },
  ].filter(({ dataIndex }: { dataIndex: string }) =>
    values?.extraColumns?.includes(dataIndex)
  );

  return [...columns, ...extraColumns] as ColumnsType<any>;
};

export default Columns;
