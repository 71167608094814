import { initialState } from "../../reducers/RegisterPayment/State";
import { RegisterPayments } from "../../constants/Actions";

const {
  GET_PAYMENT_TYPE,
  GET_PAYMENT_TYPE_SUCCESS,
  GET_PAYMENT_TYPE_FAIL,
  GET_VOUCHER_NO,
  GET_VOUCHER_NO_SUCCESS,
  GET_VOUCHER_NO_FAIL,
  GET_PAYMENT_METHOD,
  GET_PAYMENT_METHOD_SUCCESS,
  GET_PAYMENT_METHOD_FAIL,
  GET_VALIDATE_CASE_ID,
  GET_VALIDATE_CASE_ID_SUCCESS,
  GET_VALIDATE_CASE_ID_FAIL,
  SAVE_REGISTER_PAYMENT,
  SAVE_REGISTER_PAYMENT_SUCCESS,
  SAVE_REGISTER_PAYMENT_FAIL,
  CLEAR_VALIDATE_CASE_ID_DATA,
} = RegisterPayments;

/**
 * @description update amount feild value
 */
const getUpdatedAmount = (payload: any) => {
  let amount = "";

  if (
    typeof payload !== "undefined" &&
    typeof payload?.values !== "undefined"
  ) {
    const { values, addedAmount, data } = payload;
    const formValues = payload?.values != undefined && Object.keys(values).length !== 0 ? values : initialState.formInitial
    const { paymentType, voucherAmount } = formValues
    const { isCreditorInvoice, arBalance } = data;

    amount =
      paymentType == 67 && !isCreditorInvoice
        ? ""
        : addedAmount == 0
          ? arBalance != undefined
            ? voucherAmount < arBalance
              ? voucherAmount
              : arBalance
            : ""
          : voucherAmount === addedAmount
            ? ""
            : voucherAmount - addedAmount < 0
              ? ""
              : voucherAmount - addedAmount;
  }

  return amount;
};

/**
 * @description update amount feild disable status
 */
const getAmountDisableStatus = (payload: any) => {
  let disableStatus = false
  if (
    typeof payload !== "undefined" &&
    typeof payload?.values !== "undefined"
  ) {
    const { values, addedAmount, data } = payload;
    const { paymentType, voucherAmount } = values;
    const { isCreditorInvoice, arBalance } = data;

    disableStatus = voucherAmount == addedAmount ||
      (Object.keys(data).length != 0 && paymentType == 67 && !isCreditorInvoice) ||
      (Object.keys(data).length != 0 && (paymentType == 47 || paymentType == 27) && isCreditorInvoice)
  }

  return disableStatus
};

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_PAYMENT_TYPE:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          paymentTypes: [],
          isFetching: true,
          isPaymentTypeFetching: true,
        },
      });
    case GET_PAYMENT_TYPE_SUCCESS:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          paymentTypes: payload?.data,
          isFetching: false,
          isPaymentTypeFetching: false,
        },
      });
    case GET_PAYMENT_TYPE_FAIL:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          paymentTypes: [],
          isFetching: false,
          isPaymentTypeFetching: false,
        },
      });
    case GET_PAYMENT_METHOD:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          paymentMethods: [],
          isFetching: true,
          isPaymentTypeFetching: true,
        },
      });
    case GET_PAYMENT_METHOD_SUCCESS:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          paymentMethods: payload?.data,
          isFetching: false,
          isPaymentTypeFetching: false,
        },
      });
    case GET_PAYMENT_METHOD_FAIL:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          paymentMethods: [],
          isFetching: false,
          isPaymentTypeFetching: false,
        },
      });
    case GET_VOUCHER_NO:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          voucherNo: 0,
          isVoucherNoFetching: true,
        },
      });
    case GET_VOUCHER_NO_SUCCESS:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          voucherNo: payload?.data,
          isVoucherNoFetching: false,
        },
      });
    case GET_VOUCHER_NO_FAIL:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          voucherNo: 0,
          isVoucherNoFetching: false,
        },
      });
    case GET_VALIDATE_CASE_ID:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          validationCaseIdResults: {},
          isvalidating: true,
        },
      });
    case GET_VALIDATE_CASE_ID_SUCCESS:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          validationCaseIdResults: payload?.data,
          isvalidating: false,
          isAmountDisable: getAmountDisableStatus(payload),
        },
        formInitial: {
          ...initialState.formInitial,
          ...payload.values,
          amount: getUpdatedAmount(payload),
        },
      });
    case GET_VALIDATE_CASE_ID_FAIL:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          validationCaseIdResults: {},
          isvalidating: false,
        },
      });
    case SAVE_REGISTER_PAYMENT:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          isFetching: true,
          isPaymentSaving: true
        },
      });
    case SAVE_REGISTER_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          paymentId: payload?.data,
          isFetching: false,
          isPaymentSaving: false
        },
      });
    case SAVE_REGISTER_PAYMENT_FAIL:
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          isFetching: false,
          isPaymentSaving: false
        },
      });
    case CLEAR_VALIDATE_CASE_ID_DATA:
      const formValues = payload?.values != undefined && Object.keys(payload?.values).length !== 0 ? payload?.values : initialState.formInitial
      const { addedAmount } = payload
      const { voucherAmount } = formValues
      return Object.assign({}, state, {
        payments: {
          ...state.payments,
          validationCaseIdResults: {},
          isFetching: false,
          isAmountDisable: voucherAmount != undefined && voucherAmount === addedAmount,
        },
        formInitial: {
          ...initialState.formInitial,
          ...formValues,
          amount: getUpdatedAmount({
            ...payload,
            data: { isCreditorInvoice: state.payments.validationCaseIdResults },
          }),
        },
      });
    default:
      return state;
  }
};
