import { journalItem } from "us.collection.economy/components/RegisterJournal/Constants";
import { IJournalItem } from "us.collection.economy/components/RegisterJournal/Interfaces";

interface IInitialState {
  ledgerAccountList: {
    data: Array<any>;
    isLoading: boolean;
  };
  remitHistory: {
    data: Array<any>;
    isLoading: boolean;
  };
  validation: any[];
  journalEntryTableInitial: IJournalItem;
  isJournalEntriesSaving: boolean;
}

export const initialState: IInitialState = {
  ledgerAccountList: {
    data: [],
    isLoading: false,
  },
  remitHistory: {
    data: [],
    isLoading: false,
  },
  validation: [],
  journalEntryTableInitial: journalItem,
  isJournalEntriesSaving: false,
};
