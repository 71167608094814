import { VatCodeAction } from "../Constants";

/**
 * @description Get vat codes by vatId
 * @param {Array<any>} vatCodeList - List of vat codes to find by vatId
 * @param {number} vatId - The URL params id to find matching vat code from the given list
 * @returns {any | undefined} - If find item will return it otherwise return undefined
 */
export const getVatCodeRecordDetailsById = (
  vatCodeList: Array<any>,
  vatId: number
): any | {} => {
  try {
    const matchedRecord = vatCodeList.find((vat: any) => vat.vatId == vatId);
    return { ...matchedRecord, rate: matchedRecord?.rate.toFixed(2) } ?? {};
  } catch (error) {
    return {};
  }
};

/**
 * @description find vat code data is updated or not
 * @param {any} vatCodeList - current form values
 * @param {any} initialVatCodeList - initial vat code data
 * @returns {boolean} - if initial vatcode values are changed return true otherwise false
 */
export const isFormDirty = (
  vatCodeList: any,
  initialVatCodeList: any
): boolean => {
  try {
    return JSON.stringify(vatCodeList) !== JSON.stringify(initialVatCodeList);
  } catch (error) {
    return true;
  }
};

/**
 * @description find ledger account name by id
 * @param {any} ledgerAccounts - ledger acoounts list
 * @param {any} accountId - account id
 * @returns {string} - return ledger account name
 */
export const getAccountNameById = (
  ledgerAccounts: any,
  accountId: any
): string => {
  try {
    return ledgerAccounts?.find((acc: any) => acc.accountId == accountId)
      ?.accountName;
  } catch (error) {
    return "";
  }
};

/**
 * @description get drawer title by action
 * @param {VatCodeAction} action - action
 * @returns title of the drawer
 */
export const getDrawerTitle = (action: VatCodeAction) => {
  switch (action) {
    case VatCodeAction.ADD:
      return "US.COLLECTION.ECONOMY:VAT_CODE.NEW_VAT_CODE";
    default:
      return "US.COLLECTION.ECONOMY:VAT_CODE.EDIT_VAT_CODE";
  }
};
