import React from "react";
import { Route } from "react-router-dom";

const Articles = React.lazy(() => import("../containers/Articles"));
const ClientAccounts = React.lazy(() => import("../containers/ClientAccounts"));
const DedcuctionOfOtherInvoices = React.lazy(
  () => import("../containers/DedcuctionOfOtherInvoices")
);
const GenerateRemit = React.lazy(() => import("../containers/GenerateRemit"));
const ImportRemitReceipt = React.lazy(
  () => import("../containers/ImportRemitReceipt")
);
const InvoicingProfiles = React.lazy(
  () => import("../containers/InvoicingProfiles")
);
const ItemTypes = React.lazy(() => import("../containers/ItemTypes"));
const LedgerAccounts = React.lazy(() => import("../containers/LedgerAccounts"));
const ManagePayments = React.lazy(() => import("../containers/ManagePayments"));
const ManagePaymentsOld = React.lazy(
  () => import("../containers/ManagePaymentsOld")
);
const OrderLines = React.lazy(() => import("../containers/OrderLines"));
const ClientInvoices = React.lazy(() => import("../containers/ClientInvoices"));
const ClientOrders = React.lazy(() => import("../containers/ClientOrders"));
const ManualOrders = React.lazy(() => import("../containers/ManualOrders"));
const PaymentSearch = React.lazy(() => import("../containers/PaymentSearch"));
const RegisterPayments = React.lazy(
  () => import("../containers/RegisterPayments")
);

const RemitHistory = React.lazy(() => import("../containers/RemitHistory"));
const Remit = React.lazy(() => import("../containers/Remit"));
const TransactionProfiles = React.lazy(
  () => import("../containers/TransactionProfiles")
);
const VATCode = React.lazy(() => import("../containers/VATCode"));
const ImportPayments = React.lazy(() => import("../containers/ImportPayments"));
const InvoiceList = React.lazy(() => import("../containers/InvoiceList"));
const PaymentsImportSummary = React.lazy(
  () => import("../containers/PaymentImportSummary")
);
const CoveringSequence = React.lazy(
  () => import("../containers/CoveringSequence")
);
const ProvisionProfiles = React.lazy(
  () => import("../containers/ProvisionProfiles")
);
const RegisterJournal = React.lazy(
  () => import("../containers/RegisterJournal")
);
const CreditorInvoiceDetails = React.lazy(
  () => import("../containers/CreditorInvoiceDetails")
);


export default [
  <Route
    exact
    key="/articles"
    path={"/articles"}
    render={() => <Articles />}
  />,
  <Route
    exact
    key="/client-accounts"
    path={"/client-accounts"}
    render={() => <ClientAccounts />}
  />,
  <Route
    exact
    key="/deduct-invoices"
    path={"/deduct-invoices"}
    render={() => <DedcuctionOfOtherInvoices />}
  />,
  <Route
    exact
    key="/generate-remit"
    path={"/generate-remit"}
    render={() => <GenerateRemit />}
  />,
  <Route
    exact
    key="/import-remit-receipt"
    path={"/import-remit-receipt"}
    render={() => <ImportRemitReceipt />}
  />,
  <Route
    exact
    key="/invoicing-profiles"
    path={"/invoicing-profiles"}
    render={() => <InvoicingProfiles />}
  />,
  <Route
    exact
    key="/item-types"
    path={"/item-types"}
    render={() => <ItemTypes />}
  />,
  <Route
    exact
    key="/ledger-accounts"
    path={"/ledger-accounts"}
    render={() => <LedgerAccounts />}
  />,
  <Route
    exact
    key="/manage-payments"
    path={"/manage-payments"}
    render={() => <ManagePayments />}
  />,
  <Route
    exact
    key="/manage-payments-old"
    path={"/manage-payments-old"}
    render={() => <ManagePaymentsOld />}
  />,
  <Route
    exact
    key="/order-lines"
    path={"/order-lines"}
    render={() => <OrderLines />}
  />,
  <Route
    exact
    key="/orders"
    path={"/orders"}
    render={() => <ClientOrders />}
  />,
  <Route
    exact
    key="/manual-orders"
    path={"/manual-orders"}
    render={() => <ManualOrders />}
  />,
  <Route
    exact
    key="/payment-search"
    path={"/payment-search"}
    render={() => <PaymentSearch />}
  />,
  <Route
    exact
    key="/register-payments"
    path={"/register-payments"}
    render={() => <RegisterPayments />}
  />,
  <Route
    exact
    key="/remit-history"
    path={"/remit-history"}
    render={() => <RemitHistory />}
  />,
  <Route exact key="/remit" path={"/remit"} render={() => <Remit />} />,
  <Route
    exact
    key="/transaction-profiles"
    path={"/transaction-profiles"}
    render={() => <TransactionProfiles />}
  />,
  <Route
    exact
    key="/import-payments"
    path={"/import-payments"}
    render={() => <ImportPayments />}
  />,
  <Route
    exact
    key="/manage-client-invoices"
    path={"/manage-client-invoices"}
    render={() => <ClientInvoices />}
  />,
  <Route exact key="/vat-code" path={"/vat-code"} render={() => <VATCode />} />,
  <Route
    exact
    key="/invoice-list"
    path={"/invoice-list"}
    render={() => <InvoiceList />}
  />,
  <Route
    exact
    key="/payments-import-summary"
    path={"/payments-import-summary"}
    render={() => <PaymentsImportSummary />}
  />,
  <Route
    exact
    key="/covering-sequence"
    path={"/covering-sequence"}
    render={() => <CoveringSequence />}
  />,
  <Route
    key="/provision-profiles"
    path={"/provision-profiles"}
    render={() => <ProvisionProfiles />}
  />,
  <Route
    key="/manual-journal-entry"
    path={"/manual-journal-entry"}
    render={() => <RegisterJournal />}
  />,
  <Route
  exact
  key="/creditor-invoice-details"
  path={"/creditor-invoice-details"}
  render={() => <CreditorInvoiceDetails />}
/>,


  
];
