import { ILedgerAccounts } from "us.collection.economy/interfaces";
import { DEFAULT_DRAWER_STATE } from "us.collection.economy/components/LedgerAccounts/Constants"

export const INITIAL_STATE: ILedgerAccounts = {
  list: [],
  isListLoading: false,
  saveLedgerAccount: {
    data: {},
    isLoading: false,
  },
  duplicateValidation: {
    status: true,
    isValidating: false,
  },
  drawerInfo: DEFAULT_DRAWER_STATE,
};
