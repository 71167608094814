import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { ICOFilter } from "./interface";
import { InitialOrderType } from "us.collection.economy/components/ClientOrders/Constants"
import "us.collection.economy/components/ClientOrders/Home.scss";

const {
  $Affix,
  $AmountLabel,
  $Input,
  $Select,
  $Form,
  $Switch,
  $Button,
  $Badge,
} = Common.Components;

const Filter: React.FC<ICOFilter> = (props) => {
  const { t } = useTranslation();

  const {
    creditors,
    orderTypes,
    totalOrders,
    totalOrderAmount,
    values,
    restProps,
    isOrderTypesLoading,
    onChange,
    onSelectCreditors,
  } = props;

  return (
    <$Affix offsetTop={95}>
      <aside className="orders-side  d-flex align-items-start flex-column">
        <div className="mb-auto w-100">
          <$Form layout="vertical" autocomplete="off">
            <div className="d-flex align-items-center justify-content-between mb-1">
              <label>{t("US.COLLECTION.ECONOMY:ORDERS.MANUAL")}</label>
              <$Switch
                size="small"
                name="manualOrdersOnly"
                onChange={(manualOrdersOnly: boolean) => {
                  restProps.setFieldValue("manualOrdersOnly", manualOrdersOnly);
                  onChange({ manualOrdersOnly });
                }}
              />
            </div>
            <$Select
              name="orderType"
              mode="multiple"
              formitem={{}}
              size="small"
              options={orderTypes}
              optionValue="categoryCode"
              disabled={isOrderTypesLoading}
              loading={isOrderTypesLoading}
              defaultValue={InitialOrderType.ALL}
              optionText="categoryName"
              onSelect={(categoryCode: string | number) => {
                if (categoryCode == InitialOrderType.ALL) {
                  restProps.setFieldValue("orderType", [InitialOrderType.ALL]);
                  onChange({ orderType: [InitialOrderType.ALL] });
                } else {
                  const types = [...values?.orderType, categoryCode].filter(
                    (type: any) => type !== InitialOrderType.ALL
                  );
                  restProps.setFieldValue("orderType", types);
                  onChange({ orderType: types });
                }
              }}
              onDeselect={(categoryCode: string | number) => {
                onChange({
                  orderType: values?.orderType.filter(
                    (type: any) => type !== categoryCode
                  ),
                });
              }}
            />

            <$Input
              name="orderNo"
              size="small"
              allowClear
              type="number"
              placeholder={t("US.COLLECTION.ECONOMY:ORDERS.ORDER_NO")}
              onChange={(e: any) => {
                restProps.setFieldValue("orderNo", e.target.value);
                !e.target.value &&
                onChange({ orderNo: e.target.value });
              }}
              onPressEnter={()=> {
                values.orderNo &&
                onChange({ orderNo: values?.orderNo });
              }}
              formitem={{
                extra: values?.orderNo
                  ? t("US.COMMON:COMMON.PRESS_ENTER_TO_SEARCH")
                  : null,
              }}
            />

            <div className="d-flex justify-content-between mt-2">
              <$Button
                type="link"
                size="small"
                onClick={onSelectCreditors}
                data-testid="select-creditor"
                className="font-weight-bold p-0"
              >
                {t("US.COLLECTION.ECONOMY:ORDERS.SELECT_CREDITORS")}
              </$Button>
              <$Badge
                style={{ backgroundColor: "#495057" }}
                count={
                  creditors[0] === -1
                    ? t("US.COLLECTION.ECONOMY:ORDERS.ALL")
                    : creditors.length
                }
              />
            </div>
          </$Form>
        </div>
        <div className="w-100">
          <div className="d-flex flex-column orders-total mb-2">
            <div className="font-weight-bold">{totalOrders}</div>
            <div>{t("US.COLLECTION.ECONOMY:ORDERS.TOTAL_ORDERS")}</div>
          </div>
          <div className="d-flex flex-column total-order-amount">
            <div className="font-weight-bold">
              <div className="font-weight-bold">
                <$AmountLabel value={totalOrderAmount} />
              </div>
            </div>
            <div>{t("US.COLLECTION.ECONOMY:ORDERS.TOTAL_ORDERS_AMOUNT")}</div>
          </div>
        </div>
      </aside>
    </$Affix>
  );
};

export default Filter;
