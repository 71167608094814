import React, { useEffect } from "react";
import Common from "us.common";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import * as Actions from "us.collection.economy/actions";
import { connect } from "react-redux";
import { bureauOwnType } from "us.collection.economy/constants/Payments";
const { clearValidateCaseData } = Actions.managePayment;

const { $Divider, $Skeleton, $AmountLabel } = Common.Components;

interface IDebtorInfo {
  isValidating: boolean;
  validationCaseIdResults: any;
  payment: any;
  clearValidateCaseData: () => void;
}

const DebtorInfo: React.FC<IDebtorInfo> = (props) => {
  const { t } = useTranslation();
  const {
    isValidating,
    validationCaseIdResults,
    payment,
    clearValidateCaseData,
  } = props;

  useEffect(() => {
    clearValidateCaseData();

    return () => {
      clearValidateCaseData();
    };
  }, []);

  return (
    <>
      <$Skeleton loading={isValidating} paragraph={{ rows: 2 }}>
        {Object.keys(validationCaseIdResults).length != 0 && !isValidating && (
          <div className="reg-payment-case-summery d-flex justify-content-between align-items-center">
            <div className="flex-fill">
              <div className="font-weight-bold" data-testid="label-case-debtor">
                {!(
                  payment?.itemType == bureauOwnType &&
                  !validationCaseIdResults.isCreditorInvoice
                ) && validationCaseIdResults.debtorName != undefined
                  ? validationCaseIdResults.debtorName
                  : "-"}
              </div>
              <div>{t("US.COLLECTION.COMMON:COMMON.DEBTOR")}</div>

              <div
                className="font-weight-bold mt-2"
                data-testid="label-case-creditor"
              >
                {!(
                  payment?.itemType == bureauOwnType &&
                  !validationCaseIdResults.isCreditorInvoice
                ) && validationCaseIdResults.creditorName != undefined
                  ? validationCaseIdResults.pid +
                    " - " +
                    validationCaseIdResults.creditorName
                  : "-"}
              </div>
              <div>{t("US.COLLECTION.COMMON:COMMON.CREDITOR")}</div>
            </div>
            <$Divider
              className="mx-4"
              type="vertical"
              style={{
                height: "100px",
              }}
            ></$Divider>
            <div className="text-right">
              <div
                className="font-weight-bold font-lg"
                data-testid="label-case-balance"
              >
                <$AmountLabel
                  value={
                    !(
                      payment?.itemType == bureauOwnType &&
                      !validationCaseIdResults.isCreditorInvoice
                    ) && validationCaseIdResults.arBalance != undefined
                      ? validationCaseIdResults.arBalance
                      : "-"
                  }
                />
              </div>
              <div>
                {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_BALANCE")}
              </div>
            </div>
          </div>
        )}
      </$Skeleton>
    </>
  );
};
const mapStateToProps = (state: IRootState) => {
  const { managePayment } = state;
  const { caseValidation } = managePayment;

  return {
    validationCaseIdResults: caseValidation.data,
    isValidating: caseValidation.isFetching,
  };
};

const mapDispatchToProps = {
  clearValidateCaseData,
};
export default connect(mapStateToProps, mapDispatchToProps)(DebtorInfo);
