import React from "react";
import { useTranslation } from "react-i18next";
import { ITagClasses } from "us.collection.economy/components/ManagePayments/interface";
import { $Tag, $AmountLabel } from "us.common/components";

interface Props {
  amount: number;
  children?: any;
  tagName: string;
  tagClass: ITagClasses;
  isUnknownOld: boolean;
}

const PaymentBase: React.FC<Props> = ({
  amount,
  children,
  tagName,
  tagClass,
  isUnknownOld = false,
}) => {
  const { t } = useTranslation();

  return (
    <div className="map-item-wrapper">
      <div className="map-item-header d-flex justify-content-between">
        <div className="map-item-header-tag">
          <$Tag className={tagClass}>
            <div className="d-flex justify-content-between">
              <span>
                {isUnknownOld
                  ? t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN_RESOLVED")
                  : t(tagName)}
              </span>
              {!isUnknownOld && (
                <span className="map-item-tag-amount ml-5">
                  <$AmountLabel value={amount} />
                </span>
              )}
            </div>
          </$Tag>
        </div>
      </div>
      <div className="map-item-body">{children}</div>
    </div>
  );
};

export default PaymentBase;
