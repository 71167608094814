import { ISearchFilters } from "../interface";

export function GetPayments(this: ISearchFilters) {
  let payments = {
    startDate: this.startDate,
    endDate: this.endDate,
    periodType: this.periodType,
    debtorOtherCasesStatus: this.debtorOtherCasesStatus,
    paymentStates: this.paymentStates,
    accountNo: this.accountNo,
    paymentId: this.paymentId,
    offset: this.offset,
    limit: this.limit,
  };

  return payments;
}
