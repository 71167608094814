import { IDataItem } from "us.common/components/DragNDrop/Interfaces";
import { TypeCode } from "us.common/components/DragNDrop/Constants";
import { DynamicComponent } from "us.common/constants";
import {
  ICoveringSequenceList,
  sequenceDetailsParams,
} from "us.collection.economy/interfaces";
import { isArray } from "lodash";

/**
 * @function
 * @description Get formatted sequence list
 * @param {Array<CoveringSequenceRecord>} coveringSequenceData - CoveringSequenceData
 * @returns {Array<IDataItem>} Formatted sequence list
 */
export const getFormatedSequenceList = (
  sequenceDetails: Array<sequenceDetailsParams>
): Array<IDataItem> | [] => {
  let formattedSeqList: Array<IDataItem> = [];
  try {
    sequenceDetails
      .sort((a: any, b: any) => a.rank - b.rank)
      .map((seq: sequenceDetailsParams, index: number) => {
        formattedSeqList.push({
          ...seq,
          id: index,
          name:
            seq.type === TypeCode.ITEM_TYPE
              ? seq.description.split(",")
              : seq.description,
          names: seq.description,
          isNew: false,
          componentType:
            seq.type === TypeCode.ITEM_TYPE
              ? DynamicComponent.Select
              : DynamicComponent.Label,
          preferredIds: seq.preferredIds ?? "",
        });
      });
  } catch (error) {}
  return formattedSeqList;
};

/**
 * @function
 * @description Get sorted covering sequence list
 * @param {Array<ICoveringSequenceList>} coveringSequenceRecordList - CoveringSequenceTableData
 * @returns {Array<ICoveringSequenceList>} sorted covering sequence records list
 */
export const getSortedSequenceList = (
  coveringSequenceRecordList: Array<ICoveringSequenceList>
): Array<ICoveringSequenceList> => {
  try {
    if (isArray(coveringSequenceRecordList)) {
      const sortedSequenceList = coveringSequenceRecordList.sort(
        (a: any, b: any) => b.sequenceId - a.sequenceId
      );
      return sortedSequenceList;
    } else {
      return [];
    }
  } catch (error) {
    return coveringSequenceRecordList;
  }
};
