import {
  GetCreditorsParams,
  GetCreditorGroupsParams,
  GetRemitProposalParams,
  CreateRemitParams,
  InvoiceRemitParams,
} from "us.collection.economy/interfaces";
import { httpCollection } from "us.helper/http/collection";

export default {
  getBankAccounts: (params: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.get(
          "paymentservice",
          "payment/bankaccounts",
          {}
        );

        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  getCreditorGroups: ({ accountNo }: GetCreditorGroupsParams): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.get(
          "paymentservice",
          `remit/creditorgroups?accountno=${accountNo}`,
          {}
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  getCreditors: ({ accountNo, groupId }: GetCreditorsParams): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.get(
          "paymentservice",
          `remit/creditors?groupId=${groupId}&accountno=${accountNo}`,
          {}
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  getProposal: (params: GetRemitProposalParams): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.post(
          "paymentservice",
          `remit/proposal`,
          params
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  validate: ({ remitId, ignoreExceedPayments }: any): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.get(
          "paymentservice",
          `remit/validationresult?remitid=${remitId}&ignoreExceedPayments=${ignoreExceedPayments}`,
          {}
        );
        resolve({
          data,
          status,
        });
      } catch (error) {
        reject(error);
      }
    });
  },
  getDetailsByCreditor: ({
    creditorNo,
    remitId,
  }: {
    creditorNo: string;
    remitId: string;
  }): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data, status } = await httpCollection.get(
          "paymentservice",
          `remit/summary?creditorno=${creditorNo}&remitid=${remitId}`,
          {}
        );
        resolve({ data, status });
      } catch (error) {
        reject(error);
      }
    });
  },
  generate: (params: CreateRemitParams): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.post(
          "paymentservice",
          `remit`,
          params
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
  invoice: (params: InvoiceRemitParams): Promise<any> => {
    return new Promise<any>(async (resolve, reject) => {
      try {
        const { data } = await httpCollection.patch(
          "paymentservice",
          "remit/VATDeduction",
          params
        );
        resolve(data);
      } catch (error) {
        reject(error);
      }
    });
  },
};
