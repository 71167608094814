import { PaymentTypes } from "us.collection.economy/constants";
import { IPaymentType } from "../interface";

/**
 * @description  remove OCR Payment type from payment types
 * @param {Array<IPaymentType>} paymentTypes - An array of data
 * @returns {Array<IPaymentType>} - An array of `IPaymentType` return after filter OCR payment type
 */
export const filterOCRPaymentTypes  = (
  paymentTypes: Array<IPaymentType>
): Array<IPaymentType> => {
  try {
    const { OCR_PAYMENT_CLIENT_ACCOUNT } = PaymentTypes;
    return paymentTypes.filter(
      (paymentType) => paymentType.paymentTypeId !== OCR_PAYMENT_CLIENT_ACCOUNT
    );
  } catch (error) {
    return [];
  }
};
