export enum COSearchType {
  REG_DATE = "REGDATE",
  ORDER = "ORDER",
}

export enum InitialOrderType {
  ALL = "ALL",
}

export type ICOSearchType = COSearchType.ORDER | COSearchType.REG_DATE;

export enum COSortType {
  ORDER_NO = "orderNo",
  CUSTOMER_NO = "customerNo",
  ORDER_AMOUNT = "totalAmount",
}

export enum OrderGenerationTypes {
  CREDITOR_ORDERS = "CLIENT",
  VAT_ORDERS = "VAT",
  DEFAULT = "DF"
}