export enum ClientOrders {
  SEARCH_CLIENT_ORDERS = "SEARCH_CLIENT_ORDERS",
  SEARCH_CLIENT_ORDERS_SUCCESS = "SEARCH_CLIENT_ORDERS_SUCCESS",
  SEARCH_CLIENT_ORDERS_FAILED = "SEARCH_CLIENT_ORDERS_FAILED",

  GET_ORDER_TYPES = "GET_ORDER_TYPES",
  GET_ORDER_TYPES_SUCCESS = "GET_ORDER_TYPES_SUCCESS",
  GET_ORDER_TYPES_FAILED = "GET_ORDER_TYPES_FAILED",

  EXPORT_ORDERS = "EXPORT_ORDERS",
  EXPORT_ORDERS_SUCCESS = "EXPORT_ORDERS_SUCCESS",
  EXPORT_ORDERS_FAILED = "EXPORT_ORDERS_FAILED",

  DELETE_ORDERS = "DELETE_ORDERS",
  DELETE_ORDERS_SUCCESS = "DELETE_ORDERS_SUCCESS",
  DELETE_ORDERS_FAILED = "DELETE_ORDERS_FAILED",

  SORT_ORDERS = "SORT_ORDERS",

  GENERATE_ORDERS="GENERATE_ORDERS",
  GENERATE_ORDERS_SUCCESS="GENERATE_ORDERS_SUCCESS",
  GENERATE_ORDERS_FAILED="GENERATE_ORDERS_FAILED",
  HANDLE_ORDER_GENERATE_DRAWER= "HANDLE_ORDER_GENERATE_DRAWER"
}