import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { UploadOutlined, DeleteOutlined } from "us.icons";
import { IUpload } from "us.collection.economy/components/ImportPayments/interface";

const { $Upload, $Typography, $Popconfirm, $Message } = Common.Components;

const { Dragger } = $Upload;
const { Text } = $Typography;

const Uploader: React.FC<IUpload> = (props) => {
  const { t } = useTranslation();

  const { fileList, onChangeFile, uploadRules } = props;
  const { maxSize, maxCount, formats } = uploadRules ?? {};

  const draggerProps = {
    beforeUpload: (file: any, list: any) => {
      const newList = list;

      if (file.size / 1024 / 1024 > maxSize) {
        $Message.error(
          t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.FILE_SIZE_EXCEEDED")
        );
      } else {
        newList.forEach((item: any) => {
          const isListMax = item.size / 1024 / 1024 > maxSize;
          if (isListMax) {
            item.status = "error";
            item.response = t(
              "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.FILE_SIZE_EXCEEDED"
            );
          }
        });
        onChangeFile(newList);
      }
      return false;
    },
    accept: formats,
    multiple: false,
    fileList,
    showUploadList: {
      showRemoveIcon: true,
      removeIcon: (file: any) => (
        <$Popconfirm
          title={t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.SURE_TO_DELETE_?")}
          data-testid="remove-pop-confirm"
          okText={t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.YES")}
          cancelText={t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.NO")}
          onConfirm={() => {
            const index = fileList.indexOf(file);
            fileList?.splice(index, 1);
            onChangeFile([...fileList]);
          }}
          placement="right"
        >
          <DeleteOutlined data-testid="remove-icon" />
        </$Popconfirm>
      ),
    },
    maxCount: maxCount,
  };

  return (
    <>
      <div
        className={
          fileList.length >= maxCount
            ? "mt-4 mb-5 upload-disable"
            : "mt-4 mb-5"
        }
      >
        <Dragger
          {...draggerProps}
          name="fileUpload"
          data-testid="upload-dragger"
          onChange={(file: any) => {
            const formData = new FormData();
            formData.append("files[]", file);
          }}
        >
          <p className="ant-upload-drag-icon mb-0 mr-3">
            <UploadOutlined />
          </p>
          <div>
            <p className="ant-upload-text">
              {t(
                "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.CLICK_OR_DRAG_FILE_TO_THIS_AREA_TO_UPLOAD"
              )}
            </p>
            <p className="ant-upload-hint">
              {t(
                "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.SUPPORT_FOR_A_SINGLE_FILE_UPLOAD"
              )}
            </p>
            <div>
              <Text type="secondary">
                {t("US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.ONLY_ALLOWED") +
                  ` ${formats} ` +
                  `(${t(
                    "US.COLLECTION.ECONOMY:IMPORT_PAYMENTS.MAXIMUM_UPLOAD_SIZE"
                  )} ${maxSize}MB)`}
              </Text>
            </div>
          </div>
        </Dragger>
      </div>
    </>
  );
};

export default Uploader;
