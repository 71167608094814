import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { $MessageBox, $Message } from "us.common/components";
import { ProvisionProfiles } from "us.collection.economy/constants/Actions";
import { i18n } from "us.helper";
import { formatOrderLinesByType } from "us.collection.economy/components/ProvisionProfiles/Functions";
import { DebtorType } from "us.collection.economy/constants";

const { provisionProfiles } = Actions;
const { profile, profiles, profileName } = API.provisionProfiles;
const { manageDrawer } = provisionProfiles.profileDetails;

export const ProvisionProfilesSagas = {
  profiles: {
    search: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(profiles.search, params);
        if (status === 200 && Array.isArray(data)) {
          yield put(provisionProfiles.profiles.success(data));
        } else {
          yield put(provisionProfiles.profiles.fail(new Error()));
        }
      } catch (error) {
        yield put(provisionProfiles.profiles.fail(new Error()));
      }
    },
    delete: function* (action: any) {
      const { profileId, searchParams, isActive } = action?.payload?.data ?? {};
      $Message.loading(
        isActive ? i18n.t("COMMON.DEACTIVATING") : i18n.t("COMMON.ACTIVATING"),
        0
      );
      try {
        const { deleteData, profilesData, profilesStatus } = yield call(
          profiles.delete,
          { profileId, searchParams }
        );
        if (
          deleteData.status === 200 &&
          profilesStatus === 200 &&
          Array.isArray(profilesData)
        ) {
          if (deleteData.data?.code == 1) {
            $MessageBox(
              "success",
              isActive
                ? "US.COLLECTION.ECONOMY:PROVISION_PROFILES.DEACTIVATE_PROVISION_PROFILE_SUCCESS"
                : "US.COLLECTION.ECONOMY:PROVISION_PROFILES.ACTIVATE_PROVISION_PROFILE_SUCCESS",
              "",
              ""
            );
            yield put(provisionProfiles.profiles.success(profilesData));
          } else if (deleteData.data?.code == 2) {
            $MessageBox(
              "error",
              isActive
                ? "US.COLLECTION.ECONOMY:PROVISION_PROFILES.THIS_PROFILE_IS_ALREADY_IN_USE"
                : "US.COLLECTION.ECONOMY:PROVISION_PROFILES.ACTIVATE_PROVISION_PROFILE_FAILED",
              "",
              ""
            );
            yield put(provisionProfiles.profiles.fail(new Error()));
          } else {
            $MessageBox(
              "error",
              isActive
                ? "US.COLLECTION.ECONOMY:PROVISION_PROFILES.DEACTIVATE_PROVISION_PROFILE_FAILED"
                : "US.COLLECTION.ECONOMY:PROVISION_PROFILES.ACTIVATE_PROVISION_PROFILE_FAILED",
              "",
              ""
            );
            yield put(provisionProfiles.profiles.fail(new Error()));
          }
        } else {
          $MessageBox(
            "error",
            isActive
              ? "US.COLLECTION.ECONOMY:PROVISION_PROFILES.DEACTIVATE_PROVISION_PROFILE_FAILED"
              : "US.COLLECTION.ECONOMY:PROVISION_PROFILES.ACTIVATE_PROVISION_PROFILE_FAILED",
            "",
            ""
          );
          yield put(provisionProfiles.profiles.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          isActive
            ? "US.COLLECTION.ECONOMY:PROVISION_PROFILES.DEACTIVATE_PROVISION_PROFILE_FAILED"
            : "US.COLLECTION.ECONOMY:PROVISION_PROFILES.ACTIVATE_PROVISION_PROFILE_FAILED",
          "",
          ""
        );
        yield put(provisionProfiles.profiles.fail(new Error()));
      }
    },
  },
  profile: {
    get: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(profiles.get, params);
        if (status === 200 && Array.isArray(data) && data.length > 0) {
          const profileData = data[0];
          const profileDetailsPerson = formatOrderLinesByType(
            profileData.profileDetails,
            DebtorType.PERSON
          );
          const profileDetailsCompany = formatOrderLinesByType(
            profileData.profileDetails,
            DebtorType.COMPANY
          );
          yield put(
            provisionProfiles.profileDetails.success({
              profileData,
              profileDetailsPerson,
              profileDetailsCompany,
            })
          );
        } else {
          yield put(provisionProfiles.profileDetails.fail(new Error()));
        }
      } catch (error) {
        yield put(provisionProfiles.profileDetails.fail(new Error()));
      }
    },
    save: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, profiles } = yield call(profile.save, params);
        if (status === 200 && profiles.status == 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:PROVISION_PROFILES.SAVE_PROVISION_PROFILE_SUCCESS",
            "",
            ""
          );
          yield put(provisionProfiles.profile.success(data));
          yield put(provisionProfiles.profiles.success(profiles.data));
          if (manageDrawer) yield put(manageDrawer({}));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:PROVISION_PROFILES.SAVE_PROVISION_PROFILE_FAILED",
            "",
            ""
          );
          yield put(provisionProfiles.profile.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:PROVISION_PROFILES.SAVE_PROVISION_PROFILE_FAILED",
          "",
          ""
        );
        yield put(provisionProfiles.profile.fail(new Error()));
      }
    },
    add: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status, profilesData } = yield call(profile.add, params);
        if (status === 200 && profilesData.status === 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:PROVISION_PROFILES.SAVE_PROVISION_PROFILE_SUCCESS",
            "",
            ""
          );
          yield put(provisionProfiles.newProfile.success(data));
          yield put(provisionProfiles.profiles.success(profilesData.data));
          if (manageDrawer) yield put(manageDrawer({}));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:PROVISION_PROFILES.SAVE_PROVISION_PROFILE_FAILED",
            "",
            ""
          );
          yield put(provisionProfiles.newProfile.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:PROVISION_PROFILES.SAVE_PROVISION_PROFILE_FAILED",
          "",
          ""
        );
        yield put(provisionProfiles.newProfile.fail(new Error()));
      }
    },
  },
  profileName: {
    validate: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(profileName.validate, params);
        if (status === 200) {
          yield put(provisionProfiles.profileName.success(data));
        } else {
          yield put(provisionProfiles.profileName.fail(new Error()));
        }
      } catch (error) {
        yield put(provisionProfiles.profileName.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(
    ProvisionProfiles.SEARCH_PROVISION_PROFILES,
    ProvisionProfilesSagas.profiles.search
  ),
  takeLatest(
    ProvisionProfiles.DELETE_PROVISION_PROFILE,
    ProvisionProfilesSagas.profiles.delete
  ),
  takeLatest(
    ProvisionProfiles.GET_PROVISION_PROFILE_DETAILS,
    ProvisionProfilesSagas.profile.get
  ),
  takeLatest(
    ProvisionProfiles.SAVE_PROVISION_PROFILE,
    ProvisionProfilesSagas.profile.save
  ),
  takeLatest(
    ProvisionProfiles.ADD_PROVISION_PROFILE,
    ProvisionProfilesSagas.profile.add
  ),
  takeLatest(
    ProvisionProfiles.VALIDATE_PROFILE_NAME,
    ProvisionProfilesSagas.profileName.validate
  ),
];
