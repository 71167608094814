import { PaymentsImportSummaryKey } from "../Constants";

/**
 * @description - Get dynamic width property value for each column by key
 * @param - Column key
 * @returns - Width (px)
 */
export const getColumnWidth = (key: PaymentsImportSummaryKey): string => {
  switch (key) {
    case PaymentsImportSummaryKey.FILE_NAME:
      return "220px";
    case PaymentsImportSummaryKey.IMPORTED_DATE:
    case PaymentsImportSummaryKey.MAPPED_PAYMENTS:
    case PaymentsImportSummaryKey.EXCEEDED_PAYMENTS:
    case PaymentsImportSummaryKey.UNKNOWN_PAYMENTS:
    case PaymentsImportSummaryKey.TOTAL_PAYMENTS:
    case PaymentsImportSummaryKey.TOTAL_ATG_RECORDS:
    case PaymentsImportSummaryKey.ATG_APPROVED:
    case PaymentsImportSummaryKey.ATG_CANCELLATION:
      return "100px";
    case PaymentsImportSummaryKey.MAPPED_PAYMENTS_AMOUNT:
    case PaymentsImportSummaryKey.EXCEEDED_PAYMENTS_AMOUNT:
    case PaymentsImportSummaryKey.UNKNOWN_PAYMENTS_AMOUNT:
    case PaymentsImportSummaryKey.TOTAL_PAYMENTS_AMOUNT:
      return "160px";
    default:
      return "160px";
  }
};
