import React from 'react';
import { $AmountLabel } from 'us.common/components';

interface Props {
	amount?: number | undefined;
	label?: string | JSX.Element;
}

const LabelsBar: React.FC<Props> = ({ amount, label }) => {
	return (
		<>
			<span>{label}</span>
			<span>{amount && <$AmountLabel value={amount} />}</span>
		</>
	);
};

export default LabelsBar;
