import React from "react";
import Common from "us.common";
import * as Actions from "us.collection.economy/actions";
import { OrderLineProperties } from "us.collection.economy/components/ManualOrders/Constants";
import { IArticleDescription } from "us.collection.economy/components/ManualOrders/Interface";

import { connect } from "react-redux";
import { useField } from "formik";

const { $InputWithValue, $Popover, $TextArea } = Common.Components;
const { orderTableData } = Actions.manualOrders;

const ArticleDescription: React.FC<IArticleDescription> = (props) => {
  const { name, tabIndex, orderline, currentUser, updateOrderTable } = props;

  const {
    key,
    description,
    caseNo,
    isValidCaseNo,
    isManualOrder,
    isValidArticle,
  } = orderline ?? {};

  const [visibleIdField, , visibleIdFieldHelpers] = useField(
    "visibleDescriptionId"
  );
  const descriptionInputRef = React.useRef<any>();
  const isDisabled =
    (caseNo != "" && !isValidCaseNo) ||
    !isManualOrder ||
    isValidArticle === false;

  return (
    <>
      <div onClick={() => isDisabled && visibleIdFieldHelpers.setValue(name)}>
        <$Popover
          content={
            <$TextArea
              value={description}
              ref={descriptionInputRef}
              tabIndex={tabIndex}
              style={{ width: "250px" }}
              name={name}
              autoSize={{ minRows: 2, maxRows: 5 }}
              disabled={isDisabled}
              onBlur={() => visibleIdFieldHelpers.setValue("")}
              onChange={(e: any) => {
                updateOrderTable &&
                  updateOrderTable({
                    key: key,
                    value: e.target.value,
                    type: OrderLineProperties.DESCRIPTION,
                    user: currentUser?.upn,
                  });
              }}
            />
          }
          visible={visibleIdField.value == name}
          trigger="click"
          onVisibleChange={(visible: boolean) =>
            visible
              ? visibleIdFieldHelpers.setValue(name)
              : visibleIdFieldHelpers.setValue("")
          }
        >
          <$InputWithValue
            name={name + "Main"}
            size="small"
            className="w-100"
            value={description}
            tabIndex={tabIndex}
            onClick={() => visibleIdFieldHelpers.setValue(name)}
            onFocus={() => {
              visibleIdFieldHelpers.setValue(name);
              setTimeout(() => descriptionInputRef?.current?.focus(), 100);
            }}
            disabled={isDisabled || visibleIdField.value == name}
          />
        </$Popover>
      </div>
    </>
  );
};
const mapStateToProps = (state: any) => {
  const { manualOrders } = state;
  const { orderTableData } = manualOrders;

  return {
    orderTableData,
  };
};

const mapDispatchToProps = {
  updateOrderTable: orderTableData.update,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticleDescription);
