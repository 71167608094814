import { useField } from "formik";
import React from "react";
import { $Switch } from "us.common/components";

interface IStatusSwitch {
  name: string;
  label: string;
  disabled?: boolean;
  onChange: <D>(status: D) => void;
}

// custom component that handle with useField
const StatusSwitch: React.FC<IStatusSwitch> = ({
  name,
  disabled,
  label,
  onChange,
}) => {
  const [field, meta, helpers] = useField(name);

  return (
    <div
      className="d-flex align-items-center justify-content-between mb-1"
      data-testid={label}
    >
      <label>{label}</label>
      <$Switch
        {...field}
        size="small"
        name={name}
        checked={meta.value}
        onChange={(checked: boolean) => {
          helpers.setValue(checked);
          onChange({ [name]: checked });
        }}
        disabled={disabled}
      />
    </div>
  );
};

export default StatusSwitch;
