import { IClientInvoicesActions } from "us.collection.economy/interfaces";
import { ClientInvoices } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

const {
  RESET_INVOICE_LIST,

  SEARCH_CLIENT_INVOICES,
  SEARCH_CLIENT_INVOICES_SUCCESS,
  SEARCH_CLIENT_INVOICES_FAILED,

  SORT_INVOICES,
  FILTER_INVOICES,

  GET_CREDITOR_INVOICE_DETAILS_START,
  GET_CREDITOR_INVOICE_DETAILS_SUCCESS,
  GET_CREDITOR_INVOICE_DETAILS_FAIL,
  RESET_CREDITOR_INVOICE_DETAILS,
  SET_DRAWER_VISIBLE_STATUS,

  SAVE_CREDIT_NOTE_START,
  SAVE_CREDIT_NOTE_SUCCESS,
  SAVE_CREDIT_NOTE_FAIL,

  EXPORT_CREDIT_INVOICE,
  EXPORT_CREDIT_INVOICE_SUCCESS,
  EXPORT_CREDIT_INVOICE_FAIL,

  ON_TAB_CHANGED,

  EXPORT_INVOICES,
  EXPORT_INVOICES_SUCCESS,
  EXPORT_INVOICES_FAILED,

  DOWNLOAD_NAVISION_FILE,
  DOWNLOAD_NAVISION_FILE_SUCCESS,
  DOWNLOAD_NAVISION_FILE_FAIL,

  REFRESH_CLIENT_INVOICES,
} = ClientInvoices;

export const clientInvoices: Readonly<IAPIAction & IClientInvoicesActions> = {
  invoices: {
    resetInvoiceList: (data) => ({
      type: RESET_INVOICE_LIST,
      payload: data,
    }),
    search: (data) => ({
      type: SEARCH_CLIENT_INVOICES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEARCH_CLIENT_INVOICES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEARCH_CLIENT_INVOICES_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
    sort: (data) => ({
      type: SORT_INVOICES,
      payload: data,
    }),
    filter: (data) => ({
      type: FILTER_INVOICES,
      payload: data,
    }),
    onChangeTab: (data) => ({
      type: ON_TAB_CHANGED,
      payload: data,
    }),
    refresh: (data) => ({
      type: REFRESH_CLIENT_INVOICES,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  exportInvoice: {
    save: (data) => ({
      type: EXPORT_INVOICES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: EXPORT_INVOICES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: EXPORT_INVOICES_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  creditNoteDetails: {
    getDetails: (data, isOpenDrawer) => ({
      type: GET_CREDITOR_INVOICE_DETAILS_START,
      payload: {
        data,
        isOpenDrawer,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_CREDITOR_INVOICE_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_CREDITOR_INVOICE_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
    reset: (data) => ({
      type: RESET_CREDITOR_INVOICE_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    handleDrawer: (data) => ({
      type: SET_DRAWER_VISIBLE_STATUS,
      payload: {
        data,
        isLoading: true,
      },
    }),
  },
  creditNote: {
    save: (data) => ({
      type: SAVE_CREDIT_NOTE_START,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SAVE_CREDIT_NOTE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SAVE_CREDIT_NOTE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  creditNoteExport: {
    save: (data) => ({
      type: EXPORT_CREDIT_INVOICE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: EXPORT_CREDIT_INVOICE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: EXPORT_CREDIT_INVOICE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  navisionFile: {
    download: (data) => ({
      type: DOWNLOAD_NAVISION_FILE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DOWNLOAD_NAVISION_FILE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DOWNLOAD_NAVISION_FILE_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
