import { IValidateCreditor } from "../Interface";

export function ValidateCreditor(this: any): IValidateCreditor {
    const { vatCode, units, unitPrice } = this.orderTableData
    return { 
        creditorId: this.creditorId, 
        vatCode, 
        amount: units * unitPrice,
    };
}
