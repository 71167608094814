import {
  ISaveProvisionProfileParams,
  ISearchProvisionProfilesParams,
} from "us.collection.economy/interfaces";
import { httpCollection } from "us.helper/http/collection";

export default {
  profiles: {
    search: (params: ISearchProvisionProfilesParams): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "economysettingservice",
            "provisionprofile/search",
            params
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    get: ({ profileId }: { profileId?: number }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "economysettingservice",
            `provisionprofiles${profileId ? `?profileId=${profileId}` : ""}`,
            {}
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
    delete: ({
      profileId,
      searchParams,
    }: {
      profileId: number;
      searchParams: ISearchProvisionProfilesParams;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const deleteData = await httpCollection.delete(
            "economysettingservice",
            `provisionprofile`,
            profileId
          );
          const profilesData = await httpCollection.post(
            "economysettingservice",
            "provisionprofile/search",
            searchParams
          );
          resolve({
            deleteData,
            profilesData: profilesData.data,
            profilesStatus: profilesData.status,
          });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  profile: {
    save: ({
      saveParams,
      searchParams,
    }: {
      saveParams: ISaveProvisionProfileParams;
      searchParams: ISearchProvisionProfilesParams;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.put(
            "economysettingservice",
            "provisionprofile",
            saveParams
          );
          const profiles = await httpCollection.post(
            "economysettingservice",
            "provisionprofile/search",
            searchParams
          );
          resolve({ data, status, profiles });
        } catch (error) {
          reject(error);
        }
      });
    },
    add: ({
      saveParams,
      searchParams,
    }: {
      saveParams: ISaveProvisionProfileParams;
      searchParams: ISearchProvisionProfilesParams;
    }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.post(
            "economysettingservice",
            "provisionprofile",
            saveParams
          );
          const profilesData = await httpCollection.post(
            "economysettingservice",
            "provisionprofile/search",
            searchParams
          );
          resolve({ data, status, profilesData });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
  profileName: {
    validate: ({ profileName }: { profileName: string }): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "economysettingservice",
            `provisionprofile/validate/${profileName}`,
            {}
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
