import { IEditPayment } from "../interface";
import { PaymentHelper } from "us.collection.economy/components/ManagePayments/Functions";

export function SaveEditPayment(this: IEditPayment) {
  return {
    paymentId: this.paymentId,
    voucherDate: this.voucherDate,
    paymentAmount: this.paymentAmount,
    caseNo: this.caseNo,
    paymentStatus: this.paymentStatus,
    paymentArItemNo: this.paymentArItemNo,
  };
}
