import React from "react";
import { useTranslation } from "react-i18next";
import "us.collection.economy/components/ClientInvoices/Home.scss";
import { BoxIcons, DownloadOutlined, IconTypes } from "us.icons";
import { InvoiceAction } from "us.collection.economy/components/ClientInvoices/Constants";
import { IInvoiceAction } from "us.collection.economy/components/ClientInvoices/Interfaces";

interface IItemMenu {
  actions: IInvoiceAction[];
  onCallAction: (actionType: IInvoiceAction) => void;
}

const ItemMenu: React.FC<IItemMenu> = ({
  actions = [InvoiceAction.PRINT],
  onCallAction,
}) => {
  const { t } = useTranslation();

  return (
    <div className="table-more-content">
      {actions.includes(InvoiceAction.CREDIT_NOTE) && (
        <div
          className="d-flex  flex-row more-item"
          onClick={() => onCallAction(InvoiceAction.CREDIT_NOTE)}
        >
          <div className="p-1">
            <BoxIcons type={IconTypes.BOX_ICON} name="credit-invoice" />
          </div>
          <div className="p-1">
            {t("US.COLLECTION.ECONOMY:INVOICES.CREDIT_INVOICE")}
          </div>
        </div>
      )}
      {actions.includes(InvoiceAction.PRINT) && (
        <div
          className="d-flex  flex-row more-item"
          onClick={() => onCallAction(InvoiceAction.PRINT)}
        >
          <div className="p-1">
            <BoxIcons type={IconTypes.BOX_ICON} name="print" />
          </div>
          <div className="p-1">{t("US.COLLECTION.ECONOMY:INVOICES.PRINT")}</div>
        </div>
      )}
      {actions.includes(InvoiceAction.DOWNLOAD) && (
        <div
          className="d-flex  flex-row more-item"
          onClick={() => onCallAction(InvoiceAction.DOWNLOAD)}
        >
          <div className="p-1">
            <DownloadOutlined style={{ fontSize: "1rem" }} />
          </div>
          <div className="p-1">
            {t("US.COLLECTION.ECONOMY:INVOICES.DOWNLOAD")}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemMenu;
