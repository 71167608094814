import {
  initialState,
  initialDrawerValues,
} from "us.collection.economy/reducers/Articles/State";
import { Articles } from "us.collection.economy/constants/Actions";

const {
  CHANGE_DRAWER_INFO,
  GET_ARTICLES,
  GET_ARTICLES_SUCCESS,
  GET_ARTICLES_FAIL,
  GET_ARTICLE_PROPERTIES,
  GET_ARTICLE_PROPERTIES_SUCCESS,
  GET_ARTICLE_PROPERTIES_FAIL,
  SAVE_ARTICLE,
  SAVE_ARTICLE_SUCCESS,
  SAVE_ARTICLE_FAIL,
  CHECK_DUPLICATE_ARTICLE_NO,
  CHECK_DUPLICATE_ARTICLE_NO_SUCCESS,
  CHECK_DUPLICATE_ARTICLE_NO_FAIL,
  RESET_DUPLICATE_ARTICLE_NO_STATUS,
  DELETE_ARTICLE_SUCCESS,
} = Articles;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case CHANGE_DRAWER_INFO:
      return Object.assign({}, state, {
        drawerInfo: payload.data,
        isDuplicateArticleNo: false,
      });
    case GET_ARTICLES:
      return Object.assign({}, state, {
        articleList: state.articleList,
        isFetching: true,
        isLoadingArticleData: true
      });
    case GET_ARTICLES_SUCCESS:
      const formattedArticles = payload?.data?.map((acticle: any) => ({
        ...acticle,
        vatCodeName: acticle.vatCode + "-" + acticle.vatCodeName
      }));
      return Object.assign({}, state, {
        articleList: formattedArticles,
        isFetching: false,
        isLoadingArticleData: false,
      });
    case GET_ARTICLES_FAIL:
      return Object.assign({}, state, {
        ...state.articleList,
        isFetching: false,
        isLoadingArticleData: false
      });
    case GET_ARTICLE_PROPERTIES:
      return Object.assign({}, state, {
        ...state,
        isFetching: true,
      });
    case GET_ARTICLE_PROPERTIES_SUCCESS:
      const { vatCodeList, categoryList, ledgerAccountList } = payload?.data;
      const formattedPayload = vatCodeList?.map((vat: any) => ({
        ...vat,
        vatCodeName: vat.vatcode + "-" + vat.vatCodeName
      }));
      return Object.assign({}, state, {
        vatCodes: formattedPayload,
        categories: categoryList,
        ledgerAccounts: ledgerAccountList,
        isFetching: false,
      });
    case GET_ARTICLE_PROPERTIES_SUCCESS:
      return Object.assign({}, state, {
        ...state,
        isFetching: false,
      });
    case SAVE_ARTICLE:
      return Object.assign({}, state, {
        isSavingArticleData: true,
      });
    case SAVE_ARTICLE_SUCCESS:
      return Object.assign({}, state, {
        drawerInfo: initialDrawerValues,
        isSavingArticleData: false,
        isLoadingArticleData: true
      });
    case SAVE_ARTICLE_FAIL:
      return Object.assign({}, state, {
        ...state,
        isSavingArticleData: false,
      });
    case CHECK_DUPLICATE_ARTICLE_NO:
      return Object.assign({}, state, {
        isDuplicateArticleNo: false,
      });
    case CHECK_DUPLICATE_ARTICLE_NO_SUCCESS:
      return Object.assign({}, state, {
        isDuplicateArticleNo: payload?.data,
        isFetching: false,
      });
    case CHECK_DUPLICATE_ARTICLE_NO_FAIL:
      return Object.assign({}, state, {
        isDuplicateArticleNo: false,
        isFetching: false,
      });
    case RESET_DUPLICATE_ARTICLE_NO_STATUS:
      return Object.assign({}, state, {
        isDuplicateArticleNo: false,
      });
    case DELETE_ARTICLE_SUCCESS:
      return Object.assign({}, state, {
        isLoadingArticleData: true,
      });
    default:
      return state;
  }
};
