import { take, put, call, select, takeLatest, all } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { Articles } from "us.collection.economy/constants/Actions";
import Common from "us.common";
import { useDispatch, useSelector } from "react-redux";

const { $MessageBox } = Common.Components;
const { articles } = Actions;
const {
  getArticlesSuccess,
  getArticlesFail,
  deleteArticleSuccess,
  deleteArticleFail,
  saveArticleSuccess,
  saveArticleFail,
  checkDuplicateArticleNoSuccess,
  checkDuplicateArticleNoFail,
  getArticlePropertiesSuccess,
  getArticlePropertiesFail
} = articles;
const {
  getArticles,
  getVatCodes,
  getCategories,
  getLedgerAccounts,
  saveArticleData,
  updateArticleData,
  deleteArticleData,
  checkDuplicateArticleNumber,
} = API.articles.articleList;

const getExistingArticleNo = (state:any) => {
  return state.articles.articleList
} 

const ArticleSagas = {
  article: {
    getArticles: function* (action: any): any {
      try {
        const articleList = yield call(getArticles);
        yield put(getArticlesSuccess(articleList));
      } catch (error) {
        yield put(getArticlesFail(error));
      }
    },
    getArticleProperties: function* (action: any): any {
      try {
        const [vatCodeList,categoryList,ledgerAccountList] = yield all ([call(getVatCodes),call(getCategories),call(getLedgerAccounts)]);
        const payload = {
          vatCodeList,categoryList,ledgerAccountList
        }
        yield put(getArticlePropertiesSuccess(payload));
      } catch (error) {
        yield put(getArticlePropertiesFail(error));
      }
    },
    deleteArticle: function* (action: any) {
      const articleId = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(deleteArticleData, articleId);
        yield put(deleteArticleSuccess(data));
        const articleList = yield call(getArticles);
        yield put(getArticlesSuccess(articleList));

        if (status == 200) {
          $MessageBox(
            "success",
            `US.COLLECTION.VALIDATIONS:REQUIRED.SUCCESSFULLY_DELETE_THE_ARTICLE`,
            "",
            ""
          );
        } else if (status == 412) {
          $MessageBox(
            "error",
            `US.COLLECTION.VALIDATIONS:REQUIRED.ARTICLE_USED_IN_ORDERLINES`,
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            `US.COLLECTION.VALIDATIONS:REQUIRED.ARTICLE_DELETE_FAIL`,
            "",
            ""
          );
        }
      } catch (error) {
        yield put(deleteArticleFail(error));
        $MessageBox(
          "error",
          `US.COLLECTION.VALIDATIONS:REQUIRED.ARTICLE_DELETE_FAIL`,
          "",
          ""
        );
      }
    },
    saveArticle: function* (action: any) {
      const params = action?.payload?.data ?? {};
      const { isEdit, updatePayload, savePayload } = params;
      try {
        const articleListInStore = yield select(getExistingArticleNo);
        const payloadArticle = isEdit ? updatePayload : savePayload
        let isDuplicate = false
        const editedArticle = isEdit ? articleListInStore.filter((ar:any) => ar.articleId === updatePayload.articleId) : []

        if(editedArticle.length === 0 || (isEdit && editedArticle[0].articleNo.toUpperCase() !== updatePayload.articleNo.toUpperCase())) {
          isDuplicate = yield call(checkDuplicateArticleNumber, payloadArticle.articleNo);
          yield put(checkDuplicateArticleNoSuccess(isDuplicate));
        }
        if(!isDuplicate){
          if (isEdit) {
            const { data, status } = yield call(
              updateArticleData,
              updatePayload
            );
            yield put(saveArticleSuccess(data));
            const articleList = yield call(getArticles);
            yield put(getArticlesSuccess(articleList));
            if (status == 200) {
              $MessageBox(
                "success",
                `US.COLLECTION.VALIDATIONS:REQUIRED.SUCCESSFULLY_UPDATE_THE_ARTICLE`,
                "",
                ""
              );
            } else {
              $MessageBox(
                "error",
                `US.COLLECTION.VALIDATIONS:REQUIRED.ARTICLE_UPDATE_FAIL`,
                "",
                ""
              );
            }
        } else {
            const { data, status } = yield call(
              saveArticleData,
              savePayload
            );
            yield put(saveArticleSuccess(data));
            const articleList = yield call(getArticles);
            yield put(getArticlesSuccess(articleList));
            if (status == 200) {
              $MessageBox(
                "success",
                `US.COLLECTION.VALIDATIONS:REQUIRED.SUCCESSFULLY_SAVE_THE_ARTICLE`,
                "",
                ""
              );
            } else if (status == 400) {
              $MessageBox(
                "error",
                `US.COLLECTION.VALIDATIONS:REQUIRED.ARTICLE_SAVE_FAIL`,
                "",
                ""
              );
            } else if (status == 409) {
              $MessageBox(
                "error",
                `US.COLLECTION.VALIDATIONS:REQUIRED.ARTICLE_NO_ALREADY_EXISTS`,
                "",
                ""
              );
            } else if (status == 401) {
              $MessageBox(
                "error",
                `US.COLLECTION.VALIDATIONS:REQUIRED.UNAUTHORIZED_ACCESS`,
                "",
                ""
              );
            } else if (status == 403) {
              $MessageBox(
                "error",
                `US.COLLECTION.VALIDATIONS:REQUIRED.YOU_DONT_HAVE_ACCESS_TO_THIS_INFORMATION`,
                "",
                ""
              );
            }
        }
        } else{
          yield put(saveArticleFail([]));
        }
      } catch (error) {
        yield put(saveArticleFail(error));
        $MessageBox(
          "error",
          `US.COLLECTION.VALIDATIONS:REQUIRED.ARTICLE_${isEdit ? 'UPDATE' : 'SAVE'}_FAIL`,
          "",
          ""
        );
      }
    },
    checkDuplicateArticleNo: function* (action: any) {
      const articleNo = action?.payload?.data ?? '';
      try {
        if(articleNo.trim() != ''){
          const data = yield call(checkDuplicateArticleNumber, articleNo);
          yield put(checkDuplicateArticleNoSuccess(data));
        }
      } catch (error) {
        yield put(checkDuplicateArticleNoFail(error));
      }
    },
  },
};

export default [
  takeLatest(Articles.GET_ARTICLES, ArticleSagas.article.getArticles),
  takeLatest(Articles.GET_ARTICLE_PROPERTIES, ArticleSagas.article.getArticleProperties),
  takeLatest(Articles.DELETE_ARTICLE, ArticleSagas.article.deleteArticle),
  takeLatest(Articles.SAVE_ARTICLE, ArticleSagas.article.saveArticle),
  takeLatest(
    Articles.CHECK_DUPLICATE_ARTICLE_NO,
    ArticleSagas.article.checkDuplicateArticleNo
  ),
];
