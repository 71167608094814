import React from "react";
import { useTranslation } from "react-i18next";
import {
  $Tag,
  $AmountLabel,
  $DateLabel,
  $Tooltip,
  $Button,
} from "us.common/components";
import { IPaymentStates } from "us.collection.economy/components/ManagePayments/interface";
import { PaymentStates } from "us.collection.economy/constants";
import { PaymentHelper } from "us.collection.economy/components/ManagePayments/Functions";
import { BoxIcons, IconTypes } from "us.icons";
import { getRouteUrl } from "us.helper";

interface Props {
  data: any;
  priorityTag: IPaymentStates;
  onViewLinkedPayment: () => void;
}

const BaseHeader: React.FC<Props> = ({
  data,
  priorityTag,
  onViewLinkedPayment,
}) => {
  const { t } = useTranslation();
  const {
    paymentId,
    paymentAmount,
    returnedToDebtorSum,
    mappedSum,
    exceededSum,
    regDate,
    voucherDate,
    kid,
    pid,
    cid,
    voucherNo,
    caseNo,
    debtorName,
    creditorName,
    linkedPaymentId,
    caseType,
    caseId,
  } = data;
  const { amount, name, tagClass } = PaymentHelper.getPriorityStatusData(
    data,
    priorityTag
  );
  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center">
        <div className="text-primary font-weight-bold">
          {`${t(
            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_ID"
          )}: ${paymentId}`}
          <span className="pl-3" data-testid="payment-amount">
            ({`${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.AMOUNT")}: `}
            <$AmountLabel value={paymentAmount} />)
          </span>
        </div>
        <div className="d-flex align-items-end">
          {!isNaN(linkedPaymentId) && linkedPaymentId !== 0 && (
            <$Tooltip
              title={
                <div className="d-flex">
                  {`${t(
                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.THIS_PAYMENT_LINKED_WITH"
                  )} : ${linkedPaymentId}`}
                </div>
              }
            >
              <$Button
                size="small"
                type="link"
                onClick={onViewLinkedPayment}
                icon={<BoxIcons type={IconTypes.BOX_ICON} name="linked" />}
              />
            </$Tooltip>
          )}
          <div className="header-tag d-flex align-items-end ml-2">
            {returnedToDebtorSum > 0 && priorityTag !== PaymentStates.RETURNED && (
              <$Tooltip
                title={
                  <div
                    className="d-flex justify-content-between"
                    data-testid="tooltip-return"
                  >
                    <span className="mr-5">
                      {t(
                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR"
                      )}
                    </span>
                    <span className="map-item-tag-amount">
                      <$AmountLabel value={returnedToDebtorSum} />
                    </span>
                  </div>
                }
              >
                <$Tag className="tag-status-returntodebtor header-tag-small" />
              </$Tooltip>
            )}
            {mappedSum > 0 && priorityTag !== PaymentStates.MAPPED && (
              <$Tooltip
                title={
                  <div
                    className="d-flex justify-content-between"
                    data-testid="tooltip-mapped"
                  >
                    <span className="mr-5">
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MAPPED")}
                    </span>
                    <span className="map-item-tag-amount">
                      <$AmountLabel value={mappedSum} />
                    </span>
                  </div>
                }
              >
                <$Tag className="tag-status-mapped header-tag-small" />
              </$Tooltip>
            )}
            {exceededSum > 0 && priorityTag !== PaymentStates.EXCEEDED && (
              <$Tooltip
                title={
                  <div
                    className="d-flex justify-content-between"
                    data-testid="tooltip-exceeded"
                  >
                    <span className="mr-5">
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED")}
                    </span>
                    <span className="map-item-tag-amount">
                      <$AmountLabel value={exceededSum} />
                    </span>
                  </div>
                }
              >
                <$Tag className="tag-status-exceeded header-tag-small" />
              </$Tooltip>
            )}
            <$Tag className={tagClass}>
              <div
                className="d-flex justify-content-between"
                data-testid="main-tag-amount"
              >
                <span className="mr-3">
                  {`${t(name)} ${
                    priorityTag === PaymentStates.UNKNOWN && linkedPaymentId > 0
                      ? `- ${t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RESOLVED"
                        )}`
                      : ""
                  }`}
                </span>
                {!(
                  priorityTag === PaymentStates.UNKNOWN && linkedPaymentId > 0
                ) && (
                  <span className="map-item-tag-amount">
                    <$AmountLabel value={amount} />
                  </span>
                )}
              </div>
            </$Tag>
          </div>
        </div>
      </div>
      <div className=" map-item-font-sm mt-1" data-testid="date-case-details">
        {`${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.REGISTERED_DATE")}: `}
        <$DateLabel value={regDate} />
        {` | ${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VOUCHER")} : ${
          voucherNo && voucherNo > 0
            ? voucherNo
            : t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.NOT_AVAILABLE")
        }
                    - `}
        <$DateLabel value={voucherDate} />
        {" | "}
        <span>
          {`  ${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NO")}: `}

          {caseNo && caseNo > 0 && (
            <a
              href={"javascript:void(0)"}
              onClick={() => getRouteUrl.caseType(caseNo)}
            >
              {caseNo}
            </a>
          )}
          {!caseNo ||
            isNaN(caseNo) ||
            (!(caseNo > 0) &&
              t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.NOT_AVAILABLE"))}
        </span>
      </div>
      <div className="text-muted map-item-font-sm">
        {kid && `${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.KID")}: ${kid}`}
        {kid && (cid || debtorName) && " | "}
        <span data-testid="debtor-details">
          {cid
            ? debtorName
              ? `${t("US.COLLECTION.COMMON:COMMON.DEBTOR")} : ${cid ?? ""}${
                  debtorName ? " - " + debtorName : ""
                } | `
              : `${t("US.COLLECTION.COMMON:COMMON.DEBTOR")} : ${
                  cid ? cid + " | " : ""
                }`
            : debtorName
            ? `${t("US.COLLECTION.COMMON:COMMON.DEBTOR")} : ${
                debtorName ? debtorName + " | " : ""
              }`
            : ""}
        </span>
        <span data-testid="creditor-details">
          {pid
            ? creditorName
              ? `${t("US.COLLECTION.COMMON:COMMON.CREDITOR")} : ${pid ?? ""}${
                  creditorName ? " - " + creditorName : ""
                }`
              : `${t("US.COLLECTION.COMMON:COMMON.CREDITOR")} : ${pid ?? ""}`
            : creditorName
            ? `${t("US.COLLECTION.COMMON:COMMON.CREDITOR")} : ${
                creditorName ? " - " + creditorName : ""
              }`
            : ""}
        </span>
      </div>
    </div>
  );
};

export default BaseHeader;
