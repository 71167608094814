import React from "react";
import { useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import {
  TransactionTypes,
  FeeType,
} from "us.collection.economy/components/ItemTypes/Constants";
import { IDistributionFactor } from "us.collection.economy/interfaces/ItemTypes";
import { $Row, $Col, $InputAmount, $Switch } from "us.common/components";

/**
 * @description - Fraction payments among the bureau and creditor.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2992242726/Manage+Item+Types+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 23/06/2022
 */
const DistributionFactors: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { currentLanguage, currentCurrency } = props;

  const { values, setFieldValue, setFieldTouched } = useFormikContext<any>();

  return (
    <>
      {values?.transactionType === TransactionTypes.FEES && (
        <$Row gutter={[16, 16]} className="mb-2" equal-heights>
          {values?.distributionFactors?.map(
            (distributionFactor: IDistributionFactor, index: number) => (
              <$Col flex={12} key={index}>
                <div className="gray-round " style={{ height: "100%" }}>
                  <div className="font-weight-bold">
                    {distributionFactor.feeType == FeeType.AMOUNT
                      ? t("US.COLLECTION.ECONOMY:ITEM_TYPE.AMOUNT_BELONGS_TO")
                      : t(
                          "US.COLLECTION.ECONOMY:ITEM_TYPE.INTEREST_BELONGS_TO"
                        )}
                  </div>
                  <div className="d-flex">
                    <div className="mr-2">
                      <$InputAmount
                        currentLanguage={currentLanguage}
                        currentCurrency={currentCurrency}
                        label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.CREDITOR")}
                        placeholder="0,00"
                        size="small"
                        name={`distributionFactors[${index}].creditorFraction`}
                        value={distributionFactor.creditorFraction}
                        onChange={(amount: any) => {
                          setFieldTouched(
                            `distributionFactors[${index}].bureauFraction`,
                            false
                          );
                          setFieldValue(
                            `distributionFactors[${index}].creditorFraction`,
                            amount
                          );
                        }}
                        onBlur={() =>
                          setFieldTouched(
                            `distributionFactors[${index}].bureauFraction`,
                            false
                          )
                        }
                      />
                    </div>
                    <div className="mr-2">
                      <$InputAmount
                        currentLanguage={currentLanguage}
                        currentCurrency={currentCurrency}
                        label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.BUREAU")}
                        placeholder="0,00"
                        size="small"
                        name={`distributionFactors[${index}].bureauFraction`}
                        value={distributionFactor.bureauFraction}
                        onChange={(amount: any) => {
                          setFieldTouched(
                            `distributionFactors[${index}].creditorFraction`,
                            false
                          );
                          setFieldValue(
                            `distributionFactors[${index}].bureauFraction`,
                            amount
                          );
                        }}
                        onBlur={() =>
                          setFieldTouched(
                            `distributionFactors[${index}].creditorFraction`,
                            false
                          )
                        }
                      />
                    </div>

                    <div className="d-flex align-items-center">
                      <div className="d-flex align-items-center mt-2">
                        <$Switch
                          name={`distributionFactors[${index}].isVAT`}
                          size="small"
                          className="mr-1"
                          disabled={distributionFactor.bureauFraction == 0}
                          checked={distributionFactor.isVAT}
                          onChange={(isChecked: boolean) => {
                            setFieldValue(
                              `distributionFactors[${index}].isVAT`,
                              isChecked
                            );

                            isChecked &&
                              setFieldValue("isVatLiable", true, false);
                          }}
                        />
                        <label>
                          {t("US.COLLECTION.ECONOMY:ITEM_TYPE.VAT")}
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </$Col>
            )
          )}
        </$Row>
      )}
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { itemType, common } = state;
  const { itemTypeDetails } = itemType;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  return {
    itemTypeDetails,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DistributionFactors);
