import { IAddNewApportionment } from "../Interfaces";
import { ItemTypeIds, TransactionTexts, TransTypes, GroupTextTypes } from "../Constant";
import moment from "moment";

export function AddApportionmentReturnToDebtor(this: IAddNewApportionment) {
  return {
    amount: this.amountValue,
    amountBureuVat: 0,
    amountCreditorVat: 0,
    accountNo: this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? this.itemsInfo.accountNo : '',
    apportionId: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? 0 : 0,
    arId: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.arId : 0,
    bureauAmount: this.typeId == ItemTypeIds.RETURN_FEE || this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.amountValue : 0,
    caseId: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.caseId : 0,
    caseNo: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.caseNo : "",
    caseType: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.caseType : "",
    itemIndex: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.itemIndex : 1,
    parentIndex: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.parentIndex : 1,
    childIndex: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.childIndex + 1 : 0,
    creditorAmount: 0,
    debtorAmount: this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? this.amountValue : 0,
    exceedAmount: 0,
    groupText: this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? GroupTextTypes.RETURN_TO_DEBTOR : this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? '' : '',
    invoiceNo: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.invoiceNo : '',
    isInterest: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.isInterest : false,
    isRemitted: false,
    itemTypeId: this.typeId,
    journalId: "",
    key: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.paymentArItemNo + `${this.mainPayment.childIndex + 1}` : 'ch - '+ `${this.itemsInfo.itemsLength + 1}`,
    modifiedDate: moment(),
    modifiedUser: this.mainPayment.currentUser.upn,
    otherAmount: 0,
    paymentAmount: this.mainPayment.paymentAmount,
    paymentArItemNo: 0,
    paymentId: this.mainPayment.paymentId,
    transactionProfileNo: 0,
    ref: null,
    remitVoucherNo: 0,
    subcaseId: 0,
    text: this.text,
    transType: this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? TransTypes.RETURN_TO_DEBTOR : this.typeId == ItemTypeIds.MAIN_AMOUNT ? TransTypes.MAIN_AMOUNT : this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? TransTypes.MAIN_AMOUNT_INTEREST : '',
    transactionArItemNo: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.transactionArItemNo : 0,
    transactionBalance: 0,
    transactionGroupId: "0",
    transactionText: this.typeId == ItemTypeIds.EXCEEDED_AMOUNT ? TransactionTexts.EXCEEDED_AMOUNT : this.typeId == ItemTypeIds.RETURN_FEE ? TransactionTexts.RETURN_FEE : this.typeId == ItemTypeIds.RETURN_TO_DEBTOR ? TransactionTexts.RETURN_TO_DEBTOR : TransactionTexts.PROVISION,
    vatAmount: 0,
    vatBasis: 0,
    vatCode: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.vatCode : 0,
    vatDeducted: 0,
    vatLiable: true,
    vatPaidByDebtor: 0,
    vatRate: 25,
    vatStatus: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST ? this.mainPayment.vatStatus : '',
    isProvision: this.typeId == ItemTypeIds.MAIN_AMOUNT || this.typeId == ItemTypeIds.MAIN_AMOUNT_INTEREST,
    isNew: true
  }
}
