export enum ItemTypeTableColumn {
  MENU = "menu",
  ITEM_TYPE_ID = "itemTypeId",
  ITEM_TYPE_NAME = "itemTypeName",
  TRANSACTION_GROUP = "transactionGroupName",
  TRANSACTION_CODE = "transactionCode",
  DESCRIPTION = "description",
  BANK_ACCOUNT_NO = "bankAccountNumber",
  LEDGER_ACCOUNT_NO = "ledgerAccountNumber",
  MODIFIED_DATE = "modifiedDate",
  MODIFIED_USER = "modifiedUser",
}

export const COLUMNS = [
  {
    key: ItemTypeTableColumn.MENU,
    title: "",
    width: "40px",
  },
  {
    key: ItemTypeTableColumn.ITEM_TYPE_ID,
    title: "US.COLLECTION.ECONOMY:ITEM_TYPE.ID",
    width: "100px",
  },
  {
    key: ItemTypeTableColumn.DESCRIPTION,
    title: "US.COLLECTION.ECONOMY:ITEM_TYPE.DESCRIPTION",
    width: "200px",
  },
  {
    key: ItemTypeTableColumn.TRANSACTION_GROUP,
    title: "US.COLLECTION.ECONOMY:ITEM_TYPE.TRANSACTION_GROUP",
    width: "180px",
  },
  {
    key: ItemTypeTableColumn.TRANSACTION_CODE,
    title: "US.COLLECTION.ECONOMY:ITEM_TYPE.TRANSACTION_CODE",
    width: "180px",
  },
  {
    key: ItemTypeTableColumn.BANK_ACCOUNT_NO,
    title: "US.COLLECTION.ECONOMY:ITEM_TYPE.BANK_ACCOUNT_NO",
    width: "180px",
  },
  {
    key: ItemTypeTableColumn.LEDGER_ACCOUNT_NO,
    title: "US.COLLECTION.ECONOMY:ITEM_TYPE.LEDGER_ACCOUNT_NO",
    width: "180px",
  },
  {
    key: ItemTypeTableColumn.MODIFIED_DATE,
    title: "US.COLLECTION.ECONOMY:ITEM_TYPE.MODIFIED_DATE",
    width: "150px",
  },
  {
    key: ItemTypeTableColumn.MODIFIED_USER,
    title: "US.COLLECTION.ECONOMY:ITEM_TYPE.MODIFIED_USER",
    width: "150px",
  },
];

export enum ColumnSearchType {
  STRING = "string",
  NUMBER = "number",
}

export enum Align {
  CENTER = "center",
  RIGHT = "right",
  LEFT = "left",
}

export const DEFAULT_TABLE_INFO = {
  filters: [],
  sorter: { columnKey: "", order: "" },
  data: [],
};

export enum DrawerType {
  NEW_ITEM_TYPE = "new",
  EDIT_ITEM_TYPE = "edit",
}

export enum BreadcrumbType {
  ITEM_TYPE = "itemType",
  VOUCHER_SEQUENCE_ADD = "voucherSequenceAdd",
  VOUCHER_SEQUENCE_LIST = "voucherSequenceList",
}

export enum InitialGroup {
  ALL = -1,
}

export const INITIAL_FORM_VALUES = {
  itemTypeName: "",
  transactionGroup: [InitialGroup.ALL],
  transactionCode: "",
  payment: false,
  cancellation: false,
  other: false,
};

export const DEFAULT_DRAWER_STATE = {
  visible: false,
  title: "",
  type: DrawerType.NEW_ITEM_TYPE,
};

export const DEFAULT_BREADCRUMB_STATE = {
  visibleView: BreadcrumbType.ITEM_TYPE,
};

export enum TransactionTypes {
  PAYMENT = "isPayment",
  CANCELATION_TYPE = "isCancelationType",
  FEES = "other",
}

export enum ValidateFieldNames {
  ITEM_TYPE_ID = "itemTypeId",
  TRANSACTION_CODE = "transactionCode",
}

export enum FeeType {
  AMOUNT = "A",
  INTEREST = "I",
}
