export enum ItemTypeAction {
  EDIT = "EDIT",
  DELETE = "DELETE"
}

export interface IItemTypeTable {
  onCallAction: (
    action: ItemTypeAction,
    record: any
  ) => void;
}