import moment from "moment";

export enum PaymentsImportSummaryKey {
  FILE_NAME = "fileName",
  IMPORTED_DATE = "importedDate",
  TOTAL_PAYMENTS = "totalPaymentsCount",
  TOTAL_PAYMENTS_AMOUNT = "totalPaymentsAmount",
  UNKNOWN_PAYMENTS = "unknownPaymentsCount",
  UNKNOWN_PAYMENTS_AMOUNT = "unknownPaymentsAmount",
  EXCEEDED_PAYMENTS = "exceedPaymentsCount",
  EXCEEDED_PAYMENTS_AMOUNT = "exceedPaymentsAmount",
  MAPPED_PAYMENTS = "mappedPaymentsCount",
  MAPPED_PAYMENTS_AMOUNT = "mappedPaymentsAmount",
  ATG_APPROVED = "atgApproved",
  ATG_CANCELLATION = "atgCancellations",
  TOTAL_ATG_RECORDS = "totalATGStatus",
  IMPORTED_USER = "importedUser",
}
export const PAYMENTS_IMPORT_SUMMARY_TABLE_COLUMNS = [
  {
    title: "FILE_NAME",
    key: PaymentsImportSummaryKey.FILE_NAME,
  },
  {
    title: "TOTAL_PAYMENTS",
    children: [
      {
        title: "COUNT",
        key: PaymentsImportSummaryKey.TOTAL_PAYMENTS,
      },
      {
        title: "AMOUNT",
        key: PaymentsImportSummaryKey.TOTAL_PAYMENTS_AMOUNT,
        align: "right",
      },
    ],
  },
  {
    title: "MAPPED_PAYMENTS",
    children: [
      {
        title: "COUNT",
        key: PaymentsImportSummaryKey.MAPPED_PAYMENTS,
      },
      {
        title: "AMOUNT",
        key: PaymentsImportSummaryKey.MAPPED_PAYMENTS_AMOUNT,
        align: "right",
      },
    ],
  },
  {
    title: "EXCEEDED_PAYMENTS",
    children: [
      {
        title: "COUNT",
        key: PaymentsImportSummaryKey.EXCEEDED_PAYMENTS,
      },
      {
        title: "AMOUNT",
        key: PaymentsImportSummaryKey.EXCEEDED_PAYMENTS_AMOUNT,
        align: "right",
      },
    ],
  },
  {
    title: "UNKNOWN_PAYMENTS",
    children: [
      {
        title: "COUNT",
        key: PaymentsImportSummaryKey.UNKNOWN_PAYMENTS,
      },
      {
        title: "AMOUNT",
        key: PaymentsImportSummaryKey.UNKNOWN_PAYMENTS_AMOUNT,
        align: "right",
      },
    ],
  },
  {
    title: "ATG",
    children: [
      {
        title: "TOTAL",
        key: PaymentsImportSummaryKey.TOTAL_ATG_RECORDS,
      },
      {
        title: "APPROVED",
        key: PaymentsImportSummaryKey.ATG_APPROVED,
      },
      {
        title: "CANCELLATION",
        key: PaymentsImportSummaryKey.ATG_CANCELLATION,
      },
    ],
  },
  {
    title: "IMPORTED_DATE",
    key: PaymentsImportSummaryKey.IMPORTED_DATE,
  },
  {
    title: "IMPORTED_USER",
    key: PaymentsImportSummaryKey.IMPORTED_USER,
  },
];

export const INITIAL_FORM_VALUES = {
  fileName: "",
  dateRange: [moment().subtract(30, "days"), moment()],
  isVisibleATGs: false,
};
