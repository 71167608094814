import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import * as Actions from "us.collection.economy/actions";
import { JournalSelection, JournalEntryTable } from "./Components";
import { IJournalItem } from "./Interfaces";
import { JournalEntryTableColumn } from "./Constants";
import { JournalEntryValidationSchema } from "./Validations";
import { handleGoBack } from "us.helper/utility";
import { getRowIndex, getSaveValidationStatus } from "./Functions";
import { SaveJournalEntry } from "./Repository";

const { $Affix, $PageHeader, $Divider, $Button, $Drawer } = Common.Components;

/**
 * @description - Register journal entry view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3121676306/Add+Journal+Entries+-+UI+Implementation+Design
 * @author Tharanga Niroshana <tharangan@unicorn-solutions.com>
 * @since 19/10/2022
 */
const Home: React.FC<PropsFromRedux> = (props) => {
  const history = useHistory();

  const { t } = useTranslation([
    "US.COLLECTION.ECONOMY",
    "US.COLLECTION.COMMON",
  ]);
  const {
    ledgerAccountList,
    validation,
    journalEntryTableInitial,
    isJournalEntriesSaving,
    getLedgerAccounts,
    validateJournalId,
    saveJournalEntry,
    resetRemitHistory,
  } = props;

  const [journalDrawer, setJournalDrawer] = useState({
    isVisible: false,
    rowKey: "",
  });

  useEffect(() => {
    getLedgerAccounts && getLedgerAccounts({});
  }, []);

  /**
   * Select journal id from journal details table
   * @param journalId Journal id
   * @param values Select table row
   * @param restProps Formik props
   */
  const selectJournalId = (journalId: string, values: any, restProps: any) => {
    if (journalId) {
      values[getRowIndex(values, journalDrawer.rowKey)].journalId = journalId;
      restProps.setFieldValue(`values`, [...values]);
      validateJournalId &&
        validateJournalId({
          rowKey: journalDrawer.rowKey,
          field: JournalEntryTableColumn.JOURNAL_ID,
          status: true,
          isLoading: true,
          value: journalId,
        });
      setJournalDrawer({ isVisible: false, rowKey: "" });
    }
  };

  /**
   * Save journal entry
   * @param values Table values
   */
  const registerJournalEntry = (values: IJournalItem) => {
    const requestObject = SaveJournalEntry.call({ values, ledgerAccountList });
    saveJournalEntry && saveJournalEntry({ requestObject, values });
  };

  return (
    <Formik
      enableReinitialize
      validateOnChange
      validateOnBlur
      validateOnMount
      validationSchema={JournalEntryValidationSchema}
      initialValues={journalEntryTableInitial}
      onSubmit={(values: any) => {
        registerJournalEntry(values);
      }}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        isValid,
        errors,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div
                  className="d-flex align-items-center"
                  data-testid="default-view"
                >
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t("US.COLLECTION.ECONOMY:JOURNAL.REGISTER_JOURNAL")}
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <$Button
                    id="btnSave"
                    type="default"
                    size="small"
                    onClick={handleSubmit}
                    loading={isJournalEntriesSaving}
                    disabled={
                      !isValid ||
                      getSaveValidationStatus(validation).status ||
                      getSaveValidationStatus(validation).isLoading
                    }
                  >
                    {t("US.COLLECTION.COMMON:COMMON.SAVE")}
                  </$Button>
                </div>
              </div>
            </$Affix>
            <div className="order-table">
              <JournalEntryTable
                openJournalDrawer={(rowKey) =>
                  setJournalDrawer({
                    isVisible: true,
                    rowKey,
                  })
                }
              />
            </div>
          </div>
          <$Drawer
            title={t("US.COLLECTION.ECONOMY:JOURNAL.SELECT_JOURNAL")}
            width={"1000"}
            visible={journalDrawer.isVisible}
            onClose={() => {
              resetRemitHistory && resetRemitHistory({});
              setJournalDrawer({ isVisible: false, rowKey: "" });
            }}
            destroyOnClose
          >
            <JournalSelection
              selectJournalId={(journalId: string) => {
                selectJournalId(journalId, values.values, restProps);
                resetRemitHistory && resetRemitHistory({});
              }}
            />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, journalEntry } = state;
  const { currentLanguage, currentCurrency, currentDateFormat } = common;
  const {
    ledgerAccountList,
    validation,
    journalEntryTableInitial,
    isJournalEntriesSaving,
  } = journalEntry;

  return {
    currentLanguage,
    currentCurrency,
    currentDateFormat,
    ledgerAccountList,
    validation,
    journalEntryTableInitial,
    isJournalEntriesSaving,
  };
};

const { ledgerAccounts, journalIdValidation, journalEntry, remitHistory } =
  Actions.JournalEntry;

const mapDispatchToProps = {
  getLedgerAccounts: ledgerAccounts.search,
  validateJournalId: journalIdValidation.get,
  saveJournalEntry: journalEntry.save,
  resetValidation: journalEntry.resetValidation,
  resetRemitHistory: remitHistory.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
