import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { IconTypes, BoxIcons } from "us.icons";
import {
  $Divider,
  $Button,
  $InputAmount,
  $Form,
  $Alert,
  $AmountLabel,
  $Popconfirm,
  $InputAccountNo,
} from "us.common/components";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import { IRootState } from "us.collection/interfaces";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";
import { managePayment } from "us.collection.economy/actions";
import {
  GetPayments,
  SaveTransfer,
  ValidateForm,
} from "us.collection.economy/components/ManagePayments/Repository";
import { transferValidation } from "./Validations";
import {
  PaymentHelper,
  calculateOffSet,
} from "us.collection.economy/components/ManagePayments/Functions";
import {
  INITIAL_FILTERS,
  PaymentStates,
} from "us.collection.economy/constants/Payments";
import { ITransfer } from "./Interfaces";
import { getPaymentIdToFocus } from "us.collection.economy/components/ManagePayments/Functions";
const { getPayment, getTotalReturnFee, getBulkDataWithZeroAmount } =
  PaymentHelper;

const Transfer: React.FC<PropsFromRedux & ITransfer> = (props) => {
  const { t } = useTranslation();
  const {
    mainFilters,
    paymentsData,
    isFormSaving,
    currentLanguage,
    currentCurrency,
    itemCallActionState,
    isReturnPaymentSuccess,
    returnPayment,
    setPaymentsWithAcc,
  } = props;

  const { push } = useHistory();
  const { search } = useLocation();
  const params = queryString.parse(search);
  const formRef = useRef<any>();

  const [isBulkVisible, setBulkVisible] = useState<boolean>(false);

  /**
   * @name dsbKey
   * @description used to set exceeded or unknown item object key
   */
  const dsbKey =
    getPayment(Number(params?.id), paymentsData)?.payment?.exceededSum > 0
      ? "exceededSum"
      : "unknownSum";

  // Select next payment
  useEffect(() => {
    const nextPaymentId = getPaymentIdToFocus(paymentsData, Number(params?.id));
    if (!isFormSaving && isReturnPaymentSuccess) {
      if (nextPaymentId && nextPaymentId !== 0) {
        push({
          pathname: `/manage-payments`,
          search: `?view=default&id=${nextPaymentId}&open=true`,
        });
      } else {
        push({
          pathname: `/manage-payments`,
          search: "?view=default",
        });
      }
      formRef.current.resetForm();
      setPaymentsWithAcc([]);
    }
  }, [isFormSaving]);

  // Cleaned up
  useEffect(() => {
    setBulkVisible(false);
    return () => {
      setPaymentsWithAcc([]);
    };
  }, [itemCallActionState.key]);

  // Handle transfer submit
  const handleSubmit = (values: any, actions: any) => {
    if (actions.isValidating) return;
    actions.setSubmitting(true);
    const { payment, distribution, bulk } = getPayment(
      Number(params?.id),
      paymentsData
    );
    const data = {
      ...values,
      paymentId: payment.paymentId,
      accountNo: values?.accountNo?.replace(/\D+/g, ""),
      paymentAritemNo: distribution.arItemNo,
      returnedAmount: Number((payment?.[dsbKey] - values.returnFee).toFixed(2)),
    };

    const searchParams = GetPayments.call({
      ...mainFilters,
      offset: calculateOffSet(paymentsData.length, INITIAL_FILTERS.limit),
    });
    const transferParams = SaveTransfer.call({
      tansfer: data,
      perReturnFee: values.perReturnFee,
      isBulk: isBulkVisible,
      bulk,
      dsbKey,
    });

    returnPayment(transferParams, searchParams);
  };

  // Handle payment list scroll when bulk transfer visible
  const handleScroll = (isCancel: boolean) => {
    const { bulk } = getPayment(Number(params?.id), paymentsData);
    const data = !isCancel ? bulk.paymentsWithAccounts : paymentsData;
    if (data?.some((s: any) => s.paymentId == params?.id)) {
      setTimeout(
        () =>
          params.id &&
          document.getElementById(`${params.id}`)?.scrollIntoView({
            behavior: "smooth",
            block: "center",
          }),
        500
      );
    } else {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Formik
      initialValues={{
        accountNo: getPayment(Number(params?.id), paymentsData)?.payment
          .accountNo,
        returnFee: getPayment(Number(params?.id), paymentsData)?.distribution
          .returnfee
          ? getPayment(Number(params?.id), paymentsData)?.distribution.returnfee
          : 0,
        perReturnFee: getPayment(Number(params?.id), paymentsData)?.bulk
          .perReturnFee
          ? getPayment(Number(params?.id), paymentsData)?.bulk.perReturnFee
          : 0,
        exdOrUnkwSum: getPayment(Number(params?.id), paymentsData)?.payment?.[
          dsbKey
        ]
          ? getPayment(Number(params?.id), paymentsData)?.payment?.[dsbKey]
          : 0,
        totalExdAmount: getPayment(Number(params?.id), paymentsData)?.bulk
          ?.totalExdOrUnkwnAmount
          ? getPayment(Number(params?.id), paymentsData)?.bulk
              ?.totalExdOrUnkwnAmount
          : 0,
        noOfRecords: getPayment(Number(params?.id), paymentsData)?.bulk
          .noOfRecords
          ? getPayment(Number(params?.id), paymentsData)?.bulk.noOfRecords
          : 0,
        isBulkVisible: isBulkVisible,
      }}
      enableReinitialize
      validateOnMount={false}
      validationSchema={transferValidation}
      innerRef={formRef}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }) => (
        <>
          <ValidateForm />
          <$Form className="w-100" onSubmit={handleSubmit}>
            <div className="map-action-wraper  d-flex align-items-start flex-column">
              <div className="map-action-content mb-auto ">
                {(mainFilters?.paymentStates?.includes(PaymentStates.EXCEEDED)
                  ? mainFilters?.debtorOtherCasesStatus !== 2
                  : true) &&
                  values?.noOfRecords > 1 &&
                  !(
                    mainFilters?.paymentStates?.includes(
                      PaymentStates.EXCEEDED
                    ) &&
                    mainFilters?.paymentStates?.includes(PaymentStates.UNKNOWN)
                  ) && (
                    <>
                      {isBulkVisible &&
                        getBulkDataWithZeroAmount(
                          paymentsData,
                          values.perReturnFee
                        ).length > 0 && (
                          <$Alert
                            className="mb-3"
                            message={
                              t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.STANDARD_RETURN_FEE_HAS_BEEN_ADJUSTED_DUE_TO_THE_LOW_RETURN"
                              ) +
                              " " +
                              (getBulkDataWithZeroAmount(
                                paymentsData,
                                values.perReturnFee
                              ).length > 1
                                ? t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.AMOUNTS_FOUND_IN"
                                  )
                                : t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.AMOUNT_FOUND_IN"
                                  )) +
                              " " +
                              getBulkDataWithZeroAmount(
                                paymentsData,
                                values.perReturnFee
                              ).length +
                              " " +
                              (getBulkDataWithZeroAmount(
                                paymentsData,
                                values.perReturnFee
                              ).length > 1
                                ? t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RECORDS"
                                  )
                                : t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RECORD"
                                  ))
                            }
                            type="info"
                            showIcon
                          />
                        )}
                      <$Alert
                        message={
                          <div>
                            <div>
                              <h3 data-testid="label-bulk-transfer">
                                {t(
                                  "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.BULK_TRANSFER"
                                )}
                              </h3>
                              <div className="d-flex justify-content-between">
                                <p data-testid="label-bulk-transfer-data">
                                  <b>{values?.noOfRecords}</b>
                                  {` ${t(
                                    "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RECORDS_FOUND_WITH_DEBTOR_ACCOUNT_NUMBERS"
                                  )}. `}
                                  {mainFilters?.paymentStates?.length !== 1 &&
                                    t(
                                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_AMOUNT_IS"
                                    )}
                                  {mainFilters?.paymentStates?.length == 1 &&
                                    mainFilters?.paymentStates?.includes(
                                      PaymentStates.EXCEEDED
                                    ) &&
                                    t(
                                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_EXCEEDED_AMOUNT_IS"
                                    )}
                                  {mainFilters?.paymentStates?.length == 1 &&
                                    mainFilters?.paymentStates?.includes(
                                      PaymentStates.UNKNOWN
                                    ) &&
                                    t(
                                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_UNKNOWN_AMOUNT_IS"
                                    )}{" "}
                                  <b>
                                    <$AmountLabel
                                      value={values.totalExdAmount}
                                    />
                                  </b>
                                </p>
                                {!isBulkVisible && (
                                  <$Button
                                    type="default"
                                    size="small"
                                    data-testid="return-all-button"
                                    disabled={values?.noOfRecords == 0}
                                    icon={
                                      <BoxIcons
                                        type={IconTypes.BOX_ICON}
                                        name="transfer"
                                      />
                                    }
                                    onClick={() => {
                                      setPaymentsWithAcc(
                                        getPayment(
                                          Number(params?.id),
                                          paymentsData
                                        ).bulk.paymentsWithAccounts
                                      );
                                      setBulkVisible(true);
                                      handleScroll(false);
                                    }}
                                  >
                                    {t(
                                      "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_ALL"
                                    )}
                                  </$Button>
                                )}
                                {isBulkVisible && (
                                  <$Button
                                    type="default"
                                    size="small"
                                    onClick={() => {
                                      setPaymentsWithAcc([]);
                                      setBulkVisible(false);
                                      handleScroll(true);
                                    }}
                                  >
                                    {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                                  </$Button>
                                )}
                              </div>
                            </div>
                            <div className="flex-fill">
                              {isBulkVisible && (
                                <div className="d-flex">
                                  <div className="d-flex flex-column mr-5">
                                    <$InputAmount
                                      label={t(
                                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PER_RETURN_FEE"
                                      )}
                                      min={0}
                                      placeholder="0,00"
                                      size="small"
                                      name="perReturnFee"
                                      value={values.perReturnFee}
                                      currentLanguage={currentLanguage}
                                      currentCurrency={currentCurrency}
                                    />
                                  </div>
                                  <div className="d-flex flex-column mr-5">
                                    <span>
                                      {t(
                                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_RETURN_FEE"
                                      )}
                                    </span>

                                    <span
                                      className="font-weight-bold mt-2"
                                      data-testid="label-total-return-fee"
                                    >
                                      <$AmountLabel
                                        value={getTotalReturnFee(
                                          paymentsData,
                                          values.perReturnFee
                                        )}
                                      />
                                    </span>
                                  </div>
                                  <div className="d-flex flex-column ">
                                    <span>
                                      {t(
                                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TOTAL_RETURN_AMOUNT"
                                      )}
                                    </span>

                                    <span
                                      className="font-weight-bold mt-2"
                                      data-testid="label-total-return-amt"
                                    >
                                      <$AmountLabel
                                        value={
                                          values.totalExdAmount -
                                          getTotalReturnFee(
                                            paymentsData,
                                            values.perReturnFee
                                          )
                                        }
                                      />
                                    </span>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        }
                        type="warning"
                      />
                      <$Divider />
                    </>
                  )}
                {!isBulkVisible && (
                  <>
                    {mainFilters?.paymentStates?.includes(
                      PaymentStates.EXCEEDED
                    ) &&
                      mainFilters?.paymentStates?.includes(
                        PaymentStates.UNKNOWN
                      ) && (
                        <$Alert
                          className="mb-2"
                          message={t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.IF_YOU_WISH_TO_MAKE_A_BULK_TRANSFER"
                          )}
                          type="info"
                          showIcon
                        />
                      )}
                    {values.returnFee !==
                      getPayment(Number(params?.id), paymentsData)?.distribution
                        ?.actualReturnFee && (
                      <$Alert
                        className="mt-2"
                        message={
                          <span>
                            {t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.STANDARD_RETURN_FEE_IS"
                            )}{" "}
                            <$AmountLabel
                              value={
                                getPayment(Number(params?.id), paymentsData)
                                  ?.distribution?.actualReturnFee
                              }
                            />
                          </span>
                        }
                        type="info"
                        showIcon
                      />
                    )}
                    <h2 className="mt-2">
                      {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.TRANSFER")}
                      <$Divider className="bui-devider" type="vertical" />
                      <span
                        className="font-weight-bold "
                        style={{ fontSize: "1rem" }}
                        data-testid="label-payment-id"
                      >
                        {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_ID")}:{" "}
                        {
                          getPayment(Number(params?.id), paymentsData)?.payment
                            .paymentId
                        }
                      </span>
                    </h2>

                    <div className="flex-fill">
                      <$Form layout="vertical" autocomplete="off">
                        <div className="d-flex">
                          <div className="d-flex flex-column mr-5">
                            <span>
                              {t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_AMOUNT"
                              )}
                            </span>

                            <span
                              className="font-weight-bold mt-2"
                              data-testid="label-payment-amount"
                            >
                              <$AmountLabel
                                value={values.exdOrUnkwSum - values.returnFee}
                              />
                            </span>
                          </div>
                          <div className="d-flex flex-column mr-2">
                            <$InputAccountNo
                              name="accountNo"
                              label={t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ACCOUNT_NO"
                              )}
                              style={{ width: 150 }}
                              min={0}
                              size="small"
                              required
                            />
                          </div>
                          <div className="d-flex flex-column">
                            <$InputAmount
                              label={t(
                                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_FEE"
                              )}
                              min={0}
                              placeholder="0,00"
                              size="small"
                              name="returnFee"
                              value={values.returnFee}
                              currentLanguage={currentLanguage}
                              currentCurrency={currentCurrency}
                            />
                          </div>
                        </div>
                      </$Form>
                    </div>
                  </>
                )}
              </div>
              <div className="map-action-footer flex-column ">
                <div className="d-flex justify-content-between align-items-end">
                  <div className="flex-fill"></div>
                  <div className="ml-3">
                    <$Popconfirm
                      title={t("COMMON.SURE_TO_PROCEED_?")}
                      placement="topLeft"
                      onConfirm={() => handleSubmit()}
                      okText={t("COMMON.YES")}
                      cancelText={t("COMMON.NO")}
                      disabled={!rest.isValid || isSubmitting || isFormSaving}
                    >
                      <$Button
                        type="primary"
                        htmlType="submit"
                        data-testid="submit-button"
                        disabled={!rest.isValid || isSubmitting || isFormSaving}
                        loading={isSubmitting || isValidating || isFormSaving}
                      >
                        {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CONFIRM")}
                      </$Button>
                    </$Popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </$Form>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { managePayment, common } = state;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  const { payments, returnPayment } = managePayment;

  return {
    payments,
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    isFormSaving: returnPayment.isSaving,
    isReturnPaymentSuccess: returnPayment.result,
  };
};

const mapDispatchToProps = {
  returnPayment: managePayment.returnPayment,
  setPaymentsWithAcc: managePayment.setPaymentsWithAcc,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Transfer);
