import React from "react";
import { useTranslation } from "react-i18next";
import { $Divider } from "us.common/components";

interface Props {
  onViewDistributions: () => void;
  onViewPaymentDetails: () => void;
  withViewDistribution?: boolean;
}

const Footer: React.FC<Props> = ({
  onViewDistributions,
  onViewPaymentDetails,
  withViewDistribution = true,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <$Divider className="mt-1 mb-0 ml-0 mr-0" />

      <div
        data-testid="ancestor"
        className="d-flex justify-content-between pt-2 pr-2"
      >
        <div>
          {withViewDistribution && (
            <a
              onClick={onViewDistributions}
              data-testid="btnOnViewDistributions"
            >
              {t(
                "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.VIEW_PAYMENT_DISTRIBUTION"
              )}
            </a>
          )}
        </div>
        <a onClick={onViewPaymentDetails} data-testid={"btnViewPaymentDetails"}>
          {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.PAYMENT_DETAILS")}
        </a>
      </div>
    </>
  );
};

export default Footer;
