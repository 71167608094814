import { CreateRemitParams } from "us.collection.economy/interfaces";

export function CreateRemit(this: any): CreateRemitParams {
  return {
    journalId: this.journalId,
    remitVoucherNo: this.remitVoucherNo,
    remitAccount: this.remitAccount,
    remitId: this.remitId,
    creditors: this.creditors,
  };
}
