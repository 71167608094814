import { httpCollection } from "us.helper/http/collection";

export default {
  manualOrders: {
    getArticlesById: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "paymentservice",
            `payments/articles?articleNo=${params}`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getAllArticles: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "paymentservice",
            "payments/articles",
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    saveOrderLines: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.post(
            "clientinvoiceservice",
            "order",
            params
          );
          if (data.status === 200) {
            window.location.replace("/orders");
          }
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    saveAndInvoiceOrderLines: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.post(
            "clientinvoiceservice",
            "order?exportinvoice=true",
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getValidateCaseId: (params: number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.getById(
            "arservice",
            "ars/cases",
            `${params}/validationresult`
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    exportOrderLines: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.post(
            "clientinvoiceservice",
            `clientinvoice/export/${params[0]?.exportType}`,
            params
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    validateCreditor: (params: number): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.get(
            "searchservice",
            `entitysearch?roleType=CRE&searchCriteria=Number&keyword=${params}`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getNextOrderIdByTableName: (): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "commonservice",
            `common/getnextid/USP_Orders`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getOrderDetails: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "clientinvoiceservice",
            `clientorder/${params}`,
            {}
          );
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateExistingOrderLines: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.put(
            "clientinvoiceservice",
            "order",
            params
          );
          if (data.status === 200) {
            window.location.replace("/orders");
          }
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    updateAndInvoiceExistingOrderLines: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.put(
            "clientinvoiceservice",
            "order?exportinvoice=true",
            params
          );
          // if (data.status === 200) {
          //   window.location.replace("/orders");
          // }
          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    getVatAmountsByCreditor: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const data = await httpCollection.get(
            "paymentservice",
            "payment/vat",
            params
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
