export enum Articles {
    CHANGE_DRAWER_INFO = 'CHANGE_DRAWER_INFO',

    GET_ARTICLES = 'GET_ARTICLES',
    GET_ARTICLES_SUCCESS = 'GET_ARTICLES_SUCCESS',
    GET_ARTICLES_FAIL = 'GET_ARTICLES_FAIL',

    GET_ARTICLE_PROPERTIES = 'GET_ARTICLE_PROPERTIES',
    GET_ARTICLE_PROPERTIES_SUCCESS = 'GET_ARTICLE_PROPERTIES_SUCCESS',
    GET_ARTICLE_PROPERTIES_FAIL = 'GET_ARTICLE_PROPERTIES_FAIL',

    DELETE_ARTICLE = 'DELETE_ARTICLE',
    DELETE_ARTICLE_SUCCESS = 'DELETE_ARTICLE_SUCCESS',
    DELETE_ARTICLE_FAIL = 'DELETE_ARTICLE_FAIL',

    SAVE_ARTICLE = 'SAVE_ARTICLE',
    SAVE_ARTICLE_SUCCESS = 'SAVE_ARTICLE_SUCCESS',
    SAVE_ARTICLE_FAIL = 'SAVE_ARTICLE_FAIL',

    CHECK_DUPLICATE_ARTICLE_NO = 'CHECK_DUPLICATE_ARTICLE_NO',
    CHECK_DUPLICATE_ARTICLE_NO_SUCCESS = 'CHECK_DUPLICATE_ARTICLE_NO_SUCCESS',
    CHECK_DUPLICATE_ARTICLE_NO_FAIL = 'CHECK_DUPLICATE_ARTICLE_NO_FAIL',
    RESET_DUPLICATE_ARTICLE_NO_STATUS = 'RESET_DUPLICATE_ARTICLE_NO_STATUS',

}