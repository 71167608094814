import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import * as Components from "us.common/components";
import {
  CLIENT_ACCOUNTS_COLUMNS,
  ClientAccountsTableColumn,
  ITEM_MENU_DEFAULT_STATE,
  ClientAccountsAction,
} from "us.collection.economy/components/ClientAccounts/Constants";
import { MoreOutlined } from "us.icons";
import { ItemMenu } from "./Components";
import {
  IClientAccountsTableInfo,
  IClientAccountInfo,
  IItemMenu,
} from "us.collection.economy/components/ClientAccounts/Interfaces";
import {
  getColumnWidth,
  getColumnFilterProps,
  getGridData,
} from "us.collection.economy/components/ClientAccounts/Functions";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";

const { $Button, $TableTree, $Popover, $AccountNoLabel, $DateLabel } =
  Components;

/**
 * @description - Table view for show client accounts
 * @link https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2835709953/Client+Accounts
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 20/06/2022
 */
const ClientAccountsTable: React.FC<PropsFromRedux & IClientAccountsTableInfo> =
  (props) => {
    const {
      onCallAction,
      currentCurrency,
      clientAccountsList,
      currentLanguage,
      currentDateFormat,
    } = props;
    const { t } = useTranslation([
      "US.COLLECTION.REPORTS",
      "US.COLLECTION.COMMON",
    ]);

    const [itemMenu, setItemMenu] = useState<IItemMenu>(
      ITEM_MENU_DEFAULT_STATE
    );

    // auto close client account item menu after 5 seconds
    const setAutoClose = (id: number) => {
      setTimeout(() => {
        setItemMenu(ITEM_MENU_DEFAULT_STATE);
      }, 5000);
    };

    /**
     * @description - render rows
     */
    const renderCell = (record: IClientAccountInfo, dataIndex: string) => {
      const {
        accountId,
        accountNo,
        accountName,
        ledgerAccountName,
        modifiedDate,
        modifiedUser,
      } = record;
      const row = {
        children: (
          <>
            {dataIndex === ClientAccountsTableColumn.MENU && (
              <div className="align-items-center" key={accountId}>
                <$Popover
                  placement="bottomLeft"
                  trigger="hover"
                  visible={itemMenu.visible && itemMenu.id == record.accountId}
                  onVisibleChange={(visible: boolean) => {
                    setItemMenu({
                      ...itemMenu,
                      visible,
                      id: record.accountId,
                    });
                    setAutoClose(record.accountId);
                  }}
                  content={
                    <ItemMenu
                      onCallAction={(actionType: ClientAccountsAction) => {
                        setItemMenu(ITEM_MENU_DEFAULT_STATE);
                        onCallAction(actionType, record);
                      }}
                      isUsed={record.isUsed}
                    />
                  }
                  destroyTooltipOnHide
                >
                  <$Button
                    id="btnItemMenu"
                    type="default"
                    data-testid="popover-btn"
                    icon={<MoreOutlined />}
                    size="small"
                  />
                </$Popover>
              </div>
            )}
            {dataIndex === ClientAccountsTableColumn.ACCOUNT_NO && (
              <$AccountNoLabel value={accountNo} />
            )}
            {dataIndex === ClientAccountsTableColumn.ACCOUNT_NAME && (
              <div className="align-items-center" key={accountId}>
                <span className="ml-2" data-testid="accountName">
                  {accountName?.trim()}
                </span>
              </div>
            )}
            {dataIndex === ClientAccountsTableColumn.LEDGER_ACCOUNT && (
              <div className="align-items-center" key={accountId}>
                <span className="ml-2" data-testid="ledgerAccountNumber">
                  {ledgerAccountName}
                </span>
              </div>
            )}
            {dataIndex === ClientAccountsTableColumn.MODIFIED_DATE &&
              modifiedDate != null && <$DateLabel value={modifiedDate} />}
            {dataIndex === ClientAccountsTableColumn.MODIFIED_USER && (
              <div className="align-items-center" key={accountId}>
                <span className="ml-2" data-testid="modifiedUser">
                  {modifiedUser}
                </span>
              </div>
            )}
          </>
        ),
      };
      return row;
    };

    /**
     * @description - Generate table columns with sorter and filters
     * @returns - An array of columns
     */
    const getColumns = (): any[] => {
      let columns: Array<any> = [];
      CLIENT_ACCOUNTS_COLUMNS.map(({ id, key, title }, _index) => {
        const columnWidth = getColumnWidth(key);
        let column: any = {
          key,
          dataIndex: key,
          width: columnWidth,
          title: title && t(`US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.${title}`),
          ...getColumnFilterProps(key),
          customRenderChild: (_text: any, record: any) =>
            renderCell(record, key),
          align: "left",
        };
        if (key !== ClientAccountsTableColumn.MENU) {
          column = {
            ...column,
            customSorter: (a: any, b: any) => {
              if (
                [
                  ClientAccountsTableColumn.ACCOUNT_NO,
                  ClientAccountsTableColumn.ACCOUNT_NAME,
                  ClientAccountsTableColumn.LEDGER_ACCOUNT,
                  ClientAccountsTableColumn.MODIFIED_USER,
                ].includes(key)
              ) {
                return a.localeCompare(b);
              } else if (key === ClientAccountsTableColumn.MODIFIED_DATE) {
                return new Date(a).getTime() - new Date(b).getTime();
              } else {
                return a - b;
              }
            },
          };
        }
        columns.push(column);
      });
      return columns;
    };

    return (
      <$TableTree
        data-testid="clientAccounts-table"
        columns={getColumns()}
        rowKey={(record) => record?.accountId}
        data={clientAccountsList}
        className="header-custom-tag mt-3"
        bordered
        size="small"
        onSort={(sortData, dataSource) => {
          return sortData(dataSource);
        }}
        onFilter={(searchData, dataSource) => {
          return searchData(dataSource);
        }}
        filterOnType
        resetOnSourceChange
        showResetAllFilters
        pagination={{ defaultPageSize: 20 }}
        scroll={{ x: 1300, y: "calc(100vh - 225px)" }}
        renderExcelData={getGridData(
          clientAccountsList,
          currentLanguage,
          currentDateFormat
        )}
        currentCurrency={currentCurrency}
        excelFileName={"Client Accounts Details"}
      />
    );
  };

const mapStateToProps = (state: IRootState) => {
  const { clientAccounts, common } = state;
  const { clientAccountsList, isLoadingClientAccounts } = clientAccounts;
  const { currentCurrency, currentLanguage, currentDateFormat } = common;

  return {
    clientAccountsList,
    isLoadingClientAccounts,
    currentCurrency,
    currentLanguage,
    currentDateFormat,
  };
};

const connector = connect(mapStateToProps, {});

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ClientAccountsTable);
