export enum LedgerAccount {
  GET_LEDGER_ACCOUNTS_DATA = "GET_LEDGER_ACCOUNTS_DATA",
  GET_LEDGER_ACCOUNTS_DATA_SUCCESS = "GET_LEDGER_ACCOUNTS_DATA_SUCCESS",
  GET_LEDGER_ACCOUNTS_DATA_FAIL = "GET_LEDGER_ACCOUNTS_DATA_FAIL",

  ADD_NEW_LEDGER_ACCOUNT = "ADD_NEW_LEDGER_ACCOUNT",
  ADD_NEW_LEDGER_ACCOUNT_SUCCESS = "ADD_NEW_LEDGER_ACCOUNT_SUCCESS",
  ADD_NEW_LEDGER_ACCOUNT_FAIL = "ADD_NEW_LEDGER_ACCOUNT_FAIL",

  EDIT_LEDGER_ACCOUNT = "EDIT_LEDGER_ACCOUNT",
  EDIT_LEDGER_ACCOUNT_SUCCESS = "EDIT_LEDGER_ACCOUNT_SUCCESS",
  EDIT_LEDGER_ACCOUNT_FAIL = "EDIT_LEDGER_ACCOUNT_FAIL",

  DELETE_LEDGER_ACCOUNT = "DELETE_LEDGER_ACCOUNT",
  DELETE_LEDGER_ACCOUNT_SUCCESS = "DELETE_LEDGER_ACCOUNT_SUCCESS",
  DELETE_LEDGER_ACCOUNT_FAIL = "DELETE_LEDGER_ACCOUNT_FAIL",

  HANDLE_LEDGER_ACCOUNTS_DRAWER = "HANDLE_LEDGER_ACCOUNTS_DRAWER",

  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD = "DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD",
  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_SUCCESS = "DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_SUCCESS",
  DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_FAIL = "DUPLICATE_VALIDATE_ACCOUNT_NO_FIELD_FAIL",
}
