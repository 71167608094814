import { ICreditorInvoiceDetailsActions } from "us.collection.economy/interfaces";
import { CreditorInvoiceDetails } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

const {
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_FAIL,
} = CreditorInvoiceDetails;

export const creditorInvoiceDetails: Readonly<IAPIAction & ICreditorInvoiceDetailsActions> = {
  invoiceDetails: {
    getById: (data) => ({
      type: GET_INVOICE_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_INVOICE_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_INVOICE_DETAILS_FAIL,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
