export enum CoveringSequence {
    GET_COVERING_SEQUENCE_LIST = "GET_COVERING_SEQUENCE_LIST",
    GET_COVERING_SEQUENCE_LIST_SUCCESS = "GET_COVERING_SEQUENCE_LIST_SUCCESS",
    GET_COVERING_SEQUENCE_LIST_FAIL = "GET_COVERING_SEQUENCE_LIST_FAIL",
  
    GET_ITEM_TYPES = "GET_ITEM_TYPES",
    GET_ITEM_TYPES_SUCCESS = "GET_ITEM_TYPES_SUCCESS",
    GET_ITEM_TYPES_FAIL = "GET_ITEM_TYPES_FAIL",
  
    GET_DEFAULT_SEQUENCE_TYPE = "GET_DEFAULT_SEQUENCE_TYPE",
    GET_DEFAULT_SEQUENCE_TYPE_SUCCESS = "GET_DEFAULT_SEQUENCE_TYPE_SUCCESS",
    GET_DEFAULT_SEQUENCE_TYPE_FAIL = "GET_DEFAULT_SEQUENCE_TYPE_FAIL",
  
    SAVE_COVERING_SEQUENCE = "SAVE_COVERING_SEQUENCE",
    SAVE_COVERING_SEQUENCE_SUCCESS = "SAVE_COVERING_SEQUENCE_SUCCESS",
    SAVE_COVERING_SEQUENCE_FAIL = "SAVE_COVERING_SEQUENCE_FAIL",

    RESET_COVERING_SEQUENCE_DATA = "RESET_COVERING_SEQUENCE_DATA",

    UPDATE_COVERING_SEQUENCE = "UPDATE_COVERING_SEQUENCE",
    UPDATE_COVERING_SEQUENCE_SUCCESS = "UPDATE_COVERING_SEQUENCE_SUCCESS",
    UPDATE_COVERING_SEQUENCE_FAIL = "UPDATE_COVERING_SEQUENCE_FAIL",

    DELETE_COVERING_SEQUENCE = "DELETE_COVERING_SEQUENCE",
    DELETE_COVERING_SEQUENCE_SUCCESS = "DELETE_COVERING_SEQUENCE_SUCCESS",
    DELETE_COVERING_SEQUENCE_FAIL = "DELETE_COVERING_SEQUENCE_FAIL",

    COPY_COVERING_SEQUENCE = "COPY_COVERING_SEQUENCE",
    COPY_COVERING_SEQUENCE_SUCCESS = "COPY_COVERING_SEQUENCE_SUCCESS",
    COPY_COVERING_SEQUENCE_FAIL = "COPY_COVERING_SEQUENCE_FAIL",

    VALIDATE_COVERING_SEQUENCE_NAME = "VALIDATE_COVERING_SEQUENCE_NAME",
    VALIDATE_COVERING_SEQUENCE_NAME_SUCCESS = "VALIDATE_COVERING_SEQUENCE_NAME_SUCCESS",
    VALIDATE_COVERING_SEQUENCE_NAME_FAIL = "VALIDATE_COVERING_SEQUENCE_NAME_FAIL",
    RESET_COVERING_SEQUENCE_NAME_VALIDATION = "RESET_COVERING_SEQUENCE_NAME_VALIDATION"
  
  }  