import React from 'react';
import { useTranslation } from 'react-i18next';
import {ActionTypes} from 'us.collection.economy/components/ManagePayments/interface';
import { $Divider } from 'us.common/components';
import { ActionsBar } from '../ActionsBar';

interface Props {
	actions: ActionTypes[];
	onAction: (actionType: string) => void;
	caseNo?: string | number | undefined;
	kid?: string | undefined;
	onPressCaseNo?: (caseNo: string | number) => void;
}

const Header: React.FC<Props> = ({
	actions = [ActionTypes.APPORTION, ActionTypes.EDIT, ActionTypes.TRANSFER],
	onAction,
	caseNo,
	kid,
	onPressCaseNo,
}) => {
	const { t } = useTranslation();
	return (
		<>
			<div className='map-item-header d-flex justify-content-between'>
				<div className='d-flex align-items-center'>
					<ActionsBar actions={actions} onAction={onAction} />
					<div className='text-muted ml-3'>
						{caseNo && (
							<>
								<span>{`${t(
									'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CASE_NO'
								)} `}</span>
								<a
									onClick={() => {
										typeof onPressCaseNo !== 'undefined' &&
											onPressCaseNo(caseNo);
									}}
									className='mr-3'>
									{caseNo}
								</a>
							</>
						)}
						{kid && (
							<>
								<span>
									{`${t(
										'US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.KID'
									)} `}
								</span>
								<span className='text-body '>{kid}</span>
							</>
						)}
					</div>
				</div>
			</div>
			<$Divider className='my-1' dashed />
		</>
	);
};

export default Header;
