import React from "react";
import { useTranslation } from "react-i18next";
import { $AmountLabel, $Divider, $Tag } from "us.common/components";
import { Row } from "../Row";

interface Props {
  amount: number;
  accountNo: string | null;
  items: Array<any>;
  onRevertItem: <D>(item: D) => void;
}
/**
 * A component which is used to handle Payment's returned
 * @param {number} amount - Returned Amount
 * @param {Array<any>} items - returned payment distribution item array
 * @param {function} onRevertItem - call back function when revert a specific returned item
 */
const ReturnWithOther: React.FC<Props> = ({
  amount = 0,
  accountNo,
  items,
  onRevertItem,
}) => {
  const { t } = useTranslation();
  return (
    <>
      <$Divider className="mt-2 mb-0 map-payment-divider" orientation="right">
        <$Tag className="tag-status-returntodebtor">
          <div className="d-flex justify-content-between">
            <span className="mr-5">
              {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO_DEBTOR")}
            </span>
            <span data-testid="returned-amount">
              <$AmountLabel value={amount} />
            </span>
          </div>
        </$Tag>
      </$Divider>
      <div className="map-item-table pr-2" data-testid="return-items">
        {items?.map((item: any) => {
          const { arItemNo, amount, transactionItemTypeId, returnedDate } =
            item;
          return (
            <Row
              key={arItemNo}
              amount={amount}
              itemTypeId={transactionItemTypeId}
              label={
                transactionItemTypeId == 25
                  ? t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_FEE")
                  : `${t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RETURN_TO")} ${
                      accountNo ?? ""
                    }`
              }
              returnedDate={returnedDate}
              onRevert={() => onRevertItem(item)}
            />
          );
        })}
      </div>
    </>
  );
};

export default ReturnWithOther;
