export enum Remit {
  GET_BANK_ACCOUNTS = "GET_BANK_ACCOUNTS",
  GET_BANK_ACCOUNTS_SUCCESS = "GET_BANK_ACCOUNTS_SUCCESS",
  GET_BANK_ACCOUNTS_FAIL = "GET_BANK_ACCOUNTS_FAIL",

  GET_CREDITORS = "GET_CREDITORS",
  GET_CREDITORS_SUCCESS = "GET_CREDITORS_SUCCESS",
  GET_CREDITORS_FAIL = "GET_CREDITORS_FAIL",

  RESET_CREDITORS = "RESET_CREDITORS",
  SELECT_DESELECT_CREDITORS = "SELECT_DESELECT_CREDITORS",

  GET_CREDITOR_GROUPS = "GET_CREDITOR_GROUPS",
  GET_CREDITOR_GROUPS_SUCCESS = "GET_CREDITOR_GROUPS_SUCCESS",
  GET_CREDITOR_GROUPS_FAIL = "GET_CREDITOR_GROUPS_FAIL",

  REMOVE_CREDITORS_BY_GROUP_ID = "REMOVE_CREDITORS_BY_GROUP_ID",

  GET_REMIT_PROPOSAL = "GET_REMIT_PROPOSAL",
  GET_REMIT_PROPOSAL_SUCCESS = "GET_REMIT_PROPOSAL_SUCCESS",
  GET_REMIT_PROPOSAL_FAIL = "GET_REMIT_PROPOSAL_FAIL",

  VALIDATE_REMIT = "VALIDATE_REMIT",
  VALIDATE_REMIT_SUCCESS = "VALIDATE_REMIT_SUCCESS",
  VALIDATE_REMIT_FAIL = "VALIDATE_REMIT_FAIL",

  GET_REMIT_DETAILS_BY_CREDITOR = "GET_REMIT_DETAILS_BY_CREDITOR",
  GET_REMIT_DETAILS_BY_CREDITOR_SUCCESS = "GET_REMIT_DETAILS_BY_CREDITOR_SUCCESS",
  GET_REMIT_DETAILS_BY_CREDITOR_FAIL = "GET_REMIT_DETAILS_BY_CREDITOR_FAIL",

  GENERATE_REMIT = "GENERATE_REMIT",
  GENERATE_REMIT_SUCCESS = "GENERATE_REMIT_SUCCESS",
  GENERATE_REMIT_FAIL = "GENERATE_REMIT_FAIL",

  INVOICE_REMIT = "INVOICE_REMIT",
  INVOICE_REMIT_SUCCESS = "INVOICE_REMIT_SUCCESS",
  INVOICE_REMIT_FAIL = "INVOICE_REMIT_FAIL",
}
