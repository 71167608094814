import { initialState } from "us.collection.economy/reducers/RemitHistory/State";
import { RemitHistory } from "us.collection.economy/constants/Actions";

const {
  INIT_REMIT_HISTORY,
  GET_BANK_ACCOUNTS_SUCCESS,
  SEARCH_REMIT_HISTORY,
  SEARCH_REMIT_HISTORY_SUCCESS,
  SEARCH_REMIT_HISTORY_FAIL,
  DOWNLOAD_REMIT_FILE,
  DOWNLOAD_REMIT_FILE_FAIL,
  DOWNLOAD_REMIT_FILE_SUCCESS,
  REVERSE_REMIT,
  REVERSE_REMIT_SUCCESS,
  REVERSE_REMIT_FAIL,

  GET_LEDGER_DETAILS,
  GET_LEDGER_DETAILS_SUCCESS,
  GET_LEDGER_DETAILS_FAIL,
} = RemitHistory;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case INIT_REMIT_HISTORY:
      return Object.assign({}, state, {
        bankAccounts: [],
        isLoadingBankAccounts: true,
        remits: [],
        isLoadingRemitHistory: true,
      });
    case GET_BANK_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        bankAccounts: payload.data,
        isLoadingBankAccounts: false,
      });
    case SEARCH_REMIT_HISTORY:
      return Object.assign({}, state, {
        remits: [],
        isLoadingRemitHistory: true,
      });
    case SEARCH_REMIT_HISTORY_SUCCESS:
      return Object.assign({}, state, {
        remits: payload.data,
        isLoadingRemitHistory: false,
      });
    case SEARCH_REMIT_HISTORY_FAIL:
      return Object.assign({}, state, {
        remits: [],
        isLoadingRemitHistory: false,
      });
    case DOWNLOAD_REMIT_FILE:
      return Object.assign({}, state, {
        downloadedFile: {
          content: null,
          isLoading: true,
        },
      });
    case DOWNLOAD_REMIT_FILE_SUCCESS:
      return Object.assign({}, state, {
        downloadedFile: {
          content: payload.data,
          isLoading: false,
        },
      });
    case DOWNLOAD_REMIT_FILE_FAIL:
      return Object.assign({}, state, {
        downloadedFile: {
          content: null,
          isLoading: false,
        },
      });
    case REVERSE_REMIT:
      return Object.assign({}, state, {
        remitReversing: payload,
      });
    case REVERSE_REMIT_SUCCESS:
      const { remitReverse, remitHistory } = payload.data;
      return Object.assign({}, state, {
        remitReversing: {
          data: remitReverse,
          isLoading: false,
        },
        remits: remitHistory,
      });
    case REVERSE_REMIT_FAIL:
      return Object.assign({}, state, {
        remitReversing: payload,
      });
    case GET_LEDGER_DETAILS:
      return Object.assign({}, state, {
        accountsDetails: initialState.accountsDetails,
        isDetailsLoading: true,
      });
    case GET_LEDGER_DETAILS_SUCCESS:
      const { totalCreditAmount, totalDebitAmount, accountDetails } =
        payload.data;
      return Object.assign({}, state, {
        accountsDetails: {
          totalCreditAmount,
          totalDebitAmount,
          accounts: accountDetails,
        },
        isDetailsLoading: false,
      });
    case GET_LEDGER_DETAILS_FAIL:
      return Object.assign({}, state, {
        accountsDetails: initialState.accountsDetails,
        isDetailsLoading: false,
      });
    default:
      return state;
  }
};
