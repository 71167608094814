import { INITIAL_STATE } from "us.collection.economy/reducers/CreditorInvoiceDetails/State/InitialState";
import { CreditorInvoiceDetails } from "us.collection.economy/constants/Actions";

const {
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_FAIL,
} = CreditorInvoiceDetails;

export default (state = INITIAL_STATE, { payload, type }: any) => {
  switch (type) {
    case GET_INVOICE_DETAILS:
      return Object.assign({}, state, {
        ...INITIAL_STATE,
        isListLoading: true,
      });
    case GET_INVOICE_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        list: payload?.data,
        isListLoading: false,
      });
    case GET_INVOICE_DETAILS_FAIL:
      return Object.assign({}, state, {
        list: [],
        isListLoading: false,
      });
    default:
      return state;
  }
};
