import { TransactionTypes } from "us.collection.economy/components/ItemTypes/Constants";
import { i18n } from "us.helper";
import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    itemTypeId: Yup.number()
      .nullable()
      .required("US.COLLECTION.ECONOMY:VALIDATIONS.ITEM_TYPE_ID_IS_REQUIRED")
      .moreThan(0, "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_ITEM_TYPE_ID"),
    itemTypeName: Yup.string()
      .nullable()
      .required("US.COLLECTION.ECONOMY:VALIDATIONS.ITEM_TYPE_NAME_IS_REQUIRED"),
    transactionCode: Yup.string()
      .nullable()
      .required(
        "US.COLLECTION.ECONOMY:VALIDATIONS.TRANSACTION_CODE_IS_REQUIRED"
      ),
    transactionGroupId: Yup.number().moreThan(
      0,
      "US.COLLECTION.ECONOMY:VALIDATIONS.TRANSACTION_GROUP_IS_REQUIRED"
    ),
    ledgerAccountNumber: Yup.string()
      .nullable()
      .required("US.COLLECTION.ECONOMY:VALIDATIONS.LEDGER_ACCOUNT_IS_REQUIRED"),
    voucherSequenceId: Yup.number().when("transactionType", {
      is: (value) => value !== TransactionTypes.PAYMENT,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().moreThan(
        0,
        "US.COLLECTION.ECONOMY:VALIDATIONS.VOUCHER_SEQUENCE_IS_REQUIRED"
      ),
    }),
    vatCode: Yup.number().when("isVatLiable", {
      is: false,
      then: Yup.number().notRequired(),
      otherwise: Yup.number().moreThan(
        -1,
        "US.COLLECTION.ECONOMY:VALIDATIONS.VAT_CODE_IS_REQUIRED"
      ),
    }),
    distributionFactors: Yup.array().when("transactionType", {
      is: (value) => value == TransactionTypes.FEES,
      then: Yup.array().of(
        Yup.object().shape({
          creditorFraction: Yup.number().test(
            "isAmountGreaterThanOne",
            "",
            function (this: any) {
              if (
                this.parent.creditorFraction + this.parent.bureauFraction !==
                1
              ) {
                return this.createError({
                  message: `${i18n.t(
                    "US.COLLECTION.ECONOMY:VALIDATIONS.TOTAL_SHOULD_BE_EQUAL_TO_1"
                  )}`,
                  path: this.path,
                });
              } else {
                return true;
              }
            }
          ),
          bureauFraction: Yup.number().test(
            "isInterestGreaterThanOne",
            "",
            function (this: any) {
              if (
                this.parent.creditorFraction + this.parent.bureauFraction !==
                1
              ) {
                return this.createError({
                  message: `${i18n.t(
                    "US.COLLECTION.ECONOMY:VALIDATIONS.TOTAL_SHOULD_BE_EQUAL_TO_1"
                  )}`,
                  path: this.path,
                });
              } else {
                return true;
              }
            }
          ),
        })
      ),
    }),
  });
};
