import React from "react";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import "./RemitDetails.scss";
import { RemitDetailsProps } from "./Interfaces";
import { RemitDetailsTable } from "./Components";
import { getRouteUrl } from "us.helper";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import { getTotalRemitAmount } from "us.collection.economy/components/Remit/Functions";

const { $Divider, $Button, $AmountLabel, $Skeleton, $AccountNoLabel } =
  Common.Components;

/**
 * @description - Remit proposal record details navigate from the remit proposal table
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2858385532/Remit+Details+UI+Design+and+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 08/03/2022
 */
const RemitDetails: React.FC<RemitDetailsProps & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const { onCancel, remitSummaryByCreditor, selectedRemitRecord } = props;
  const { creditor, vatStatus, remitAccountNo, remitDetails } =
    remitSummaryByCreditor.data ?? {};

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center flex-fill">
          <div className="d-flex flex-column mr-4">
            <a
              onClick={() => getRouteUrl.creditor(remitDetails[0]?.creditorNo)}
              className="font-weight-bold"
              data-testid="link-creditor"
            >
              {creditor}
            </a>
            <div className="text-muted">
              {t("US.COLLECTION.COMMON:COMMON.CREDITOR")}
            </div>
          </div>
          <$Divider className="bui-devider" type="vertical" />

          <div className="d-flex flex-column ml-4">
            <div className="font-weight-bold" data-testid="total-remit-amount">
              <$AmountLabel value={getTotalRemitAmount(selectedRemitRecord)} />
            </div>
            <div className="text-muted">
              {t("US.COLLECTION.ECONOMY:REMIT.TOTAL_AMOUNT")}
            </div>
          </div>
        </div>
        <div className="d-flex">
          <div className="remt-tile gray-100">
            <div className="font-weight-bold mb1" data-testid="vat-status">
              {vatStatus}
            </div>
            <div className="text-muted">
              {t("US.COLLECTION.ECONOMY:REMIT.VAT_INFORMATION")}
            </div>
          </div>
          <div className="remt-tile gray-100 ml-3">
            <div
              className="font-weight-bold mb1"
              data-testid="remit-account-no"
            >
              <$AccountNoLabel value={remitAccountNo} />
            </div>
            <div className="text-muted text-nowrap">
              {t("US.COLLECTION.ECONOMY:REMIT.REMIT_ACCOUNT_NUMBER")}
            </div>
          </div>
        </div>
      </div>
      <div>
        <$Skeleton
          loading={remitSummaryByCreditor.isLoading}
          active
          paragraph={{ rows: 2 }}
        >
          <RemitDetailsTable />
        </$Skeleton>
      </div>

      <div className="drawer-footer-fixed align-content-center justify-content-end">
        <div>
          <$Button id="btnCancelDrawer" onClick={onCancel}>
            {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
          </$Button>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { remit } = state;

  const { remitValidation, remitSummaryByCreditor } = remit;
  return {
    remitValidation,
    remitSummaryByCreditor,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemitDetails);
