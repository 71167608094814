export const DEFAULT_TABLE_INFO = {
  filters: {},
  sorter: { columnKey: "", order: "" },
  data: [],
};

export enum VATDeductionStatus {
  ADD = "ADD",
  REMOVE = "REMOVE",
}
