import { INITIAL_STATE_REMIT } from "us.collection.economy/reducers/Remit/State/InitialState";
import { Remit } from "us.collection.economy/constants/Actions";
import {
  getCreditors,
  removeLocalCreditors,
  resetSelection,
} from "us.collection.economy/components/Remit/Components/CreditorSelector/Functions";
import { RemitValidationState } from "us.collection.economy/components/Remit/Constants";
import { getRemitRecordsAfterInvoice } from "us.collection.economy/functions/Remit";
const {
  GET_BANK_ACCOUNTS,
  GET_BANK_ACCOUNTS_SUCCESS,
  GET_BANK_ACCOUNTS_FAIL,
  GET_CREDITORS,
  GET_CREDITORS_SUCCESS,
  GET_CREDITORS_FAIL,
  RESET_CREDITORS,
  GET_CREDITOR_GROUPS,
  GET_CREDITOR_GROUPS_FAIL,
  GET_CREDITOR_GROUPS_SUCCESS,
  REMOVE_CREDITORS_BY_GROUP_ID,
  GET_REMIT_PROPOSAL,
  GET_REMIT_PROPOSAL_SUCCESS,
  GET_REMIT_PROPOSAL_FAIL,
  VALIDATE_REMIT,
  VALIDATE_REMIT_SUCCESS,
  VALIDATE_REMIT_FAIL,
  GET_REMIT_DETAILS_BY_CREDITOR,
  GET_REMIT_DETAILS_BY_CREDITOR_FAIL,
  GET_REMIT_DETAILS_BY_CREDITOR_SUCCESS,
  SELECT_DESELECT_CREDITORS,
  GENERATE_REMIT,
  GENERATE_REMIT_FAIL,
  GENERATE_REMIT_SUCCESS,
  INVOICE_REMIT,
  INVOICE_REMIT_SUCCESS,
  INVOICE_REMIT_FAIL,
} = Remit;

export default (state = INITIAL_STATE_REMIT, { payload, type }: any) => {
  switch (type) {
    case GET_BANK_ACCOUNTS:
      return Object.assign({}, state, {
        ...INITIAL_STATE_REMIT,
        bankAccounts: payload,
      });
    case GET_BANK_ACCOUNTS_SUCCESS:
      return Object.assign({}, state, {
        bankAccounts: payload,
        remitAccount: payload.data[0]?.accountNo,
      });
    case GET_BANK_ACCOUNTS_FAIL:
      return Object.assign({}, state, {
        bankAccounts: {
          ...payload,
          data: [],
        },
      });
    case GET_CREDITOR_GROUPS:
      return Object.assign({}, state, {
        creditorGroups: {
          ...state.creditorGroups,
          isLoading: true,
        },
      });
    case GET_CREDITOR_GROUPS_SUCCESS:
      return Object.assign({}, state, {
        creditorGroups: {
          data: payload.data,
          isLoading: false,
        },
      });
    case GET_CREDITOR_GROUPS_FAIL:
      return Object.assign({}, state, {
        creditorGroups: INITIAL_STATE_REMIT.creditorGroups,
      });

    case GET_CREDITORS:
      return Object.assign({}, state, {
        creditors: { ...state.creditors, isLoading: true },
      });
    case GET_CREDITORS_SUCCESS:
      return Object.assign({}, state, {
        creditors: {
          ...getCreditors(
            state.creditors.data,
            state.creditors.selected,
            payload.data
          ),
          isLoading: false,
        },
      });
    case GET_CREDITORS_FAIL:
      return Object.assign({}, state, {
        creditorGroups: INITIAL_STATE_REMIT.creditorGroups,
      });
    case REMOVE_CREDITORS_BY_GROUP_ID:
      return Object.assign({}, state, {
        creditors: {
          ...state.creditors,
          ...removeLocalCreditors(
            state.creditors.data,
            state.creditors.selected,
            payload.data.groupId
          ),
          isLoading: false,
        },
      });
    case RESET_CREDITORS:
      return Object.assign({}, state, {
        creditors: {
          ...resetSelection(state.creditors.backupData, payload.data),
          isLoading: false,
        },
      });
    case SELECT_DESELECT_CREDITORS:
      return Object.assign({}, state, {
        creditors: {
          ...state.creditors,
          selected: payload.data.selectedKeys,
        },
      });
    case GET_REMIT_PROPOSAL:
      return Object.assign({}, state, {
        remitProposal: {
          ...INITIAL_STATE_REMIT.remitProposal,
          isLoading: true,
        },
        remitValidation: {
          ...state.remitValidation,
          data: [],
        },
        validationState: RemitValidationState.VALIDATION,
      });
    case GET_REMIT_PROPOSAL_SUCCESS:
      return Object.assign({}, state, {
        remitProposal: payload,
      });
    case GET_REMIT_PROPOSAL_FAIL:
      return Object.assign({}, state, {
        remitProposal: {
          ...INITIAL_STATE_REMIT.remitProposal,
          isLoading: false,
        },
      });
    case VALIDATE_REMIT:
      return Object.assign({}, state, {
        remitValidation: {
          data: [],
          isLoading: true,
        },
        validationState: RemitValidationState.VALIDATION,
      });
    case VALIDATE_REMIT_SUCCESS:
      const remitErrors = payload.data;
      return Object.assign({}, state, {
        remitValidation: {
          data: remitErrors,
          isLoading: false,
        },
        validationState:
          remitErrors && Array.isArray(remitErrors) && remitErrors.length > 0
            ? RemitValidationState.VALIDATION_FAIL
            : RemitValidationState.VALIDATION_SUCCESS,
      });
    case VALIDATE_REMIT_FAIL:
      return Object.assign({}, state, {
        remitValidation: {
          isLoading: false,
          data: [],
        },
        validationState: RemitValidationState.VALIDATION,
      });
    case GET_REMIT_DETAILS_BY_CREDITOR:
      return Object.assign({}, state, {
        remitSummaryByCreditor: {
          ...INITIAL_STATE_REMIT.remitSummaryByCreditor,
          isLoading: true,
        },
      });
    case GET_REMIT_DETAILS_BY_CREDITOR_SUCCESS:
      return Object.assign({}, state, {
        remitSummaryByCreditor: payload,
      });
    case GET_REMIT_DETAILS_BY_CREDITOR_FAIL:
      return Object.assign({}, state, {
        remitSummaryByCreditor: {
          ...INITIAL_STATE_REMIT.remitSummaryByCreditor,
          isLoading: false,
        },
      });
    case GENERATE_REMIT:
      return Object.assign({}, state, {
        remitGeneration: {
          isLoading: true,
          data: null,
        },
      });
    case GENERATE_REMIT_SUCCESS:
      const remitAccount = state.remitAccount;
      const bankAccounts = state.bankAccounts;
      return Object.assign({}, state, {
        ...INITIAL_STATE_REMIT,
        remitGeneration: payload,
        remitAccount,
        bankAccounts,
      });
    case GENERATE_REMIT_FAIL:
      return Object.assign({}, state, {
        remitGeneration: payload,
      });
    case INVOICE_REMIT:
      return Object.assign({}, state, {
        remitInvoicing: payload,
      });
    case INVOICE_REMIT_SUCCESS:
      const remitRecords = getRemitRecordsAfterInvoice(
        state.remitProposal.data.remitRecords,
        payload.data.creditorNo,
        payload.data.status
      );
      return Object.assign({}, state, {
        remitInvoicing: payload,
        remitProposal: {
          ...state.remitProposal,
          data: {
            ...state.remitProposal.data,
            remitRecords,
          },
        },
      });
    case INVOICE_REMIT_FAIL:
      return Object.assign({}, state, {
        remitInvoicing: payload,
      });
    default:
      return state;
  }
};
