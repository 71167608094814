import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  COLUMNS,
  JournalEntryTableColumn,
  initialJournalData,
} from "us.collection.economy/components/RegisterJournal/Constants";
import { MoreOutlined, PlusOutlined, SearchOutlined } from "us.icons";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "us.helper/types";
import { ItemMenu } from "./Components";
import { IJournalEntryTable } from "./Interfaces";
import {
  $Button,
  $Popover,
  $TableTree,
  ITableTreeColumns,
  $Select,
  $InputWithValue,
  $InputAmount,
  $AsyncInput,
} from "us.common/components";
import { useFormikContext } from "formik";
import * as Actions from "us.collection.economy/actions";
import {
  getRowIndex,
  getRowValidationStatus,
} from "us.collection.economy/components/RegisterJournal/Functions";
import _ from "lodash";

/**
 * @description - Journal entry table is used to display and edit entries.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3121676306/Add+Journal+Entries+-+UI+Implementation+Design
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 01/19/2023
 */
const JournalEntryTable: React.FC<IJournalEntryTable & PropsFromRedux> = (
  props
) => {
  const {
    openJournalDrawer,
    resetValidation,
    validateJournalId,
    validateCaseId,
    ledgerAccountList,
    validation,
    currentLanguage,
    currentCurrency,
  } = props;
  const { t } = useTranslation();
  const tableRef = useRef<any>();
  const { values, setFieldValue } = useFormikContext<any>();

  const [itemMenu, setItemMenu] = useState<{
    visible: boolean;
    key: string;
    visibleTooltip: boolean;
  }>({ visible: false, key: "", visibleTooltip: false });

  // auto close item menu after 5 seconds
  const setAutoClose = (key: string, isTooltip?: boolean) => {
    setTimeout(
      () => {
        setItemMenu({
          visible: false,
          key,
          visibleTooltip: false,
        });
      },
      isTooltip ? 8000 : 5000
    );
  };

  /**
   * @function
   * @description Get table columns list
   * @returns {ITableTreeColumns} - TableTree columns
   */
  const getColumns = (): ITableTreeColumns => {
    const columns: ITableTreeColumns = [];

    COLUMNS.map(({ key, title }: any) => {
      let column: ITableTreeColumns[0] = {
        key: key,
        dataIndex: key,
        title: title && t(title),
      };
      if (key === JournalEntryTableColumn.MENU) {
        // configure menu column
        column = {
          ...column,
          align: "center",
          width: "40px",
          customRenderChild: (_text: any, record: any) => (
            <div className="align-items-center" key={0}>
              <$Popover
                placement="bottomLeft"
                trigger="click"
                visible={itemMenu.visible && itemMenu.key === record.key}
                onVisibleChange={(visible: boolean) => {
                  setItemMenu({
                    ...itemMenu,
                    visible,
                    key: record.key,
                  });
                  setAutoClose("");
                }}
                content={
                  <ItemMenu
                    onCallAction={() => {
                      setItemMenu({
                        ...itemMenu,
                        visible: false,
                        key: record.key,
                      });
                      deleteRow(record);
                    }}
                  />
                }
                destroyTooltipOnHide
              >
                <$Button
                  data-testid="popover-btn"
                  icon={<MoreOutlined />}
                  size="small"
                  disabled={values.values?.length == 1}
                />
              </$Popover>
            </div>
          ),
        };
      } else if ([JournalEntryTableColumn.ACCOUNT_ID].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          showFilters: false,
          width: "250px",
          customRenderChild: (_text, record) => (
            <$Select
              name={`values[${getRowIndex(
                values.values,
                record.key
              )}].accountId`}
              value={record?.accountId}
              size="small"
              required
              options={ledgerAccountList.data}
              allOption={false}
              className="mt-1 w-100"
              optionValue="accountId"
              optionText="accountName"
              getPopupContainer={() => tableRef.current}
              onSearchBy={["accountName"]}
              disabled={ledgerAccountList.isLoading}
              loading={ledgerAccountList.isLoading}
            />
          ),
        };
      } else if ([JournalEntryTableColumn.JOURNAL_ID].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          showFilters: true,
          width: "250px",
          customSorter: (a: any, b: any) => {
            return a?.localeCompare(b);
          },
          customRenderChild: (_text, record) => (
            <$AsyncInput
              name={`values[${getRowIndex(
                values.values,
                record.key
              )}].journalId`}
              size="small"
              autoFocus={false}
              style={{ height: "24px" }}
              onBlur={(e: any) => {
                if (e.target.value !== "" && e.target.value != 0) {
                  validateJournalId &&
                    validateJournalId({
                      rowKey: record?.key,
                      field: JournalEntryTableColumn.JOURNAL_ID,
                      status: true,
                      isLoading: true,
                      value: e.target.value,
                    });
                }
              }}
              isValid={
                getRowValidationStatus(
                  validation,
                  record.key,
                  JournalEntryTableColumn.JOURNAL_ID
                ).status
              }
              onClear={() =>
                resetValidation &&
                resetValidation({
                  rowKey: record?.key,
                  field: JournalEntryTableColumn.JOURNAL_ID,
                  status: true,
                  isLoading: false,
                })
              }
              asyncError={t(
                "US.COLLECTION.VALIDATIONS:INVALID.INVALID_JOURNAL_ID"
              )}
              suffix={
                <$Button
                  onClick={() => openJournalDrawer(record.key)}
                  size="small"
                  icon={<SearchOutlined />}
                  type="link"
                  loading={
                    getRowValidationStatus(
                      validation,
                      record.key,
                      JournalEntryTableColumn.JOURNAL_ID
                    ).isLoading
                  }
                />
              }
              disabled={
                getRowValidationStatus(
                  validation,
                  record.key,
                  JournalEntryTableColumn.JOURNAL_ID
                ).isLoading
              }
            />
          ),
        };
      } else if ([JournalEntryTableColumn.CREDITOR_AMOUNT].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: "amount",
          showFilters: true,
          customSorter: (a: any, b: any) => a - b,
          customRenderChild: (text, record) => (
            <$InputAmount
              name={`values[${getRowIndex(
                values.values,
                record.key
              )}].creditorAmount`}
              size="small"
              value={record?.creditorAmount}
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              disabled={record?.debitAmount > 0}
            />
          ),
        };
      } else if ([JournalEntryTableColumn.DEBIT_AMOUNT].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: "amount",
          showFilters: true,
          customSorter: (a: any, b: any) => a - b,
          customRenderChild: (text, record) => (
            <$InputAmount
              name={`values[${getRowIndex(
                values.values,
                record.key
              )}].debitAmount`}
              value={record?.debitAmount}
              size="small"
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              disabled={record?.creditorAmount > 0}
            />
          ),
        };
      } else if ([JournalEntryTableColumn.CASE_OR_SUB_CASE].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          showFilters: true,
          customSorter: (a: any, b: any) => {
            return a?.localeCompare(b);
          },
          customRenderChild: (text, record) => (
            <$AsyncInput
              name={`values[${getRowIndex(
                values.values,
                record.key
              )}].caseOrSubcase`}
              size="small"
              autoFocus={false}
              isNumber={true}
              onBlur={(e: any) => {
                if (e.target.value !== "" && e.target.value != 0) {
                  validateCaseId &&
                    validateCaseId({
                      rowKey: record?.key,
                      field: JournalEntryTableColumn.CASE_OR_SUB_CASE,
                      status: true,
                      isLoading: true,
                      value: e.target.value,
                    });
                }
              }}
              isValid={
                getRowValidationStatus(
                  validation,
                  record.key,
                  JournalEntryTableColumn.CASE_OR_SUB_CASE
                ).status
              }
              onClear={() =>
                resetValidation &&
                resetValidation({
                  rowKey: record?.key,
                  field: JournalEntryTableColumn.CASE_OR_SUB_CASE,
                  status: true,
                  isLoading: false,
                })
              }
              asyncError={t(
                "US.COLLECTION.VALIDATIONS:INVALID.INVALID_CASE_ID"
              )}
              disabled={
                getRowValidationStatus(
                  validation,
                  record.key,
                  JournalEntryTableColumn.CASE_OR_SUB_CASE
                ).isLoading
              }
              loading={
                getRowValidationStatus(
                  validation,
                  record.key,
                  JournalEntryTableColumn.CASE_OR_SUB_CASE
                ).isLoading
              }
            />
          ),
        };
      } else if ([JournalEntryTableColumn.VOUCHER].includes(key)) {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          showFilters: true,
          customSorter: (a: any, b: any) => {
            return a?.localeCompare(b);
          },
          customRenderChild: (text, record) => (
            <$InputWithValue
              name={`values[${getRowIndex(values.values, record.key)}].voucher`}
              size="small"
              value={record?.voucher}
              type="number"
            />
          ),
        };
      } else {
        // configure other columns
        column = {
          ...column,
          ellipsis: { showTitle: false },
          customFilter: true,
          showFilters: true,
          width: "250px",
          customSorter: (a: any, b: any) => {
            return a?.localeCompare(b);
          },
          customRenderChild: (text, record) => (
            <$InputWithValue
              name={`values[${getRowIndex(values.values, record.key)}].text`}
              size="small"
              value={record?.text}
              className="bui-number-input"
            />
          ),
        };
      }
      // add configured column data object to the columns array
      columns.push(column);
    });

    return columns;
  };

  /**
   * @function
   * @description Add new journal entry
   */
  const addNewRow = () => {
    if (Array.isArray(values.values)) {
      let newValues = values.values;

      newValues.push(
        _.cloneDeep({
          ...initialJournalData,
          key: Math.random().toString(36).slice(2),
        })
      );
      setFieldValue(`values`, [...newValues]);
    }
  };

  /**
   * @function
   * @description Delete journal entry
   */
  const deleteRow = (record: any) => {
    if (Array.isArray(values.values)) {
      let newValues = values.values;
      newValues.splice(
        newValues.findIndex((entry: any) => entry.key === record?.key),
        1
      );
      resetValidation &&
        resetValidation({
          rowKey: record?.key,
          type: "delete",
        });
      setFieldValue(`values`, [...newValues]);
    }
  };

  return (
    <div ref={tableRef}>
      <$TableTree
        rowKey={(record: any) => record.key}
        data={values.values}
        size="small"
        filterOnType
        resetOnSourceChange={true}
        bordered
        className="mt-3"
        onSort={(sortData, dataSource) => {
          return sortData(dataSource);
        }}
        onFilter={(searchData, dataSource) => {
          return searchData(dataSource);
        }}
        pagination={{
          defaultPageSize: 50,
        }}
        scroll={{ x: 900, y: "calc(100vh - 280px)" }}
        columns={getColumns()}
        data-testid="item-type-table"
        footer={() => (
          <$Button
            type="link"
            icon={<PlusOutlined />}
            data-testid="add-new-order-line"
            onClick={() => addNewRow()}
            disabled={
              values.values[values.values.length - 1]?.accountId ? false : true
            }
          >
            {t("US.COLLECTION.ECONOMY:JOURNAL.NEW_JOURNAL")}
          </$Button>
        )}
      />
    </div>
  );
};

const mapStateToProps = (state: RootState) => {
  const { common, journalEntry } = state;
  const { currentLanguage, currentCurrency } = common;
  const { ledgerAccountList, validation } = journalEntry;

  return {
    currentLanguage,
    currentCurrency,
    ledgerAccountList,
    validation,
  };
};

const { journalIdValidation, caseIdValidation, journalEntry } =
  Actions.JournalEntry;

const mapDispatchToProps = {
  validateJournalId: journalIdValidation.get,
  validateCaseId: caseIdValidation.get,
  resetValidation: journalEntry.resetValidation,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(JournalEntryTable);
