export enum TableColumn {
  ARTICLE_NUMBER = "articleNo",
  NAME = "articleText",
  VAT_AMOUNT = "vatAmount",
  DISCOUNT = "discount",
  ORDERLINE_TOTAL = "totalAmount",
}

export const TABLE_COLUMNS = [
  {
    id: 1,
    key: TableColumn.ARTICLE_NUMBER,
    title: "ARTICLE_NUMBER",
    align: "left",
  },
  {
    id: 2,
    key: TableColumn.NAME,
    title: "NAME",
    align: "left",
  },
  {
    id: 3,
    key: TableColumn.VAT_AMOUNT,
    title: "VAT_AMOUNT",
    align: "right",
  },
  {
    id: 4,
    key: TableColumn.DISCOUNT,
    title: "DISCOUNT",
    align: "right",
  },
  {
    id: 5,
    key: TableColumn.ORDERLINE_TOTAL,
    title: "ORDERLINE_TOTAL",
    align: "right",
  },
];
