import { initialState } from "us.collection.economy/reducers/PaymentsImportSummary/State";
import { PaymentsImportSummary } from "us.collection.economy/constants/Actions";

const {
  GET_PAYMENTS_IMPORT_SUMMARY,
  GET_PAYMENTS_IMPORT_SUMMARY_FAIL,
  GET_PAYMENTS_IMPORT_SUMMARY_SUCCESS,
} = PaymentsImportSummary;

export default (state = initialState, { payload, type }: any) => {
  switch (type) {
    case GET_PAYMENTS_IMPORT_SUMMARY:
      return Object.assign({}, state, {
        summary: payload,
      });
    case GET_PAYMENTS_IMPORT_SUMMARY_SUCCESS:
      return Object.assign({}, state, {
        summary: payload,
      });
    case GET_PAYMENTS_IMPORT_SUMMARY_FAIL:
      return Object.assign({}, state, {
        summary: {
          data: [],
          isLoading: false,
        },
      });
    default:
      return state;
  }
};
