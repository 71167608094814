import React from "react";
import { $Table, $Checkbox, $Button } from "us.common/components";
import { RemitStatus } from "us.collection.economy/components/Remit/Components/CreditorSelector/Constants";
import {
  getCreditorNumbers,
  getTableData,
} from "us.collection.economy/components/Remit/Components/CreditorSelector/Functions";
import { ICreditor } from "us.collection.economy/interfaces/Remit/IRemitProps";
import { Columns } from "us.collection.economy/components/Remit/Components/CreditorSelector/Components/CreditorTable/Components";
import { ICreditorTable } from "us.collection.economy/components/Remit/Components/CreditorSelector/Interfaces";
import { useTranslation } from "react-i18next";

const CreditorTable = ({
  creditors,
  selectedCreditors,
  creditorGroups,
  ineligibleCreditors,
  searchData,
  viewIneligible,
  onNavigate,
  onSelectAll,
  onSelect,
  onResetFilters,
  onChange,
  values,
}: ICreditorTable) => {
  const { t } = useTranslation();

  // Handle multiple row selection in table
  const rowSelection = () => {
    return {
      getCheckboxProps: (record: ICreditor) => ({
        disabled: record.remitStatus === RemitStatus.INELIGIBLE,
        key: record.creditorNo,
      }),
      onChange: (selectedRowKeys: any[]) => {
        onSelect(selectedRowKeys);
      },
      selectedRowKeys: selectedCreditors,
      preserveSelectedRowKeys: true,
    };
  };

  return (
    <>
      <div className="d-flex flex-fill justify-content-end">
        <$Button
          onClick={() => onResetFilters && onResetFilters()}
          size="small"
          className="mt-2 mb-1"
          disabled={
            searchData.filterTable == null && searchData.columnFilters == null
          }
        >
          {t("COMMON.RESET_ALL")}
        </$Button>
      </div>
      <$Table
        rowKey={(record) => record.creditorNo}
        rowSelection={{
          type: "checkbox",
          columnTitle: (
            <$Checkbox
              name="isAllCreditors"
              disabled={viewIneligible.isVisible}
              checked={
                getCreditorNumbers(creditors)?.length !== 0 &&
                selectedCreditors?.length ===
                  getCreditorNumbers(creditors)?.length
              }
              onChange={onSelectAll}
            />
          ),
          ...rowSelection(),
        }}
        columns={Columns(values, creditorGroups, onNavigate)}
        pagination={{ defaultPageSize: 25 }}
        dataSource={getTableData(
          viewIneligible.isVisible,
          creditors,
          searchData.filterTable,
          ineligibleCreditors
        )}
        onChange={onChange}
        bordered
      />
    </>
  );
};

export default CreditorTable;
