import { IInitialState } from "../Interfaces";
import { FileTypes } from "us.collection.economy/components/ImportPayments/Constants";

export const initialState: IInitialState = {
    initialValues: {
    fileType: FileTypes.OCR
  },
  fileList: [],
  upload: {
    data: {},
    isLoading: false,
  },

  importFiles: {
    isLoading: false,
  },
};
