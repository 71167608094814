import React from "react";
import "us.collection.economy/components/ClientInvoices/Home.scss";
import {
  IInvoice,
  IInvoiceAction,
} from "us.collection.economy/components/ClientInvoices/Interfaces";
import { IInvoiceList } from "./interface";
import { InvoiceItem } from "./Components";
import { InvoiceAction } from "us.collection.economy/components/ClientInvoices/Constants";
import { hasFileToDownload } from "us.collection.economy/components/ClientInvoices/Functions";

const InvoiceList: React.FC<IInvoiceList> = ({
  invoices,
  pending = false,
  selectedInvoices,
  onSelectInvoice,
  onCallAction,
}) => {
  /**
   * @description Get available invoice item actions
   * @param {IInvoice} invoice - Invoice item
   * @returns {IInvoiceAction[]} Available invoice actions array
   */
  const getInvoiceActions = (invoice: IInvoice): IInvoiceAction[] => {
    const actions: IInvoiceAction[] = [InvoiceAction.PRINT];
    try {
      if (!invoice.isCreditInvoice) {
        actions.push(InvoiceAction.CREDIT_NOTE);
      }
      if (hasFileToDownload(invoice)) {
        actions.push(InvoiceAction.DOWNLOAD);
      }
      return actions;
    } catch (error) {
      return actions;
    }
  };

  return (
    <div
      className="invoices-item"
      data-testid={
        pending ? "pending-export-invoice-items" : "exported-invoice-items"
      }
    >
      {invoices?.map((invoice: IInvoice) => {
        return (
          <InvoiceItem
            key={invoice.invoiceId}
            invoice={invoice}
            actions={
              pending ? [InvoiceAction.CREDIT_NOTE] : getInvoiceActions(invoice)
            }
            selectedInvoices={selectedInvoices}
            onSelectInvoice={onSelectInvoice}
            onCallAction={onCallAction}
          />
        );
      })}
    </div>
  );
};

export default InvoiceList;
