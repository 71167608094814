import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { connect, ConnectedProps } from "react-redux";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { PlusOutlined } from "us.icons";
import "./Home.scss";
import { Table, Details } from "./Components";
import { useHistory } from "react-router-dom";
import { Drawer } from "./Interfaces";
import {
  DRAWER_DEFAULT_STATE,
  CoveringSequenceAction,
  CoveringSequenceRecord,
} from "./Constants";
import { getDrawerTitle } from "./Functions";
import * as Actions from "us.collection.economy/actions";
import { handleGoBack } from "us.helper/utility";

const { $PageHeader, $Button, $Divider, $Affix, $Drawer, $Skeleton } =
  Common.Components;

/**
 * @description - Covering Sequence List Show in the table view
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3054534676/Covering+Sequence+List
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 18/07/2022
 */
const Home: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const {
    isListLoading,
    getCoveringSequenceList,
    resetDrawerData,
    deleteCoveringSequenceData,
  } = props;
  const history = useHistory();
  const { replace } = history;
  const [drawer, setDrawer] = useState<Drawer>(DRAWER_DEFAULT_STATE);

  useEffect(() => {
    getCoveringSequenceList && getCoveringSequenceList({});
  }, []);

  /**
   * @description - Update the browser tab url according to given id value and action value
   * @param {number} id - sequence id if available
   * @param {CoveringSequenceAction} action - action if available
   */
  const updateUrl = (id?: string, action?: CoveringSequenceAction) => {
    // update the URL params
    replace({
      pathname: "/covering-sequence",
      search: id
        ? `?sequenceId=${id}${action ? `&action=${action}` : ""}`
        : `${action ? `?action=${action}` : ""}`,
    });
  };

  /**
   * @description - Open a drawer by Type
   * @param {CoveringSequenceAction} type - Drawer action type
   */
  const openDrawer = (type: CoveringSequenceAction, id?: string) => {
    updateUrl(id, type);
    setDrawer({
      ...DRAWER_DEFAULT_STATE,
      title: t(getDrawerTitle(type)),
      visible: true,
    });
  };

  /**
   * @description - close the opened drawer
   */
  const closeDrawer = () => {
    resetDrawerData && resetDrawerData();
    updateUrl();
    setDrawer(DRAWER_DEFAULT_STATE);
  };

  /**
   * @description Handle Convering Sequence table item action
   * @param record - Convering Sequence table record data
   * @param actionType - Convering Sequence action type
   */
  const handleCoveringSequenceRecordAction = (
    record: CoveringSequenceRecord,
    actionType: CoveringSequenceAction
  ) => {
    const { sequenceId } = record;

    switch (actionType) {
      case CoveringSequenceAction.EDIT:
        openDrawer(CoveringSequenceAction.EDIT, sequenceId);
        break;
      case CoveringSequenceAction.COPY:
        openDrawer(CoveringSequenceAction.COPY, sequenceId);
        break;
      case CoveringSequenceAction.DELETE:
        deleteCoveringSequenceData && deleteCoveringSequenceData(sequenceId);
        break;
      default:
        break;
    }
  };

  return (
    <Formik
      initialValues={{}}
      onSubmit={() => {}}
      enableReinitialize
      validateOnChange
      validateOnBlur
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      onBack={() => handleGoBack(history)}
                      title={t(
                        "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.COVERING_SEQUENCE"
                      )}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <$Button
                      data-testid="add-new-covering-sequence"
                      type="default"
                      size="small"
                      icon={<PlusOutlined />}
                      onClick={() => openDrawer(CoveringSequenceAction.ADD)}
                    >
                      {t(
                        "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.NEW_SEQUENCE"
                      )}
                    </$Button>
                  </div>
                </div>
              </div>
            </$Affix>
            <div>
              <$Skeleton loading={isListLoading} paragraph={{ rows: 2 }}>
                <Table onCallAction={handleCoveringSequenceRecordAction} />
              </$Skeleton>
            </div>
          </div>
          <$Drawer
            data-testid="drawer"
            title={t(drawer.title)}
            width={drawer.width}
            visible={drawer.visible}
            onClose={closeDrawer}
            destroyOnClose
            maskClosable={false}
          >
            <Details onCancel={closeDrawer} />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { coveringSequence } = state;
  const { isListLoading, saveSequence } = coveringSequence;
  return {
    isListLoading,
    saveSequence,
  };
};

const { coveringSequenceList, newCoveringSequence, coveringSequenceData } =
  Actions.coveringSequence;

const { get } = coveringSequenceList;
const { resetDrawerData } = newCoveringSequence;

const mapDispatchToProps = {
  getCoveringSequenceList: get,
  resetDrawerData: resetDrawerData,
  deleteCoveringSequenceData: coveringSequenceData.deleteById,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Home);
