import { take, put, call, select, takeLatest, all } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import { ManualOrders } from "us.collection.economy/constants/Actions";
import { DefaultVatStatus } from "us.collection.economy/components/ManualOrders/Constants"
import Common from "us.common";
import { $Message } from "us.common/components";

const { $MessageBox } = Common.Components;
const { manualOrders } = Actions;
const {
  article,
  articleList,
  orderLines,
  caseNoValidation,
  invoicedOrderLines,
  creditorValidation,
  nextOrderId,
  orderDetails,
  existingOrderLines,
  vatAmounts,
} = manualOrders;

const {
  getArticlesById,
  saveOrderLines,
  getAllArticles,
  saveAndInvoiceOrderLines,
  getValidateCaseId,
  exportOrderLines,
  validateCreditor,
  getNextOrderIdByTableName,
  getOrderDetails,
  updateExistingOrderLines,
  updateAndInvoiceExistingOrderLines,
  getVatAmountsByCreditor,
} = API.manualOrders.manualOrders;

$Message.config({
  maxCount: 1,
});

const ManualOrderSagas = {
  manualOrder: {
    getArticleById: function* (action: any) {
      const { articleNo, rowKey, creditorVATStatus, pid, units, user } =
        action?.payload?.data ?? "";
      try {
        const { data, status } = yield call(getArticlesById, articleNo);
        if (status == 200) {
          if (data.length == 1) {
            $MessageBox(
              "success",
              `US.COLLECTION.ECONOMY:ORDERS.VALID_ARTICLE_NO`,
              "",
              ""
            );
            const isEligibleForVat =
              data[0].vatCode != 0 &&
              data[0].defaultPrice != 0 &&
              pid != 0
            yield put(
              article.success({ data, rowKey, isEligibleForVat, user })
            );
            if (
              data[0].vatCode != 0 &&
              data[0].defaultPrice != 0 &&
              pid != 0
            ) {
              const vatParams = {
                vatCode: data[0].vatCode,
                vatStatus: DefaultVatStatus,
                amount: units * data[0].defaultPrice,
                creditorNo: pid,
              };
              const vatData = yield call(getVatAmountsByCreditor, vatParams);
              if (vatData.status == 200) {
                yield put(vatAmounts.success({ data: vatData.data, rowKey }));
              }
            }
          }
        } else if (status != 200) {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ORDERS.INVALID_ARTICLE_NO",
            "",
            ""
          );
          yield put(article.fail({ rowKey }));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ORDERS.SOMETHING_WRONG",
            "",
            ""
          );
          yield put(article.fail({ rowKey }));
        }
      } catch (error) {
        yield put(article.fail(new Error()));
      }
    },
    getArticleList: function* (action: any) {
      try {
        const { data, status } = yield call(getAllArticles);
        if (status == 200) {
          yield put(articleList.success(data));
        } else if (status != 200) {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ORDERS.ARTICLES__LOADING_FAILED",
            "",
            ""
          );
          yield put(articleList.fail(new Error()));
        }
      } catch (error) {
        yield put(articleList.fail(new Error()));
      }
    },
    orderLines: function* (action: any) {
      const params = action?.payload?.data ?? {};
      const { saveAndInvoice, payload } = params;
      try {
        if (saveAndInvoice) {
          const { data, status } = yield call(
            saveAndInvoiceOrderLines,
            payload
          );
          if (status == 200) {
            $MessageBox(
              "success",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_SAVE_AND_INVOICE_SUCCESSFULLY",
              "",
              ""
            );
            yield put(orderLines.success({ ...data, saveAndInvoice }));
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_SAVE_AND_INVOICE_FAILED",
              "",
              ""
            );
            yield put(orderLines.fail({}));
          }
        } else {
          const { data, status } = yield call(saveOrderLines, payload);
          if (status == 200) {
            $MessageBox(
              "success",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_SAVE_SUCCESSFULLY",
              "",
              ""
            );
            yield put(orderLines.success({ ...data, saveAndInvoice }));
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_SAVE_FAILED",
              "",
              ""
            );
            yield put(orderLines.fail({}));
          }
        }
      } catch (error) {
        yield put(orderLines.fail(new Error()));
      }
    },
    caseNoValidation: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const validationResults = yield call(getValidateCaseId, params.val);
        yield put(
          caseNoValidation.success({
            validationResults: {
              ...validationResults,
              isValidCaseNo: params.creditorId == validationResults.pid,
              caseNo: params.val,
            },
            rowKey: params.rowKey,
          })
        );
        if (Object.keys(validationResults).length === 0) {
          $MessageBox(
            "error",
            "US.COLLECTION.VALIDATIONS:INVALID.INVALID_CASE_NO",
            "",
            ""
          );
        } else if (params.creditorId != validationResults.pid) {
          $MessageBox(
            "error",
            "US.COLLECTION.VALIDATIONS:INVALID.CASE_NO_DOESNT_MATCH_WITH_CREDITOR",
            "",
            ""
          );
        }
      } catch (error) {
        yield put(caseNoValidation.fail(new Error()));
      }
    },
    invoicedOrderLines: function* (action: any) {
      const params = action?.payload?.data ?? {};
      try {
        const { data, status } = yield call(exportOrderLines, params);
        if (status === 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:ORDERS.ORDER_LINE_EXPORTED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(invoicedOrderLines.success(data));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ORDERS.ORDER_LINE_EXPORT_FAILED",
            "",
            ""
          );
          yield put(invoicedOrderLines.fail({}));
        }
      } catch (error) {
        yield put(invoicedOrderLines.fail(new Error()));
      }
    },
    creditorValidation: function* (action: any) {
      const { creditorId, vatCode, amount } = action?.payload?.data ?? {};
      try {
        const validationResults = yield call(validateCreditor, creditorId);
        if (
          Object.keys(validationResults).length === 0 ||
          validationResults[0].roleType !== "CRE"
        ) {
          $MessageBox(
            "error",
            "US.COLLECTION.VALIDATIONS:INVALID.INVALID_CREDITOR_NO",
            "",
            ""
          );
          yield put(creditorValidation.fail(new Error()));
        } else {
          yield put(creditorValidation.success(validationResults[0]));
          $MessageBox(
            "success",
            "US.COLLECTION.VALIDATIONS:INVALID.VALID_CREDITOR_NO",
            "",
            ""
          );
          if (
            vatCode != 0 &&
            amount != 0 &&
            creditorId != 0
          ) {
            const vatParams = {
              vatCode: vatCode,
              vatStatus: DefaultVatStatus,
              amount: amount,
              creditorNo: creditorId,
            };
            const vatData = yield call(getVatAmountsByCreditor, vatParams);
            if (vatData.status == 200) {
              yield put(vatAmounts.success({ data: vatData.data, rowKey: 0 }));
            }
          }
        }
      } catch (error) {
        yield put(creditorValidation.fail(new Error()));
      }
    },
    getNextOrderId: function* (action: any) {
      try {
        const { data, status } = yield call(getNextOrderIdByTableName);
        if (status === 200) {
          yield put(nextOrderId.success(data));
        } else {
          yield put(nextOrderId.fail(new Error()));
        }
      } catch (error) {
        yield put(nextOrderId.fail(new Error()));
      }
    },
    getOrderDetailsForEdit: function* (action: any) {
      const params = action?.payload?.data ?? "";
      try {
        const { data, status } = yield call(getOrderDetails, params?.orderId);
        if (status === 200) {
          yield put(orderDetails.success(data));
          const validationResults = yield call(
            validateCreditor,
            data.customerNo
          );
          yield put(creditorValidation.success(validationResults[0]));
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ORDERS.ORDER_DETAILS_LOADING_FAILED",
            "",
            ""
          );
          yield put(orderDetails.fail(new Error()));
        }
      } catch (error) {
        yield put(orderDetails.fail(new Error()));
      }
    },
    updateOrderLines: function* (action: any) {
      const params = action?.payload?.data ?? {};
      const { saveAndInvoice, payload } = params;
      try {
        if (saveAndInvoice) {
          const { data, status } = yield call(
            updateAndInvoiceExistingOrderLines,
            payload
          );
          if (status == 200) {
            $MessageBox(
              "success",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_UPDATE_SUCCESSFULLY",
              "",
              ""
            );
            yield put(existingOrderLines.success({ ...data, saveAndInvoice }));
          } else if (status == 412) {
            $MessageBox(
              "warning",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_ALREADY_INVOICED",
              "",
              ""
            );
            yield put(existingOrderLines.fail(new Error()));
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_UPDATE_FAILED",
              "",
              ""
            );
            yield put(existingOrderLines.fail(new Error()));
          }
        } else {
          const { data, status } = yield call(
            updateExistingOrderLines,
            payload
          );
          if (status == 200) {
            $MessageBox(
              "success",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_UPDATE_SUCCESSFULLY",
              "",
              ""
            );
            yield put(existingOrderLines.success({ ...data, saveAndInvoice }));
          } else if (status == 412) {
            $MessageBox(
              "warning",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_ALREADY_INVOICED",
              "",
              ""
            );
            yield put(existingOrderLines.fail(new Error()));
          } else {
            $MessageBox(
              "error",
              "US.COLLECTION.ECONOMY:ORDERS.ORDER_UPDATE_FAILED",
              "",
              ""
            );
            yield put(existingOrderLines.fail(new Error()));
          }
        }
      } catch (error) {
        yield put(existingOrderLines.fail(new Error()));
      }
    },
    getVatAmounts: function* (action: any) {
      const params = action?.payload?.data ?? "";
      try {
        const { data, status } = yield call(
          getVatAmountsByCreditor,
          params.vatParams
        );
        if (status == 200) {
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:ORDERS.VAT_CALCULATED_SUCCESSFULLY",
            "",
            ""
          );
          yield put(
            vatAmounts.success({
              data,
              rowKey: params.rowKey,
              label: params.label,
              value: params.value,
            })
          );
        } else if (status != 200) {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:ORDERS.VAT_CALCULATION_FAILED",
            "",
            ""
          );
          yield put(vatAmounts.fail({ rowKey: params.rowKey }));
        }
      } catch (error) {
        yield put(vatAmounts.fail({ rowKey: params.rowKey }));
      }
    },
  },
};

export default [
  takeLatest(
    ManualOrders.GET_ARTICLE_BY_NO,
    ManualOrderSagas.manualOrder.getArticleById
  ),
  takeLatest(
    ManualOrders.GET_ARTICLE_LIST,
    ManualOrderSagas.manualOrder.getArticleList
  ),
  takeLatest(
    ManualOrders.SAVE_ORDER_LINE,
    ManualOrderSagas.manualOrder.orderLines
  ),
  takeLatest(
    ManualOrders.VALIDATE_CASE_NO,
    ManualOrderSagas.manualOrder.caseNoValidation
  ),
  takeLatest(
    ManualOrders.EXPORT_ORDER_LINE,
    ManualOrderSagas.manualOrder.invoicedOrderLines
  ),
  takeLatest(
    ManualOrders.VALIDATE_CREDITOR_BY_ID,
    ManualOrderSagas.manualOrder.creditorValidation
  ),
  takeLatest(
    ManualOrders.GET_NEXT_ORDER_ID,
    ManualOrderSagas.manualOrder.getNextOrderId
  ),
  takeLatest(
    ManualOrders.GET_ORDER_DETAILS,
    ManualOrderSagas.manualOrder.getOrderDetailsForEdit
  ),
  takeLatest(
    ManualOrders.UPDATE_ORDER_LINE,
    ManualOrderSagas.manualOrder.updateOrderLines
  ),
  takeLatest(
    ManualOrders.GET_VAT_AMOUNTS,
    ManualOrderSagas.manualOrder.getVatAmounts
  ),
];
