import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { MoreOutlined } from "us.icons";
import Common from "us.common";
import { getRouteUrl } from "us.helper";
import { $AmountLabel } from "us.common/components";
import { RemitProposalTableProps } from "./Interfaces";
import {
  RemitSummaryTableColumn,
  REMIT_PROPOSAL_TABLE_COLUMNS,
} from "./Constants";
import { ItemMenu } from "./Components";
import {
  RemitProposalActionType,
  RemitRecord,
} from "us.collection.economy/components/Remit/Interfaces";
import {
  ITEM_MENU_DEFAULT_STATE,
  RemitProposalAction,
} from "us.collection.economy/components/Remit/Constants";
import {
  getRemitProposalRecordActions,
  getTableColumnTotal,
  isMinusRemitCreditorValue,
} from "us.collection.economy/components/Remit/Functions";
import { isErrorCreditor } from "./Functions";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";

const { $Button, $Empty, $Popover, $Tag, $TableTree } = Common.Components;

/**
 * @description - Remit proposal table to manage records before remitting
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2858189310/Remit+Proposal+UI+Design+and+Implementations
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 08/03/2022
 */
const RemitProposalTable: React.FC<RemitProposalTableProps & PropsFromRedux> =
  ({ remitProposal, selectedRowKey, remitValidation, onCallAction }) => {
    const { t } = useTranslation();
    const [itemMenu, setItemMenu] = useState<{
      visible: boolean;
      id: string;
    }>(ITEM_MENU_DEFAULT_STATE);
    const { remitRecords } = remitProposal.data ?? {};

    // auto close invoice item menu after 5 seconds
    const setAutoClose = (id: string) => {
      setTimeout(() => {
        setItemMenu({
          visible: false,
          id,
        });
      }, 5000);
    };

    /**
     * @description - Get row style class name to highlight the row
     * @param {RemitRecord} record - Table row record
     * @returns {string} class
     */
    const getRowClass = (record: RemitRecord): string => {
      try {
        if (record.creditorNo === selectedRowKey) {
          return "ant-table-row-selected";
        }
        if (
          isErrorCreditor(record, remitValidation.data) ||
          isMinusRemitCreditorValue(record)
        ) {
          return "error-table-row-bg";
        }
        return "";
      } catch (error) {
        return "";
      }
    };

    // get table columns
    const getColumns: any = () => {
      const columns: Array<any> = [];
      REMIT_PROPOSAL_TABLE_COLUMNS.map(({ key, title, align }: any) => {
        let column: any = { key, dataIndex: key, align };
        if (key === RemitSummaryTableColumn.MENU) {
          column = {
            ...column,
            title: title ? t(`US.COLLECTION.ECONOMY:REMIT.${title}`) : "",
            width: "50px",
            customRenderChild: (text: any, record: RemitRecord) => {
              return (
                <$Popover
                  placement="bottomLeft"
                  trigger="click"
                  visible={
                    itemMenu.visible && itemMenu.id === record.creditorNo
                  }
                  onVisibleChange={(visible: boolean) => {
                    setItemMenu({
                      ...itemMenu,
                      visible,
                      id: record.creditorNo,
                    });
                    setAutoClose(record.creditorNo);
                  }}
                  content={
                    <ItemMenu
                      actions={getRemitProposalRecordActions(record)}
                      onCallAction={(actionType: RemitProposalActionType) => {
                        setItemMenu(ITEM_MENU_DEFAULT_STATE);
                        onCallAction(record, actionType);
                      }}
                    />
                  }
                  destroyTooltipOnHide
                >
                  <$Button
                    id="btnItemMenu"
                    type="default"
                    icon={<MoreOutlined />}
                    size="small"
                  />
                </$Popover>
              );
            },
          };
        } else if (key === RemitSummaryTableColumn.CREDITOR_NO) {
          column = {
            ...column,
            title: title ? t(`US.COLLECTION.ECONOMY:REMIT.${title}`) : "",
            customFilter: true,
            showFilters: true,
            customRenderChild: (text: any, record: RemitRecord) => {
              return (
                <a
                  data-testid="link-creditor-no"
                  onClick={() => getRouteUrl.creditor(record.creditorNo)}
                >
                  {text}
                </a>
              );
            },
            customSorter: (a: any, b: any) => a - b,
          };
        } else if (key === RemitSummaryTableColumn.VAT_DEDUCTED) {
          column = {
            ...column,
            with: "100px",
            title: () => (
              <div className="mr-2">
                <div>{t(`US.COLLECTION.ECONOMY:REMIT.VAT_DEDUCTED`)}</div>
                <div
                  className="header-custom-amount"
                  data-testid="vat-deducted-total"
                >
                  <$AmountLabel
                    value={getTableColumnTotal(key, remitRecords)}
                  />
                </div>
              </div>
            ),
            customRenderChild: (
              text: any,
              { isInvoiced, vatDeducted }: RemitRecord
            ) => {
              return (
                <div data-testid="vat-deducted-amount">
                  {isInvoiced && (
                    <$Tag color={"green"}>
                      {t("US.COLLECTION.ECONOMY:REMIT.INVOICED")}
                    </$Tag>
                  )}
                  <$AmountLabel value={vatDeducted} />
                </div>
              );
            },
            customSorter: (a: any, b: any) => a - b,
          };
        } else {
          column = {
            ...column,
            with: "100px",
            title: () => (
              <div className="mr-2">
                <div>
                  {title ? t(`US.COLLECTION.ECONOMY:REMIT.${title}`) : ""}
                </div>
                <div
                  className="header-custom-amount"
                  data-testid={`${key}Total`}
                >
                  <$AmountLabel
                    value={getTableColumnTotal(key, remitRecords)}
                  />
                </div>
              </div>
            ),
            customRenderChild: (text: any, record: any) => {
              return (
                <span data-testid={`${key}Amount`}>
                  <$AmountLabel value={record[key]} />
                </span>
              );
            },
          };
        }
        columns.push(column);
      });
      return columns;
    };

    return (
      <$TableTree
        rowKey={(record: RemitRecord) => record.creditorNo}
        data={remitRecords}
        size="small"
        className="mt-3 header-custom-tag"
        rowClassName={getRowClass}
        onSort={(sortData, dataSource) => {
          return sortData(dataSource);
        }}
        onFilter={(searchData, dataSource) => {
          return searchData(dataSource);
        }}
        filterOnType
        resetOnSourceChange
        bordered
        showResetAllFilters
        pagination={{
          defaultPageSize: 20,
        }}
        scroll={{
          x: 900,
          y: "calc(100vh - 265px)",
        }}
        columns={getColumns()}
        onRow={(record, _rowIndex) => {
          return {
            onDoubleClick: (_event) =>
              onCallAction(record, RemitProposalAction.VIEW_REMIT_DETAILS),
          };
        }}
        locale={{
          emptyText: (
            <$Empty
              image={$Empty.PRESENTED_IMAGE_SIMPLE}
              description={
                <div className="text-center">
                  <div>{t("US.COLLECTION.COMMON:COMMON.NO_DATA")}</div>
                  <div>
                    {t(
                      "US.COLLECTION.ECONOMY:REMIT.SELECT_CREDITORS_AND_THE_CORRECT_BANK_ACCOUNT_TO_VIEW_REMIT"
                    )}
                  </div>
                </div>
              }
            />
          ),
        }}
      />
    );
  };

const mapStateToProps = (state: IRootState) => {
  const { remit } = state;

  const { remitValidation, validationState, remitProposal } = remit;
  return {
    validationState,
    remitValidation,
    remitProposal,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemitProposalTable);
