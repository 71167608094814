import React, { useEffect } from "react";
import { connect, ConnectedProps } from "react-redux";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import Common from "us.common";
import { AccountsValidationSchema } from "us.collection.economy/components/ClientAccounts/Validations";
import * as Actions from "us.collection.economy/actions";
import { IRootState } from "us.collection/interfaces";
import { SaveClientAccounts } from "us.collection.economy/components/ClientAccounts/Repository";
import {
  getClientAccountsById,
  isFormDirty,
  getButtonTitle,
} from "us.collection.economy/components/ClientAccounts/Functions";
import { useLocation } from "react-router-dom";
import { ClientAccountsAction } from "us.collection.economy/components/ClientAccounts/Constants";
import { IAddEditClientAccount } from "us.collection.economy/components/ClientAccounts/Interfaces";

const {
  $Switch,
  $Button,
  $Form,
  $Row,
  $Col,
  $Select,
  $Input,
  $AsyncInput,
  $AsyncInputAccountNo,
  $Popconfirm,
} = Common.Components;

/**
 * @description - Drawer View for Add, Edit Client Accounts
 * @link https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2835709953/Client+Accounts
 * @author Kaushalya Sandamali <kaushalyas@unicorn-solutions.com>
 * @since 20/06/2022
 */
const AddEditClientAccount: React.FC<PropsFromRedux & IAddEditClientAccount> = (
  props
) => {
  const { t } = useTranslation();

  const { search } = useLocation();
  const urlParams = new URLSearchParams(search);
  const action = urlParams.get("action");
  const isEdit = action === ClientAccountsAction.EDIT;
  const accountId = urlParams.get("accountId");

  const {
    initDetails,
    ledgerAccounts,
    addNewClientAccount,
    onCancel,
    clientAccountsList,
    updateClientAccounts,
    remitSequenceDetails,
    ledgerSequenceDetails,
    validateAccountNo,
    isValidAccountNo,
    resetAccountNoValidation,
    validateJournalPrefix,
    resetJournalPrefixValidation,
    isValidJournalPrefix,
    accountSaveUpdate,
  } = props;

  useEffect(() => {
    initDetails && initDetails({});
  }, []);

  useEffect(() => {
    if (
      !accountSaveUpdate.isLoading &&
      accountSaveUpdate.data.hasOwnProperty("accountId")
    ) {
      onCancel();
    }
  }, [accountSaveUpdate.isLoading]);

  /**
   * @description - Handle form submit
   * @param {any} values - Form values
   * @param {any} actions - Form Actions
   */
  const handleSubmit = (values: any, actions: any) => {
    actions.setSubmitting(true);
    const payload = SaveClientAccounts.call({ ...values, accountId });
    if (isEdit) {
      updateClientAccounts && updateClientAccounts(payload);
    } else {
      addNewClientAccount && addNewClientAccount(payload);
    }
  };

  /**
   * @description - clear account no input value
   */
  const onClearAccountNoInput = () => {
    resetAccountNoValidation && resetAccountNoValidation({});
  };

  /**
   * @description - clear journal prefix input
   */
  const onClearJournalPrefixInput = () => {
    resetJournalPrefixValidation && resetJournalPrefixValidation({});
  };

  return (
    <Formik
      initialValues={{
        ...getClientAccountsById(clientAccountsList, Number(accountId)),
      }}
      enableReinitialize
      validationSchema={AccountsValidationSchema}
      validateOnBlur
      validateOnChange
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical" autoComplete="off" onSubmit={handleSubmit}>
            <$Row gutter={16} className="mb-2">
              <$Col span={12}>
                <$AsyncInputAccountNo
                  label={t(
                    "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.ACCOUNT_NUMBER"
                  )}
                  className="w-100"
                  name="accountNo"
                  placeholder={t(
                    "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.ACCOUNT_NUMBER"
                  )}
                  size="small"
                  required={true}
                  allowClear
                  onBlur={(e: any) => {
                    if (e.target.value !== "" && e.target.value != 0) {
                      const accountNum = e.target.value.replace(/ /g, "");
                      validateAccountNo && validateAccountNo(accountNum);
                    }
                  }}
                  isValid={isValidAccountNo}
                  asyncError={t(
                    "US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_ACCOUNT_NUMBER"
                  )}
                  onClear={onClearAccountNoInput}
                  disabled={isEdit && values.isUsed}
                />
              </$Col>
              <$Col span={12}>
                <$Input
                  required
                  size="small"
                  name="accountName"
                  label={t(
                    "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.ACCOUNT_NAME"
                  )}
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-2">
              <$Col span={12}>
                <$Select
                  name="ledgerAccountNumber"
                  data-testid="ledger-account"
                  formitem={{
                    label: t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.LEDGER_ACCOUNT"
                    ),
                  }}
                  size="small"
                  options={ledgerAccounts}
                  allOption={false}
                  optionValue="accountNo"
                  optionText={"accountName"}
                  onSearchBy={["accountName", "accountNo"]}
                  tabIndex={1}
                  required
                />
              </$Col>
              <$Col span={12}>
                <$Select
                  name="ledgerVoucherSequence"
                  data-testid="ledger-Voucher-Sequence"
                  formitem={{
                    label: t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.LEDGER_VOUCHER_SEQUENCE"
                    ),
                  }}
                  size="small"
                  options={ledgerSequenceDetails}
                  allOption={false}
                  optionValue="subSequenceId"
                  optionText={"text"}
                  onSearchBy={["sequenceId", "subSequenceId"]}
                  tabIndex={1}
                  required
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-2">
              <$Col span={12}>
                <$AsyncInput
                  name="remitJournalPrifix"
                  label={t(
                    "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.REMIT_JOURNAL_PREFIX"
                  )}
                  dataTestid="async-input-remitJournalPrifix"
                  placeholder={"XXXX"}
                  size="small"
                  autoFocus={false}
                  maxLength={4}
                  onBlur={(e: any) => {
                    if (e.target.value !== "" && e.target.value != 0) {
                      validateJournalPrefix &&
                        validateJournalPrefix(e.target.value);
                    }
                  }}
                  isValid={isValidJournalPrefix}
                  onClear={onClearJournalPrefixInput}
                  asyncError={t(
                    "US.COLLECTION.VALIDATIONS:INVALID.DUPLICATE_REMIT_JOURNAL_PREFIX"
                  )}
                />
              </$Col>
              <$Col span={12}>
                <$Select
                  name="remitVoucherSequence"
                  data-testid="remit-Voucher-Sequence"
                  formitem={{
                    label: t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.REMIT_VOUCHER_SEQUENCE"
                    ),
                  }}
                  size="small"
                  options={remitSequenceDetails}
                  allOption={false}
                  optionValue="subSequenceId"
                  optionText={"text"}
                  onSearchBy={["sequenceId", "subSequenceId"]}
                  tabIndex={1}
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-2">
              <$Col span={12}>
                <$Select
                  name="remitTransactionSequence"
                  data-testid="remit-Transaction-Sequence"
                  formitem={{
                    label: t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.REMIT_TRANSACTION_SEQUENCE"
                    ),
                  }}
                  size="small"
                  options={remitSequenceDetails}
                  allOption={false}
                  optionValue="subSequenceId"
                  optionText={"text"}
                  onSearchBy={["sequenceId", "subSequenceId"]}
                  tabIndex={1}
                />
              </$Col>
              <$Col span={12}>
                <$Select
                  name="remitDaySequence"
                  data-testid="remit-Day-Sequence"
                  formitem={{
                    label: t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.REMIT_DAY_SEQUENCE"
                    ),
                  }}
                  size="small"
                  options={remitSequenceDetails}
                  allOption={false}
                  optionValue="subSequenceId"
                  optionText={"text"}
                  onSearchBy={["sequenceId", "subSequenceId"]}
                  tabIndex={1}
                />
              </$Col>
            </$Row>
            <$Row gutter={16} className="mb-2">
              <$Col span={12}>
                <$Select
                  name="remitJournalSequence"
                  data-testid="remit-Journal-Sequence"
                  formitem={{
                    label: t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.REMIT_JOURNAL_SEQUENCE"
                    ),
                  }}
                  size="small"
                  options={remitSequenceDetails}
                  allOption={false}
                  optionValue="subSequenceId"
                  optionText={"text"}
                  onSearchBy={["sequenceId", "subSequenceId"]}
                  tabIndex={1}
                />
              </$Col>
              <$Col span={12}>
                <$Input
                  size="small"
                  name="enterpriseNumber"
                  label={t(
                    "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.ENTERPRISE_NUMBER"
                  )}
                  maxLength={11}
                />
              </$Col>
            </$Row>
            <$Row gutter={16}>
              <$Col span={12}>
                <div className="d-flex">
                  <label className="mr-2">
                    {t(
                      "US.COLLECTION.ECONOMY:CLIENT_ACCOUNTS.CONSIDER_FOR_ORDERLINES"
                    )}
                  </label>
                  <div>
                    <$Switch
                      size="small"
                      name="isConsideredForOrderlines"
                      checked={values?.isConsideredForOrderlines}
                    />
                  </div>
                </div>
              </$Col>
            </$Row>
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="ml-3 mr-2"
                type="primary"
                data-testid="save-btn"
                onClick={handleSubmit}
                disabled={
                  !restProps.isValid ||
                  !isValidAccountNo ||
                  !isValidJournalPrefix ||
                  !isFormDirty(
                    values,
                    getClientAccountsById(clientAccountsList, Number(accountId))
                  )
                }
                loading={accountSaveUpdate?.isLoading}
              >
                {t(getButtonTitle(isEdit))}
              </$Button>
              {isFormDirty(
                values,
                getClientAccountsById(clientAccountsList, Number(accountId))
              ) && (
                <$Popconfirm
                  title={t(
                    "US.COLLECTION.ECONOMY:COVERING_SEQUENCE.ARE_YOU_SURE_YOU_WANT_TO_DISCARD_THE_CHANGES_?"
                  )}
                  placement="topLeft"
                  onConfirm={onCancel}
                  okText={t("COMMON.YES")}
                  cancelText={t("COMMON.NO")}
                >
                  <$Button>{t("US.COLLECTION.COMMON:COMMON.CANCEL")}</$Button>
                </$Popconfirm>
              )}
              {!isFormDirty(
                values,
                getClientAccountsById(clientAccountsList, Number(accountId))
              ) && (
                <$Button onClick={onCancel}>
                  {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
                </$Button>
              )}
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};
const mapStateToProps = (state: IRootState) => {
  const { clientAccounts } = state;

  const {
    ledgerAccounts,
    remitSequenceDetails,
    ledgerSequenceDetails,
    clientAccountsList,
    isValidAccountNo,
    isValidJournalPrefix,
    accountSaveUpdate,
  } = clientAccounts;

  return {
    ledgerAccounts,
    remitSequenceDetails,
    ledgerSequenceDetails,
    clientAccountsList,
    isValidAccountNo,
    isValidJournalPrefix,
    accountSaveUpdate,
  };
};

const {
  initDetails,
  newClientAccount,
  existingClientAccount,
  accountNumber,
  journalPrefix,
} = Actions.clientAccounts;
const { get } = initDetails;
const { save } = newClientAccount;
const { update } = existingClientAccount;

const mapDispatchToProps = {
  initDetails: get,
  addNewClientAccount: save,
  updateClientAccounts: update,
  validateAccountNo: accountNumber.getById,
  resetAccountNoValidation: accountNumber.reset,
  validateJournalPrefix: journalPrefix.getById,
  resetJournalPrefixValidation: journalPrefix.reset,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddEditClientAccount);
