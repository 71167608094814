export enum ItemTypeIds {
    EXCEEDED_AMOUNT = 15,
    RETURN_TO_DEBTOR = 28,
    RETURN_FEE = 25,
    COURT_FEE = 24,
    INTEREST_ON_COURT_AMOUNT = 803,
    CORRECTION_PAID_CODE_FEE = 504,
    ROUNDING_OFF = 48,
    MAIN_AMOUNT = 3,
    MAIN_AMOUNT_INTEREST = 801
}

export enum TransTypes {
    EXCEEDED_AMOUNT = 'AE',
    RETURN_TO_DEBTOR = 'RD',
    RETURN_FEE = 'RD',
    ROUNDING_OFF = 'CBS',
    MAIN_AMOUNT = 'IN',
    MAIN_AMOUNT_INTEREST = 'IN'
}

export enum GroupTextTypes {
    EXCEEDED_AMOUNT = 'EM',
    RETURN_TO_DEBTOR = 'RD',
    RETURN_FEE = 'RD',
    ROUNDING_OFF = 'CBS',
    MAIN_AMOUNT = 'MA',
    COLLECTION_FEE = 'CF',
    COURT_FEE = 'CRF',
    CASE_COST = 'CC',
    INTEREST = 'IN'
}

export enum AmountTypes {
    CREDITOR_AMOUNT = 'creditorAmount',
    BUREAU_AMOUNT = 'bureauAmount',
    DEBTOR_AMOUNT = 'debtorAmount',
    OTHER_AMOUNT = 'otherAmount'
}

export enum TotalAmountTypes {
    TOTAL_CREDITOR_AMOUNT = 'totalCreditorAmount',
    TOTAL_BUREAU_AMOUNT = 'totalBureauAmount',
    TOTAL_DEBTOR_AMOUNT = 'totalDebtorAmount',
    TOTAL_OTHER_AMOUNT = 'totalOtherAmount'
}

export enum TotalAmountByText {
    TOTAL_CREDITOR_AMOUNT_BY_TEXT = 'totalCreditorAmountByText',
    TOTAL_BUREAU_AMOUNT_BY_TEXT = 'totalBureauAmountByText',
    TOTAL_DEBTOR_AMOUNT_BY_TEXT = 'totalDebtorAmountByText',
    TOTAL_OTHER_AMOUNT_BY_TEXT = 'totalOtherAmountByText',
    TRANSACTION_BALANCE_BY_TEXT = 'totalTransBalanceByText',
}

export enum TransactionTexts {
    EXCEEDED_AMOUNT = 'Exceeded Amount',
    RETURN_TO_DEBTOR = 'Return To Debtor',
    RETURN_FEE = 'Return Fee',
    ROUNDING_OFF = 'Rounding Off',
    PROVISION = 'Provision'
}

export enum VATAmountInfo {
    TOTAL_VAT_AMOUNT = 'totalVatAmount',
    TOTAL_VAT_BASIS = 'totalVatBasis',
    TOTAL_VAT_DEDUCTED = 'totalVatDeducted',
    TOTAL_VAT_PAID_BY_DEBTOR = 'totalVatPaidByDebtor',
    VAT_AMOUNT = 'vatAmount',
    VAT_BASIS = 'vatBasis',
    VAT_DEDUCTED = 'vatDeducted',
    VAT_PAID_BY_DEBTOR = 'vatPaidByDebtor',
    TOTAL_VAT_AMOUNT_BY_TEXT = 'totalVatAmountByText',
    TOTAL_VAT_BASIS_BY_TEXT = 'totalVatBasisByText',
    TOTAL_VAT_DEDUCTED_BY_TEXT = 'totalVatDeductedByText',
    TOTAL_VAT_PAID_BY_DEBTOR_BY_TEXT = 'totalVatPaidByDebtorByText',
}

export enum PropertyKeys {
    AMOUNT = 'amount',
    TOTAL = 'total',
    CASE_NO = 'caseNo',
    TEXT = 'text',
    TRANSACTION_BALANCE = 'transactionBalance',
    GROUP_TEXT = 'groupText'
}

// export const CoveringOrder = ['MA', 'CF', 'CRF', 'CC', 'OTHER', 'IN', 'CBS', 'RD', 'EM'] // 'Rounding Off'
export const CoveringOrder = ['Main Amount', 'Collection Fee', 'Court Fee', 'Case Cost', 'Other Cost', 'Interest', 'Rounding Off', 'Return To Debtor', 'Exceeded Amount'] // 'Rounding Off'

export enum VatStatus {
    REFUNDABLE = 'Refundable',
    NON_REFUNDABLE = 'NonRefundable'
}

export enum TransactionGroupId {
    COURT_FEE = '5'
}