import { httpCollection } from "us.helper/http/collection";

export default {
  payments: {
    upload: <P extends { file: any; fileType: string }>({
      file,
      fileType,
    }: P): Promise<any> => {
      const formData = new FormData();
      formData.append("fileType", fileType);
      formData.append("file", file[0]);

      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.post(
            "paymentimportservice",
            `payment/paymentfile`,
            formData
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
    import: (params: any): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data } = await httpCollection.post(
            "paymentimportservice",
            `payment/importpaymentsfile`,
            params
          );

          resolve(data);
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
