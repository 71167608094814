import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { PlusOutlined } from "us.icons";
import { $Drawer } from "us.common/components";
import { Details } from "./Components/Details";
import { Table } from "./Components/Table";
import { Action, DEFAULT_DRAWER_STATE, SearchType } from "./Constants";
import { getDrawerTitle } from "./Functions";
import { LedgerDetails, ActionType, ILedgerAccounts } from "./Interfaces";
import * as Actions from "us.collection.economy/actions";
import { IRootState } from "us.collection/interfaces";
import { handleGoBack } from "us.helper/utility";
import { useHistory } from "react-router-dom";

const { $Affix, $PageHeader, $Divider, $Button, $Skeleton, $Search } =
  Common.Components;

/**
 * @description - Ledger Accounts Home View
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/3094577191/Ledger+List+-+UI+Implementation
 * @author Kaushalya Sandamali <kaushalyasa@unicorn-solutions.com>
 * @since 08/31/2022
 */
const LedgerAccounts: React.FC<ILedgerAccounts & PropsFromRedux> = ({
  getLedgerAccounts,
  deleteLedgerAccount,
  manageDrawer,
  isListLoading,
  drawerInfo,
}) => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    getLedgerAccounts && getLedgerAccounts({ searchText: SearchType.ALL });
  }, []);

  const onClose = () => {
    manageDrawer && manageDrawer(DEFAULT_DRAWER_STATE);
  };

  /**
   * @description - Update the browser tab url according to given id value
   * @param {number} vatId - Setting id if available
   */
  const updateUrl = (action: Action, accountId?: number) => {
    // update the URL params
    history.replace({
      pathname: "/ledger-accounts",
      search: `?action=${action}&accountId=${accountId}`,
    });
  };

  const handleAccountActions = (type: Action, accountId: number) => {
    updateUrl(type, accountId);
    manageDrawer &&
      manageDrawer({
        title: t(getDrawerTitle(type)),
      });
  };

  /**
   * @description handle item menu actions
   * @param {Action} action - Called action from the ledger account record item menu
   * @param {LedgerDetails} ledgerDetails - The ledger account record details
   * @param {any} values - Form values
   */
  const onHandleAction = (
    action: ActionType,
    ledgerDetails: LedgerDetails,
    values: any
  ) => {
    const { accountId } = ledgerDetails;

    switch (action) {
      case Action.EDIT:
        handleAccountActions(Action.EDIT, accountId);
        break;
      case Action.VIEW:
        handleAccountActions(Action.VIEW, accountId);
        break;
      case Action.DELETE:
        deleteLedgerAccount &&
          deleteLedgerAccount({ searchText: values?.searchText, accountId });
        break;
      default:
        break;
    }
  };

  return (
    <Formik
      initialValues={{
        searchText: SearchType.ALL,
      }}
      enableReinitialize
      onSubmit={() => {}}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t(
                      "US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.LEDGER_ACCOUNTS"
                    )}
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <$Button
                    type="default"
                    size="small"
                    icon={<PlusOutlined />}
                    id="add-new-account"
                    onClick={() => handleAccountActions(Action.ADD, -1)}
                  >
                    {t("US.COLLECTION.ECONOMY:LEDGER_ACCOUNTS.NEW_ACCOUNT")}
                  </$Button>
                  <$Divider className="bui-devider" type="vertical" />
                  <$Search
                    name="searchAccounts"
                    size="small"
                    allowClear
                    required
                    style={{ width: "280px" }}
                    className="mb-1 ml-2"
                    placeholder={t(
                      "US.COLLECTION.ECONOMY:PLACE_HOLDERS.SEARCH_BY_ACCOUNT_NO"
                    )}
                    onSearch={async (text: string) => {
                      const searchText = text ? text : SearchType.ALL;
                      restProps.setFieldValue("searchText", searchText);
                      getLedgerAccounts &&
                        getLedgerAccounts({
                          searchText,
                        });
                    }}
                  />
                </div>
              </div>
            </$Affix>
            <$Skeleton loading={isListLoading} active paragraph={{ rows: 2 }}>
              <div>
                <Table
                  onCallAction={(
                    action: ActionType,
                    ledgerDetails: LedgerDetails
                  ) => onHandleAction(action, ledgerDetails, values)}
                />
              </div>
            </$Skeleton>
          </div>
          <$Drawer
            title={drawerInfo.title}
            visible={drawerInfo.visible}
            width={"400"}
            destroyOnClose={true}
            maskClosable={false}
            onClose={onClose}
          >
            <Details onCancel={onClose} />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { ledgerAccounts } = state;

  const { isListLoading, drawerInfo } = ledgerAccounts;
  return {
    isListLoading,
    drawerInfo,
  };
};
const { accounts, ledgerAccount, newAccount } = Actions.ledgerAccounts;

const mapDispatchToProps = {
  getLedgerAccounts: accounts.get,
  deleteLedgerAccount: ledgerAccount.deleteById,
  manageDrawer: newAccount.manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(LedgerAccounts);
