import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AddArticles from "./AddNew";
import Common from "us.common";
import {
  PlusOutlined,
  DeleteOutlined,
  EditOutlined,
  MoreOutlined,
  SearchOutlined,
  FilterOutlined,
} from "us.icons";
import { IArticlesHome } from "./Interface";
import { IRootState } from "us.collection/interfaces";
import * as Actions from "us.collection.economy/actions";
import { initialDrawerValues } from "us.collection.economy/reducers/Articles/State";
import { Input } from "antd";
import { useHistory, useLocation } from "react-router-dom";
import { handleGoBack } from "us.helper/utility";

const { getArticles, changeDrawerInfo, deleteArticle } = Actions.articles;

const {
  $Affix,
  $PageHeader,
  $Divider,
  $Button,
  $AmountLabel,
  $Drawer,
  $Table,
  $Popconfirm,
  $Popover,
  $Skeleton,
  $Input,
  $InputAmount,
} = Common.Components;

const ArticlesHome: React.FC<IArticlesHome> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation()

  const {
    getArticles,
    articleList,
    changeDrawerInfo,
    drawerInfo,
    deleteArticle,
    isLoadingArticleData,
    currentLanguage,
    currentCurrency,
  } = props;
  const [filterTableData, setFilterTableData] = useState<Array<any>>([]);
  const [filterText, setFilterText] = useState<Array<any>>([]);
  const [filterInfor, setFilterInfo] = useState<any>({});
  const [sortedInfo, setSortedInfo] = useState<any>({
    columnKey: "",
    order: "",
  });


  useEffect(() => {
    getArticles();
  }, []);

  useEffect(() => {
    if (articleList?.length > 0) {
      setFilterText([]);
      setFilterInfo({});
      setSortedInfo({});
      setFilterTableData(articleList);
    }
  }, [articleList]);

  const onClose = () => {
    changeDrawerInfo(initialDrawerValues);
  };

  const handleAddArticles = () => {
    changeDrawerInfo({
      ...initialDrawerValues,
      title: t("US.COLLECTION.ECONOMY:ARTICLES.NEW_ARTICLE"),
      visible: true,
    });
  };

  const editSubCategoryHandler = (record: any) => {
    changeDrawerInfo({
      ...initialDrawerValues,
      title:
        t(`US.COLLECTION.ECONOMY:ARTICLES.EDIT_ARTICLE`) +
        " " +
        `${record.articleNo}`,
      visible: true,
      isEdit: true,
      selectedArticle: { ...record, vatCodeName: record.vatCodeName },
    });
  };

  const content = (record: any) => (
    <div className="table-more-content" data-testid="popover-content">
      <div
        className="d-flex  flex-row more-item"
        data-testid="edit-btn"
        onClick={() => editSubCategoryHandler(record)}
      >
        <div className="p-1">
          <EditOutlined />
        </div>
        <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.EDIT")}</div>
      </div>
      <$Popconfirm
        title={t("US.COLLECTION.COMMON:COMMON.SURE_TO_DELETE_?")}
        data-testid="popUpConfirm-box"
        onConfirm={() => deleteArticle(record.articleId)}
      >
        <div
          className="d-flex mb-2 flex-row more-item text-error"
          data-testid="delete-btn"
        >
          <div className="p-1">
            <DeleteOutlined />
          </div>
          <div className="p-1">{t("US.COLLECTION.COMMON:COMMON.DELETE")}</div>
        </div>
      </$Popconfirm>
    </div>
  );

  const filterData = (dataIndex: string, value: any, type: string) => {
    const searchData = articleList?.filter((o: any) =>
      [
        ...filterText.filter((n: any) => n.dataIndex !== dataIndex),
        { dataIndex: dataIndex, text: value },
      ].every((s: any) => {
        if (type == "amount") {
          return s.text?.toString()
            ? o[s.dataIndex]?.toString()?.includes(s.text?.toString())
            : true;
        } else {
          return o[s.dataIndex]
            ?.toString()
            ?.toLowerCase()
            ?.includes(s.text?.toString()?.toLowerCase());
        }
      })
    );
    setFilterTableData(searchData);
  };

  const getColumnSearchProps = (
    dataIndex: string,
    title: string,
    type: string
  ) => {
    const inputSearchRef = React.useRef<any>();
    return {
      onFilterDropdownVisibleChange: (visible: boolean) => {
        if (visible) {
          window.setTimeout(function () {
            typeof inputSearchRef?.current !== "undefined" &&
              inputSearchRef.current.focus();
            document.querySelector("[name=columnSearchAmount]" as any)?.focus();
          }, 1);
        }
      },
      filterDropdown: ({
        setSelectedKeys,
        selectedKeys,
        confirm,
        clearFilters,
      }: any) => (
        <div style={{ padding: 8 }}>
          {type !== "amount" && (
            <Input
              name="columnSearch"
              placeholder={`${t(
                "US.COLLECTION.COMMON:COMMON.SEARCH"
              )} ${title}`}
              value={
                filterText.length > 0
                  ? filterText.filter((i: any) => i.dataIndex == dataIndex)[0]
                      ?.text
                  : ""
              }
              onChange={(e: any) => {
                setSelectedKeys(e.target.value ? [e.target.value] : []);
                setFilterText([
                  ...filterText?.filter((n: any) => n.dataIndex !== dataIndex),
                  { dataIndex: dataIndex, text: e.target.value },
                ]);
                filterData(dataIndex, e.target.value, type);
              }}
              onPressEnter={(e: any) => {
                filterData(dataIndex, e.target.value, type);
                confirm();
              }}
              style={{ width: 188, marginBottom: 8, display: "block" }}
              autoComplete="off"
              ref={inputSearchRef}
            />
          )}
          {type == "amount" && (
            <$InputAmount
              id="column-search-amount"
              min={0}
              className="w-100"
              placeholder="0,00"
              name="columnSearchAmount"
              currentLanguage={currentLanguage}
              currentCurrency={currentCurrency}
              value={
                !filterText.filter((i: any) => i.dataIndex == dataIndex)[0]
                  ?.text
                  ? ""
                  : filterText.filter((i: any) => i.dataIndex == dataIndex)[0]
                      ?.text
              }
              onChange={(amount: any) => {
                amount = amount == 0 ? "" : amount;
                setSelectedKeys(amount ? [amount] : []);
                setFilterText([
                  ...filterText?.filter((n: any) => n.dataIndex !== dataIndex),
                  { dataIndex: dataIndex, text: amount },
                ]);
                filterData(dataIndex, amount, type);
              }}
              onKeyPress={(e: any) => {
                if (e.key === "Enter") {
                  filterData(
                    dataIndex,
                    filterText.filter((i: any) => i.dataIndex == dataIndex)[0]
                      ?.text,
                    type
                  );
                  confirm();
                }
              }}
              autoComplete="off"
              dataTestid="column-search"
            />
          )}
          <$Button
            onClick={() => {
              clearFilters();
              setFilterText([
                ...filterText?.filter((n: any) => n.dataIndex !== dataIndex),
              ]);
              filterData(dataIndex, "", type);
              confirm();
            }}
            size="small"
            style={{ width: 90 }}
          >
            {t("US.COLLECTION.COMMON:COMMON.RESET")}
          </$Button>
        </div>
      ),
      filterIcon: (filtered: string) => (
        <FilterOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
      ),
      render: (text: string) => <div>{text}</div>,
    };
  };

  const getHeaderTitle = (dataIndex: string, title: any, type: string) => {
    return (
      <div className="d-flex flex-column">
        <span style={{ fontWeight: "bold" }}>
          <span data-testid={dataIndex}>{title}</span>
          {filterText.length > 0 &&
            filterText.filter((i: any) => i.dataIndex == dataIndex)[0]
              ?.text && (
              <p>
                <span
                  className="font-sm mt-n4"
                  style={{ fontWeight: "bold" }}
                  data-testid="filter-by"
                >
                  {t("COMMON.FILTERED_BY_")}:{" "}
                  {type !== "amount" &&
                    filterText.filter((i: any) => i.dataIndex == dataIndex)[0]
                      ?.text}
                  {type == "amount" && (
                    <$AmountLabel
                      value={
                        filterText.filter(
                          (i: any) => i.dataIndex == dataIndex
                        )[0]?.text
                      }
                    />
                  )}
                </span>
              </p>
            )}
        </span>
      </div>
    );
  };

  const column: any = [
    {
      title: "",
      key: "more",
      dataIndex: "more",
      width: "50px",
      render: (text: any, record: any, index: number) => {
        return (
          <$Popover
            placement="rightTop"
            content={content(record)}
            destroyTooltipOnHide
            trigger="hover"
          >
            <$Button
              data-testid="popover-btn"
              icon={<MoreOutlined />}
              size="small"
            />
          </$Popover>
        );
      },
    },
    {
      title: getHeaderTitle(
        "articleNo",
        t("US.COLLECTION.ECONOMY:ARTICLES.NUMBER"),
        "number"
      ),
      dataIndex: "articleNo",
      key: "articleNo",
      filteredValue: filterInfor.articleNo || null,
      ...getColumnSearchProps(
        "articleNo",
        t("US.COLLECTION.ECONOMY:ARTICLES.NUMBER"),
        "number"
      ),
      sorter: (a: any, b: any) => a.articleNo - b.articleNo,
      sortOrder: sortedInfo.columnKey === "articleNo" && sortedInfo.order,
    },
    {
      title: getHeaderTitle(
        "description",
        t("US.COLLECTION.ECONOMY:ARTICLES.DESCRIPTION"),
        "string"
      ),
      dataIndex: "description",
      key: "description",
      filteredValue: filterInfor.description || null,
      ...getColumnSearchProps(
        "description",
        t("US.COLLECTION.ECONOMY:ARTICLES.DESCRIPTION"),
        "string"
      ),
      sorter: (a: any, b: any) => a.description.localeCompare(b.description),
      sortOrder: sortedInfo.columnKey === "description" && sortedInfo.order,
    },
    {
      title: getHeaderTitle(
        "defaultPrice",
        t("US.COLLECTION.ECONOMY:ARTICLES.DEFAULT_PRICE"),
        "amount"
      ),
      dataIndex: "defaultPrice",
      key: "defaultPrice",
      align: "right",
      filteredValue: filterInfor.defaultPrice || null,
      ...getColumnSearchProps(
        "defaultPrice",
        t("US.COLLECTION.ECONOMY:ARTICLES.DEFAULT_PRICE"),
        "amount"
      ),
      render: (text: any, record: any) => {
        return <$AmountLabel value={record.defaultPrice} />;
      },
      sorter: (a: any, b: any) => a.defaultPrice - b.defaultPrice,
      sortOrder: sortedInfo.columnKey === "defaultPrice" && sortedInfo.order,
    },
    {
      title: getHeaderTitle(
        "vatCodeName",
        t("US.COLLECTION.ECONOMY:ARTICLES.VAT_CODE"),
        "string"
      ),
      dataIndex: "vatCodeName",
      key: "vatCodeName",
      ...getColumnSearchProps(
        "vatCodeName",
        t("US.COLLECTION.ECONOMY:ARTICLES.VAT_CODE"),
        "string"
      ),
      filteredValue: filterInfor.vatCodeName || null,
      sorter: (a: any, b: any) => a.vatCodeName.localeCompare(b.vatCodeName),
      sortOrder: sortedInfo.columnKey === "vatCodeName" && sortedInfo.order,
    },
    {
      title: getHeaderTitle(
        "revenueAccountName",
        t("US.COLLECTION.ECONOMY:ARTICLES.REVENUE_ACCOUNT"),
        "number"
      ),
      dataIndex: "revenueAccountName",
      key: "revenueAccountName",
      ...getColumnSearchProps(
        "revenueAccountName",
        t("US.COLLECTION.ECONOMY:ARTICLES.REVENUE_ACCOUNT"),
        "number"
      ),
      filteredValue: filterInfor.revenueAccountName || null,
      sorter: (a: any, b: any) =>
        a.revenueAccountName.localeCompare(b.revenueAccountName),
      sortOrder:
        sortedInfo.columnKey === "revenueAccountName" && sortedInfo.order,
    },
    {
      title: getHeaderTitle(
        "nonVATAccountName",
        t("US.COLLECTION.ECONOMY:ARTICLES.NO_VAT_ACCOUNT"),
        "number"
      ),
      dataIndex: "nonVATAccountName",
      key: "nonVATAccountName",
      ...getColumnSearchProps(
        "nonVATAccountName",
        t("US.COLLECTION.ECONOMY:ARTICLES.NO_VAT_ACCOUNT"),
        "number"
      ),
      filteredValue: filterInfor.nonVATAccountName || null,
      sorter: (a: any, b: any) =>
        a.nonVATAccountName.localeCompare(b.nonVATAccountName),
      sortOrder:
        sortedInfo.columnKey === "nonVATAccountName" && sortedInfo.order,
    },
  ];

  const handleTableChange = (pagination: any, filters: any, sorter: any) => {
    setFilterInfo(filters);
    setSortedInfo(sorter);
  };

  const clearFilters = () => {
    setFilterInfo({});
    setSortedInfo({});
    setFilterText([]);
    setFilterTableData(articleList);
  };

  return (
    <Formik initialValues={{}} enableReinitialize onSubmit={() => {}}>
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex align-items-center justify-content-between">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      onBack={() => handleGoBack(history)}
                      title={t("US.COLLECTION.ECONOMY:ARTICLES.ARTICLES")}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <$Button
                      type="default"
                      size="small"
                      icon={<PlusOutlined />}
                      data-testid="add-new-article"
                      onClick={() => handleAddArticles()}
                    >
                      {t("US.COLLECTION.ECONOMY:ARTICLES.NEW_ARTICLE")}
                    </$Button>
                  </div>
                  <div>
                    <$Button
                      size="small"
                      onClick={() => clearFilters()}
                      disabled={
                        filterText.length == 0 &&
                        (sortedInfo.order == "" ||
                          sortedInfo.order == undefined)
                      }
                      data-testid="reset-all-btn"
                    >
                      {t("COMMON.RESET_ALL")}
                    </$Button>
                  </div>
                </div>
              </div>
            </$Affix>
            <$Skeleton
              loading={isLoadingArticleData}
              active
              paragraph={{ rows: 2 }}
            >
              <div>
                <$Table
                  data-testid="article-table"
                  rowKey={(record: any) => {
                    return record.articleId;
                  }}
                  columns={column}
                  dataSource={filterTableData}
                  bordered
                  className="mt-3"
                  pagination={{ defaultPageSize: 15 }}
                  onChange={handleTableChange}
                />
              </div>
            </$Skeleton>
          </div>
          <$Drawer
            title={drawerInfo.title}
            width={"450"}
            visible={drawerInfo.visible}
            onClose={() => onClose()}
            destroyOnClose
          >
            <AddArticles />
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, articles } = state;
  const { currentLanguage, currentCurrency, currentDateFormat } = common;
  const { articleList, drawerInfo, isLoadingArticleData } = articles;
  return {
    currentLanguage,
    currentCurrency,
    currentDateFormat,
    articleList,
    drawerInfo,
    isLoadingArticleData,
  };
};

const mapDispatchToProps = {
  getArticles,
  changeDrawerInfo,
  deleteArticle,
};

export default connect(mapStateToProps, mapDispatchToProps)(ArticlesHome);
