export enum VatCode {
  GET_VAT_CODE_LIST = "GET_VAT_CODE_LIST",
  GET_VAT_CODE_LIST_SUCCESS = "GET_VAT_CODE_LIST_SUCCESS",
  GET_VAT_CODE_LIST_FAIL = "GET_VAT_CODE_LIST_FAIL",

  GET_LEDGER_ACCOUNTS = "GET_LEDGER_ACCOUNTS",
  GET_LEDGER_ACCOUNTS_SUCCESS = "GET_LEDGER_ACCOUNTS_SUCCESS",
  GET_LEDGER_ACCOUNTS_FAIL = "GET_LEDGER_ACCOUNTS_FAIL",

  SAVE_VAT_CODE = "SAVE_VAT_CODE",
  SAVE_VAT_CODE_SUCCESS = "SAVE_VAT_CODE_SUCCESS",
  SAVE_VAT_CODE_FAIL = "SAVE_VAT_CODE_FAIL",
  RESET_VAT_CODE_DETAILS = "RESET_VAT_CODE_DETAILS",

  VALIDATE_VAT_CODE = "VALIDATE_VAT_CODE",
  VALIDATE_VAT_CODE_SUCCESS = "VALIDATE_VAT_CODE_SUCCESS",
  VALIDATE_VAT_CODE_FAIL = "VALIDATE_VAT_CODE_FAIL",

  RESET_VAT_CODE_VALIDATION = "RESET_VAT_CODE_VALIDATION",

  UPDATE_VAT_CODE = "UPDATE_VAT_CODE",
  UPDATE_VAT_CODE_SUCCESS = "UPDATE_VAT_CODE_SUCCESS",
  UPDATE_VAT_CODE_FAIL = "UPDATE_VAT_CODE_FAIL",

  DELETE_VAT_CODE = "DELETE_VAT_CODE",
  DELETE_VAT_CODE_SUCCESS = "DELETE_VAT_CODE_SUCCESS",
  DELETE_VAT_CODE_FAIL = "DELETE_VAT_CODE_FAIL",
}
