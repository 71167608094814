import React from "react";
import { useTranslation } from "react-i18next";
import { BoxIcons, DownloadOutlined, IconTypes } from "us.icons";
import { RemitAction } from "us.collection.economy/components/RemitHistory/Constants";
import { $Popconfirm, $TextArea, $Alert, $Popover } from "us.common/components";
import { useField } from "formik";
import { IItemMenu } from "./interface";

/**
 * @description - Remit History to view previous remit records, navigate from the main menu
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2815000877/Remit+History+-+UI+Design+and+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 06/01/2022
 */
const ItemMenu: React.FC<IItemMenu> = ({
  reversible,
  isOrderInvoiced,
  actions = [RemitAction.VIEW_LEDGER_DASHBOARD],
  onCallAction,
}) => {
  const { t } = useTranslation();
  const [_field, meta, helpers] = useField("comment");

  return (
    <div className="table-more-content">
      {actions.includes(RemitAction.RE_CREATE) && (
        <$Popconfirm
          title={t("COMMON.SURE_TO_PROCEED_?")}
          placement="topLeft"
          onConfirm={() => onCallAction(RemitAction.RE_CREATE)}
          okText={t("COMMON.YES")}
          cancelText={t("COMMON.NO")}
          data-testid="tooltip-confirm"
        >
          <div className="d-flex  flex-row more-item">
            <div className="p-1">
              <BoxIcons type={IconTypes.BOX_ICON} name="re-creat-remit" />
            </div>
            <div className="p-1">
              {t("US.COLLECTION.ECONOMY:REMIT_HISTORY.RE_CREATE_REMIT_FILE")}
            </div>
          </div>
        </$Popconfirm>
      )}
      {actions.includes(RemitAction.VIEW_LEDGER_DASHBOARD) && (
        <div
          className="d-flex  flex-row more-item"
          onClick={() => onCallAction(RemitAction.VIEW_LEDGER_DASHBOARD)}
        >
          <div className="p-1">
            <BoxIcons type={IconTypes.BOX_ICON} name="update-ledger" />
          </div>
          <div className="p-1">
            {t("US.COLLECTION.ECONOMY:REMIT_HISTORY.VIEW_LEDGER_DASHBOARD")}
          </div>
        </div>
      )}
      {actions.includes(RemitAction.REVERSE) && reversible && (
        <$Popconfirm
          okText={t("COMMON.YES")}
          cancelText={t("COMMON.NO")}
          destroyTooltipOnHide
          placement="topLeft"
          title={
            <div style={{ width: "350px" }}>
              <p>
                {t(
                  "US.COLLECTION.ECONOMY:REMIT_HISTORY.ARE_YOU_SURE_YOU_WANT_TO_REVERSE_?"
                )}
              </p>
              {isOrderInvoiced && (
                <$Alert
                  message={t(
                    "US.COLLECTION.ECONOMY:REMIT_HISTORY.REVERSE_REMIT_CAUTION_MESSAGE"
                  )}
                  type="warning"
                  className="mb-4 mr-4"
                />
              )}
              <$TextArea
                style={{ width: "320px" }}
                required
                placeholder={t("COMMON.COMMENT")}
                name="comment"
                autoSize={{ minRows: 2, maxRows: 5 }}
                maxLength={150}
                autoFocus={true}
              />
            </div>
          }
          okButtonProps={{
            disabled: meta.value == undefined || meta.value == "",
          }}
          onConfirm={() => {
            if (meta.value != undefined || meta.value != "") {
              onCallAction(RemitAction.REVERSE);
            } else {
              helpers.setTouched(true);
            }
          }}
          onCancel={() => {
            helpers.setTouched(false);
            helpers.setValue("");
          }}
        >
          <div
            className="d-flex  flex-row text-error more-item"
            onClick={() => {
              helpers.setTouched(false);
              helpers.setValue("");
            }}
            data-testid="btn-reverse-remit"
          >
            <div className="p-1">
              <BoxIcons type={IconTypes.BOX_ICON} name="reverse-remit" />
            </div>
            <div className="p-1">
              {t("US.COLLECTION.ECONOMY:REMIT_HISTORY.REVERSE_REMIT")}
            </div>
          </div>
        </$Popconfirm>
      )}
      {actions.includes(RemitAction.REVERSE) && !reversible && (
        <$Popover
          placement="top"
          content={
            <$Alert
              message={t(
                "US.COLLECTION.ECONOMY:REMIT_HISTORY.PLEASE_REVERSE_THE_LATEST_REMIT_FIRST"
              )}
              type="warning"
              className="mx-0"
            />
          }
        >
          <div
            className="d-flex  flex-row text-error more-item"
            data-testid="btn-reverse-remit"
          >
            <div className="p-1">
              <BoxIcons type={IconTypes.BOX_ICON} name="reverse-remit" />
            </div>
            <div className="p-1">
              {t("US.COLLECTION.ECONOMY:REMIT_HISTORY.REVERSE_REMIT")}
            </div>
          </div>
        </$Popover>
      )}
      {actions.includes(RemitAction.DOWNLOAD) && (
        <div
          className="d-flex  flex-row more-item"
          onClick={() => onCallAction(RemitAction.DOWNLOAD)}
        >
          <div className="p-1">
            <DownloadOutlined style={{ fontSize: "1rem" }} />
          </div>
          <div className="p-1">
            {t("US.COLLECTION.ECONOMY:REMIT_HISTORY.DOWNLOAD")}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemMenu;
