import React from "react";
import * as Components from "us.common/components";
import { useTranslation } from "react-i18next";
import * as Actions from "us.collection.economy/actions";
import { connect } from "react-redux";
import { IAddCreditNote } from "us.collection.economy/components/ClientInvoices/Interfaces";
import { SaveCreditNoteData } from "us.collection.economy/components/ClientInvoices/Repository";
const { $Drawer, $CreditNote } = Components;

const AddCreditNote: React.FC<IAddCreditNote> = (props) => {
  const { t } = useTranslation();
  const {
    invoiceNo,
    orderlines,
    filterValues,
    invoiceDetails,
    isDrawerVisible,
    isDetailsLoading,
    isOpenByList,
    isExporting,
    isSaving,
    invoiceSaveResult,
    getCreditorInvoice,
    saveCreditNote,
    creditNoteExport,
    resetDetails,
    handleDrawer,
  } = props;

  // Drawer close function
  const onClose = () => {
    handleDrawer && handleDrawer({});
  };

  // Form reset
  const onReset = () => {
    resetDetails && resetDetails({});
  };

  // Save credit note
  const onSave = (data: any) => {
    const creditNoteParams = SaveCreditNoteData.call(data);
    saveCreditNote &&
      saveCreditNote({ searchParams: filterValues, creditNoteParams });
  };

  // Export credit note
  const onExport = ({exportType}:any) => {
    const { invoiceNo, invoiceId } = invoiceSaveResult;
    creditNoteExport &&
      creditNoteExport({
        searchParams: filterValues,
        exportParams: [
          {
            invoiceNo,
            invoiceId,
            isCreditInvoice: true,
          },
        ],
        exportType: exportType ?? "BOXImport"
      });
  };

  return (
    <>
      <$Drawer
        title={t("US.COLLECTION.ECONOMY:CREDITOR_INVOICE.NEW_CREDIT_INVOICE")}
        width={"80%"}
        visible={isDrawerVisible}
        onClose={onClose}
        maskClosable={false}
        destroyOnClose
      >
        <$CreditNote
          invoiceDetails={invoiceDetails}
          orderlines={orderlines}
          isSaving={isSaving}
          isExporting={isExporting}
          isExportVisible={invoiceSaveResult ? true : false}
          isLoading={isDetailsLoading}
          isInvoiceNo={!isOpenByList}
          invoiceNo={invoiceNo}
          onBlurInput={(e: any) =>
            getCreditorInvoice && getCreditorInvoice(e.target.value)
          }
          onSave={onSave}
          onExport={onExport}
          onClose={onClose}
          onReset={onReset}
          onClearInput={onReset}
        />
      </$Drawer>
    </>
  );
};

const mapStateToProps = (state: any) => {
  const { clientInvoices } = state;
  const { details, drawer, creditNote, creditNoteExport } = clientInvoices;
  const { data, isLoading } = details;
  const { isVisible, isOpenByList } = drawer;
  const { isSaving, result } = creditNote;
  return {
    invoiceDetails: data,
    isDetailsLoading: isLoading,
    orderlines: data.creditorOrderlines,
    isDrawerVisible: isVisible,
    isOpenByList,
    isSaving,
    invoiceSaveResult: result,
    isExporting: creditNoteExport.isLoading,
  };
};

const { creditNote, creditNoteExport, creditNoteDetails } =
  Actions.clientInvoices;

const { getDetails, reset, handleDrawer } = creditNoteDetails;
const { save } = creditNote;

const mapDispatchToProps = {
  getCreditorInvoice: getDetails,
  saveCreditNote: save,
  resetDetails: reset,
  handleDrawer: handleDrawer,
  creditNoteExport: creditNoteExport.save,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddCreditNote);
