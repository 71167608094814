import * as Yup from "yup";

export default () => {
  return Yup.object().shape({
    accountNo: Yup.number().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.ACCOUNT_NUMBER_REQUIRED"
    ),
    accountName: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.ACCOUNT_NAME_REQUIRED"
    ),
    ledgerAccountNumber: Yup.string().required(
      "US.COLLECTION.VALIDATIONS:REQUIRED.LEDGER_ACCOUNT_NUMBER_REQUIRED"
    ),
    ledgerVoucherSequence: Yup.string()
      .required(
        "US.COLLECTION.VALIDATIONS:REQUIRED.LEDGER_VOUCHER_SEQUENCE_REQUIRED"
      )
      .nullable(),
    remitJournalPrifix: Yup.number()
      .typeError(
        "US.COLLECTION.VALIDATIONS:INVALID.INVALID_REMIT_JOURNAL_PREFIX"
      )
      .test(
        "min number of digits is 4",
        "US.COLLECTION.VALIDATIONS:INVALID.REMIT_JOURNAL_PREFIX_SHOULD_NOT_BE_LESS_THAN_4_DIGITS",
        function (val: any) {
          return (val && val.toString().length == 4) || val == null;
        }
      ),
    enterpriseNumber: Yup.number().typeError(
      "US.COLLECTION.VALIDATIONS:INVALID.INVALID_ENTERPRISE_NO"
    ),
  });
};
