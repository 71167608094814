import moment from "moment";
import { DATE_FORMAT_FOR_SERVER } from "us.collection.economy/constants";
import { ISearchOrders } from "us.collection.economy/interfaces/ClientOrders/IClientOrdersServices";
import {
  COSearchType,
  InitialOrderType
} from "us.collection.economy/components/ClientOrders/Constants";

export function SearchOrders(this: any): ISearchOrders {
  const [startDate, endDate] = this.dateRange ?? [
    moment().startOf("month"),
    moment().endOf("month"),
  ];

  return {
    startDate: moment(startDate).format(DATE_FORMAT_FOR_SERVER),
    endDate: moment(endDate).format(DATE_FORMAT_FOR_SERVER),
    manualOrdersOnly: this.manualOrdersOnly,
    searchType: this.orderNo ? COSearchType.ORDER : COSearchType.REG_DATE,
    orderType:
      this.orderType?.length == 0 ||
      this.orderType?.length == this.orderTypes?.length
        ? [InitialOrderType.ALL]
        : this.orderType,
    orderNo: this.orderNo ? Number(this.orderNo) : null,
    customers: this.customers,
    offset: this.offset,
    limit: this.limit,
  };
}
