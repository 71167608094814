import React from "react";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useFormikContext } from "formik";

import Common from "us.common";
import { DeleteOutlined, PlusOutlined } from "us.icons";
import { IRootState } from "us.collection/interfaces";
import { IProfileDetail } from "us.collection.economy/interfaces";
import { IOrderLinesTable } from "us.collection.economy/components/ProvisionProfiles/Interfaces";
import { DebtorType } from "us.collection.economy/constants";
import {
  arrangeAmounts,
  addNewline,
  isDisableNewLine,
} from "us.collection.economy/components/ProvisionProfiles/Functions";
import * as Actions from "us.collection.economy/actions";

const { $Button, $Table, $InputAmountRange, $InputNumber } = Common.Components;

/**
 * @description - Provision profiles orderlines table component to view or add new order lines
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2985361423/Provision+Profiles+UI+Implementation
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 23/05/2022
 */
const RangesTable: React.FC<PropsFromRedux & IOrderLinesTable> = (props) => {
  const { t } = useTranslation();
  const { values, setFieldTouched } = useFormikContext<any>();

  const {
    currentCurrency,
    currentLanguage,
    debtorType,
    restProps,
    profileDetails,
    updateOrderLines,
  } = props;

  const debtorKey =
    debtorType == DebtorType.PERSON
      ? "profileDetailsPerson"
      : "profileDetailsCompany";

  const orderLinesByTypeInitial = profileDetails.initialData[debtorKey];
  const orderLinesByType = profileDetails.data[debtorKey];

  /**
   * @description Handle orderline main amount range changes
   * @param {number} value - number - the value of the input
   * @param {number} dataIndex - number - the index of the row in the table
   * @param {string} field - string =&gt; this is the field name of the object that I want to update.
   */
  const onChangeAmount = (value: number, dataIndex: number, field: string) => {
    const orderLines: any = orderLinesByType;
    orderLines[dataIndex][field] = value;

    updateOrderLines &&
      updateOrderLines({
        ...values,
        [debtorKey]: orderLines,
      });
  };

  /**
   * When the user clicks the button, add a new line to the table.
   */
  const handleAddNewLine = (e: any) => {
    const newLine = addNewline(
      orderLinesByType,
      orderLinesByTypeInitial,
      debtorType
    );
    updateOrderLines &&
      updateOrderLines({
        ...values,
        [debtorKey]: newLine,
      });
    // dynamically set touched true for the custom component
    setFieldTouched(
      `${debtorKey}[${orderLinesByType.length - 1}].mainAmountRange`,
      true,
      true
    );
    // dynamically focus the amount range element
    document
      .getElementById(
        `${`${debtorKey}[${
          orderLinesByType.length - 1
        }].mainAmountRange-amount-range-1`}`
      )
      ?.focus();
  };

  /**
   * @description Generate the orderlines table columns
   * @param {any} restProps - Form restProps
   * @returns {Array<any>} - An array of columns
   */
  const getColumns: any = (restProps: any): Array<any> => {
    const columns: any = [
      {
        title: "",
        key: "moreAction",
        dataIndex: "moreAction",
        width: "50px",
        render: (_text: any, record: any, index: number) => {
          return (
            <$Button
              id="btnDeleteOrderline"
              type="primary"
              danger
              icon={<DeleteOutlined />}
              size="small"
              onClick={() => {
                updateOrderLines &&
                  updateOrderLines({
                    ...values,
                    [debtorKey]: arrangeAmounts(
                      orderLinesByType,
                      orderLinesByTypeInitial,
                      index,
                      record.mainAmountRange,
                      "delete",
                      record.detailId
                    ),
                  });
              }}
            />
          );
        },
      },
      {
        title: t("US.COLLECTION.ECONOMY:PROVISION_PROFILES.MAIN_AMOUNT_RANGE"),
        dataIndex: "mainAmountRange",
        key: "mainAmountRange",
        render: (_text: any, record: any, index: number) => {
          return (
            <div className="d-flex">
              <$InputAmountRange
                {...restProps}
                placeholder="0,00"
                className="w-100"
                size="small"
                isAutoSwap={false}
                currentLanguage={currentLanguage}
                currentCurrency={currentCurrency}
                name={`${debtorKey}[${index}].mainAmountRange`}
                value={record.mainAmountRange}
                onChange={(value: number[]) => {
                  updateOrderLines &&
                    updateOrderLines({
                      ...values,
                      [debtorKey]: arrangeAmounts(
                        orderLinesByType,
                        orderLinesByTypeInitial,
                        index,
                        value
                      ),
                    });
                }}
                min={0}
              />
            </div>
          );
        },
      },
      {
        title: t(
          "US.COLLECTION.ECONOMY:PROVISION_PROFILES.MAIN_AMOUNT_PROVISION_RATE"
        ),
        dataIndex: "provisionRate",
        key: "provisionRate",
        render: (_text: any, record: any, index: number) => {
          return (
            <$InputNumber
              {...restProps}
              addonAfter="%"
              placeholder="0.00"
              className="w-100"
              size="small"
              type="number"
              min={0}
              max={100}
              value={record.mainAmountProvisionRate}
              name={`${debtorKey}[${index}].mainAmountProvisionRate`}
              onChange={(value: number) => {
                onChangeAmount(value, index, "mainAmountProvisionRate");
              }}
            />
          );
        },
      },
      {
        title: t(
          "US.COLLECTION.ECONOMY:PROVISION_PROFILES.INTEREST_PROVISION_RATE"
        ),
        dataIndex: "interestProvisionRate",
        key: "interestProvisionRate",
        render: (_text: any, record: any, index: number) => {
          return (
            <$InputNumber
              {...restProps}
              name={`${debtorKey}[${index}].interestProvisionRate`}
              addonAfter="%"
              placeholder="0.00"
              className="w-100"
              size="small"
              type="number"
              min={0}
              max={100}
              value={record.interestProvisionRate}
              onChange={(value: number) =>
                onChangeAmount(value, index, "interestProvisionRate")
              }
              onKeyPress={(e: any) => {
                if (e.key == "Enter") {
                  updateOrderLines &&
                    updateOrderLines({
                      ...values,
                      [debtorKey]: addNewline(
                        orderLinesByType,
                        orderLinesByTypeInitial,
                        debtorType
                      ),
                    });
                }
              }}
            />
          );
        },
      },
    ];
    return columns;
  };

  return (
    <$Table
      rowKey={(record: IProfileDetail) => record.detailId}
      columns={getColumns(restProps)}
      dataSource={orderLinesByType}
      pagination={false}
      footer={() => (
        <$Button
          id="btnNewOrderLine"
          type="link"
          icon={<PlusOutlined />}
          size="small"
          disabled={isDisableNewLine(orderLinesByType)}
          onClick={handleAddNewLine}
        >
          {t("US.COLLECTION.ECONOMY:PROVISION_PROFILES.NEW_LINE")}
        </$Button>
      )}
      className="mt-3"
      bordered
    />
  );
};

const mapStateToProps = (state: IRootState) => {
  const { provisionProfiles, common } = state;
  const { currentLanguage, currentCurrency } = common;
  const { profileDetails } = provisionProfiles;

  return {
    currentCurrency,
    currentLanguage,
    profileDetails,
  };
};

const { provisionProfiles } = Actions;

const mapDispatchToProps = {
  updateOrderLines: provisionProfiles.profileDetails.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RangesTable);
