import { IGetPaymentsImportSummary } from "us.collection.economy/interfaces";
import { httpCollection } from "us.helper/http/collection";

export default {
  summary: {
    search: ({
      startDate,
      endDate,
      fileName,
    }: IGetPaymentsImportSummary): Promise<any> => {
      return new Promise<any>(async (resolve, reject) => {
        try {
          const { data, status } = await httpCollection.get(
            "paymentimportservice",
            `payment/ocrimportsummary?startimportdate=${startDate}&endimportdate=${endDate}&filename=${fileName}`,
            {}
          );
          resolve({ data, status });
        } catch (error) {
          reject(error);
        }
      });
    },
  },
};
