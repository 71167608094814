import { ISaveArticle } from "../Interface";

export function SaveArticle(this: ISaveArticle) {
  return {
    articleNo: this.articleNo,
    description: this.description,
    vatCode: this.vatCodeId,
    revenueAccountId: this.revenueAccountId,
    nonVatAccountId: this.nonVatAccountId,
    categoryId: this.categoryId,
    defaultPrice: this.defaultPrice != '' ? this.defaultPrice : 0
  };
}
