import React, { useState, useEffect, useRef } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import * as Actions from "us.collection.economy/actions";
import {
  RemitProposalTable,
  ValidationMessage,
  CreditorSelector,
  RemitDetails,
  RemitValidationErrors,
} from "./Components";
import {
  DrawerType,
  DRAWER_DEFAULT_STATE,
  RemitProposalAction,
  RemitValidationState,
  REMIT_DEFAULT_FORM_VALUES,
} from "./Constants";
import {
  getDrawerSettings,
  hasMinusRemitCreditors,
  isInvoicedAnyRecord,
} from "us.collection.economy/components/Remit/Functions";
import { IRemitProps } from "us.collection.economy/interfaces";
import { IRootState } from "us.collection/interfaces";
import { Drawer, RemitProposalActionType, RemitRecord } from "./Interfaces";
import { RemitValidationSchema } from "./Validations";
import { CreateRemit, GetRemitProposal, InvoiceRemit } from "./Repository";
import { handleGoBack } from "us.helper";

const {
  $PageHeader,
  $Divider,
  $Button,
  $Affix,
  $Select,
  $Drawer,
  $Tooltip,
  $Switch,
  $Skeleton,
} = Common.Components;

/**
 * @description - Remit generation home view component navigate from remit history
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2858189310/Remit+Proposal+UI+Design+and+Implementations
 * @author Roshan Maddumage <roshanma@unicorn-solutions.com>
 * @since 08/03/2022
 */
const Remit: React.FC<IRemitProps & PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const formRef = useRef<any>();

  const {
    bankAccounts,
    remitAccount,
    remitProposal,
    remitValidation,
    validationState,
    remitGeneration,
    resetCreditors,
    getBankAccounts,
    getRemitProposal,
    validateRemit,
    getRemitDetails,
    createRemit,
    invoiceRemit,
  } = props;
  const { journalId, remitVoucherNo, remitRecords, remitId } =
    remitProposal.data ?? {};
  const [selectedRemitRecord, setSelectedRemitRecord] = useState<
    RemitRecord | undefined
  >();
  const [drawer, setDrawer] = useState<Drawer>(DRAWER_DEFAULT_STATE);

  const isValidationFail =
    validationState === RemitValidationState.VALIDATION_FAIL;

  useEffect(() => {
    getBankAccounts && getBankAccounts({});
  }, []);

  useEffect(() => {
    if (
      remitGeneration.data &&
      remitGeneration.data.hasOwnProperty("isSuccess") &&
      remitGeneration.data.isSuccess
    ) {
      // reset the form
      formRef && formRef.current?.resetForm();
    }
  }, [remitGeneration]);

  // close the opened drawer and do the rest
  const closeDrawer = (values?: any) => {
    drawer.type === DrawerType.REMIT_DETAILS &&
      setSelectedRemitRecord(undefined);
    drawer.type === DrawerType.SELECT_CREDITOR &&
      resetCreditors &&
      resetCreditors({
        creditors: values?.creditors,
        selectedGroups: values?.creditorGroups,
      });
    setDrawer(DRAWER_DEFAULT_STATE);
  };

  // open a drawer by type
  const openDrawer = (type: DrawerType) => {
    const drawerSettings = getDrawerSettings(type);
    setDrawer(drawerSettings);
  };

  /**
   * @description Handle Remit summary table item action
   * @param actionType - Remit summary action type
   * @param record - remit summary table record data
   */
  const handleRemitRecordAction = (
    record: RemitRecord,
    actionType: RemitProposalActionType
  ) => {
    switch (actionType) {
      case RemitProposalAction.VIEW_REMIT_DETAILS:
        setSelectedRemitRecord(record);
        getRemitDetails &&
          getRemitDetails({ creditorNo: record.creditorNo, remitId });
        openDrawer(DrawerType.REMIT_DETAILS);
        break;
      case RemitProposalAction.INVOICE:
      case RemitProposalAction.RESET_INVOICE:
        invoiceRemitRecord(record);
        break;
      default:
        break;
    }
  };

  /**
   * @description - get remit proposal for the selected creditors
   * @param {any} values
   */
  const getRemitsRecords = (values: any) => {
    const params = GetRemitProposal.call(values);
    getRemitProposal && getRemitProposal(params);
    setDrawer(DRAWER_DEFAULT_STATE);
  };

  /**
   * @description - Re-validate remit proposal after updating/fixing errors of the payments
   * @param {any} values
   */
  const validateRemitProposal = (values: any) => {
    validateRemit &&
      validateRemit({
        remitId,
        ignoreExceedPayments: values.ignoreExceedPayments,
      });
  };

  /**
   * @description - Handle remit invoicing
   * @param {RemitRecord} record
   */
  const invoiceRemitRecord = (record: RemitRecord) => {
    const invoiceParams = InvoiceRemit.call({ ...record, remitId });
    invoiceRemit && invoiceRemit(invoiceParams);
  };

  /**
   * @description - handle submit button actions
   * @param {any} values
   */
  const handleSubmit = (values: any) => {
    if (validationState === RemitValidationState.VALIDATION_SUCCESS) {
      // create remit
      const params = CreateRemit.call({
        ...values,
        remitId,
        journalId,
        remitVoucherNo,
      });
      createRemit && createRemit(params);
    } else {
      // validate remit
      validateRemitProposal(values);
    }
  };

  return (
    <Formik
      initialValues={{
        ...REMIT_DEFAULT_FORM_VALUES,
        remitAccount,
      }}
      innerRef={formRef}
      enableReinitialize
      validateOnChange
      validateOnBlur
      validationSchema={RemitValidationSchema}
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        isValid,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="d-flex align-items-center">
                    <$PageHeader
                      className="px-0"
                      onBack={() => handleGoBack(history)}
                      title={t("US.COLLECTION.ECONOMY:REMIT.REMIT")}
                    />
                    <$Divider className="bui-devider" type="vertical" />
                    <div className="d-flex align-items-center">
                      <div>
                        <$Tooltip
                          title={t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ACCOUNT"
                          )}
                        >
                          <$Select
                            dataTestid={"select-account"}
                            className="mr-4"
                            name="remitAccount"
                            placeholder={t(
                              "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.ACCOUNT"
                            )}
                            size="small"
                            style={{ width: 280 }}
                            options={bankAccounts.data}
                            hideSearch={true}
                            optionValue="accountNo"
                            optionText="accountName"
                            allOption={false}
                            required
                            loading={bankAccounts.isLoading}
                            disabled={
                              bankAccounts.isLoading ||
                              remitValidation.isLoading ||
                              remitProposal.isLoading
                            }
                            optionExtra={{
                              extraNumber: "accountNo",
                            }}
                            onChange={(remitAccount: any) => {
                              restProps.setFieldValue(
                                "remitAccount",
                                remitAccount
                              );
                              getRemitsRecords({
                                ...values,
                                remitAccount,
                              });
                            }}
                          />
                        </$Tooltip>
                      </div>
                      <div className="d-flex align-items-center mr-4 py-1">
                        <$Switch
                          checked={values.ignoreExceedPayments}
                          size="small"
                          name={"ignoreExceedPayments"}
                          disabled={
                            bankAccounts.isLoading ||
                            remitValidation.isLoading ||
                            remitProposal.isLoading
                          }
                          onChange={(ignoreExceedPayments: boolean) => {
                            restProps.setFieldValue(
                              "ignoreExceedPayments",
                              ignoreExceedPayments
                            );
                            getRemitsRecords({
                              ...values,
                              ignoreExceedPayments,
                            });
                          }}
                        />
                        <label className="ml-2">
                          {t(
                            "US.COLLECTION.ECONOMY:REMIT.IGNORE_EXCEED_PAYMENTS"
                          )}
                        </label>
                      </div>
                      <div className="d-flex flex-column mr-4 py-1">
                        <div>
                          <$Button
                            id="btnSelectCreditor"
                            type="link"
                            className="font-weight-bold m-0 p-0"
                            data-testid="select-creditors"
                            size="small"
                            onClick={() =>
                              openDrawer(DrawerType.SELECT_CREDITOR)
                            }
                            disabled={
                              bankAccounts.isLoading ||
                              (Array.isArray(bankAccounts.data) &&
                                bankAccounts.data.length === 0) ||
                              remitValidation.isLoading ||
                              remitProposal.isLoading
                            }
                          >
                            {t("US.COLLECTION.ECONOMY:REMIT.SELECT_CREDITORS")}
                          </$Button>
                        </div>
                        {Array.isArray(values.creditors) &&
                          values.creditors.length > 0 && (
                            <div
                              data-testid="label-creditors-count"
                              className="font-weight-bold"
                              style={{ fontSize: "0.8rem" }}
                            >
                              {values.creditors.length}
                            </div>
                          )}
                      </div>
                      {(validationState === RemitValidationState.VALIDATION ||
                        (isValidationFail &&
                          isInvoicedAnyRecord(remitRecords))) && (
                        <$Button
                          id={"btnRemitSubmit"}
                          type="primary"
                          size="small"
                          onClick={handleSubmit}
                          loading={
                            remitValidation.isLoading ||
                            remitGeneration.isLoading
                          }
                          disabled={
                            bankAccounts.isLoading ||
                            !isValid ||
                            values.creditors.length === 0 ||
                            remitProposal.isLoading ||
                            remitRecords.length === 0
                          }
                        >
                          {t("US.COLLECTION.ECONOMY:REMIT.VALIDATE_REMIT")}
                        </$Button>
                      )}
                      {validationState ===
                        RemitValidationState.VALIDATION_SUCCESS && (
                        <$Button
                          id={"btnRemitSubmit"}
                          type="primary"
                          size="small"
                          onClick={handleSubmit}
                          loading={remitGeneration.isLoading}
                          disabled={
                            bankAccounts.isLoading ||
                            !isValid ||
                            values.creditors.length === 0 ||
                            remitProposal.isLoading ||
                            remitRecords.length === 0 ||
                            hasMinusRemitCreditors(
                              remitProposal.data.remitRecords
                            )
                          }
                        >
                          {t("US.COLLECTION.ECONOMY:REMIT.CREATE_REMIT")}
                        </$Button>
                      )}
                      <$Button
                        id={"btnRefresh"}
                        type="primary"
                        size="small"
                        className="ml-2"
                        onClick={() => getRemitsRecords(values)}
                        loading={remitProposal.isLoading}
                        disabled={
                          bankAccounts.isLoading ||
                          !isValid ||
                          values.creditors.length === 0 ||
                          remitProposal.isLoading ||
                          remitValidation.isLoading ||
                          remitGeneration.isLoading
                        }
                      >
                        {t("COMMON.REFRESH")}
                      </$Button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex flex-column mr-4">
                      <div
                        className="font-weight-bold"
                        data-testid="label-journal-id"
                      >
                        {journalId ?? "-"}
                      </div>
                      <div className="text-muted">
                        {t("US.COLLECTION.ECONOMY:REMIT.JOURNAL_ID")}
                      </div>
                    </div>
                    <$Divider className="bui-devider" type="vertical" />

                    <div className="d-flex flex-column ml-4">
                      <div
                        data-testid="label-voucher-no"
                        className="font-weight-bold"
                      >
                        {remitVoucherNo ?? "-"}
                      </div>
                      <div className="text-muted">
                        {t("US.COLLECTION.ECONOMY:REMIT.VOUCHER_NO")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </$Affix>
            <div>
              {validationState !== RemitValidationState.VALIDATION && (
                <ValidationMessage
                  type={isValidationFail ? "error" : "success"}
                  message={
                    isValidationFail
                      ? t(
                          "US.COLLECTION.ECONOMY:MESSAGES.REMIT_VALIDATION_ERRORS"
                        )
                      : t(
                          "US.COLLECTION.ECONOMY:MESSAGES.REMIT_VALIDATION_SUCCESS"
                        )
                  }
                  buttonTitle={
                    isValidationFail
                      ? t("US.COLLECTION.ECONOMY:REMIT.VIEW_ERRORS")
                      : undefined
                  }
                  onPress={() => {
                    openDrawer(DrawerType.REMIT_VALIDATION_ERRORS);
                  }}
                />
              )}
              <$Skeleton
                loading={remitProposal.isLoading || bankAccounts.isLoading}
                active
                paragraph={{ rows: 2 }}
              >
                <div>
                  <RemitProposalTable
                    selectedRowKey={selectedRemitRecord?.creditorNo ?? ""}
                    onCallAction={handleRemitRecordAction}
                  />
                </div>
              </$Skeleton>
            </div>
          </div>
          <$Drawer
            data-testid="drawer"
            title={t(drawer.title)}
            width={drawer.width}
            visible={drawer.visible}
            onClose={() => closeDrawer(values)}
          >
            {drawer.type === DrawerType.REMIT_VALIDATION_ERRORS && (
              /**
               * Remit Validation Errors drawer content goes here
               */
              <RemitValidationErrors
                onViewRemit={() => getRemitsRecords(values)}
                onReValidate={() => validateRemitProposal(values)}
                onCancel={closeDrawer}
              />
            )}
            {drawer.type === DrawerType.REMIT_DETAILS && selectedRemitRecord && (
              /**
               * Remit details drawer content goes here
               */
              <RemitDetails
                selectedRemitRecord={selectedRemitRecord}
                onCancel={closeDrawer}
              />
            )}
            {drawer.type === DrawerType.SELECT_CREDITOR && (
              /**
               * Select Creditor drawer content goes here
               */
              <CreditorSelector
                name="creditors"
                groupName="creditorGroups"
                onCancel={() => closeDrawer(values)}
                onConfirm={(creditors: string[], _customerGroups: number[]) => {
                  getRemitsRecords({ ...values, creditors });
                }}
              />
            )}
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common, remit } = state;

  const {
    bankAccounts,
    remitAccount,
    remitProposal,
    remitValidation,
    remitSummaryByCreditor,
    validationState,
    remitGeneration,
    remitInvoicing,
  } = remit;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;
  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    bankAccounts,
    remitAccount,
    validationState,
    remitProposal,
    remitValidation,
    remitSummaryByCreditor,
    remitGeneration,
    remitInvoicing,
  };
};
const {
  bankAccounts,
  remitProposal,
  remitValidation,
  creditors,
  remitDetails,
  remitGeneration,
  remitInvoice,
} = Actions.remit;

const mapDispatchToProps = {
  getBankAccounts: bankAccounts.get,
  getRemitProposal: remitProposal.search,
  validateRemit: remitValidation.get,
  resetCreditors: creditors.reset,
  getRemitDetails: remitDetails.get,
  createRemit: remitGeneration.save,
  invoiceRemit: remitInvoice.update,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Remit);
