import { IItemTypeActions } from "us.collection.economy/interfaces";
import { ItemTypes } from "us.collection.economy/constants/Actions";
import { IAPIAction } from "us.collection/interfaces";

const {
  INIT_ITEM_TYPES_HOME,
  INIT_ITEM_TYPES_HOME_SUCCESS,
  INIT_ITEM_TYPES_HOME_FAILED,

  DELETE_ITEM_TYPE,

  SEARCH_ITEM_TYPES,
  SEARCH_ITEM_TYPES_SUCCESS,
  SEARCH_ITEM_TYPES_FAILED,

  GET_TRANSACTION_GROUPS,
  GET_TRANSACTION_GROUPS_SUCCESS,
  GET_TRANSACTION_GROUPS_FAILED,

  ADD_NEW_VOUCHER_SEQUENCE,
  ADD_NEW_VOUCHER_SEQUENCE_SUCCESS,
  ADD_NEW_VOUCHER_SEQUENCE_FAILED,

  HANDLE_ITEM_TYPE_DRAWER,
  HANDLE_ITEM_TYPE_DRAWER_BREADCRUMB,
  RESET_ITEM_TYPE_DETAILS,

  GET_INIT_ITEM_TYPE_DATA,
  GET_INIT_ITEM_TYPE_DATA_SUCCESS,
  GET_INIT_ITEM_TYPE_DATA_FAILED,

  GET_ITEM_TYPE_DETAILS,
  GET_ITEM_TYPE_DETAILS_SUCCESS,
  GET_ITEM_TYPE_DETAILS_FAILED,

  DUPLICATE_VALIDATE_FIELDS,
  DUPLICATE_VALIDATE_FIELDS_SUCCESS,
  DUPLICATE_VALIDATE_FIELDS_FAILED,

  ADD_NEW_ITEM_TYPE,
  ADD_NEW_ITEM_TYPE_SUCCESS,
  ADD_NEW_ITEM_TYPE_FAILED,

  EDIT_ITEM_TYPE,
  EDIT_ITEM_TYPE_SUCCESS,
  EDIT_ITEM_TYPE_FAILED,
} = ItemTypes;

export const itemTypes: Readonly<IAPIAction & IItemTypeActions> = {
  init: {
    get: (data) => ({
      type: INIT_ITEM_TYPES_HOME,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: INIT_ITEM_TYPES_HOME_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: INIT_ITEM_TYPES_HOME_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  types: {
    search: (data) => ({
      type: SEARCH_ITEM_TYPES,
      payload: {
        data,
        isLoading: true,
      },
    }),
    delete: (data) => ({
      type: DELETE_ITEM_TYPE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: SEARCH_ITEM_TYPES_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: SEARCH_ITEM_TYPES_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  groups: {
    get: (data) => ({
      type: GET_TRANSACTION_GROUPS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_TRANSACTION_GROUPS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_TRANSACTION_GROUPS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  addSequence: {
    save: (data) => ({
      type: ADD_NEW_VOUCHER_SEQUENCE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_NEW_VOUCHER_SEQUENCE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_NEW_VOUCHER_SEQUENCE_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  addItemType: {
    save: (data) => ({
      type: ADD_NEW_ITEM_TYPE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: ADD_NEW_ITEM_TYPE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: ADD_NEW_ITEM_TYPE_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  editItemType: {
    save: (data) => ({
      type: EDIT_ITEM_TYPE,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: EDIT_ITEM_TYPE_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: EDIT_ITEM_TYPE_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  initDetails: {
    get: (data) => ({
      type: GET_INIT_ITEM_TYPE_DATA,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_INIT_ITEM_TYPE_DATA_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: GET_INIT_ITEM_TYPE_DATA_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  itemTypeDetails: {
    get: (data) => ({
      type: GET_ITEM_TYPE_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    reset: (data) => ({
      type: RESET_ITEM_TYPE_DETAILS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: GET_ITEM_TYPE_DETAILS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    manageBreadcrumb: (data) => ({
      type: HANDLE_ITEM_TYPE_DRAWER_BREADCRUMB,
      payload: {
        data,
        isLoading: true,
      },
    }),
    manageDrawer: (data) => ({
      type: HANDLE_ITEM_TYPE_DRAWER,
      payload: {
        data,
        isLoading: true,
      },
    }),
    fail: (error) => ({
      type: GET_ITEM_TYPE_DETAILS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
  validateFields: {
    get: (data) => ({
      type: DUPLICATE_VALIDATE_FIELDS,
      payload: {
        data,
        isLoading: true,
      },
    }),
    success: (data) => ({
      type: DUPLICATE_VALIDATE_FIELDS_SUCCESS,
      payload: {
        data,
        isLoading: false,
      },
    }),
    fail: (error) => ({
      type: DUPLICATE_VALIDATE_FIELDS_FAILED,
      payload: {
        error,
        isLoading: false,
      },
    }),
  },
};
