import { put, call, takeLatest } from "redux-saga/effects";
import * as Actions from "us.collection.economy/actions";
import * as API from "us.collection.economy/services";
import Common from "us.common";
import { ClientOrders } from "us.collection.economy/constants/Actions";

const { clientOrders } = Actions;
const { $MessageBox } = Common.Components;
const {
  searchOrders,
  searchOrderTypes,
  exportOrders,
  deleteOrder,
  orderGeneration,
} = API.clientOrders;
const { orders, orderTypes, exportOrder, generateOrders, order } = clientOrders;
const { SEARCH_CLIENT_ORDERS, GET_ORDER_TYPES, EXPORT_ORDERS, GENERATE_ORDERS, DELETE_ORDERS } =
  ClientOrders;

export const ClientOrdersSagas = {
  clientInvoices: {
    search: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const data = yield call(searchOrders, params);
        yield put(orders.success(data));
      } catch (error) {
        yield put(orders.fail(new Error()));
      }
    },
    orderTypes: function* (): any {
      try {
        const data = yield call(searchOrderTypes);
        yield put(orderTypes.success(data));
      } catch (error) {
        yield put(orderTypes.fail(new Error()));
      }
    },
    generate: function* (action: any): any {
      const { generateParams, searchParams } = action?.payload?.data ?? {};
      try {
        const { result, data } = yield call(
          orderGeneration,
          generateParams,
          searchParams
        );

        if (result?.status === "Success") {
          yield put(generateOrders.success(result));
          yield put(orders.success(data));
          $MessageBox(
            "loading",
            "US.COLLECTION.ECONOMY:MESSAGES.ORDER_GENERATION_IS_IN_PROGRESS_PLEASE_AWAIT_THE_NOTIFICATION",
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.ORDER_GENERATION_FAILED",
            "",
            ""
          );
          yield put(generateOrders.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:MESSAGES.ORDER_GENERATION_FAILED",
          "",
          ""
        );
        yield put(generateOrders.fail(new Error()));
      }
    },
    export: function* (action: any): any {
      const { exportParams, searchParams } = action?.payload?.data ?? {};
      try {
        const { result, data } = yield call(
          exportOrders,
          exportParams,
          searchParams
        );

        if (result) {
          yield put(exportOrder.success(result));
          yield put(orders.success(data));
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:MESSAGES.ORDER_EXPORT_SUCCESSFUL",
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.ORDER_EXPORT_FAILED",
            "",
            ""
          );
          yield put(exportOrder.fail(new Error()));
        }
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:MESSAGES.ORDER_EXPORT_FAILED",
          "",
          ""
        );
        yield put(exportOrder.fail(new Error()));
      }
    },
    delete: function* (action: any): any {
      const params = action?.payload?.data ?? {};
      try {
        const { deleteResult, searchResult } = yield call(
          deleteOrder,
          params.orderNo,
          params.searchParams
        );
        if (deleteResult.status === 200) {
          yield put(order.success(deleteResult.data));
          if (searchResult.status === 200) {
            yield put(orders.success(searchResult.data));
          }
          $MessageBox(
            "success",
            "US.COLLECTION.ECONOMY:MESSAGES.ORDER_DELETED_SUCCESSFULLY",
            "",
            ""
          );
        } else {
          $MessageBox(
            "error",
            "US.COLLECTION.ECONOMY:MESSAGES.ORDER_DELETE_FAILED",
            "",
            ""
          );
        }
        yield put(exportOrder.fail(new Error()));
      } catch (error) {
        $MessageBox(
          "error",
          "US.COLLECTION.ECONOMY:MESSAGES.ORDER_DELETE_FAILED",
          "",
          ""
        );
        yield put(order.fail(new Error()));
      }
    },
  },
};

export default [
  takeLatest(SEARCH_CLIENT_ORDERS, ClientOrdersSagas.clientInvoices.search),
  takeLatest(GET_ORDER_TYPES, ClientOrdersSagas.clientInvoices.orderTypes),
  takeLatest(EXPORT_ORDERS, ClientOrdersSagas.clientInvoices.export),
  takeLatest(GENERATE_ORDERS, ClientOrdersSagas.clientInvoices.generate),
  takeLatest(DELETE_ORDERS, ClientOrdersSagas.clientInvoices.delete),
];
