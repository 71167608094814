import React from "react";
import { Input, Form } from "antd";
import { useField, Field } from "formik";
import { bureauOwnType } from "us.collection.economy/constants/Payments";
import { useDispatch, useSelector } from "react-redux";
import { managePayment } from "us.collection.economy/actions";
import { useTranslation } from "react-i18next";

interface IAsyncInput {
  name: string;
  label?: string;
  type?: string;
  size?: string;
  payment: any;
  onKeyDown: any
}

export default ({ name, label, type, size, payment, onKeyDown }: IAsyncInput) => {
  const [field, meta, helpers] = useField(name);
  const error = meta?.error;

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const validationCaseIdResults = useSelector(
    (state: any) => state.managePayment.caseValidation.data
  );

  return (
    <Form.Item
      label={label}
      help={
        validationCaseIdResults.arBalance <= 0
          ? t(
              "US.COLLECTION.VALIDATIONS:INVALID.YOU_CANNOT_MAP_TO_A_CASE_WITH_A_0_BALANCE"
            )
          : error
      }
      validateStatus={
        (validationCaseIdResults.arBalance <= 0 || error) && "error"
      }
    >
      <Field
        as={Input}
        {...field}
        type={type}
        size={size}
        onKeyDown={onKeyDown}
        onBlur={(e: any) => {
          let payload = {
            itemTypeId: payment?.itemType == bureauOwnType ? 67 : 0,
            caseNo: e.target.value,
          };
          e.target.value !== "" &&
            e.target.value != 0 &&
            dispatch(managePayment.getCaseValidationData(payload));
          field.onBlur(e);
        }}
        onChange={(v: any) => {
          helpers?.setValue(v.target.value);
          !v.target.value && dispatch(managePayment.clearValidateCaseData());
        }}
      />
    </Form.Item>
  );
};
