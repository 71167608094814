/**
 * @description - Get row index
 * @param {any[]} values - array of journal entries
 * @param {number | string} key - current row key
 * @return - Current row index
 */
export const getRowIndex = (values: any[], key: number | string): number => {
  try {
    if (Array.isArray(values)) {
      return values.findIndex((item: any) => item?.key == key);
    } else {
      return 0;
    }
  } catch (error) {
    return 0;
  }
};

/**
 * @description - Get row validation status by row key
 * @param {any[]} validations - array of journal entries
 * @param {number | string} key - current row key
 * @param {any} field - current field name
 * @return - Current row index
 */
export const getRowValidationStatus = (
  validations: any[],
  key: number | string,
  field: any
): { status: boolean; isLoading: boolean } => {
  try {
    const rowIndex = validations.findIndex(
      (item: any) => item?.rowKey == key && item?.field == field
    );
    if (Array.isArray(validations) && rowIndex >= 0) {
      return {
        status: validations[rowIndex]?.status,
        isLoading: validations[rowIndex]?.isLoading,
      };
    } else {
      return {
        status: true,
        isLoading: false,
      };
    }
  } catch (error) {
    return {
      status: true,
      isLoading: false,
    };
  }
};

/**
 * @description - Get all entries validations status
 * @param {any[]} validations - array of journal entries
 * @return - status
 */
export const getSaveValidationStatus = (
  validations: any[]
): { status: boolean; isLoading: boolean } => {
  try {
    return {
      status: validations.some((item: any) => item?.status == false),
      isLoading: validations.some((item: any) => item?.isLoading),
    };
  } catch (error) {
    return {
      status: false,
      isLoading: false,
    };
  }
};
