import { IOrderGeneratePayload } from "us.collection.economy/interfaces/ClientOrders";
import { OrderGenerationTypes } from "us.collection.economy/components/ClientOrders/Constants";

export function OrderGeneration(this: any): IOrderGeneratePayload {
  return {
    orderType: this.orderType,
    orderCategories:
      this.orderType == OrderGenerationTypes.VAT_ORDERS
        ? ["VAT"]
        : this.categories,
    creditors: this.creditors,
  };
}
