import React from "react";
import { Formik, useFormikContext } from "formik";
import { useTranslation } from "react-i18next";
import { IRootState } from "us.collection/interfaces";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "us.collection.economy/actions";
import { IManageVoucherSequence } from "us.collection.economy/components/ItemTypes/Interfaces";
import { SaveVoucherSequence } from "us.collection.economy/components/ItemTypes/Repository";
import { SequenceValidationSchema } from "./Validations";
import {
  $Button,
  $Input,
  $InputNumber,
  $TextArea,
  $DatePicker,
  $Form,
  $Row,
  $Col,
} from "us.common/components";
import moment from "moment";

/**
 * @description - Add new voucher sequence.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2992242726/Manage+Item+Types+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 23/06/2022
 */
const VoucherSequenceAdd: React.FC<IManageVoucherSequence & PropsFromRedux> = (
  props
) => {
  const { t } = useTranslation();
  const {
    voucherSequenceDetails,
    currentDateFormat,
    addVoucherSequence,
    onCancel,
  } = props;

  const parentFormik = useFormikContext<any>();

  /**
   * @description Handle add new sequence
   * @param {any} values
   */
  const handleSubmit = (values: any) => {
    let itemTypeDetails = { ...parentFormik?.values };

    const sequenceDetails = SaveVoucherSequence.call(values);
    addVoucherSequence &&
      addVoucherSequence({
        itemTypeDetails,
        sequenceDetails,
      });
  };

  return (
    <Formik
      initialValues={voucherSequenceDetails.data}
      validationSchema={SequenceValidationSchema}
      validateOnChange
      validateOnBlur
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical">
            <$Row gutter={16} className="mt-4 mb-2">
              <$Col span={6}>
                <$Input
                  required
                  size="small"
                  name="sequenceId"
                  maxLength={10}
                  label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.SEQUENCE_ID")}
                />
              </$Col>
              <$Col span={6}>
                <$Input
                  required
                  size="small"
                  name="subSequenceId"
                  maxLength={30}
                  label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.SEQUENCE_SUB_ID")}
                />
              </$Col>
            </$Row>
            <$TextArea
              className="w-100 mb-4"
              label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.DESCRIPTION")}
              name="text"
              autoSize={{ minRows: 2, maxRows: 5 }}
              maxLength={500}
            />
            <$Row gutter={16} className="mb-2">
              <$Col span={4}>
                <$InputNumber
                  required
                  size="small"
                  name="nextNo"
                  min={0}
                  label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.NEXT_NO")}
                />
              </$Col>
              <$Col span={6}>
                <$DatePicker
                  name={`prefix`}
                  size="small"
                  label={t("US.COLLECTION.ECONOMY:ITEM_TYPE.PREFIX")}
                  value={values.prefix}
                  allowClear={true}
                  style={{ width: "100%" }}
                  placeholder={currentDateFormat}
                  format={currentDateFormat}
                  onChange={(date: any) => {
                    !date && restProps.setFieldValue("prefix", "");
                    date &&
                      restProps.setFieldValue(
                        "prefix",
                        moment(date).format("YYYY-MM-DD")
                      );
                  }}
                />
              </$Col>
            </$Row>
          </$Form>
          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div>
              <$Button
                className="ml-3 mr-2"
                type="primary"
                loading={voucherSequenceDetails.isSaving}
                disabled={
                  voucherSequenceDetails.isSaving ||
                  !restProps.isValid ||
                  !restProps.dirty ||
                  isSubmitting
                }
                onClick={() => {
                  handleSubmit();
                }}
              >
                {t("US.COLLECTION.COMMON:COMMON.SAVE")}
              </$Button>
              <$Button onClick={() => onCancel()}>
                {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { itemType, common } = state;
  const { voucherSequenceDetails } = itemType;
  const { currentDateFormat } = common;
  return {
    voucherSequenceDetails,
    currentDateFormat,
  };
};

const { addSequence } = Actions.itemTypes;

const mapDispatchToProps = {
  addVoucherSequence: addSequence.save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(VoucherSequenceAdd);
