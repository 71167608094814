import React, { useEffect, useState } from "react";
import { Formik, useField } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import * as Actions from "us.collection.economy/actions";
import {
  FilterOptions,
  ExtraColumnsOptions,
} from "us.collection.economy/components/Remit/Components/CreditorSelector/Constants";
import { ICreditor } from "us.collection.economy/interfaces/Remit";
import { CreditorTable } from "./Components";
import { searchContent, getCreditorNumbers } from "./Functions";
import { ValidationMessage } from "../ValidationMessage";
import { getRouteUrl } from "us.helper";
import "./CreditorSelector.scss";
import { $Button, $Select, $Search, $Skeleton } from "us.common/components";
import { ICreditorSelector, ISearchData, IViewIneligible } from "./Interfaces";

const { creditorGroups, creditors } = Actions.remit;

const CreditorSelector: React.FC<ICreditorSelector & PropsFromRedux> = ({
  name,
  groupName,
  creditorGroups,
  creditors,
  selectedCreditors,
  ineligibleCreditors,
  isGroupsLoading,
  isCreditorsLoading,
  getCreditorGroups,
  getCreditors,
  removeCreditors,
  onSelect,
  onCancel,
  onConfirm,
}) => {
  const { t } = useTranslation();
  const [field, , helpers] = useField(name);
  const [remitAccountField, ,] = useField("remitAccount");
  const [selectedGroupField, , selectedGroupHelpers] = useField(groupName);

  const [viewIneligible, setViewIneligible] = useState<IViewIneligible>({
    isVisible: false,
    navigatedGroupId: null,
  });

  const [searchData, setSearchData] = useState<ISearchData>({
    searchText: "",
    filterTable: null,
    columnFilters: null,
  });

  // Get creditor groups
  useEffect(() => {
    getCreditorGroups &&
      !selectedGroupField.value &&
      getCreditorGroups({ accountNo: remitAccountField.value });

    selectedGroupField.value && onFilter(FilterOptions.SELECTED, field.value);
  }, []);

  // Local search for the creditors
  const onSearch = (searchText: string, restProps: any) => {
    const localCreditors = viewIneligible.isVisible
      ? ineligibleCreditors
      : creditors;
    const filterTable = localCreditors.filter((creditor: ICreditor) => {
      return searchContent(creditor, searchText);
    });
    setSearchData({
      ...searchData,
      searchText,
      filterTable,
    });
    restProps.setFieldValue("selectionFilter", FilterOptions.ALL);
  };

  // Local filter for the creditors
  const onFilter = (option: any, selected: string[]) => {
    const localCreditors = viewIneligible.isVisible
      ? ineligibleCreditors
      : creditors;

    const filterTable = localCreditors.filter((creditor: ICreditor) => {
      if (option === FilterOptions.SELECTED) {
        return (
          selected?.includes(creditor?.creditorNo) &&
          searchContent(creditor, searchData.searchText)
        );
      } else if (option === FilterOptions.NOT_SELECTED) {
        return (
          !selected?.includes(creditor?.creditorNo) &&
          searchContent(creditor, searchData.searchText)
        );
      } else {
        return true;
      }
    });

    setSearchData({
      ...searchData,
      filterTable,
    });
    onSelect && onSelect({ selectedKeys: selected });
  };

  // Form submission
  const handleSubmit = (selectionData: any) => {
    helpers.setValue(selectedCreditors);
    selectedGroupHelpers.setValue(selectionData.creditorGroup);
    onConfirm && onConfirm(selectedCreditors, selectionData.creditorGroup);
  };

  // Reset creditor filters
  const resetFilters = (restProps: any, selectedGroupId?: number) => {
    restProps.setFieldValue("selectionFilter", FilterOptions.ALL);
    document
      .querySelector(
        ".ant-table-filter-dropdown .ant-table-filter-dropdown-btns button" as any
      )
      ?.click();

    setSearchData({
      ...searchData,
      searchText: "",
      filterTable: null,
      columnFilters: null,
    });
  };

  // Navigate to creditor details page
  const onNavigate = ({ creditorNo, groupId }: ICreditor) => {
    setViewIneligible({ ...viewIneligible, navigatedGroupId: groupId });
    getRouteUrl.creditor(creditorNo);
  };

  return (
    <Formik
      initialValues={{
        creditorGroup: selectedGroupField.value ? selectedGroupField.value : [],
        extraColumns: [],
        selectionFilter: FilterOptions.SELECTED,
      }}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <div className="creditor-selector d-flex align-items-center justify-content-between">
            <div>{t("US.COLLECTION.ECONOMY:REMIT.CREDITOR_GROUP")}</div>
            <div className="d-flex align-items-center">
              <span className="text-muted mr-3">
                <span className="mr-2">{values.creditorGroup?.length}</span>
                {values.creditorGroup?.length > 1
                  ? t("US.COLLECTION.ECONOMY:REMIT.GROUPS_SELECTED")
                  : t("US.COLLECTION.ECONOMY:REMIT.GROUP_SELECTED")}
              </span>
            </div>
          </div>
          <div>
            <$Select
              dataTestid="select-creditor-group"
              name="creditorGroup"
              mode="multiple"
              size="large"
              formitem={{}}
              allowClear
              showSearch
              onSearchBy={["groupName"]}
              options={creditorGroups}
              loading={isGroupsLoading}
              disabled={isGroupsLoading}
              optionValue="groupId"
              optionText="groupName"
              allOption={false}
              onSelect={(selectedGroupId: number) => {
                getCreditors &&
                  getCreditors({
                    groupId: selectedGroupId,
                    accountNo: remitAccountField.value,
                  });
                resetFilters(restProps, selectedGroupId);
              }}
              onDeselect={(selectedGroupId: number) => {
                removeCreditors &&
                  removeCreditors({ groupId: selectedGroupId });

                resetFilters(restProps, selectedGroupId);
              }}
            />
          </div>
          <$Skeleton
            loading={isGroupsLoading || isCreditorsLoading}
            active
            paragraph={{ rows: 3 }}
          >
            <>
              <div className="font-weight-bold mt-3">
                {t("US.COLLECTION.ECONOMY:REMIT.CREDITORS")}
              </div>

              {ineligibleCreditors?.length !== 0 && (
                <div className="mb-3">
                  <ValidationMessage
                    type="error"
                    message={`${t(
                      "US.COLLECTION.ECONOMY:REMIT.MISSING_INFORMATION_ON"
                    )} ${ineligibleCreditors?.length} ${t(
                      "US.COLLECTION.COMMON:COMMON.CREDITORS"
                    )}`}
                    buttonTitle={
                      !viewIneligible.isVisible
                        ? t("US.COLLECTION.ECONOMY:REMIT.VIEW_DETAILS")
                        : t("US.COLLECTION.ECONOMY:REMIT.CLOSE_DETAILS")
                    }
                    onPress={() => {
                      setViewIneligible({
                        ...viewIneligible,
                        isVisible: !viewIneligible.isVisible,
                      });
                      resetFilters(restProps);
                    }}
                  />
                </div>
              )}

              <div className="d-flex align-items-center justify-content-between">
                <div>
                  <$Search
                    size="small"
                    name="filterCreditorGroup"
                    allowClear={true}
                    value={searchData.searchText}
                    placeholder={t(
                      "US.COLLECTION.COMMON:COMMON.FILTER_BY_NUMBER,NAME,BELONGS_TO_GROUP"
                    )}
                    style={{ width: 400 }}
                    onChange={(value: any) =>
                      onSearch(value.target.value, restProps)
                    }
                  />
                  <$Select
                    name="selectionFilter"
                    size="small"
                    options={[
                      {
                        label: t("COMMON.ALL"),
                        value: FilterOptions.ALL,
                      },
                      {
                        label: t("COMMON.SELECTED"),
                        value: FilterOptions.SELECTED,
                      },
                      {
                        label: t("COMMON.NOT_SELECTED"),
                        value: FilterOptions.NOT_SELECTED,
                      },
                    ]}
                    onChange={(value: any) => {
                      onFilter(value, selectedCreditors);
                    }}
                    value={values?.selectionFilter}
                    allOption={false}
                    style={{ width: 150 }}
                    hideSearch={true}
                    className="ml-2"
                  />
                </div>

                <div className="d-flex align-items-center">
                  <$Select
                    name="extraColumns"
                    size="small"
                    options={[
                      {
                        label: t("US.COLLECTION.ECONOMY:REMIT.ZIP_CODE"),
                        value: ExtraColumnsOptions.ZIP_CODE,
                      },
                      {
                        label: t("US.COLLECTION.ECONOMY:REMIT.ZIP_NAME"),
                        value: ExtraColumnsOptions.ZIP_NAME,
                      },
                    ]}
                    mode="multiple"
                    placeholder={t(
                      "US.COLLECTION.ECONOMY:REMIT.SELECT_COLUMNS"
                    )}
                    allOption={false}
                    style={{ width: 300 }}
                    className="ml-2"
                  />
                </div>
              </div>

              <CreditorTable
                values={values}
                creditors={creditors}
                selectedCreditors={selectedCreditors}
                creditorGroups={creditorGroups}
                ineligibleCreditors={ineligibleCreditors}
                searchData={searchData}
                viewIneligible={viewIneligible}
                onNavigate={onNavigate}
                onResetFilters={() => resetFilters(restProps)}
                onSelectAll={() => {
                  if (onSelect)
                    selectedCreditors?.length !==
                    getCreditorNumbers(creditors)?.length
                      ? onSelect({
                          selectedKeys: getCreditorNumbers(creditors),
                        })
                      : onSelect({ selectedKeys: [] });
                }}
                onSelect={(selectedRowKeys: Array<any>) => {
                  onSelect && onSelect({ selectedKeys: selectedRowKeys });
                }}
                onChange={(pagination: any, filters: any) => {
                  setSearchData({
                    ...searchData,
                    columnFilters: filters?.groupName,
                  });
                }}
              />
            </>
          </$Skeleton>
          <div className="drawer-footer-fixed align-items-end justify-content-between">
            <div className="d-flex flex-column">
              <div
                className="font-weight-bold"
                data-testid="selected-creditor-count"
              >
                {isGroupsLoading ? 0 : selectedCreditors?.length}
              </div>
              <div>{t("US.COLLECTION.ECONOMY:REMIT.SELECTED_CREDITORS")}</div>
            </div>
            <div>
              {viewIneligible.navigatedGroupId && (
                <$Button
                  type="primary"
                  disabled={isGroupsLoading}
                  onClick={() => {
                    getCreditors &&
                      getCreditors({
                        groupId: viewIneligible.navigatedGroupId,
                        accountNo: remitAccountField.value,
                        isRefresh: true,
                      });
                    resetFilters(restProps);
                    setViewIneligible({
                      isVisible: false,
                      navigatedGroupId: null,
                    });
                  }}
                >
                  {t("US.COLLECTION.COMMON:COMMON.REFRESH")}
                </$Button>
              )}
              <$Button
                id="btnViewRemit"
                className="ml-2 mr-2"
                data-testid="confirm-selection"
                type="primary"
                disabled={isGroupsLoading || selectedCreditors?.length === 0}
                onClick={(e: any) => handleSubmit(e)}
              >
                {t("US.COLLECTION.COMMON:COMMON.VIEW_REMIT")}
              </$Button>
              <$Button
                onClick={() => onCancel && onCancel()}
                data-testid="cancel-selection"
              >
                {t("US.COMMON:COMMON.CANCEL")}
              </$Button>
            </div>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { remit } = state;

  const { creditorGroups, creditors } = remit;
  return {
    creditorGroups: creditorGroups.data,
    isGroupsLoading: creditorGroups.isLoading,
    isCreditorsLoading: creditors.isLoading,
    ineligibleCreditors: creditors.ineligible,
    creditors: creditors.data,
    selectedCreditors: creditors.selected,
  };
};

const mapDispatchToProps = {
  getCreditorGroups: creditorGroups.get,
  getCreditors: creditors.get,
  removeCreditors: creditors.remove,
  onSelect: creditors.select,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CreditorSelector);
