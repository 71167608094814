import { RemitHistoryTableColumn } from "us.collection.economy/components/RemitHistory/Constants";
import { ColumnFilterTypes } from "us.common/components/antd/TableTree/Constants";

/**
 * @description Get column search input field current value by key
 * @param key - input field name
 * @returns value - Current value of the input field
 */
export const getColumnFilterProps = (key: RemitHistoryTableColumn) => {
  switch (key) {
    case RemitHistoryTableColumn.MENU:
    case RemitHistoryTableColumn.VALIDATION_STATUS:
    case RemitHistoryTableColumn.REMIT_PERIOD:
      return {};
    case RemitHistoryTableColumn.FILE_DATE:
      return { customFilter: ColumnFilterTypes.DATE_TIME, showFilters: true };
    case RemitHistoryTableColumn.BUREAU_AMOUNT:
    case RemitHistoryTableColumn.CREDITOR_AMOUNT:
    case RemitHistoryTableColumn.DEBTOR_AMOUNT:
    case RemitHistoryTableColumn.TOTAL_AMOUNT:
      return { customFilter: ColumnFilterTypes.AMOUNT, showFilters: true };
    default:
      return { customFilter: true, showFilters: true };
  }
};
