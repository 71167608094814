import React from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { IGenerateOrders } from "us.collection.economy/components/ClientOrders/Interfaces";
import { OrderGenerationTypes } from "us.collection.economy/components/ClientOrders/Constants";
import * as Actions from "us.collection.economy/actions";
import {
  OrderGeneration,
  SearchOrders,
} from "us.collection.economy/components/ClientOrders/Repository";
import { GenerateOrderValidations } from "us.collection.economy/components/ClientOrders/Validations";
import { getCategories } from "us.collection.economy/components/ClientOrders/Components/OrderGeneration/Functions";
import Common from "us.common";

const {
  $Divider,
  $Button,
  $Form,
  $Select,
  $Skeleton,
  $CheckBoxList,
  $SelectCreditors,
  $Popconfirm,
} = Common.Components;

const GenerateContent: React.FC<PropsFromRedux & IGenerateOrders> = (props) => {
  const { t } = useTranslation();

  const {
    categories,
    searchValues,
    isCategoriesLoading,
    isGenerating,
    generateOrders,
    onClose,
  } = props;

  // handle order generation
  const onGenerateOrders = (values: any, actions: any) => {
    actions.setSubmitting(true);
    const generateParams = OrderGeneration.call(values);
    const searchParams = SearchOrders.call({
      ...searchValues,
      orderTypes: categories,
    });

    generateOrders && generateOrders({ generateParams, searchParams });
  };

  return (
    <Formik
      initialValues={{
        orderType: OrderGenerationTypes.CREDITOR_ORDERS,
      }}
      enableReinitialize
      validateOnChange
      validationSchema={GenerateOrderValidations}
      onSubmit={onGenerateOrders}
    >
      {({
        values,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...restProps
      }: any) => (
        <>
          <$Form layout="vertical">
            <$Skeleton
              loading={isCategoriesLoading}
              active
              paragraph={{ rows: 2 }}
            >
              <>
                <div className="d-flex align-items-end">
                  <div style={{ width: "250px" }}>
                    <$Select
                      name="orderType"
                      formitem={{
                        label: t("US.COLLECTION.ECONOMY:ORDERS.TYPE"),
                      }}
                      size="small"
                      options={[
                        {
                          value: OrderGenerationTypes.CREDITOR_ORDERS,
                          label: t(
                            "US.COLLECTION.ECONOMY:ORDERS.CREDITOR_ORDERS"
                          ),
                        },
                        {
                          value: OrderGenerationTypes.VAT_ORDERS,
                          label: t("US.COLLECTION.ECONOMY:ORDERS.VAT_ORDERS"),
                        },
                      ]}
                      allOption={false}
                      onChange={(orderType: OrderGenerationTypes) => {
                        restProps.setFieldValue("orderType", orderType);
                        restProps.setFieldValue("categories", undefined);
                      }}
                    />
                  </div>
                  <div className="text-muted pb-2 ml-4">
                    {t(
                      "US.COLLECTION.ECONOMY:ORDERS.ORDERS_FOR_BUREAU_SERVICES"
                    )}
                  </div>
                </div>
                <$Divider />
                <div>
                  {values?.orderType ==
                    OrderGenerationTypes.CREDITOR_ORDERS && (
                    <$CheckBoxList
                      name="categories"
                      optionText="categoryName"
                      optionValue="categoryCode"
                      options={getCategories(categories)}
                    />
                  )}
                </div>
              </>
            </$Skeleton>
            <$SelectCreditors
              name="creditors"
              groupName="creditorGroups"
              withDrawer={false}
              defaultAllSelectionFilter={true}
              disabledKeys={[1]}
              onSelect={(selectedKeys: any) =>
                restProps.setFieldValue("creditors", selectedKeys)
              }
            />
          </$Form>

          <div className="drawer-footer-fixed align-content-center justify-content-end">
            <div className="ml-3 mr-2">
              <$Popconfirm
                title={t(
                  "US.COLLECTION.ECONOMY:ORDERS.YOU_WILL_BE_NOTIFIED_WHEN_THE_ORDERS_ARE_GENERATED"
                )}
                placement="topLeft"
                data-testid="pop-confirm"
                onConfirm={(e: any) => handleSubmit(e)}
                okText={t("COMMON.OK")}
                showCancel={false}
                disabled={
                  !restProps.isValid ||
                  !restProps.dirty ||
                  isSubmitting ||
                  isGenerating
                }
              >
                <$Button
                  data-testid="submit-order-generation"
                  type="primary"
                  disabled={
                    !restProps.isValid ||
                    !restProps.dirty ||
                    isSubmitting ||
                    isGenerating
                  }
                  loading={isSubmitting || isValidating || isGenerating}
                >
                  {t("US.COLLECTION.COMMON:COMMON.GENERATE")}
                </$Button>
              </$Popconfirm>
            </div>
            <$Button
              data-testid="cancel-order-generation"
              onClick={() => onClose && onClose()}
            >
              {t("US.COLLECTION.COMMON:COMMON.CANCEL")}
            </$Button>
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: any) => {
  const { common, clientOrders } = state;

  const { orderTypes, generateOrder, isLoadingOrdersTypes } = clientOrders;
  const { currentDateFormat, currentLanguage, currentCurrency } = common;

  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
    categories: orderTypes,
    isCategoriesLoading: isLoadingOrdersTypes,
    isGenerating: generateOrder.isLoading,
  };
};
const { generateOrders } = Actions.clientOrders;

const { save } = generateOrders;

const mapDispatchToProps = {
  generateOrders: save,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(GenerateContent);
