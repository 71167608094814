import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useTranslation } from "react-i18next";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import Common from "us.common";
import { PlusOutlined } from "us.icons";
import {
  ItemTypeAction,
  ItemTypesTable,
  ItemTypesFilter,
  ItemTypeDetails,
} from "./Components";
import { IRootState } from "us.collection/interfaces";
import { IItemType } from "us.collection.economy/interfaces";
import * as Actions from "us.collection.economy/actions";
import {
  INITIAL_FORM_VALUES,
  DEFAULT_DRAWER_STATE,
  DrawerType,
} from "./Constants";
import { IFormValues } from "./Interfaces";
import { SearchItemTypes } from "./Repository";
import { handleGoBack } from "us.helper/utility";
import "./Home.scss";

const { $Affix, $Drawer, $PageHeader, $Divider, $Button } = Common.Components;

/**
 * @description - Item types table component to show and filter all available item types.
 * @link Design Document - https://unicorn-solutions.atlassian.net/wiki/spaces/USU/pages/2992242726/Manage+Item+Types+-+UI+Implementation
 * @author Ishan Udyoga <ishanud@unicorn-solutions.com>
 * @since 17/06/2022
 */
const ItemTypesHome: React.FC<PropsFromRedux> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { replace } = history;

  const [filterValues, setFilterValues] =
    useState<IFormValues>(INITIAL_FORM_VALUES);

  const { drawerInfo, searchItemTypes, init, manageDrawer, deleteItemType } =
    props;

  useEffect(() => {
    const searchParams = SearchItemTypes.call(INITIAL_FORM_VALUES);
    init && init({ searchParams });
  }, []);

  /**
   * onSearch
   * search item types API will call with the filter values
   */
  const onSearch = (values: IFormValues) => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setFilterValues({
      ...filterValues,
      ...values,
    });
    const searchParams = SearchItemTypes.call(values);
    searchItemTypes && searchItemTypes({ searchParams });
  };

  /**
   * @description - Close the drawer
   */
  const closeDrawer = () => {
    updateUrl();
    manageDrawer && manageDrawer(DEFAULT_DRAWER_STATE);
  };

  /**
   * @description Open the drawer by type
   * @param {DrawerType} type - Type of the drawer
   * @param {number} itemTypeId - Selected item type id
   */
  const openDrawer = (type: DrawerType, itemTypeId?: number) => {
    // decide the suitable title for the drawer
    let title =
      type === DrawerType.EDIT_ITEM_TYPE
        ? t("US.COLLECTION.ECONOMY:ITEM_TYPE.EDIT_ITEM_TYPE")
        : t("US.COLLECTION.ECONOMY:ITEM_TYPE.NEW_ITEM_TYPE");

    // update the url that related to the selected item type or current action
    updateUrl(itemTypeId, type);
    // change drawer state
    manageDrawer &&
      manageDrawer({
        type,
        title,
      });
  };

  /**
   * @description - Update the browser tab url according to given id value
   * @param {number} itemTypeId - item type id if available
   * @param {ItemTypeAction | DrawerType} type - drawer type
   */
  const updateUrl = (
    itemTypeId?: number,
    type?: ItemTypeAction | DrawerType
  ) => {
    // update the URL params
    replace({
      pathname: "/item-types",
      search: itemTypeId
        ? `?item-type-id=${itemTypeId}${
            type ? `&type=${type?.toLowerCase()}` : ""
          }`
        : type
        ? `?type=${type?.toLowerCase()}`
        : "",
    });
  };

  /**
   * @description handle item types menu actions
   * @param action - action of clicked menu icon
   * @param record - selected item type for action
   * @param values - formik values
   */
  const onHandleTableAction = (
    action: ItemTypeAction,
    record: IItemType,
    values: any
  ) => {
    const { itemTypeId } = record;
    const searchParams = SearchItemTypes.call(values);
    switch (action) {
      case ItemTypeAction.EDIT:
        openDrawer(DrawerType.EDIT_ITEM_TYPE, itemTypeId);
        break;
      case ItemTypeAction.DELETE:
        deleteItemType && deleteItemType({ itemTypeId, searchParams });
        break;
      default:
        break;
    }
  };

  return (
    <Formik initialValues={filterValues} enableReinitialize onSubmit={() => {}}>
      {({ values }: any) => (
        <>
          <div className="space-content">
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex align-items-center">
                  <$PageHeader
                    className="px-0"
                    onBack={() => handleGoBack(history)}
                    title={t("US.COLLECTION.ECONOMY:ITEM_TYPE.ITEM_TYPE")}
                  />
                  <$Divider className="bui-devider" type="vertical" />
                  <$Button
                    data-testid="new-item-type"
                    className="mr-2"
                    type="default"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => openDrawer(DrawerType.NEW_ITEM_TYPE)}
                  >
                    {t("US.COLLECTION.ECONOMY:ITEM_TYPE.NEW_ITEM_TYPE")}
                  </$Button>
                </div>
              </div>
            </$Affix>
          </div>
          <div className="item-type-layout">
            <ItemTypesFilter
              onChange={(filters: any) => onSearch({ ...values, ...filters })}
            />
            <div className="flex-fill px-4">
              <div className="item-type-content ">
                <ItemTypesTable
                  onCallAction={(action: ItemTypeAction, record: IItemType) =>
                    onHandleTableAction(action, record, values)
                  }
                />
              </div>
            </div>
          </div>

          <$Drawer
            title={drawerInfo.title}
            width={"800px"}
            visible={drawerInfo.visible}
            onClose={closeDrawer}
            destroyOnClose={true}
            maskClosable={false}
          >
            {[DrawerType.NEW_ITEM_TYPE, DrawerType.EDIT_ITEM_TYPE].includes(
              drawerInfo.type
            ) && <ItemTypeDetails onCancel={closeDrawer} />}
          </$Drawer>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { itemType } = state;
  const { itemTypes, transactionGroups, drawerInfo } = itemType;

  return {
    itemTypes,
    transactionGroups,
    drawerInfo,
  };
};

const { init, types, itemTypeDetails } = Actions.itemTypes;

const mapDispatchToProps = {
  init: init.get,
  searchItemTypes: types.search,
  deleteItemType: types.delete,
  manageDrawer: itemTypeDetails.manageDrawer,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ItemTypesHome);
