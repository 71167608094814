import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Formik } from "formik";
import queryString from "query-string";
import moment from "moment";
import _ from "lodash";

import * as Components from "us.common/components";
import * as Actions from "us.collection.economy/actions";

import { BoxIcons, IconTypes, SearchOutlined } from "us.icons";
import { useTranslation } from "react-i18next";
import { PaymentStates } from "us.collection.economy/constants/Payments";
import { IManagePaymentsOld } from "./interface";
import { useLocation, useHistory } from "react-router-dom";
import { IRootState } from "us.collection/interfaces";
import { GetPayments } from "./Repository/ManagePayments";
import { paymentOptions, periodTypeOptions, getCounts } from "./Functions";
import { GridView } from "./Components";
import "./Home.scss";
import { handleGoBack } from "us.helper/utility";

const { managePaymentGrid } = Actions;
const { EXCEEDED, UNKNOWN, MAPPED, RETURNED, UNEXED } = PaymentStates;

const { $Button, $PageHeader, $Affix, $Select, $Tooltip, $RangePicker } =
  Components;

const ManagePaymentsOld: React.FC<IManagePaymentsOld> = (props) => {
  const {
    currentDateFormat,
    payments,
    initPayments,
    paymentAccounts,
    searchPayments,
    onChangeView,
  } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const { search } = useLocation();
  const params = queryString.parse(search);

  const [dates, setDates] = useState<Array<moment.Moment>>([]);
  const [mainFilters, setMainFilters] = useState<any>({
    paymentDateRange: [moment().subtract(1, "d"), moment()],
    paymentId: params?.paymentId ? params?.paymentId : 0,
    debtorOtherCasesStatus: 3,
    paymentStates: UNEXED,
    periodType: "REG",
    accountNo: "",
    offset: 0,
    limit: 100000,
  });

  useEffect(() => {
    handleSearchPayments(mainFilters, true);
  }, []);

  useEffect(() => {
    setMainFilters({
      ...mainFilters,
      accountNo: paymentAccounts.data[0]?.accountNo,
    });
  }, [paymentAccounts.isFetching]);

  // Search function for all columns
  const handleSearchPayments = (filters: any, isInit: boolean) => {
    const [startDate, endDate] = filters.paymentDateRange;
    let paymentStates;
    if (filters.paymentStates == "ALL") {
      paymentStates = [EXCEEDED, MAPPED, UNKNOWN, RETURNED];
    } else if (filters.paymentStates == UNEXED) {
      paymentStates = [UNKNOWN, EXCEEDED];
    } else {
      paymentStates = [filters.paymentStates];
    }
    const searchFilters = GetPayments.call({
      ...filters,
      paymentId: parseInt(filters.paymentId),
      startDate: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      endDate: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      paymentStates,
    });
    isInit && initPayments(searchFilters);
    !isInit && searchPayments(searchFilters);
  };

  // set dates that should disabled for selecting more than 14 days
  const disabledDate = (current: any) => {
    // disable future dates
    if (current.isAfter(moment())) return true;
    // don't disable if no dates selected
    if (!dates || dates.length === 0) {
      return false;
    }
    // if first date selected then
    const tooLate = dates[0] && current.diff(dates[0], "days") >= 14;
    // if last date selected without first date then
    const tooEarly =
      !dates[0] && dates[1] && dates[1].diff(current, "days") >= 14;
    return tooLate || tooEarly;
  };

  return (
    <Formik
      initialValues={{ ...mainFilters }}
      enableReinitialize
      validateOnChange
      validateOnBlur
      onSubmit={() => {}}
    >
      {({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        isValidating,
        resetForm,
        ...rest
      }) => (
        <>
          <div>
            <$Affix offsetTop={48}>
              <div className="page-header header-border">
                <div className="d-flex flex-row align-items-center justify-content-between">
                  <div className="d-flex align-items-center" data-testid="grid-view">
                    <$PageHeader
                      className="px-0"
                      title={t(
                        "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.MANAGE_PAYMENTS"
                      )}
                      onBack={() => handleGoBack(history)}
                      extra={
                        <$Tooltip
                          title={t(
                            "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.CHANGE_VIEW"
                          )}
                        >
                          <$Button
                            onClick={onChangeView}
                            className="px-1 my-1"
                            data-testid="toggle-grid-view"
                            size="small"
                            type="link"
                            icon={
                              <BoxIcons
                                type={IconTypes.BOX_ICON}
                                name="toggle-view"
                              />
                            }
                          ></$Button>
                        </$Tooltip>
                      }
                    />
                    {/* <$Divider className="bui-devider" type="vertical" />
                    <$Skeleton loading={false} active paragraph={{ rows: 0 }}>
                      <$Button type="primary" size="small" onClick={() => {}}>
                        {t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.COMMIT_MATCHED_RECORD"
                        )}
                      </$Button>
                      <$Button type="dashed" size="small" className="ml-3">
                        {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.RESET")}
                      </$Button>
                    </$Skeleton> */}
                  </div>
                  <div className="d-flex">
                    <div className="man-payment-header-btn tag-status-unknown d-flex mr-2">
                      <span className="mb-n1">
                        {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UNKNOWN")}
                      </span>
                      <span className="mb-0 ml-3">
                        {getCounts(payments.data.raw).unkwCount}
                      </span>
                    </div>
                    <div className="man-payment-header-btn tag-status-exceeded d-flex mr-2">
                      <span className="mb-n1">
                        {t("US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.EXCEEDED")}
                      </span>
                      <span className="ml-3">
                        {getCounts(payments.data.raw).exedCount}
                      </span>
                    </div>
                    <div className="man-payment-header-btn unapportion-bg d-flex">
                      <span className="mb-n1">
                        {t(
                          "US.COLLECTION.ECONOMY:MANAGE_PAYMENTS.UN_APPORTIONED_PAYMENTS"
                        )}
                      </span>
                      <span className="ml-3">0</span>
                    </div>
                  </div>
                </div>
                <div className="d-flex flex-row align-items-center">
                  <$Tooltip title="Date">
                    <$Select
                      name="periodType"
                      className="mr-3"
                      size="small"
                      style={{ width: 220 }}
                      options={periodTypeOptions(t)}
                      defaultValue={"regDate"}
                      data-testid="select-period-type"
                      optionValue="value"
                      optionText="label"
                      allOption={false}
                    />
                  </$Tooltip>

                  <div className="mr-3 mb-n1">
                    <$RangePicker
                      name={`paymentDateRange`}
                      currentTimeFormat={currentDateFormat}
                      size="small"
                      picker="date"
                      onCalendarChange={(val: any) => setDates(val)}
                      disabledDate={disabledDate}
                      onOpenChange={(isOpen: boolean) => {
                        if (isOpen) {
                          setDates([]);
                          rest.setFieldValue("paymentDateRange", undefined);
                        } else {
                          !dates.length &&
                            rest.setFieldValue(
                              "paymentDateRange",
                              mainFilters.paymentDateRange
                            );
                        }
                      }}
                    />
                  </div>
                  <$Tooltip title="Type">
                    <$Select
                      name="paymentStates"
                      className="mr-3"
                      data-testid="select-payment-states"
                      placeholder={t("COMMON.STATUS")}
                      size="small"
                      style={{ width: 170 }}
                      options={paymentOptions(t)}
                      allOption={false}
                      optionValue="value"
                      optionText="label"
                    />
                  </$Tooltip>

                  <$Tooltip title="Account">
                    <$Select
                      name="accountNo"
                      data-testid="select-account"
                      placeholder={t("COMMON.TYPE")}
                      size="small"
                      style={{ width: 255 }}
                      options={paymentAccounts.data}
                      allOption={false}
                      optionValue="accountNo"
                      optionText="accountName"
                      optionExtra={{
                        extraNumber: "accountNo",
                      }}
                      loading={paymentAccounts.isFetching}
                      disabled={paymentAccounts.isFetching}
                    />
                  </$Tooltip>

                  <$Button
                    type="dashed"
                    size="small"
                    className="ml-2"
                    disabled={
                      payments.isFetching ||
                      paymentAccounts.isFetching ||
                      !values.paymentDateRange
                    }
                    loading={payments.isFetching || paymentAccounts.isFetching}
                    icon={<SearchOutlined />}
                    onClick={() => {
                      handleSearchPayments(values, false);
                    }}
                  />
                </div>
              </div>
            </$Affix>

            <GridView paymentDateRange={values.paymentDateRange} />
          </div>
        </>
      )}
    </Formik>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { managePayment } = state;

  const { paymentsGrid, paymentAccounts } = managePayment;
  return {
    paymentAccounts,
    payments: paymentsGrid,
    currentDateFormat: state.common.currentDateFormat,
  };
};

const mapDispatchToProps = {
  searchPayments: managePaymentGrid.payments,
  initPayments: managePaymentGrid.initPayments,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagePaymentsOld);
