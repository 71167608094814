export const initialState = {
  totalInvoices: 0,
  totalInvoicesAmount: 0,
  exported: {
    invoices: [],
    filtered: [],
  },
  pendingExport: {
    invoices: [],
    filtered: [],
  },
  isLoadingInvoices: true,
  activeTabKey: 0,

  details: {
    data: {
      invoiceId: 0,
      invoiceType: null,
      invoiceNo: null,
      orderNo: 0,
      customerNo: null,
      customerName: null,
      dueDate: null,
      amount: 0,
      balance: 0,
      exportType: null,
      isExported: false,
      creditorOrderlines: [],
      arNo: 0,
      creditInvoiceDescription: "",
      creditedAmount: null,
      creditorNo: null,
      isCreditInvoice: false,
      sourceInvoceNo: null,
    },
    isLoading: false,
  },
  creditNote: {
    isSaving: false,
    result: null,
  },
  creditNoteExport: {
    isLoading: false,
    result: null,
  },
  drawer: {
    isVisible: false,
    isOpenByList: false,
  },
  invoiceExport: {
    isLoading: false,
    result: null,
  },
  searchParams: {},
};
