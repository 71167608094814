import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import moment from "moment";
import { useHistory } from "react-router-dom";

import Common from "us.common";
import { SearchOutlined } from "us.icons";
import "us.collection.economy/components/ClientInvoices/Home.scss";
import {
  CISearchType,
  ClientInvoiceType,
  ICISearchType,
} from "us.collection.economy/components/ClientInvoices/Constants";
import { IHeader } from "./interface";
import { useField } from "formik";
import { handleGoBack } from "us.helper/utility";

const {
  $Affix,
  $PageHeader,
  $Divider,
  $Button,
  $Select,
  $RangePicker,
  $Search,
  $Message,
} = Common.Components;

const Header: React.FC<IHeader> = ({
  disabled,
  restProps,
  currentDateRange,
  onSearch,
  onChange,
  creditInvoice,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [dates, setDates] = useState<Array<moment.Moment>>([]);
  const { currentDateFormat } = useSelector((state: any) => state.common);
  const [, meta] = useField("searchType");
  const isInvoiceNo = meta.value == CISearchType.INVOICE_NO;
  const isDueDate = meta.value == CISearchType.DUE_DATE;
  const isExportedDate = meta.value == CISearchType.EXPORTED_DATE;

  // set dates that should disabled for selecting more than 30 days
  const disabledDate = (current: any) => {
    // disable future dates
    if (current.isAfter(moment()) && isExportedDate) return true;
    // disable future dates from last day of this month
    if (current.isAfter(moment().endOf("month")) && !isDueDate) return true;
    // don't disable if no dates selected
    if (!dates || dates.length === 0) {
      return false;
    }
  };

  return (
    <$Affix offsetTop={48}>
      <div className="page-header header-border">
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center">
            <$PageHeader
              className="px-0"
              onBack={() => handleGoBack(history)}
              title={t("US.COLLECTION.ECONOMY:INVOICES.INVOICES")}
            />
            <$Divider className="bui-devider" type="vertical" />

            <$Select
              dataTestid="select-search-type"
              name="searchType"
              style={{ width: "150px" }}
              formitem={{}}
              size="small"
              showSearch={false}
              options={[
                {
                  label: t("US.COLLECTION.ECONOMY:INVOICES.INVOICED_DATE"),
                  searchType: CISearchType.REG_DATE,
                },
                {
                  label: t("US.COLLECTION.ECONOMY:INVOICES.DUE_DATE"),
                  searchType: CISearchType.DUE_DATE,
                },
                {
                  label: t("US.COLLECTION.ECONOMY:INVOICES.EXPORTED_DATE"),
                  searchType: CISearchType.EXPORTED_DATE,
                },
                {
                  label: t("US.COLLECTION.ECONOMY:INVOICES.INVOICE_NO"),
                  searchType: CISearchType.INVOICE_NO,
                },
              ]}
              optionValue="searchType"
              optionText="label"
              defaultValue={CISearchType.REG_DATE}
              defaultLabel={t("US.COLLECTION.ECONOMY:INVOICES.INVOICED_DATE")}
              allOption={false}
              className="mr-2"
              onChange={(searchType: ICISearchType) => {
                onChange({
                  searchType,
                  invoiceNo: "",
                  creditInvoicesOnly: false,
                  invoiceType: ClientInvoiceType.CLIENT_INVOICES,
                });
              }}
            />
            {!isInvoiceNo && (
              <$RangePicker
                name="dateRange"
                size="small"
                picker="date"
                minuteStep={15}
                order={false}
                currentTimeFormat={currentDateFormat}
                onCalendarChange={(val: any) => {
                  setDates(val);
                }}
                onOpenChange={(isOpen: boolean) => {
                  if (isOpen) {
                    setDates([]);
                    restProps.setFieldValue("dateRange", undefined);
                  } else {
                    restProps.setFieldValue("dateRange", currentDateRange);
                  }
                }}
                disabledDate={disabledDate}
                onChange={(dateRange: any) => {
                  if (dateRange) {
                    onChange({ dateRange });
                  }
                }}
              />
            )}
            {isInvoiceNo && (
              <$Search
                name="invoiceNo"
                size="small"
                allowClear
                required
                className="mb-1 ml-2"
                placeholder={t(
                  "US.COLLECTION.ECONOMY:PLACE_HOLDERS.SEARCH_BY_INVOICE_NO"
                )}
                onSearch={async (text: string) => {
                  restProps.isValid
                    ? text && onSearch()
                    : $Message.error(
                        t(
                          "US.COLLECTION.ECONOMY:VALIDATIONS.INVALID_PAYMENT_ID"
                        )
                      );
                }}
                disabled={disabled}
              />
            )}
            {!isInvoiceNo && (
              <$Button
                className="mb-1 ml-1"
                type="default"
                size="small"
                disabled={disabled}
                onClick={onSearch}
                icon={<SearchOutlined />}
              />
            )}
          </div>
          <div>
            <$Button type="default" size="small" onClick={creditInvoice}>
              {t("US.COLLECTION.ECONOMY:INVOICES.NEW_CREDIT_INVOICE")}
            </$Button>
          </div>
        </div>
      </div>
    </$Affix>
  );
};

export default Header;
