import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { connect, ConnectedProps } from "react-redux";
import Common from "us.common";
import { ICIFilter } from "./interface";
import "us.collection.economy/components/ClientInvoices/Home.scss";
import {
  CISearchType,
  ClientInvoiceType,
  IClientInvoiceType,
} from "us.collection.economy/components/ClientInvoices/Constants";
import { IRootState } from "us.collection/interfaces";

const {
  $Affix,
  $Divider,
  $AmountLabel,
  $Input,
  $Select,
  $RangePicker,
  $Form,
  $Switch,
  $Button,
  $Badge,
} = Common.Components;

const Filter: React.FC<ICIFilter & PropsFromRedux> = (props) => {
  const { t } = useTranslation();

  const {
    creditors,
    totalInvoices,
    totalInvoicesAmount,
    values,
    restProps,
    onChange,
    onSelectCreditors,
    onReset,
    onFilter,
    currentDateFormat,
  } = props;

  const { searchType, creditInvoicesOnly, activeTabKey } = values;
  const [dates, setDates] = useState<Array<moment.Moment>>([]);

  const isSearchByInvoiceNo = searchType === CISearchType.INVOICE_NO;

  // set dates that should disabled for selecting more than 30 days
  const disabledDate = (current: any): boolean => {
    try {
      // don't disable if no dates selected
      if (!dates || dates.length === 0) {
        return false;
      }
      // if first date selected then
      const tooLate = dates[0] && current.diff(dates[0], "days") >= 31;
      // if last date selected without first date then
      const tooEarly =
        !dates[0] && dates[1] && dates[1].diff(current, "days") >= 31;
      return tooLate || tooEarly;
    } catch (error) {
      return false;
    }
  };

  return (
    <$Affix offsetTop={95}>
      <aside className="invoices-side  d-flex align-items-start flex-column">
        <div className="mb-auto w-100">
          <$Form layout="vertical" autocomplete="off">
            <$Select
              dataTestid="select-invoice-type"
              name="invoiceType"
              formitem={{}}
              size="small"
              options={[
                {
                  label: t("US.COLLECTION.ECONOMY:INVOICES.VAT_INVOICES"),
                  invoiceType: ClientInvoiceType.VAT_INVOICES,
                },
                {
                  label: t("US.COLLECTION.ECONOMY:INVOICES.CREDITOR_INVOICES"),
                  invoiceType: ClientInvoiceType.CLIENT_INVOICES,
                },
              ]}
              optionValue="invoiceType"
              optionText="label"
              defaultValue={ClientInvoiceType.CLIENT_INVOICES}
              defaultLabel={t("US.COLLECTION.ECONOMY:INVOICES.NON_VAT_INVOICE")}
              allOption={false}
              showSearch={false}
              placeholder={t("US.COLLECTION.ECONOMY:INVOICES.TYPE")}
              onChange={(invoiceType: IClientInvoiceType) =>
                onChange({ invoiceType })
              }
              disabled={isSearchByInvoiceNo}
            />
            <div className="d-flex align-items-center justify-content-between mb-2">
              <label>
                {t("US.COLLECTION.ECONOMY:INVOICES.CREDIT_INVOICE")}
              </label>
              <$Switch
                size="small"
                name="creditInvoicesOnly"
                checked={creditInvoicesOnly}
                onChange={(creditInvoicesOnly: boolean) => {
                  restProps.setFieldValue(
                    "creditInvoicesOnly",
                    creditInvoicesOnly
                  );
                  onChange({ creditInvoicesOnly });
                }}
                disabled={isSearchByInvoiceNo}
              />
            </div>
            <div className="d-flex justify-content-between">
              <$Button
                id="btn-select-creditors"
                type="link"
                size="small"
                onClick={onSelectCreditors}
                className="font-weight-bold p-0"
                disabled={isSearchByInvoiceNo}
              >
                {t("US.COLLECTION.ECONOMY:INVOICES.SELECT_CREDITORS")}
              </$Button>
              <$Badge
                data-testid="badge-selected-creditors"
                style={{ backgroundColor: "#495057" }}
                count={
                  creditors[0] === -1
                    ? t("US.COLLECTION.ECONOMY:INVOICES.ALL")
                    : creditors.length
                }
              />
            </div>
            <$Divider orientation="center">
              {t("US.COLLECTION.ECONOMY:INVOICES.ADVANCE_FILTER")}
            </$Divider>
            {searchType !== CISearchType.INVOICE_NO && (
              <$Input
                dataTestid="input-filter-invoice-no"
                name="filterInvoiceNo"
                size="small"
                className="mb-2"
                placeholder={t(
                  "US.COLLECTION.ECONOMY:PLACE_HOLDERS.INVOICE_NO"
                )}
                allowClear
                onChange={(e: any) => {
                  restProps.setFieldValue("filterInvoiceNo", e.target.value);
                  onFilter({ filterInvoiceNo: e.target.value });
                }}
                disabled={isSearchByInvoiceNo}
              />
            )}
            <$Input
              dataTestid="input-filter-customer-no"
              name="customerNo"
              size="small"
              className="mb-2"
              placeholder={t("US.COLLECTION.ECONOMY:PLACE_HOLDERS.CUSTOMER_NO")}
              allowClear
              onChange={(e: any) => {
                restProps.setFieldValue("customerNo", e.target.value);
                onFilter({ customerNo: e.target.value });
              }}
              disabled={isSearchByInvoiceNo}
            />
            {searchType !== CISearchType.DUE_DATE && (
              <$RangePicker
                name="duePeriod"
                size="small"
                picker="date"
                minuteStep={15}
                order={false}
                currentTimeFormat={currentDateFormat}
                label={t("US.COLLECTION.ECONOMY:INVOICES.DUE_PERIOD")}
                className="mb-2"
                onCalendarChange={(value: any) => {
                  setDates(value);
                }}
                onOpenChange={(isOpen: boolean) => {
                  if (isOpen) {
                    setDates([]);
                  }
                }}
                onChange={(duePeriod: any) => {
                  onFilter({ duePeriod });
                }}
                disabledDate={disabledDate}
                disabled={isSearchByInvoiceNo}
              />
            )}
            <$Button
              id="btn-reset-advance-filter"
              type="default"
              block
              size="small"
              onClick={onReset}
              disabled={!restProps.isValid || isSearchByInvoiceNo}
            >
              {t("US.COLLECTION.ECONOMY:INVOICES.RESET")}
            </$Button>
          </$Form>
        </div>
        <div className="w-100">
          <div className="d-flex flex-column invoice-total mb-2">
            <div
              data-testid="total-invoices-count"
              className="font-weight-bold font-lg"
            >
              {totalInvoices}
            </div>
            <div data-testid="total-invoices-count-label">
              {activeTabKey == "0"
                ? t(
                    "US.COLLECTION.ECONOMY:INVOICES.TOTAL_PENDING_EXPORT_INVOICES"
                  )
                : t("US.COLLECTION.ECONOMY:INVOICES.TOTAL_EXPORTED_INVOICES")}
            </div>
          </div>
          <div
            className="d-flex flex-column export-invoice-total"
            data-testid="total-invoices-amount"
          >
            <div className="font-weight-bold font-lg">
              <$AmountLabel value={totalInvoicesAmount} />
            </div>
            <div>
              {activeTabKey == "0"
                ? t("US.COLLECTION.ECONOMY:INVOICES.PENDING_EXPORT_TOTAL")
                : t("US.COLLECTION.ECONOMY:INVOICES.EXPORTED_TOTAL")}
            </div>
          </div>
        </div>
      </aside>
    </$Affix>
  );
};

const mapStateToProps = (state: IRootState) => {
  const { common } = state;

  const { currentDateFormat, currentLanguage, currentCurrency } = common;

  return {
    currentDateFormat,
    currentLanguage,
    currentCurrency,
  };
};

const mapDispatchToProps = {};

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Filter);
