import EconSagas from "./economy";
import { RegisterPaymentSagas } from "./RegisterPayments";
import { ManagePaymentsSagas } from "./ManagePayments";
import { ClientInvoicesSagas } from "./ClientInvoices";
import { ClientOrdersSagas } from "./ClientOrders";
import { ManualOrderSagas } from "./ManualOrders";
import { ArticleSagas } from "./Articles";
import { RemitSagas } from "./Remit";
import { RemitHistorySagas } from "./RemitHistory";
import { ImportPaymentsSagas } from "./ImportPayments";
import { PaymentsImportSummarySaga } from "./PaymentsImportSummary";
import { VatCodeSaga } from "./VatCode";
import { CoveringSequenceSagas } from "./CoveringSequence";
import { ProvisionProfilesSagas } from "./ProvisionProfiles";
import { ClientAccountsSagas } from "./ClientAccounts";
import { ItemTypesSagas } from "./ItemTypes";
import { LedgerAccountSagas } from "./LedgerAccount";
import { JournalEntrySagas } from "./RegisterJournal";
import { CreditorInvoiceDetailSagas } from "./CreditorInvoiceDetails";

export default [
  ...EconSagas,
  ...RegisterPaymentSagas,
  ...ManagePaymentsSagas,
  ...ClientOrdersSagas,
  ...ManualOrderSagas,
  ...ArticleSagas,
  ...RemitSagas,
  ...RemitHistorySagas,
  ...ClientInvoicesSagas,
  ...ImportPaymentsSagas,
  ...PaymentsImportSummarySaga,
  ...VatCodeSaga,
  ...CoveringSequenceSagas,
  ...ProvisionProfilesSagas,
  ...ClientAccountsSagas,
  ...ItemTypesSagas,
  ...LedgerAccountSagas,
  ...JournalEntrySagas,
  ...CreditorInvoiceDetailSagas
];
