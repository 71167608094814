export const initialOrderTableData = {
    key: 0,
    orderlineId: 0,
    article: '',
    articleDescription: "",
    vatCode: 0,
    caseNo: '',
    caseId: 0,
    description: "",
    units: 1,
    unitPrice: 0,
    amount: 0,
    vatAmount: 0,
    totalAmount: 0,
    user: "",
    isValidCaseNo: '',
    isValidArticle: "",
    type: 'C',
    isManualOrder: true,
    isVatCalculated: ''
  };

  export enum OrderTypes {
    VAT_INVOICE_TYPE = 'V',
    CLIENT_INVOICE_TYPE = 'C',
}

export enum OrderLineProperties {
  ARTICLE = 'article',
  CASE_NO = 'caseNo',
  DESCRIPTION = 'description',
  UNITS = 'units',
  UNIT_PRICE = 'unitPrice',
}

export const DefaultVatStatus = "Refundable"